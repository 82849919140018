import axios from "axios"

export const getPosition = async () => {

    return new Promise((resolve , reject) => {

        const onSuccess = async (position) => {
            const latitude = position.coords.latitude
            const longitude = position.coords.longitude
    
            // const apiUrl = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=jsonv2`
            // const apiUrl = `https://api.geoapify.com/v1/geocode/reverse?lat=${latitude}&lon=${longitude}&apiKey=7f94949b1b184d4795d1c8578ec6683f`
            const apiUrl = `https://api.geoapify.com/v1/geocode/reverse?lat=${latitude}&lon=${longitude}&apiKey=0fceb5f9087c4502beb663d525a7e997`

            const { data } = await axios({
                url: apiUrl,
                method: 'GET'
            })
            const { features: [ { properties: address } ] } = data
            const { country, city } = address

            const response = {city , country}

            resolve(response)
            
        }
    
        const onError = () => {
            resolve('')
        }
    
        navigator.geolocation.getCurrentPosition(onSuccess , onError)
    })
}