import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import RegistrationKYC from './RegistrationKYC'
import RegistrationCheck from './RegistrationCheck'

const useQuery = () => {
    
    const {search} = useLocation()

    return React.useMemo( () => new URLSearchParams(search)  , [search])
}

function Index() {

    const param = useQuery()
    const { state } = useLocation();

    if(!param.get('token') && !state) return <RegistrationKYC />

    return <RegistrationCheck token={param.get('token')} />
    
}

export default Index