import { Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { numberCommas } from "../../utils";

export default function Quotation({ dataSource }) {
  const columnsProduct = [
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (unit_price) => {
        return `Rp ${numberCommas(unit_price)},-`;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => {
        return `Rp ${numberCommas(price)},-`;
      },
    },
  ];

  return (
    <Row gutter={[0, 24]} className="w-full rounded-md">
      <Col
        span={24}
        className="rounded-t-md"
        style={{
          backgroundImage: "url('/images/cover-login.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Row gutter={[24, 24]} className="px-8 py-4" justify="space-between">
          <Col>
            <div className="flex flex-row space-x-4">
              <img
                className="w-14"
                src="/images/logo-primary.png"
                alt="dli-logo"
              />
              <div className="flex flex-col">
                <h1 className="text-lg font-medium text-white tracking-widest">
                  Managed Work Perfectly
                </h1>
                <h2 className="text-xs text-white">
                  Gather insights from data through customizable visualization
                </h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="flex flex-col space-y-1">
              <p className="text-xs text-gray-100 tracking-wide">
                dlisolution@dli.com
              </p>
              <p className="text-xs text-gray-100 tracking-wide">
                (021) 65890032
              </p>
              <p className="text-xs text-gray-100 tracking-wide">
                Jl. Bangka Barat No.7b
              </p>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <div className="flex flex-col space-y-4 px-8">
          <div className="flex flex-row justify-between">
            <div>
              <p>No. Surat : {dataSource?.nomor_surat}</p>
              <p>Perihal : Surat Penawaran Harga Produk ONEGO</p>
            </div>
            <div>
              <p className="text-gray-500">
                {moment(dataSource?.quotation_date, moment.ISO_8601).format(
                  "dddd, DD MMMM YYYY"
                )}
              </p>
              <p className="text-gray-500">(created/download data)</p>
            </div>
          </div>
          <div>
            <p>Kepada Yth,</p>
            <p>Perusahaan {dataSource?.office_name}</p>
            <p>{dataSource?.address}</p>
          </div>
          <div>
            <p>Dengan hormat,</p>
            <p>
              Kami dari PT DLI Solution bermaksud menawarkan produk berupa
              layanan di dalam produk ONEGO, dengan penawaran harga yang
              menarik. Berikut dibawah ini daftar rincian produk yang kami
              tawarkan.
            </p>
          </div>
          <table class="items-center bg-transparent w-full">
            <thead>
              <tr>
                {columnsProduct.map((item, index) => {
                  return (
                    <th
                      key={index}
                      className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-2 text-xs border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
                    >
                      {item.title}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {dataSource?.products?.map((itemTr, indexTr) => {
                return (
                  <tr key={indexTr}>
                    {columnsProduct.map((itemTd, indexTd) => {
                      return (
                        <td
                          key={indexTd}
                          className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 "
                        >
                          {itemTd?.render
                            ? itemTd.render(itemTr[itemTd.dataIndex], itemTr)
                            : itemTr[itemTd.dataIndex]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              <tr className="border-t-2 border-gray-100">
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 ">
                  <p className="text-gray-500 font-medium text-sm">Biaya Admin</p>
                </td>
                <td></td>
                <td></td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 ">
                  <p className="text-gray-500 font-medium text-sm">
                    <p className="text-blue-700 font-medium text-sm">{`Rp ${numberCommas(
                      Number(dataSource?.admin_fee || 0)
                    )},-`}</p>
                  </p>
                </td>
              </tr>
              <tr className="border-0">
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 ">
                  <p className="text-gray-500 font-medium text-sm">Subtotal</p>
                </td>
                <td></td>
                <td></td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 ">
                  <p className="text-gray-500 font-medium text-sm">
                    <p className="text-blue-700 font-medium text-sm">{`Rp ${numberCommas(
                      dataSource?.total
                    )},-`}</p>
                  </p>
                </td>
              </tr>
              <tr className="border-b-2 border-gray-100">
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 ">
                  <p className="text-gray-500 font-medium text-sm">Total</p>
                </td>
                <td></td>
                <td></td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 ">
                  <p className="text-gray-500 font-medium text-sm">
                    <p className="text-blue-700 font-medium text-sm">{`Rp ${numberCommas(
                      Number(dataSource?.admin_fee || 0) + (Number(dataSource?.total || 0))
                    )},-`}</p>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Bila Bapak/Ibu menyetujui penawaran tersebut, proses pembayaran
            dapat dilakukan secara langsung melalui platform ONEGO. Demikian
            surat penawaran harga ini kami sampaikan, besar harapan kami untuk
            Bapak/Ibu berminat dengan harga yang kami tawarkan. Atas
            perhatiannya, kami ucapkan banyak terima kasih.
          </p>
          <div className="flex flex-col self-end">
            <p>Hormat kami,</p>
            <p>Direktur Utama DLI Solution</p>
            <br />
            <br />
            <p>Mas Ginanjar</p>
          </div>
        </div>
      </Col>
    </Row>
  );
}
