import { 
  Button, 
  Row,   
  Col,
  Modal, 
  Menu,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFetchDetailClientQuery } from "../../config/features/platform";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { errorMessage } from "../../utils";
import ChangePassword from "./ChangePassword";
import { ProtectedRoute } from "../../config";
import { useTranslation } from "react-i18next";

// === TABS 
import AdminInfo from "./AdminInfo";
import CooperationAgreement from "./CooperationAgreement";
import {LeftOutlined} from '@ant-design/icons'
export default function Profile(props) {
  const location = useLocation();
  const history = useHistory();
  const pathname = props?.location?.pathname;
  const initialTabs = pathname.match(/\/coopAgreement/) ? "coopAgreement" : ""
  const [tabs, setTabs] = useState(initialTabs);
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const {
    data: userProfile = {},
    isError,
    isFetching,
    error,
    refetch,
  } = useFetchProfileUserQuery();

  const {
    data: dataDetailClient = {},
    refetch: refetchDetail,
    // isFetching: isFetchingDetail,
  } = useFetchDetailClientQuery(userProfile?.office_id);

  const isPic = userProfile?.is_pic;

  useEffect(() => {
    const isSuperAdmin = userProfile?.role_id === 4;
    const isNewUser = userProfile?.is_first;
    const isRegistered = userProfile?.is_registered;

    if (
      !isNewUser &&
      !isSuperAdmin &&
      isRegistered !== undefined &&
      isRegistered === false
    ) {
      history.push("/registration");
    }

    if (isError) {
      errorMessage(error);
    }
  }, [isError, error, userProfile, history]);

  useEffect(() => {
    refetch();
    refetchDetail();
  }, [refetch, refetchDetail]);


  useEffect(() => {
    const editRegx = /^\/profile\/edit$/
    if(editRegx.test(location.pathname)){
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
  }, [location.pathname])

  function handleClickTabs(e) {
    history.push(`${props?.match?.url}${e.key ? `/${e.key}` : ""}`)
    setTabs(e.key)
  }

  return (
    <>
      {!isEdit && (
        <>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Row gutter={[24, 24]} justify="space-between">
                <Col>
                  <Row gutter={[24, 24]} align="middle">
                    <Col>
                      <div
                        className="group cursor-pointer"
                        onClick={() => history.goBack()}
                      >
                        <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                      </div>
                    </Col>
                    <Col>
                      <h1 className="text-xl md:text-2xl font-medium">{t("Profile")}</h1>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    {/* <Col>
                      <Button
                        className="bg-blue-500 justify-center px-10 rounded-md mr-4"
                        type="primary"
                      >
                        Register Account
                      </Button>
                    </Col> */}
                    <Button
                      className="bg-blue-700 justify-center px-10 rounded-md"
                      type="primary"
                      onClick={() =>
                        history.push({
                          pathname: `/profile/edit`,
                          state: userProfile,
                        })
                      }
                    >
                      {t("changepassword")}
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Menu 
                className="bg-transparent"
                onClick={handleClickTabs} 
                selectedKeys={[tabs]} 
                mode="horizontal"
              >
                <Menu.Item key="">
                  {t("admininformation")}
                </Menu.Item>
                {isPic && <Menu.Item key="coopAgreement">
                  {t("cooperationagreement")}
                </Menu.Item>}
              </Menu>
            </Col>

            <ProtectedRoute
              path="/profile"
              component={
                () => 
                <AdminInfo 
                  userProfile={userProfile} 
                  dataDetailClient={dataDetailClient} 
                  isFetching={isFetching} 
                />
              }
              exact={true}
            />

            {isPic && <ProtectedRoute
              path="/profile/coopAgreement"
              component={
                () => 
                <CooperationAgreement 
                  dataDetailClient={dataDetailClient} 
                  isFetching={isFetching} 
                  officeId={userProfile?.office_id}
                />
              }
              exact={true}
            />}
          </Row>

          

          <Modal
            visible={userProfile?.is_first}
            width={600}
            footer={false}
            closable={false}
          >
            <ChangePassword dataSource={userProfile} />
          </Modal>
        </>
      )}
    </>
  );
}
