import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Divider, message, Row, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useAddQuotationMutation, useFetchBillingDetailQuery, useFetchMonitorDetailQuery } from "../../config/features";
import { Button, Modal, Spinner } from "../../components";
import clsx from "clsx";
import { PRODUCTS, STAMP_STATUS } from "../../constant";
import moment from "moment";
import { current } from "@reduxjs/toolkit";
import { createIndex } from "../../utils/table";
import { errorMessage, numberCommas } from "../../utils";

export default function CompletionMonitor() {
  const { state: bill } = useLocation();
  const [paginationDetails, setPaginationDetails] = useState({
    row: 5,
    page: 1
  })
  const [paginationHistory, setPaginationHistory] = useState({
    row: 5,
    page: 1
  })

  const [showModalQuotation, setShowModalQuotation] = useState(false)
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [payloadCompletion, setPayloadCompletion] = useState({})
  const history = useHistory();

  const getParams = (bill) => ({
    office_id: Number(bill.office_id) || '',
    bill_id: Number(bill.bill_id) || '',
    bill_parent_id: Number(bill.bill_parent_id) || ''
  })

  const [
    createCompletion,
    {
      isSuccess: isSuccessCompletion,
      isError: isErrorCompletion,
      error: errorCompletion,
      isLoading: isLoadingCompletion
    }
  ] = useAddQuotationMutation();

  const {
    data: dataBill,
    refetch: refetchBill,
    error: errorBill,
    isError: isErrorBill,
    isFetching: isFetchingBill
  } = useFetchBillingDetailQuery(bill.bill_parent_id || bill.bill_id)

  const {
    data: dataDetailMonitor,
    refetch: refetchDetailMonitor,
    isError: isErrorDetailMonitor,
    error: errorDetailMonitor,
    isFetching: isFetchDetailMonitor
  } = useFetchMonitorDetailQuery(getParams(bill))

  useEffect(() => {
    refetchDetailMonitor();
  }, [refetchDetailMonitor])

  useEffect(() => {
    refetchBill();
  }, [refetchBill])

  useEffect(() => {
    if (isSuccessCompletion) {
      setIsModalSuccess(true);
    }
    if (isErrorCompletion) {
      errorMessage(errorCompletion);
    }
  }, [isSuccessCompletion, isErrorCompletion, errorCompletion])

  useEffect(() => {
    console.log('DATA BILL => ', dataBill);
  }, [dataBill])

  const getLastStatus = () => {
    if (dataDetailMonitor?.monitor_childs?.length > 0) {
      const monitor_childs = dataDetailMonitor.monitor_childs;
      return monitor_childs[monitor_childs.length - 1]?.status;
    }
    return dataDetailMonitor?.status;
  }
  const calculateTotalGenerated = () => {
    let generated = dataDetailMonitor?.generated;
    if (dataDetailMonitor?.monitor_childs && dataDetailMonitor?.monitor_childs?.length > 0) {
      const monitor_childs = dataDetailMonitor.monitor_childs;
      generated += Number(monitor_childs.reduce((subtotal, item) => typeof subtotal == 'object' ? (subtotal.generated + item.generated) : (subtotal + item.generated))) || Number(monitor_childs[0].generated)
    }
    return generated;
  }
  const columnsDetail = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no'
    },
    {
      title: 'Batch ID',
      dataIndex: 'batch_id',
      key: 'batch_id'
    },
    {
      title: 'Generated',
      dataIndex: 'current',
      key: 'current'
    },
    {
      title: 'Max',
      dataIndex: 'max',
      key: 'max'
    },
    {
      title: 'Status',
      render: (record) => {
        const color = record?.status == 1 ? 'processing' : record?.status == 2 ? 'success' : record?.status == 3 ? 'error' : 'default'
        return <Tag color={color} className="text-center w-20">{STAMP_STATUS[record?.status]?.value}</Tag>
      }
    }
  ]
  const columnsLog = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no'
    },
    {
      title: 'Batch ID',
      render: (record) => {
        return (
          <>
            {record?.details?.map((item) => (
              <Row><p className="my-2">{item?.batch_id}</p></Row>
            ))}
          </>
        )
      }
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => created_at && moment(created_at, moment.ISO_8601).format('DD MMMM YY, HH:mm')
    },
    {
      title: 'Current',
      render: (record) => {
        return (
          <>
            {record?.details?.map((item) => (
              <Row><p className="my-2">{item?.current}</p></Row>
            ))}
          </>
        )
      }
    },
    {
      title: 'Max',
      render: (record) => {
        return (
          <>
            {record?.details?.map((item) => (
              <Row><p className="my-2">{item?.max}</p></Row>
            ))}
          </>
        )
      }
    },
    {
      title: 'Status',
      render: (record) => {
        return (
          <>
            {record?.details?.map((item) => {
              const color = item?.status == 1 ? 'processing' : item?.status == 2 ? 'success' : item?.status == 3 ? 'error' : 'default'
              return <Row><Tag color={color} className="text-center w-20 my-2">{STAMP_STATUS[item?.status]?.value}</Tag></Row>
            })}
          </>
        )
      }
    }
  ]
  const getProductID = (product) => {
    const product_index = Object.values(PRODUCTS).findIndex((item) => item.name == product.product_name)
    return Object.keys(PRODUCTS)[product_index];
  }
  const getProductCompletion = () => {
    if (!dataBill?.product_list) {
      return [];
    }
    const incompleteGenerated = Number(dataDetailMonitor?.total) - calculateTotalGenerated();


    // let products = dataBill?.product_list?.filter(item => item.product_name == PRODUCTS[1].name || item.product_name == PRODUCTS[6].name)
    let products = dataBill?.product_list

    products = products.map(({ product_name, unit_price }) => ({
      id: getProductID({ product_name }),
      product_name,
      // quantity: incompleteGenerated, 
      unit_price,
      // total: incompleteGenerated * unit_price
    })) || []

    products = products.map(item => ({
      ...item,
      quantity: item.id == 1 || item.id == 6 ? incompleteGenerated : 0,
      total: item.id == 1 || item.id == 6 ? incompleteGenerated * item.unit_price : 0
    }))
    return products
  }
  const handleShowModal = () => {
    const products = getProductCompletion();
    setPayloadCompletion({
      office_id: bill.office_id,
      total: Number(products.reduce((subtotal, item) => typeof subtotal == 'object' ? (subtotal.total + item.total) : (subtotal + item.total))) || Number(products[0].total),
      products,
      completion: 1,
      bill_parent_id: bill.bill_parent_id || bill.bill_id
    })
    setShowModalQuotation(true)
  };
  const handleSubmitCompletion = () => {
    try {
      createCompletion(payloadCompletion);
      setPayloadCompletion({});
      setShowModalQuotation(false);
    } catch (err) {
      message.error(err.message);
    }
  }
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div className="group cursor-pointer" onClick={() => history.goBack()}>
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    Completion Stamp Monitor
                  </h1>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row className="flex justify-between">
              <Col>
                <Row gutter={[48, 48]}>
                  <Col>
                    <h5 className="text-gray-500 mb-3">
                      Status
                    </h5>
                    {/* Last Generate Status */}
                    <h4 className={clsx("text-3xl", STAMP_STATUS[getLastStatus()]?.clsTextColor)}>
                      {STAMP_STATUS[getLastStatus()]?.value || "--"}
                    </h4>
                  </Col>
                  <Col>
                    <h5 className="text-gray-500 mb-3">
                      Date Create
                    </h5>
                    <h4 className="text-3xl">
                      {dataDetailMonitor?.created_at ? moment(dataDetailMonitor?.created_at, moment.ISO_8601).format("DD/MM/YY") : "--"}
                    </h4>
                  </Col>
                  <Col>
                    <h5 className="text-gray-500 mb-3">
                      Total
                    </h5>
                    <h4 className="text-3xl">
                      {dataDetailMonitor?.total || "--"}
                    </h4>
                  </Col>
                  <Col>
                    <h5 className="text-gray-500 mb-3">
                      Generated
                    </h5>
                    <h4 className="text-3xl">
                      {calculateTotalGenerated() || "--"}
                    </h4>
                  </Col>
                  {/* <Col>
                    <h5 className="text-gray-500 mb-3">
                      Type
                    </h5>
                    <h4 className="text-3xl capitalize">
                      {dataDetailMonitor?.type || "--"}
                    </h4>
                  </Col> */}
                </Row>
              </Col>
              {
                getLastStatus() != 2 && calculateTotalGenerated() < dataDetailMonitor?.total && (
                  <Col className="flex flex-col items-center justify-center">
                    <Button type="primary" ghost onClick={handleShowModal}>
                      Create Completion
                    </Button>
                  </Col>
                )
              }
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 0]}>
              <Col span={24}>
                <Row gutter={[24, 24]} align="middle" justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      Monitor Details
                    </h3>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Divider />
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsDetail}
                  dataSource={createIndex(dataDetailMonitor?.details)}
                  loading={isFetchDetailMonitor}
                  scroll={{ x: 950 }}
                  pagination={{
                    current: paginationDetails.page,
                    pageSize: paginationDetails.row
                  }}
                  onChange={(pagination) => {
                    setPaginationDetails({
                      ...paginationDetails,
                      page: pagination.current,
                      row: pagination.pageSize
                    })
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        {dataDetailMonitor?.monitor_childs && dataDetailMonitor?.monitor_childs?.length > 0 && (
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    Completion History
                  </h3>
                </Col>
                <Col span={24}>
                  <Table
                    columns={columnsLog}
                    dataSource={createIndex(dataDetailMonitor?.monitor_childs?.filter(item => item.monitor_id))}
                    loading={isFetchDetailMonitor}
                    scroll={{ x: 950 }}
                    pagination={{
                      current: paginationHistory.page,
                      pageSize: paginationHistory.row
                    }}
                    onChange={(pagination) => {
                      setPaginationHistory({
                        ...paginationHistory,
                        page: pagination.current,
                        row: pagination.pageSize
                      })
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        )}
      </Row>
      <Modal
        title="Are you sure?"
        subtitle="Are you sure you to change this quotation to client?"
        type="warning"
        visible={showModalQuotation}
        onCancel={() => setShowModalQuotation(false)}
        width={700}
      >
        <div className="flex flex-col space-y-2">
          <Table
            dataSource={payloadCompletion.products || []}
            columns={[
              {
                title: 'Product Name',
                dataIndex: 'product_name',
                key: 'product_name'
              },
              {
                title: "Qty",
                dataIndex: "quantity",
                key: "quantity",
                render: (quantity) => {
                  return numberCommas(quantity);
                },
              },
              {
                title: 'Unit Price',
                dataIndex: 'unit_price',
                key: 'unit_price',
                render: (unit_price) => {
                  return `Rp ${numberCommas(unit_price)},-`;
                }
              },
              {
                title: "Total",
                align: "right",
                render: (record) => {
                  return `Rp ${numberCommas(
                    record.unit_price * record.quantity
                  )},-`;
                },
              }
            ]}
            pagination={false}
          />
          <div className="flex flex-row justify-between px-4">
            <p className="font-medium text-gray-800">Total</p>
            <p className="font-medium text-gray-800">
              Rp {numberCommas(payloadCompletion?.total)},-
            </p>
          </div>
          <Divider />
        </div>
        <div className="flex flex-row space-x-4 pt-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={() => setShowModalQuotation(false)}
          >
            Cancel
          </Button>
          <Button
            className="w-full"
            type="primary"
            onClick={handleSubmitCompletion}
          >
            Yes
          </Button>
        </div>
      </Modal>
      <Modal
        title="Updated Success!"
        subtitle="Every time the quotation is updated the admin will get an notification"
        type="success"
        visible={isModalSuccess}
        onCancel={() => setIsModalSuccess(false)}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={() => history.goBack()}>
            Done
          </Button>
        </div>
      </Modal>
      <Spinner loading={isFetchDetailMonitor || isFetchDetailMonitor} />
    </>
  )
}