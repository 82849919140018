import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Row, Table, Switch, Form, message } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Modal, Spinner } from "../../components";
import { useState, useEffect } from "react";
import { useEditModuleMutation } from "../../config/features/platform";
import { errorMessage } from "../../utils";
import { useFetchProfileUserQuery } from "../../config/features";
import { useTranslation } from "react-i18next";

export default function EditModule() {
  const { t } = useTranslation();
  const history = useHistory();
  
  const location = useLocation();
  const params = useParams();
  const [form] = Form.useForm();

  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [payload, setPayload] = useState({});

  const { data: userProfile = {} } = useFetchProfileUserQuery();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [editModule, { isLoading, isSuccess, isError, error }] =
    useEditModuleMutation();

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }

    if (isSuccess) {
      setIsModalSuccess(true);
    }
  }, [isError, isSuccess, error]);

  useEffect(() => {
    function generateModule(listModule) {
      const module = [];

      for (let i = 0; i < Object.keys(listModule)?.length; i++) {
        if (listModule[Object.keys(listModule)[i]] !== null) {
          switch (Object.keys(listModule)[i]) {
            case "stamp_m":
              module.push({
                module: "E-Meterai",
                status: Object.keys(listModule)[i],
              });
              break;

            case "sign_m":
              module.push({
                module: "Digital Signature",
                status: Object.keys(listModule)[i],
              });
              break;

            case "user_m":
              module.push({
                module: "User Management",
                status: Object.keys(listModule)[i],
              });
              break;

            case "group_m":
              module.push({
                module: "Group Management",
                status: Object.keys(listModule)[i],
              });
              break;

            case "office_m":
              module.push({
                module: "Office Management",
                status: Object.keys(listModule)[i],
              });
              break;

            case "document_m":
              module.push({
                module: "Document Management",
                status: Object.keys(listModule)[i],
              });
              break;

            case "backup_m":
              module.push({
                module: "Backup Secure Document",
                status: Object.keys(listModule)[i],
              });
              break;
            
            case "blockchain_m":
              module.push({
                module: "Blockchain Document",
                status: Object.keys(listModule)[i],
              });
              break;

            default:
              break;
          }
        }
      }

      setDataSource(module);
    }

    generateModule(location?.state);
  }, [location]);

  const columns = [
    {
      title: "Module Name",
      dataIndex: "module",
      key: "module",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          location?.state &&
          Object.keys(location?.state).length > 0 && (
            <Form.Item
              className="my-0"
              name={status}
              initialValue={location?.state[status]}
            >
              <Switch
                disabled={status === 'blockchain_m' && userProfile?.role_id !== 4}
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                valuePropName="checked"
                defaultChecked={location?.state[status]}
              />
            </Form.Item>
          )
        );
      },
    },
  ];

  const data = [
    {
      module: "E-Meterai",
      status: "stamp_m",
    },
    {
      module: "Digital Signature",
      status: "sign_m",
    },
    {
      module: "Backup Secure Document",
      status: "backup_m",
    },
    {
      module: "User Management",
      status: "user_m",
    },
    {
      module: "Group Management",
      status: "group_m",
    },
    {
      module: "Office Management",
      status: "office_m",
    },
    {
      module: "Document Management",
      status: "document_m",
    },
    {
      module: "Blockchain Document",
      status: "blockchain_m",
    },
  ];

  function toggleModalConfirm() {
    setIsModalConfirm(!isModalConfirm);
  }

  function handleFinish(values) {
    toggleModalConfirm();
    setPayload(values);
  }

  async function handleSubmit() {
    try {
      toggleModalConfirm();
      editModule({ office_id: params?.id, body: payload });
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleDone() {
    history.goBack();
  }

  return (
    <>
      <Form
        autoComplete="off"
        layout="vertical"
        form={form}
        onFinish={handleFinish}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.goBack()}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      {t("editmodul")}
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button className="md:w-40" type="primary" htmlType="submit">
                  {t("btnupdate")}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    {t("listmodule")}
                  </h3>
                </Col>
                <Col span={24}>
                  <Table
                    columns={columns}
                    dataSource={
                      location?.pathname?.split("/")[1] === "module-management"
                        ? dataSource
                        : data
                    }
                    scroll={{ x: 300 }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Are you sure?"
        subtitle="Are you sure you want to change module?"
        type="warning"
        visible={isModalConfirm}
        onCancel={toggleModalConfirm}
      >
        <div className="flex flex-row space-x-4 pt-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={toggleModalConfirm}
          >
            Back
          </Button>
          <Button className="w-full" type="primary" onClick={handleSubmit}>
            Update
          </Button>
        </div>
      </Modal>
      <Modal
        title="Update Success!"
        subtitle="Module has been updated! Now you can continue browsing"
        type="success"
        visible={isModalSuccess}
        onCancel={() => setIsModalSuccess(false)}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={handleDone}>
            Done
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
