import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { rootApi, access } from "../config";

export const platformApi = createApi({
  reducerPath: "api/platform",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi + "/platform",
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      fetchHeader: builder.query({
        query() {
          return "/header";
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      fetchMonthlySales: builder.query({
        query(month_range = 6) {
          return `/monthly?month_range=${month_range}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchSalesPerHQ: builder.query({
        query({ month = 1, office_id = "", year = "" }) {
          return `/hq?month=${month}&office_id=${office_id}&year=${year}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchBillingHistory: builder.query({
        query({
          page = 1,
          sort_by = "created_at",
          order = "desc",
          row = 10,
          office_id = "",
        }) {
          return `/bill?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&office_id=${office_id}`;
        },
      }),

      fetchBillingDetail: builder.query({
        query(bill_id) {
          return `/bill/${bill_id}`;
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      fetchActivityClient: builder.query({
        query({ office_id = "" }) {
          return `/client-act?office_id=${office_id}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchListClient: builder.query({
        query({
          page = 1,
          sort_by = "created_at",
          order = "desc",
          row = 10,
          search = "",
          activated = "",
          verified = "",
        }) {
          return `/client?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}&activated=${activated}&verified=${verified}`;
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      fetchDetailClient: builder.query({
        query(id_client) {
          return `/client/${id_client}`;
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      addClient: builder.mutation({
        query: (body) => ({
          method: "POST",
          url: "/client",
          body,
        }),
      }),

      addQuotation: builder.mutation({
        query: (body) => ({
          method: "POST",
          url: "/quotation",
          body,
        }),
      }),

      fetchListQoutation: builder.query({
        query({
          page = 1,
          sort_by = "created_at",
          order = "desc",
          row = 10,
          search = "",
          office_id = "",
        }) {
          return `/quotation?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}&office_id=${office_id}`;
        },
      }),

      fetchDetailQoutation: builder.query({
        query({id_office, page = 1}) {
          return `/quotation/${id_office}?page=${page}`;
        },
        transformResponse: (response) => (response ? {...response.data, total: response.total} : {}),
      }),

      sendQuotation: builder.mutation({
        query: (id_quotation) => ({
          method: "PUT",
          url: `/quotation/${id_quotation}`,
        }),
      }),

      verifyClient: builder.mutation({
        query: ({ id_office, status }) => ({
          method: "PUT",
          url: `/verify/${id_office}`,
          params: {
            client_status: status,
          },
        }),
        transformResponse: (response) => (response ? response.data : ""),
      }),

      confirmPayment: builder.mutation({
        query: ({ id_bill, payment_date, cancel, note }) => ({
          method: "PUT",
          url: `/confirm/${id_bill}`,
          params: {
            cancel,
          },
          body: {
            payment_date,
            note,
          },
        }),
        transformResponse: (response) => (response ? response.data : ""),
      }),

      editModule: builder.mutation({
        query: ({ office_id, body }) => ({
          method: "PUT",
          url: `/client-module`,
          params: {
            office_id,
          },
          body,
        }),
        transformResponse: (response) => (response ? response.data : {}),
      }),

      editPIC: builder.mutation({
        query: (body) => ({
          method: "PUT",
          url: `/pic`,
          body,
        }),
        transformResponse: (response) => (response ? response.data : {}),
      }),

      fetchAdminBilling: builder.query({
        query({
          page = 1,
          sort_by = "created_at",
          order = "asc",
          row = 10,
          search = "",
          status = "",
        }) {
          return `/admin-bill?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}&status=${status}`;
        },
      }),

      addAdminBill: builder.mutation({
        query: (body) => ({
          method: "POST",
          url: `/admin-bill`,
          body,
        }),
        transformResponse: (response) => (response ? response.data : {}),
      }),

      editAdminBill: builder.mutation({
        query: ({ userId, body }) => ({
          method: "PUT",
          url: `/admin-bill/${userId}`,
          body,
        }),
        transformResponse: (response) => (response ? response.data : {}),
      }),

      deleteAdminBill: builder.mutation({
        query: (userId) => ({
          method: "DELETE",
          url: `/admin-bill/${userId}`,
        }),
        transformResponse: (response) => (response ? response.data : {}),
      }),
      fetchQuotaHQ: builder.query({
        query({office_id = ""}) {
          return `/quotas?office_id=${office_id}`
        },
        transformResponse: (respone) => (respone ? respone.data : [])
      })
    };
  },
});

export const {
  useFetchHeaderQuery,
  useFetchMonthlySalesQuery,
  useFetchSalesPerHQQuery,
  useFetchBillingHistoryQuery,
  useFetchActivityClientQuery,
  useFetchBillingDetailQuery,
  useFetchListClientQuery,
  useFetchDetailClientQuery,
  useAddClientMutation,
  useFetchListQoutationQuery,
  useFetchDetailQoutationQuery,
  useVerifyClientMutation,
  useConfirmPaymentMutation,
  useEditModuleMutation,
  useEditPICMutation,
  useAddQuotationMutation,
  useSendQuotationMutation,
  useFetchAdminBillingQuery,
  useEditAdminBillMutation,
  useDeleteAdminBillMutation,
  useAddAdminBillMutation,
  useFetchQuotaHQQuery
} = platformApi;
