import {
  Card,
  Col,
  Divider,
  message,
  Row,
  Skeleton,
  Switch,
} from "antd";
import { Button, Spinner } from "../../components";
import { useEffect } from "react";
import { ReactComponent as VerifyIcon } from "../../assets/icons/verify-filled.svg";

// === REDUX API
import { useVerifyClientMutation } from "../../config/features/platform";

// === UTILS
import { errorMessage } from "../../utils";


export default function ClientInfo({ dataDetailClient, isFetching, refetchDetailClient, id }) {
  const [
    verifyClient,
    { isError, error, isSuccess, data: responseVerify, isLoading },
  ] = useVerifyClientMutation();

  useEffect(() => {
    if (isError) { errorMessage(error) }
    if (isSuccess) { 
      message.success(responseVerify) 
      refetchDetailClient()
    }
  }, [isError, error, isSuccess, refetchDetailClient, responseVerify]);

  async function handleVerify() {
    try {
      verifyClient({ id_office: id });
    } catch (error) {
      message.error(error);
    }
  }

  async function toggleActivated() {
    const isActive = Boolean(dataDetailClient?.company?.is_active);
    try {
      verifyClient({ id_office: id, status: !isActive });
    } catch (error) {
      message.error(error);
    }
  }

  return (
    <>
      {dataDetailClient?.company && !dataDetailClient?.account?.is_verified && (
        <Col span={24}>
          <Card className="rounded-md bg-yellow-50">
            <Row gutter={[24, 24]} justify="space-between" align="middle">
              <Col>
                <p className="text-yellow-500 text-xs md:text-sm">
                  Client have been send update new data for verified account.
                  Please make sure before activate
                </p>
              </Col>
              <Col>
                <Button type="primary" onClick={handleVerify}>
                  Activate Account
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
      {dataDetailClient?.company && dataDetailClient?.account?.is_verified && (
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]} justify="space-between" align="middle">
              <Col>
                <p className="text-yellow-500 text-xs md:text-sm">
                  You can changed client status account. Please make sure
                  before change!
                </p>
              </Col>
              <Col>
                <Switch
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  defaultChecked={true}
                  checked={dataDetailClient?.company?.is_active}
                  onChange={toggleActivated}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      )}
      {dataDetailClient?.company ? (
        <Col span={24}>
          <Card
            className={`rounded-md ${
              !dataDetailClient?.account?.is_verified && "bg-yellow-50"
            }`}
          >
            <Skeleton loading={isFetching} active paragraph={{ rows: 6 }}>
              <Row gutter={[24, 6]}>
                <Col span={24}>
                  <Row
                    gutter={[24, 24]}
                    justify="space-between"
                    align="middle"
                  >
                    <Col>
                      <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                        Office Information
                      </h3>
                    </Col>
                    <Col>
                      <h4
                        className={`text-base md:text-lg font-medium ${
                          dataDetailClient?.account?.is_verified
                            ? "text-blue-700"
                            : "text-gray-500"
                        }`}
                      >
                        {dataDetailClient?.account?.is_verified ? (
                          <div className="flex flex-row space-x-2 justify-center items-center">
                            <VerifyIcon /> <p>Verified</p>
                          </div>
                        ) : (
                          "Unverified"
                        )}
                      </h4>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Divider />
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 24]}>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            Office Name
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetailClient?.account?.office_name}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            Email Office
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetailClient?.account?.email_office}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            Office Number Phone
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetailClient?.company?.phone_office}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            Office Address
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetailClient?.company?.address}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            Province
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetailClient?.company?.province}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            City
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetailClient?.company?.city}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Divider />
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 24]}>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            NPWP Number
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetailClient?.company?.npwp_number}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            NPWP File
                          </p>
                        </Col>
                        <Col
                          span={24}
                          className="flex flex-row space-x-2 items-center"
                        >
                          <div className="bg-red-400 px-1 py-1 rounded-md">
                            <p className="font-bold text-xs text-white">
                              PDF
                            </p>
                          </div>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetailClient?.company?.npwp_file
                              ?.split("/")
                              ?.pop()}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            TDP File
                          </p>
                        </Col>
                        <Col
                          span={24}
                          className="flex flex-row space-x-2 items-center"
                        >
                          <div className="bg-red-400 px-1 py-1 rounded-md">
                            <p className="font-bold text-xs text-white">
                              PDF
                            </p>
                          </div>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetailClient?.company?.tdp_file
                              ?.split("/")
                              ?.pop()}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Skeleton>
          </Card>
        </Col>
      ) : (
        <Col span={24}>
          <Card className="rounded-md">
            <Skeleton loading={isFetching} active paragraph={{ rows: 6 }}>
              <Row gutter={[24, 6]}>
                <Col span={24}>
                  <Row
                    gutter={[24, 24]}
                    justify="space-between"
                    align="middle"
                  >
                    <Col>
                      <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                        Office Information
                      </h3>
                    </Col>
                    <Col>
                      <h4 className="text-gray-500 text-base md:text-lg font-medium">
                        {dataDetailClient?.account?.is_verified ? (
                          <div className="flex flex-row space-x-2 justify-center items-center">
                            <VerifyIcon /> <p>Verified</p>
                          </div>
                        ) : (
                          "Unverified"
                        )}
                      </h4>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Divider />
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 24]}>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            Office Name
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetailClient?.account?.office_name}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            Email Office
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetailClient?.account?.email_office}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Skeleton>
          </Card>
        </Col>
      )}
      <Col span={24}>
        <Card className="rounded-md">
          <Skeleton loading={isFetching} active paragraph={{ rows: 6 }}>
            <Row gutter={[24, 6]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between" align="middle">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      Account Information
                    </h3>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Divider />
              </Col>
              <Col span={24}>
                <Row gutter={[24, 24]}>
                  <Col xs={12} lg={8}>
                    <Row gutter={[24, 12]}>
                      <Col span={24}>
                        <p className="text-gray-400 text-xs md:text-sm">
                          Name PIC
                        </p>
                      </Col>
                      <Col span={24}>
                        <h4 className="text-gray-800 text-sm md:text-base font-medium">
                          {`${dataDetailClient?.account?.first_name_pic} ${
                            dataDetailClient?.account?.last_name_pic
                              ? dataDetailClient?.account?.last_name_pic
                              : ""
                          }`}
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Row gutter={[24, 12]}>
                      <Col span={24}>
                        <p className="text-gray-400 text-xs md:text-sm">
                          Phone Number PIC
                        </p>
                      </Col>
                      <Col span={24}>
                        <h4 className="text-gray-800 text-sm md:text-base font-medium">
                          {dataDetailClient?.account?.phone_pic}
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Row gutter={[24, 12]}>
                      <Col span={24}>
                        <p className="text-gray-400 text-xs md:text-sm">
                          Email PIC
                        </p>
                      </Col>
                      <Col span={24}>
                        <h4 className="text-gray-800 text-sm md:text-base font-medium">
                          {dataDetailClient?.account?.email_pic}
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>
      <Spinner loading={isLoading} />
    </>
  )
}