import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { Card, Col, DatePicker, Dropdown, Input, Menu, Radio, Row, Select, Table } from "antd";
import { FilterOutlined, DownloadOutlined, LeftOutlined, IdcardFilled } from "@ant-design/icons";
import { SiMicrosoftexcel } from "react-icons/si";
import { MdPictureAsPdf } from "react-icons/md";
import { Button } from "../../components";


import {  useFetchListDepartemenDefectDetailQuery } from "../../config/features";
import { errorMessage, exportExcel, exportPdf, exportPdfFromExcel, getBlob, numberCommas } from "../../utils";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";

const { Search } = Input;
const { Option } = Select;

export default function MonitoringDepartemenDefectDetail() {
  const location = useLocation();
  const history = useHistory();
  const deptActivityRef = useRef()
  const { id }  = useParams();
  const { t } = useTranslation();

  const [isVisibleFilterDepartemenDefectDetail, setIsVisibleFilterDepartemenDefectDetail] = useState(false)
  const [paramsTableDepartemenDefectDetail, setParamsTableDepartemenDefectDetail] = useState({
    row: 5,
    page: 1,
    order: "desc",
    sort_by: "created_at",
    search: "office_id",
    start_date: "",
    end_date: "",
    dept_id:"user_name",
  });
  
  const {
    data: dataListDepartemenDefectDetail = {},
    refetch: refetchListDepartemenDefectDetail,
    isError: isErrorDepartemenDefectDetail,
    isFetching: isFetchingDepartemenDefectDetail,
  } = useFetchListDepartemenDefectDetailQuery
  ({deptId: id, ...paramsTableDepartemenDefectDetail});

  

  const columnDepartemenDefectDetail = [
    
    {
      title: "User",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Upload",
      dataIndex: "upload_doc",
      key: "upload_doc",
    },
    {
      title: "Send",
      dataIndex: "send_doc",
      key: "send_doc",
    },
    {
      title: "Stamping",
      dataIndex: "stamp_doc",
      key: "stamp_doc",
    },
    {
      title: "Signing",
      dataIndex: "sign_doc",
      key: "sign_doc",
    },
    {
      title: "Download",
      dataIndex: "download_doc",
      key: "download_doc",

      render: (download_doc) => {
        return numberCommas(download_doc)
      }
    },
    {
      title: "Read",
      dataIndex: "read_doc",
      key: "read_doc",
      render: (read_doc) => {
        return numberCommas(read_doc)
      }
    },  
  ];


  const datasetsListTableDefectDepartemenl = (data = []) => {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTableDepartemenDefectDetail.row * (paramsTableDepartemenDefectDetail.page - 1),
        ...data[i],
      });
    }
    return datasets;
  }

  const handleChangeSearchDepartemenDefectDetail = (e) => {
    setParamsTableDepartemenDefectDetail(prevState => ({
      ...prevState,
      search: e.target.value
    }))
  }

  const handleClickSearchDepartemenDefectDetail = () => {
    //console.log(paramsTableDepartemenDefectDetail)
  }

  const handleResetFilterDepartemenDefectDetail = () => {
    setParamsTableDepartemenDefectDetail(prevState => ({
      ...prevState,
      sort_by: "created_at",
      order: "desc",
      search: "",
      start_date: "",
      end_date: "",
      office_id:"",
      office_name:"",
    }))
  }

  const menuFilterDepartemenDefectDetail = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
          <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-blue-500"
              onClick={handleResetFilterDepartemenDefectDetail}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Date</p>
            <DatePicker.RangePicker
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(value) => {
                setParamsTableDepartemenDefectDetail({ 
                  ...paramsTableDepartemenDefectDetail, 
                  start_date: moment(value[0]).utc().format().toString(),
                  end_date: moment(value[1]).utc().format().toString(), 
                })
              }}
              value={[
                paramsTableDepartemenDefectDetail.start_date ? moment(paramsTableDepartemenDefectDetail.start_date) : "", 
                paramsTableDepartemenDefectDetail.end_date ? moment(paramsTableDepartemenDefectDetail.end_date) : ""
              ]}
            />
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort Type</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) => {
                setParamsTableDepartemenDefectDetail({ 
                  ...paramsTableDepartemenDefectDetail, 
                  order: e.target.value 
                })
              }}
              value={paramsTableDepartemenDefectDetail.order}
            >
              <Radio value="asc">Ascending</Radio>
              <Radio value="desc">Descending</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const handleChangePaginationDepartemenDefectDetail = (pagination) => {
    setParamsTableDepartemenDefectDetail(prevState => ({
      ...prevState,
      page: pagination.current,
      row: pagination.pageSize,
    }));
  }

  const handleClickExportExcel = async () => {
    const response = await getBlob(`/overview/doc-dep-activity-detail?deptId=${id}&export_format=excel`);
    exportExcel(response.data, "Detail Departemen Activity");
  }

  const handleClickExportPdf = async () => {
    // const response = await getBlob(`/overview/doc-dep-activity-detail?deptId=${id}&export_format=pdf`);
    // exportPdfFromExcel(response.data, "Detail Departemen Activity");
  }

  /* CURRENTLY COMMENTED UNTIL BACKEND API IS READY */

  useEffect(() => {
    if (isErrorDepartemenDefectDetail) errorMessage(columnDepartemenDefectDetail);
  }, [isErrorDepartemenDefectDetail, columnDepartemenDefectDetail]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} align="middle">
            <Col>
              <div
                className="group cursor-pointer"
                onClick={() => history.goBack()}
              >
                <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
              </div>
            </Col>
            <Col>
              <h1 className="text-xl md:text-2xl font-medium">
                {location?.state?.office_name}
              </h1>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between" align="middle">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      Detail Departemen Activity
                    </h3>
                  </Col>
                  <Col className="flex gap-2">
                    <Search
                      placeholder="Search"
                      className="rounded-full"
                      allowClear
                      onSearch={handleClickSearchDepartemenDefectDetail}
                      onChange={handleChangeSearchDepartemenDefectDetail}
                    />
                    <Dropdown
                      overlay={menuFilterDepartemenDefectDetail}
                      onVisibleChange={() => {
                        setIsVisibleFilterDepartemenDefectDetail(prevState => !prevState)
                      }}
                      visible={isVisibleFilterDepartemenDefectDetail}
                      trigger={["click"]}
                    >
                      <Button
                        type="primary"
                        ghost
                        icon={<FilterOutlined />}
                        />
                    </Dropdown>
                    <Button
                      type="text"
                      className="text-white hover:text-white"
                      style={{ background: "#8BC34A" }}
                      icon={<SiMicrosoftexcel />}
                      onClick={handleClickExportExcel}
                    >
                      Export Excel
                    </Button>
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          type="text"
                          className="text-white hover:text-white"
                          style={{ background: "#E9290F" }}
                          icon={<MdPictureAsPdf />}
                        >
                          Export PDF
                        </Button>
                      )}
                      content={() => deptActivityRef.current}
                      copyStyles
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <div className="hidden">
                  <div ref={deptActivityRef} className="p-12">
                    <Table
                      columns={columnDepartemenDefectDetail}
                      dataSource={dataListDepartemenDefectDetail.data?.map((data, i) => ({no: i + 1, ...data}))}
                      loading={isFetchingDepartemenDefectDetail}
                      scroll={{ x: 600 }}
                      pagination={false}
                      onChange={handleChangePaginationDepartemenDefectDetail}
                    />
                  </div>
                </div>
                <Table
                    columns={columnDepartemenDefectDetail}
                    dataSource={datasetsListTableDefectDepartemenl(dataListDepartemenDefectDetail.data)}
                    loading={isFetchingDepartemenDefectDetail}
                    scroll={{ x: 600 }}
                    pagination={{
                      current: paramsTableDepartemenDefectDetail.page,
                      pageSize: paramsTableDepartemenDefectDetail.row,
                      total: dataListDepartemenDefectDetail?.total || 0,
                    }}
                    onChange={handleChangePaginationDepartemenDefectDetail}
                  />
              </Col>
              
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}
