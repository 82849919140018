export const getLastArrayValue = (array) => {

    if (array.length === 0) {
        return 0
    }

    return array[array.length - 1]

}
export const summarizeVal = (array, key) => {
    if (!key) {
        return array.reduce((prev, current) => prev + current)
    }
    if (array.length === 1) {
        return Number(array[0][key])
    }
    return array.reduce((prev, current, index) => {
        if (index == 1) {
            return Number(prev[key]) + Number(current[key])
        }
        return Number(prev) + Number(current[key])
    })
}
export const getMonthName = (index) => {
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ]
    return months[index]
}
export const dataDistribution = (part, max, data) => {
    const partition = data.length / max
    const start = Math.round(partition * part)
    const end = Math.round(start + partition)
    return data.slice(start, end)
}