import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { rootApi, access } from "../config";

export const officeApi = createApi({
  reducerPath: "api/office",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi,
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      fetchListOffice: builder.query({
        query({
          page = 1,
          sort_by = "office_name",
          order = "asc",
          row = 10,
          search = "",
          hq = 2,
        }) {
          return `/office?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}&hq=${hq}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchListOfficeType: builder.query({
        query() {
          return "/office-types";
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchListOfficePic: builder.query({
        query(office_id) {
          return `/pic-branch${office_id ? `?office=${office_id}` : ""}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchOfficeDetail: builder.query({
        query(office_id) {
          return `/office/${office_id}`;
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      addOffice: builder.mutation({
        query: (body) => ({
          url: "/office",
          method: "POST",
          body,
        }),
      }),

      deleteOffice: builder.mutation({
        query: (officeId) => ({
          url: `/office/${officeId}`,
          method: "DELETE",
        }),
      }),

      editOffice: builder.mutation({
        query: ({ officeId, body }) => ({
          url: `/office/${officeId}`,
          method: "PUT",
          body,
        }),
      }),
      uploadOfficeLogo: builder.mutation({
        query: (body) => ({
          url: "/office/upload-logo",
          method: "POST",
          body
        })
      })
    };
  },
});

export const {
  useFetchOfficeDetailQuery,
  useFetchListOfficeQuery,
  useFetchListOfficeTypeQuery,
  useFetchListOfficePicQuery,
  useAddOfficeMutation,
  useDeleteOfficeMutation,
  useEditOfficeMutation,
  useUploadOfficeLogoMutation
} = officeApi;
