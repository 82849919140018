import { CloseOutlined, LeftOutlined, ArrowsAltOutlined } from "@ant-design/icons";
import {
  Button as ButtonAntd,
  Col,
  Collapse,
  Divider,
  Input,
  message,
  Row,
  Select,
  Tooltip,
  Form,
  Modal as ModalAntd
} from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import Draggable from "react-draggable";
import OtpInput from "react-otp-input";
import { Document, Page } from "react-pdf";
import Fade from "react-reveal/Fade";
import { useHistory, useLocation, useParams } from "react-router-dom";
import QrCodeSVG, {
  ReactComponent as QrCodeIcon,
} from "../../assets/icons/qrcode.svg";
import EditIconYellow from '../../assets/img/icon/edit-doc-yellow.png'
import EditIconBlack from '../../assets/img/icon/edit-doc-black.png'
import google1 from '../../assets/img/google-1.png'
import google2 from '../../assets/img/google-2.png'
import safari1 from '../../assets/img/safari1.png'
import safari2 from '../../assets/img/safari2.png'
import safari3 from '../../assets/img/safari3.png'
import firefox1 from '../../assets/img/firefox1.png'
import firefox2 from '../../assets/img/firefox2.png'
import arrowRight from '../../assets/img/icon/right-arrow.png'
import arrowBtm from '../../assets/img/icon/arrow-btm.png'
import arrowUp from '../../assets/img/icon/arrow-up.png'

import { Button, Modal, Spinner } from "../../components";
import { useFetchQuotaQuery, useSendOtpMutation } from "../../config/features";
import { useFetchDetailDocumentQuery } from "../../config/features/document";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { useAddBulkStampMutation, useFetchListJenisDocumentQuery } from "../../config/features/peruri";
import { errorMessage, getData, getPosition, numberCommas } from "../../utils";
import { extractEmails } from "../../utils/regex";
import _ from 'lodash';
import { PDFDocument, degrees } from "pdf-lib";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { rootApi } from "../../config/features/config";

// Dynamic import for stamp image based on env variable
// This is because prod and others has different stmap image
let imgStamp;
import(`../../assets/img/env/${process.env.REACT_APP_ROOT_ENV}/e-meterai.png`)
  .then((module) => {
    imgStamp = module.default;
  });

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;

export default function BulkStamp() {
  const history = useHistory();
  const tokenAccess = useMemo(() => localStorage.getItem('access'), [])
  const location = useLocation();
  const { id: doc_id } = useParams();
  const { t } = useTranslation();
  const [totalQuota, setTotalQuota] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [originalPdfFile, setOriginalPdfFile] = useState([]);
  const [pdfFile, setPdfFile] = useState([]);
  const [idDocument, setIdDocument] = useState(null);
  const [stampImg, setStampImg] = useState(null)


  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  let [activeDrags, setActiveDrags] = useState({});

  const [activeDeltaPosition, setActiveDeltaPosition] = useState({ x: 351, y: -520 });
  const [deltaPosition, setDeltaPosition] = useState([]);

  const [stampPosition, setStampPosition] = useState({});
  const [totalStampPosition, setTotalStampPosition] = useState([]);

  const [size, setSize] = useState({ x: 100, y: 100 });
  const [sizing, setSizing] = useState({
    width: 100,
    height: 100,
  });
  let [totalStamp, setTotalStamp] = useState([]);
  const [moveable] = useState(true);
  const [reloadDocument, setReloadDocument] = useState(0);

  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalOtp, setIsModalOtp] = useState(false);
  const [isModalDocType, setIsModalDocType] = useState(false);
  const [isLocationBlocked, setIsLocationBlocked] = useState(false)
  const [guideModal, setGuideModal] = useState({ guide1: false, guide2: false, guide3: false, })

  //bypass OTP Modal
  const [isModalConfirm2, setIsModalConfirm2] = useState(false);
  const toggleModalConfirm2 = () => {
    setIsModalConfirm2(!isModalConfirm2);
  }

  const [docType, setDocType] = useState(location?.state?.docType)
  const [otpValue, setOtpValue] = useState("");
  const [isFirstOtpSent, setIsFirstOtpSent] = useState(false);
  const [countdownValue, setCountdownValue] = useState(Date.now());
  const countdownRef = useRef();

  const [isLoadingPreview, setIsLoadingPreview] = useState(false)
  const [userLocation, setUserLocation] = useState(null)
  const [activeLocation, setActiveLocation] = useState(false)

  const [
    sendOtp,
    { data: dataOtp, isError: isErrorOtp, error: errorOtp, isLoading: isLoadingOtp },
  ] = useSendOtpMutation();

  const [
    addBulkStamp,
    {
      isSuccess,
      data: responseAddStamp,
      isError,
      error: errorStamp,
      isLoading,
    },
  ] = useAddBulkStampMutation();

  const {
    data: dataDetail = {},
    isFetching,
    isError: isErrorDetailDoc,
    error: errorDetailDoc,
    refetch: refetchDetailDocument,
    isSuccess: isSuccessDetail,
  } = useFetchDetailDocumentQuery({ id: doc_id, is_folder: 1 });

  const { data: userProfile = {} } = useFetchProfileUserQuery();

  const { data: dataQuota, refetch: refetchQuota } = useFetchQuotaQuery();

  const { data: dataListDocType, refetch: refetchDocType } = useFetchListJenisDocumentQuery()

  const getMeteraiQuota = useCallback(() => {
    for (let i = 0; i < dataQuota?.length; i++) {
      if (dataQuota[i]?.id === "1") {
        return dataQuota[i]?.remaining;
      }
    }
  }, [dataQuota])

  useEffect(() => {
    setTotalQuota(getMeteraiQuota())
  }, [getMeteraiQuota])


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const normalizeCoordinates = (x, y, rotation, pageWidth, pageHeight) => {
    let newX, newY;
    switch (rotation % 360) {
      case 90:
        // 90 degrees: Origin at the top-left corner
        newX = pageWidth - y;
        newY = x;
        break;
      case 180:
        // 180 degrees: Origin at the top-right corner
        newX = pageWidth - x;
        newY = pageHeight - y;
        break;
      case 270:
        // 270 degrees: Origin at the bottom-right corner
        newX = y;
        newY = pageHeight - x;
        break;
      case 0:
      default:
        // 0 degrees: Default coordinate system
        newX = x;
        newY = y;
        break;
    }
    return { x: newX, y: newY }
  }

  const getEmbededDocUint8Array = async (existingPdfBytes, currentDeltaPosition) => {
    // if doesn't have currentDeltaPosition then use deltaPosition in state
    // current delta position is used when there is new stamping ( refer to modifyPdf() )
    // delta position is state is used if no new stamping
    const dPos = currentDeltaPosition ? currentDeltaPosition : deltaPosition

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const res = await fetch(stampImg)
    const pngImageBytes = await res.arrayBuffer()

    const pngImage = await pdfDoc.embedPng(pngImageBytes);

    const pages = pdfDoc.getPages();
    const pageDocument = pages[pageNumber - 1];

    const rotation = pageDocument.getRotation().angle;
    const { width, height } = pageDocument.getSize();

    for (let i = 0; i < dPos.length; i++) {
      const deltaPositionY = dPos[i].y < 0
        ? Math.abs(dPos[i].y)
        : -Math.abs(dPos[i].y);
      const deltaPositionX = dPos[i].x;

      const { x: x_pos, y: y_pos } = normalizeCoordinates(deltaPositionX, deltaPositionY - sizing?.height, rotation, width, height);

      pageDocument.drawImage(pngImage, {
        x: x_pos,
        y: y_pos,
        width: sizing?.width,
        height: sizing?.height,
        rotate: degrees(rotation)
      });
    }

    const pdfBytes = await pdfDoc.save();
    const arr = new Uint8Array(pdfBytes);

    return arr
  }

  //modify existing pdf file by buffer/bytes and id
  const modifyPdf = async (existingPdfBytes, id, currentDeltaPosition) => {
    const arr = await getEmbededDocUint8Array(existingPdfBytes, currentDeltaPosition)
    const prevPdf = { ...pdfFile };
    prevPdf[id].buffer = arr.buffer;
    setPdfFile(prevPdf);
  }

  const handleAddStamp = (index) => {
    const isInsufficient = Number(totalDocs) > totalQuota
    if (isInsufficient) {
      message.error(t("You dont have enough quota"));
      return
    }

    setActiveDeltaPosition({ x: 351, y: -520 });
    setTotalStamp([...totalStamp, index]);
  }

  const handleCloseStamp = () => {
    setActiveDeltaPosition({})
    setTotalStamp([])
  }

  const handleStart = (e) => {
    setActiveDrags({ ...activeDrags, [e]: ++activeDrags });
  };

  const handleStop = (e, position, index) => {
    setActiveDrags({ ...activeDrags, [index]: --activeDrags });

    const { x, y } = position;
    setActiveDeltaPosition({ x, y })
  };

  const handleSave = async () => {
    setTotalQuota(prevState => Number(prevState) - Number(totalDocs))
     
    for (let i = 0; i < Object.keys(pdfFile).length; i++) {
      const id = Object.keys(pdfFile)[i];
      modifyPdf(
        pdfFile[id]?.buffer,
        id,
        [activeDeltaPosition],
      );
    }
    setTotalStamp([]);
    // Add new deltaPosition for pdf preview
    // Add new stamping data to send to backend
    // llx : lower left x coordinate
    // lly : lower left y coordinate
    // urx : upper right x coordinate
    // ury : upper right y coordinate
    
    
    const first_id = Object.keys(pdfFile)[0];
    const pdfDoc = await PDFDocument.load(pdfFile[first_id]?.buffer);
    const pages = pdfDoc.getPages();
    const pageDocument = pages[pageNumber - 1]
    
    const rotation = pageDocument.getRotation().angle;
    const { width, height } = pageDocument.getSize();
    // Set Stamp Position With Normalized Coordinates
    
    const deltaPositionY = activeDeltaPosition.y < 0 ? Math.abs(activeDeltaPosition.y) : -Math.abs(activeDeltaPosition.y);
    const deltaPositionX = activeDeltaPosition.x;
    const { x, y } = normalizeCoordinates(deltaPositionX, deltaPositionY - sizing?.height, rotation, width, height )
    
    setDeltaPosition(prev => [...prev, {x, y}])

    setTotalStampPosition((prevPosition) => [...prevPosition, {
      llx: Math.abs(deltaPositionX),
      lly: Math.abs(deltaPositionY) - sizing?.height,
      urx: Math.abs(activeDeltaPosition.x) + sizing?.width,
      ury: Math.abs(activeDeltaPosition.y),
      page: pageNumber
    }]);
  };

  const handleReset = () => {
    setPdfFile(_.cloneDeep(originalPdfFile))
    setTotalStamp([])
    setTotalStampPosition([]);
    setDeltaPosition([])
    setStampPosition([])
    setTotalQuota(getMeteraiQuota())
  }

  const handleChangePage = (e) => {
    setPageNumber(e);
  }

  const clearOtp = () => {
    setOtpValue("");
  }

  const handleSubmit = (values) => {
    try {
      if (!isLoading) {
        addBulkStamp({
          doc_id,
          body: {
            jenis_doc: docType,
            reason: docType,
            city: (userLocation !== '' || userLocation !== undefined) ? `${userLocation?.city},${userLocation?.country}` : '',
            otp: parseInt(otpValue),
            note: values.note,
            stamps: totalStampPosition,
          },
        });
        if (userProfile?.storage_credential?.bypass_otp != "yes") {
          toggleModalOtp();
        }
      }
    } catch (error) {
      message.error(error.message);
    }
  }

  const toggleModalConfirm = () => {
    setIsModalConfirm(!isModalConfirm);
  }

  const toggleModalOtp = () => {
    setIsModalOtp(!isModalOtp);
  }



  const handleSendOtp = async (props) => {
    try {
      // Make sure NOT to sent another otp on click "Next"
      setIsFirstOtpSent(true)
      message.loading({ content: "Sending the OTP", key: "otpMsg" });
      await sendOtp({ folder_id: doc_id, ...props });
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleModalDocType() {
    setIsModalDocType(false)
  }

  function onFinish(values) {
    setIsModalDocType(false)
    setDocType(values.docType)

  }

  const getLocation = async () => {

    setActiveLocation(false)

    const response = await getPosition()

    if (typeof response === 'object') {
      setUserLocation(response)
      // //console.log(userProfile?.storage_credential.bypass_otp,"Crede");
      if (userProfile?.storage_credential?.bypass_otp != "yes") {
        toggleModalConfirm()
      } else {
        toggleModalConfirm2()
      }
      // toggleModalConfirm2()
      return
    }

    setUserLocation('')
    setIsLocationBlocked(true)

  }

  const getMeteraiSpeciment = async () => {
    setIsLoadingPreview(true)
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${rootApi}/platform/get-speciment`,
        headers: {
          token: tokenAccess
        }
      })
      setStampImg(data.data.base64Speciment)
      setIsLoadingPreview(false)
    } catch (e) {
      setStampImg(imgStamp)
      setIsLoadingPreview(false)
      if (e.response) {
        const { data: { error } } = e.response
        // message.error(error)
        console.log(error);
      }
      else {
        // message.error(e)
        console.log(e);
      }
    }
  }

  useEffect(() => {
    getMeteraiSpeciment()
  }, [])

  useEffect(() => {
    if (dataOtp?.status === "success") {
      const email = extractEmails(dataOtp?.data)[0]
      const success_message = dataOtp?.data.replace(email, '')
      const response_message = `${t(success_message)}${email}`
      message.success({
        content: response_message || t("Success send OTP, please check your email."),
        key: "otpMsg"
      })
    }
  }, [dataOtp])

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <ButtonAntd
          onClick={() => {
            setCountdownValue(Date.now() + 60000)
            setTimeout(() => {
              countdownRef.current.api.start()
            }, 100)
            handleSendOtp({ resend: true });
          }}
          type="link"
          className="p-0"
        >
          {t("Resend")}
        </ButtonAntd>
      );
    } else {
      return (
        <p>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </p>
      );
    }
  };

  const base64ToArrayBuffer = (base64) => {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  // Initial preview document to be used when reset the stamping
  const getInitialPreviewDocument = useCallback(async (params) => {
    try {
      setIsLoadingPreview(true);
      const res = await getData(`/document/preview/${params?.doc_id}`);
      let documentBuffer = await base64ToArrayBuffer(res.data?.data);

      setOriginalPdfFile((prev) => {
        return {
          ...prev,
          [params?.doc_id]: {
            buffer: documentBuffer,
            name: params?.doc_name,
          },
        };
      });
      setPdfFile((prev) => {
        return {
          ...prev,
          [params?.doc_id]: {
            buffer: documentBuffer,
            name: params?.doc_name,
          },
        };
      });
      setIsLoadingPreview(false);
    } catch (error) {
      setIsLoadingPreview(false);
    }
  }, [])

  const getStampedDoc = async (documentBuffer) => {
    const pdfDoc = await PDFDocument.load(documentBuffer)
    const resStamp = await fetch(stampImg)
    const pngImageBytes = await resStamp.arrayBuffer()
    const pngImage = await pdfDoc.embedPng(pngImageBytes)
    const pages = pdfDoc.getPages()
    totalStampPosition.forEach(item => {
      const pageDocument = pages[item.page - 1]
      const rotation = pageDocument.getRotation().angle;
      const { width: pageWidth, height: pageHeight }=  pageDocument.getSize()
      const { x, y } = normalizeCoordinates(item.llx, item.lly, rotation, pageWidth, pageHeight)
      const width = Math.abs(item.urx - item.llx)
      const height = Math.abs(item.ury - item.lly)
      pageDocument.drawImage(
        pngImage,
        {
          x: x,
          y: y,
          width,
          height,
          rotate: degrees(rotation)
        }
      )
    })
    const pdfBytes = await pdfDoc.save()
    const arr = new Uint8Array(pdfBytes)
    return arr.buffer
  }

  // Get the document to preview when clicked 
  // For performance purpose, we not get and store all document
  // at one time in the beginning

  const getPreviewDocument = async (params) => {
    try {
      setIsLoadingPreview(true);
      const res = await getData(`/document/preview/${params?.doc_id}`);
      const isStamped = totalStampPosition.length > 0;
      let documentBuffer = base64ToArrayBuffer(res.data?.data);
      let stampedDocumentBuffer;


      if (isStamped) {
        stampedDocumentBuffer = await getStampedDoc(documentBuffer)
      }

      setOriginalPdfFile((prev) => {
        return {
          ...prev,
          [params?.doc_id]: {
            buffer: documentBuffer,
            name: params?.doc_name,
          },
        };
      });
      setPdfFile((prev) => {
        return {
          ...prev,
          [params?.doc_id]: {
            buffer: isStamped ? stampedDocumentBuffer : documentBuffer,
            name: params?.doc_name,
          },
        };
      });
      setIsLoadingPreview(false);
    } catch (error) {
      setIsLoadingPreview(false);
    }
  }


  useEffect(() => {
    refetchQuota();
  }, [refetchQuota]);

  useEffect(() => {
    refetchDetailDocument();
  }, [refetchDetailDocument]);

  useEffect(() => {
    if (isSuccess) {
      setIsModalOtp(false);
      history.replace(`/stamp-management/detail-bulk/${responseAddStamp}`);
      clearOtp();
    }
  }, [
    isSuccess,
    history,
    responseAddStamp,
  ]);

  useEffect(() => {
    if (isSuccessDetail) {
      const element = dataDetail?.docs[0];
      getInitialPreviewDocument(element);
      setIdDocument(dataDetail?.docs[0]?.doc_id);
      setTotalDocs(dataDetail?.docs?.length)
    }
  }, [
    isSuccessDetail,
    dataDetail,
    getInitialPreviewDocument,
    reloadDocument,
  ]);

  useEffect(() => {
    if (isError) {
      errorMessage(errorStamp);
      clearOtp();
      if (errorStamp?.data?.error === "Incorrect OTP") {
        setIsModalOtp(true);
      }
    }
    if (isErrorDetailDoc) {
      errorMessage(errorDetailDoc);
    }
    if (isErrorOtp) {
      errorMessage(errorOtp);
    }
  }, [
    isError,
    errorStamp,
    isErrorDetailDoc,
    errorDetailDoc,
    isErrorOtp,
    errorOtp,
  ]);

  const handler0 = (mouseDownEvent) => {
    mouseDownEvent.preventDefault();
    var isDown = true;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const currentSize = size
        const ratio = currentSize.x / currentSize.y
        let resizeValue = 0
        if (currentPosition.x < startPosition.x && currentPosition.y > startPosition.y) {
          // Zoom In
          resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        else if (currentPosition.x > startPosition.x && currentPosition.y < startPosition.y) {
          // Zoom Out
          resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        const currentWidth = currentSize.x + (ratio * resizeValue);
        const currentHeight = currentSize.y + resizeValue;
        setSize({
          x: currentWidth,
          y: currentHeight
        })
        setSizing({
          width: currentWidth, height: currentHeight 
        })
      }
    }
    function onMouseUp() {
      isDown = false
      document.body.removeEventListener("mousemove", onmousemove);
    }
    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }
  const handler1 = (mouseDownEvent) => {
    mouseDownEvent.preventDefault();
    var isDown = true;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        // console.log("HANDLER 1");
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const currentSize = size
        const ratio = currentSize.x / currentSize.y
        let resizeValue = 0
        if (currentPosition.x > startPosition.x && currentPosition.y > startPosition.y) {
          // Zoom In
          resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        else if (currentPosition.x < startPosition.x && currentPosition.y < startPosition.y) {
          // Zoom Out
          resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))          
        }
        const currentWidth = currentSize.x + (ratio * resizeValue);
        const currentHeight = currentSize.y + resizeValue;
        setSize({
          x: currentWidth,
          y: currentHeight
        })
        setSizing({
          width: currentWidth, height: currentHeight
        })
      }
    }
    function onMouseUp() {
      isDown = false
      document.body.removeEventListener("mousemove", onmousemove);
    }
    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }
  const handler2 = (mouseDownEvent) => {
    mouseDownEvent.preventDefault();
    var isDown = true;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const currentSize = size
        const ratio = currentSize.x / currentSize.y
        let resizeValue = 0
        if (currentPosition.x < startPosition.x && currentPosition.y < startPosition.y) {
          // Zoom In
          resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        else if (currentPosition.x > startPosition.x && currentPosition.y > startPosition.y) {
          // Zoom Out
          resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        const currentWidth = currentSize.x + (ratio * resizeValue);
        const currentHeight = currentSize.y + resizeValue;
        setSize({
          x: currentWidth,
          y: currentHeight
        })
        setSizing({
          width: currentWidth, height: currentHeight
        })
      }
    }
    function onMouseUp() {
      isDown = false
      document.body.removeEventListener("mousemove", onmousemove);
    }
    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <Row gutter={[24, 3]}>
                    <Col span={24}>
                      <Tooltip title={location?.state?.doc_name}>
                        <h1 className="text-xl md:text-2xl font-medium truncate w-40 md:w-60 lg:w-96">
                          {location?.state?.doc_name}
                        </h1>
                        <div className="flex">
                          <img width={25} height={25} src={EditIconYellow} />
                          <span className="font-semibold text-yellow-500 mr-2">
                            {t("Stamp Bulk Document")}
                          </span>
                          <img width={25} height={25} src={EditIconBlack} />
                          <span className="font-semibold mr-2">{t("Type")} : {docType} - </span> <span onClick={() => setIsModalDocType(true)} className="text-blue-600 font-semibold cursor-pointer hover:text-blue-400">{t("Edit")}</span>
                        </div>

                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                className="md:w-40"
                type="primary"
                disabled={
                  totalStampPosition?.length < 1 || totalStamp.length > 0
                }
                onClick={() => setActiveLocation(true)}
              >
                {t("Submit")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col xs={0} lg={4} xl={6}>
              <div className="bg-white rounded-tl-md">
                <div className="w-full bg-yellow-400 rounded-tl-md">
                  <p className="text-center text-white text-sm md:text-base py-2">
                    {t("Preview Stamp")}
                  </p>
                </div>
                <div
                  className="overflow-y-auto custom-scrollbar"
                  style={{ height: "80vh" }}
                >
                  <Collapse defaultActiveKey={["1"]} ghost>
                    <Panel
                      className="bg-gray-100 border-l-4 border-blue-500 font-medium"
                      header={dataDetail?.name}
                      key="1"
                    >
                      <div className="relative">
                        <ul className="flex flex-col space-y-2">
                          {dataDetail?.docs?.map((item, index) => {
                            return (
                              <li
                                key={index}
                                onClick={async () => {
                                  const isFilePreviewed = !!pdfFile[item.doc_id]
                                  setIdDocument(item.doc_id)

                                  if (!isFilePreviewed) {
                                    await getPreviewDocument(item);
                                  }
                                }}
                              >
                                <Tooltip title={item.doc_name}>
                                  <p
                                    className={`cursor-pointer rounded-md p-2 hover:bg-gray-300 truncate ${item.doc_id === idDocument &&
                                      "bg-gray-200 font-medium border-l-4 border-gray-800"
                                      }`}
                                  >
                                    {item.doc_name}
                                  </p>
                                </Tooltip>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </Col>
            <Col xs={24} lg={20} xl={18}>
              <div className="relative">
                <div className="w-full bg-gray-600 rounded-tr-md py-1">
                  <Row gutter={[24, 12]} justify="end" align="middle">
                    <Col xs={24} md={8}>
                      {totalStampPosition.length > 0 &&
                        <p className="text-gray-300 text-sm md:text-base">
                          {t("You Have Added")} [{totalStampPosition.length * Number(totalDocs)}] Stamp!
                        </p>
                      }
                    </Col>
                    <Col>
                      <ButtonAntd
                        disabled={totalStampPosition.length < 1}
                        className="rounded-md"
                        type="default"
                        onClick={handleReset}
                      >
                        {t("Reset")}
                      </ButtonAntd>
                    </Col>
                    <Col>
                      <Button
                        disabled={totalStamp.length < 1}
                        type="secondary"
                        onClick={handleSave}
                      >
                        {t("Preview")}
                      </Button>
                    </Col>
                    <Col xs={24} md={8}>
                      <Row
                        className="pr-4 pb-2 md:pb-0"
                        gutter={[24, 24]}
                        justify="center"
                        align="middle"
                      >
                        <Col>
                          <div className="flex flex-row bg-yellow-400 text-white rounded-full px-2 py-1 justify-center items-center">
                            <QrCodeIcon />
                            <Divider className="h-4 bg-white" type="vertical" />
                            <p className="font-medium">
                              {numberCommas(totalQuota)}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div className="flex flex-col space-y-4 justify-end items-end fixed mt-8 right-10 md:right-20 lg:right-32 z-50">
                  {totalStamp.length < 1 && (
                    <div className="flex bg-yellow-400 hover:bg-yellow-300 cursor-pointer text-white rounded-full p-1 justify-center items-center duration-700 transition">
                      <Fade>
                        <img
                          className="transition duration-500 ease-in-out"
                          src={QrCodeSVG}
                          alt="qr-icon"
                          width="40px"
                          onClick={() => handleAddStamp(totalStamp.length)}
                        />
                      </Fade>
                    </div>
                  )}
                </div>
                <div
                  className="bg-gray-300 rounded-b-md overflow-auto custom-scrollbar shadow-md pb-10"
                  style={{ height: "80vh" }}
                >
                  <div className="flex justify-center items-center p-8">
                    <Document
                      file={pdfFile[idDocument]?.buffer}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={pageNumber}>
                        {totalStamp.map((_, index) => {
                          return (
                            <Draggable
                              key={`stamp-${index}`}
                              position={activeDeltaPosition}
                              onStart={moveable ? () => handleStart(index) : false}
                              onStop={(e, position) => handleStop(e, position, index)}
                              bounds="parent"
                            >
                              <div
                                className={`relative border-dashed border-2 border-gray-500 ${moveable ? "cursor-move" : "cursor-pointer"
                                  }`}
                                style={{
                                  width: sizing.width,
                                  height: sizing.height,
                                }}
                              >
                                <div
                                  className="absolute -top-3 -right-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                  onClick={() => handleCloseStamp(index)}
                                >
                                  <CloseOutlined className="font-medium" />
                                </div>
                                <img
                                  src={stampImg}
                                  alt="e-meterai"
                                  style={{ WebkitUserDrag: "none", width: sizing.width, height: sizing.height }}
                                />
                                <div
                                  className="absolute -top-3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                  onMouseDown={handler2}
                                >
                                  <ArrowsAltOutlined style = {{transform: 'rotate(90deg)' }} className="font-medium" />
                                </div>
                                <div
                                  className="absolute -bottom-3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                  onMouseDown={handler0}
                                >
                                  <ArrowsAltOutlined className="font-medium" />
                                </div>
                                <div
                                  className="absolute -bottom-3 -right-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                  onMouseDown={handler1}
                                >
                                  <ArrowsAltOutlined style = {{transform: 'rotate(90deg)' }} className="font-medium" />
                                </div>
                              </div>
                            </Draggable>
                          );
                        })}
                      </Page>
                    </Document>
                    <div className="flex flex-row justify-center items-center space-x-2 bg-gray-100 shadow-md absolute bottom-5 right-5 p-2 rounded-md">
                      <Select
                        value={pageNumber}
                        size="small"
                        onChange={handleChangePage}
                      >
                        {Array.from(new Array(numPages), (_, index) => (
                          <Option value={index + 1}>{index + 1}</Option>
                        ))}
                      </Select>
                      <p>{`dari ${numPages} halaman`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* </div> */}
        </Col>
      </Row>

      <Modal
        title={t("Are you sure?")}
        subtitle={t("After the data has been submit, you cannot change the data. Please make sure the data is correct.")}
        type="warning"
        visible={isModalConfirm}
        onCancel={toggleModalConfirm}
        page={1}
        totalPage={2}
      >
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Document Name")}</p>
            <div className="w-2" />
            <ul className="flex flex-col space-y-1 max-h-40 overflow-y-auto custom-scrollbar">
              {dataDetail?.docs?.map((item, index) => {
                return (
                  <li key={index}>
                    <Tooltip title={item.doc_name}>
                      <p className="text-gray-800 truncate">{item.doc_name}</p>
                    </Tooltip>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Stamp Category")}</p>
            <div className="w-12" />
            <Tooltip title={t("Stamp Bulk On Document")}>
              <p className="text-gray-800 truncate">{t("Stamp Bulk On Document")}</p>
            </Tooltip>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Document Type")}</p>
            <div className="w-12" />
            <Tooltip title={docType}>
              <p className="text-gray-800 truncate">{docType}</p>
            </Tooltip>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Total Stamp")}</p>
            <div className="w-12" />
            <Tooltip title={`${dataDetail?.docs?.length * totalStampPosition?.length} Stamp`}>
              <p className="text-gray-800 truncate">
                {dataDetail?.docs?.length * totalStampPosition?.length} Stamp
              </p>
            </Tooltip>
          </div>
          <Divider />
        </div>
        <div className="flex flex-row space-x-4">
          <Button
            onClick={toggleModalConfirm}
            className="w-full"
            ghost
            type="secondary"
          >
            {t("Back")}
          </Button>
          <Button
            onClick={() => {
              toggleModalConfirm();
              toggleModalOtp();

              if (!isFirstOtpSent) {
                handleSendOtp();
                // Set the countdown timer to enable resend button
                setCountdownValue(Date.now() + 60000)
                setTimeout(() => {
                  countdownRef.current.api.start()
                }, 100)
              }
            }}
            className="w-full"
            type="primary"
          >
            {t("Next")}
          </Button>
        </div>
      </Modal>

      <Modal
        title={t("Are you sure?")}
        subtitle={t("After the data has been submit, you cannot change the data. Please make sure the data is correct.")}
        type="warning"
        visible={isModalConfirm2}
        onCancel={toggleModalConfirm2}
        page={1}
        totalPage={1}
      >
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Document Name")}</p>
            <div className="w-2" />
            <ul className="flex flex-col space-y-1 max-h-40 overflow-y-auto custom-scrollbar">
              {dataDetail?.docs?.map((item, index) => {
                return (
                  <li key={index}>
                    <Tooltip title={item.doc_name}>
                      <p className="text-gray-800 truncate">{item.doc_name}</p>
                    </Tooltip>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Stamp Category")}</p>
            <div className="w-12" />
            <Tooltip title={t("Stamp Bulk On Document")}>
              <p className="text-gray-800 truncate">{t("Stamp Bulk On Document")}</p>
            </Tooltip>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Document Type")}</p>
            <div className="w-12" />
            <Tooltip title={docType}>
              <p className="text-gray-800 truncate">{docType}</p>
            </Tooltip>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">Total Stamp</p>
            <div className="w-12" />
            <Tooltip title={`${dataDetail?.docs?.length * totalStampPosition?.length} Stamp`}>
              <p className="text-gray-800 truncate">
                {dataDetail?.docs?.length * totalStampPosition?.length} Stamp
              </p>
            </Tooltip>
          </div>
          <Divider />
        </div>
        <Form onFinish={handleSubmit} autoComplete={false}>
          <div>
            <p>{t("Note (Optional)")}</p>
            <Form.Item name="note">
              <TextArea name="note" rows={4} />
            </Form.Item>
          </div>
          <Form.Item>
            <div className="flex flex-row space-x-4">
              <Button
                onClick={() => {

                  toggleModalConfirm2();
                }}
                className="w-full"
                ghost
                type="secondary"
              >
                {t("Back")}
              </Button>
              <Button
                className="w-full"
                type="primary"
                htmlType="submit"
                disabled={isLoading}
              >
                {t("Submit")}
              </Button>
            </div>
          </Form.Item>
          <Spinner loading={isLoading}/>
        </Form>
      </Modal>

      <Modal
        title={t("Are you sure?")}
        subtitle={`${t("Please make sure your data is correct. OTP Code automatic has sent to your email, let’s check!")} ${userProfile?.email}`}
        type="warning"
        visible={isModalOtp}
        onCancel={toggleModalOtp}
        page={2}
        totalPage={2}
      >
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row justify-between">
            <p>{t("OTP Code")}</p>
            <Countdown
              date={countdownValue}
              renderer={renderer}
              ref={countdownRef} />
          </div>
          <OtpInput
            value={otpValue}
            onChange={(otp) => setOtpValue(otp)}
            numInputs={6}
            isInputNum
            className="text-gray-800"
            containerStyle={{
              display: "flex",
              justifyContent: "space-between",
            }}
            inputStyle={{
              width: "3rem",
              height: "3rem",
              fontWeight: "bold",
              border: "2px solid #DBDBDB",
              borderRadius: 4,
              color: "#000000",
            }}
          />
          <Form onFinish={handleSubmit} autoComplete={false}>
            <div>
              <p>{t("Note (Optional)")}</p>
              <Form.Item name="note">
                <TextArea name="note" rows={4} />
              </Form.Item>
            </div>
            <Form.Item>
              <div className="flex flex-row space-x-4">
                <Button
                  onClick={() => {
                    toggleModalOtp();
                    toggleModalConfirm();
                  }}
                  className="w-full"
                  ghost
                  type="secondary"
                >
                  {t("Back")}
                </Button>
                <Button
                  disabled={otpValue?.length < 6}
                  className="w-full"
                  type="primary"
                  htmlType="submit"
                >
                  {t("Submit")}
                </Button>
              </div>
            </Form.Item>
          </Form>
          <Divider />
          <div className="text-center">
            <p className="text-gray-500 text-xs">
              {t("By pressing the ‘Submit’ button means that you have agreed to the")}
            </p>
            <p className="text-blue-500 text-xs">{t("Terms & Conditions")}</p>
          </div>
        </div>
      </Modal>
      <Modal
        visible={activeLocation}
        type="warning"
        title={t("Confirmation Location")}
        subtitle={t("For more accurate data certificate in document, turn on device location.")}
        onCancel={() => setActiveLocation(false)}
      >
        <div className="flex justify-center">
          <Button onClick={() => setActiveLocation(false)} ghost type="primary" className="mt-9 mb-9 h-10 rounded-md w-52 tracking-tight font-medium mr-4">{t("No")}</Button>
          <Button onClick={() => getLocation()} type="primary" className="mt-9 mb-9 h-10 rounded-md w-52 tracking-tight font-medium">{t("Yes")}</Button>
        </div>
      </Modal>

      <Modal
        visible={isModalDocType}
        onCancel={handleModalDocType}
        type="warning"
        subtitle={t("Choose Document Type")}
        title={t("Update Document Type")}
        width={500}
      >
        <Form onFinish={onFinish}>
          <Row align="midle">
            <Col md={24}>
              <Form.Item
                name="docType"
                initialValue={docType}
                rules={[{ required: true, message: t('Please select document type') }]}
              >
                <Select placeholder="Choose" value={docType} className="mt-4">
                  {
                    dataListDocType?.length > 0 && dataListDocType?.map((data , index) => {
                      return (
                        <Option key={index} value={data.nama}>
                          {data.nama}
                        </Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
              <div className="flex justify-center">
                <Button onClick={handleModalDocType} ghost type="primary" className="mt-9 mb-9 h-10 rounded-md w-full tracking-tight font-medium mr-4">{t("No")}</Button>
                <Button htmlType="submit" type="primary" className="mt-9 mb-9 h-10 rounded-md w-full tracking-tight font-medium">{t("Yes")}</Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>


      <ModalAntd
        footer={false}
        visible={isLocationBlocked}
        width={1050}
        onCancel={() => setIsLocationBlocked(false)}
      >
        <div className='mb-6 pt-2 pb-2'>
          <div className="mb-6">
            <h4 className="text-xl font-semibold text-gray-600 mb-5">{t("Location Not Found")}</h4>
            <hr />
          </div>
          <p className='font-medium text-base text-left mt-2 text-gray-600'>{t("Your location was not found. You need to enable GPS permission to be able to Stamp documents. You can do some of the steps below. !")}</p>
          <div onClick={() => setGuideModal(prev => ({ ...prev, guide1: !prev.guide1 }))} className='mt-5 cursor-pointer'>
            <div className="flex justify-between items-center">
              <p className='text-xl font-bold'>{t("Access Google Chrome")}</p>
              <img width={18} height={15} src={guideModal.guide1 === true ? arrowUp : arrowBtm} alt="" />
            </div>
            {
              guideModal.guide1 &&
              <div className='mt-2'>
                <p className='text-black font-medium text-base'>{t("1. Logo location on the searchbar")}</p>
                <p className='text-black font-medium text-base'>{t("2. Change the option to “Allow this site access your location”")}</p>
                <div className="mt-2 flex items-center">
                  <img src={google1} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={google2} alt="" />
                </div>
              </div>
            }
            <hr className="mt-4 h-1" />
          </div>
          <div onClick={() => setGuideModal(prev => ({ ...prev, guide2: !prev.guide2 }))} className='mt-2 cursor-pointer'>
            <div className="flex justify-between items-center">
              <p className='text-xl font-bold'>{t("Access With Safari")}</p>
              <img width={18} height={15} src={guideModal.guide2 === true ? arrowUp : arrowBtm} alt="" />
            </div>
            {
              guideModal.guide2 &&
              <div className='mt-2'>
                <p className='text-black font-medium text-base'>{t("1. Safari menu")}</p>
                <p className='text-black font-medium text-base'>{t("2. Settings for [domain name, Co: demo.vas.id]")} </p>
                <p className='text-black font-medium text-base'>{t("3. Deactivate the “Enable content blockers” checkbox")}</p>
                <div className="mt-2 flex items-center">
                  <img width={225} height={215} src={safari1} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={safari2} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={safari3} alt="" />
                </div>
              </div>
            }
            <hr className="mt-4 h-1" />
          </div>
          <div onClick={() => setGuideModal(prev => ({ ...prev, guide3: !prev.guide3 }))} className='mt-2 cursor-pointer'>
            <div className="flex justify-between items-center">
              <p className='text-xl font-bold'>{t("Access With Firefox")}</p>
              <img width={18} height={15} src={guideModal.guide3 === true ? arrowUp : arrowBtm} alt="" />
            </div>
            {
              guideModal.guide3 &&
              <div className='mt-2'>
                <p className='text-black font-medium text-base'>{t("1. Logo location on the searchbar")} </p>
                <p className='text-black font-medium text-base'>{t("2. Delete the option “Block temporarily”")} </p>
                <div className="mt-2 flex items-center">
                  <img src={firefox1} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={firefox2} alt="" />
                </div>
              </div>
            }
          </div>
        </div>
        <div className="w-full">
          <hr className='mb-4' />
          <div className="flex justify-center">
            <Button
              className="bg-blue-700 rounded-md text-white w-40"
              onClick={() => setIsLocationBlocked(false)}
            >
              {t("Close")}
            </Button>
          </div>
        </div>
      </ModalAntd>
      <Spinner loading={isLoading || isFetching || isLoadingOtp || isLoadingPreview} />
    </>
  );
}
