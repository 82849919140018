import { LeftOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Card, Col, Row, Skeleton, Tooltip } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useFetchDetailReceivedQuery } from "../../config/features/document";
import { errorMessage } from "../../utils";

export default function DocumentDetailReceived() {
  const history = useHistory();
  const { id } = useParams();
  const {
    data: detailMessage = {},
    isFetching,
    isError,
    error,
  } = useFetchDetailReceivedQuery(id);

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
  }, [isError, error]);

  function handleDetailDocument(id) {
    history.push(`/document-management/detail/${id}`);
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.push("/document-message")}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    Document Detail
                  </h1>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Skeleton loading={isFetching} active paragraph={{ rows: 6 }}>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <h3 className="text-gray-500 text-base md:text-lg font-medium">
                    {detailMessage?.subject}
                  </h3>
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 24]} justify="space-between" align="middle">
                    <Col>
                      <Row gutter={[24, 24]}>
                        <Col>
                          <Row gutter={[24, 24]}>
                            <Col xs={4} md={3} lg={2}>
                              <img src="../../images/avatar.png" alt="avatar" />
                            </Col>
                            <Col>
                              <Row gutter={[24, 0]}>
                                <Col span={24}>
                                  <p className="text-sm font-semibold">
                                    {detailMessage?.sender?.split("@")[0]}
                                    <span className="ml-2 text-xs font-normal text-gray-400">
                                      {detailMessage?.sender}
                                    </span>
                                  </p>
                                </Col>
                                <Col span={24}>
                                  <p className="text-xs text-gray-400">
                                    To :{" "}
                                    {detailMessage?.recipient_to?.map(
                                      (item, index) => {
                                        return (
                                          <span key={index}>
                                            {item[1]}
                                            {detailMessage?.recipient_to
                                              ?.length -
                                              1 !==
                                            index
                                              ? ", "
                                              : "."}
                                          </span>
                                        );
                                      }
                                    )}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <p className="text-xs md:text-sm text-gray-400">
                        {moment(
                          detailMessage?.created_at,
                          moment.ISO_8601
                        ).format("DD/MM/YYYY")}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <div>{detailMessage?.message}</div>
                </Col>
                <Col span={24}>
                  <Row gutter={[12, 24]}>
                    {detailMessage?.documents?.map((item, index) => {
                      return (
                        <Tooltip key={index} title={item[1]}>
                          <Col>
                            <div
                              className="flex flex-col justify-center items-center cursor-pointer"
                              onClick={() => handleDetailDocument(item[0])}
                            >
                              <div className="w-full py-3 bg-gray-100 rounded-md flex justify-center items-center">
                                <FilePdfOutlined className="text-3xl md:text-4xl text-red-500" />
                              </div>
                              <p className="w-24 truncate text-xs font-semibold">
                                {item[1]}
                              </p>
                            </div>
                          </Col>
                        </Tooltip>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </Skeleton>
          </Card>
        </Col>
      </Row>
    </>
  );
}
