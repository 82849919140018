import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { rootApi, access } from "../config";
import { objectToQueryString } from "../../../utils/queryString";
import moment from "moment";
import { t } from "i18next";

export const overviewApi = createApi({
  reducerPath: "api/overview",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi,
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      fetchCard: builder.query({
        query({ office_id = "" }) {
          return `/overview/card?office_id=${office_id}`;
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      fetchBar: builder.query({
        query({ date = "", office_id = "all" }) {
          return `/overview/barchart?date=${date}&office_id=${office_id}`;
        },
        transformResponse: (response) =>
          response ? response.data.sort((a, b) => a.date - b.date) : [],
      }),

      fetchBarOffice: builder.query({
        query({ date = "" }) {
          return `/overview/barchart-per-office?date=${date}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchPopularDocument: builder.query({
        query({ date = "", office_id = "", create_at = "", full_name = "", doc_name = "" }) {
          return `/overview/popular-doc?date=${date}&office_id=${office_id}&create_at=${create_at}&full_name=${full_name}&doc_name=${doc_name}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),



      fetchListFilterOffice: builder.query({
        query() {
          return "/ovvw/filter-office";
        },
        transformResponse: (response) =>
          response ? response.data : { dateFilter: [] },
      }),

      fetchListFilterDate: builder.query({
        query() {
          return "/ovvw/filter-date";
        },
        transformResponse: (response) =>
          response ? response.data : { dateFilter: [] },
      }),

      fetchUserActivity: builder.query({
        query({ office_id = "" }) {
          return `/overview/doc-activity?office_id=${office_id}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchDeptActivity: builder.query({

        query({ office_id = "", start_date = "" }) {
          return `/overview/doc-dep-activity?office_id=${office_id}&start_date=${start_date}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchOfficeActivity: builder.query({
        query({ office_id = "" }) {
          return `/overview/doc-office-activity?office_id=${office_id}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchOfficeDeptUsrAct: builder.query({
        query({ office_id = "" }) {
          return `/overview/doc-office-dept-usr-act?office_id=${office_id}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchListDepartemenDefectDetail: builder.query({
        query({
          deptId = "",
          page = 1,
          row = 10,
          sort_by = "create_at",
          order = "asc",
          search = "",
          start_date = "",
          end_date = "",
          user_name = "",
          read_doc = "",
          download_doc = "",
          upload_doc = "",
          send_doc = "",
        }) {
          return `/overview/doc-dep-activity-detail?deptId=${deptId}&page=${page}&row=${row}&sort_by=${sort_by}&order=${order}&search=${search}&start_date=${start_date}&end_date=${end_date}&user_name=${user_name}&read_doc=${read_doc}&download_doc=${download_doc}&upload_doc=${upload_doc}&send_doc=${send_doc}`;
        },
        transformResponse: (response) => (response ? response : []),
      }),
      fetchListUsageMaterai: builder.query({
        query({
          office_id = "",
          start_date = "",
          end_date = ""
        }) {
          return `/overview/usage-materai?office_id=${office_id}&start_date=${start_date}&end_date=${end_date}`;
        },
        transformResponse: (response) => {
          if (!response?.data) {
            return []
          }
          const data = response?.data.map(item => ({...item, period: t(moment(item.period, 'YYYY-MM').format('MMMM')) }))
          return data;
        }
      }),
      fetchListUsageActivities: builder.query({
        query({
          office_id = "",
          row = "",
          page = "",
          year = new Date().getFullYear()
        }) {
          return `/overview/usage-activities?office_id=${office_id}&row=${row}&page=${page}&year=${year}`;
        },
        transformResponse: (response) => (response ? response.data : [])
      }),
      fetchListDetailUsageActivity: builder.query({
        query({
          office_id = "",
          row = "10",
          page = "1",
          period = ""
        }) {
          return `/overview/usage-activity/detail?office_id=${office_id}&row=${row}&page=${page}&period=${period}`
        },
        transformResponse: (response) => (response ? response.data : [])
      }),
      fetchListMonthlyActivities: builder.query({
        query({
          office_id = "",
          row = "",
          page = "",
          year = new Date().getFullYear(),
          monthDate = "",
          office_name = ""
        }) {
          return `/overview/monthly-activities?${objectToQueryString({office_id, year, row, page, monthDate, office_name})}`;
        },
        transformResponse: (response) => (response ? response.data : [])
      })
    };
  },
});

export const {
  useFetchCardQuery,
  useFetchListUsageMateraiQuery,
  useFetchListUsageActivitiesQuery,
  useFetchListDetailUsageActivityQuery,
  useFetchBarOfficeQuery,
  useFetchBarQuery,
  useFetchPopularDocumentQuery,
  useFetchListFilterOfficeQuery,
  useFetchUserActivityQuery,
  useFetchDeptActivityQuery,
  useFetchOfficeActivityQuery,
  useFetchOfficeDeptUsrActQuery,
  useFetchListFilterDateQuery,
  useFetchListDepartemenDefectDetailQuery,
  useFetchListMonthlyActivitiesQuery,
} = overviewApi;
