import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { access, rootApi } from "../config";
import { getSocket } from "../../../utils/socket";

export const notificationApi = createApi({
  reducerPath: "api/notification",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi,
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      notifIsRead: builder.mutation({
        queryFn: (args) => {
          const socket = getSocket();
          return new Promise(resolve => {
            socket.emit("notifIsRead", {user_id: args?.profile?.user_id}, (message) => {
              resolve({ data: true });
            });
          })
        },
        async onQueryStarted(
          { ...args},
          { dispatch, queryFulfilled }
        ) {
          const patchResult = dispatch(
            notificationApi.util.updateQueryData('fetchNotification', {...args}, (draft) => {
              Object.assign(
                draft, 
                { 
                  data: draft?.data?.map(notif => ({...notif, is_read: true})),
                  unread_total: false
                }
              )
            })
          )
          try {
            await queryFulfilled
          } catch {
            patchResult.undo()
          }
        }
      }),
      fetchNotification: builder.query({
        query: ({
          page = 1,
          order = "desc",
          row = 10,
          is_read = "",
          title = ""
        }) => `/notif?page=${page}&order=${order}&row=${row}&is_read=${is_read}&title=${title}`,
        transformResponse: (response) => response,
        async onCacheEntryAdded(
          { profile = {} },
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
        ) {
          try {
            await cacheDataLoaded;

            const socket = getSocket();

            const isBasicAccess = profile?.role_id === 1;
            const isAllDocumentAccess = profile?.role_id === 2;
            const isFullAccess = profile?.role_id === 3;
            const isSuperAdmin = profile?.role_id === 4;
            const isAdminBilling = profile?.role_id === 5;
            const isPic = profile?.is_pic;

            socket.on('connect', () => {
              //console.log("Socket is connect")
              socket.emit("login", { user_id: profile?.user_id })
            });

            const listener = (data) => {
              updateCachedData((draft) => {
                // draft is a proxy of the returned response 
                // in transformResponse
                Object.assign(
                  draft,
                  {
                    data: [data?.data, ...draft?.data],
                    unread_total: data?.unread_total
                  }
                )
              })
            }
            
            if(isSuperAdmin){
              socket.on("notif", listener)
              socket.on("notifAdmin", listener)
            }

            if(isAdminBilling){
              socket.on("notif", listener)
            }

            if(isFullAccess && isPic){
              socket.on(`notifHQ ${profile?.office_id}`, listener)
              socket.on(`notifHQUser ${profile?.office_id}`, listener)
            }

            if((isFullAccess && !isPic) || isBasicAccess || isAllDocumentAccess){
              socket.on(`notifHQUser ${profile?.office_id}`, listener)
            }

            await cacheEntryRemoved;

            // Clean up the connection
            socket.off('connect');
            socket.off("notificationSent");
          } catch(err) {
            //console.log("ERROR:", err)
          }
        }
      }),
      notifIsReadDropdown: builder.mutation({
        queryFn: (args) => {
          const socket = getSocket();
          return new Promise(resolve => {
            socket.emit("notifIsRead", {user_id: args?.profile?.user_id}, (message) => {
              resolve({ data: true });
            });
          })
        },
        async onQueryStarted(
          { ...args},
          { dispatch, queryFulfilled }
        ) {
          const patchResult = dispatch(
            notificationApi.util.updateQueryData('fetchNotificationDropdown', {...args}, (draft) => {
              Object.assign(
                draft, 
                { 
                  data: draft?.data?.map(notif => ({...notif, is_read: true})),
                  unread_total: false
                }
              )
            })
          )
          try {
            await queryFulfilled
          } catch {
            patchResult.undo()
          }
        }
      }),
      fetchNotificationDropdown: builder.query({
        query: ({
          page = 1,
          order = "desc",
          row = 10,
        }) => `/notif?page=${page}&order=${order}&row=${row}`,
        transformResponse: (response) => response,
        async onCacheEntryAdded(
          { profile = {} },
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
        ) {
          try {
            await cacheDataLoaded;

            const socket = getSocket();

            const isBasicAccess = profile?.role_id === 1;
            const isAllDocumentAccess = profile?.role_id === 2;
            const isFullAccess = profile?.role_id === 3;
            const isSuperAdmin = profile?.role_id === 4;
            const isPic = profile?.is_pic;

            socket.on('connect', () => {
              //console.log("Socket is connect")
              socket.emit("login", { user_id: profile?.user_id })
            });

            const listener = (data) => {
              updateCachedData((draft) => {
                // draft is a proxy of the returned response 
                // in transformResponse
                Object.assign(
                  draft,
                  {
                    data: [data?.data, ...draft?.data],
                    unread_total: data?.unread_total
                  }
                )
              })
            }
            
            if(isSuperAdmin){
              socket.on("notif", listener)
              socket.on("notifAdmin", listener)
            }

            if((isBasicAccess || isAllDocumentAccess || isFullAccess) && isPic){
              socket.on(`notifHQ ${profile?.office_id}`, listener)
            }

            if((isBasicAccess || isAllDocumentAccess || isFullAccess) && !isPic){
              socket.on(`notifHQUser ${profile?.office_id}`, listener)
            }

            await cacheEntryRemoved;

            // Clean up the connection
            socket.off('connect');
            socket.off("notificationSent");
          } catch(err) {
            //console.log("ERROR:", err)
          }
        }
      }),
    };
  },
});

export const {
  useFetchNotificationQuery,
  useNotifIsReadMutation,
  useFetchNotificationDropdownQuery,
  useNotifIsReadDropdownMutation,
} = notificationApi;
