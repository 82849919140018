import axios from "axios";

export default function AxiosRequest(method, url, data, params, responseType , peruri ) {
  const { REACT_APP_ROOT_API } = process.env;
  return axios({
    method: method,
    url: REACT_APP_ROOT_API + url,
    data: data,
    params: params,
    headers: {
      token: localStorage.getItem("access"),
     "token-peruri-sign" : peruri ?? localStorage.getItem('peruri') 
    },
    responseType: responseType ? responseType : null,
  });
}

export function getData(url, params, data) {
  return AxiosRequest("GET", url, data, params, null);
}

export function getBlob(url, params ) {
  return AxiosRequest("GET", url, null, params, "arraybuffer");
}

export function postBlob(url, data, params) {
  return AxiosRequest("POST", url, data, params, "arraybuffer");
}

export function postData(url, data, params) {
  return AxiosRequest("POST", url, data, params);
}

export function getBlobPeruri(url, params , peruri = null) {
  return AxiosRequest("GET", url, null, params, null , peruri);
}
