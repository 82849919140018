import { FilterOutlined, RightOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Dropdown,
  Menu,
  Radio,
  Row,
  Select,
  Skeleton,
  Table,
  Tooltip,
} from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ReactComponent as AlertIcon } from "../../assets/icons/alert-icon.svg";
import { Button } from "../../components";
import {
  useFetchActivityClientQuery,
  useFetchBillingHistoryQuery,
  useFetchHeaderQuery,
  useFetchListClientQuery,
  useFetchMonthlySalesQuery,
  useFetchQuotaHQQuery,
  useFetchSalesPerHQQuery,
} from "../../config/features/platform";
import { errorMessage, numberCommas } from "../../utils";
import { useTranslation } from "react-i18next";
import { PRODUCTS } from "../../constant";

const { Option } = Select;

export default function OverviewAdmin() {
  const history = useHistory();
  const { t } = useTranslation();

  const [visibleFilter, setVisibleFilter] = useState(false);

  const [paramsTable] = useState({
    row: 10,
    page: 1,
    office: "",
    sort_by: "created_at",
    order: "desc",
  });

  const [paramsTableBill, setParamsTableBill] = useState({
    row: 5,
    page: 1,
    sort_by: "date",
    order: "desc",
    office_id: "",
  });

  const [paramsTableClientAct, setParamsTableClientAct] = useState({
    office_id: "",
  });

  //filter state
  const [filterDate, setFilterDate] = useState({
    sales_monthly: 6,
    sales_per_hq: Number(moment().format("M")),
  });

  const [paramsChartHq, setParamsChartHq] = useState({
    month: filterDate?.sales_per_hq,
    office_id: "",
    year: moment().format("YYYY"),
  });

  const [paramsQuota, setParamsQuota] = useState({
    office_id: ""
  })

  const {
    data: dataHeader = {},
    isError,
    error,
    isFetching,
  } = useFetchHeaderQuery();

  const { data: dataSalesPerHQ = [] } = useFetchSalesPerHQQuery(paramsChartHq);

  const {
    data: dataQuotasHQ = [],
    isFetching: isFetchingQuota,
    isError: isErrorQuota,
    error: errorQuota
  } = useFetchQuotaHQQuery(paramsQuota)

  const { data: dataBillingHistory, refetch: refetchBill } =
    useFetchBillingHistoryQuery(paramsTableBill);

  const { data: dataMonthlySales = [] } = useFetchMonthlySalesQuery(
    filterDate?.sales_monthly
  );

  const { data: dataClientActivity = [] } =
    useFetchActivityClientQuery(paramsTableClientAct);

  const { data: dataListOffice = {} } = useFetchListClientQuery({
    row: 9999,
    page: 1,
    sort_by: "created_at",
    order: "desc",
  });

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
  }, [isError, error, dataSalesPerHQ]);

  useEffect(() => {
    if (isErrorQuota) {
      errorMessage(errorQuota)
    }
  }, [isErrorQuota, errorQuota])

  const createQuotaDatasets = (data) => {
    const office_id = []
    const office_name = []
    const emeterai = []
    const quota_products = Object.values(PRODUCTS).map(({ name }) => name).reduce((accoumulator, item) => ({ ...accoumulator, [item]: [] }), {})
    data.forEach(item => {
      office_id.push(item.office_id)
      office_name.push(item.office_name)

      Object.keys(PRODUCTS).forEach(key => {
        quota_products[PRODUCTS[key].name].push(item.quota.find(quota => quota.product_id == key)?.remaining || 0)
      })
    });
    return {
      office_id,
      office_name,
      ...quota_products
    }
  }

  useEffect(() => {
    refetchBill();
  }, [refetchBill, paramsTableBill]);

  const optionsBar = ({ labels = [], datasets = [] }) => {
    return {
      chart: {
        type: "column",
      },

      credits: {
        enabled: false,
      },

      title: false,

      xAxis: {
        categories: labels,
        crosshair: true,
      },

      yAxis: {
        min: 0,
        title: false,
      },

      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:f}</b></td></tr>',
        footerFormat: "</table>",
        formatter: function () {
          return this.points.reduce(function (s, point) {
            return (
              s +
              "<br/>" +
              point.series.name +
              ": <b>" +
              numberCommas(point.y) +
              ",-</b>"
            );
          }, "<b>" + this.x + "</b>");
        },
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: datasets,
    };
  };

  const columnsBilling = [
    {
      title: t("Date"),
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return moment(date, moment.ISO_8601).format("DD/MM/YYYY");
      },
    },
    {
      title: t("Office"),
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: t("Invoice"),
      dataIndex: "invoice_id",
      key: "invoice_id",
    },
    {
      title: t("Total"),
      dataIndex: "total",
      key: "total",
      render: (total) => {
        return `Rp ${numberCommas(total)},-`;
      },
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return status === 1 ? (
          <p className="text-yellow-500">{t("Pending")}</p>
        ) : status === 2 ? (
          <p className="text-blue-500">{t("Completed")}</p>
        ) : (
          <p className="text-gray-500">{t("Canceled")}</p>
        );
      },
    },
    {
      render: (record) => {
        return (
          <div
            className="group cursor-pointer"
            onClick={() =>
              history.push(`/overview/billing-detail/${record.id}`)
            }
          >
            <RightOutlined className="group-hover:text-blue-500" />
          </div>
        );
      },
    },
  ];

  const columnsActivity = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Office"),
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: t("e-Signature"),
      dataIndex: "sign",
      key: "sign",
      render: (_, record) => {
        return (
          <>
            {record?.product_list?.map((item) => {
              return item?.id === 2 && item.used;
            })}
          </>
        );
      },
    },
    {
      title: t("e-Meterai"),
      dataIndex: "emeterai",
      key: "emeterai",
      render: (_, record) => {
        return (
          <>
            {record?.product_list?.map((item) => {
              return item?.id === 1 && item.used;
            })}
          </>
        );
      },
    },
    {
      title: t("DMS per-Document"),
      dataIndex: "storage (page)",
      key: "storage (page)",
      render: (_, record) => {
        return (
          <>
            {record?.product_list?.map((item) => {
              return item?.id === 3 && item.used;
            })}
          </>
        );
      },
    },
    {
      title: t("Backup Secure Document"),
      dataIndex: "storage (mb)",
      key: "storage (mb)",
      render: (_, record) => {
        return (
          <>
            {record?.product_list?.map((item) => {
              return item?.id === 4 && item.used;
            })}
          </>
        );
      },
    },
  ];

  function datasetsBar(data) {
    const datasets = {};

    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < Object.keys(data[i]).length; j++) {
        datasets[Object.keys(data[i])[j]] = [];
      }

      for (let x = 0; x < data[i]?.product_list?.length; x++) {
        datasets[data[i]?.product_list[x].id] = [];
      }
    }

    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < Object.keys(data[i]).length; j++) {
        datasets[Object.keys(data[i])[j]].push(
          data[i][Object.keys(data[i])[j]]
        );
      }

      for (let x = 0; x < data[i]?.product_list?.length; x++) {
        datasets[data[i]?.product_list[x].id].push(
          data[i]?.product_list[x].total
        );
      }
    }

    return datasets;
  }

  function handleChangeDate(name, value) {
    setFilterDate({ ...filterDate, [name]: value });
  }

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  function handleChangeTableBill(pagination) {
    setParamsTableBill({
      ...paramsTableBill,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  const listFilterMonth = [
    {
      label: t("Jan"),
      value: 1,
    },
    {
      label: t("Feb"),
      value: 2,
    },
    {
      label: t("Mar"),
      value: 3,
    },
    {
      label: t("Apr"),
      value: 4,
    },
    {
      label: t("Mei"),
      value: 5,
    },
    {
      label: t("Jun"),
      value: 6,
    },
    {
      label: t("Jul"),
      value: 7,
    },
    {
      label: t("Agu"),
      value: 8,
    },
    {
      label: t("Sep"),
      value: 9,
    },
    {
      label: t("Okt"),
      value: 10,
    },
    {
      label: t("Nov"),
      value: 11,
    },
    {
      label: t("Des"),
      value: 12,
    },
  ];

  function listFilterYears() {
    const listYear = [];

    for (let i = 2021; i <= moment().format("YYYYY"); i++) {
      listYear.push(i);
    }

    return listYear;
  }

  function handleResetFilter() {
    setParamsTableBill({
      ...paramsTableBill,
      sort_by: "created_at",
      order: "desc",
    });
  }

  const menuFilter = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">{t("Filter")}</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={handleResetFilter}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <p className="text-gray-500 text-base font-medium">{t("Sort")}</p>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort By")}</p>
            <Select
              defaultValue="date"
              style={{ width: "100%" }}
              onChange={(e) =>
                setParamsTableBill({ ...paramsTableBill, sort_by: e })
              }
              value={paramsTableBill.sort_by}
            >
              <Option value="office_name">{t("Client Name")}</Option>
              <Option value="date">{t("Created Date")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="desc"
              buttonStyle="solid"
              onChange={(e) =>
                setParamsTableBill({
                  ...paramsTableBill,
                  order: e.target.value,
                })
              }
              value={paramsTableBill.order}
            >
              <Radio value="asc">{t("Ascending")}</Radio>
              <Radio value="desc">{t("Descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row gutter={[24, 24]} justify="space-between">
          <Col>
            <h1 className="text-xl md:text-2xl font-medium">{t("Overview")}</h1>
          </Col>
          {/* <Col>
            <Select
              defaultValue=""
              style={{ width: 150 }}
              onChange={handleChangeOffice}
              value={filterOffice}
            >
              {userProfile?.office_type_id === 1 && (
                <Option value="">Show All</Option>
              )}
              {listOffice?.map((item, index) => {
                return (
                  <Option key={index} value={item.office_id}>
                    {item.office_name}
                  </Option>
                );
              })}
            </Select>
          </Col> */}
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
            <Col xs={12} md={6}>
              <div>
                <h3 className="text-gray-500 text-sm md:text-base">
                  Signature
                </h3>
                <div className="flex flex-row space-x-2 items-center">
                  <h2 className="text-blue-700 text-3xl md:text-4xl font-bold">
                    {numberCommas(dataHeader?.sign)}
                  </h2>
                  {Number(dataHeader?.sign) <= 1000 && (
                    <Tooltip title={`Signature quantity is less than 1000`}>
                      <AlertIcon />
                    </Tooltip>
                  )}
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div>
                <h3 className="text-gray-500 text-sm md:text-base">
                  {t("E-Meterai")}
                </h3>
                <div className="flex flex-row space-x-2 items-center">
                  <h2 className="text-gray-700 text-3xl md:text-4xl font-bold">
                    {numberCommas(dataHeader?.meterai)}
                  </h2>
                  {Number(dataHeader?.meterai) <= 1000 && (
                    <Tooltip title={t("E-Meterai quantity is less than 1000")}>
                      <AlertIcon />
                    </Tooltip>
                  )}
                </div>
              </div>
            </Col>
          </Skeleton>
        </Row>
      </Col>
      <Col span={24}>
        <Card className="rounded-md">
          <Skeleton loading={isFetching} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("Total Sales per-Month")}
                    </h3>
                  </Col>
                  <Col>
                    <Select
                      style={{ width: 130 }}
                      onChange={(value) =>
                        handleChangeDate("sales_monthly", value)
                      }
                      value={filterDate.sales_monthly}
                    >
                      <Option value={3}>{t("Last 3 Months")}</Option>
                      <Option value={6}>{t("Last 6 Months")}</Option>
                      <Option value={12}>{t("Last 12 Months")}</Option>
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsBar({
                    labels: datasetsBar(dataMonthlySales)?.month,
                    datasets: [
                      {
                        name: t("E-Meterai"),
                        data: datasetsBar(dataMonthlySales)["1"],
                        color: "#EECD33",
                      },
                      {
                        name: t("E-Signature"),
                        data: datasetsBar(dataMonthlySales)["2"],
                        color: "#48A1D9",
                      },
                      {
                        name: t("DMS per-Page"),
                        data: datasetsBar(dataMonthlySales)["3"],
                        color: "#07418C",
                      },
                      {
                        name: t("Backup Secure Document"),
                        data: datasetsBar(dataMonthlySales)["4"],
                        color: "#8B8B8B",
                      },
                    ],
                  })}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>
      <Col span={24}>
        <Card className="rounded-md">
          <Skeleton loading={isFetching} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("Total Sales per-Headquarter")}
                    </h3>
                  </Col>
                  <Col>
                    <Row gutter={[24, 24]}>
                      <Col>
                        <Select
                          style={{ width: 150 }}
                          onChange={(value) =>
                            setParamsChartHq({
                              ...paramsChartHq,
                              office_id: value,
                            })
                          }
                          value={paramsChartHq.office_id}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="">{t("Select All HQ")}</Option>
                          {dataListOffice?.rows?.map((item, index) => {
                            return (
                              <Option key={index} value={item?.office_id}>
                                {item?.office_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Col>
                      <Col>
                        <Select
                          style={{ width: 120 }}
                          onChange={(value) =>
                            setParamsChartHq({
                              ...paramsChartHq,
                              month: value,
                            })
                          }
                          value={paramsChartHq.month}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {listFilterMonth?.map((item, index) => {
                            return (
                              (item.value <= Number(moment().format("M")) ||
                                paramsChartHq.year <
                                moment().format("YYYY")) && (
                                <Option key={index} value={item.value}>
                                  {item.label}
                                </Option>
                              )
                            );
                          })}
                        </Select>
                      </Col>
                      <Col>
                        <Select
                          style={{ width: 120 }}
                          onChange={(value) =>
                            setParamsChartHq({
                              ...paramsChartHq,
                              year: value,
                            })
                          }
                          value={paramsChartHq.year}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {listFilterYears()?.map((item, index) => {
                            return (
                              <Option key={index} value={item}>
                                {String(item)}
                              </Option>
                            );
                          })}
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsBar({
                    labels: datasetsBar(dataSalesPerHQ)?.office_name
                      ? datasetsBar(dataSalesPerHQ)?.office_name
                      : datasetsBar(dataSalesPerHQ)?.purchase_date,
                    datasets: [
                      {
                        name: t("E-Meterai"),
                        data: datasetsBar(dataSalesPerHQ)["1"],
                        color: "#EECD33",
                      },
                      {
                        name: t("E-Signature"),
                        data: datasetsBar(dataSalesPerHQ)["2"],
                        color: "#48A1D9",
                      },
                      // {
                      //   name: "Backup Secure Document",
                      //   data: datasetsBar(dataSalesPerHQ)?.storage_mb,
                      //   color: "#DBDBDB",
                      // },
                      {
                        name: t("DMS per-Page"),
                        data: datasetsBar(dataSalesPerHQ)["3"],
                        color: "#07418C",
                      },
                      // {
                      //   name: "Scanner per-Page",
                      //   data: datasetsBar(dataSalesPerHQ)?.storage_page,
                      //   color: "#8B8B8B",
                      // },
                      {
                        name: t("Backup Secure Document"),
                        data: datasetsBar(dataSalesPerHQ)["4"],
                        color: "#8B8B8B",
                      },
                    ],
                  })}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>
      <Col span={24}>
        <Card className="rounded-md">
          <Skeleton loading={false} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("Quota Headquarter")}
                    </h3>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsBar({
                    labels: createQuotaDatasets(dataQuotasHQ)?.office_name,
                    datasets: [
                      {
                        name: PRODUCTS[1].name,
                        data: createQuotaDatasets(dataQuotasHQ)[PRODUCTS[1].name]
                      },
                      {
                        name: PRODUCTS[2].name,
                        data: createQuotaDatasets(dataQuotasHQ)[PRODUCTS[2].name]
                      },
                      {
                        name: PRODUCTS[3].name,
                        data: createQuotaDatasets(dataQuotasHQ)[PRODUCTS[3].name]
                      },
                      {
                        name: PRODUCTS[4].name,
                        data: createQuotaDatasets(dataQuotasHQ)[PRODUCTS[4].name]
                      }, 
                      {
                        name: PRODUCTS[5].name,
                        data: createQuotaDatasets(dataQuotasHQ)[PRODUCTS[5].name]
                      },
                      {
                        name: PRODUCTS[6].name,
                        data: createQuotaDatasets(dataQuotasHQ)[PRODUCTS[6].name]
                      }
                    ]
                  })}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>
      <Col span={24}>
        <Card className="rounded-md">
          <Skeleton loading={isFetching} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("Billing History")}
                    </h3>
                  </Col>
                  <Col>
                    <Row gutter={[12, 12]}>
                      <Col>
                        <Select
                          style={{ width: 150 }}
                          onChange={(value) =>
                            setParamsTableBill({
                              ...paramsTableBill,
                              office_id: value,
                            })
                          }
                          value={paramsTableBill?.office_id}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="">{t("Select All HQ")}</Option>
                          {dataListOffice?.rows?.map((item, index) => {
                            return (
                              <Option key={index} value={item?.office_id}>
                                {item?.office_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Col>
                      <Col>
                        <Dropdown
                          overlay={menuFilter}
                          onVisibleChange={handleVisibleFilter}
                          visible={visibleFilter}
                          trigger={["click"]}
                        >
                          <Button
                            type="primary"
                            ghost
                            icon={<FilterOutlined />}
                          />
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsBilling}
                  dataSource={dataBillingHistory?.data}
                  scroll={{ x: 500 }}
                  pagination={{
                    current: paramsTableBill.page,
                    pageSize: paramsTableBill.row,
                    total: dataBillingHistory?.total,
                  }}
                  onChange={handleChangeTableBill}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>
      <Col span={24}>
        <Card className="rounded-md">
          <Skeleton loading={isFetching} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("Activity Client")}
                    </h3>
                  </Col>
                  <Col>
                    <Select
                      style={{ width: 150 }}
                      onChange={(value) =>
                        setParamsTableClientAct({
                          ...paramsTableClientAct,
                          office_id: value,
                        })
                      }
                      value={paramsTableClientAct?.office_id}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="">{t("Select All HQ")}</Option>
                      {dataListOffice?.rows?.map((item, index) => {
                        return (
                          <Option key={index} value={item?.office_id}>
                            {item?.office_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsActivity}
                  dataSource={datasetsListTable(dataClientActivity)}
                  scroll={{ x: 600 }}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>
    </Row>
  );
}
