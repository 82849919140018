import { ReactComponent as Overview } from "../../assets/icons/view-quilt-outline.svg";
import { ReactComponent as Office } from "../../assets/icons/office-building-outline.svg";
import { ReactComponent as AccountOutline } from "../../assets/icons/account-outline.svg";
import { ReactComponent as Document } from "../../assets/icons/file-document-outline.svg";
import { ReactComponent as Group } from "../../assets/icons/account-multiple-outline.svg";
import { ReactComponent as DocumentManagement } from "../../assets/icons/file-search-outline.svg";
import { ReactComponent as SignManagement } from "../../assets/icons/folder-outline.svg";
// import { ReactComponent as DocumentMessage } from "../../assets/icons/inbox.svg";
import { ReactComponent as PanelAdmin } from "../../assets/icons/panel-admin-outline.svg";
import { ReactComponent as ModuleManagement } from "../../assets/icons/module-management-outline.svg";
import { ReactComponent as ClientManagement } from "../../assets/icons/client-outline.svg";
import { ReactComponent as QoutationManagement } from "../../assets/icons/qoutation-outline.svg";
import { ReactComponent as Setting } from "../../assets/icons/setting.svg";
import i18next from "i18next";

export const menus = (profile, module) => {
  const isBasicAccess = profile?.role_id === 1;
  const isAllDocumentAccess = profile?.role_id === 2;
  const isFullAccess = profile?.role_id === 3;
  const isSuperAdmin = profile?.role_id === 4;
  const isAdminBilling = profile?.role_id === 5;
  const isBranch = profile?.office_type_id === 2;
  const isPic = profile?.is_pic;
  const isVerified = profile?.is_verified;
  const isContractActive = profile?.is_contract_active;

  return profile?.role_id
    ? [
        {
          title: i18next.t("overview"),
          path: "/overview",
          icon: <Overview />,
          hidden: isBasicAccess || isAllDocumentAccess || isFullAccess,
        },
        {
          title: i18next.t('Config Management'),
          path: '/config-management',
          icon: <Setting/>,
          hidden: !isSuperAdmin && !isAdminBilling
        },
        {
          title: i18next.t("Admin Management"),
          path: "/user-management",
          icon: <AccountOutline />,
          hidden:
            isBasicAccess ||
            isAllDocumentAccess ||
            isFullAccess ||
            isAdminBilling,
        },
        {
          title: i18next.t("Client Management"),
          path: "/client-management",
          icon: <ClientManagement />,
          hidden: isBasicAccess || isAllDocumentAccess || isFullAccess,
        },
        {
          title: i18next.t("Quotation Management"),
          path: "/quotation-management",
          icon: <QoutationManagement />,
          hidden: isBasicAccess || isAllDocumentAccess || isFullAccess,
        },
        {
          title: i18next.t("Monitoring Product"),
          path: "/monitoring-product",
          icon: <Overview />,
          hidden: isBasicAccess || isAllDocumentAccess || isFullAccess,
        },

        {
          title: i18next.t("Panel Admin"),
          path: "/panel-admin",
          icon: <PanelAdmin />,
          hidden:
            isBasicAccess ||
            isAllDocumentAccess ||
            isBranch ||
            isSuperAdmin ||
            !isPic ||
            isAdminBilling,
          disabled: !isVerified || !isContractActive,
        },

        // Persiapan UAT & SIT Overview Platform VAS BJT
        // {
        //   title: "Module Management",
        //   path: "/module-management",
        //   icon: <ModuleManagement />,
        //   hidden:
        //     isBasicAccess ||
        //     isAllDocumentAccess ||
        //     isBranch ||
        //     isSuperAdmin ||
        //     !isPic ||
        //     isAdminBilling,
        //   disabled: !isVerified || !isContractActive,
        // },

        {
          title: <div className="w-full bg-gray-700 h-0.5 rounded-md" />,
          hidden:
            isBasicAccess ||
            isAllDocumentAccess ||
            isBranch ||
            isSuperAdmin ||
            !isPic ||
            isAdminBilling,
        },
        {
          title: i18next.t("overview"),
          path: "/",
          icon: <Overview />,
          hidden: isSuperAdmin || isAdminBilling,
          disabled: !isVerified || !isContractActive,
        },
        {
          title: i18next.t("document"),
          icon: <Document />,
          hidden: isSuperAdmin || isAdminBilling,
          disabled: !isVerified || !isContractActive,
          subMenu: [
            // {
            //   title: "Document Management",
            //   path: "/v2/document-management",
            //   icon: <DocumentManagement />,
            //   disabled: !module?.document_m,
            //   hidden: module?.document_m === null,
            // },
            {
              title: i18next.t("Upload Document"),
              path: "/document-management",
              icon: <DocumentManagement />,
              disabled: !module?.document_m,
              hidden: module?.document_m === null,
            },
            {
              title: i18next.t("Stamp Document"),
              path: "/stamp-management",
              icon: <SignManagement />,
              disabled: !module?.stamp_m,
              hidden: module?.stamp_m === null,
            },
            {
              title : "Sign & Tera Management" ,
              path : "/sign-management" ,
              icon : <SignManagement /> ,
              disabled: !module?.sign_m,
              hidden: module?.sign_m === null,
            }
            // {
            //   title: "Document Message",
            //   path: "/document-message",
            //   icon: <DocumentMessage />,
            // },
          ],
        },
        {
          title: i18next.t("User"),
          path: "/user",
          icon: <AccountOutline />,
          hidden:
            isBasicAccess ||
            isAllDocumentAccess ||
            isSuperAdmin ||
            module?.user_m === null ||
            isAdminBilling,
          disabled: !module?.user_m || !isVerified || !isContractActive,
        },
        {
          title: i18next.t("Group"),
          path: "/group",
          icon: <Group />,
          hidden: isSuperAdmin || module?.group_m === null || isAdminBilling,
          disabled: !module?.group_m || !isVerified || !isContractActive,
        },
        {
          title: i18next.t("Work Unit"),
          path: "/office",
          icon: <Office />,
          hidden:
            isBasicAccess ||
            isAllDocumentAccess ||
            isSuperAdmin ||
            module?.office_m === null ||
            isAdminBilling,
          disabled: !module?.office_m || !isVerified || !isContractActive,
        },
        {
          title: i18next.t("Reporting"),
          icon: <Document />,
          hidden: isSuperAdmin || isAdminBilling,
          disabled: !isVerified || !isContractActive,
          subMenu: [
            // Persiapan UAT & SIT Overview Platform VAS BJT
            // {
            //   title: "Rekonsiliasi",
            //   path: "/Rekonsiliasi",
            //   icon: <DocumentManagement />,
            //   disabled: !module?.document_m,
            //   hidden: module?.document_m === null,
            // },
            // Persiapan UAT & SIT Overview Platform VAS BJT
            {
              title: i18next.t("rekon"),
              path: "/Rekonsiliasi",
              icon: <DocumentManagement />,
              disabled: !module?.document_m,
              hidden: module?.document_m === null || isBranch || isBasicAccess,
            },
            {
              title: i18next.t('Recapitulation'),
              path: '/Rekapitulasi',
              icon: <DocumentManagement />,
              disabled: !module?.document_m,
              hidden: module?.document_m === null
            }
          ],
        },
        
        
      ]
    : [];
};
