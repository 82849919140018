import 'antd/dist/antd.css';
import { Card, Col, DatePicker, Row, Select, Skeleton, Table, Menu, Radio, Dropdown, Input } from "antd";
import Highcharts, { dateFormat, numberFormat } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DownloadOutlined, EllipsisOutlined, FilterOutlined, PlusOutlined, ProfileFilled, RightOutlined } from "@ant-design/icons";
import { ReactComponent as FilterOutline } from "../../assets/icons/filter-outline.svg";
import Button from "../../components/Button";
import { FaBeer } from 'react-icons/fa';

import {
  useFetchBarOfficeQuery,
  useFetchBarQuery,
  useFetchPopularDocumentQuery,
  useFetchListFilterOfficeQuery,
  useFetchListFilterDateQuery,
  useFetchUserActivityQuery,
  useFetchDeptActivityQuery,
  useFetchOfficeActivityQuery,
  useFetchOfficeDeptUsrActQuery,
  useFetchCardQuery,
  useFetchListUsageMateraiQuery,
  useFetchListMonthlyActivitiesQuery,
} from "../../config/features/overview";

import { useFetchProfileUserQuery } from "../../config/features/user";
import { errorMessage, exportExcel, exportPdf, getBlob, numberCommas } from "../../utils";
import { useHistory } from "react-router";
import { useFetchActivityDocumentQuery, useFetchListModuleQuery } from "../../config/features";
import { DateRange } from "@material-ui/icons";
import { filter, values } from 'lodash';
import { generateRandomColor, pieOptions } from '../../utils/optionsChart';
import { objectToQueryString } from '../../utils/queryString';
import { createPagination } from '../../utils/table';
import { dataDistribution } from '../../utils/array';
import CardPrimary from '../../components/Card/CardPrimary';

const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;
export default function Overview() {
  const { t } = useTranslation();
  const history = useHistory();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };



  // filter User Activity
  const [isVisibleFilterUserActivity, setIsVisibleFilterUserActivity] = useState(false)
  const [paramsUserActivity, setParamsUserActivity] = useState({
    row: 5,
    page: 1,
    order: "desc",
    sort_by: "created_at",
    search: "",
    start_date: "",
    end_date: "",
  });

  const [paramsUsageMaterai, setParamsUsageMaterai] = useState(() => {
    let start_date = new Date();
    let end_date = new Date();
    start_date.setMonth(start_date.getMonth() - 5)
    start_date = `${start_date.getFullYear()}-${start_date.getMonth() + 1 < 10 ? 0 : ''}${start_date.getMonth() + 1}`
    end_date = `${end_date.getFullYear()}-${end_date.getMonth() + 1 < 10 ? 0 : ''}${end_date.getMonth() + 1}`
    return { start_date, end_date };
  })
  const [paramsMonthlyActivities, setParamsMonthlyActivities] = useState({ row: 12, page: 1, year: new Date().getFullYear() })
  const columnUserActivity = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },

    {
      title: "Upload At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY");
      },
    },
  ];


  const datasetsListTableUserActivity = (data = []) => {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsUserActivity.row * (paramsUserActivity.page - 1),
        ...data[i],
      });
    }


    return datasets;
  }
  //handle filteringdate  User activity office

  const handleClickTotalUserActivity = () => {
    // console.log(paramsUserActivity)
  }

  const handleResetFilterTotalUserActivity = () => {
    setParamsUserActivity(prevState => ({
      ...prevState,
      sort_by: "created_at",
      order: "desc",
      search: "",
      start_date: "",
      end_date: "",
      status: "",
      office_id: "",
    }))
  }

  // filter Departement Activity
  const [isVisibleFilterDepatartementActivity, setIsVisibleFilterDepartementActivity] = useState(false)
  const [paramsDepartementActivity, setParamsDepartementActivity] = useState({
    row: 5,
    page: 1,
    order: "desc",

    sort_by: "created_at",
    search: "",
    start_date: "",
    end_date: "",
  });

  //handle filteringdate  Doc activity office
  const handleTotalDepartementActivity = (e) => {
    setParamsDepartementActivity(prevState => ({
      ...prevState,
      search: e.target.value
    }))
  }

  const handleClickTotalDepartementActivity = () => {
    console.log(paramsDepartementActivity)
  }



  const handleResetFilterTotalDepartementActivity = () => {
    setParamsDepartementActivity(prevState => ({
      ...prevState,
      sort_by: "created_at",
      order: "desc",
      search: "",
      start_date: "",
      end_date: "",
      status: "",
    }))
  }

  // filter Office Activity
  const [isVisibleFilterOfficeActivity2, setIsVisibleFilterOfficeActivity2] = useState(false)
  const [paramsOfficeActivity2, setParamsOfficeActivity2] = useState({
    row: 5,
    page: 1,

    order: "desc",
    sort_by: "created_at",
    search: "",
    start_date: "",
    end_date: "",
    office_name: "deptId",
    upload: "",
    send: "",
    stamping: "",
    signing: "",
    download: "",
    read_detail: "",
    dept_id: "",
  });

  // filter Office Dept Usr Act
  const [isVisibleFilterOfficeDeptUsrAct, setIsVisibleFilterOfficeDeptUsrAct] = useState(false)
  const [paramsOfficeDeptUsrAct, setParamsOfficeDeptUsrAct] = useState({
    row: 5,
    page: 1,
    order: "desc",
    sort_by: "created_at",
    search: "",
    start_date: "",
    end_date: "",
    office_name: "deptId",
    upload: "",
    send: "",
    stamping: "",
    signing: "",
    download: "",
    read_detail: "",
    dept_id: "",
  });

  const columnOfficeActivity = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },

    {
      title: "Office Name",
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: "Upload",
      dataIndex: "upload_doc",
      key: "upload_doc",
    },
    // {
    //   title: "Send",
    //   dataIndex: "send_doc",
    //   key: "send_doc",
    // },
    {
      title: "e-Meterai",
      dataIndex: "stamp_doc",
      key: "stamp_doc",
    },
    {
      title: "e-Sign",
      dataIndex: "sign_doc",
      key: "sign_doc",
    },
    {
      title: "Upload At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY");
      },
    },
  ];

  //detail product


  const datasetsListTableOfficeActivity = (data = []) => {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsOfficeActivity2.row * (paramsOfficeActivity2.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }
  //handle filteringdate  Doc activity office
  const handleTotalOfficeActivity = (e) => {
    setParamsOfficeActivity2(prevState => ({
      ...prevState,
      search: e.target.value
    }))
  }

  const handleClickTotalOfficeActivity = () => {
    console.log(paramsOfficeActivity2)
  }

  const handleResetFilterTotalOfficeActivity = () => {
    setParamsOfficeActivity2(prevState => ({
      ...prevState,
      sort_by: "created_at",
      order: "desc",
      search: "",
      start_date: "",
      end_date: "",
      status: "",
    }))
  }

  const handleResetFilterTotalOfficeDeptUsrAct = () => {
    setParamsOfficeActivity2(prevState => ({
      ...prevState,
      sort_by: "created_at",
      order: "desc",
      search: "",
      start_date: "",
      end_date: "",
      status: "",
    }))
  }


  // filter Doc Activity Office
  const [isVisibleFilterDocActivityOffice, setIsVisibleFilterDocActivityOffice] = useState(false)
  const [paramsTableDocActivityOffice, setParamsTableDocActivityOffice] = useState({
    row: 5,
    page: 1,
    order: "desc",
    sort_by: "created_at",
    search: "",
    start_date: "",
    end_date: "",
    dept_id: "",
  });

  const columnDocActivityOffice = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },

    {
      title: "Upload At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY");
      },
    },
  ];


  const datasetsListTableDocActivityOffice = (data = []) => {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTableDocActivityOffice.row * (paramsTableDocActivityOffice.page - 1),
        ...data[i],
      });
    }


    return datasets;
  }



  //handle filteringdate  Doc activity office
  const handleTotalDocActivityOffice = (e) => {
    setParamsTableDocActivityOffice(prevState => ({
      ...prevState,
      search: e.target.value
    }))
  }

  const handleClickTotalDocActivityOffice = () => {
    console.log(paramsTableDocActivityOffice)
  }

  const handleResetFilterTotalDocActivityOffice = () => {
    setParamsTableDocActivityOffice(prevState => ({
      ...prevState,
      sort_by: "created_at",
      order: "desc",
      search: "",
      start_date: "",
      end_date: "",
      status: "",
      dept_id: "",
    }))
  }

  // filter popular document
  const [isVisibleFilterPopularDoc, setIsVisibleFilterPopularDoc] = useState(false)
  const [paramsTablePopularDoc, setParamsTablePopularDoc] = useState({
    row: 5,
    page: 1,
    order: "desc",
    sort_by: "created_at",
    search: "",
    start_date: "",
    end_date: "",
  });

  const columnPopularDoc = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },

    {
      title: "Document Name.",
      dataIndex: "doc_name",
      key: "doc_name",
    },
    {
      title: "Work Unit",
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: "Uploaded By",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Upload At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY");
      },
    },
    {
      title: "Total Activity",
      dataIndex: "total_activity",
      key: "total_activity",

      //       dataIndex: "office",
      //       key: "office",
    },
  ];



  //handle filteringdate Popular Doc activity
  const handleTotalPopularDoc = (e) => {
    setParamsTablePopularDoc(prevState => ({
      ...prevState,
      search: e.target.value
    }))
  }

  const handleClickTotalPopularDoc = () => {
    console.log(paramsTablePopularDoc)
  }

  const handleResetFilterTotalPopularDoc = () => {
    setParamsTablePopularDoc(prevState => ({
      ...prevState,
      sort_by: "created_at",
      order: "desc",
      search: "",
      start_date: "",
      end_date: "",
      status: "",
    }))
  }


  //filter date docActivity
  const [isVisibleFilterDocActivity, setIsVisibleFilterDocActivity] = useState(false)
  const [paramsTableDocActivity, setParamsTableDocActivity] = useState({
    row: 5,
    page: 1,

    order: "desc",
    sort_by: "created_at",
    send: "",
    download: "",
    upload: "",
    read_detail: "",
    stamping: "",
    signing: "",
    search: "",
    start_date: "",
    end_date: "",
  });

  const columnDocActivity = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },

    {
      title: "Upload At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY HH:mm:ss");
      },
    },
  ];

  const datasetsListTableDocActivity = (data = []) => {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTableDocActivityLog.row * (paramsTableDocActivityLog.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }
  //handle filteringdate doc activity
  const handleTotalDocActivity = (e) => {
    setParamsTableDocActivity(prevState => ({
      ...prevState,
      search: e.target.value
    }))
  }


  const handleClickTotalDocActivity = () => {
    console.log(paramsTableDocActivity)
  }


  const handleResetFilterTotalDocActivity = () => {
    setParamsTableDocActivity(prevState => ({
      ...prevState,
      sort_by: "created_at",
      order: "desc",
      search: "",
      start_date: "",
      end_date: "",
      status: "",
    }))
  }
  const handleResetFilterMonthlyActivities = () => {
    setParamsMonthlyActivities(prevState => ({
      ...prevState,
      year: new Date().getFullYear()
    }))
  }
  function disabledDateAfter(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }
  // menu filtering User Activity 
  const menuFilterTotalUserActivity = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-blue-500"
              onClick={handleResetFilterTotalUserActivity}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("date")}</p>
            <DatePicker.RangePicker
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(value) => {
                setParamsUserActivity({
                  ...paramsUserActivity,
                  start_date: moment(value[0]).utc().format().toString(),
                  end_date: moment(value[1]).utc().format().toString(),
                })
              }}
              value={[
                paramsUserActivity.start_date ? moment(paramsUserActivity.start_date) : "",
                paramsUserActivity.end_date ? moment(paramsUserActivity.end_date) : ""
              ]}
              placeholder={[t("Start Date"), t("End Date")]}
            />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort By")}</p>
            <Select
              defaultValue="office_name"
              style={{ width: "100%" }}
              onChange={(e) => {
                setParamsUserActivity({
                  ...paramsUserActivity,
                  sort_by: e
                })
              }}
              value={paramsUserActivity.sort_by}
            >
              <Option value="created_at">{t("Created Date")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) => {
                setParamsUserActivity({
                  ...paramsUserActivity,
                  order: e.target.value
                })
              }}
              value={paramsUserActivity.order}
            >
              <Radio value="asc">{t("ascending")}</Radio>
              <Radio value="desc">{t("descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  // menu filtering Departement Activity 
  const menuFilterTotalDepartementActivity = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-blue-500"
              onClick={handleResetFilterTotalDepartementActivity}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("date")}</p>
            <DatePicker.RangePicker
              defaultValue=""
              placeholder={[t("Start Date", t("End Date"))]}
              style={{ width: "100%" }}
              onChange={(value) => {
                setParamsDepartementActivity({
                  ...paramsDepartementActivity,
                  start_date: moment(value[0]).utc().format().toString(),
                  end_date: moment(value[1]).utc().format().toString(),
                })
              }}
              value={[
                paramsDepartementActivity.start_date ? moment(paramsDepartementActivity.start_date) : "",
                paramsDepartementActivity.end_date ? moment(paramsDepartementActivity.end_date) : ""
              ]}
            />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort By")}</p>
            <Select
              defaultValue="office_name"
              style={{ width: "100%" }}
              onChange={(e) => {
                setParamsDepartementActivity({
                  ...paramsDepartementActivity,
                  sort_by: e
                })
              }}
              value={paramsDepartementActivity.sort_by}
            >
              <Option value="created_at">{t("Created Date")}</Option>
              <Option value="upload_doc">{t("Upload")}</Option>
              <Option value="send_doc">{t("Send")}</Option>
              <Option value="stamp_doc">{t("Stamping")}</Option>
              <Option value="download">{t("Download")}</Option>
              <Option value="read">{t("Read")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) => {
                setParamsDepartementActivity({
                  ...paramsDepartementActivity,
                  order: e.target.value
                })
              }}
              value={paramsDepartementActivity.order}
            >
              <Radio value="asc">{t("Ascending")}</Radio>
              <Radio value="desc">{t("Descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  // menu filtering Doc Activity Office
  const menuFilterTotalOfficeActivity = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-blue-500"
              onClick={handleResetFilterTotalOfficeActivity}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("date")}</p>
            <DatePicker.RangePicker
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(value) => {
                setParamsTableDocActivityOffice({
                  ...paramsTableDocActivityOffice,
                  start_date: moment(value[0]).utc().format().toString(),
                  end_date: moment(value[1]).utc().format().toString(),
                })
              }}
              value={[
                paramsTableDocActivityOffice.start_date ? moment(paramsTableDocActivityOffice.start_date) : "",
                paramsTableDocActivityOffice.end_date ? moment(paramsTableDocActivityOffice.end_date) : ""
              ]}
              placeholder={[t("Start Date"), t("End Date")]}
            />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort By")}</p>
            <Select
              defaultValue="office_name"
              style={{ width: "100%" }}
              onChange={(e) => {
                setParamsTableDocActivityOffice({
                  ...paramsTableDocActivityOffice,
                  sort_by: e
                })
              }}
              value={paramsTableDocActivityOffice.sort_by}
            >
              <Option value="created_at">{t("Created Date")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) => {
                setParamsTableDocActivityOffice({
                  ...paramsTableDocActivityOffice,
                  order: e.target.value
                })
              }}
              value={paramsTableDocActivityOffice.order}
            >
              <Radio value="asc">{t("Ascending")}</Radio>
              <Radio value="desc">{t("Descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  // menu filtering Doc Activity Office
  const menuFilterTotalOfficeDeptUsrAct = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-blue-500"
              onClick={handleResetFilterTotalOfficeDeptUsrAct}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Date")}</p>
            <DatePicker.RangePicker
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(value) => {
                setParamsTableDocActivityOffice({
                  ...paramsTableDocActivityOffice,
                  start_date: moment(value[0]).utc().format().toString(),
                  end_date: moment(value[1]).utc().format().toString(),
                })
              }}
              value={[
                paramsTableDocActivityOffice.start_date ? moment(paramsTableDocActivityOffice.start_date) : "",
                paramsTableDocActivityOffice.end_date ? moment(paramsTableDocActivityOffice.end_date) : ""
              ]}
              placeholder={[t("Start Date"), t("End Date")]}
            />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort By")}</p>
            <Select
              defaultValue="office_name"
              style={{ width: "100%" }}
              onChange={(e) => {
                setParamsTableDocActivityOffice({
                  ...paramsTableDocActivityOffice,
                  sort_by: e
                })
              }}
              value={paramsTableDocActivityOffice.sort_by}
            >
              <Option value="created_at">{t("Created Date")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) => {
                setParamsTableDocActivityOffice({
                  ...paramsTableDocActivityOffice,
                  order: e.target.value
                })
              }}
              value={paramsTableDocActivityOffice.order}
            >
              <Radio value="asc">{t("Ascending")}</Radio>
              <Radio value="desc">{t("Descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  // menu filtering Doc Activity Office
  const menuFilterTotalDocActivityOffice = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-blue-500"
              onClick={handleResetFilterTotalDocActivityOffice}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Date</p>
            <DatePicker.RangePicker
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(value) => {
                setParamsTableDocActivityOffice({
                  ...paramsTableDocActivityOffice,
                  start_date: moment(value[0]).utc().format().toString(),
                  end_date: moment(value[1]).utc().format().toString(),
                })
              }}
              value={[
                paramsTableDocActivityOffice.start_date ? moment(paramsTableDocActivityOffice.start_date) : "",
                paramsTableDocActivityOffice.end_date ? moment(paramsTableDocActivityOffice.end_date) : ""
              ]}
              placeholder={[t("Start Date"), t("End Date")]}
            />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort By")}</p>
            <Select
              defaultValue="office_name"
              style={{ width: "100%" }}
              onChange={(e) => {
                setParamsTableDocActivityOffice({
                  ...paramsTableDocActivityOffice,
                  sort_by: e
                })
              }}
              value={paramsTableDocActivityOffice.sort_by}
            >
              <Option value="created_at">{t("Created Date")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) => {
                setParamsTableDocActivityOffice({
                  ...paramsTableDocActivityOffice,
                  order: e.target.value
                })
              }}
              value={paramsTableDocActivityOffice.order}
            >
              <Radio value="asc">{t("Ascending")}</Radio>
              <Radio value="desc">{t("Descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };
  const handleResetFilterUsageActivities = () => {
    handleChangeDate("usage_activity_office", "7 Days")
    setSelectedUsageActivity({ label: 'e-Meterai', value: 'stamping' });
  }
  const handleFilterUsageActivities = (e) => {
    if (e == "stamping") {
      var label = 'e-Meterai';
    }
    else if (e == 'signing') {
      var label = "e-Sign"
    }
    else {
      var label = e.charAt(0).toUpperCase() + e.slice(1);
    }
    setSelectedUsageActivity({ label: label, value: e })
  }
  const menuFilterUsageActivities = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium gap-36 items-center">Filter</p>
            <p
              className="text-blue-500"
              onClick={handleResetFilterUsageActivities}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Activity")}</p>
            <Select
              defaultValue="stamping"
              style={{ width: 200 }}
              onChange={handleFilterUsageActivities}
              value={selectedUsageActivity.value}
            >
              <Option key={'stamping'} value="stamping">e-Meterai</Option>
              {/* Persiapan UAT & SIT Overview Platform VAS BJT */}
              {/* <Option key={'signing'} value="signing">e-Sign</Option> */}
              <Option key={'upload'} value="upload">{t("Upload")}</Option>
              <Option key={'download'} value="download">{t("Download")}</Option>
            </Select>
          </div>
        </Menu.Item>
      </Menu>
    );
  }

  // menu filtering Total Popular Doc
  const menuFilterTotalPopularDoc = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-blue-500"
              onClick={handleResetFilterTotalPopularDoc}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("date")}</p>
            <DatePicker.RangePicker
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(value) => {
                setParamsTablePopularDoc({
                  ...paramsTablePopularDoc,
                  start_date: moment(value[0]).utc().format().toString(),
                  end_date: moment(value[1]).utc().format().toString(),
                })
              }}
              value={[
                paramsTablePopularDoc.start_date ? moment(paramsTablePopularDoc.start_date) : "",
                paramsTablePopularDoc.end_date ? moment(paramsTablePopularDoc.end_date) : ""
              ]}
              placeholder={[t("Start Date"), t("End Date")]}
            />
          </div>
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort By")}</p>
            <Select
              defaultValue="office_name"
              style={{ width: "100%" }}
              onChange={(e) => {
                setParamsTablePopularDoc({
                  ...paramsTablePopularDoc,
                  sort_by: e
                })
              }}
              value={paramsTablePopularDoc.sort_by}
            >
              <Option value="created_at">{t("Created Date")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) => {
                setParamsTablePopularDoc({
                  ...paramsTablePopularDoc,
                  order: e.target.value
                })
              }}
              value={paramsTablePopularDoc.order}
            >
              <Radio value="asc">{t("Ascending")}</Radio>
              <Radio value="desc">{t("Descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };


  // menu filtering Total Doc Activty
  const menuFilterTotalDocActivity = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-blue-500"
              onClick={handleResetFilterTotalDocActivity}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("date")}</p>
            <DatePicker.RangePicker
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(value) => {
                setParamsTableDocActivity({
                  ...paramsTableDocActivity,
                  start_date: moment(value[0]).utc().format().toString(),
                  end_date: moment(value[1]).utc().format().toString(),
                })
              }}
              value={[
                paramsTableDocActivity.start_date ? moment(paramsTableDocActivity.start_date) : "",
                paramsTableDocActivity.end_date ? moment(paramsTableDocActivity.end_date) : ""
              ]}
              placeholder={[t("Start Date"), t("End Date")]}
            />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort By")}</p>
            <Select
              defaultValue="office_name"
              style={{ width: "100%" }}
              onChange={(e) => {
                setParamsTableDocActivity({
                  ...paramsTableDocActivity,
                  sort_by: e
                })
              }}
              value={paramsTableDocActivity.sort_by}
            >
              <Option value="created_at">{t("Created Date")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) => {
                setParamsTableDocActivity({
                  ...paramsTableDocActivity,
                  order: e.target.value
                })
              }}
              value={paramsTableDocActivity.order}
            >
              <Radio value="asc">{t("Ascending")}</Radio>
              <Radio value="desc">{t("Descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  // Filter by year monthly activities
  const [isVisibleMonthly, setIsVisibleMonthly] = useState(false);
  const menuFilterMonthlyActivity = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-blue-500"
              onClick={handleResetFilterMonthlyActivities}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Year")}</p>
            <DatePicker
              defaultValue=""
              placeholder={t("Select Year")}
              style={{ width: "100%" }}
              picker='year'
              onChange={(value) => setParamsMonthlyActivities(prevState => ({ ...prevState, year: moment(value).format("YYYY").toString() }))}
              disabledDate={disabledDateAfter}
            />
          </div>
        </Menu.Item>
      </Menu>
    )
  }

  // Usage Activities Per Work Unit
  const [paramsUsageActivitiesPerOffice, setParamsUsageActivitiesPerOffice] = useState({
    row: 10,
    page: 1,
    office_name: "",
    monthDate: moment().format("YYYYMM")
  })
  const {
    data: dataListUsageOffices = [],
    isFetching: isFetchingUsageOffices,
    refetch: refetchUsageOffices,
    isError: isErrorUsageOffices,
    error: errorUsageOffices
  } = useFetchListMonthlyActivitiesQuery(paramsUsageActivitiesPerOffice)

  // Refetch Usage Activities Per Office
  useEffect(() => {
    refetchUsageOffices();
  }, [refetchUsageOffices, paramsUsageActivitiesPerOffice])

  const onSearch = (value) => setParamsUsageActivitiesPerOffice({ ...paramsUsageActivitiesPerOffice, office_name: value });
  const handleResetFilterUsageActivitiesPerOffice = () => {
    setParamsUsageActivitiesPerOffice({
      ...paramsUsageActivitiesPerOffice,
      office_name: "",
      monthDate: moment().format("YYYYMM"),
      page: 1
    })
  }

  // Filter By Office name usage activities per office
  // Filter by month usage activities per office
  const [isVisibleUsageActivitiesPerOffice, setIsVisibleUsageActivitiesPerOffice] = useState(false);
  const menuFilterUsageActivitiesPerOffice = () => (
    <Menu>
      <Menu.Item>
        <div className="flex flex-row justify-between gap-36 items-center">
          <p className="text-gray-500 text-base font-medium">Filter</p>
          <p
            className="text-blue-500"
            onClick={handleResetFilterUsageActivitiesPerOffice}
          >
            {t("resetall")}
          </p>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div className="flex flex-col space-y-2">
          <p className="text-xs">{t("Choose Month")}</p>
          <DatePicker
            defaultValue=""
            style={{ width: "100%" }}
            picker='month'
            onChange={(value) => setParamsUsageActivitiesPerOffice(prevState => ({ ...prevState, monthDate: moment(value).format("YYYYMM") }))}
            disabledDate={disabledDateAfter}
            placeholder={t("Select Month")}
          />
        </div>
      </Menu.Item>
    </Menu>
  )

  // Columns Table Usage Activity Per Office
  const columnsUsageActivitiesPerOffice = [
    { title: 'No', dataIndex: 'no', key: 'no' },
    { title: t('Work Unit'), dataIndex: 'office', key: 'office' },
    { title: 'Stamp', dataIndex: 'stamp', key: 'stamp' },
    {
      title: 'Rp', dataIndex: 'cost', key: 'cost',
      render: (cost) => `Rp ${numberCommas(cost)}`
    },
    {
      render: (record) => {
        return (
          <Dropdown
            overlay={menuActionUsageOffices(record)}
            trigger={["click"]}
          >
            <div className="group cursor-pointer">
              <EllipsisOutlined className="group-hover:text-blue-500" />
            </div>
          </Dropdown>
        );
      },
    }
  ]

  // Action Dropdown Data Usage Activities Per Office
  const menuActionUsageOffices = ({ office_id }) => (
    <Menu>
      <Menu.Item>
        <div
          className="flex flex-row space-x-2 items-center"
          onClick={() => history.push(`/Rekapitulasi/detail/${office_id}/${paramsUsageActivitiesPerOffice.monthDate}`)}
        >
          <ProfileFilled />
          <p>{t("Detail")}</p>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div
          className="flex flex-row space-x-2 items-center"
          onClick={() => handleExportUsagePerMonth({ office_id: office_id, monthDate: `${paramsUsageActivitiesPerOffice.monthDate}` })}
        >
          <DownloadOutlined />
          <p>{t("Download")}</p>
        </div>
      </Menu.Item>
    </Menu>
  )

  const menuActionMonthlyActivity = (record) => (
    <Menu>
      <Menu.Item>
        <div
          className="flex flex-row space-x-2 items-center"
          onClick={() => history.push(`/Rekapitulasi/detail/${filterOffice}/${paramsMonthlyActivities.year}${record.month}`)}
        >
          <ProfileFilled />
          <p>{t("Detail")}</p>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div
          className="flex flex-row space-x-2 items-center"
          onClick={() => handleExportUsagePerMonth({ office_id: filterOffice, monthDate: `${paramsMonthlyActivities.year}${record.month}` })}
        >
          <DownloadOutlined />
          <p>{t("Download")}</p>
        </div>
      </Menu.Item>
    </Menu>
  )

  //filter date docActivity log
  const [date, setDate] = useState([])

  const [isVisibleFilterDocActivityLog, setIsVisibleFilterDocActivityLog] = useState(false)
  const [paramsTableDocActivityLog, setParamsTableDocActivityLog] = useState({
    row: 5,
    page: 1,
    order: "desc",
    sort_by: "created_at",
    search: "",
    start_date: "",
    end_date: "",
  });

  const handleClickExportExcel = async () => {
    const response = await getBlob(`/overview/doc-dep-activity-detail?`);
    exportExcel(response.data, "History Transaksi Departemen ");
  }

  const handleClickExportPdf = async () => {
    const response = await getBlob(`/overview/doc-dep-activity-detail?`);
    exportPdf(response.data, "History Transaksi Departemen");
  }
  const handleExportUsagePerMonth = (params) => {
    const queryString = objectToQueryString({ export_format: 'excel', ...params });
    const { monthDate } = params;
    getBlob(`/report/rekapitulasidetil?${queryString}`)
      .then(response => exportExcel(response.data, `Data Detail Rekapitulasi_${monthDate}_${new Date()}`))
      .catch(err => errorMessage(err));
  }

  const handleExportMonthlyActivity = (params) => {
    const queryString = objectToQueryString({ export_format: 'excel', ...params });
    // getBlob(`/overview/monthly-activity/detail?${queryString}`)
    //   .then(response => exportExcel(response.data, `Usage Activity_${params.period}_${new Date()}`))
    //   .catch(err => errorMessage(err));
  }

  const columnTotalDocActivity = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },

    {
      title: t("date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY HH:mm:ss");
      },
    },
  ];

  const datasetsListTableDocActivitylog = (data = []) => {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTableDocActivityLog.row * (paramsTableDocActivityLog.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }


  //handle filteringdate
  const handleDocActivity = (e) => {
    setParamsTableDocActivityLog(prevState => ({
      ...prevState,
      search: e.target.value
    }))
  }


  const handleClickDocActivity = () => {
    // console.log(paramsTableDocActivityLog)
  }

  const handleResetFilterDocActivity = () => {
    setParamsTableDocActivityLog(prevState => ({
      ...prevState,
      sort_by: "created_at",
      order: "desc",
      search: "",
      start_date: "",
      end_date: "",
      status: "",
    }))
  }

  // menu filtering
  const menuFilterDocActivity = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-blue-500"
              onClick={handleResetFilterDocActivity}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Date")}</p>
            <DatePicker.RangePicker
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(value) => {
                setParamsTableDocActivityLog({
                  ...paramsTableDocActivityLog,
                  start_date: moment(value[0]).utc().format().toString(),
                  end_date: moment(value[1]).utc().format().toString(),
                })
              }}
              value={[
                paramsTableDocActivityLog.start_date ? moment(paramsTableDocActivityLog.start_date) : "",
                paramsTableDocActivityLog.end_date ? moment(paramsTableDocActivityLog.end_date) : ""
              ]}
              placeholder={[t("Start Date"), t("End Date")]}
            />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort By")}</p>
            <Select
              defaultValue="office_name"
              style={{ width: "100%" }}
              onChange={(e) => {
                setParamsTableDocActivityLog({
                  ...paramsTableDocActivityLog,
                  sort_by: e
                })
              }}
              value={paramsTableDocActivityLog.sort_by}
            >
              <Option value="created_at">{t("Created Date")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) => {
                setParamsTableDocActivityLog({
                  ...paramsTableDocActivityLog,
                  order: e.target.value
                })
              }}
              value={paramsTableDocActivityLog.order}
            >
              <Radio value="asc">{t("ascending")}</Radio>
              <Radio value="desc">{t("descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };
  //param card filtering

  // filter state
  const [filterOffice, setFilterOffice] = useState("");
  const [filterDate, setFilterDate] = useState({
    doc_activity: "7 Days",
    doc_activity_office: "7 Days",
    popular_doc: "7 Days",
    usage_activity_office: "7 Days"
  });


  const { data: listOffice = [] } = useFetchListFilterOfficeQuery();

  const { data: listDate = [] } = useFetchListFilterDateQuery();

  const { data: userProfile = {} } = useFetchProfileUserQuery();

  const {
    data: dataCard = {},
    isFetching: isFetchingCard,
    error: errorCard,
    isError: isErrorCard,
  } = useFetchCardQuery({ office_id: filterOffice });

  const { data: dataModule = {} } = useFetchListModuleQuery(
    userProfile?.office_id
  );
  const getActivedCard = () => {
    const cards = []
    if (dataModule.office_m == true) {
      cards.push("Office Management")
    }
    if (dataModule.sign_m == true) {
      cards.push("Sign Management")
    }
    if (dataModule.stamp_m == true) {
      cards.push("Stamp Management")
    }
    return cards
  }
  const {
    data: dataUsageMaterai = [],
    isFetching: isFetchingUsageMaterai,
    error: errorUsageMaterai,
    isError: isErrorUsageMaterai
  } = useFetchListUsageMateraiQuery({ office_id: filterOffice, ...paramsUsageMaterai });

  const {
    data: dataListMonthlyActivities = [],
    isFetching: isFetchingMonthlyActivities,
    error: errorMonthlyActivities,
    isError: isErrorMonthlyActivities
  } = useFetchListMonthlyActivitiesQuery({ office_id: filterOffice, ...paramsMonthlyActivities });

  const {
    data: dataDocActivityLog = [],
    isFetching: isFetchingDocActivityLog,
    isError: isErrorDocActivityLog,
    error: errorDocActivityLog,
  } = useFetchActivityDocumentQuery(paramsTableDocActivityLog);



  const {
    data: dataBar = [],
    isFetching: isFetchingBar,
    isError: isErrorBar,
    error: errorBar,
  } = useFetchBarQuery({
    office_id: filterOffice,
    date: filterDate.doc_activity,

  });

  const {
    data: dataBarOffice = [],
    isFetching: isFetchingBarOffice,
    isError: isErrorBarOffice,
    error: errorBarOffice,
  } = useFetchBarOfficeQuery({ date: filterDate.doc_activity_office });

  const [isVisibleFilterUsageActivities, setIsVisibleFilterUsageActivities] = useState(false)
  const [selectedUsageActivity, setSelectedUsageActivity] = useState({ label: 'e-Meterai', value: 'stamping' })
  const {
    data: usageActivities = [],
    isFetching: isFetchingUsageActivities,
    isError: isErrorUsageActivities,
    error: errorUsageActivities
  } = useFetchBarOfficeQuery({ date: filterDate.usage_activity_office });

  const [usageActivitiesDiagram, setUsageActivitiesDiagram] = useState({})
  const {
    data: dataPopularDocument = [],
    isFetching: isFetchingPopularDocument,
    isError: isErrorPopularDocument,
    error: errorPopularDocument,

    //   } = useFetchPopularDocumentQuery(paramsTablePopularDoc);

  } = useFetchPopularDocumentQuery({
    office_id: filterOffice,
    date: filterDate.popular_doc,

  });


  const {
    data: dataUserActivity = [],
    isFetching: isFetchingUserActivity,
    isError: isErrorUserActivity,
    error: errorUserActivity,
  } =
    useFetchUserActivityQuery({
      office_id: filterOffice,
      created_at: paramsUserActivity,
      office_id: filterOffice, ...paramsUserActivity,
    });

  const {
    data: dataDeptActivity = [],
    isFetching: isFetchingDeptActivity,
    isError: isErrorDeptActivity,
    error: errorDeptActivity,
  } =
    useFetchDeptActivityQuery({
      office_id: filterOffice,
      start_date: paramsDepartementActivity,
    });

  const {
    data: dataOfficeActivity = [],
    isFetching: isFetchingOfficeActivity,
    isError: isErrorOfficeActivity,
    error: errorOfficeActivity,
  } =
    useFetchOfficeActivityQuery({
      office_id: filterOffice,
      created_at: paramsTableDocActivityOffice,
    });

  const {
    data: dataOfficeDeptUsrAct = [],
    isFetching: isFetchingOfficeDeptUsrAct,
    isError: isErrorOfficeDeptUsrAct,
    error: errorOfficeDeptUsrAct,
  } =
    useFetchOfficeDeptUsrActQuery({
      office_id: filterOffice,
      created_at: paramsTableDocActivityOffice,
    });

  useEffect(() => {
    if (userProfile?.office_type_id !== 1) {
      setFilterOffice(userProfile?.office_id);
    } else {
      setFilterOffice("");
    }
  }, [userProfile]);


  useEffect(() => {
    setUsageActivitiesDiagram(datasetsBar(usageActivities))
  }, [usageActivities])

  // Error Handling
  // =============== 
  useEffect(() => {
    if (isErrorCard) errorMessage(errorCard)
  }, [isErrorCard, errorCard]);

  useEffect(() => {
    if (isErrorUsageActivities) errorMessage(errorUsageActivities);
  }, [isErrorUsageActivities, errorUsageActivities])

  useEffect(() => {
    if (isErrorMonthlyActivities) errorMessage(errorMonthlyActivities)
  }, [isErrorMonthlyActivities, errorMonthlyActivities]);

  useEffect(() => {
    if (isErrorUsageMaterai) errorMessage(errorUsageMaterai);
  }, [isErrorUsageMaterai, errorUsageMaterai])

  useEffect(() => {
    if (isErrorDocActivityLog) errorMessage(errorDocActivityLog)
  }, [isErrorDocActivityLog, errorDocActivityLog]);

  useEffect(() => {
    if (isErrorBar) errorMessage(errorBar)
  }, [isErrorBar, errorBar]);

  useEffect(() => {
    if (isErrorBarOffice) errorMessage(errorBarOffice)
  }, [isErrorBarOffice, errorBarOffice]);

  useEffect(() => {
    if (isErrorPopularDocument) errorMessage(errorPopularDocument)
  }, [isErrorPopularDocument, errorPopularDocument]);

  useEffect(() => {
    if (isErrorUserActivity) errorMessage(errorUserActivity)
  }, [isErrorUserActivity, errorUserActivity]);

  useEffect(() => {
    if (isErrorDeptActivity) errorMessage(errorDeptActivity)
  }, [isErrorDeptActivity, errorDeptActivity]);

  useEffect(() => {
    if (isErrorOfficeActivity) errorMessage(errorOfficeActivity)
  }, [isErrorOfficeActivity, errorOfficeActivity]);

  useEffect(() => {
    if (isErrorOfficeDeptUsrAct) errorMessage(errorOfficeDeptUsrAct)
  }, [isErrorOfficeDeptUsrAct, errorOfficeDeptUsrAct]);

  useEffect(() => {
    if (isErrorUsageOffices) errorMessage(errorUsageOffices)
  }, [isErrorUsageOffices, errorUsageOffices])

  const optionsBar = ({ labels = [], datasets = [] }, horizontal = false, total_data) => {
    return {
      chart: {
        type: horizontal ? "bar" : "column",
        height: total_data && total_data > 10 ? parseInt(15 * total_data) : "100%"
      },
      credits: {
        enabled: false,
      },

      title: false,

      xAxis: {
        categories: labels,
        crosshair: true,
      },

      yAxis: {
        min: 0,
        title: false,
        tickAmount: 5,
      },

      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
        bar: {
          colorByPoint: true,
          dataLabels: { align: "left" }
        },
        series: {
          pointWidth: 20,
        },
      },
      series: datasets,
    };
  };
  const optionsLine = (title, name, categories, data, other) => {
    return {
      title: { text: title },
      chart: {
        type: "line"
      },
      credits: { enabled: false },
      xAxis: {
        categories: categories,
        crosshair: false,
        gridLineWidth: 1
      },
      yAxis: {
        title: false,
      },
      tooltip: {
        shared: true,
        useHTML: true,
        formatter: function () {
          let tooltipOther;
          if (other && other.length == data.length) {
            const cost = other[categories.findIndex(item => item == this.x)]
            tooltipOther = `
            <tr>
              <td style="color: ${this.points[0].series.color}">Total Rp : </td>
              <td style="text-align: right"><b>${numberFormat(cost, 0)}</b></td>
            </tr>
            `
          }
          return `
            <table>
              <tr><th colspan="2">${this.x}</th></tr>
              ${tooltipOther}
              <tr>
                <td style="color: purple">${this.points[0].series.name} : </td>
                <td style="text-align: right"><b>${numberFormat(this.y, 0)}</b></td>
              </tr>
            </table>
          `;
        }
      },
      series: [
        {
          name: name,
          type: 'line',
          linkedTo: false,
          data: data
        }
      ]
    }
  }
  const columnsDocument = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Document Name"),
      dataIndex: "doc_name",
      key: "doc_name",
      render: (e, record) => {
        return (
          <p
            className="text-blue-400 hover:text-blue-500 cursor-pointer underline"
            onClick={() =>
              history.push(`/document-management/detail/${record.doc_id}`)
            }
          >
            {e}
          </p>
        );
      },
    },
    {
      title: t("Work Unit"),
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: t("Uploaded By"),
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: t("Uploaded At"),
      dataIndex: "created_at",
      key: "created_at",
      render: (e) => {
        return moment(e, moment.ISO_8601).format("DD/MM/YYYY");
      },
    },
    {
      title: t("Total Activity"),
      dataIndex: "total_activity",
      key: "total_activity",
    },
  ];

  const columnsUsers = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("User Name"),
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: t("Departement"),
      dataIndex: "dept_name",
      key: "dept_name",
    },
    {
      title: t("Upload"),
      dataIndex: "upload_doc",
      key: "upload_doc",
    },
    {
      title: t("Download"),
      dataIndex: "download_doc",
      key: "download_doc",
    },
    // {
    //   title: "Send",
    //   dataIndex: "send_doc",
    //   key: "send_doc",
    // },
    {
      title: "e-Meterai",
      dataIndex: "stamp_doc",
      key: "stamp_doc",
    },
    {
      title: "e-Sign",
      dataIndex: "sign_doc",
      key: "sign_doc",
    },
    // {
    //   title: "Read",
    //   dataIndex: "read_doc",
    //   key: "read_doc",
    // },    
  ];

  const columnsDepartements = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Departement Name"),
      dataIndex: "dept_name",
      key: "dept_name",
    },
    {
      title: t("Total User"),
      dataIndex: "total_user",
      key: "total_user",
    },
    {
      title: t("Upload"),
      dataIndex: "upload_doc",
      key: "upload_doc",
    },
    {
      title: t("Download"),
      dataIndex: "download_doc",
      key: "download_doc",
    },
    // {
    //   title: "Send",
    //   dataIndex: "send_doc",
    //   key: "send_doc",
    // },
    {
      title: "e-Meterai",
      dataIndex: "stamp_doc",
      key: "stamp_doc",
    },
    {
      title: "e-Sign",
      dataIndex: "sign_doc",
      key: "sign_doc",
    },
    // {
    //   title: "Read",
    //   dataIndex: "read_doc",
    //   key: "read_doc",
    // },  
    {
      title: t("Action"),
      dataIndex: "action",
      key: "action",
      render: (_, row) => {
        return (
          <Button
            type="primary"
            icon=""
            onClick={() => {
              history.push({
                pathname: `/monitoring-departemen/defect/${row?.dept_id}`,
                state: row
              })
            }}
            ghost
          >
            Detail
          </Button>
        );
      },
    },
  ];

  const columnsMonthlyActivities = [
    { title: 'No', dataIndex: 'no', key: 'no' },
    {
      title: t('Month'), dataIndex: 'month', key: 'month',
      render: (month) => moment(`${paramsMonthlyActivities.year}-${month}`).format("MMMM").toString()
    },
    { title: 'Stamp', dataIndex: 'stamp', key: 'stamp' },
    {
      title: 'Rp', dataIndex: 'cost', key: 'cost',
      render: (cost) => `Rp ${numberCommas(cost)}`
    },
    {
      render: (record) => {
        return (
          <Dropdown
            overlay={menuActionMonthlyActivity(record)}
            trigger={["click"]}
          >
            <div className="group cursor-pointer">
              <EllipsisOutlined className="group-hover:text-blue-500" />
            </div>
          </Dropdown>
        );
      },
    }
  ];

  const columnsPopularDoc = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Document Name"),
      dataIndex: "doc_name",
      key: "doc_name",
    },
    {
      title: t("Office Name"),
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: t("Upload By"),
      dataIndex: "creator",
      key: "creator",
    },
    {
      title: t("Upload At"),
      dataIndex: "upload_doc",
      key: "upload_doc",
    },
    {
      title: t("Read"),
      dataIndex: "read_doc",
      key: "read_doc",
    }
  ];

  const columnsOffices = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Office Name"),
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: t("Upload"),
      dataIndex: "upload_doc",
      key: "upload_doc",
    },
    {
      title: t("Download"),
      dataIndex: "download_doc",
      key: "download_doc",
    },
    // {
    //   title: "Send",
    //   dataIndex: "send_doc",
    //   key: "send_doc",
    // },
    {
      title: "e-Meterai",
      dataIndex: "stamp_doc",
      key: "stamp_doc",
    },
    {
      title: "e-Sign",
      dataIndex: "sign_doc",
      key: "sign_doc",
    },
    // {
    //   title: "Read",
    //   dataIndex: "read_doc",
    //   key: "read_doc",
    // },    
  ];

  const columnsOfficesDeptUsr = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Office Name",
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: "Total Departement",
      dataIndex: "total_dept",
      key: "total_dept",
    },
    {
      title: "Total User",
      dataIndex: "total_user",
      key: "total_user",
    },
  ];

  const columnsDocLog = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Document Name"),
      dataIndex: "doc_name",
      key: "doc_name",
    },
    {
      title: t("Activity"),
      dataIndex: "activity",
      key: "activity",
      render: (activity) => t(activity)
    },
    {
      title: t("User"),
      dataIndex: "creator",
      key: "creator",
    },
    {
      title: t("Source"),
      dataIndex: "source",
      key: "source",
      render: (_, record) => {
        return (

          <>
            <p className="text-gray-500">
              {t("Device Type")} :
              <span className="text-gray-800"> {record?.device_type}</span>
            </p>
            <p className="text-gray-500">
              {t("Operating System")} :{" "}
              <span className="text-gray-800"> {record?.os}</span>
            </p>
            <p className="text-gray-500">
              {t("Browser")} :{" "}
              <span className="text-gray-800"> {record?.browser}</span>
            </p>
            <p className="text-gray-500">
              IP : <span className="text-gray-800"> {record?.ip}</span>
            </p>
          </>
        );
      },
    },
    {
      title: t("date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (e) => {
        return moment(e, moment.ISO_8601).format("DD/MM/YYYY, HH:mm:ss");
      },
    },
  ];

  function datasetsBar(data) {
    const datasets = {};

    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < Object.keys(data[i]).length; j++) {
        datasets[Object.keys(data[i])[j]] = [];
      }
    }

    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < Object.keys(data[i]).length; j++) {
        datasets[Object.keys(data[i])[j]].push(Object.values(data[i])[j]);
      }
    }

    return datasets;
  }

  function datasetsTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({ no: i + 1 + paramsTableDocActivityLog.row * (paramsTableDocActivityLog.page - 1), ...data[i] });
    }

    return datasets;
  }

  function datasetsTableofficeactivity(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({ no: i + 1 + paramsOfficeActivity2.row * (paramsOfficeActivity2.page - 1), ...data[i] });
    }

    return datasets;
  }

  function datasetsTableofficedeptusract(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({ no: i + 1 + paramsOfficeActivity2.row * (paramsOfficeActivity2.page - 1), ...data[i] });
    }

    return datasets;
  }

  function datasetsListTablePopularDoc(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({ no: i + 1 + paramsTablePopularDoc.row * (paramsTablePopularDoc.page - 1), ...data[i] });
    }

    return datasets;
  }

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTableDocActivityLog.row * (paramsTableDocActivityLog.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  function handleChangeOffice(value) {
    setFilterOffice(value);
  }

  function handleChangeDate(name, value) {
    setFilterDate({ ...filterDate, [name]: value });
  }

  function handleChangeTable(pagination) {
    setParamsTableDocActivityLog({
      ...paramsTableDocActivityLog,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row gutter={[24, 24]} justify="space-between">
          <Col>
            <h1 className="text-xl md:text-2xl font-medium">{t("overview")}</h1>
          </Col>
          {userProfile?.office_type_id == 1 && (
            <Col>
              <Row gutter={[12, 12]} className='items-center'>
                <Col>
                  <h3>{t("Filter Data Work Unit")}</h3>
                </Col>
                <Col>
                  <Select
                    defaultValue=""
                    style={{ width: 350 }}
                    onChange={handleChangeOffice}
                    value={filterOffice}
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {userProfile?.office_type_id === 1 && (
                      <Option value="">{t("All Work Unit")}</Option>
                    )}
                    {listOffice?.map((item, index) => {
                      return (
                        <Option key={index} value={item.office_id}>
                          {item.office_name}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col sm={24} md={parseInt(24 / getActivedCard().length)}>
            <CardPrimary
              title={t('Work Unit Information')}
              data={[
                { label: t("Total Active User"), value: dataCard?.total_user },
                { label: t("Total Work Unit"), value: dataCard?.total_office }
              ]}
            />
          </Col>
          {dataModule?.stamp_m == true && (
            <Col sm={24} md={parseInt(24 / getActivedCard().length)}>
              <CardPrimary
                title={'E-Meterai'}
                data={[
                  { label: t("Total Generated E-Meterai"), value: dataCard?.generated_meterai },
                  { label: t("Used E-Meterai"), value: dataCard?.used_meterai },
                  { label: t("Unused E-Meterai"), value: dataCard?.total_meterai, warning: dataCard?.warning_meterai }
                ]}
              />
            </Col>
          )}
          {dataModule?.sign_m == true && (
            <Col sm={24} md={parseInt(24 / getActivedCard().length)}>
              <CardPrimary
                title={'E-Sign'}
                data={[
                  { label: t("Total E-Sign"), value: Number(dataCard?.total_signature + dataCard?.used_signature) },
                  { label: t("Used E-Sign"), value: dataCard?.used_signature },
                  { label: t("Unused E-Sign"), value: dataCard?.total_signature }
                ]}
              />
            </Col>
          )}
        </Row>
      </Col>

      {/* Persiapan UAT & SIT Overview Platform VAS BJT */}
      {/* <Col span={24}>
        <Row gutter={[24, 24]}>
          <Skeleton loading={isFetchingCard} active paragraph={{ rows: 2 }}>
            {!filterOffice && (
              <Col xs={24} sm={12} md={6} lg={3}>
                <div>
                  <h3 className="text-gray-500 text-sm md:text-base">
                    {t("TotalDocument")}
                  </h3>
                  <h2 className="text-blue-700 text-3xl md:text-4xl font-bold">
                    {dataCard ? dataCard?.total_document : 0}
                  </h2>
                </div>
              </Col>
            )}

            <Col xs={24} sm={12} md={6} lg={3}>
              <div>
                <h3 className="text-gray-500 text-sm md:text-base">
                  {t("TotalActive")}
                </h3>
                <h2 className="text-blue-700 text-3xl md:text-4xl font-bold">
                  {dataCard ? dataCard?.total_user : 0}
                </h2>
              </div>
            </Col>
            {!filterOffice && (
              <Col xs={24} sm={12} md={6} lg={3} hidden={filterOffice !== ""}>
                <div>
                  <h3 className="text-gray-500 text-sm md:text-base">
                    {t("TotalOffice")}
                  </h3>
                  <h2 className="text-blue-700 text-3xl md:text-4xl font-bold">
                    {dataCard ? dataCard?.total_office : 0}
                  </h2>
                </div>
              </Col>
            )}
            {!filterOffice && (
              <Col xs={24} sm={12} md={6} lg={3}>
                <div>
                  <h3 className="text-gray-500 text-sm md:text-base">
  
                    {t("Unused e-Materai")}
                  </h3>
                  <h2 className="text-blue-700 text-3xl md:text-4xl font-bold">
                    {dataCard?.total_meterai || 0}
                  </h2>
                </div>
              </Col>
            )}
            <Col xs={24} sm={12} md={6} lg={3}>
              <div>
                <h3 className="text-gray-500 text-sm md:text-base">
                  {t("usedemet")}
                </h3>
                <h2 className="text-blue-700 text-3xl md:text-4xl font-bold">
                  {dataCard?.used_meterai || 0}
                </h2>
              </div>
            </Col>

           
            {!filterOffice && (
              <Col xs={24} sm={12} md={6} lg={3}>
                <div>
                  <h3 className="text-gray-500 text-sm md:text-base">
                    {t("sign")}
                  </h3>
                  <h2 className="text-blue-700 text-3xl md:text-4xl font-bold">
                    {dataCard?.total_signature || 0}
                  </h2>
                </div>
              </Col>
            )}

           
            {!filterOffice && (
              <Col xs={24} sm={12} md={6} lg={3}>
                <div>
                  <h3 className="text-gray-500 text-sm md:text-base">
                    {t("usedsign")}
                  </h3>
                  <h2 className="text-blue-700 text-3xl md:text-4xl font-bold">
                    {dataCard?.used_signature || 0}
                  </h2>
                </div>
              </Col>
            )}
           
            <Col xs={24} sm={12} md={6} lg={3}>
              <div>
                <h3 className="text-gray-500 text-sm md:text-base">
                {t("quotablock")}
                </h3>
                <h2 className="text-blue-700 text-3xl md:text-4xl font-bold">
                  {dataCard?.total_blockchain ||0}
                </h2>
              </div>
            </Col>
          </Skeleton>
        </Row>
      </Col> */}

      {/* Overview Usage E-Materai */}
      <Col span={24}>
        <Card className="rounded-md">
          <Skeleton loading={isFetchingCard || isFetchingUsageMaterai} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col xs={24} lg={8} hidden={filterOffice !== ""}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={pieOptions(t("totalMeteraiUsage"), "e-Materai",
                    [t("Used"), t("Unused")],
                    [dataCard?.used_meterai, dataCard?.total_meterai],
                    ["#FF0000", "#1D4ED8"])}
                />
              </Col>
              <Col xs={24} lg={filterOffice !== "" ? 24 : 16}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsLine(
                    t("totalUsage6Month"), "Total Stamp",
                    dataUsageMaterai?.map(({ period }) => period),
                    dataUsageMaterai?.map(({ usage }) => parseInt(usage)),
                    dataUsageMaterai?.map(({ cost }) => parseInt(cost)),
                  )
                  }
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>

      {/* Persiapan UAT & SIT Overview Platform VAS BJT */}
      {/* <Col span={24}>
        <Card className="rounded-md">
          <Skeleton loading={isFetchingBar} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("totdocactivity")}
                    </h3>
                  </Col>
                  <Col>
                    <Row gutter={[12, 12]}>
                      <Select
                        style={{ width: 120 }}
                        onChange={(value) =>
                          handleChangeDate("doc_activity", value)
                        }
                        value={filterDate.doc_activity}
                      >
                        {listDate?.map((item, index) => {
                          return (
                            <Option key={index} value={item}>
                              {`Last ${item}`}
                            </Option>
                          );
                        })}
                      </Select>
                      <Dropdown
                        overlay={menuFilterTotalDocActivity}
                        onVisibleChange={() => {
                          setIsVisibleFilterDocActivity(prevState => !prevState)
                        }}
                        visible={isVisibleFilterDocActivity}
                        trigger={["click"]}
                      >
                        <Button
                          type="primary"
                          ghost
                          icon={<FilterOutlined />}
                        />
                      </Dropdown>
                    </Row>
                  </Col>

                </Row>
              </Col>
              <Col span={24}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsBar({
                    labels: datasetsBar(dataBar).date,
                    datasets: [
                      // {
                      //   name: "Send",
                      //   data: datasetsBar(dataBar).assign_document,
                      //   color: "#323331",
                      // },
                      {
                        name: "Upload",
                        data: datasetsBar(dataBar).upload,
                        color: "#07418C",
                      },
                      {
                        name: "Download",
                        data: datasetsBar(dataBar).download,
                        color: "#DBDBDB",
                      },
                      // {
                      //   name: "Read Details",
                      //   data: datasetsBar(dataBar).read_detail,
                      //   color: "#8B8B8B",
                      // },
                      {
                        name: "e-Meterai",
                        data: datasetsBar(dataBar).stamp,
                        color: "#b0e0e8",
                      },
                      {
                        name: "e-Sign",
                        data: datasetsBar(dataBar).signing,
                        color: "#b0e8da",
                      },
                    ],
                  })}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col> */}

      {/* Persiapan UAT & SIT Overview Platform VAS BJT */}
      {/* <Col span={24} hidden={filterOffice !== ""}>
        <Card className="rounded-md">
          <Skeleton loading={isFetchingBarOffice} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("Docactivity")}
                    </h3>
                  </Col>
                  <Col>
                    <Select
                      style={{ width: 120 }}
                      onChange={(value) =>
                        handleChangeDate("doc_activity_office", value)
                      }
                      value={filterDate.doc_activity_office}
                    >
                      {listDate?.map((item, index) => {
                        return (
                          <Option key={index} value={item}>
                            {`Last ${item}`}
                          </Option>
                        );
                      })}

                    </Select>
                    <Dropdown
                      overlay={menuFilterTotalDocActivityOffice}
                      onVisibleChange={() => {
                        setIsVisibleFilterDocActivityOffice(prevState => !prevState)
                      }}
                      visible={isVisibleFilterDocActivityOffice}
                      trigger={["click"]}
                    >
                      <Button
                        type="primary"
                        ghost
                        icon={<FilterOutlined />}
                      />
                    </Dropdown>
                  </Col>

                </Row>
              </Col>
              <Col span={24}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsBar({
                    labels: datasetsBar(dataBarOffice).office_name,
                    datasets: [
                      // {
                      //   name: "Send",
                      //   data: datasetsBar(dataBarOffice).assign_document,
                      //   color: "#323331",
                      // },
                      {
                        name: "Upload",
                        data: datasetsBar(dataBarOffice).upload,
                        color: "#07418C",
                      },
                      {
                        name: "Download",
                        data: datasetsBar(dataBarOffice).download,
                        color: "#DBDBDB",
                      },
                      // {
                      //   name: "Read Details",
                      //   data: datasetsBar(dataBarOffice).read_detail,
                      //   color: "#8B8B8B",
                      // },
                      {
                        name: "e-Meterai",
                        data: datasetsBar(dataBarOffice).stamping,
                        color: "#b0e0e8",
                      },
                      {
                        name: "e-Sign",
                        data: datasetsBar(dataBarOffice).signing,
                        color: "#b0e8da",
                      },
                    ],
                  })}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col> */}

      <Col span={24} hidden={filterOffice !== ""}>
        <Card className="rounded-md">
          <Skeleton loading={isFetchingUsageActivities} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      Diagram {t("Usage Activity Per Work Unit")}
                    </h3>
                  </Col>
                  <Col>
                    <Row gutter={[12, 12]}>
                      <Col>
                        <Select
                          style={{ width: "100%" }}
                          onChange={(value) =>
                            handleChangeDate("usage_activity_office", value)
                          }
                          value={filterDate.usage_activity_office}
                        >
                          {listDate?.map((item, index) => {
                            return (
                              <Option key={index} value={item}>
                                {t(`Last ${item}`)}
                              </Option>
                            );
                          })}
                        </Select>
                      </Col>
                      <Col>
                        <Dropdown
                          overlay={menuFilterUsageActivities}
                          onVisibleChange={() => setIsVisibleFilterUsageActivities(prevState => !prevState)}
                          visible={isVisibleFilterUsageActivities}
                          trigger={["click"]}
                        >
                          <Button
                            type="primary"
                            ghost
                            icon={<FilterOutlined />}
                          />
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[24, 24]}>
                  {[0, 1].map(current => (
                    <Col xs={24} sm={12}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsBar({
                          labels: dataDistribution(current, 2, usageActivitiesDiagram.office_name || []),
                          datasets: [
                            {
                              name: selectedUsageActivity.label,
                              label: { enabled: false },
                              data: dataDistribution(current, 2, usageActivitiesDiagram[selectedUsageActivity.value] || []),
                            }
                          ],
                        }, true, usageActivities.length)}
                      />
                    </Col>
                  ))}
                </Row>

              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>

      {/* Persiapan UAT & SIT Overview Platform VAS BJT */}
      {/* <Col span={24} hidden={filterOffice !== ""}>
        <Card className="rounded-md">
          <Skeleton loading={isFetchingOfficeActivity} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col span={[24, 24]}>
                <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  Office Activity
                </h3>
              </Col>
              <Col span={12}>
                <Dropdown
                  overlay={menuFilterTotalOfficeActivity}
                  onVisibleChange={() => {
                    setIsVisibleFilterOfficeActivity2(prevState => !prevState)
                  }}
                  visible={isVisibleFilterOfficeActivity2}
                  trigger={["click"]}
                >
                  <Button
                    type="primary"
                    ghost
                    icon={<FilterOutlined />}
                  />
                </Dropdown>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsOffices}
                  dataSource={datasetsTableofficeactivity(dataOfficeActivity)}
                  scroll={{ x: 600 }}
                  onChange={handleChangeTable}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col> */}

      <Col span={24} hidden={filterOffice !== ""}>
        <Card className="rounded-md">
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Row gutter={[24, 24]} justify='space-between'>
                <Col>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    {t("Usage Activity Per Work Unit")}
                  </h3>
                </Col>
                <Col>
                  <Row gutter={[12, 12]}>
                    <Col>
                      <Col>
                        <Search
                          placeholder={t("Search")}
                          allowClear
                          onSearch={onSearch}
                          onChange={(e) =>
                            setTimeout(() => {
                              onSearch(e.target.value);
                            }, 1000)
                          }
                          style={{
                            width: 200,
                            borderRadius: "10px !important",
                          }}
                        />
                      </Col>
                    </Col>
                    <Col>
                      <Dropdown
                        overlay={menuFilterUsageActivitiesPerOffice}
                        onVisibleChange={() => {
                          setIsVisibleUsageActivitiesPerOffice(prevState => !prevState)
                        }}
                        visible={isVisibleUsageActivitiesPerOffice}
                        trigger={["click"]}
                      >
                        <Button
                          type="primary"
                          ghost
                          icon={<FilterOutlined />}
                        />
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                columns={columnsUsageActivitiesPerOffice}
                dataSource={createPagination(dataListUsageOffices, paramsUsageActivitiesPerOffice)}
                scroll={{ x: 600 }}
                loading={isFetchingUsageOffices}
                pagination={{
                  current: paramsUsageActivitiesPerOffice.page,
                  pageSize: paramsUsageActivitiesPerOffice.row,
                  total: dataListUsageOffices[0] ? dataListUsageOffices[0].total : 0
                }}
                onChange={(pagination) => setParamsUsageActivitiesPerOffice({ ...paramsUsageActivitiesPerOffice, page: pagination.current, row: pagination.pageSize })}
              />
            </Col>
          </Row>
        </Card>
      </Col>

      {/* Persiapan UAT & SIT Overview Platform VAS BJT */}
      {/* <Col span={24} hidden={filterOffice !== ""}>
        <Card className="rounded-md">
          <Skeleton loading={isFetchingOfficeDeptUsrAct} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col span={[24, 24]}>
                <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  {t("User Activity Per Office")}
                </h3>
              </Col>
              <Col span={12}>
                <Dropdown
                  overlay={menuFilterTotalOfficeDeptUsrAct}
                  onVisibleChange={() => {
                    setIsVisibleFilterOfficeDeptUsrAct(prevState => !prevState)
                  }}
                  visible={isVisibleFilterOfficeDeptUsrAct}
                  trigger={["click"]}
                >
                  <Button
                    type="primary"
                    ghost
                    icon={<FilterOutlined />}
                  />
                </Dropdown>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsOfficesDeptUsr}
                  dataSource={datasetsTableofficedeptusract(dataOfficeDeptUsrAct)}
                  scroll={{ x: 600 }}
                  onChange={handleChangeTable}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col> */}

      {/* Monthly Recap Activity */}
      <Col span={24} hidden={filterOffice === ""}>
        <Card className='rounded-md'>
          <Skeleton loading={isFetchingMonthlyActivities} active paragraph={{ rows: 8 }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify='space-between'>
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("Monthly Activity")}
                    </h3>
                  </Col>
                  <Col>
                    <Dropdown
                      overlay={menuFilterMonthlyActivity}
                      onVisibleChange={() => setIsVisibleMonthly(prevState => !prevState)}
                      visible={isVisibleMonthly}
                      trigger={["click"]}
                    >
                      <Button
                        type="primary"
                        ghost
                        icon={<FilterOutlined />}
                      />
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsMonthlyActivities}
                  dataSource={createPagination(dataListMonthlyActivities, paramsMonthlyActivities)}
                  scroll={{ x: 600 }}
                  pagination={{
                    current: paramsMonthlyActivities.page,
                    pageSize: paramsMonthlyActivities.row,
                    total: dataListMonthlyActivities[0] ? dataListMonthlyActivities[0].total : 0
                  }}
                  onChange={(pagination) => setParamsMonthlyActivities({ ...paramsMonthlyActivities, page: pagination.current, row: pagination.pageSize })}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>

      {/* Department Activity */}
      <Col span={24} hidden={filterOffice === ""}>
        <Card className="rounded-md">
          <Skeleton loading={isFetchingDeptActivity} active paragraph={{ rows: 4 }}>
            <Row gutter={[28, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify='space-between'>
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("Departement Activity")}
                    </h3>
                  </Col>
                  <Col>
                    <Dropdown
                      overlay={menuFilterTotalDepartementActivity}
                      onVisibleChange={() => {
                        setIsVisibleFilterDepartementActivity(prevState => !prevState)
                      }}
                      visible={isVisibleFilterDepatartementActivity}
                      trigger={["click"]}
                    >
                      <Button
                        type="primary"
                        ghost
                        icon={<FilterOutlined />}
                      />
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsDepartements}
                  dataSource={datasetsTable(dataDeptActivity)}
                  scroll={{ x: 600 }}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>

      <Col span={24} hidden={filterOffice === ""}>
        <Card className="rounded-md">
          <Skeleton loading={isFetchingUserActivity} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify='space-between'>
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("User Activity")}
                    </h3>
                  </Col>
                  <Col>
                    <Dropdown
                      overlay={menuFilterTotalUserActivity}
                      onVisibleChange={() => {
                        setIsVisibleFilterUserActivity(prevState => !prevState)
                      }}
                      visible={isVisibleFilterUserActivity}
                      trigger={["click"]}
                    >
                      <Button
                        type="primary"
                        ghost
                        icon={<FilterOutlined />}
                      />
                    </Dropdown>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Table
                  columns={columnsUsers}
                  dataSource={datasetsTable(dataUserActivity)}
                  scroll={{ x: 600 }}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>

      <Col span={24}>
        <Card className="rounded-md">
          <Skeleton loading={isFetchingDocActivityLog} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("lastday30")} {" "}
                      <span className="text-gray-400">
                      </span>
                    </h3>
                  </Col>
                  <Dropdown
                    overlay={menuFilterDocActivity}
                    onVisibleChange={() => {
                      setIsVisibleFilterDocActivityLog(prevState => !prevState)
                    }}
                    visible={isVisibleFilterDocActivityLog}
                    trigger={["click"]}
                  >
                    <Button
                      type="primary"
                      ghost
                      icon={<FilterOutlined />}
                    />
                  </Dropdown>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsDocLog}
                  dataSource={datasetsListTable(dataDocActivityLog)}
                  loading={isFetchingDocActivityLog}
                  scroll={{ x: 800 }}
                  pagination={{
                    current: paramsTableDocActivityLog.page,
                    pageSize: paramsTableDocActivityLog.row,
                    total: dataDocActivityLog[0] ? dataDocActivityLog[0]?.total : 0,
                    showSizeChanger: false,
                  }}
                  onChange={handleChangeTable}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>

      {/* Persiapan UAT & SIT Overview Platform VAS BJT */}
      {/* <Col span={24}>
        <Card className="rounded-md">
          <Skeleton loading={isFetchingPopularDocument} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("populardoc")}
                    </h3>
                  </Col>
                  <Col>
                    <Select
                      style={{ width: 120 }}
                      onChange={(value) =>
                        handleChangeDate("popular_doc", value)
                      }
                      value={filterDate.popular_doc}
                    >
                      {listDate?.map((item, index) => {
                        return (
                          <Option key={index} value={item}>
                            {`Last ${item}`}
                          </Option>
                        );
                      })}
                    </Select>
                    <Dropdown
                      overlay={menuFilterTotalPopularDoc}
                      onVisibleChange={() => {
                        setIsVisibleFilterPopularDoc(prevState => !prevState)
                      }}
                      visible={isVisibleFilterPopularDoc}
                      trigger={["click"]}
                    >
                      <Button
                        type="primary"
                        ghost
                        icon={<FilterOutlined />}
                      />
                    </Dropdown>
                  </Col>                            
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsDocument}
                  dataSource={datasetsListTable(dataPopularDocument)}
                  loading={isFetchingPopularDocument}
                  scroll={{ x: 800 }}
                  pagination={false}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col> */}
    </Row>
  );
}