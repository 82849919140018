import { Alert, Modal } from "antd";
import React from "react";
import { ReactComponent as AlertCircleIcon } from "../../assets/icons/alert-circle-outline.svg";

export default function ModalWarning(props) {
  return (
    <Modal
      {...props}
      title={false}
      className="border-yellow-200 rounded-md"
      style={{ borderTopWidth: 14 }}
      footer={false}
    >
      <div className="flex flex-col space-y-4 px-8 -mt-20">
        <div className="flex flex-col space-y-2 justify-center items-center">
          <AlertCircleIcon className="w-20" />
          <p className="text-gray-800 font-semibold text-xl md:text-2xl">
            {props.title}
          </p>
          {props.totalPage && (
            <div className="flex flex-row items-center justify-center">
              {[...Array(props.totalPage).keys()].map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item > 0 && (
                      <div
                        className={`w-4 h-1 ${
                          item + 1 <= props.page
                            ? "bg-yellow-300"
                            : "bg-gray-400"
                        }`}
                      />
                    )}
                    <p
                      className={`px-2 rounded-full text-white ${
                        item + 1 <= props.page ? "bg-yellow-300" : "bg-gray-400"
                      }`}
                    >
                      {item + 1}
                    </p>
                  </React.Fragment>
                );
              })}
            </div>
          )}
          {props.subtitle && (
            <Alert
              className="text-center"
              message={props.subtitle}
              type={
                props.alertError
                  ? "error"
                  : props.alertSuccess
                  ? "success"
                  : "warning"
              }
            />
          )}
        </div>
        {props.children}
      </div>
    </Modal>
  );
}
