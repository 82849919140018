import { CheckOutlined, LeftOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import Button from "../../components/Button";
import { numberCommas } from "../../utils";
import { useTranslation } from "react-i18next";

export default function PaymentMethod({
  next,
  back,
  handlePaymentMethod,
  tabValue,
  data,
  dataMethod,
  processAction,
}) {
  const { t } = useTranslation();

  const [adminFee, setAdminFee] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    //console.log(data);
  }, []);

  const logoUrl = (val) => {
    switch (val) {
      case 1:
        return "/images/cimb.png";

      case 2:
        return "/images/mandiri.png";

      case 3:
        return "/images/ovo.png";

      case 4:
        return "/images/dana.png";

      case 5:
        return "/images/shopeepay.png";

      case 6:
        return "/images/shopeepay.png";

      case 7:
        return "/images/bca.png";

      case 8:
        return "/images/bni.png";

      case 9:
        return "/images/bri.png";

      case 10:
        return "/images/bca.png";

      case 11:
        return "/images/bnc-logo.png";

      case 12:
        return "/images/finpay.png";

      case 13:
        return "/images/nobu-qris.png";

      case 14:
        return "/images/bri.png";

      default:
        return "/images/bca.png";
    }
  };

  function PaymentCard({
    imageUrl,
    title,
    first,
    last,
    tab,
    tabValue,
    onClick,
  }) {
    const active = tab === tabValue;

    return (
      <div
        className={`transition duration-500 transform ${
          !active && "hover:-translate-y-1"
        }`}
        onClick={() => onClick(tab)}
      >
        <div
          className={`flex flex-row justify-between items-center px-8 py-3 cursor-pointer transition duration-500 ${
            first ? "rounded-t-md" : last && "rounded-b-md"
          } ${active ? "bg-blue-100" : "bg-white"}`}
        >
          <div className="flex flex-row">
            <div className="w-24 md:w-32">
              <img src={imageUrl} alt={title} width={45} height={45} />
            </div>
            <p className="text-base md:text-lg text-blue-800 font-medium">
              {title}
            </p>
          </div>
          {active && <CheckOutlined className="text-lg text-blue-800" />}
        </div>
        {!active && (
          <div className="px-8">
            <Divider className="my-0" />
          </div>
        )}
      </div>
    );
  }

  const handleSelectPaymentMethod = (e, acc_q) => {
    setAdminFee(acc_q?.admin_fee);
    console.log(acc_q);
    handlePaymentMethod(e);
  };
  //console.log(dataMethod[0].pgchannel_list);
  return (
    <Fade>
      <Row gutter={[24, 12]} justify="center">
        <Col xs={24} md={18}>
          <div className="rounded-md px-6 py-3 bg-white">
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <div className="cursor-pointer" onClick={back}>
                  <LeftOutlined className="hover:text-gray-400" />
                </div>
              </Col>
              <Col>
                <h1 className="text-lg md:text-xl font-medium">
                  {t("Payment Method")}
                </h1>
              </Col>
              <Col>
                <LeftOutlined className="hidden" />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={24} md={18}>
          {dataMethod[0].pgchannel_list.map((channel) => (
            <Row
              key={channel.pgchannel_name}
              gutter={[24, 12]}
              justify="space-between"
              style={{ marginTop: `5px` }}
            >
              <Col span={24}>
                <h1 className="text-sm md:text-base font-medium text-blue-700">
                  {t(channel.pgchannel_name)}
                </h1>
              </Col>
              <Col span={24}>
                <div className="rounded-md bg-white">
                  <Row gutter={[24, 0]}>
                    <Col span={24}>
                      {channel.acq_list.map((acq_list) => (
                        <PaymentCard
                          title={acq_list.acq_name}
                          imageUrl={logoUrl(acq_list.acq_id)}
                          tab={acq_list.acq_code}
                          tabValue={tabValue}
                          onClick={(e) =>
                            handleSelectPaymentMethod(e, acq_list)
                          }
                        />
                      ))}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          ))}
        </Col>
        <Col xs={24} md={18}>
          <Card className="rounded-md">
            <Row
              gutter={[24, 24]}
              justify="space-between"
              hidden={adminFee == null}
            >
              <Col>
                <h1 className="text-base md:text-lg font-medium text-gray-500">
                  {t("Admin Fee")}
                </h1>
              </Col>
              <Col>
                <h1 className="text-base md:text-lg font-medium text-blue-700">
                  {`Rp ${numberCommas(adminFee)},-`}
                </h1>
              </Col>
            </Row>
            <Row
              gutter={[24, 24]}
              justify="space-between"
              hidden={adminFee == null}
            >
              <Col>
                <h1 className="text-base md:text-lg font-medium text-gray-500">
                  {t("Subtotal")}
                </h1>
              </Col>
              <Col>
                <h1 className="text-base md:text-lg font-medium text-blue-700">
                  {`Rp ${numberCommas(data?.total)},-`}
                </h1>
              </Col>
            </Row>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <h1 className="text-base md:text-lg font-medium text-gray-500">
                  {t("Total Payment")}
                </h1>
              </Col>
              <Col>
                <h1 className="text-base md:text-lg font-medium text-blue-700">
                  {`Rp ${numberCommas(Number(data?.total || 0) + Number(adminFee || 0))},-`}
                </h1>
              </Col>
              <Col span={24}>
                <Button
                  className="w-full"
                  type="primary"
                  onClick={processAction}
                >
                  {t("Pay Now")}
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fade>
  );
}
