import { Card, Col, Dropdown, Menu, Radio, Row, Select, Skeleton, Pagination } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { useEffect, useState } from "react";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { errorMessage, timeSince } from "../../utils";
import { useHistory } from "react-router";
import { FilterOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, NotificationList } from "../../components";
import { useFetchNotificationQuery, useNotifIsReadMutation } from "../../config/features";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export default function Notifications() {
  const history = useHistory();
  const { t } = useTranslation();
  const [visibleFilter, setVisibleFilter] = useState(false);
  const { data: userProfile = {} } = useFetchProfileUserQuery();
  const [notifParams, setNotifParams] = useState({
    profile: userProfile,
    page: 1,
    row: 10,
    order: "desc",
    is_read: ""
  })
  const { data: dataNotifications } = useFetchNotificationQuery(notifParams);
  const [notifIsRead] = useNotifIsReadMutation();

  function handleResetFilter() {}

  const handlePageChange = (page) => {
    setNotifParams(prevState => ({
      ...prevState,
      page
    }))
  }

  useEffect(() => {
    setTimeout(() => {
      notifIsRead(notifParams)
    }, 2000)
  }, [notifIsRead, notifParams])


  const menuFilter = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">{t("Filter")}</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={handleResetFilter}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Notification Status")}</p>
            <Select
              defaultValue="date"
              style={{ width: "100%" }}
              onChange={(val) => {
                setNotifParams(prevNotifParams => ({ ...prevNotifParams, is_read: val }))
              }}
              value={notifParams?.is_read}
            >
              <Option value={true}>{t("Read")}</Option>
              <Option value={false}>{t("Not Read")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <p className="text-gray-500 text-base font-medium">{t("Sort")}</p>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="desc"
              buttonStyle="solid"
              onChange={(e) => {
                setNotifParams(prevNotifParams => ({ ...prevNotifParams, order: e.target.value }))
              }}
              value={notifParams?.order}
            >
              <Radio value="asc">{t("ascending")}</Radio>
              <Radio value="desc">{t("descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };


  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} lg={18} xl={12} className="mx-auto">
        <Card bodyStyle={{ width: "100%"}} className="rounded-md mb-4">
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <LeftOutlined onClick={() => history.goBack()}/>
            </Col>
            <Col>
              <h2 className="text-xl md:text-2xl font-bold">
                {t("Notification List")}
              </h2>
              </Col>
            <Col>
              <Dropdown
                overlay={menuFilter}
                onVisibleChange={handleVisibleFilter}
                visible={visibleFilter}
                trigger={["click"]}
              >
                <Button
                  className="rounded-full"
                  type="primary"
                  icon={<FilterOutlined />}
                />
              </Dropdown>
            </Col>
          </Row>
        </Card>
        <NotificationList className="mb-4">
          {dataNotifications?.data?.map((notif) => {
            return (
              <NotificationList.Item 
                key={`notificationListItem-${notif.id}`}
                title={notif?.title} 
                message={notif?.message}
                date={timeSince(new Date(notif?.created_at))}
                isRead={notif?.is_read}
                onClick={() => history.push(notif?.endpoint)}
              />
            )
          })}
        </NotificationList>
        <footer className="text-right">
          <Pagination 
            className="bg-white inline-block p-2 rounded-full" 
            simple 
            current={notifParams?.page} 
            total={dataNotifications?.total} 
            onChange={handlePageChange}
          />  
        </footer>
      </Col>
    </Row>
  );
}
