import { Card, Col, DatePicker, Dropdown, Form, Row, Select, Skeleton, Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchListOfficeQuery, useFetchListReportRecapMutation, useFetchListReportRekapQuery, useFetchProfileUserQuery } from "../../config/features";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { RightOutlined } from "@ant-design/icons";
import { getMonthName, summarizeVal } from "../../utils/array";
import { errorMessage, exportExcel, getBlob, numberCommas } from "../../utils";
import { Button } from "../../components";
import { SiMicrosoftexcel } from "react-icons/si";
import { createPagination } from "../../utils/table";
import { objectToQueryString } from "../../utils/queryString";
export default function Rekapitulasi() {
  const { t } = useTranslation()
  const history = useHistory()

  const { data: userProfile = {} } = useFetchProfileUserQuery();

  const [paramsOffice, setParamsOffice] = useState({
    sort_by: "created_at",
    order: "desc",
    search: ""
  })

  const [paramsRekap, setParamsRekap] = useState({
    office_id: "",
    monthDate: "",
    page: 1,
    row: 10,
    sort_by: "used_at",
    order: "desc",
  })

  const [rekapResult, setRekapResult] = useState({})
  const [getDataListRekap, { isSuccess, isLoading, data: dataListRekap, error, isError }] = useFetchListReportRecapMutation()

  const {
    data: dataListOffice = [],
    refetch: refetchOffice,
  } = useFetchListOfficeQuery(paramsOffice);

  const columns1 = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no'
    },
    {
      title: t('Work Unit'),
      dataIndex: 'office_name',
      key: 'office_name'
    },
    {
      title: t('Stamp'),
      dataIndex: 'stamp',
      key: 'stamp'
    },
    {
      title: 'Rp',
      dataIndex: 'rp',
      key: 'rp',
      render: (rp) => numberCommas(rp)
    },
    {
      render: (record) => (
        <div className="group cursor-pointer"
          onClick={() => history.push(`/Rekapitulasi/detail/${record.office_id}/${paramsRekap.monthDate}`)}
        >
          <RightOutlined className="group-hover:text-blue-500" />
        </div>
      )
    }
  ]
  const columns2 = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no'
    },
    {
      title: t('Department'),
      dataIndex: 'dept_name',
      key: 'dept_name'
    },
    {
      title: t('Stamp'),
      dataIndex: 'stamp',
      key: 'stamp'
    },
    {
      title: 'Rp',
      dataIndex: 'rp',
      key: 'rp',
      render: (rp) => numberCommas(rp)
    },
    {
      render: (record) => (
        <div className="group cursor-pointer"
          onClick={() => history.push(`/Rekapitulasi/detail/${record.office_id}/${record.dept_id}/${paramsRekap.monthDate}`)}
        >
          <RightOutlined className="group-hover:text-blue-500" />
        </div>
      )
    }
  ]
  const columns3 = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no'
    },
    {
      title: t("User"),
      dataIndex: 'full_name',
      key: 'full_name'
    },
    {
      title: t('Department'),
      dataIndex: 'dept_name',
      key: 'dept_name'
    },
    {
      title: t("Document Type"),
      dataIndex: 'doc_type',
      key: 'doc_type',
      render: (doc_type) => t(doc_type)
    },
    {
      title: t("Document Name"),
      dataIndex: 'doc_name',
      key: 'doc_name'
    },
    {
      title: t("Stamp"),
      dataIndex: "stamp",
      key: "stamp"
    },
    {
      title: t("Upload Date"),
      dataIndex: 'stamping_date',
      key: 'stamping_date'
    }
  ]

  let [columnsRekap, setColumnsRekap] = useState(columns1)
  useEffect(() => {
    if (dataListRekap?.data?.length > 0) {
      if (dataListRekap.data[0].full_name) {
        setColumnsRekap(columns3)
      }
      else if (dataListRekap.data[0].dept_name) {
        setColumnsRekap(columns2)
      }
      else {
        setColumnsRekap(columns1)
      }
    }
    if (dataListRekap?.summary) {
      const splitted = dataListRekap.summary.period.split(" ")
      setRekapResult({
        "Work Unit": dataListRekap.summary.office,
        // "User": userProfile.role_name == 'Basic Access' || userProfile.office_type_name == 'Branch' ? dataListRekap.summary.user : null,
        ...userProfile?.office_type_id == 1 && paramsRekap.office_id ? { "Department": "All" } : {},
        ...userProfile?.office_type_id == 3 || userProfile?.role_id == 1 ? { "User": dataListRekap.summary.user } : {},
        "Period": `${t(splitted[0])} ${splitted[splitted.length - 1]}`,
        "Total Stamp": numberCommas(dataListRekap.summary.total_stamp),
        "Total (Rp)": `Rp ${numberCommas(dataListRekap.summary.total_rp)}`
      })
    }
  }, [dataListRekap, userProfile])

  const handleSumit = (values) => {
    if (values.monthDate) {
      values.monthDate = moment(values.monthDate).utc(false).format("YYYYMM").toString()
    }
    else {
      values.monthDate = ''
    }
    if (!values.office_id) {
      values.office_id = ''
    }
    getDataListRekap({ ...paramsRekap, ...values });
    setParamsRekap({
      ...paramsRekap,
      ...values
    })
  }

  const handleClickExportExcel = () => {
    const { row, page, ...otherParams } = paramsRekap;
    const queryString = objectToQueryString({ export_format: 'excel', ...otherParams });
    getBlob(`/report/rekapitulasi?${queryString}`)
      .then(response => exportExcel(response.data, `Data Rekapitulasi_${paramsRekap.monthDate}_${new Date()}`))
      .catch(err => errorMessage(err))
  }
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  function disabledDateAfter(current) {
    // Can not select months after this month
    return current > moment().endOf("month");
  }

  const handleChangeTable = (pagination) => {
    getDataListRekap({ ...paramsRekap, page: pagination.current, row: pagination.pagSize })
    setParamsRekap({
      ...paramsRekap,
      page: pagination.current,
      row: pagination.pageSize
    })
  }

  useEffect(() => {
    refetchOffice()
  }, [paramsOffice.search])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <h1 className="text-xl md:text-2xl font-medium">
          {t("recapitulation")}
        </h1>
      </Col>
      <Col span={24}>
        <Form layout="vertical" onFinish={handleSumit}>
          <Card className="rounded-md">
            <Row>
              <Col span={24}>
                <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  {`${t('filter')} ${t('recapitulation')}`}
                </h3>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={12} className="mt-4">
                <Form.Item
                  name={'office_id'}
                  label={userProfile?.office_type_id == 1 && userProfile?.role_name != 'Basic Access' ? t("Choose Work Unit") : t("Choose Department")}
                >
                  {userProfile?.office_type_id == 1 && userProfile?.role_name != 'Basic Access' ? (
                    <Select
                      showSearch
                      allowClear
                      placeholder={t("All Work Unit")}
                      options={dataListOffice?.map(item => ({
                        value: item.office_id,
                        label: item.office_name
                      }))}
                      onSearch={(val) => {
                        setParamsOffice({
                          ...paramsOffice,
                          search: val
                        })
                      }}
                      onClear={() => setParamsOffice({ ...paramsOffice, search: "" })}
                      defaultActiveFirstOption={false}
                      filterOption={filterOption}
                    />
                  ) : (
                    <Select
                      disabled
                      placeholder={userProfile?.dept_name}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12} className="mt-4">
                <Form.Item
                  name={'monthDate'}
                  label={t("Choose Month")}
                  rules={[{ required: true, message: "Please select the Month!" }]}
                >
                  <DatePicker
                    format={"YYYY/MM"}
                    picker="month"
                    placeholder={t("Select Month")}
                    disabledDate={disabledDateAfter}
                    className="w-full"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card className="rounded-md mt-2">
            <Button type="primary" htmlType="submit" className="w-full rounded-md">
              Filter
            </Button>
          </Card>
        </Form>
      </Col>

      <Skeleton loading={isLoading} active paragraph={{ rows: 8 }}>
        <Col span={24} hidden={!isSuccess}>
          <Card className="rounded-md">
            {!isLoading && dataListRekap && (
              <Row gutter={[24, 24]} justify="space-between">
                <Col span={12}>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    {t('recapresult')} :
                  </h3>
                  {Object.entries(rekapResult).map(([key, val], index) => (
                    <Row className="mt-2" hidden={!val}>
                      <Col span={5}>
                        <h4 className="text-gray-500 font-medium">{t(key)}</h4>
                      </Col>
                      <Col span={1}>
                        <h4 className="text-gray-500 font-medium">:</h4>
                      </Col>
                      <Col>
                        <h4 className="text-gray-500 font-medium">{t(val)}</h4>
                      </Col>
                    </Row>
                  ))}
                </Col>
                <Col>
                  <Button
                    type="text"
                    className="text-white hover:text-white"
                    style={{ background: "#8BC34A" }}
                    icon={<SiMicrosoftexcel />}
                    onClick={handleClickExportExcel}
                  >
                    {t("Export Excel")}
                  </Button>
                </Col>
              </Row>
            )}
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Table
                  columns={columnsRekap}
                  dataSource={createPagination(dataListRekap?.data || [], paramsRekap)}
                  scroll={{ x: 850 }}
                  loading={isLoading}
                  pagination={{
                    current: paramsRekap.page,
                    pageSize: paramsRekap.row,
                    total: dataListRekap?.total || 0
                  }}
                  onChange={handleChangeTable}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Skeleton>
    </Row>
  )
}