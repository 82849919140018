import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { rootApi, access } from "../config";

export const roleApi = createApi({
  reducerPath: "api/role",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi,
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      fetchListRole: builder.query({
        query() {
          return "/roles";
        },
        transformResponse: (response) => (response ? response.data : []),
      }),
    };
  },
});

export const { useFetchListRoleQuery } = roleApi;
