import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Form, Input, message, Row, Switch } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { NumericInput } from "../../components";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import { useEditAdminBillMutation } from "../../config/features/platform";
import { REGEXES } from "../../constant";
import { errorMessage } from "../../utils";
import { useTranslation } from "react-i18next";

export default function EditUser() {
  const { id: userId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [editUser, { isSuccess, isLoading, error, isError }] =
    useEditAdminBillMutation();

  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [payload, setPayload] = useState({});

  useEffect(() => {
    if (isSuccess) {
      setIsModalSuccess(true);
      setTimeout(() => {
        setIsModalSuccess(false);
        history.push("/user-management");
      }, 2000);
    }
    if (isError) {
      errorMessage(error);
    }
  }, [isSuccess, error, isError, history]);

  useEffect(() => {
    form.setFieldsValue(location?.state);
  }, [location, form]);

  async function handleSubmit(values) {
    try {
      values.user_status = values.status ? 1 : 2;
      values.first_name = values.first_name?.trim();
      values.last_name = values.last_name?.trim();
      values.phone = values.phone?.trim();
      values.password = values.password?.trim();
      editUser({
        userId,
        body: values,
      });
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleDone() {
    history.push("/user-management");
  }

  function toggleModalConfirm() {
    setIsModalConfirm(!isModalConfirm);
  }

  const onFinish = (values) => {
    setPayload(values);
    toggleModalConfirm();
  };

  const handleOk = () => {
    toggleModalConfirm();
    handleSubmit(payload);
  };

  return (
    <>
      <Row gutter={[24, 24]}>
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.goBack()}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      Edit Admin
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    className="md:w-40"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Update
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Row gutter={[24, 12]}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="First Name"
                        name="first_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input first name!",
                          },
                          () => ({
                            validator(_, value) {
                              if (
                                !Boolean(value?.trim()) &&
                                value?.length > 0
                              ) {
                                return Promise.reject(
                                  new Error("must be a character!")
                                );
                              }
                              if (value?.length <= 50) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("maximum 50 characters!")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input placeholder="Enter first name" maxLength={50} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Last Name"
                        name="last_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input last name!",
                          },
                          () => ({
                            validator(_, value) {
                              if (
                                !Boolean(value?.trim()) &&
                                value?.length > 0
                              ) {
                                return Promise.reject(
                                  new Error("must be a character!")
                                );
                              }
                              if (value?.length <= 50) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("maximum 50 characters!")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input placeholder="Enter last name" maxLength={50} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Please input email!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter email" disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Phone Number"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Please input phone!",
                          },
                          () => ({
                            validator(_, value) {
                              if (!Number(value) && value?.length > 0) {
                                return Promise.reject(
                                  new Error("must be a number!")
                                );
                              }
                              if (value?.length <= 14) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("maximum 14 digits!")
                              );
                            },
                          }),
                        ]}
                      >
                        <NumericInput
                          placeholder="Enter phone"
                          maxLength={14}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Role"
                        name="role_id"
                        rules={[
                          {
                            required: false,
                            message: "Please select role!",
                          },
                        ]}
                        initialValue="Admin Billing"
                      >
                        <Input disabled value="Admin Billing" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Status"
                        name="status"
                        rules={[
                          {
                            required: true,
                            message: "Please select status!",
                          },
                        ]}
                      >
                        <Switch
                          checkedChildren="Active"
                          unCheckedChildren="Inactive"
                          defaultChecked={location.state.status !== 2}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Divider />
                      <p className="text-gray-500">
                        Fill in the password if you only want to change the
                        password
                      </p>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Password"
                        name="password"
                        hasFeedback
                        rules={[
                          {
                            required: false,
                            message: "Please input password!",
                          },
                          () => ({
                            validator(_, value) {
                              if(value){
                                if (
                                  value.match(
                                    REGEXES.password
                                  )
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                   t( "Password needs to be at least 8 character, 1 uppercase, 1 special character!")
                                  )
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="Enter password" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Password Confirmation"
                        name="password_confirmation"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "The two passwords that you entered do not match!"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="Enter password confirmation" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Form>
      </Row>
      <Modal
        type="warning"
        title="Are you sure?"
        subtitle="Before the data has been updated, please make sure the data is correct."
        visible={isModalConfirm}
        onCancel={toggleModalConfirm}
      >
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={toggleModalConfirm}
          >
            Back
          </Button>
          <Button className="w-full" type="primary" onClick={handleOk}>
            Update
          </Button>
        </div>
      </Modal>
      <Modal
        title="Updated Success!"
        subtitle="Changes have been saved! Now you can continue browsing."
        visible={isModalSuccess}
        onCancel={() => setIsModalSuccess(!isModalSuccess)}
      >
        <div className="text-center">
          <Button onClick={handleDone} className="w-32" type="primary">
            Done
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
