import React from "react";
import ButtonPrimary from "./ButtonPrimary";
import ButtonSecondary from "./ButtonSecondary";
import propTypes from "prop-types";
import ButtonDanger from "./ButtonDanger";
import ButtonDefault from "./ButtonDefault";
import ButtonText from "./ButtonText";


export default function Button(props) {
  switch (props.type) {
    case "primary":
      return <ButtonPrimary {...props} />;
    case "secondary":
      return <ButtonSecondary {...props} />;
    case "danger":
      return <ButtonDanger {...props} />;
    case "text":
      return <ButtonText {...props} />;
    default:
      return <ButtonDefault {...props} />;
  }
}

Button.propTypes = {
  onClick: propTypes.func.isRequired,
  children: propTypes.any.isRequired,
  ghost: propTypes.bool,
  type: propTypes.oneOf(["primary", "secondary"]),
  htmlType: propTypes.string,
  loading: propTypes.bool,
  className: propTypes.string,
  disabled: propTypes.bool,
  icon: propTypes.any,
};
