import { Button, Card, Col, Divider, message, Modal, Row, Skeleton } from "antd";
import { useHistory } from "react-router-dom";
import { ReactComponent as VerifyIcon } from "../../assets/icons/verify-filled.svg";
import { useFetchListModuleQuery } from "../../config/features/general";
import { DIGITAL_CERTIFICATE_STATUS, STATUS_REGISTRATION_SIGN } from '../../constant';
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import RegistrationCheck from "../RegistrationKYC/RegistrationCheck";
import { useCheckCertificateQuery, useFetchNotificationQuery, useResendActivationEmailMutation } from "../../config/features";
import { errorMessage } from "../../utils";


export default function AdminInfo({ userProfile, isFetching, dataDetailClient }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [isToggled, setIsToggled] = useState(false);

  //handlevidio//


  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const { data: dataModule = {} } = useFetchListModuleQuery(
    userProfile?.office_id
  );
  const [record, setRecord] = useState(false)
  const isBasicAccess = userProfile?.role_id === 1;
  const isAllDocumentAccess = userProfile?.role_id === 2;
  const isFullAccess = userProfile?.role_id === 3;
  const isAdminBilling = userProfile?.role_id === 5;
  const isSuperAdmin = userProfile?.role_id === 4;

  // const { data: dataSignCertificate } = useCheckCertificateQuery()

  const history = useHistory()

  const options = [
    {
      label: "IN",
      value: "",
      selectedBackgroundColor: "#0097e6",
      innerHeight: 50
    },
    {
      label: "EN",
      value: "purchase",
      selectedBackgroundColor: "#0097e6"
    }
  ];

  const [triggerResendActivation, setTriggerResendActivation] = useState(false);
  const [notifParams, setNotifParams] = useState({
    profile: userProfile,
    page: 1,
    row: 1,
    order: "desc",
    is_read: "",
    title: "Account Registered"
  })
  // for mechanism resend activation email
  const { data: dataNotifications } = useFetchNotificationQuery(notifParams); // get email registration notification
  const [
    resendActivationEmail,
    {
      data: responseResendActivation,
      isError: isErrorActivation,
      error: errorActivation,
      isSuccess: isSuccessActivation,
      isLoading: isLoadingActivation
    }
  ] = useResendActivationEmailMutation();
  
  useEffect(() => {

    if (dataNotifications && userProfile) {      
      const { data } = dataNotifications;
      if (userProfile?.status_registration_peruri == 1 && data && data[0]) {
        const last_send_activation = localStorage.getItem('last_send_activation'); // date time iso string format
        const notif_register_time = data[0].created_at;
  
        const last_register_activation = new Date(last_send_activation || notif_register_time);
        const now = new Date();
  
        console.log(last_register_activation);
        
        console.log(now);
        // Calculate the difference in milliseconds
        const diffInMilliseconds = now.getTime() - last_register_activation.getTime();
  
        // Convert the difference to minutes
        const minutesPassed = diffInMilliseconds / (1000 * 60);
          
        if (minutesPassed >= 15) {
          setTriggerResendActivation(true);
        }
      }
    }
    
  }, [dataNotifications, userProfile])

  useEffect(() => {
    if (isSuccessActivation && responseResendActivation) {
      console.log(responseResendActivation);
      const now = new Date()
      localStorage.setItem('last_send_activation', now.toISOString())
      message.success(responseResendActivation.message)
      const refresh = setTimeout(() => history.go(0), 1000)
      return () => clearTimeout(refresh)
    }
  }, [isSuccessActivation, responseResendActivation])

  useEffect(() => {
    if (isErrorActivation) {
      errorMessage(errorActivation);
    }
  }, [isErrorActivation, errorActivation])

  const handleResendActivation = () => {
    resendActivationEmail();
  }
  return (

    <>
      {userProfile?.is_pic ? (
        <>
          <Col span={24}>
            <Card className="rounded-md">
              <Skeleton loading={isFetching} active paragraph={{ rows: 6 }}>
                <Row gutter={[24, 0]}>
                  <Col span={24}>
                    <Row gutter={[24, 24]} justify="space-between">
                      <Col>
                        <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                          {t("accinformation")}
                        </h3>
                      </Col>
                      {/* <Col>
                  <h4 className="text-blue-400 text-base md:text-lg font-medium">
                    Not Verified
                  </h4>
                </Col> */}
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Divider />
                  </Col>
                  <Col span={24}>
                    <Row gutter={[24, 24]}>
                      <Col xs={12} lg={8}>
                        <Row gutter={[24, 12]}>
                          <Col span={24}>
                            <p className="text-gray-400 text-xs md:text-sm">
                              {t("firstnamepic")}
                            </p>
                          </Col>
                          <Col span={24}>
                            <h4 className="text-gray-800 text-sm md:text-base font-medium">
                              {dataDetailClient?.account?.first_name_pic}
                            </h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} lg={8}>
                        <Row gutter={[24, 12]}>
                          <Col span={24}>
                            <p className="text-gray-400 text-xs md:text-sm">
                              {t("lastnamepic")}
                            </p>
                          </Col>
                          <Col span={24}>
                            <h4 className="text-gray-800 text-sm md:text-base font-medium">
                              {dataDetailClient?.account?.last_name_pic
                                ? dataDetailClient?.account?.last_name_pic
                                : "-"}
                            </h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} lg={8}>
                        <Row gutter={[24, 12]}>
                          <Col span={24}>
                            <p className="text-gray-400 text-xs md:text-sm">
                              {t("phonenumberpic")}
                            </p>
                          </Col>
                          <Col span={24}>
                            <h4 className="text-gray-800 text-sm md:text-base font-medium">
                              {dataDetailClient?.account?.phone_pic}
                            </h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} lg={8}>
                        <Row gutter={[24, 12]}>
                          <Col span={24}>
                            <p className="text-gray-400 text-xs md:text-sm">
                              {t("email")}
                            </p>
                          </Col>
                          <Col span={24}>
                            <h4 className="text-gray-800 text-sm md:text-base font-medium truncate">
                              {dataDetailClient?.account?.email_pic}
                            </h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} lg={8}>
                        <Row gutter={[24, 12]}>
                          <Col span={24}>
                            <p className="text-gray-400 text-xs md:text-sm">
                              {t("role")}
                            </p>
                          </Col>
                          <Col span={24}>
                            <h4 className="text-gray-800 text-sm md:text-base font-medium">
                              Admin Lvl Headquarter
                            </h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} lg={8}>
                        <Row gutter={[24, 12]}>
                          <Col span={24}>
                            <p className="text-gray-400 text-xs md:text-sm">
                              {t("Department")}
                            </p>
                          </Col>
                          <Col span={24}>
                            <h4 className="text-gray-800 text-sm md:text-base font-medium">
                              {userProfile?.dept_name
                                ? userProfile?.dept_name
                                : "-"}
                            </h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} lg={8}>
                        <Row gutter={[24, 12]}>
                          <Col span={24}>
                            <p className="text-gray-400 text-xs md:text-sm">
                              {t("Work Unit")}
                            </p>
                          </Col>
                          <Col span={24}>
                            <h4 className="text-gray-800 text-sm md:text-base font-medium">
                              {`${userProfile?.office_name}(${userProfile?.office_type_name})`}
                            </h4>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col xs={12} lg={8}>
                        <Row gutter={[24, 12]}>
                          <Col span={24}>
                            <p className="text-gray-400 text-xs md:text-sm">
                              Blockchain Address
                            </p>
                          </Col>
                          <Col span={24}>
                            <h4 className="text-gray-800 text-sm md:text-base font-medium truncate">
                              {
                                (userProfile?.bc_address === "On Progress" || userProfile?.bc_address === "Failed") ?
                                  <span>{userProfile?.bc_address}</span>
                                  :
                                  <a rel="noreferrer" href={`${process.env.REACT_APP_ROOT_ENV === 'prod' ? 'https://blockexplorer.vas.id' : 'https://demo-blockexplorer.vas.id'}/traces?address=${userProfile.bc_address}&version=1`} target="_blank">
                                    {userProfile?.bc_address}
                                  </a>
                              }
                            </h4>
                          </Col>
                        </Row>
                      </Col> */}
                      {(isBasicAccess || isAllDocumentAccess || isFullAccess) && dataModule?.sign_m && (
                        <>
                          <Col xs={12} lg={8}>
                            <Row gutter={[24, 12]}>
                              <Col span={24}>
                                <p className="text-gray-400 text-xs md:text-sm">
                                  {t("statusregsign")}
                                </p>
                              </Col>
                              <Col span={24}>
                                <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                  {userProfile?.status_registration_peruri === null ? t("Not Registered") :
                                    t(STATUS_REGISTRATION_SIGN[userProfile?.status_registration_peruri])
                                  }
                                  {
                                    (userProfile?.status_registration_peruri === 0 || userProfile?.status_registration_peruri === null) &&
                                    <Button className="ml-5 rounded" onClick={() => history.push('/registrationKYC')} type="primary" ghost>
                                      {t("Registration Sign")}
                                    </Button>

                                  }
                                  {
                                    (userProfile?.status_registration_peruri === 2 && (userProfile?.status_video_kyc === 1 ||
                                      userProfile?.status_video_kyc === 2) && userProfile?.is_status_video_kyc === false) &&
                                    <Button className="ml-5 rounded"
                                      onClick={() =>
                                        history.push('/registrationKYC')} type="primary" ghost>
                                      {t("Retry")}
                                    </Button>
                                  }
                                  {
                                    (userProfile?.status_registration_peruri === 3 && userProfile?.status_video_kyc === 3
                                      && userProfile?.is_status_video_kyc === false)
                                    // {t("kycmanual")} 
                                  }
                                  {
                                    (userProfile?.status_registration_peruri === 4)
                                    // {t("statusregkycsukses")} 
                                  }
                                </h4>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} lg={8}>
                            <Row gutter={[24, 12]}>
                              <Col span={24}>
                                <p className="text-gray-400 text-xs md:text-sm">
                                  {t("Status Certificate")}
                                </p>
                              </Col>
                              <Col span={24}>
                                <h4 className="text-gray-800 text-sm md:text-base font-medium truncate">
                                  {t(DIGITAL_CERTIFICATE_STATUS[userProfile?.sign_cert_status == 0 || userProfile?.sign_cert_status == 2 ? 0 : 1] || "Not Actived CA")}
                                </h4>
                                {userProfile?.sign_cert_status == 2 && (
                                  <p>
                                    {t(DIGITAL_CERTIFICATE_STATUS[2])}
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </>
                      )}

                    </Row>
                  </Col>
                </Row>
              </Skeleton>
            </Card>
          </Col>
          {dataDetailClient?.company && (
            <Col span={24}>
              <Card className={`rounded-md`}>
                <Skeleton loading={isFetching} active paragraph={{ rows: 6 }}>
                  <Row gutter={[24, 6]}>
                    <Col span={24}>
                      <Row
                        gutter={[24, 24]}
                        justify="space-between"
                        align="middle"
                      >
                        <Col>
                          <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                            {t("companyinformation")}
                          </h3>
                        </Col>
                        <Col>
                          <h4
                            className={`text-base md:text-lg font-medium ${dataDetailClient?.account?.is_verified
                              ? "text-blue-700"
                              : "text-gray-500"
                              }`}
                          >
                            {dataDetailClient?.account?.is_verified ? (
                              <div className="flex flex-row space-x-2 justify-center items-center">
                                <VerifyIcon /> <p>Verified</p>
                              </div>
                            ) : (
                              "Unverified"
                            )}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Divider />
                    </Col>
                    <Col span={24}>
                      <Row gutter={[24, 24]}>
                        <Col xs={12} lg={8}>
                          <Row gutter={[24, 12]}>
                            <Col span={24}>
                              <p className="text-gray-400 text-xs md:text-sm">
                                {t("officename")}
                              </p>
                            </Col>
                            <Col span={24}>
                              <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                {dataDetailClient?.account?.office_name}
                              </h4>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} lg={8}>
                          <Row gutter={[24, 12]}>
                            <Col span={24}>
                              <p className="text-gray-400 text-xs md:text-sm">
                                {t("emailoffice")}
                              </p>
                            </Col>
                            <Col span={24}>
                              <h4 className="text-gray-800 text-sm md:text-base font-medium truncate">
                                {dataDetailClient?.account?.email_office}
                              </h4>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} lg={8}>
                          <Row gutter={[24, 12]}>
                            <Col span={24}>
                              <p className="text-gray-400 text-xs md:text-sm">
                                {t("officephonenumber")}
                              </p>
                            </Col>
                            <Col span={24}>
                              <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                {dataDetailClient?.company?.phone_office}
                              </h4>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} lg={8}>
                          <Row gutter={[24, 12]}>
                            <Col span={24}>
                              <p className="text-gray-400 text-xs md:text-sm">
                                {t("officeaddress")}
                              </p>
                            </Col>
                            <Col span={24}>
                              <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                {dataDetailClient?.company?.address}
                              </h4>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} lg={8}>
                          <Row gutter={[24, 12]}>
                            <Col span={24}>
                              <p className="text-gray-400 text-xs md:text-sm">
                                {t("Province")}
                              </p>
                            </Col>
                            <Col span={24}>
                              <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                {dataDetailClient?.company?.province}
                              </h4>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} lg={8}>
                          <Row gutter={[24, 12]}>
                            <Col span={24}>
                              <p className="text-gray-400 text-xs md:text-sm">
                                {t("city")}
                              </p>
                            </Col>
                            <Col span={24}>
                              <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                {dataDetailClient?.company?.city}
                              </h4>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Divider />
                    </Col>
                    <Col span={24}>
                      <Row gutter={[24, 24]}>
                        <Col xs={12} lg={8}>
                          <Row gutter={[24, 12]}>
                            <Col span={24}>
                              <p className="text-gray-400 text-xs md:text-sm">
                                {t("NPWP Number")}
                              </p>
                            </Col>
                            <Col span={24}>
                              <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                {dataDetailClient?.company?.npwp_number}
                              </h4>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} lg={8}>
                          <Row gutter={[24, 12]}>
                            <Col span={24}>
                              <p className="text-gray-400 text-xs md:text-sm">
                                {t("NPWP File")}
                              </p>
                            </Col>
                            <Col
                              span={24}
                              className="flex flex-row space-x-2 items-center"
                            >
                              <div className="bg-red-400 px-1 py-1 rounded-md">
                                <p className="font-bold text-xs text-white">
                                  PDF
                                </p>
                              </div>
                              <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                {dataDetailClient?.company?.npwp_file
                                  ?.split("/")
                                  ?.pop()}
                              </h4>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} lg={8}>
                          <Row gutter={[24, 12]}>
                            <Col span={24}>
                              <p className="text-gray-400 text-xs md:text-sm">
                                {t("TDP File")}
                              </p>
                            </Col>
                            <Col
                              span={24}
                              className="flex flex-row space-x-2 items-center"
                            >
                              <div className="bg-red-400 px-1 py-1 rounded-md">
                                <p className="font-bold text-xs text-white">
                                  PDF
                                </p>
                              </div>
                              <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                {dataDetailClient?.company?.tdp_file
                                  ?.split("/")
                                  ?.pop()}
                              </h4>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Skeleton>
              </Card>
            </Col>
          )}
        </>
      ) : (
        <Col span={24}>
          <Card className="rounded-md">
            <Skeleton loading={isFetching} active paragraph={{ rows: 6 }}>
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <Row gutter={[24, 24]} justify="space-between">
                    <Col>
                      <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                        {t("accinformation")}
                      </h3>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Divider />
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 24]}>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("fullname")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {userProfile?.full_name}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Email")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium truncate">
                            {userProfile?.email}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("phone")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {userProfile?.phone ? userProfile?.phone : "-"}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("role")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {userProfile?.role_name}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    {!isAdminBilling && !isSuperAdmin && (
                      <Col xs={12} lg={8}>
                        <Row gutter={[24, 12]}>
                          <Col span={24}>
                            <p className="text-gray-400 text-xs md:text-sm">
                              {t("Department")}
                            </p>
                          </Col>
                          <Col span={24}>
                            <h4 className="text-gray-800 text-sm md:text-base font-medium">
                              {userProfile?.dept_name}
                            </h4>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Work Unit")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {isAdminBilling
                              ? "DLI"
                              : `${userProfile?.office_name}(${userProfile?.office_type_name})`}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium truncate">
                            {
                              (userProfile?.bc_address === "On Progress" || userProfile?.bc_address === "Failed") ?
                                <span>{userProfile?.bc_address}</span>
                                :
                                <a rel="noreferrer" href={`${process.env.REACT_APP_ROOT_ENV === 'prod' ? 'https://blockexplorer.vas.id' : 'https://demo-blockexplorer.vas.id'}/traces?address=${userProfile.bc_address}&version=1`} target="_blank">
                                  {userProfile?.bc_address}
                                </a>
                            }
                          </h4>
                        </Col>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            Blockchain Address
                          </p>
                        </Col>
                      </Row>
                    </Col> */}
                    {(isBasicAccess || isAllDocumentAccess || isFullAccess) && dataModule?.sign_m && (
                      <>
                        <Col xs={12} lg={8}>
                          <Row gutter={[24, 12]}>
                            <Col span={24}>
                              <p className="text-gray-400 text-xs md:text-sm">
                                {t("statusregsign")}
                              </p>
                            </Col>
                            <Col span={24}>
                              <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                {userProfile?.status_registration_peruri === null
                                  ? t("Not Registered") : t(STATUS_REGISTRATION_SIGN[userProfile?.status_registration_peruri])
                                }
                                {

                                  (userProfile?.status_registration_peruri === 0 || userProfile?.status_registration_peruri === null) &&
                                  <Button className="ml-5 rounded" onClick={() => history.push('/registrationKYC')} type="primary" ghost>
                                    {t("regissign")}
                                  </Button>
                                }
                                
                                {triggerResendActivation && (
                                  <Button className="ml-5 rounded"type="primary" ghost onClick={() => resendActivationEmail()}>
                                    {t("Resend Activation Email")}
                                  </Button>
                                )}
                                
                                {userProfile?.status_registration_peruri == 2 && userProfile?.status_video_kyc == 0 && (
                                  <Button className="ml-5 rounded" onClick={() => history.push({ pathname: '/registrationKYC', state: { tab: 1 } })} type="primary" ghost>
                                    {t("Continue Registration")} 
                                  </Button>
                                )}
                                {
                                  (userProfile?.status_registration_peruri === 2 && (userProfile?.status_video_kyc === 1 ||
                                    userProfile?.status_video_kyc === 2)) &&
                                  <Button className="ml-5 rounded" onClick={() => history.push({ pathname: '/registrationKYC', state: { tab: 1 } })} type="primary" ghost>
                                    {t("Retry")} 
                                    {/* push to process video */}
                                  </Button>
                                }
                                {
                                   userProfile?.status_registration_peruri >= 3 && (userProfile?.sign_cert_status == 1 || userProfile?.sign_cert_status == '07') &&
                                  <Button className="ml-5 rounded" onClick={() => history.push({ pathname: '/registrationKYC', state: { tab: 1, renewal: true } })} type="primary" ghost>
                                    {t("Renewal Verification")} 
                                    {/* push to process video */}
                                  </Button>
                                }
                                {
                                  (userProfile?.status_registration_peruri === 3 && userProfile?.status_video_kyc === 3
                                    && userProfile?.is_status_video_kyc === false)
                                  // {t("kycmanual")} 
                                }
                                {
                                  (userProfile?.status_registration_peruri === 4)
                                  // {t("statusregkycsukses")} 
                                }
                              </h4>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} lg={8}>
                          <Row gutter={[24, 12]}>
                            <Col span={24}>
                              <p className="text-gray-400 text-xs md:text-sm">
                                {t("Status Certificate")}
                              </p>
                            </Col>
                            <Col span={24}>
                              <h4 className="text-gray-800 text-sm md:text-base font-medium truncate">
                                {t(DIGITAL_CERTIFICATE_STATUS[userProfile?.sign_cert_status] || "Not Actived CA")}
                              </h4>
                            </Col>
                          </Row>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </Skeleton>
          </Card>
        </Col>
      )}
    </>
  )
}
