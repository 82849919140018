import {createApi , fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {access , rootApi} from '../config'
import { objectToQueryString } from '../../../utils/queryString'

export const signManage = createApi({
    reducerPath : 'api/signmanagement' ,
    baseQuery : fetchBaseQuery({
        baseUrl : rootApi ,
        prepareHeaders : (header) => {
            header.set('token' , access)
            return header
        }
    }),
    endpoints(builder) {
        return {
            fetchSignList : builder.query({
                query({status , page , sort_by , order , search}){
                    return `/signs?status_doc=${status}&page=${page}${sort_by === null ? '' : `&order_by=${sort_by}`}&order_type=${order}${search === null || search === "" ? '' : `&search=${search}`}`
                } ,
                transformResponse : (response) => ( response ? response.data : [])
            }) ,

            fetchOtpSign : builder.mutation({
                query : (id) => ({
                    method : "GET" ,
                    url : `sign/single/get-otp/${id}`
                }) ,
                transformResponse : (response) => ( response ? response.data : null)
            }) ,
            fetchDetailSignDocument : builder.query({
                query(id){
                  return `/sign/${id}`
                },
                transformResponse : (response) => (response ? response.data : "")
            }),
            fetchDetailSignFolder: builder.mutation({
                // query: (folder_id, sign_type) => ({
                //     method: 'GET',
                //     url: `sign/signed-folder/${folder_id}?${objectToQueryString({sign_type: sign_type || ''})}`
                // }),
                query({folder_id, sign_type}) {
                    return `sign/signed-folder/${folder_id}?${objectToQueryString({sign_type: sign_type || ''})}`
                },
                transformResponse: (response) => (response ? response.data : "")
            }),
            checkCertificate: builder.query({
                query: () => ({
                    url: "/sign/checkCertificate",
                    method: "POST"
                }),
                transformResponse: (response) => (response ? response.data : {})
            }),
            generateQRCodeSpeciment: builder.mutation({
                query: ({text, qr_type, custom_logo}) => ({
                    method: 'POST',
                    url: '/sign/generate-qr',
                    body: {
                        text,
                        qr_type,
                        custom_logo
                    }
                }),
                transformResponse: (response) => (response ? response.data : {}),
            }),
            sendDocSingleSignerBulk: builder.mutation({
                query: ({ doc_id, ...body}) => ({
                    method: 'POST',
                    url: `/sign/single/send-doc-bulk/${doc_id}`,
                    body
                }),
                transformResponse: (response) => (response ? response.data : '')
            }),
            resendActivationEmail: builder.mutation({
                query: () => ({
                    method: 'POST',
                    url: '/sign/resend-activation'
                }),
                transformResponse: (response) => (response ? response.data : '')
            })
        }
    }
})

export const {
    useFetchSignListQuery , 
    useFetchOtpSignMutation , 
    useFetchDetailSignDocumentQuery,
    useFetchDetailSignFolderMutation,
    useCheckCertificateQuery,
    useGenerateQRCodeSpecimentMutation,
    useSendDocSingleSignerBulkMutation,
    useResendActivationEmailMutation
} = signManage