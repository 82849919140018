import { DownloadOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  DatePicker,
  Divider,
  message,
  Modal,
  Progress,
  Row,
  Skeleton,
  Table,
  Tag,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Spinner, Button } from "../../components";
import SignatureReceipt from "../../components/SignatureReceipt";
import { useAddBulkStampMutation } from "../../config/features";
import {
  useFetchActivityDocumentQuery,
  useFetchDetailDocumentQuery,
} from "../../config/features/document";
import { STAMP_DOC_STATUS } from "../../constant";
import { errorMessage, exportPdf, getBlob } from "../../utils";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import("moment/locale/id");
moment.locale("id");

const columnsFailedStampDocs = [
  {
    title: i18next.t("Document Name"),
    dataIndex: "doc_name",
    key: "doc_name"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: () => i18next.t("Incomplete")
  },
]

export default function DocumentDetail() {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();

  const [paramsTable, setParamsTable] = useState({
    row: 10,
    page: 1,
    office: "",
    sort_by: "created_at",
    order: "desc",
    folder_id: id,
    date: moment(new Date(), moment.ISO_8601).format("YYYY-MM-DD"),
  });
  const [isModalDownload, setIsModalDownload] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [stampedDocs, setStampedDocs] = useState(0)

  const {
    data: dataDetail = {},
    isFetching,
    isError,
    error,
    refetch: refetchDetailDocument,
  } = useFetchDetailDocumentQuery({ id, is_folder: 1 });

  const {
    data: dataActivity = [],
    isError: isErrorActivity,
    error: errorActivity,
    refetch,
  } = useFetchActivityDocumentQuery(paramsTable);

  const [
    addBulkStamp,
    {
      isSuccess: isSuccessRetry,
      isError: isErrorRetry,
      error: errorRetry,
      isLoading: isLoadingRetry,
    },
  ] = useAddBulkStampMutation();

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
  }, [isError, error, dataDetail]);

  useEffect(() => {
    if (isErrorActivity) {
      errorMessage(errorActivity);
    }
  }, [isErrorActivity, errorActivity]);

  useEffect(() => {
    if (isErrorRetry) {
      errorMessage(errorRetry);
    }
  }, [isErrorRetry, errorRetry]);

  useEffect(() => {
    refetch();
    refetchDetailDocument();
  }, [refetch, refetchDetailDocument]);

  useEffect(() => {
    if (dataDetail?.stamp_in_progress?.stamped_docs) {
      setStampedDocs(Number(dataDetail?.stamp_in_progress?.stamped_docs))
    }
    // if (dataDetail?.stamp_in_progress?.stamp_status == 1 && dataDetail?.stamp_in_progress?.stamped_docs < dataDetail?.total_docs) {
    //   console.log("Fetch Again");
    //   const interval = setInterval(() => {
    //     refetchDetailDocument();
    //   }, 5000);
    //   return () => clearInterval(interval)
    // }
  }, [dataDetail])
  useEffect(() => {
    if (dataDetail?.stamp_in_progress?.stamp_status == 1 && stampedDocs < dataDetail?.total_docs) {
      const interval = setInterval(() => {
        refetchDetailDocument();
      }, 5000);
      return () => clearInterval(interval)
    }
  }, [stampedDocs, dataDetail, refetchDetailDocument])
  const columnsVersion = [
    {
      title: t("Document Name"),
      dataIndex: "file",
      key: "file",
      render: (file) => {
        return file?.split("/")?.pop();
      },
    },
    {
      title: t("date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY, HH:mm");
      },
    },
    {
      title: t("Noted"),
      dataIndex: "description",
      key: "description",
      render: (description, record) => {
        return (
          <div>
            <Tag color="success">{description}</Tag>
            <span>by {record?.creator}</span>
          </div>
        )
      },
    },
    {
      render: (_, record) => {
        return (
          <Button
            className="bg-blue-700 focus:bg-blue-700 active:bg-blue-700 justify-center"
            type="primary"
            disabled={record?.bc_address === "On Progress" || record?.bc_address === "Failed"}
            onClick={() => handleDownload(record?.version)}
          >
            {t("Download")}
          </Button>
        );
      },
    },
  ];

  async function handleDownload(version) {
    try {
      // downloadDocument({ id, version });
      setIsLoadingDownload(true);
      setIsModalDownload(false);
      const response = await getBlob(
        `/document/download?id=${id}&version=${version}`
      );
      const documentName = dataDetail?.file
        ? dataDetail?.file?.split("/")?.pop()
        : "";
      exportPdf(response.data, documentName);
      setIsLoadingDownload(false);
    } catch (error) {
      message.error(error.message);
      setIsLoadingDownload(false);
    }
  }

  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  function handleChangeActivityDate(date) {
    setParamsTable({
      ...paramsTable,
      date: moment(date, moment.ISO_8601).format("YYYY-MM-DD"),
    });
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }

  function toggleModalDownload() {
    setIsModalDownload(!isModalDownload);
  }

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Activity"),
      dataIndex: "activity",
      key: "activity",
    },
    {
      title: t("Actor"),
      dataIndex: "creator",
      key: "creator",
    },
    {
      title: t("Source"),
      dataIndex: "source",
      key: "source",
      render: (_, record) => {
        return (
          <>
            <p className="text-gray-500">
              {t("Device Type")} :
              <span className="text-gray-800"> {record?.device_type}</span>
            </p>
            <p className="text-gray-500">
              {t("Operating System")} :{" "}
              <span className="text-gray-800"> {record?.os}</span>
            </p>
            <p className="text-gray-500">
              {t("Browser")} :{" "}
              <span className="text-gray-800"> {record?.browser}</span>
            </p>
            <p className="text-gray-500">
              IP : <span className="text-gray-800"> {record?.ip}</span>
            </p>
          </>
        );
      },
    },
    {
      title: t("Time"),
      dataIndex: "created_at",
      key: "created_at",
      render: (e) => {
        return moment(e, moment.ISO_8601).format("DD/MM/YYYY, HH:mm:ss");
      },
    },
  ];

  const datasetsVersion = dataDetail?.versions
    ? [
      {
        file: dataDetail?.file,
        created_at: dataDetail?.created_at,
        description: "UPLOADED",
        creator: dataDetail?.creator,
        version: 1,
      },
      ...dataDetail?.versions,
    ]
    : [];

  function datasetsHistory(dataVersion) {
    dataVersion?.pop();
    return dataVersion.reverse();
  }

  async function handleClickRetry() {
    try {
      // await addBulkStamp({
      //   doc_id: dataDetail?.id,
      //   retry: 1
      // });
      await addBulkStamp({
        doc_id: id,
        retry: 1
      });
      refetchDetailDocument();
    } catch (error) {
      message.error(error.message);
    }
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    {t("Document Detail")}
                  </h1>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                className="bg-blue-700 focus:bg-blue-700 active:bg-blue-700 justify-center"
                type="primary"
                onClick={() =>
                  history.push({
                    pathname: `/document-management/detail-bulk/${id}/version`,
                    state: dataDetail,
                  })
                }
                icon={<DownloadOutlined />}
                disabled={isFetching && !dataDetail?.name}
              >
                {t("Download Document")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Skeleton loading={isFetching && !dataDetail?.name} active paragraph={{ rows: 6 }}>
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <Row gutter={[24, 24]} justify="space-between">
                    <Col>
                      <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                        {dataDetail?.name}
                      </h3>
                    </Col>
                    {/* <Col>
                      <Row gutter={[16, 24]} align="middle">
                        <Col>
                          <div
                            className={`p-2 rounded-full ${
                              dataDetail?.is_authentic
                                ? "bg-green-400"
                                : "bg-red-400"
                            }`}
                          />
                        </Col>
                        <Col>
                          <h4 className="text-gray-500 text-base md:text-lg font-medium">
                            {dataDetail?.is_authentic
                              ? "Autentik"
                              : "Tidak Autentik"}
                          </h4>
                        </Col>
                      </Row>
                    </Col> */}
                  </Row>
                </Col>
                <Col span={24}>
                  <Divider />
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 24]}>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Created Date")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {moment(
                              dataDetail?.created_at,
                              moment.ISO_8601
                            ).format("DD MMMM YYYY, HH:mm:ss")}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Last Updated")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {
                              dataDetail && dataDetail?.versions?.length > 0 ?
                                moment(
                                  dataDetail?.versions?.length > 0 ?
                                    dataDetail?.versions[dataDetail?.versions?.length - 1]?.created_at :
                                    dataDetail?.versions[dataDetail?.versions?.length]?.created_at,
                                  moment.ISO_8601
                                ).format("DD MMMM YYYY, HH:mm:ss")
                                :
                                moment(
                                  dataDetail.created_at,
                                  moment.ISO_8601
                                ).format("DD MMMM YYYY, HH:mm:ss")
                            }
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Work Unit")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetail?.office_name}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Attach File")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium truncate">
                            {dataDetail?.name + ".zip"}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Description")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetail?.description}
                            {/* <span className="text-blue-400 hover:text-blue-500 text-xs md:text-sm cursor-pointer">
                          (Detail)
                        </span> */}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                          <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                              Blockchain Address
                          </p>
                          </Col>
                          <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium truncate">
                              {dataDetail.bc_address}
                              <a href="https://scan.haratoken.app/traces-view?addr=0x0A35909C144a31b1c4e82571C3659D4e391e0C55&ver=3" target="_blank" rel="noreferrer">
                                0x0A35909C144a31b1c4e82571C3659D4e391e0C55
                              </a>
                          </h4>
                          </Col>
                      </Row>
                    </Col> */}
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Uploaded By")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetail?.creator}
                            {/* <span className="text-gray-400">(Admin)</span> */}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Divider />
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 24]}>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Tag Key")}
                          </p>
                        </Col>
                        {dataDetail?.tags?.map((item, index) => {
                          return (
                            <Col key={index} span={24}>
                              <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                {item?.tag_key}
                              </h4>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Tag Value")}
                          </p>
                        </Col>
                        {dataDetail?.tags?.map((item, index) => {
                          return (
                            <Col key={index} span={24}>
                              <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                {item?.tag_value}
                              </h4>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-800 text-xs md:text-sm font-medium">
                            {t("You can edit the tags of all selected resources or add new tags to them.")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            <Button
                              type="primary"
                              ghost
                              onClick={() =>
                                history.push({
                                  pathname: `/document-management/edit/${id}`,
                                  state: {
                                    tags: dataDetail?.tags,
                                    is_folder: true,
                                  },
                                })
                              }
                            >
                              {t("Edit Tag")}
                            </Button>
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Skeleton>
          </Card>
        </Col>
        {dataDetail?.stamp_in_progress && dataDetail?.stamp_in_progress?.stamp_status !== 0 &&
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Row gutter={[24, 24]} justify="space-between">
                    <Col>
                      <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                        {t("Latest Stamp Activity")}
                      </h3>
                    </Col>
                    <Col>
                      <div className="flex gap-2 items-center">

                        {dataDetail?.stamp_in_progress?.stamp_status === STAMP_DOC_STATUS.PENDING &&
                          <div
                            className="bg-blue-500 rounded-full text-white py-1 px-3 text-sm"
                            type="primary"
                          >
                            {t("On Progress")}
                          </div>}
                        {dataDetail?.stamp_in_progress?.stamp_status === STAMP_DOC_STATUS.COMPLETE &&
                          <div
                            className="bg-green-500 rounded-full text-white py-1 px-3 text-sm"
                            type="primary"
                          >
                            {t("Completed")}
                          </div>}
                        {dataDetail?.stamp_in_progress?.stamp_status === STAMP_DOC_STATUS.INCOMPLETE &&
                          <>
                            <div
                              className="bg-gray-500 rounded-full text-white py-1 px-3 text-sm"
                              type="primary"
                            >
                              {t("Incomplete")}
                            </div>
                            <Button
                              type="primary"
                              onClick={handleClickRetry}
                              loading={isLoadingRetry}
                            >
                              {t("Retry Stamping")}
                            </Button>
                          </>}
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <h6> {t("Date Issued")} : {" "}
                        {dataDetail?.stamp_in_progress?.created_at
                          ? moment(dataDetail?.stamp_in_progress?.created_at, moment.ISO_8601)
                            .format("DD/MM/YYYY - HH:mm")
                          : "--"
                        }
                      </h6>
                    </Col>
                    <Col span={24}>
                      <Progress
                        type="line"
                        strokeWidth={20}
                        percent={stampedDocs / Number(dataDetail?.total_docs) * 100}
                        format={percent => `${stampedDocs} / ${dataDetail?.total_docs || 0}`}
                        className="pr-6"
                      />
                    </Col>
                    {/* {dataDetail?.stamp_in_progress?.stamp_status === STAMP_DOC_STATUS.INCOMPLETE &&
                      <>
                        <Divider className="m-0"/>
                        <Col span={24}>
                          <h3 className="text-gray-500 text-lg md:text-xl font-medium mb-2">
                            {t("List of Failed Document")}
                          </h3>
                          <Table columns={columnsFailedStampDocs} dataSource={dataDetail?.stamp_in_progress?.failed_docs || []} />
                        </Col>
                      </>
                    } */}
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        }

        {dataDetail?.receipts?.length > 0 && (
          <Col span={24}>
            <SignatureReceipt
              receiptHistory={dataDetail?.receipts}
              isFetching={isFetching}
            />
          </Col>
        )}
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("Document Activity")}
                      <span className="text-gray-400">
                        {` (${t("Last 30 Days From Date")})`}
                      </span>
                    </h3>
                  </Col>
                  <Col>
                    <DatePicker
                      placeholder={t("Select Date")}
                      format="DD/MM/YYYY"
                      onChange={handleChangeActivityDate}
                      allowClear={false}
                      disabledDate={disabledDate}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={datasetsListTable(dataActivity)}
                  scroll={{ x: 800 }}
                  loading={isFetching && !dataDetail?.name}
                  pagination={{
                    current: paramsTable.page,
                    pageSize: paramsTable.row,
                    total: dataActivity[0] ? dataActivity[0]?.total : 0,
                    locale: {
                      items_per_page: t("/ page"),
                    }
                  }}
                  onChange={handleChangeTable}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        visible={isModalDownload}
        onCancel={toggleModalDownload}
        footer={false}
        width={800}
      >
        <Row gutter={[24, 24]} className="pr-4">
          <Col span={24}>
            <h3 className="text-gray-500 text-lg md:text-xl font-medium">
              {t("Version Document (Current)")}
            </h3>
          </Col>
          <Col span={24}>
            <Table
              columns={columnsVersion}
              dataSource={[datasetsVersion[datasetsVersion.length - 1]]}
              scroll={{ x: 500 }}
              loading={isFetching}
              pagination={false}
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]} className="pr-4 mt-4">
          <Col span={24}>
            <h3 className="text-gray-500 text-lg md:text-xl font-medium">
              {t("Version History")}
            </h3>
          </Col>
          <Col span={24}>
            <Table
              columns={columnsVersion}
              dataSource={datasetsHistory(datasetsVersion)}
              scroll={{ x: 500 }}
              loading={isFetching}
            // pagination={{
            //   current: paramsTable.page,
            //   pageSize: paramsTable.row,
            //   // total: dataListDocument[0] ? dataListDocument[0]?.total : 0,
            // }}
            // onChange={handleChangeTable}
            />
          </Col>
        </Row>
      </Modal>
      <Spinner loading={dataDetail?.bc_address === "On Progress" ? true : (isLoadingDownload === true ? true : false)} />
    </>
  );
}
