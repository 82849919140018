import React from "react";
import { Button } from "antd";

export default function ButtonSecondary(props) {
  const className = props.className ? props.className : "";
  return (
    <Button {...props} className={"rounded-md " + className} type="primary">
      {props.children}
    </Button>
  );
}
