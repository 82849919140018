import { DeleteOutlined, EditOutlined, EllipsisOutlined, EyeFilled, FilterOutlined, LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Dropdown, Input, Menu, message, Radio, Row, Select, Skeleton, Table } from "antd";
import clsx from "clsx";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import { useFetchGroupDetailQuery } from "../../config/features";
import { GROUP_MEMBER_ROLE } from "../../constant";
import { errorMessage } from "../../utils";
import { useTranslation } from "react-i18next";

const { Search } = Input;
const { Option } = Select;

export default function GroupDetail() {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const { t } = useTranslation();

  const [paramsTable, setParamsTable] = useState({
    office_id: id,
    row: 10,
    page: 1,
    sort_by: "dept_name",
    order: "asc",
  });
  const [visibleFilter, setVisibleFilter] = useState(false);

  const { 
    data: groupDetail,  
    isLoading, 
    refetch,
    error,
    isError, 
  } = useFetchGroupDetailQuery(id);

  const onSearch = (value) => setParamsTable({ ...paramsTable, search: value });

  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }

  function handleResetFilter() {
    setParamsTable({
      ...paramsTable,
      sort_by: "created_at",
      order: "desc",
    });
  }

  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  const menuFilter = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={handleResetFilter}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort By</p>
            <Select
              defaultValue="user_name"
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, sort_by: e })}
              value={paramsTable.sort_by}
            >
              <Option value="user_name">Members Name</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort Type</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) =>
                setParamsTable({ ...paramsTable, order: e.target.value })
              }
              value={paramsTable.order}
            >
              <Radio value="asc">Ascending</Radio>
              <Radio value="desc">Descending</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const columnsMembers = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "User Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role) => {
        return GROUP_MEMBER_ROLE[role]?.value || "--"
      }
    },
    {
      title: "Date & Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return created_at ? moment(created_at, moment.ISO_8601).format("DD/MM/YY, HH:mm:ss") : "--"
      }
    },
  ];

  const columnsSharedDocs = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Sender Name",
      dataIndex: "sender_name",
      key: "sender_name",
    },
    {
      title: "Document Name",
      dataIndex: "doc_name",
      key: "doc_name",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Date & Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return created_at ? moment(created_at, moment.ISO_8601).format("DD/MM/YY, HH:mm:ss") : "--"
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "actions",
      render: (_, row) => {
        return (
          <Button 
            type="primary" 
            icon={<EyeFilled />} 
            onClick={() => {
              history.push({
                pathname: `/document-management/detail/${row?.doc_id}`,
                state: row
              })
            }}
            ghost
          > 
            Detail 
          </Button>
        );
      },
    }
  ];

  const columnsGroupLog = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Activity",
      dataIndex: "activity",
      key: "activity",
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      render: (_, record) => {
        return (
          <>
            <p className="text-gray-500">
              Device Type :
              <span className="text-gray-800"> {record?.device_type}</span>
            </p>
            <p className="text-gray-500">
              Operating System :{" "}
              <span className="text-gray-800"> {record?.os}</span>
            </p>
            <p className="text-gray-500">
              Browser :{" "}
              <span className="text-gray-800"> {record?.browser}</span>
            </p>
            <p className="text-gray-500">
              IP : <span className="text-gray-800"> {record?.ip}</span>
            </p>
          </>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (e) => {
        return moment(e, moment.ISO_8601).format("DD/MM/YYYY, HH:mm:ss");
      },
    },
  ];


  function datasetsListTable(data) {
    const datasets = [];

    if(data){
      for (let i = 0; i < data.length; i++) {
        datasets.push({
          no: i + 1 + paramsTable.row * (paramsTable.page - 1),
          ...data[i],
        });
      }
    }

    return datasets;
  }

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
  }, [isError, error]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    Group Detail
                  </h1>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row className="flex justify-between">
              <Col xs={24} md={12}>
                <h5 className="text-gray-500 mb-3">
                  Group Name
                </h5>
                <h4 className="text-3xl">
                  {groupDetail?.group_data?.group_name || "--"}
                </h4>
              </Col>
              <Col xs={24} md={12}>
                <h5 className="text-gray-500 mb-3">
                  Created Date
                </h5>
                <h4 className="text-3xl">
                  {groupDetail?.group_data?.created_at 
                    ? moment(groupDetail?.group_data?.created_at, moment.ISO_8601).format("DD/MM/YYYY") 
                    : "--"}
                </h4>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Col span={24} className="mb-4">
              <Row gutter={[24, 24]} justify="space-between">
                <Col>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    List of Members
                  </h3>
                </Col>
                <Col>
                  <Row gutter={[12, 12]}>
                    {/* 
                    Disabled until API update for list members in group support search, filter, & sort.
                    <Col>
                      <Search
                        placeholder="Search"
                        allowClear
                        onSearch={onSearch}
                        onChange={(e) =>
                          setTimeout(() => {
                            onSearch(e.target.value);
                          }, 1000)
                        }
                        style={{
                          width: 200,
                          borderRadius: "10px !important",
                        }}
                      />
                    </Col>
                    <Col>
                      <Dropdown
                        overlay={menuFilter}
                        onVisibleChange={handleVisibleFilter}
                        visible={visibleFilter}
                        trigger={["click"]}
                      >
                        <Button
                          type="primary"
                          ghost
                          icon={<FilterOutlined />}
                        />
                      </Dropdown>
                    </Col> */}
                    {location?.state?.is_owner && 
                      <Col>
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={() => {
                            const members = [...groupDetail?.member_data  || []]
                            const sortedMemberData = members?.sort((a, b) => a?.role < b?.role ? -1 : 1);
                            history.push({
                              pathname: `/group/edit/${id}`,
                              state: { 
                                group_name: groupDetail?.group_data?.group_name,
                                group_id: groupDetail?.group_data?.group_id,
                                members: sortedMemberData
                              }
                            })
                          }}
                        >
                          Add New Members
                        </Button>
                      </Col>
                    }
                  </Row>
                </Col>
              </Row>
            </Col>
            <Table
              columns={columnsMembers}
              dataSource={datasetsListTable(groupDetail?.member_data || [])}
              scroll={{ x: 800 }}
              loading={isLoading}
              onChange={handleChangeTable}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Col span={24} className="mb-4">
              <Row gutter={[24, 24]} justify="space-between">
                <Col>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    Shared Documents
                  </h3>
                </Col>
                {/* 
                  Disabled until API update for shared document in group support search, filter, & sort.
                <Col>
                  <Row gutter={[12, 12]}>
                    <Col>
                      <Search
                        placeholder="Search"
                        allowClear
                        onSearch={onSearch}
                        onChange={(e) =>
                          setTimeout(() => {
                            onSearch(e.target.value);
                          }, 1000)
                        }
                        style={{
                          width: 200,
                          borderRadius: "10px !important",
                        }}
                      />
                    </Col>
                    <Col>
                      <Dropdown
                        overlay={menuFilter}
                        onVisibleChange={handleVisibleFilter}
                        visible={visibleFilter}
                        trigger={["click"]}
                      >
                        <Button
                          type="primary"
                          ghost
                          icon={<FilterOutlined />}
                        />
                      </Dropdown>
                    </Col>
                  </Row>
                </Col> */}
              </Row>
            </Col>
            <Table
              columns={columnsSharedDocs}
              dataSource={datasetsListTable(groupDetail?.doc_data || [])}
              scroll={{ x: 800 }}
              loading={isLoading}
              onChange={handleChangeTable}
            />
          </Card>
        </Col>
        <Col span={24}>
        <Card className="rounded-md">
          <Skeleton loading={false} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      Group Activity Log {" "}
                      <span className="text-gray-400">
                        (Last 30 Days From Date)
                      </span>
                    </h3>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsGroupLog}
                  dataSource={datasetsListTable(groupDetail?.activity_data || [])}
                  loading={false}
                  scroll={{ x: 800 }}
                  onChange={handleChangeTable}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>
      </Row>
      <Spinner loading={isLoading} />
    </>
  );
}
