import { message } from "antd";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Quotation } from "../../components";
import { useDownloadQuotationMutation } from "../../config/features/general";
import { errorMessage } from "../../utils";

export default function QuotationDownload() {
  const location = useLocation();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [
    downloadQuotation,
    { data: dataSource = {}, isSuccess, isError, error },
  ] = useDownloadQuotationMutation();

  useEffect(() => {
    async function fetchData() {
      const token = location?.search?.replace("?t=", "");
      try {
        downloadQuotation({ t: token });
      } catch (error) {
        message.error(error.message);
      }
    }
    fetchData();
  }, [location, downloadQuotation]);

  useEffect(() => {
    if (isSuccess) {
      message.info("preparing files...");
      setTimeout(() => {
        handlePrint();
      }, 3000);
    }
    if (isError) {
      errorMessage(error);
    }
  }, [isSuccess, isError, error, handlePrint]);

  return (
    <div className="hidden">
      <div ref={componentRef} className="p-4">
        <Quotation dataSource={dataSource} />
      </div>
    </div>
  );
}
