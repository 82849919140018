import {
  Card,
  Col,
  Row,
  Skeleton,
  Table,
} from "antd";
import { useEffect, useState } from "react";


export default function ModuleList({ dataDetailClient, isFetching }) {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    function generateModule(listModule) {
      const module = [];

      for (let i = 0; i < Object.keys(listModule)?.length; i++) {
        if (listModule[Object.keys(listModule)[i]] !== null) {
          switch (Object.keys(listModule)[i]) {
            case "stamp_m":
              module.push({
                module: "E-Meterai",
                status: Object.keys(listModule)[i],
              });
              break;

            case "sign_m":
              module.push({
                module: "Digital Signature",
                status: Object.keys(listModule)[i],
              });
              break;

            case "user_m":
              module.push({
                module: "User Management",
                status: Object.keys(listModule)[i],
              });
              break;

            case "group_m":
              module.push({
                module: "Group Management",
                status: Object.keys(listModule)[i],
              });
              break;

            case "office_m":
              module.push({
                module: "Office Management",
                status: Object.keys(listModule)[i],
              });
              break;

            case "document_m":
              module.push({
                module: "Document Management",
                status: Object.keys(listModule)[i],
              });
              break;

            case "backup_m":
              module.push({
                module: "Backup Secure Document",
                status: Object.keys(listModule)[i],
              });
              break;
            
            case "blockchain_m":
              module.push({
                module: "Blockchain Document",
                status: Object.keys(listModule)[i],
              });
              break;

            default:
              // module.push({
              //   module: "Document Management",
              //   status: Object.keys(listModule)[i],
              // });
              break;
          }
        }
      }

      setDataSource(module);
    }

    dataDetailClient?.module && generateModule(dataDetailClient?.module);
  }, [dataDetailClient]);

  const columns = [
    {
      title: "Module Name",
      dataIndex: "module",
      key: "module",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          dataDetailClient?.module &&
          Object.keys(dataDetailClient?.module).length > 0 &&
          (dataDetailClient?.module[status] ? (
            <p className="text-blue-600">Active</p>
          ) : (
            <p className="text-gray-500">Inactive</p>
          ))
        );
      },
    },
  ];

  return (
    <>
      <Col span={24}>
        <Card className="rounded-md">
          <Skeleton loading={isFetching} active paragraph={{ rows: 6 }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  Module List
                </h3>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  scroll={{ x: 500 }}
                  pagination={false}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>
    </>
  )
}