import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { rootApi, access } from "../config";

export const peruriApi = createApi({
  reducerPath: "api/peruri",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi + "/peruri",
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      fetchListJenisDocument: builder.query({
        query() {
          return "/list-doc";
        },
        transformResponse: (response) => (response ? response : {}),
      }),

      addSingleStamp: builder.mutation({
        query: ({ doc_id, retry = "", body }) => ({
          method: "POST",
          url: `/stamp/${doc_id}?retry=${retry}`,
          body,
        }),
        transformResponse: (response) => (response ? response.data : ""),
      }),

      addBulkStamp: builder.mutation({
        query: ({ doc_id, retry = "", body }) => ({
          method: "POST",
          url: `/multi-stamp/${doc_id}?retry=${retry}`,
          body,
        }),
        transformResponse: (response) => (response ? response.data : ""),
      }),
    };
  },
});

export const {
  useFetchListJenisDocumentQuery,
  useAddSingleStampMutation,
  useAddBulkStampMutation,
} = peruriApi;
