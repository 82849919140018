import { Card, Col, Divider, Row, Tooltip } from "antd"
import { numberCommas } from "../../utils";
import { ReactComponent as WarningIcon } from "../../assets/icons/warning-icon.svg";
import { t } from "i18next";

const CardPrimary = ({ title, data }) => {
  if (!data) return null;
  return (
    <Card
      className="rounded-lg h-full"
      style={{
        backgroundImage: "linear-gradient(266.92deg, #48A1D9 -6.95%, #054BA6 102.75%)"
      }}
    >
      <div className="grid grid-cols-1 gap-y-3 divide-y">
        <h1 className="text-base md:text-lg font-medium text-white">
          {title}
        </h1>
        <div className="pt-4 flex flex-row flex-wrap divide-x gap-4">
          {data?.map(({ label, value, warning }, index) => (
            <div className={`${index > 0 && "pl-4"} flex-grow flex flex-col justify-between`}>
              <h3 className="flex items-start gap-1 text-sm md:text-base font-medium text-white">
                {label}
                {warning && (
                  <Tooltip className="mt-1" placement="topLeft" title={t("Your remaining quota is almost used up, please top up your quota")}>
                    <WarningIcon />
                  </Tooltip>
                )}
              </h3>
              <h1 className="text-sm md:text-base font-medium text-white">
                {numberCommas(value)}
              </h1>
            </div>
          ))}
        </div>
      </div>
    </Card>
  )
}
export default CardPrimary;