import { LeftOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Card, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Table, Dropdown, Menu } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Modal, Spinner } from "../../components";
import { useCreateNewContractMutation, useFetchListModuleQuery } from "../../config/features";
import { useFetchListProductQuery } from "../../config/features/product";
import { errorMessage, getProductsTotalPrice, numberCommas } from "../../utils";
import { useTranslation } from "react-i18next";
const { Option } = Select;

export default function CoopAgreementDetail() {
  const { t } = useTranslation();
  const history = useHistory();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const { id } = useParams();
  const [form] = Form.useForm();
  const { data: dataListProduct = [] } = useFetchListProductQuery({});
  const { data: module = {} } = useFetchListModuleQuery(id);
  const [totalPrice, setTotalPrice] = useState({});

  const [newData, setNewData] = useState({});
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);

  const fields = form?.getFieldsValue();
  const { products } = fields;

  const [
    createNewContract, 
    { 
      isSuccess: isSuccessCreateNewContract, 
      isError: isErrorCreateNewContract, 
      error: errorLoadingCreateNewContract, 
      isLoading: isLoadingCreateNewContract 
    }
  ] = useCreateNewContractMutation();

  const toggleModalSuccess = () => {
    setIsModalSuccess(prevState => !prevState);
  }

  const toggleModalConfirm = useCallback(() =>  {
    setIsModalConfirm(prevState => !prevState);
  }, [setIsModalConfirm])

  const getTotalPrice = (listPrice) => {
    let price = 0;

    for (let i = 0; i < Object.keys(listPrice).length; i++) {
      price += listPrice[Object.keys(listPrice)[i]];
    }

    return price;
  }

  const isProductInvalid = (item) => {
    const isInvalid = Boolean(form?.getFieldValue("id") === item?.id);
    const isInvalidList = Boolean(
      form
        ?.getFieldValue("products")
        ?.find((element) => element?.id === item?.id)
    );

    return isInvalidList || isInvalid;
  }

  const disabledDateBefore = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  }

  const disabledDateAfter = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }

  const handleFinish = (values) => {
    setNewData({
      ...values,
      total: getProductsTotalPrice(values.products)
    })
    toggleModalConfirm();
  }

  const handleConfirm = () => {
    createNewContract({
      office_id: id,
      body: newData
    })
  }

  const handleChangeProductNameMultiple = useCallback((e) => {
    const selectedProduct = dataListProduct?.find((item) => item?.id === e);
    const stampProccessProduct = e === "1" ? dataListProduct?.find((item) => item?.id === "6") : {};
    const fields = form?.getFieldsValue();
    const { products } = fields;

    if (products?.length < 1) {
      const data = [{
        unit_price: selectedProduct?.price_default,
        quantity: 1,
        total: selectedProduct?.price_default * 1,
        price: `Rp ${numberCommas(selectedProduct?.price_default * 1)},-`,
        name: selectedProduct?.name,
        id: selectedProduct?.id,
      }]

      if(e === "1"){
        data.push({
          unit_price: stampProccessProduct?.price_default,
          quantity: 1,
          total: stampProccessProduct?.price_default * 1,
          price: `Rp ${numberCommas(stampProccessProduct?.price_default * 1)},-`,
          name: stampProccessProduct?.name,
          id: stampProccessProduct?.id,
        })
      }

      form.setFieldsValue({
        products: [
          ...data
        ],
      });
    } else {
      const data = [{
        unit_price: selectedProduct?.price_default,
        quantity: 1,
        total: selectedProduct?.price_default * 1,
        price: `Rp ${numberCommas(selectedProduct?.price_default * 1)},-`,
        name: selectedProduct?.name,
        id: selectedProduct?.id,
      }]

      if(e === "1"){
        data.push({
          unit_price: stampProccessProduct?.price_default,
          quantity: 1,
          total: stampProccessProduct?.price_default * 1,
          price: `Rp ${numberCommas(stampProccessProduct?.price_default * 1)},-`,
          name: stampProccessProduct?.name,
          id: stampProccessProduct?.id,
        })
      }

      form.setFieldsValue({
        products: [
          ...products,
          ...data,
        ],
      });
    }

    setTotalPrice((prev) => {
      const data = {
        [e]: selectedProduct?.price_default * 1,
      }

      if(e === "1"){
        data["6"] = stampProccessProduct?.price_default * 1
      }

      return {
        ...prev,
        ...data
      };
    });
  }, [dataListProduct, form])

  const handleClickDeleteProduct = (key) => {
    const fields = form?.getFieldsValue();
    const { products } = fields;
    
    const newProduct = products.filter((item, idx) => {
      if(products[key]?.id === "1"){
        if(item?.id === "1" || item?.id === "6") return false
        
        return true
      } 

      return key !== idx
    })
    // products.splice(key, 1)
    form.setFieldsValue({ products: newProduct })

    const totalPrice = {}
    newProduct?.forEach(product => totalPrice[product.id] = product.total)
    setTotalPrice(totalPrice)
  }

  const availableProductMenu = () => {
    const fields = form?.getFieldsValue();
    const { products } = fields;

    const productsTable = {}
    products.forEach(product => productsTable[product.id] = product)
    const availProductToAdd = dataListProduct?.filter(product => !productsTable[product.id] && product.id !== "6")

    return (
      <Menu style={{ maxHeight: "400px", overflowY: "scroll"}}>
        {availProductToAdd?.map(product => {
          return (
            <Menu.Item onClick={() => handleChangeProductNameMultiple(product.id)}>
              {product.name}
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  // Populate module to products
  useEffect(() => {
    form.setFieldsValue({ products: [] });
    if (module?.stamp_m) {
      handleChangeProductNameMultiple("1");
    }
    if (module?.backup_m) {
      handleChangeProductNameMultiple("4");
    }
    if (module?.sign_m) {
      handleChangeProductNameMultiple("2");
      handleChangeProductNameMultiple("5");
    }
  }, [module, form, dataListProduct, handleChangeProductNameMultiple]);

  useEffect(() => {
    if (isSuccessCreateNewContract) {
      setIsModalSuccess(true);
      toggleModalConfirm()
    }
    if (isErrorCreateNewContract) {
      errorMessage(errorLoadingCreateNewContract);
    }
  }, [
    isSuccessCreateNewContract, 
    errorLoadingCreateNewContract, 
    isErrorCreateNewContract, 
    toggleModalConfirm
  ]);

  return (
    <>
      <Form
        autoComplete="off"
        layout="vertical"
        form={form}
        onFinish={handleFinish}
      >
        <Row gutter={[24, 24]} className="pb-14">
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.goBack()}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      {t("newcoopagree")}
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  {t("submit")}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md mb-4">
              <h3 className="text-gray-500 text-base md:text-lg font-medium">
                {t("newcoorperationt")}
              </h3>
              <Divider />
              <Row gutter={[24, 0]} align="middle">
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Date Issued"
                    name="contract_issue"
                    rules={[
                      {
                        required: true,
                        message: "Please input Date Issued!",
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      className="w-full"
                      disabledDate={disabledDateAfter}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Expired Date"
                    name="contract_expire"
                    rules={[
                      {
                        required: true,
                        message: "Please input Expired Date!",
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      className="w-full"
                      disabledDate={disabledDateBefore}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Form.List name="products">
              {(fields, { add, remove }) => {
                return (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                    <Card className="rounded-md mb-4">
                      <Row key={key} gutter={[24, 0]} align="middle">
                        <Col span={24}>
                          <Row
                            gutter={[24, 24]}
                            align="middle"
                            justify="space-between"
                          >
                            <Col span={24} className="flex justify-between items-center">
                              <h3 className="text-gray-500 text-base md:text-lg font-medium">
                                Product ({index + 1})
                              </h3>
                              {products[key]?.id !== "6" && (
                                <Button 
                                  type="danger" 
                                  icon={<DeleteOutlined />}
                                  onClick={() => handleClickDeleteProduct(key)}>
                                    Delete
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Divider className="my-4" />
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Product Name"
                            name={[name, "name"]}
                            fieldKey={[fieldKey, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input product name!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Not Selected"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              disabled
                            >
                              {dataListProduct?.map((item, index) => {
                                return (
                                  !isProductInvalid(item) && (
                                    <Option key={index} value={item.id}>
                                      {item.name}
                                    </Option>
                                  )
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12} className="hidden">
                          <Form.Item
                            {...restField}
                            label="Product Name"
                            name={[name, "id"]}
                            fieldKey={[fieldKey, "id"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input product name!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Not Selected"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {dataListProduct?.map((item, index) => {
                                return (
                                  !isProductInvalid(item) && (
                                    <Option key={index} value={item.id}>
                                      {item.name}
                                    </Option>
                                  )
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Unit Price"
                            name={[name, "unit_price"]}
                            fieldKey={[fieldKey, "unit_price"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input unit price!",
                              },
                              ({ getFieldsValue, setFieldsValue }) => ({
                                validator(_, value) {
                                  const fields = getFieldsValue();
                                  const { products } = fields;
                                  if (value || products[key].quantity) {
                                    Object.assign(products[key], {
                                      total: products[key]?.quantity * value,
                                      price: `Rp ${numberCommas(
                                        products[key]?.quantity * value
                                      )},-`,
                                    });
                                    setFieldsValue({ products });
                                    setTotalPrice((prev) => {
                                      return {
                                        ...prev,
                                        [products[key].id]:
                                          products[key]?.quantity * value,
                                      };
                                    });
                                    return Promise.resolve();
                                  }
                                },
                              }),
                            ]}
                          >
                            <Input
                              addonBefore="Rp"
                              placeholder="Enter unit price"
                              min={1}
                            />
                          </Form.Item>
                        </Col>
                        {products[key]?.id !== "6" && (
                          <>
                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Quantity"
                                name={[name, "quantity"]}
                                fieldKey={[fieldKey, "quantity"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input quantity!",
                                  },
                                  ({ getFieldsValue, setFieldsValue }) => ({
                                    validator(_, value) {
                                      const fields = getFieldsValue();
                                      const { products } = fields;
                                      if (value || products[key].quantity) {
                                        // e-Meterai per seal is related with Stamp Process
                                        // find stamp process index and populate the data 
                                        // based on e-meterai per-seal
                                        if(products[key].id === "1"){
                                          const stampProcessKey = products.findIndex(item => item?.id == "6")
                                          Object.assign(products[stampProcessKey], {
                                            quantity: value,
                                            total: products[stampProcessKey].unit_price * value,
                                            price: `Rp ${numberCommas(
                                              products[stampProcessKey].unit_price * value
                                            )},-`,
                                          });
                                        }

                                        Object.assign(products[key], {
                                          total: products[key].unit_price * value,
                                          price: `Rp ${numberCommas(
                                            products[key].unit_price * value
                                          )},-`,
                                        });

                                        setFieldsValue({ products });

                                        const price = {
                                          [products[key].id]: products[key].unit_price * value,
                                        }

                                         // e-Meterai per seal is related with Stamp Process
                                        // find stamp process index and populate the data 
                                        // based on e-meterai per-seal
                                        if(products[key].id === "1"){
                                          const stampProcessKey = products.findIndex(item => item?.id == "6")
                                          price[products[stampProcessKey].id] = products[stampProcessKey].unit_price * value
                                        }

                                        setTotalPrice((prev) => {
                                          return {
                                            ...prev,
                                            ...price,
                                          };
                                        });

                                        if(value > products[key].quota || 
                                          products[key].quantity > products[key].quota) {
                                            return Promise.reject("Quantity cannot be more than Quota")
                                          }

                                        return Promise.resolve();
                                      }
                                    },
                                  }),
                                ]}
                              >
                                <InputNumber 
                                  placeholder="Enter Quantity" 
                                  className="w-full"
                                  min={1} 
                                  precision={0}
                                  controls={false} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Quota"
                                name={[name, "quota"]}
                                fieldKey={[fieldKey, "quota"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input quota!",
                                  },
                                  ({ getFieldsValue, setFieldsValue }) => ({
                                    validator(_, value) {
                                      const fields = getFieldsValue();
                                      const { products } = fields;
                                      if (value || products[key].quantity) {
                                         // e-Meterai per seal is related with Stamp Process
                                        // find stamp process index and populate the data 
                                        // based on e-meterai per-seal
                                        if(products[key].id === "1"){
                                          const stampProcessKey = products.findIndex(item => item?.id == "6")
                                          Object.assign(products[stampProcessKey], {
                                            quota: value,
                                            total_quota: products[key].unit_price * value,
                                          });
                                        }

                                        Object.assign(products[key], {
                                          total_quota: products[key].unit_price * value,
                                        });
                                        
                                        setFieldsValue({ products });

                                        if(value < products[key].quantity || 
                                          products[key].quota < products[key].quantity) {
                                            return Promise.reject("Quota cannot be less than Quantity")
                                          }

                                        return Promise.resolve();
                                      }
                                    },
                                  }),
                                ]}
                              >
                                <InputNumber 
                                  placeholder="Enter Quota" 
                                  className="w-full"
                                  min={0} 
                                  precision={0}
                                  controls={false} />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                        <Col xs={24} md={12} className="hidden">
                          <Form.Item
                            {...restField}
                            label="Price"
                            name={[name, "price"]}
                            fieldKey={[fieldKey, "price"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input price!",
                              },
                            ]}
                            initialValue="Rp 0,-"
                          >
                            <Input placeholder="Enter price" disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12} className="hidden">
                          <Form.Item
                            {...restField}
                            label="Total"
                            name={[name, "total"]}
                            fieldKey={[fieldKey, "total"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input total!",
                              },
                            ]}
                          >
                            <Input placeholder="Enter total" disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12} className="hidden">
                          <Form.Item
                            {...restField}
                            label="Name"
                            name={[name, "name"]}
                            fieldKey={[fieldKey, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input name!",
                              },
                            ]}
                          >
                            <Input placeholder="Enter name" disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                  { fields?.length !== dataListProduct?.length &&
                    <Dropdown overlay={availableProductMenu} trigger={['click']}>
                      <Button 
                        type="primary" 
                        ghost 
                        icon={<PlusOutlined />}
                      >  
                        Add Product 
                      </Button>
                    </Dropdown>
                  }
                </>
              )}}
            </Form.List>
          </Col>
        </Row>
        <div className="fixed bottom-5 right-10 left-60">
          <div className="flex flex-row justify-between rounded-md bg-blue-200 px-8 py-2">
            <h2 className="text-xs md:text-sm lg:text-base font-medium text-gray-800">
              Total Price
            </h2>
            <h2 className="text-xs md:text-sm lg:text-base font-medium text-blue-800">
              {`Rp ${numberCommas(getTotalPrice(totalPrice))},-`}
            </h2>
          </div>
        </div>
      </Form>
      <Modal
        title="Are you sure?"
        subtitle="After the data has been added, you cannot change the data. Please make sure the data is correct."
        type="warning"
        visible={isModalConfirm}
        onCancel={toggleModalConfirm}
        width={700}
      >
        <div className="flex flex-col space-y-2">
          <Row>
            <Col span={6}>
              <span className="text-gray-500"> Date Issued </span>
            </Col>
            <Col span={12}>
              {newData?.contract_issue ?
               moment(newData?.contract_issue, moment.ISO_8601).format("DD/MM/YYYY") : 
               "--"}
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <span className="text-gray-500"> Expired Date </span>
            </Col>
            <Col span={12}>
              {newData?.contract_expire ?
               moment(newData?.contract_expire, moment.ISO_8601).format("DD/MM/YYYY") : 
               "--"}
            </Col>
          </Row>
          <Divider />
          <Table
            dataSource={newData?.products}
            columns={[
              {
                title: "Product Name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Unit Price",
                dataIndex: "unit_price",
                key: "unit_price",
                render: (unit_price) => {
                  return `Rp ${numberCommas(unit_price)},-`;
                },
              },
              {
                title: "Qty",
                dataIndex: "quantity",
                key: "quantity",
                render: (quantity) => {
                  return numberCommas(quantity);
                },
              },
              {
                title: "Quota",
                dataIndex: "quota",
                key: "quota",
                render: (quota) => {
                  return numberCommas(quota);
                },
              },
              {
                title: "Total",
                render: (record) => {
                  return `Rp ${numberCommas(
                    record.unit_price * record.quantity
                  )},-`;
                },
              },
            ]}
            pagination={false}
          />
          <div className="flex flex-row justify-between px-4">
            <p className="font-medium text-gray-800">Total</p>
            <p className="font-medium text-gray-800">
              Rp {numberCommas(newData?.total)},-
            </p>
          </div>
          <Divider />
        </div>
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={toggleModalConfirm}
          >
            Back
          </Button>
          <Button className="w-full" type="primary" onClick={handleConfirm}>
            Create New Agreement
          </Button>
        </div>
      </Modal>
      <Modal
        type="success"
        title="Updated Success!"
        subtitle="Changes have been saved! Now you can continue browsing."
        visible={isModalSuccess}
        closable={false}
      >
        <div className="text-center">
          <Button 
            className="w-32" 
            type="primary" 
            onClick={() => history.replace(`/client-management/detail/${id}/coopAgreement`)}
          >
            Done
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoadingCreateNewContract} />
    </>
  );
}
