import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as AlertCircleIcon } from "../../assets/icons/alert-circle-outline.svg";
import { ReactComponent as CheckCircleIcon } from "../../assets/icons/check-circle-outline.svg";
import { ReactComponent as FilterOutline } from "../../assets/icons/filter-outline.svg";
import Spinner from "../../components/Spinner";
import {
  useFetchListDocumentQuery,
  useSendDocumentMutation,
} from "../../config/features/document";
import { useFetchListGroupQuery } from "../../config/features/group";
import { useFetchListOfficeQuery } from "../../config/features/office";
import { useFetchComposeUserQuery } from "../../config/features/user";
import { errorMessage } from "../../utils";
import { useTranslation } from "react-i18next";
const { TextArea, Search } = Input;
const { Option } = Select;

export default function SendDocument() {
  const history = useHistory();
  const { t } = useTranslation();
  const [searchUser, setSearchUser] = useState("");
  const [searchGroup, setSearchGroup] = useState("");
  const [visibleBcc, setVisibleBcc] = useState(false);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [paramsTable, setParamsTable] = useState({
    row: 5,
    page: 1,
    office: "",
    sort_by: "created_at",
    order: "desc",
  });

  const { data: listComposeUser = [] } = useFetchComposeUserQuery({
    search: searchUser,
  });
  const { data: listGroup = [] } = useFetchListGroupQuery({
    search: searchGroup,
  });
  const { data: dataListDocument = [], isFetching } =
    useFetchListDocumentQuery(paramsTable);
  const [
    sendDocument,
    { isSuccess, isError, error, isLoading, data: responseSent },
  ] = useSendDocumentMutation();
  const { data: dataListOffice = [] } = useFetchListOfficeQuery({ row: 100 });

  const [form] = Form.useForm();
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [isModalDocument, setIsModalDocument] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [payload, setPayload] = useState({});

  useEffect(() => {
    if (isSuccess) {
      setIsModalSuccess(true);
      form.resetFields();
    }
    if (isError) {
      errorMessage(error);
    }
  }, [isSuccess, error, isError, form]);

  const onFinish = (values) => {
    if (values.recipient_to?.length > 0 || values.group_id?.length > 0) {
      if (getDocId(selectedDocument)?.length > 0) {
        showModal(values);
      } else {
        message.error("Please attach your file");
      }
    } else {
      message.error("Please enter recipient");
    }
  };

  async function handleSubmit(values) {
    try {
      sendDocument(values);
    } catch (error) {
      message.error(error.message);
    }
  }

  function getDocId(data) {
    const doc_id = [];
    for (let i = 0; i < data.length; i++) {
      doc_id.push(data[i].doc_id);
    }
    return doc_id;
  }

  const showModal = (values) => {
    if (!values.recipient_to) {
      values.recipient_to = [];
    }
    if (!values.group_id) {
      values.group_id = [];
    }
    if (!values.recipient_cc) {
      values.recipient_cc = [];
    }
    setPayload({ ...values, doc_id: getDocId(selectedDocument) });
    setIsModalConfirm(true);
  };

  const handleOk = () => {
    setIsModalConfirm(false);
    handleSubmit(payload);
  };

  const handleCancel = () => {
    setIsModalConfirm(false);
  };

  const handleDone = () => {
    setIsModalSuccess(false);
    history.push("/document-message");
  };

  const handleToDetail = () => {
    history.push(`/document-message/sent/${responseSent?.data?.sent_id}`);
  };

  const options = (data) => {
    const listOptions = [];

    for (let i = 0; i < data.length; i++) {
      listOptions.push({ value: data[i].user_id, label: data[i].email });
    }

    return listOptions;
  };

  const optionsGroup = (data) => {
    const listOptions = [];

    for (let i = 0; i < data.length; i++) {
      listOptions.push({
        value: parseInt(data[i].group_id),
        label: data[i].group_name,
      });
    }

    return listOptions;
  };

  function tagRender(props) {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        className="rounded-full"
        color={"default"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Document Name",
      dataIndex: "doc_name",
      key: "doc_name",
    },
    {
      title: "Tag Document",
      dataIndex: "tags",
      key: "tags",
      render: (tags) => {
        return (
          <p className="w-40 truncate">
            {tags?.map((item, index) => {
              return (
                <span key={index}>
                  {" "}
                  {item}
                  {tags?.length > 1 && tags?.length - 1 !== index ? ", " : ""}
                </span>
              );
            })}
          </p>
        );
      },
    },
    {
      title: t("Work Unit"),
      dataIndex: "office_name",
      key: "office_name",
    },
  ];

  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  const onSearch = (value) => setParamsTable({ ...paramsTable, search: value });

  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        key: data[i].doc_id,
        ...data[i],
      });
    }

    return datasets;
  }

  function handleResetFilter() {
    setParamsTable({
      ...paramsTable,
      office: "",
      sort_by: "created_at",
      order: "desc",
    });
  }

  const menuFilter = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={handleResetFilter}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Office</p>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, office: e })}
              value={paramsTable.office}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">All Office</Option>
              {dataListOffice.map((item, index) => {
                return (
                  <Option key={index} value={item.office_id}>
                    {item.office_name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="w-full h-0.5 bg-gray-100 rounded-full" />
        </Menu.Item>
        <Menu.Item>
          <p className="text-gray-500 text-base font-medium">Sort</p>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort By</p>
            <Select
              defaultValue="created_at"
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, sort_by: e })}
              value={paramsTable.sort_by}
            >
              <Option value="created_at">Uploaded Time</Option>
              <Option value="doc_name">Document Name</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort Type</p>
            <Radio.Group
              defaultValue="desc"
              buttonStyle="solid"
              onChange={(e) =>
                setParamsTable({ ...paramsTable, order: e.target.value })
              }
              value={paramsTable.order}
            >
              <Radio value="asc">Ascending</Radio>
              <Radio value="desc">Descending</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  function handleSearchUser(e) {
    setSearchUser(e);
  }

  function handleSearchGroup(e) {
    setSearchGroup(e);
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedDocument(selectedRows);
      // setSelectedDocumentId(selectedRowKeys?.split(","));
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  function handleCloseDocument(data) {
    const cloneSelected = [...selectedDocument];
    const index = cloneSelected.indexOf(data);
    if (index > -1) {
      cloneSelected.splice(index, 1);
    }
    setSelectedDocument(cloneSelected);
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.push("/document-message")}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    Send Document
                  </h1>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Form
                  onFinish={onFinish}
                  autoComplete="off"
                  layout="horizontal"
                  form={form}
                >
                  <Row gutter={[24, 0]}>
                    {!visibleBcc && (
                      <Col className="text-right" xs={24}>
                        <Button
                          className="text-blue-700 hover:text-blue-600"
                          type="link"
                          onClick={() => setVisibleBcc(!visibleBcc)}
                        >
                          Bcc
                        </Button>
                      </Col>
                    )}
                    <Col xs={24}>
                      <Form.Item
                        label={
                          <div className="text-xs font-medium text-center w-20 py-1 bg-blue-100 text-blue-700 rounded-md">
                            TO
                          </div>
                        }
                        name="recipient_to"
                        rules={[
                          {
                            required: false,
                            message: "Please input recipient!",
                          },
                        ]}
                      >
                        <Select
                          // placeholder="To"
                          mode="multiple"
                          showArrow
                          tagRender={tagRender}
                          style={{
                            width: "100%",
                          }}
                          options={options(listComposeUser)}
                          bordered={false}
                          onSearch={handleSearchUser}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label={
                          <div className="text-xs font-medium text-center w-20 py-1 bg-blue-100 text-blue-700 rounded-md">
                            TO GROUP
                          </div>
                        }
                        name="group_id"
                        rules={[
                          {
                            required: false,
                            message: "Please input recipient!",
                          },
                        ]}
                      >
                        <Select
                          // placeholder="To"
                          mode="multiple"
                          showArrow
                          tagRender={tagRender}
                          style={{
                            width: "100%",
                          }}
                          options={optionsGroup(listGroup)}
                          bordered={false}
                          onSearch={handleSearchGroup}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label={
                          <div className="text-xs font-medium text-center w-20 py-1 bg-blue-100 text-blue-700 rounded-md">
                            CC
                          </div>
                        }
                        name="recipient_cc"
                        rules={[
                          {
                            required: false,
                            message: "Please input office name!",
                          },
                        ]}
                      >
                        <Select
                          // placeholder="CC"
                          mode="multiple"
                          showArrow
                          tagRender={tagRender}
                          style={{
                            width: "100%",
                          }}
                          options={options(listComposeUser)}
                          bordered={false}
                          onSearch={handleSearchUser}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        />
                      </Form.Item>
                    </Col>
                    {visibleBcc && (
                      <Col xs={24}>
                        <Form.Item
                          label={
                            <div className="text-xs font-medium text-center w-20 py-1 bg-blue-100 text-blue-700 rounded-md">
                              BCC
                            </div>
                          }
                          name="recipient_bcc"
                          rules={[
                            {
                              required: false,
                              message: "Please input office name!",
                            },
                          ]}
                        >
                          <Select
                            // placeholder="BCC"
                            mode="multiple"
                            showArrow
                            tagRender={tagRender}
                            style={{
                              width: "100%",
                              // borderTopStyle: "none",
                              // borderLeftStyle: "none",
                              // borderRightStyle: "none",
                              // borderBottom: "0.5px solid #ABCABCAB",
                            }}
                            options={options(listComposeUser)}
                            bordered={false}
                            onSearch={handleSearchUser}
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col xs={24}>
                      <Form.Item
                        name="subject"
                        rules={[
                          {
                            required: true,
                            message: "Please input subject!",
                          },
                        ]}
                      >
                        <Input
                          className="border-gray-300 placeholder-gray-300 py-2"
                          style={{
                            borderLeftStyle: "none",
                            borderRightStyle: "none",
                            borderBottom: "0.5px solid #DBDBDB",
                            borderTop: "0.5px solid #DBDBDB",
                          }}
                          placeholder="Add a subject"
                          bordered={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        name="message"
                        rules={[
                          {
                            required: false,
                            message: "Please input office name!",
                          },
                        ]}
                      >
                        <TextArea
                          // placeholder="Message"
                          style={{
                            borderTopStyle: "none",
                            borderLeftStyle: "none",
                            borderRightStyle: "none",
                            borderBottom: "0.5px solid #ABCABCAB",
                          }}
                          rows={6}
                          bordered={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Row gutter={[24, 24]}>
                        {selectedDocument.map((item, index) => {
                          return (
                            <Col key={index}>
                              <div className="bg-gray-200 px-8 py-4 rounded-md relative">
                                <div
                                  className="absolute -top-3 -right-2 bg-gray-400 flex items-center justify-center rounded-full px-1 py-0.5 cursor-pointer"
                                  onClick={() => handleCloseDocument(item)}
                                >
                                  <CloseOutlined className="text-xs font-semibold" />
                                </div>
                                <div className="bg-red-400 px-2 py-2 rounded-md">
                                  <p className="font-bold text-sm text-white">
                                    PDF
                                  </p>
                                </div>
                              </div>
                              <Tooltip title={item?.file?.split("/")?.pop()}>
                                <p className="truncate w-24">
                                  {item?.file?.split("/")?.pop()}
                                </p>
                              </Tooltip>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                    {/* <Col xs={24}>
                      <Form.Item
                        name="doc_id"
                        rules={[
                          {
                            required: false,
                            message: "Please input office name!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Attachment"
                          mode="multiple"
                          showArrow
                          tagRender={docRender}
                          style={{
                            width: "100%",
                          }}
                          options={optionsDocument(listDocument)}
                          bordered={false}
                          onSearch={handleSearchDocument}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        />
                      </Form.Item>
                    </Col> */}
                    <Col xs={24} className="mt-8">
                      <Row gutter={[24, 24]} justify="end">
                        <Col xs={12} md={8} lg={5}>
                          <Form.Item>
                            <Button
                              className="w-full rounded-md text-xs md:text-sm"
                              type="primary"
                              onClick={() => setIsModalDocument(true)}
                            >
                              Attach Document
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={8} lg={5}>
                          <Form.Item>
                            <Button
                              className="w-full rounded-md bg-blue-700 hover:bg-blue-600 text-xs md:text-sm"
                              type="primary"
                              htmlType="submit"
                              loading={isLoading}
                              // disabled
                            >
                              Send Document
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        visible={isModalDocument}
        onCancel={() => setIsModalDocument(false)}
        width={800}
        footer={false}
        style={{ top: 30 }}
      >
        <Row gutter={[24, 24]} className="pr-4">
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between" align="middle">
              <Col>
                <p className="text-lg md:text-xl text-gray-500 font-medium">
                  Choose Document
                </p>
              </Col>
              <Col>
                <Row gutter={[24, 24]}>
                  <Col>
                    <Search
                      placeholder="Search"
                      allowClear
                      onSearch={onSearch}
                      onChange={(e) =>
                        setTimeout(() => {
                          onSearch(e.target.value);
                        }, 1000)
                      }
                      style={{ width: 200, borderRadius: "10px !important" }}
                    />
                  </Col>
                  <Col>
                    <Dropdown
                      overlay={menuFilter}
                      onVisibleChange={handleVisibleFilter}
                      visible={visibleFilter}
                      trigger={["click"]}
                    >
                      <Button
                        className="bg-blue-700"
                        type="primary"
                        shape="round"
                        icon={<FilterOutline />}
                      />
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={datasetsListTable(dataListDocument)}
              scroll={{ x: 500 }}
              loading={isFetching}
              pagination={{
                current: paramsTable.page,
                pageSize: paramsTable.row,
                total: dataListDocument[0] ? dataListDocument[0]?.total : 0,
              }}
              onChange={handleChangeTable}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
            />
          </Col>
        </Row>
      </Modal>
      <Modal
        className="border-yellow-200 rounded-md"
        style={{ borderTopWidth: 14 }}
        visible={isModalConfirm}
        onCancel={handleCancel}
        footer={false}
      >
        <div className="flex flex-col space-y-4 px-8 -mt-20">
          <div className="flex flex-col space-y-2 justify-center items-center">
            <AlertCircleIcon className="w-20" />
            <p className="text-gray-800 font-semibold text-xl md:text-2xl">
              Are you sure?
            </p>
            <Alert
              className="text-center"
              message="After the document has been sent, you cannot change the document data. Please make sure the data is correct."
              type="warning"
            />
          </div>
          <div className="flex flex-row space-x-4">
            <Button
              onClick={handleCancel}
              className="w-full rounded-md"
              ghost
              type="primary"
            >
              Back
            </Button>
            <Button
              onClick={handleOk}
              className="w-full rounded-md"
              type="primary"
            >
              Send
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        className="border-blue-500 rounded-md"
        style={{ borderTopWidth: 14 }}
        visible={isModalSuccess}
        footer={false}
      >
        <div className="flex flex-col space-y-4 px-8 -mt-20">
          <div className="flex flex-col space-y-2 justify-center items-center">
            <CheckCircleIcon className="w-20" />
            <p className="text-gray-800 font-semibold text-xl md:text-2xl">
              Document Sent!
            </p>
            <Alert
              className="text-center"
              message="The document has been Sent! You are now can view details or countinue browsing"
              type="info"
            />
          </div>
          <div className="flex flex-row space-x-4">
            <Button
              onClick={handleToDetail}
              className="w-full rounded-md"
              ghost
              type="primary"
            >
              Detail
            </Button>
            <Button
              onClick={handleDone}
              className="w-full rounded-md"
              type="primary"
            >
              Done
            </Button>
          </div>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
