import { configureStore } from "@reduxjs/toolkit";
import {
  userApi,
  overviewApi,
  documentApi,
  officeApi,
  roleApi,
  groupApi,
  adminApi,
  platformApi,
  departmentApi,
  peruriApi,
  productApi,
  generalApi,
  otpApi,
  notificationApi,
  signManage,
  contractApi,
  monitorApi,
  tagApi,
} from "../features";

export default configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [overviewApi.reducerPath]: overviewApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [officeApi.reducerPath]: officeApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [groupApi.reducerPath]: groupApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [platformApi.reducerPath]: platformApi.reducer,
    [departmentApi.reducerPath]: departmentApi.reducer,
    [peruriApi.reducerPath]: peruriApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [generalApi.reducerPath]: generalApi.reducer,
    [otpApi.reducerPath]: otpApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [signManage.reducerPath] : signManage.reducer,
    [contractApi.reducerPath]: contractApi.reducer,
    [monitorApi.reducerPath]: monitorApi.reducer,
    [tagApi.reducerPath]: tagApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
    .concat(
      overviewApi.middleware, 
      notificationApi.middleware,
      contractApi.middleware,
      monitorApi.middleware,
      tagApi.middleware,
    );
  },
});
