import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { Card, Col, DatePicker, Dropdown, Input, Menu, Radio, Row, Select, Table } from "antd";
import { FilterOutlined, DownloadOutlined, LeftOutlined } from "@ant-design/icons";
import { SiMicrosoftexcel } from "react-icons/si";
import { MdPictureAsPdf } from "react-icons/md";
import { Button } from "../../components";
import {  useFetchListMeteraiDefectDetailQuery } from "../../config/features";
import { STAMP_STATUS } from "../../constant";
import { errorMessage, exportExcel, exportPdf, getBlob, numberCommas } from "../../utils";
import { useTranslation } from "react-i18next";

const { Search } = Input;
const { Option } = Select;

export default function MonitoringProductDefectDetail() {
  const location = useLocation();
  const history = useHistory();
  const { id }  = useParams();
  const { t } = useTranslation();

  const [isVisibleFilterMeteraiDefectDetail, setIsVisibleFilterMeteraiDefectDetail] = useState(false)
  const [paramsTableMeteraiDefectDetail, setParamsTableMeteraiDefectDetail] = useState({
    row: 5,
    page: 1,
    order: "desc",
    sort_by: "created_at",
    search: "",
    start_date: "",
    end_date: "",
  });

  const {
    data: dataListMeteraiDefectDetail = {},
    refetch: refetchListMeteraiDefectDetail,
    isError: isErrorMeteraiDefectDetail,
    error: errorMeteraiDefectDetail,
    isFetching: isFetchingMeteraiDefectDetail,
  } = useFetchListMeteraiDefectDetailQuery
  ({office_id: id, ...paramsTableMeteraiDefectDetail});

  const columnMeteraiDefectDetail = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Serial Number",
      dataIndex: "sn",
      key: "sn",
    },
    {
      title: "Date & Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY HH:mm:ss");
      },
    },
    {
      title: "Status",
      dataIndex: "is_refunded",
      key: "is_refunded",
      render: (is_refunded) => {
        return is_refunded ? "Refunded" : "Not Refunded"
      }
    },
  ];

  const datasetsListTableDefectMeterail = (data = []) => {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTableMeteraiDefectDetail.row * (paramsTableMeteraiDefectDetail.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  const handleChangeSearchMeteraiDefectDetail = (e) => {
    setParamsTableMeteraiDefectDetail(prevState => ({
      ...prevState,
      search: e.target.value
    }))
  }

  const handleClickSearchMeteraiDefectDetail = () => {
    //console.log(paramsTableMeteraiDefectDetail)
  }

  const handleResetFilterMeteraiDefectDetail = () => {
    setParamsTableMeteraiDefectDetail(prevState => ({
      ...prevState,
      sort_by: "created_at",
      order: "desc",
      search: "",
      start_date: "",
      end_date: "",
    }))
  }

  const menuFilterMeteraiDefectDetail = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
          <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-blue-500"
              onClick={handleResetFilterMeteraiDefectDetail}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Date</p>
            <DatePicker.RangePicker
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(value) => {
                setParamsTableMeteraiDefectDetail({ 
                  ...paramsTableMeteraiDefectDetail, 
                  start_date: moment(value[0]).utc().format().toString(),
                  end_date: moment(value[1]).utc().format().toString(), 
                })
              }}
              value={[
                paramsTableMeteraiDefectDetail.start_date ? moment(paramsTableMeteraiDefectDetail.start_date) : "", 
                paramsTableMeteraiDefectDetail.end_date ? moment(paramsTableMeteraiDefectDetail.end_date) : ""
              ]}
            />
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort Type</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) => {
                setParamsTableMeteraiDefectDetail({ 
                  ...paramsTableMeteraiDefectDetail, 
                  order: e.target.value 
                })
              }}
              value={paramsTableMeteraiDefectDetail.order}
            >
              <Radio value="asc">Ascending</Radio>
              <Radio value="desc">Descending</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const handleChangePaginationMeteraiDefectDetail = (pagination) => {
    setParamsTableMeteraiDefectDetail(prevState => ({
      ...prevState,
      page: pagination.current,
      row: pagination.pageSize,
    }));
  }

  const handleClickExportExcel = async () => {
    const response = await getBlob(`/platform/defect/${id}?export_format=excel`);
    exportExcel(response.data, "Defective E-Meterai Log");
  }

  const handleClickExportPdf = async () => {
    const response = await getBlob(`/platform/defect/${id}?export_format=pdf`);
    exportPdf(response.data, "Defective E-Meterai Log");
  }

  /* CURRENTLY COMMENTED UNTIL BACKEND API IS READY */

  useEffect(() => {
    if (isErrorMeteraiDefectDetail) errorMessage(errorMeteraiDefectDetail);
  }, [isErrorMeteraiDefectDetail, errorMeteraiDefectDetail]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} align="middle">
            <Col>
              <div
                className="group cursor-pointer"
                onClick={() => history.goBack()}
              >
                <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
              </div>
            </Col>
            <Col>
              <h1 className="text-xl md:text-2xl font-medium">
                {location?.state?.office_name}
              </h1>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between" align="middle">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      Log of Defect E-Meterai
                    </h3>
                  </Col>
                  <Col className="flex gap-2">
                    <Search
                      placeholder="Search"
                      className="rounded-full"
                      allowClear
                      onSearch={handleClickSearchMeteraiDefectDetail}
                      onChange={handleChangeSearchMeteraiDefectDetail}
                    />
                    <Dropdown
                      overlay={menuFilterMeteraiDefectDetail}
                      onVisibleChange={() => {
                        setIsVisibleFilterMeteraiDefectDetail(prevState => !prevState)
                      }}
                      visible={isVisibleFilterMeteraiDefectDetail}
                      trigger={["click"]}
                    >
                      <Button
                        type="primary"
                        ghost
                        icon={<FilterOutlined />}
                        />
                    </Dropdown>
                    <Button
                      type="text"
                      className="text-white hover:text-white"
                      style={{ background: "#8BC34A" }}
                      icon={<SiMicrosoftexcel />}
                      onClick={handleClickExportExcel}
                    >
                      Export Excel
                    </Button>
                    <Button
                      type="text"
                      className="text-white hover:text-white"
                      style={{ background: "#E9290F" }}
                      icon={<MdPictureAsPdf />}
                      onClick={handleClickExportPdf}
                    >
                      Export PDF
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnMeteraiDefectDetail}
                  dataSource={datasetsListTableDefectMeterail(dataListMeteraiDefectDetail.data)}
                  loading={isFetchingMeteraiDefectDetail}
                  scroll={{ x: 600 }}
                  pagination={{
                    current: paramsTableMeteraiDefectDetail.page,
                    pageSize: paramsTableMeteraiDefectDetail.row,
                    total: dataListMeteraiDefectDetail?.total || 0,
                  }}
                  onChange={handleChangePaginationMeteraiDefectDetail}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}
