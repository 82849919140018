import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useFetchProfileUserQuery } from "../../config/features"
import { Button, Modal, Spinner } from "../../components"
import { Card, Col, Form, Row, Upload, message } from "antd"
import { LeftOutlined, UploadOutlined } from "@ant-design/icons"
import { useMemo, useState } from "react"
import axios from "axios"
import { rootApi } from "../../config/features/config"

// encoded file to base64 format and remove prefix of file 
const toBase64 = (file) => {

  return new Promise((resolve, reject) => {

    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {

      if (reader.result.includes('data:image/png;base64,')) {
        resolve(reader.result.replace('data:image/png;base64,', ''))
      }
    }

    reader.onerror = (e) => reject(e)

  })

}

const ConfigManagement = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [isModalSuccess, setIsModalSuccess] = useState(false)
  const [form] = Form.useForm();
  const tokenAccess = useMemo(() => localStorage.getItem('access'), [])
  const history = useHistory()

  const { data: userProfile = {}, loading: loadingProfile } = useFetchProfileUserQuery()

  const propsUpload = {
    onRemove: () => {
      setFileList([])
    },
    beforeUpload: (file) => {
      const isPng = file.type == "image/png"
      const isLt5 = file.size / 1024 / 1024 < 5
      if (isPng) {
        if (!isLt5) {
          message.error(t('File must smaller then 5MB!'))
        }
        else {
          setFileList([file])
        }
      }
      else {
        message.error(t("You can't upload this extension!"))
      }
      return false
    },
    fileList
  }
  const propsUpload2 = {
    onRemove: () => {
      setFileList2([])
    },
    beforeUpload: (file) => {
      const isPng = file.type === "image/png"
      const isLt5 = file.size / 1024 / 1024 < 5
      if (isPng) {
        if (!isLt5) {
          message.error(t('File must smaller than 5MB!'))
        }
        else {
          setFileList2([file])
        }
      }
      else {
        message.error(t("You can't upload this extension!"))
      }
      return false
    },
    fileList2
  }
  const onFinish = async (values) => {
    setLoading(true)
    try {
      if (!fileList[0] && !fileList2[0]) {
        message.info(t('Please Upload App Logo or Speciment E-Meterai'))
        return
      }
      const [appLogo = null] = fileList
      const [specimentMeterai = null] = fileList2
      const assets = await Promise.all([specimentMeterai, appLogo].map(async (item, i) => {
        const base64File = item ? await toBase64(item) : item
        return {
          id: i + 1,
          base64File: await base64File
        }
      }))
      const body = {
        assets
      }
      const { data } = await axios({
        method: 'POST',
        url: `${rootApi}/platform/setting`,
        data: body,
        headers: {
          token: tokenAccess
        }
      })
      setLoading(false)
      setIsModalSuccess(true)
    } catch (e) {
      const { data: { error } } = e.response
      setLoading(false)
      message.error(error)
    }
  }
  const handleDone = () => {
    setIsModalSuccess(false);
    history.replace('/overview')
  };
  return (
    !loadingProfile ?
      <>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Form
              autoComplete="off"
              layout="vertical"
              form={form}
              onFinish={onFinish}
            >
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <Row gutter={[24, 24]} align="middle">
                      <Col>
                        <div className="group cursor-pointer" onClick={() => history.goBack()}>
                          <LeftOutlined className="text-gray-500 group-hover:text-blue-50" />
                        </div>
                      </Col>
                      <Col>
                        <h1 className="text-xl md:text-2xl font-medium">
                          {t("Config Asset")}
                        </h1>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button
                        className="w-72 h-11 font-medium rounded-lg tracking-tight"
                        type="primary"
                        style={{ background: '#054BA6' }}
                        htmlType="submit"
                      >
                        {t("Save Config")}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Card className="rounded-md">
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                        {t("Config Assets")}
                      </h3>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[24, 12]}>
                        <Col xs={24}>
                          <Row gutter={[24, 24]}>
                            <Col xs={24} md={12}>
                              <Form.Item
                                label={`${t('Upload App Logo')}`}
                                name={'logo'}
                              >
                                <Upload
                                  className="w-full"
                                  maxCount={1}
                                  fileList={fileList}
                                  {...propsUpload}
                                >
                                  <Button
                                    icon={<UploadOutlined />}
                                  >
                                    {t("Click to Upload")}
                                  </Button>
                                </Upload>
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.Item
                                label={`${t('Upload E-METERAI')}`}
                                name={'meterai'}
                              >
                                <Upload
                                  className="w-full"
                                  maxCount={1}
                                  fileList={fileList2}
                                  {...propsUpload2}
                                >
                                  <Button icon={<UploadOutlined />}>
                                    {t("Click to Upload")}
                                  </Button>
                                </Upload>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Form>
          </Col>
        </Row>
        <Modal
          title={t("Upload Success!")}
          subtitle={t("Successfully created a new signature! You can now continue browsing.")}
          className="border-blue-500 rounded-md"
          onCancel={() => setIsModalSuccess(false)}
          style={{ borderTopWidth: 14 }}
          visible={isModalSuccess}
          footer={false}
        >
          <div className="flex flex-row justify-center space-x-4">
            <Button
              onClick={handleDone}
              className="w-8/12 h-11 tracking-tight rounded-md"
              type="primary"
            >
              {t("Done")}
            </Button>
          </div>
        </Modal>
        <Spinner loading={loading}/>
      </> :
      <Spinner loading={loadingProfile} />
  )
}

export default ConfigManagement