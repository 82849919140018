import React, { useState, useEffect, useRef, useCallback } from "react";
import jwt from 'jsonwebtoken';
import Qoutation from "./Qoutation";

import PaymentMethod from "./PaymentMethod";
import PaymentPage from "./PaymentPage";
import PaymentSuccessPage from "./PaymentSuccessPage";
import PaymentPendingPage from "./PaymentPendingPage";

import { useHistory, useLocation } from "react-router-dom";
import { useDownloadQuotationMutation, useFetchListPaymentQuery, useCreatePaymentMutation } from "../../config/features/general";

import { useFetchProfileUserQuery } from "../../config/features/user";
import { errorMessage } from "../../utils";
import { useReactToPrint } from "react-to-print";
import { Quotation as QuotationFile } from "../../components";
import Spinner from "../../components/Spinner";
import { secret } from "../../config/features/config";

export default function TopUp() {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [loading , setLoading] = useState(false)
  const { quotation } = useLocation().state;
 
  const [
    paymentMethod,
    setPaymentMethod
  ] = useState(null);
  
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const {
    data: userProfile = {},
    isError: isErrorProfile,
    error: errorProfile,
  } = useFetchProfileUserQuery();

  const [
    createPayment,
    { isSuccess, isLoading, errorPayment, data: dataPayment = {}, isErrorPayment },
  ] = useCreatePaymentMutation();

  const [downloadQuotation, { data: dataSource = {}, isError, error }] =
  useDownloadQuotationMutation();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
    if (isErrorProfile) {
      errorMessage(errorProfile);
    }
    if(isErrorPayment){
      errorMessage(errorPayment);
    }


  }, [isError, error, isErrorProfile, errorProfile, isErrorPayment, errorPayment]);

  useEffect(() => {
    
    if (quotation.id) {
      const bill = {
        bill_id: quotation.id
      }
      const t = jwt.sign(bill, secret);
      downloadQuotation({t});
    }
    else {
      downloadQuotation({ id: userProfile?.office_id });
    }
    
  
  }, [downloadQuotation, userProfile, quotation]);
  
  const  {
     data: paymentMethode = []
  } = useFetchListPaymentQuery({bill_id: quotation.id});

  const paymentSuccess = useCallback(async (dataP) => {
    //console.log(dataP);
    if (dataP?.response_code == '00') {        
        setPage(2); 
    } else {
        //console.log(dataP);
        setPage(0);      
    }
  }, [])

  useEffect(() => {
    async function check(){
      if(isSuccess){
        if (dataPayment != null || dataPayment != undefined) {        
          paymentSuccess(dataPayment);
        }
      }      
    }
    check()
  }, [isSuccess,dataPayment, paymentSuccess])

  
  function handleNext() {    
    setPage(page + 1);
  }

  function buyNow(){
    //console.log(dataSource, "Data Source Payment!");
    if((dataSource?.external_id != null)){      
      openInNewTab(dataSource?.payment_url);
    } else {
      setPage(1);
    }
  }

  const customNavigation = (event,x) => {
    setPage(x);
  }

  function handleBack() {
    setPage(page - 1);
  }

  function handleToHome() {
    history.goBack();
  }

  function processPayment(){
    
   setLoading(true);
   createPayment({body : dataSource, acc_q: paymentMethod});
   //console.log(dataPayment, "Data Source Payment!");
   
  }

  
  switch (page) {
    case 1:
      return (
        <>
          <PaymentMethod
            back={handleBack}
            next={handleNext}
            tabValue={paymentMethod}
            data={dataSource}
            dataMethod={paymentMethode}
            handlePaymentMethod={(e) => setPaymentMethod(e)}
            processAction={(ex) => processPayment(ex)}
          />
          <Spinner loading={isLoading} />
        </>        
      );

    case 2:
      return (
          <PaymentPage
            back={handleToHome}
            next={handleNext}            
            data={dataPayment}                      
          />
        );
    case 3:
          return (
              <PaymentPendingPage
                back={handleToHome}
                next={customNavigation}            
                data={dataSource}                        
              />
            );
    case 4:
      return (
          <PaymentSuccessPage
            back={handleBack}
            next={handleNext}            
            data={dataPayment}            
          />
        );
          
    default:
      return (
        <>
          <Qoutation
            dataSource={dataSource}
            back={handleToHome}
            next={buyNow}
            handleDownload={handlePrint}
          />
          <div className="hidden">
            <div ref={componentRef} className="p-4">
              <QuotationFile dataSource={dataSource} />
            </div>
          </div>
        </>
      );
  }
}
