import React from "react";
import { Button } from "antd";
import clsx from "clsx";

export default function ButtonDefault(props) {
  const className = props.className ? props.className : "";
  const classNameGhost = clsx("rounded-md border-1", className)
  const classNameNonGhost = clsx("rounded-md", className);
  return (
    <Button
      {...props}
      className={(props.ghost ? classNameGhost : classNameNonGhost)}
      icon={false}
      ghost={false}
    >
      <div className="flex items-center justify-center space-x-2">
        {props.icon}
        {props.children && <p>{props.children}</p>}
      </div>
    </Button>
  );
}
