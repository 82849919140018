import React from 'react';
import { BellOutlined } from "@ant-design/icons";
import {
  Badge,
  Dropdown,
  Menu,
  Tag
} from "antd";
import clsx from "clsx";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotificationDropdownItem = ({ title, message, date, isRead, ...props}) => {
  const { t } = useTranslation();
  return (
    <Menu.Item 
      className={clsx("p-2", !isRead && "bg-blue-100 hover:bg-blue-100")}
      {...props}
    >
      <div className={`whitespace-pre-wrap`}>
        <header className="flex justify-between items-center mb-1">
          <h6 className="text-blue-700 font-bold flex items-center">
            {t(title)} {!isRead && <Tag className="bg-blue-700 text-white border-0 ml-2">{t("NEW")}</Tag>}
          </h6>
          <p className="text-gray-400 text-xs">
            {date}
          </p>
        </header>
        <p>{t(message)}</p>
      </div>
    </Menu.Item>
  )
}

function NotificationDropdown({ children, onClick, isNewNotification = false, ...props }){
  const history = useHistory();
  const { t } = useTranslation()

  return(
    <div style={{position: 'relative' }} id="notificationDropdown">
      <Dropdown
        overlay={
          <Menu className="w-96 rounded-md" {...props}>
            <h5 className="p-3 text-gray-400 text-lg">{t("Notifications")}</h5>
            <Menu.Divider />
            <div className="h-96 overflow-scroll">
              {children}
            </div>
            <Menu.Divider />
            <h5 className="p-2 text-blue-700 text-center cursor-pointer" onClick={() => {
          history.push("/notification-list")
        }}>{t("See All")} &gt;</h5>
          </Menu>
        }
        trigger={["click"]}
        placement="bottomRight"
        getPopupContainer={() => document.getElementById('notificationDropdown')}
      >
        <Badge dot={isNewNotification} onClick={onClick}>
          <BellOutlined className="text-xl leading-none cursor-pointer" />
        </Badge>
      </Dropdown>
    </div>
  )
}

NotificationDropdown.Item = NotificationDropdownItem;

export default NotificationDropdown;