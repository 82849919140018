import { LeftOutlined , RightOutlined } from "@ant-design/icons";
import { Card, Col ,Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import {useFetchListDepartmentQuery } from "../../config/features";
import { useTranslation } from "react-i18next";
export default function EditOffice() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  // const [isModalDeleteDept, setIsModalDeleteDept] = useState(false);
  // const [isModalDeleteOffice, setIsModalDeleteOffice] = useState(false);
  // const [isModalSuccess, setIsModalSuccess] = useState(false);
  // const [departmentId, setDepartmentId] = useState(null);
  // const [departmentName, setDepartmentName] = useState(null);
  // const [departmentNameToDelete, setDepartmentNameToDelete] = useState(null);
  // const [officeName, setOfficeName] = useState(null);
  // const [officeNameToDelete, setOfficeNameToDelete] = useState(null);

  const [paramsTable, setParamsTable] = useState({
    office_id: id,
    row: 10,
    page: 1,
    sort_by: "created_at",
    order: "desc",
  });

  const { 
    data: dataListDepartment = [], 
    isLoading, 
  } = useFetchListDepartmentQuery(paramsTable, {
    refetchOnMountOrArgChange: true,
  });



  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }


  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Department Name",
      dataIndex: "dept_name",
      key: "dept_name",
    },
    {
      title: "Date Issued",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return created_at ? moment(created_at, moment.ISO_8601).format("DD/MM/YY") : "--"
      }
    },
    {
      title: "User",
      dataIndex: "total_users",
      key: "total_users",
    },
    {
        render: (record) => {
          return (
            <div
              className="group cursor-pointer"
              onClick={() =>
                history.push({
                  pathname: `/panel-admin/department-detail/${record?.id}`,
                  state: record,
                })
              }
            >
              <RightOutlined className="group-hover:text-blue-500" />
            </div>
          );
        },
      },
  ];

  function datasetsListTable(data) {
    const datasets = [];

    if(data){
      for (let i = 0; i < data.length; i++) {
        datasets.push({
          no: i + 1 + paramsTable.row * (paramsTable.page - 1),
          ...data[i],
        });
      }
    }

    return datasets;
  }


  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>

          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    {t("depactivity")}
                  </h1>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Card className="rounded-md">
            <Col span={24} className="mb-4">
              <Row gutter={[24, 24]} justify="space-between">
                <Col>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  {t("listdepartement")}
                  </h3>
                </Col>
              </Row>
            </Col>
            <Table
              columns={columns}
              dataSource={datasetsListTable(dataListDepartment)}
              scroll={{ x: 800 }}
              loading={isLoading}
              pagination={{
                current: paramsTable.page,
                pageSize: paramsTable.row,
                total: dataListDepartment[0] ? dataListDepartment[0]?.total : 0,
              }}
              onChange={handleChangeTable}
            />
          </Card>
        </Col>
      </Row>
      <Spinner loading={isLoading } />
    </>
  );
}
