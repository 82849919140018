import { Input } from "antd";
import React from "react";

export default class NumericInput extends React.Component {
  onChange = (e) => {
    const { value } = e.target;
    const reg = /^\d+$/;
    if ((!isNaN(value) && reg.test(value)) || value === "") {
      this.props.onChange(value);
    }
  };

  render() {
    return <Input {...this.props} onChange={this.onChange} />;
  }
}
