import { LeftOutlined } from "@ant-design/icons";
import { Alert, Card, Col, Form, Input, message, Row, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import { useFetchProfileUserQuery } from "../../config/features";
import {
  useEditOfficeMutation,
  useFetchListOfficePicQuery,
  useFetchListOfficeTypeQuery,
} from "../../config/features/office";
import { errorMessage } from "../../utils";
import { useTranslation } from "react-i18next";
const { Option } = Select;

export default function EditOffice() {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { office_name, office_type_id, pic_id, is_active, } = useLocation().state;

  const { data: userProfile } = useFetchProfileUserQuery();
  const [editOffice, { isSuccess, isLoading, error, isError }] = useEditOfficeMutation();
  const { data: listOfficeType = [] } = useFetchListOfficeTypeQuery();
  const { data: listOfficePic = [] } = useFetchListOfficePicQuery(id);


  const [form] = Form.useForm();
  const [isModalSuccess, setIsModalSuccess] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      office_name,
      office_type_id,
      pic_id,
      is_active,
    });
    if (isSuccess) {
      setIsModalSuccess(true);
      form.resetFields();
    }
    if (isError) {
      errorMessage(error);
    }
  }, [isSuccess, history, form, office_name, office_type_id, error, isError, pic_id, is_active]);

  async function handleSubmit(body) {
    try {
      editOffice({ officeId: id, body });
    } catch (error) {
      message.error(error.message);
    }
  }

  const handleToDetail = () => {
    setIsModalSuccess(false);
    history.push(`/office`);
  };

  return (
    <>
      <Form
        onFinish={(payload) => handleSubmit(payload)}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.push("/office")}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      {t("Edit Work Unit")}
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    className="md:w-40"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {t("Update")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Alert
                    type="warning"
                    className="text-center"
                    description={t("Be careful in changing work unit data! If you make any changes to work unit type, it may change / lost your access to the system.")}
                  />
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 12]}>
                    <Col xs={24}>
                      <Form.Item
                        label={t("Work Unit Name")}
                        name="office_name"
                        rules={[
                          {
                            required: true,
                            message: t("Please input work unit name!"),
                          },
                        ]}
                      >
                        <Input placeholder={t("Enter work unit name")} />
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item
                        label={t("Work Unit Type")}
                        name="office_type_id"
                        rules={[
                          {
                            required: true,
                            message: t("Please select work unit type!"),
                          },
                        ]}
                      >
                        <Select placeholder={t("Not Selected")} disabled>
                          {listOfficeType.map((item, index) => {
                            return (
                              <Option key={index} value={item.office_type_id}>
                                {item.office_type_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item
                        label="PIC"
                        name="pic_id"
                        rules={[
                          {
                            required: true,
                            message: t("Please select PIC!"),
                          },
                        ]}
                      >
                        <Select
                          placeholder={t("Not Selected")}
                          defaultValue={null}
                        >
                          {listOfficePic.map((item, index) => {
                            return (
                              <Option key={index} value={item.user_id}>
                                {item.full_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label={t("Status")}
                    name="is_active"
                    rules={[
                      {
                        required: false,
                        message: t("Please select status!"),
                      },
                    ]}
                    defaultValue={false}
                    valuePropName="checked"
                  >
                    <Switch
                      checkedChildren={t("Active")}
                      unCheckedChildren={t("Inactive")}
                      defaultChecked={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      <Modal
        title={t("Updated Success!")}
        subtitle={t("Changes have been saved! Now you can continue browsing.")}
        type="success"
        visible={isModalSuccess}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={handleToDetail}>
            {t("Done")}
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
