import { SendOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Tabs,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as CheckCircleIcon } from "../../assets/icons/check-circle-outline.svg";
import { ReactComponent as FilterOutline } from "../../assets/icons/filter-outline.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-circle-outline.svg";
import {
  useFetchSentDocumentQuery,
  useFetchReceivedDocumentQuery,
} from "../../config/features/document";
import { errorMessage } from "../../utils";
import { useTranslation } from "react-i18next";
const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

export default function DocumentMessage() {
  const history = useHistory();
  const { t } = useTranslation();
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [visibleFilterReceived, setVisibleFilterReceived] = useState(false);
  const [visibleFilterSent, setVisibleFilterSent] = useState(false);
  const [paramsTableSent, setParamsTableSent] = useState({
    row: 10,
    page: 1,
    sort_by: "created_at",
    order: "desc",
    search: "",
    start: "",
    end: "",
  });

  const [paramsTableReceived, setParamsTableReceived] = useState({
    row: 10,
    page: 1,
    sort_by: "created_at",
    order: "desc",
    search: "",
    start: "",
    end: "",
  });

  const {
    data: dataListSent = [],
    isFetching: isFetchingSent,
    refetch: refetchSent,
    error: errorSent,
    isError: isErrorSent,
  } = useFetchSentDocumentQuery(paramsTableSent);
  const {
    data: dataListReceived = [],
    isFetching: isFetchingReceived,
    refetch: refetchReceived,
    error: errorReceived,
    isError: isErrorReceived,
  } = useFetchReceivedDocumentQuery(paramsTableReceived);

  useEffect(() => {
    refetchSent();
    refetchReceived();
  }, [refetchSent, refetchReceived]);

  useEffect(() => {
    if (isErrorSent) {
      errorMessage(errorSent);
    }
  }, [isErrorSent, errorSent]);

  useEffect(() => {
    if (isErrorReceived) {
      errorMessage(errorReceived);
    }
  }, [isErrorReceived, errorReceived]);

  function handleResetFilterReceived() {
    setParamsTableReceived({
      ...paramsTableReceived,
      sort_by: "created_at",
      order: "desc",
      start: "",
      end: "",
    });
  }

  function handleResetFilterSent() {
    setParamsTableSent({
      ...paramsTableSent,
      sort_by: "created_at",
      order: "desc",
      start: "",
      end: "",
    });
  }

  function handleDateReceived(date) {
    setParamsTableReceived({
      ...paramsTableReceived,
      start: moment(date[0], moment.ISO_8601).format("MM-DD-YYYY"),
      end: moment(date[1], moment.ISO_8601).format("MM-DD-YYYY"),
    });
  }

  function handleDateSent(date) {
    setParamsTableSent({
      ...paramsTableSent,
      start: moment(date[0], moment.ISO_8601).format("MM-DD-YYYY"),
      end: moment(date[1], moment.ISO_8601).format("MM-DD-YYYY"),
    });
  }

  const menuFilterReceived = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={handleResetFilterReceived}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Date</p>
            <RangePicker
              value={
                paramsTableReceived.start && [
                  moment(paramsTableReceived.start, "MM-DD-YYYY"),
                  moment(paramsTableReceived.end, "MM-DD-YYYY"),
                ]
              }
              format="DD/MM/YYYY"
              onChange={handleDateReceived}
              allowClear={false}
            />
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="w-full h-0.5 bg-gray-100 rounded-full" />
        </Menu.Item>
        <Menu.Item>
          <p className="text-gray-500 text-base font-medium">Sort</p>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort By</p>
            <Select
              defaultValue="created_at"
              style={{ width: "100%" }}
              onChange={(e) =>
                setParamsTableReceived({ ...paramsTableReceived, sort_by: e })
              }
              value={paramsTableReceived.sort_by}
            >
              <Option value="created_at">Created Date</Option>
              <Option value="subject">Subject</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort Type</p>
            <Radio.Group
              defaultValue="desc"
              buttonStyle="solid"
              onChange={(e) =>
                setParamsTableReceived({
                  ...paramsTableReceived,
                  order: e.target.value,
                })
              }
              value={paramsTableReceived.order}
            >
              <Radio value="asc">Asc</Radio>
              <Radio value="desc">Desc</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const menuFilterSent = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={handleResetFilterSent}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Date</p>
            <RangePicker
              value={
                paramsTableSent.start && [
                  moment(paramsTableSent.start, "MM-DD-YYYY"),
                  moment(paramsTableSent.end, "MM-DD-YYYY"),
                ]
              }
              format="DD/MM/YYYY"
              onChange={handleDateSent}
              allowClear={false}
            />
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="w-full h-0.5 bg-gray-100 rounded-full" />
        </Menu.Item>
        <Menu.Item>
          <p className="text-gray-500 text-base font-medium">Sort</p>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort By</p>
            <Select
              defaultValue="created_at"
              style={{ width: "100%" }}
              onChange={(e) =>
                setParamsTableSent({ ...paramsTableSent, sort_by: e })
              }
              value={paramsTableSent.sort_by}
            >
              <Option value="created_at">Created Date</Option>
              <Option value="subject">Subject</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort Type</p>
            <Radio.Group
              defaultValue="desc"
              buttonStyle="solid"
              onChange={(e) =>
                setParamsTableSent({
                  ...paramsTableSent,
                  order: e.target.value,
                })
              }
              value={paramsTableSent.order}
            >
              <Radio value="asc">Asc</Radio>
              <Radio value="desc">Desc</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const columnsReceived = [
    {
      title: "From",
      dataIndex: "sender_name",
      key: "sender_name",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (e) => {
        return <p className="w-60 truncate">{e}</p>;
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY");
      },
    },
  ];

  const columnsSend = [
    {
      title: "To",
      dataIndex: "recipient_to",
      key: "recipient_to",
      render: (recipient_to) => {
        return (
          <>
            {recipient_to?.map((item, index) => {
              return (
                <>
                  <p key={index}>{item[2]}</p>
                </>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (e) => {
        return <p className="w-60 truncate">{e}</p>;
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY");
      },
    },
  ];

  const onSearchReceived = (value) =>
    setParamsTableReceived({ ...paramsTableReceived, search: value });

  const onSearchSent = (value) =>
    setParamsTableSent({ ...paramsTableSent, search: value });

  function handleCancel() {
    setIsModalDelete(false);
  }

  async function handleDelete() {
    try {
      setIsModalDelete(false);
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleDone() {
    setIsModalSuccess(false);
  }

  function handleVisibleFilterReceived(flag) {
    setVisibleFilterReceived(flag);
  }

  function handleVisibleFilterSent(flag) {
    setVisibleFilterSent(flag);
  }

  function handleChangeTableReceived(pagination) {
    setParamsTableReceived({
      ...paramsTableReceived,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  function handleChangeTableSent(pagination) {
    setParamsTableSent({
      ...paramsTableSent,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <h1 className="text-xl md:text-2xl font-medium">
                Document Message
              </h1>
            </Col>
            <Col>
              <Button
                className="bg-blue-700 hover:bg-blue-600 md:w-40 rounded-md"
                type="primary"
                onClick={() => history.push("/document-message/send")}
              >
                <div className="flex items-center justify-center space-x-2">
                  <SendOutlined />
                  <p>Send Document</p>
                </div>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Received Document" key="1">
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Row gutter={[24, 24]} justify="end">
                      <Col>
                        <Row gutter={[24, 24]}>
                          <Col>
                            <Search
                              placeholder="Search"
                              allowClear
                              onSearch={onSearchReceived}
                              onChange={(e) =>
                                setTimeout(() => {
                                  onSearchReceived(e.target.value);
                                }, 1500)
                              }
                              style={{
                                width: 200,
                                borderRadius: "10px !important",
                              }}
                            />
                          </Col>
                          <Col>
                            <Dropdown
                              overlay={menuFilterReceived}
                              onVisibleChange={handleVisibleFilterReceived}
                              visible={visibleFilterReceived}
                              trigger={["click"]}
                            >
                              <Button
                                className="bg-blue-700 hover:bg-blue-600"
                                type="primary"
                                shape="round"
                                icon={<FilterOutline />}
                              />
                            </Dropdown>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={columnsReceived}
                      dataSource={dataListReceived}
                      scroll={{ x: 800 }}
                      loading={isFetchingReceived}
                      pagination={{
                        current: paramsTableReceived.page,
                        pageSize: paramsTableReceived.row,
                        total: dataListReceived[0]
                          ? dataListReceived[0]?.total
                          : 0,
                      }}
                      onChange={handleChangeTableReceived}
                      onRow={(record) => {
                        return {
                          onClick: () => {
                            history.push(
                              `/document-message/received/${record.sent_id}`
                            );
                          }, // click row
                        };
                      }}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Sent Document" key="2">
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Row gutter={[24, 24]} justify="end">
                      <Col>
                        <Row gutter={[24, 24]}>
                          <Col>
                            <Search
                              placeholder="Search"
                              allowClear
                              onSearch={onSearchSent}
                              onChange={(e) =>
                                setTimeout(() => {
                                  onSearchSent(e.target.value);
                                }, 1500)
                              }
                              style={{
                                width: 200,
                                borderRadius: "10px !important",
                              }}
                            />
                          </Col>
                          <Col>
                            <Dropdown
                              overlay={menuFilterSent}
                              onVisibleChange={handleVisibleFilterSent}
                              visible={visibleFilterSent}
                              trigger={["click"]}
                            >
                              <Button
                                className="bg-blue-700"
                                type="primary"
                                shape="round"
                                icon={<FilterOutline />}
                              />
                            </Dropdown>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={columnsSend}
                      dataSource={dataListSent}
                      scroll={{ x: 800 }}
                      loading={isFetchingSent}
                      pagination={{
                        current: paramsTableSent.page,
                        pageSize: paramsTableSent.row,
                        total: dataListSent[0] ? dataListSent[0]?.total : 0,
                      }}
                      onChange={handleChangeTableSent}
                      onRow={(record) => {
                        return {
                          onClick: () => {
                            history.push(
                              `/document-message/sent/${record.sent_id}`
                            );
                          }, // click row
                        };
                      }}
                    />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
      <Modal
        className="border-red-400 rounded-md"
        style={{ borderTopWidth: 14 }}
        visible={isModalDelete}
        footer={false}
        onCancel={handleCancel}
      >
        <div className="flex flex-col space-y-4 px-8 -mt-20">
          <div className="flex flex-col space-y-2 justify-center items-center">
            <TrashIcon className="w-20 h-20 bg-red-500 rounded-full" />
            <p className="text-gray-800 font-semibold text-xl md:text-2xl">
              Delete Office?
            </p>
            <p className="text-gray-500">
              Are you sure want to delete this office?
            </p>
          </div>
          <div className="flex flex-row space-x-4">
            <Button
              onClick={handleCancel}
              className="w-full rounded-md"
              ghost
              danger
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              className="w-full rounded-md"
              danger
              type="primary"
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        className="border-blue-500 rounded-md"
        style={{ borderTopWidth: 14 }}
        visible={isModalSuccess}
        footer={false}
      >
        <div className="flex flex-col space-y-4 px-8 -mt-20">
          <div className="flex flex-col space-y-2 justify-center items-center">
            <CheckCircleIcon className="w-20" />
            <p className="text-gray-800 font-semibold text-xl md:text-2xl">
              Updated Success!
            </p>
            <p className="text-gray-500">
              Changes have been saved! Now you can continue browsing.
            </p>
          </div>
          <div className="flex justify-center">
            <Button
              onClick={handleDone}
              className="w-20 rounded-md"
              type="primary"
            >
              Done
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
