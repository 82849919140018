import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { rootApi, access } from "../config";

export const topupApi = createApi({
  reducerPath: "api/topup",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi,
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      
      fetchListPayment: builder.query({
        query({bill_id}) {
          //console.log(bill_id);
          return `/admin/paymentmethode/${bill_id}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchConfirmPayment: builder.query({
        query({bill_id}) {
          //console.log(bill_id);
          return `paymentmethode/${bill_id}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

    };
  },
});

export const {  
  useFetchListPaymentQuery,
  useFetchConfirmPaymentQuery
} = topupApi;
