import { CheckOutlined, LeftOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import Button from "../../components/Button";
import { numberCommas } from "../../utils";

export default function PaymentPage({
  next,
  back,      
  data,    
}) {

  const [counter, setCounter] = useState(3);
  const [btn_status, setBtnStatus] = useState(true);
  
  

  function openInNewTab(url) {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  useEffect(() => {    
    window.scrollTo(0, 0);        
  }, []);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if(counter <= 0){
        setBtnStatus(false);        
        openInNewTab(data?.data.payment_url);
    }
    return () => clearInterval(timer);
  }, [counter]);


  return (
    <Fade>
      <Row gutter={[24, 12]} justify="center">        
        <Col xs={24} md={18}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <div className="cursor-pointer" onClick={back}>
                  <LeftOutlined className="hover:text-gray-400" /> 
                </div>
              </Col>              
              <Col>
                <LeftOutlined className="hidden" />
              </Col>
            </Row>
            <Row gutter={[24, 24]} justify="center">
              <Col>
                <div>
                    <img src="/images/hourglass.png" width={64} height={64} />
                </div>
              </Col>              
              <Col gutter={[24, 24]} style={{marginTop: 20, padding: 20,}}>
                <h1 className="text-base md:text-lg font-small text-black-600" style={{textAlign: "center"}}>
                    We have received your payment request. In a moment we will direct you to the payment page. Please wait
                </h1>                
              </Col>
              <Col span={24}>
                <Button className="w-full " type="primary" disabled={btn_status} onClick={() => openInNewTab(data?.data.payment_url)}>                                         
                    {(counter < 0) ? "Redirecting on "+counter : "Manual Redirect" }                  
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fade>
  );
}
