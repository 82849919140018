import { DeleteOutlined, LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Form, Input, message, Row, Select } from "antd";
import { t } from "i18next";
import { useMemo } from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import {
  useAddGroupMutation,
  useFetchListMembersQuery,
} from "../../config/features/group";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { errorMessage } from "../../utils";
const { Option } = Select;

export default function AddGroup() {
  const history = useHistory();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [searchUser, setSearchUser] = useState("");

  const { data: profileUser = {} } = useFetchProfileUserQuery();
  const [addGroup, { isSuccess, isLoading, error, isError }] =
    useAddGroupMutation();
  const { data: listUser = [] } = useFetchListMembersQuery({
    search: searchUser,
  });

  const [form] = Form.useForm();
  const addedUsers = Form.useWatch("members", form);
  const [payload, setPayload] = useState({});
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);

  const userOptions = useMemo(() => {
    let options = []
    let addedUserTable = {}
    addedUsers?.forEach(item => {
      if(item){
        addedUserTable[item?.user_id] = 1
      }
    })
    listUser?.forEach(item => {
      options.push({
        disabled: addedUserTable[item?.user_id] ? true : false, 
        ...item
      })
    })

    return options
  }, [addedUsers, listUser])

  useEffect(() => {
    form.setFieldsValue({
      members: [{ user_id: profileUser?.user_id, role: 1 }]
    })
  }, [form, profileUser])

  useEffect(() => {
    if (isSuccess) {
      setIsModalSuccess(true);
    } else if (isError) {
      errorMessage(error);
    }
  }, [isSuccess, error, isError]);

  const onFinish = (values) => {
    if (values.members === undefined || values.members?.length < 1) {
      message.error("Please add members group");
    } else {
      showModal(values);
    }
  };

  async function handleSubmit(values) {
    let body = values;
    body.members.shift()

    try {
      addGroup(body);
    } catch (error) {
      message.error(error.message);
      form.resetFields();
    }
  }

  const showModal = (values) => {
    setIsModalConfirm(true);
    setPayload(values);
  };

  const handleOk = () => {
    setIsModalConfirm(false);
    handleSubmit(payload);
  };

  const handleCancel = () => {
    setIsModalConfirm(false);
  };

  const handleDone = () => {
    setIsModalSuccess(false);
    history.push(`/group`);
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.goBack()}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                     {t("addgroup")}
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button
                  className="md:w-40"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  {t("addgroup")}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    {t("inputgrup")}
                  </h3>
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 12]}>
                    <Col xs={24}>
                      <Form.Item
                        label={t("groupname")}
                        name="group_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input group name!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter group name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.List name="members">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(
                              ({ key, name, fieldKey, ...restField }) => (
                                <Row key={key} gutter={[24, 12]} align="middle">
                                  <Col>
                                    <Form.Item
                                      {...restField}
                                      label={t("useremail")}
                                      name={[name, "user_id"]}
                                      fieldKey={[fieldKey, "user_id"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please enter user!",
                                        },
                                      ]}
                                    >
                                      <Select
                                        placeholder="Enter user email"
                                        style={{ width: 300 }}
                                        showSearch
                                        disabled={key === 0}
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {userOptions.map((item, index) => {
                                          return (
                                            (
                                              <Option
                                                key={index}
                                                value={item?.user_id}
                                                disabled={item?.disabled}
                                              >
                                                {item?.email}
                                              </Option>
                                            )
                                          );
                                        })}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col>
                                    <Form.Item
                                      {...restField}
                                      label="Role"
                                      name={[name, "role"]}
                                      fieldKey={[fieldKey, "role"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please enter your role!",
                                        },
                                      ]}
                                      initialValue={2}
                                    >
                                      <Select
                                        placeholder="Enter user role"
                                        style={{ width: 300 }}
                                        showSearch
                                        disabled={key === 0}
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        allowClear={false}
                                      >
                                        <Option value={1}>Owner</Option>
                                        <Option value={2}>Member</Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  {key !== 0 && 
                                    <Col span={1}>
                                      <Button
                                        type="primary"
                                        danger
                                        onClick={() => remove(name)}
                                        icon={<DeleteOutlined />}
                                      >
                                        {t("btndelete")}
                                      </Button>
                                    </Col>
                                  }
                                </Row>
                              )
                            )}
                            <Form.Item>
                              <Button
                                type="primary"
                                ghost
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                              >
                                {t("btnadduser")}
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Are you sure?"
        subtitle="After the data has been added, you cannot change the data. Please make sure the data is correct."
        type="warning"
        visible={isModalConfirm}
        onCancel={handleCancel}
      >
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">Group Name</p>
            <div className="w-2" />
            <p className="text-gray-800 truncate">
              {form.getFieldValue("group_name")}
            </p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">Users</p>
            <div className="w-2" />
            <div>
              {listUser?.map((item, index) => {
                return (
                  form
                    .getFieldValue("members")
                    ?.find((e) => e?.user_id === item?.user_id) && (
                    <p key={index} className="text-gray-800 truncate">
                      {item.full_name}
                    </p>
                  )
                );
              })}
            </div>
          </div>
          <Divider />
        </div>
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            ghost
            type="primary"
            onClick={handleCancel}
          >
            Back
          </Button>
          <Button className="w-full" type="primary" onClick={handleOk}>
            Add
          </Button>
        </div>
      </Modal>
      <Modal
        title="Added Success!"
        subtitle="New group has been added! Now you can continue browsing"
        type="success"
        visible={isModalSuccess}
        onCancel={() => setIsModalSuccess(false)}
        closable={false}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={handleDone}>
            Done
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
