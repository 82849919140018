import { CloseOutlined, LeftOutlined, ArrowsAltOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Input,
  message,
  Row,
  Select,
  Tooltip,
  Button as ButtonAntd,
  Form,
  Modal as ModalAntd
} from "antd";
import { PDFDocument, degrees } from "pdf-lib";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";
import OtpInput from "react-otp-input";
import { Document, Page } from "react-pdf";
import Fade from "react-reveal/Fade";
import { useHistory, useLocation, useParams } from "react-router-dom";
import QrCodeSVG, {
  ReactComponent as QrCodeIcon,
} from "../../assets/icons/qrcode.svg";
import { Button, Modal, Spinner } from "../../components";
import { useFetchQuotaQuery, useSendOtpMutation } from "../../config/features";
import { useFetchDocumentPreviewQuery } from "../../config/features/document";
import { useAddSingleStampMutation, useFetchListJenisDocumentQuery } from "../../config/features/peruri";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { errorMessage, getPosition, numberCommas } from "../../utils";
import Countdown, { zeroPad } from "react-countdown";

//assets
import EditIconYellow from '../../assets/img/icon/edit-doc-yellow.png'
import EditIconBlack from '../../assets/img/icon/edit-doc-black.png'
import google1 from '../../assets/img/google-1.png'
import google2 from '../../assets/img/google-2.png'
import safari1 from '../../assets/img/safari1.png'
import safari2 from '../../assets/img/safari2.png'
import safari3 from '../../assets/img/safari3.png'
import firefox1 from '../../assets/img/firefox1.png'
import firefox2 from '../../assets/img/firefox2.png'
import arrowRight from '../../assets/img/icon/right-arrow.png'
import arrowBtm from '../../assets/img/icon/arrow-btm.png'
import arrowUp from '../../assets/img/icon/arrow-up.png'
import { useTranslation } from "react-i18next";
import axios from "axios";
import { rootApi } from "../../config/features/config";


// Dynamic import for stamp image based on env variable
// This is because prod and others has different stmap image
let imgStamp;
import(`../../assets/img/env/${process.env.REACT_APP_ROOT_ENV}/e-meterai.png`)
  .then((module) => {
    imgStamp = module.default;
  });

const { Option } = Select;
const { TextArea } = Input;

export default function SingleStamp() {
  const history = useHistory();
  const tokenAccess = useMemo(() => localStorage.getItem('access'), [])
  const location = useLocation();
  const { t } = useTranslation();
  const { id: doc_id } = useParams();
  const [totalQuota, setTotalQuota] = useState(0);
  const [pdfFile, setPdfFile] = useState(null);
  const [stampImg, setStampImg] = useState(null)

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  let [activeDrags, setActiveDrags] = useState({});
  const [deltaPosition, setDeltaPosition] = useState({});
  const [stampPosition, setStampPosition] = useState({});
  const [totalStampPosition, setTotalStampPosition] = useState([]);
  const [size, setSize] = useState({ x: 100, y: 100 });
  const [sizing, setSizing] = useState({
    width: 100,
    height: 100,
  });
  let [totalStamp, setTotalStamp] = useState([]);
  const [moveable] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalOtp, setIsModalOtp] = useState(false);
  const [isModalDocType, setIsModalDocType] = useState(false);
  const [guideModal, setGuideModal] = useState({ guide1: false, guide2: false, guide3: false, })

  //bypassotp
  const [isModalConfirm2, setIsModalConfirm2] = useState(false);
  function toggleModalConfirm2() {
    setIsModalConfirm2(!isModalConfirm2);
  }

  const [docType, setDocType] = useState(location?.state?.docType)
  const [otpValue, setOtpValue] = useState("");
  const [isFirstOtpSent, setIsFirstOtpSent] = useState(false);
  const [countdownValue, setCountdownValue] = useState(Date.now());
  const countdownRef = useRef();

  const [isLoadingPreview, setIsLoadingPreview] = useState(true);
  const [reloadDocument, setReloadDocument] = useState(0);
  const [userLocation, setUserLocation] = useState(null)

  const [activeLocation, setActiveLocation] = useState(false)
  const [isLocationBlocked, setIsLocationBlocked] = useState(false)


  const [
    sendOtp,
    { data: dataOtp, isError: isErrorOtp, error: errorOtp, isLoading: isLoadingOtp },
  ] = useSendOtpMutation();

  const [
    addSingleStamp,
    {
      isSuccess,
      data: responseAddStamp,
      isError,
      error: errorStamp,
      isLoading,
    },
  ] = useAddSingleStampMutation();

  const { data: userProfile = {} } = useFetchProfileUserQuery();

  const { data: previewDocument = "", isSuccess: isSuccessPreview } =
    useFetchDocumentPreviewQuery(doc_id);

  const { data: dataQuota, refetch: refetchQuota } = useFetchQuotaQuery();

  const { data: dataListDocType, refetch: refetchDocType } = useFetchListJenisDocumentQuery()

  const getMeteraiQuota = useCallback(() => {
    for (let i = 0; i < dataQuota?.length; i++) {
      if (dataQuota[i]?.id === "1") {
        return dataQuota[i]?.remaining;
      }
    }
  }, [dataQuota])

  const getMeteraiSpeciment = async () => {
    setIsLoadingPreview(true)
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${rootApi}/platform/get-speciment`,
        headers: {
          token: tokenAccess
        }
      })
      setStampImg(data.data.base64Speciment)
      setIsLoadingPreview(false)
    } catch (e) {
      setStampImg(imgStamp)
      setIsLoadingPreview(false)
      if (e.response) {
        const { data: { error } } = e.response
        // message.error(error)
        console.log(error);
      }
      else {
        console.log(e);
        // message.error(e)
      }
    }
  }

  useEffect(() => {
    getMeteraiSpeciment()
  }, [])
  useEffect(() => {
    setTotalQuota(getMeteraiQuota())
  }, [getMeteraiQuota])

  useEffect(() => {
    refetchQuota();
  }, [refetchQuota]);

  useEffect(() => {
    if (isError) {
      errorMessage(errorStamp);
      clearOtp();
      if (errorStamp?.data?.error === "Incorrect OTP") {
        setIsModalOtp(true);
      }
    }

    if (isErrorOtp) {
      errorMessage(errorOtp);
    }
  }, [isError, errorStamp, isErrorOtp, errorOtp]);

  useEffect(() => {
    if (isSuccess) {
      setIsModalOtp(false);
      clearOtp();
      history.replace(`/stamp-management/detail/${responseAddStamp}`);
      setDisableSubmit(true);
    }
  }, [isSuccess, responseAddStamp, history]);

  useEffect(() => {
    function base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    }

    async function fetchData() {
      try {
        setIsLoadingPreview(true);
        // Request to Backend for the pdf file returned base64
        // Needs to be transform to buffer to show in Frontend
        const documentBuffer = await base64ToArrayBuffer(previewDocument);
        setPdfFile(documentBuffer);
        setIsLoadingPreview(false);
      } catch (error) {
        message.error(error.message);
        setIsLoadingPreview(false);
      }
    }

    isSuccessPreview && fetchData();
  }, [previewDocument, isSuccessPreview, reloadDocument]);


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleStart = (e) => {
    setActiveDrags({ ...activeDrags, [e]: ++activeDrags });
  };

  const handleStop = (e) => {
    setActiveDrags({ ...activeDrags, [e]: --activeDrags });
  };

  const onControlledDrag = (e, position, index) => {
    const { x, y } = position;
    // Delta position is used for FE (only x & y)
    // Stamp Position is needed for BE ( llx, lly, urx, ury )
    // llx : lower left x coordinate
    // lly : lower left y coordinate
    // urx : upper right x coordinate
    // ury : upper right y coordinate
    setDeltaPosition({ ...deltaPosition, [index]: { x, y } });
    setStampPosition({
      llx: Math.abs(x),
      lly: Math.abs(y) - sizing?.height,
      urx: Math.abs(x) + sizing?.width,
      ury: Math.abs(y),
    });
  };

  function handleAddStamp(index) {
    const isInsufficient = totalQuota <= 0
    if (isInsufficient) {
      message.error(t("You dont have enough quota"));
      return
    }

    // Delta position is used for FE (only x & y)
    // Stamp Position is needed for BE ( llx, lly, urx, ury )
    // llx : lower left x coordinate
    // lly : lower left y coordinate
    // urx : upper right x coordinate
    // ury : upper right y coordinate
    setDeltaPosition({
      ...deltaPosition,
      [index]: { x: 351, y: -520 },
    });
    setStampPosition({
      llx: 351,
      lly: Math.abs(520) - sizing?.height,
      urx: 351 + sizing?.width,
      ury: Math.abs(520),
    });
    setTotalStamp([...totalStamp, index]);
  }

  function handleCloseSignature(index) {
    //delete total signature by value
    const duplicateTotalSignature = [...totalStamp];
    for (var i = 0; i < duplicateTotalSignature.length; i++) {
      if (duplicateTotalSignature[i] === index) {
        duplicateTotalSignature.splice(i, 1);
      }
      setTotalStamp(duplicateTotalSignature);
    }

    // delete delta position of signature
    const duplicateDeltaPosition = { ...deltaPosition };
    delete duplicateDeltaPosition[index];
    setDeltaPosition(duplicateDeltaPosition);
  }

  function handleChangePage(e) {
    setPageNumber(e);
  }

  const normalizeCoordinates = (x, y, rotation, pageWidth, pageHeight) => {
    let newX, newY;
    switch (rotation % 360) {
      case 90:
        // 90 degrees: Origin at the top-left corner
        newX = pageWidth - y;
        newY = x;
        break;
      case 180:
        // 180 degrees: Origin at the top-right corner
        newX = pageWidth - x;
        newY = pageHeight - y;
        break;
      case 270:
        // 270 degrees: Origin at the bottom-right corner
        newX = y;
        newY = pageHeight - x;
        break;
      case 0:
      default:
        // 0 degrees: Default coordinate system
        newX = x;
        newY = y;
        break;
    }
    return { x: newX, y: newY }
  }

  // Embed the stamp image to the pdf
  async function modifyPdf(existingPdfBytes) {
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const res = await fetch(stampImg)
    const pngImageBytes = await res.arrayBuffer()

    const pngImage = await pdfDoc.embedPng(pngImageBytes);

    const pages = pdfDoc.getPages();
    const pageDocument = pages[pageNumber - 1];

    const rotation = pageDocument.getRotation().angle;
    const { width, height } = pageDocument.getSize();

    for (let i = 0; i < Object.keys(deltaPosition).length; i++) {
      const deltaPositionY = deltaPosition[i].y < 0
        ? Math.abs(deltaPosition[i].y)
        : -Math.abs(deltaPosition[i].y);
      const deltaPositionX = deltaPosition[i].x;

      // let x_pos = deltaPositionX;
      // let y_pos = deltaPositionY - sizing?.height;
      // if (rotation == 270) {
      //   x_pos = y_pos;
      //   y_pos = height - deltaPositionX;
      // }

      const { x: x_pos, y: y_pos } = normalizeCoordinates(deltaPositionX, deltaPositionY - sizing?.height, rotation, width, height);

      pageDocument.drawImage(pngImage, {
        x: x_pos,
        y: y_pos,
        width: sizing?.width,
        height: sizing?.height,
        rotate: degrees(rotation)
      });
    }

    const pdfBytes = await pdfDoc.save();
    const arr = new Uint8Array(pdfBytes);
    setPdfFile(arr.buffer);
  }

  const handleSave = () => {
    setTotalQuota(prevState => Number(prevState) - 1)
    modifyPdf(pdfFile);
    setTotalStamp([]);
    setDisableSubmit(false);
    setTotalStampPosition((prev) => [
      ...prev,
      { ...stampPosition, page: pageNumber },
    ]);
  };

  function clearOtp() {
    setOtpValue("");
  }

  async function handleSubmit(values) {
    try {
      addSingleStamp({
        doc_id,
        body: {
          jenis_doc: docType,
          reason: docType,
          city: (userLocation !== '' || userLocation !== undefined) ? `${userLocation?.city},${userLocation?.country}` : '',
          otp: parseInt(otpValue),
          note: values.note,
          stamps: totalStampPosition,
        },
      });

      if (userProfile?.storage_credential?.bypass_otp != "yes") {
        toggleModalOtp();
      }
    } catch (error) {
      message.error(error.message);
    }
  }

  function toggleModalConfirm() {
    setIsModalConfirm(!isModalConfirm);
  }

  function toggleModalOtp() {
    setIsModalOtp(!isModalOtp);
  }

  async function handleSendOtp(props) {
    try {
      // Make sure NOT to sent another otp on click "Next"
      setIsFirstOtpSent(true)
      message.loading({ content: t("Sending the OTP"), key: "otpMsg" });
      await sendOtp({ doc_id, ...props });
    } catch (error) {
      message.error(error.message);
    }
  }

  useEffect(() => {
    if (dataOtp?.status === "success") {
      message.success({
        content: dataOtp?.data || t("Success send OTP, please check your email."),
        key: "otpMsg"
      })
    }
  }, [dataOtp])

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <ButtonAntd
          onClick={() => {
            setCountdownValue(Date.now() + 60000)
            setTimeout(() => {
              countdownRef.current.api.start()
            }, 100)
            handleSendOtp({ resend: true });
          }}
          type="link"
          className="p-0"
        >
          {t("Resend")}
        </ButtonAntd>
      );
    } else {
      return (
        <p>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </p>
      );
    }
  };

  function handleReset() {
    setReloadDocument(reloadDocument + 1);
    setTotalStampPosition([]);
    setTotalQuota(getMeteraiQuota())
  }

  function handleModalDocType() {
    setIsModalDocType(false)
  }

  function onFinish(values) {
    setIsModalDocType(false)
    setDocType(values.docType)

  }

  const getLocation = async () => {

    setActiveLocation(false)

    const response = await getPosition()

    if (typeof response === 'object') {
      console.log(response);
      setUserLocation(response)

      if (userProfile?.storage_credential?.bypass_otp != "yes") {
        toggleModalConfirm()
      } else {
        toggleModalConfirm2()
      }

      return
    }

    setUserLocation('')
    setIsLocationBlocked(true)

  }

  const handler0 = (mouseDownEvent) => {
    mouseDownEvent.preventDefault();
    var isDown = true;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const currentSize = size
        const ratio = currentSize.x / currentSize.y
        let resizeValue = 0
        if (currentPosition.x < startPosition.x && currentPosition.y > startPosition.y) {
          // Zoom In
          resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        else if (currentPosition.x > startPosition.x && currentPosition.y < startPosition.y) {
          // Zoom Out
          resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        const currentWidth = currentSize.x + (ratio * resizeValue);
        const currentHeight = currentSize.y + resizeValue;
        setSize({
          x: currentWidth,
          y: currentHeight
        })
        setSizing({
          width: currentWidth, height: currentHeight
        })
      }
    }
    function onMouseUp() {
      isDown = false
      document.body.removeEventListener("mousemove", onmousemove);
    }
    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }
  const handler1 = (mouseDownEvent) => {
    mouseDownEvent.preventDefault();
    var isDown = true;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        // console.log("HANDLER 1");
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const currentSize = size
        const ratio = currentSize.x / currentSize.y
        let resizeValue = 0
        if (currentPosition.x > startPosition.x && currentPosition.y > startPosition.y) {
          // Zoom In
          resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        else if (currentPosition.x < startPosition.x && currentPosition.y < startPosition.y) {
          // Zoom Out
          resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        const currentWidth = currentSize.x + (ratio * resizeValue);
        const currentHeight = currentSize.y + resizeValue;
        setSize({
          x: currentWidth,
          y: currentHeight
        })
        setSizing({
          width: currentWidth, height: currentHeight
        })
      }
    }
    function onMouseUp() {
      isDown = false
      document.body.removeEventListener("mousemove", onmousemove);
    }
    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }
  const handler2 = (mouseDownEvent) => {
    mouseDownEvent.preventDefault();
    var isDown = true;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const currentSize = size
        const ratio = currentSize.x / currentSize.y
        let resizeValue = 0
        if (currentPosition.x < startPosition.x && currentPosition.y < startPosition.y) {
          // Zoom In
          resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        else if (currentPosition.x > startPosition.x && currentPosition.y > startPosition.y) {
          // Zoom Out
          resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        const currentWidth = currentSize.x + (ratio * resizeValue);
        const currentHeight = currentSize.y + resizeValue;
        setSize({
          x: currentWidth,
          y: currentHeight
        })
        setSizing({
          width: currentWidth, height: currentHeight
        })
      }
    }
    function onMouseUp() {
      isDown = false
      document.body.removeEventListener("mousemove", onmousemove);
    }
    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <Row gutter={[24, 3]}>
                    <Col span={24}>
                      <Tooltip title={location?.state?.doc_name}>
                        <h1 className="text-xl md:text-2xl font-medium truncate w-40 md:w-60 lg:w-96">
                          {location?.state?.doc_name}
                        </h1>
                        <div className="flex">
                          <img width={25} height={25} src={EditIconYellow} />
                          <span className="font-semibold text-yellow-500 mr-2">
                            {t("Stamp Document")}
                          </span>
                          <img width={25} height={25} src={EditIconBlack} />
                          <span className="font-semibold mr-1">{t("Type")} : {docType} - </span> <span onClick={() => setIsModalDocType(true)} className="text-blue-600 font-semibold cursor-pointer hover:text-blue-400">{t("Edit")}</span>
                        </div>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                className="md:w-40"
                type="primary"
                disabled={disableSubmit || docType === ""}
                onClick={() => setActiveLocation(true)}
              >
                {t("Submit")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="relative">
            <div className="w-full bg-gray-600 rounded-t-md">
              <Row gutter={[24, 12]} justify="end" align="middle">
                <Col xs={24} md={8}>
                  {
                    totalStampPosition.length === 0 &&
                    <p className="text-center text-gray-300 text-sm md:text-base">
                      {t("Please Add e-Meterai")}
                    </p>
                  }

                </Col>
                <Col>
                  <ButtonAntd
                    disabled={totalStampPosition.length < 1}
                    className="rounded-md"
                    type="default"
                    onClick={handleReset}
                  >
                    {t("Reset")}
                  </ButtonAntd>
                </Col>
                <Col xs={24} md={8}>
                  <Row
                    className="pr-4 pb-2 md:pb-0"
                    gutter={[24, 24]}
                    justify="center"
                    align="middle"
                  >
                    <Col>
                      <div className="flex flex-row bg-yellow-400 text-white rounded-full px-2 py-1 justify-center items-center">
                        <QrCodeIcon />
                        <Divider className="h-4 bg-white" type="vertical" />
                        <p className="font-medium">
                          {numberCommas(totalQuota)}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="flex flex-col space-y-4 justify-end items-end fixed mt-8 right-10 md:right-20 lg:right-32 z-50">
              <div className="flex bg-yellow-400 hover:bg-yellow-300 cursor-pointer text-white rounded-full p-1 justify-center items-center duration-700 transition">
                <Fade>
                  {totalStamp.length > 0 ? (
                    <Fade>
                      <div
                        className={`cursor-pointer text-white rounded-full p-1 justify-center items-center h-12 w-12 transition duration-500 ease-in-out ${totalStamp.length > 0 ? "flex" : "hidden"
                          }`}
                        onClick={handleSave}
                      >
                        <p className="font-semibold">{t("Done")}</p>
                      </div>
                    </Fade>
                  ) : (
                    <img
                      className="transition duration-500 ease-in-out"
                      src={QrCodeSVG}
                      alt="qr-icon"
                      width="40px"
                      onClick={() => handleAddStamp(totalStamp.length)}
                    />
                  )}
                </Fade>
              </div>
            </div>
            <div
              className="bg-gray-300 rounded-b-md overflow-auto custom-scrollbar shadow-md pb-10"
              style={{ height: "80vh" }}
            >
              <div className="flex justify-center items-center p-8">
                <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber}>
                    {totalStamp.map((_, index) => {
                      return (
                        <Draggable
                          key={index}
                          position={deltaPosition[index]}
                          onStart={moveable ? () => handleStart(index) : false}
                          onStop={() => handleStop(index)}
                          onDrag={(e, position) =>
                            onControlledDrag(e, position, index)
                          }
                          bounds="parent"
                        >
                          <div
                            className={`relative border-dashed border-2 border-gray-500 ${moveable ? "cursor-move" : "cursor-pointer"
                              }`}
                            style={{
                              width: sizing.width,

                            }}
                          >
                            <div
                              className="absolute -top-3 -right-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                              onClick={() => handleCloseSignature(index)}
                            >
                              <CloseOutlined className="font-medium" />
                            </div>
                            <img
                              src={stampImg}
                              alt="e-meterai"
                              style={{ WebkitUserDrag: "none", width: sizing.width }}
                            />
                            <div
                              className="absolute -top-3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                              onMouseDown={handler2}
                            >
                              <ArrowsAltOutlined style={{ transform: 'rotate(90deg)' }} className="font-medium" />
                            </div>
                            <div
                              className="absolute -bottom-3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                              onMouseDown={handler0}
                            >
                              <ArrowsAltOutlined className="font-medium" />
                            </div>
                            <div
                              className="absolute -bottom-3 -right-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                              onMouseDown={handler1}
                            >
                              <ArrowsAltOutlined style={{ transform: 'rotate(90deg)' }} className="font-medium" />
                            </div>
                          </div>
                        </Draggable>
                      );
                    })}
                  </Page>
                </Document>
                <div className="flex flex-row justify-center items-center space-x-2 bg-gray-100 shadow-md absolute bottom-5 right-5 p-2 rounded-md">
                  <Select
                    value={pageNumber}
                    size="small"
                    onChange={handleChangePage}
                  >
                    {Array.from(new Array(numPages), (_, index) => (
                      <Option value={index + 1}>{index + 1}</Option>
                    ))}
                  </Select>
                  <p>{`dari ${numPages} halaman`}</p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        title={t("Are you sure?")}
        subtitle={t("After the document has been stamped, you cannot change the document. Please make sure the document is correct.")}
        type="warning"
        visible={isModalConfirm}
        onCancel={toggleModalConfirm}
        page={1}
        totalPage={2}
      >
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Document Name")}</p>
            <div className="w-2" />
            <Tooltip title={location?.state?.doc_name}>
              <p className="text-gray-800 truncate">
                {location?.state?.doc_name}
              </p>
            </Tooltip>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Stamp Category")}</p>
            <div className="w-12" />
            <Tooltip title={t("Single")}>
              <p className="text-gray-800 truncate">{t("Single")}</p>
            </Tooltip>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Document Type")}</p>
            <div className="w-12" />
            <Tooltip title={docType}>
              <p className="text-gray-800 truncate">{docType}</p>
            </Tooltip>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Total Stamp")}</p>
            <div className="w-12" />
            <Tooltip title="Single Stamp">
              <p className="text-gray-800 truncate">
                {totalStampPosition?.length} Stamp
              </p>
            </Tooltip>
          </div>
          <Divider />
        </div>
        <div className="flex flex-row space-x-4">
          <Button
            onClick={toggleModalConfirm}
            className="w-full"
            ghost
            type="secondary"
          >
            {t("Back")}
          </Button>
          <Button
            onClick={() => {
              toggleModalConfirm();
              toggleModalOtp();

              if (!isFirstOtpSent) {
                handleSendOtp();
                // Set the countdown timer to enable resend button
                setCountdownValue(Date.now() + 60000)
                setTimeout(() => {
                  countdownRef.current.api.start()
                }, 100)
              }
            }}
            className="w-full"
            type="primary"
          >
            {t("Next")}
          </Button>
        </div>
      </Modal>
      <Modal
        title={t("Are you sure?")}
        subtitle={`${t("Please make sure your document is correct. OTP Code automatic has sent to your email, let’s check!")} ${userProfile?.email}`}
        type="warning"
        visible={isModalOtp}
        onCancel={toggleModalOtp}
        page={2}
        totalPage={2}
      >
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row justify-between items-center">
            <p>{t("OTP Code")}</p>
            <Countdown
              date={countdownValue}
              renderer={renderer}
              ref={countdownRef} />
          </div>
          <OtpInput
            value={otpValue}
            onChange={(otp) => setOtpValue(otp)}
            numInputs={6}
            isInputNum
            className="text-gray-800"
            containerStyle={{
              display: "flex",
              justifyContent: "space-between",
            }}
            inputStyle={{
              width: "3rem",
              height: "3rem",
              fontWeight: "bold",
              border: "2px solid #DBDBDB",
              borderRadius: 4,
              color: "#000000",
            }}
          />
          <Form onFinish={handleSubmit}>
            <div>
              <p>{t("Note (Optional)")}</p>
              <Form.Item name="note">
                <TextArea name="note" rows={4} />
              </Form.Item>
            </div>
            <Form.Item>
              <div className="flex flex-row space-x-4">
                <Button
                  onClick={() => {
                    toggleModalOtp();
                    toggleModalConfirm();
                  }}
                  className="w-full"
                  ghost
                  type="secondary"
                >
                  {t("Back")}
                </Button>
                <Button
                  disabled={otpValue?.length < 6}
                  htmlType="submit"
                  className="w-full"
                  type="primary"
                >
                  {t("Submit")}
                </Button>
              </div>
            </Form.Item>
          </Form>
          <Divider />
          <div className="text-center">
            <p className="text-gray-500 text-xs">
              {t("By pressing the “Submit” button means that you have agreed to the ")}
            </p>
            <a href="https://ca.peruri.co.id/ca/legal" target="_blank" className="text-blue-500 text-xs" >{t("Terms & Conditions")}</a>
            {/* <p className="text-blue-500 text-xs">{t("Terms & Conditions")}</p> */}
          </div>
        </div>
      </Modal>
      <Modal
        title={t("Are you sure?")}
        subtitle={t("After the document has been stamped, you cannot change the document. Please make sure the document is correct.")}
        type="warning"
        visible={isModalConfirm2}
        onCancel={toggleModalConfirm2}
        page={1}
        totalPage={1}
      >
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Document Name")}</p>
            <div className="w-2" />
            <Tooltip title={location?.state?.doc_name}>
              <p className="text-gray-800 truncate">
                {location?.state?.doc_name}
              </p>
            </Tooltip>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Stamp Category")}</p>
            <div className="w-12" />
            <Tooltip title={t("Single Stamp")}>
              <p className="text-gray-800 truncate">{t("Single Stamp")}</p>
            </Tooltip>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Document Type")}</p>
            <div className="w-12" />
            <Tooltip title={docType}>
              <p className="text-gray-800 truncate">{docType}</p>
            </Tooltip>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Total Stamp")}</p>
            <div className="w-12" />
            <Tooltip title={`${totalStampPosition?.length} Stamp`}>
              <p className="text-gray-800 truncate">
                {totalStampPosition?.length} Stamp
              </p>
            </Tooltip>
          </div>
          <Divider />
        </div>
        <Form onFinish={handleSubmit}>
          <div>
            <p>Note (Optional)</p>
            <Form.Item name="note">
              <TextArea name="note" rows={4} />
            </Form.Item>
          </div>
          <Form.Item>
            <div className="flex flex-row space-x-4">
              <Button
                onClick={() => {
                  toggleModalConfirm2();
                }}
                className="w-full"
                ghost
                type="secondary"
              >
                {t("Back")}
              </Button>
              <Button
                htmlType="submit"
                className="w-full"
                type="primary"
              >
                {t("Submit")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={activeLocation}
        type="warning"
        title={t("Confirmation Location")}
        subtitle={t("For more accurate data certificate in document, turn on device location.")}
        onCancel={() => setActiveLocation(false)}
      >
        <div className="flex justify-center">
          <Button onClick={() => setActiveLocation(false)} ghost type="primary" className="mt-9 mb-9 h-10 rounded-md w-52 tracking-tight font-medium mr-4">{t("No")}</Button>
          <Button onClick={() => getLocation()} type="primary" className="mt-9 mb-9 h-10 rounded-md w-52 tracking-tight font-medium">{t("Yes")}</Button>
        </div>
      </Modal>

      <Modal
        visible={isModalDocType}
        onCancel={handleModalDocType}
        type="warning"
        title={t("Update Document Type")}
        subtitle={t("Select document type")}
        width={500}
      >
        <Form onFinish={onFinish}>
          <Row align="midle">
            <Col md={24}>
              <Form.Item
                name="docType"
                initialValue={docType}
                rules={[{ required: true, message: t('Please select document type') }]}
              >
                <Select placeholder={t("Choose")} value={docType}>
                  {
                    dataListDocType?.length > 0 && dataListDocType?.map((data, index) => {
                      return (
                        <Option key={index} value={data.nama}>
                          {data.nama}
                        </Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
              <div className="flex justify-center">
                <Button onClick={handleModalDocType} ghost type="primary" className="mt-9 mb-9 h-10 rounded-md w-full tracking-tight font-medium mr-4">{t("No")}</Button>
                <Button htmlType="submit" type="primary" className="mt-9 mb-9 h-10 rounded-md w-full tracking-tight font-medium">{t("Yes")}</Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>

      <ModalAntd
        footer={false}
        visible={isLocationBlocked}
        width={1050}
        onCancel={() => setIsLocationBlocked(false)}
      >
        <div className='mb-6 pt-2 pb-2'>
          <div className="mb-6">
            <h4 className="text-xl font-semibold text-gray-600 mb-5">{t("Location Not Found")}</h4>
            <hr />
          </div>
          <p className='font-medium text-base text-left mt-2 text-gray-600'>{t("Your location was not found. You need to enable GPS permission to be able to Stamp documents. You can do some of the steps below. !")}</p>
          <div onClick={() => setGuideModal(prev => ({ ...prev, guide1: !prev.guide1 }))} className='mt-5 cursor-pointer'>
            <div className="flex justify-between items-center">
              <p className='text-xl font-bold'>{t("Access With Google Chrome")}</p>
              <img width={18} height={15} src={guideModal.guide1 === true ? arrowUp : arrowBtm} alt="" />
            </div>
            {
              guideModal.guide1 &&
              <div className='mt-2'>
                <p className='text-black font-medium text-base'>{t("1. Logo location on the searchbar")}</p>
                <p className='text-black font-medium text-base'>{t("2. Change the option to “Allow this site access your location”")}</p>
                <div className="mt-2 flex items-center">
                  <img src={google1} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={google2} alt="" />
                </div>
              </div>
            }
            <hr className="mt-4 h-1" />
          </div>
          <div onClick={() => setGuideModal(prev => ({ ...prev, guide2: !prev.guide2 }))} className='mt-2 cursor-pointer'>
            <div className="flex justify-between items-center">
              <p className='text-xl font-bold'>{t("Access With Safari")}</p>
              <img width={18} height={15} src={guideModal.guide2 === true ? arrowUp : arrowBtm} alt="" />
            </div>
            {
              guideModal.guide2 &&
              <div className='mt-2'>
                <p className='text-black font-medium text-base'>{t("1. Safari menu")}</p>
                <p className='text-black font-medium text-base'>{t("2. Settings for [domain name, Co: demo.vas.id]")} </p>
                <p className='text-black font-medium text-base'>{t("3. Deactivate the “Enable content blockers” checkbox")}</p>
                <div className="mt-2 flex items-center">
                  <img width={225} height={215} src={safari1} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={safari2} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={safari3} alt="" />
                </div>
              </div>
            }
            <hr className="mt-4 h-1" />
          </div>
          <div onClick={() => setGuideModal(prev => ({ ...prev, guide3: !prev.guide3 }))} className='mt-2 cursor-pointer'>
            <div className="flex justify-between items-center">
              <p className='text-xl font-bold'>{t("Access With Firefox")}</p>
              <img width={18} height={15} src={guideModal.guide3 === true ? arrowUp : arrowBtm} alt="" />
            </div>
            {
              guideModal.guide3 &&
              <div className='mt-2'>
                <p className='text-black font-medium text-base'>{t("1. Logo location on the search bar")} </p>
                <p className='text-black font-medium text-base'>{t("2. Delete the option “Block temporarily”")}</p>
                <div className="mt-2 flex items-center">
                  <img src={firefox1} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={firefox2} alt="" />
                </div>
              </div>
            }
          </div>
        </div>
        <div className="w-full">
          <hr className='mb-4' />
          <div className="flex justify-center">
            <Button
              className="bg-blue-700 rounded-md text-white w-40"
              onClick={() => setIsLocationBlocked(false)}
            >
              {t("Close")}
            </Button>
          </div>
        </div>
      </ModalAntd>
      <Spinner loading={isLoading || isLoadingOtp || isLoadingPreview} />
    </>
  );
}
