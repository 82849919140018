import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Form, Input, message, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { NumericInput, Button, Modal, Spinner } from "../../components";
import { useTranslation } from "react-i18next";
import {
  useAddDeparmentMutation,
  useFetchListDepartmentQuery,
} from "../../config/features/department";
import { useFetchListOfficeQuery } from "../../config/features/office";
import { useFetchListRoleQuery } from "../../config/features/role";
import { useAddUserMutation } from "../../config/features/user";
import { REGEXES } from "../../constant";
import { errorMessage } from "../../utils";
const { Option } = Select;

export default function AddUser() {
  const history = useHistory();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [form] = Form.useForm();

  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [payload, setPayload] = useState({ phone: null });
  const [officeName, setOfficeName] = useState("");
  const [officeId, setOfficeId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [departmentName, setDepartmentName] = useState("");

  const [addUser, { isSuccess, isLoading, error, isError }] =
    useAddUserMutation();

  const [
    addDepartment,
    {
      isSuccess: isSuccessAddDepartment,
      isError: isErrorAddDepartment,
      error: errorAddDepartment,
    },
  ] = useAddDeparmentMutation();

  const [newDepartment, setNewDepartment] = useState(null);

  const { data: listRole = [] } = useFetchListRoleQuery();
  const [paramsOffice, setParamsOffice] = useState({
    row: 100,
    page: 1,
    sort_by: "created_at",
    order: "desc",
    search: ""
  })
  const [paramsDept, setParamsDept] = useState({
    office_id: officeId, 
    page: 1, 
    row: 1000,
    search: ""
  })
  const { 
    data: listOffice = [],
    refetch: refetchListOffice,
  } = useFetchListOfficeQuery(paramsOffice);
  const {
    data: listDepartment = [],
    refetch: refetchListDepartment,
    isLoading: isLoadingListDepartment,
  } = useFetchListDepartmentQuery(paramsDept);

  useEffect(() => {
    refetchListOffice()
  }, [refetchListOffice])

  useEffect(() => {
    if (isSuccess) {
      setIsModalSuccess(true);
      form.resetFields();
    }
    if (isError) {
      errorMessage(error);
    }
  }, [isSuccess, error, isError, form]);

  useEffect(() => {
    if (isErrorAddDepartment) {
      errorMessage(errorAddDepartment);
    }
    if (isSuccessAddDepartment) {
      refetchListDepartment();
      setNewDepartment(null);
    }
  }, [
    isErrorAddDepartment,
    errorAddDepartment,
    isSuccessAddDepartment,
    refetchListDepartment,
  ]);

  const onFinish = (values) => {
    values.status_id = 1;
    setPayload({ ...payload, ...values });
    toggleModalConfirm();
  };

  async function handleSubmit(values) {
    try {
      addUser(values);
    } catch (error) {
      message.error(error.message);
    }
  }

  function toggleModalSuccess() {
    setIsModalSuccess(!isModalSuccess);
  }

  function toggleModalConfirm() {
    setIsModalConfirm(!isModalConfirm);
  }

  const handleOk = () => {
    toggleModalConfirm();
    handleSubmit(payload);
  };

  const handleDone = () => {
    toggleModalSuccess();
    history.push(`/user`);
  };

  function handleChangeNewDepartment(e) {
    setNewDepartment(e.target.value);
  }

  function handleAddDepartment() {
    addDepartment({ dept_name: newDepartment });
  }

  function handleChangePhone(e) {
    setPayload({ ...payload, phone: e?.trim() });
  }

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.push("/user")}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      {t("btnadduser")}
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    className="md:w-40"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {t("btnadduser")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    {t("inputnewuser")}
                  </h3>
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 12]}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("firstname")}
                        name="first_name"
                        rules={[
                          {
                            required: true,
                            message: t("Please input first name!"),
                          },
                          () => ({
                            validator(_, value) {
                              if (
                                !Boolean(value?.trim()) &&
                                value?.length > 0
                              ) {
                                return Promise.reject(
                                  new Error(t("must be a character!"))
                                );
                              }
                              if (value?.length <= 50) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(t("maximum 50 characters!"))
                              );
                            },
                          }),
                        ]}
                      >
                        <Input placeholder={t("Enter first name")} maxLength={50} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("lastname")}
                        name="last_name"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: t("Please input last name!"),
                        //   },
                        //   () => ({
                        //     validator(_, value) {
                        //       if (
                        //         !Boolean(value?.trim()) &&
                        //         value?.length > 0
                        //       ) {
                        //         return Promise.reject(
                        //           new Error(t("must be a character!"))
                        //         );
                        //       }
                        //       if (value?.length <= 50) {
                        //         return Promise.resolve();
                        //       }
                        //       return Promise.reject(
                        //         new Error(t("maximum 50 characters!"))
                        //       );
                        //     },
                        //   }),
                        // ]}
                      >
                        <Input placeholder={t("Enter last name")} maxLength={50} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("Email")}
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: t("The input is not valid E-mail!"),
                          },
                          {
                            required: true,
                            message: t("Please input email!"),
                          },
                        ]}
                      >
                        <Input placeholder={t("Enter email")} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("phone")}
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: t("Please input phone!"),
                          },
                          () => ({
                            validator(_, value) {
                              if (!Number(value) && value?.length > 0) {
                                return Promise.reject(
                                  new Error(t("must be a number!"))
                                );
                              }
                              if (value?.length <= 14) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(t("maximum 14 digits!"))
                              );
                            },
                          }),
                        ]}
                      >
                        <NumericInput
                          placeholder={t("Enter phone")}
                          maxLength={14}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("Work Unit")}
                        name="office_id"
                        rules={[
                          {
                            required: true,
                            message: t("Please select work unit!"),
                          },
                        ]}
                      >
                        <Select
                          placeholder={t("Please Choose Work Unit")}
                          onChange={(_, record) => {
                            setOfficeName(record?.children);
                            setOfficeId(record?.value);
                          }}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSearch={(e) => setParamsOffice({...paramsOffice, search: e})}
                          allowClear
                          onClear={() => setParamsOffice({...paramsOffice, search: ""})}
                        >
                          {listOffice?.map((item, index) => {
                            return (
                              <Option key={index} value={item.office_id}>
                                {item.office_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("Department")}
                        name="department"
                        rules={[
                          {
                            required: true,
                            message: t("Please input department!"),
                          },
                        ]}
                      >
                        <Select
                          placeholder={t("Please Choose Department")}
                          onChange={(_, record) => {
                            setDepartmentName(record?.children);
                          }}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSearch={(e) => setParamsDept({...paramsDept, search: e})}
                          loading={isLoadingListDepartment}
                          disabled={!officeId}
                          allowClear
                          onClear={() => setParamsDept({...paramsDept, search: ""})}
                        >
                          {listDepartment?.map((item, index) => {
                            return (
                              <Option key={index} value={item.id}>
                                {item.dept_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("Role")}
                        name="role_id"
                        rules={[
                          {
                            required: true,
                            message: t("Please select role!"),
                          },
                        ]}
                      >
                        <Select
                          placeholder={t("Please Choose Role")}
                          onChange={(_, record) => {
                            setRoleName(record?.children);
                          }}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {listRole.map((item, index) => {
                            return (
                              <Option key={index} value={item.role_id}>
                                {item.role_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Divider />
                      <p className="text-gray-500">
                        {t("Fill in the password if you only want to change the password")}
                      </p>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("Password")}
                        name="password"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: t("Please input password!"),
                          },
                          () => ({
                            validator(_, value) {
                              if (
                                value.match(
                                  REGEXES.password
                                )
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                 t( "Password needs to be at least 8 character, 1 uppercase, 1 special character!")
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder={t("Enter password")} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("confirmpassword")}
                        name="password_confirmation"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: t("Please confirm your password!"),
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  t("The two passwords that you entered do not match!")
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder={t("Enter password confirmation")} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      <Modal
        type="warning"
        title={t("modalwarning")}
        subtitle={t("modalwarningsub")}
        visible={isModalConfirm}
        onCancel={toggleModalConfirm}
      >
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("fullname")}</p>
            <div className="w-2" />
            <p className="text-gray-800 truncate">{payload?.full_name}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">Email</p>
            <div className="w-2" />
            <p className="text-gray-800 truncate">{payload?.email}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("phone")}</p>
            <div className="w-2" />
            <p className="text-gray-800 truncate">{payload?.phone}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Work Unit")}</p>
            <div className="w-2" />
            <p className="text-gray-800 truncate">{officeName}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Department")}</p>
            <div className="w-2" />
            <p className="text-gray-800 truncate">{departmentName}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("role")}</p>
            <div className="w-12" />
            <p className="text-gray-800 truncate">{roleName}</p>
          </div>
          <Divider />
        </div>
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={toggleModalConfirm}
          >
            {t("btnback")}
          </Button>
          <Button className="w-full" type="primary" onClick={handleOk}>
          {t("btnadduser")}
          </Button>
        </div>
      </Modal>
      <Modal
        type="success"
        title={t("modaldone")}
        subtitle={t("modaldonesub")}
        visible={isModalSuccess}
        closable={false}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={handleDone}>
          {t("btndone")}
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
