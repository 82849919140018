import { LeftOutlined } from "@ant-design/icons";
import { Col, Divider, Form, message, Row, Table } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, Spinner } from "../../components";
import ClientInformation from "./ClientInformation";
import ClientModule from "./ClientModule";
import ClientQuotation from "./ClientQuotation";
import { useAddClientMutation } from "../../config/features/platform";
import { errorMessage, numberCommas } from "../../utils";
import moment from "moment";
import { Card } from "@material-ui/core";

export default function AddClient() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [page, setPage] = useState(0);
  const [client, setClient] = useState({});
  const [module, setModule] = useState({});
  const [quotation, setQuotation] = useState({});
  const [payload, setPayload] = useState({ phone_pic: null });
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);

  const [addClient, { isSuccess, isError, error, isLoading }] =
    useAddClientMutation();

  useEffect(() => {
    if (isSuccess) {
      setIsModalSuccess(true);
      form.resetFields();
    }
    if (isError) {
      errorMessage(error);
    }
  }, [isSuccess, isError, error, form]);

  function handleNext() {
    setPage(page + 1);
  }

  function handleBack() {
    setPage(page - 1);
  }

  async function handleSubmit() {
    try {
      toggleModalConfirm();
      addClient({ client, module, quotation });
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleFinish(values) {
    let { products } = values;
    values.phone_pic = payload.phone_pic;

    if (!payload.phone_pic) {
      message.error("Please input phone number PIC");
      return;
    }

    function getTotalPrice(products) {
      let totalPrice = 0;

      for (let i = 0; i < products?.length; i++) {
        totalPrice += products[i]?.total;
      }

      return totalPrice;
    }

    switch (page) {
      case 0:
        setClient(values);
        handleNext();
        break;

      case 1:
        setModule(values);
        handleNext();
        break;

      default:
        console.log(products);
        products = products.map(item => {
          item.quantity = Number(item.quantity) || 0
          item.quota = Number(item.quota) || 0
          item.total = Number(item.total) || 0
          item.unit_price = Number(item.unit_price) || 0
          return item
        })
        setQuotation({
          contract_expire: values.contract_expire,
          contract_issue: values.contract_issue,
          total: getTotalPrice(products),
          products: products,
        });
        toggleModalConfirm();
        break;
    }
  }

  function toggleModalConfirm() {
    setIsModalConfirm(!isModalConfirm);
  }

  function toggleModalSuccess() {
    setIsModalSuccess(!isModalSuccess);
  }

  function handleDone() {
    history.push("/client-management");
  }

  return (
    <>
      <div className="relative">
        <Form
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={handleFinish}
        >
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Row gutter={[24, 24]} justify="space-between">
                <Col>
                  <Row gutter={[24, 24]} align="middle">
                    <Col>
                      <div
                        className="group cursor-pointer"
                        onClick={() => history.goBack()}
                      >
                        <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                      </div>
                    </Col>
                    <Col>
                      <h1 className="text-xl md:text-2xl font-medium">
                        Add Client
                      </h1>
                    </Col>
                  </Row>
                </Col>
                <Col span={14}>
                  <div className="flex flex-col space-y-4"> 
                    <div className="flex flex-row space-x-4 md:space-x-4 lg:space-x-12 xl:space-x-20">
                      <div
                        className={`py-0.5 px-2 md:py-1 md:px-3 text-white rounded-full font-medium text-center transition ease-in-out duration-500 ${
                          page === 0 ? "bg-blue-700" : "bg-gray-300"
                        }`}
                      >
                        1
                      </div>
                      <div
                        className={`py-0.5 px-2 md:py-1 md:px-3 text-white rounded-full font-medium text-center transition ease-in-out duration-500 ${
                          page === 1 ? "bg-blue-700" : "bg-gray-300"
                        }`}
                      >
                        2
                      </div>
                      <div
                        className={`py-0.5 px-2 md:py-1 md:px-3 text-white rounded-full font-medium text-center transition ease-in-out duration-500 ${
                          page === 2 ? "bg-blue-700" : "bg-gray-300"
                        }`}
                      >
                        3
                      </div>
                    </div>
                    <Col span={10,6}
                    className="font-medium text-sm md:text-base lg:text-lg text-blue-700 text-center">
                      {page === 0
                        ? "Client Information"
                        : page === 1
                        ? "Client Module"
                        : "Client Quotation"}
                    
                    </Col>
                  </div>
                </Col>
                
              </Row>
            </Col>
            <Col span={24}>
              {page === 0 ? (
                <ClientInformation payload={payload} setPayload={setPayload} />
              ) : page === 1 ? (
                <ClientModule form={form} />
              ) : (
                <ClientQuotation form={form} module={module} />
              )}
            </Col>
          </Row>
          <br></br>
          <Card className="w-full">
        <Col>
                  <Row gutter={[24, 24]}>
                    {page !== 0 && (
                      <Col className="w-1/2">
                        <Button className="w-full" type="primary" ghost onClick={handleBack}>
                          Back
                        </Button>
                      </Col>
                    )}
                    <Col className="w-1/2">
                      <Button className="w-full" type="primary" htmlType="submit">
                        Next
                      </Button>
                    </Col>
                  </Row>
                </Col>
        </Card>
        </Form>
      </div>
      <Modal
        title="Are you sure?"
        subtitle="After the data has been added, you cannot change the data. Please make sure the data is correct."
        type="warning"
        visible={isModalConfirm}
        onCancel={toggleModalConfirm}
        width={700}
      >
        <div className="flex flex-col space-y-2">
          <Row>
            <Col span={6}>
              <span className="text-gray-500"> Date Issued </span>
            </Col>
            <Col span={12}>
              {quotation?.contract_issue ?
               moment(quotation?.contract_issue, moment.ISO_8601).format("DD/MM/YYYY") : 
               "--"}
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <span className="text-gray-500"> Expired Date </span>
            </Col>
            <Col span={12}>
              {quotation?.contract_expire ?
               moment(quotation?.contract_expire, moment.ISO_8601).format("DD/MM/YYYY") : 
               "--"}
            </Col>
          </Row>
          <Divider />
          <Table
            dataSource={quotation?.products}
            columns={[
              {
                title: "Product Name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Unit Price",
                dataIndex: "unit_price",
                key: "unit_price",
                render: (unit_price) => {
                  return `Rp ${numberCommas(unit_price)},-`;
                },
              },
              {
                title: "Qty",
                dataIndex: "quantity",
                key: "quantity",
                render: (quantity) => {
                  return numberCommas(quantity);
                },
              },
              {
                title: "Quota",
                dataIndex: "quota",
                key: "quota",
                render: (quota) => {
                  return numberCommas(quota);
                },
              },
              {
                title: "Total",
                align: "right",
                render: (record) => {
                  return `Rp ${numberCommas(
                    record.unit_price * record.quantity
                  )},-`;
                },
              },
            ]}
            pagination={false}
          />
          <div className="flex flex-row justify-between px-4">
            <p className="font-medium text-gray-800">Total</p>
            <p className="font-medium text-gray-800">
              Rp {numberCommas(quotation?.total)},-
            </p>
          </div>
        
        </div>
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={toggleModalConfirm}
          >
            Back
          </Button>
          <Button className="w-full" type="primary" onClick={handleSubmit}>
            Add
          </Button>
        </div>
      </Modal>
      <Modal
        title="Added Success!"
        subtitle="New client has been added! Now you can continue browsing"
        type="success"
        visible={isModalSuccess}
        onCancel={toggleModalSuccess}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={handleDone}>
            Done
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
