import React from "react";
import { Button } from "antd";
import clsx from "clsx";

export default function ButtonDanger(props) {
  const className = props.className ? props.className : "";
  const classNameBtn = "rounded-md";
  return (
    <Button
      {...props}
      className={clsx(classNameBtn, className)}
      type="danger"
      icon={false}
    >
      <div className="flex items-center justify-center space-x-2">
        {props.icon}
        {props.children && <p>{props.children}</p>}
      </div>
    </Button>
  );
}
