import { FilterOutlined, RightOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  Radio,
  Row,
  Select,
  Table,
  Tag,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as ClientIcon } from "../../assets/icons/client-outline.svg";
import { ReactComponent as FilterOutline } from "../../assets/icons/filter-outline.svg";
import { Button, Modal } from "../../components";
import { useFetchListClientQuery } from "../../config/features/platform";
import { errorMessage } from "../../utils";
import { useTranslation } from "react-i18next";
import { CONTRACT_STATUS } from "../../constant";
const { Search } = Input;
const { Option } = Select;

export default function ClientManagement() {
  const history = useHistory();
  const { t } = useTranslation();
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [visibleFilter, setVisibleFilter] = useState(false);

  const [paramsTable, setParamsTable] = useState({
    row: 10,
    page: 1,
    sort_by: "created_at",
    order: "desc",
    activated: "",
    verified: "",
  });

  const {
    data: dataListOffice = {},
    isFetching,
    refetch,
    error,
    isError,
  } = useFetchListClientQuery(paramsTable);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
  }, [isError, error]);

  function handleResetFilter() {
    setParamsTable({
      ...paramsTable,
      sort_by: "created_at",
      order: "desc",
      activated: "",
      verified: "",
    });
  }

  const menuFilter = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={handleResetFilter}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort By</p>
            <Select
              defaultValue="office_name"
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, sort_by: e })}
              value={paramsTable.sort_by}
            >
              <Option value="created_at">Created Date</Option>
              <Option value="expired_at">Expired Date</Option>
              <Option value="office_name">Office Name</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">State</p>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, activated: e })}
              value={paramsTable.activated}
            >
              <Option value="">All State</Option>
              <Option value={true}>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Status</p>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, verified: e })}
              value={paramsTable.verified}
            >
              <Option value="">All Status</Option>
              <Option value={true}>Verified</Option>
              <Option value={false}>Unverified</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort Type</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) =>
                setParamsTable({ ...paramsTable, order: e.target.value })
              }
              value={paramsTable.order}
            >
              <Radio value="asc">Ascending</Radio>
              <Radio value="desc">Descending</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "ID",
      dataIndex: "office_id",
      key: "office_id",
    },
    {
      title: "Client Name",
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format(
          "dddd, DD MMMM YYYY HH:mm"
        );
      },
    },
    {
      title: "Status",
      dataIndex: "is_verified",
      key: "is_verified",
      render: (is_verified) => {
        return is_verified ? "Verified" : "Unverified";
      },
    },
    {
      title: "State",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active) => {
        return is_active ? "Active" : "Inactive";
      },
    },
    {
      title: 'Contract Status',
      dataIndex: 'contract_status',
      key: 'contract_status',
      render: (contract_status) => CONTRACT_STATUS[contract_status]?.value
    },
    {
      title: 'Expired In',
      dataIndex: 'expired_in',
      key: 'expired_in',
      render: (expired_in, row) => {
        const { expired_at } = row;
        const expired_date = moment(expired_at, moment.ISO_8601)
        const current_date = moment(new Date(), moment.ISO_8601)
        const diff_days = expired_date.diff(current_date, 'days')
        
        let expired = ''
        if (expired_in.year > 0) {
          expired += `${expired_in.year} years `
        }
        if (expired_in.month > 0) {
          expired += `${expired_in.month} months `
        }
        if (expired_in.day > 0) {
          expired += `${expired_in.day} days`
        }
        if (!expired || expired == '') {
          expired = 'Expired'
        }
        return <Tag color={diff_days > 60 ? 'green' : diff_days > 0 ? 'yellow' : 'red' }>{expired}</Tag>
      }
    },
    {
      title: "Expired Date",
      dataIndex: "expired_at",
      key: "expired_at",
      render: (expired_at) => {
        return moment(expired_at, moment.ISO_8601).format(
          "dddd, DD MMMM YYYY HH:mm"
        );
      },
    },
    {
      render: (record) => {
        return (
          <div
            className="group cursor-pointer"
            onClick={() =>
              history.push(`/client-management/detail/${record.office_id}`)
            }
          >
            <RightOutlined className="group-hover:text-blue-500" />
          </div>
        );
      },
    },
  ];

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  const onSearch = (value) => setParamsTable({ ...paramsTable, search: value });

  function handleCancel() {
    setIsModalDelete(false);
  }

  // async function handleDelete() {
  //   try {
  //     setIsModalDelete(false);
  //     deleteOffice(officeId);
  //   } catch (error) {
  //     message.error(error.message);
  //   }
  // }

  function handleDone() {
    setIsModalSuccess(false);
  }

  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }

  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <h1 className="text-xl md:text-2xl font-medium">
                Client Management
              </h1>
            </Col>
            <Col>
              <Button
                className="md:w-40"
                type="primary"
                onClick={() => history.push("/client-management/add")}
                icon={<ClientIcon />}
              >
                Add Client
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      List of Client
                    </h3>
                  </Col>
                  <Col>
                    <Row gutter={[12, 12]}>
                      <Col>
                        <Search
                          placeholder="Search"
                          allowClear
                          onSearch={onSearch}
                          onChange={(e) =>
                            setTimeout(() => {
                              onSearch(e.target.value);
                            }, 1000)
                          }
                          style={{
                            width: 200,
                            borderRadius: "10px !important",
                          }}
                        />
                      </Col>
                      <Col>
                        <Dropdown
                          overlay={menuFilter}
                          onVisibleChange={handleVisibleFilter}
                          visible={visibleFilter}
                          trigger={["click"]}
                        >
                          <Button
                            type="primary"
                            ghost
                            icon={<FilterOutlined />}
                          />
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={
                    dataListOffice?.rows &&
                    datasetsListTable(dataListOffice?.rows)
                  }
                  scroll={{ x: 800 }}
                  loading={isFetching}
                  pagination={{
                    current: paramsTable.page,
                    pageSize: paramsTable.row,
                    total: dataListOffice.total ? dataListOffice.total : 0,
                  }}
                  onChange={handleChangeTable}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Delete Client?"
        subtitle="Are you sure want to delete this client?"
        type="danger"
        visible={isModalDelete}
        onCancel={handleCancel}
      >
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="secondary"
            ghost
            danger
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="w-full"
            danger
            // onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </Modal>
      <Modal
        title="Updated Success!"
        subtitle="Changes have been saved! Now you can continue browsing."
        type="success"
        visible={isModalSuccess}
      >
        <div className="text-center">
          <Button onClick={handleDone} className="w-32" type="primary">
            Done
          </Button>
        </div>
      </Modal>
    </>
  );
}
