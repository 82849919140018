import { Card, Col, Row, Skeleton } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import("moment/locale/id");
moment.locale("id");

export default function SignatureReceipt({ receiptHistory, isFetching }) {
  const { t } = useTranslation();
  return (
    <Card className="rounded-md">
      <Skeleton loading={isFetching} active paragraph={{ rows: 6 }}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <h3 className="text-gray-500 text-lg md:text-xl font-medium">
              {t("Receipt History")}
            </h3>
          </Col>
          <Col span={24}>
            {receiptHistory?.map((item, index) => {
              return (
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Row
                      gutter={[24, 24]}
                      align="middle"
                      justify="space-between"
                    >
                      <Col xs={24} md={24}>
                        <Row
                          gutter={[24, 24]}
                          className="rounded-md bg-gray-100 py-2 px-4"
                          align="middle"
                          justify="space-between"
                        >
                          <Col>
                            <h3 className="text-gray-500 text-base font-medium">
                              Stamp ({index + 1}) :
                              <span className="text-gray-800">
                                {" "}
                                {item?.created_by}
                              </span>
                            </h3>
                          </Col>
                          <Col>
                            <div
                              className="bg-green-500 rounded-full text-white py-1 px-3 text-sm"
                              type="primary"
                              // onClick={() => history.push("/document-management/add")}
                            >
                              {t("Completed")}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[24, 24]}>
                      <Col xs={12} md={8}>
                        <Row gutter={[24, 12]}>
                          <Col span={24}>
                            <h3 className="font-medium text-gray-500">ID</h3>
                          </Col>
                          <Col span={24}>
                            <h4 className="font-medium">{item?.doc_log_id}</h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={8}>
                        <Row gutter={[24, 12]}>
                          <Col span={24}>
                            <h3 className="font-medium text-gray-500">
                              Total Stamp
                            </h3>
                          </Col>
                          <Col span={24}>
                            <h4 className="font-medium">{item?.page}</h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={8}>
                        <Row gutter={[24, 12]}>
                          <Col span={24}>
                            <h3 className="font-medium text-gray-500">{t("date")}</h3>
                          </Col>
                          <Col span={24}>
                            <h4 className="font-medium">
                              {moment(item?.created_at, moment.ISO_8601).format(
                                "DD/MM/YYYY, HH:mm:ss"
                              )}
                            </h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={8}>
                        <Row gutter={[24, 12]}>
                          <Col span={24}>
                            <h3 className="font-medium text-gray-500">{t("Notes")}</h3>
                          </Col>
                          <Col span={24}>
                            <h4 className="font-medium">
                              {item?.note === null ? "-" : item?.note}
                            </h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={8}>
                        <Row gutter={[24, 12]}>
                          <Col span={24}>
                            <h3 className="font-medium text-gray-500">
                              {t("Device")}
                            </h3>
                          </Col>
                          <Col span={24}>
                            <h4 className="font-medium">{item?.device_type}</h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={8}>
                        <Row gutter={[24, 12]}>
                          <Col span={24}>
                            <h3 className="font-medium text-gray-500">
                              {t("IP Address")}
                            </h3>
                          </Col>
                          <Col span={24}>
                            <h4 className="font-medium">{item?.ip}</h4>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </Skeleton>
    </Card>
  );
}
