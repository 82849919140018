import { LeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, message, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Spinner } from "../../components";
import ButtonComponent from "../../components/Button";
import { errorMessage, exportBinaryToZip, exportPdf, exportZip, getBlob, getData } from "../../utils";
import { useTranslation } from "react-i18next";
import { useFetchDetailSignFolderMutation, useFetchDocumentsFolderVersionsQuery } from "../../config/features";

const { Column } = Table;

export default function DocumentVersion() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams()

  const {
    data: folders = [],
    isFetching: isFetchingFolders,
    isError: isErrorFolders,
    error: errorFolders,
    isSuccess: isSuccessFolders,
    refetch: refetchFolders
  } = useFetchDocumentsFolderVersionsQuery({ folder_id: id })
  const [
    getDetailSigned,
    {
      isSuccess: isSuccessDetailSigned,
      isLoading: isLoadingDetailSigned,
      error: errorDetailSigned,
      data: dataDetailSigned = {}
    }
  ] = useFetchDetailSignFolderMutation();

  useEffect(() => {
    refetchFolders()
    
  }, [refetchFolders])

  useEffect(() => {
    if (id) {
      getDetailSigned({folder_id: id});
    }
  }, [id])

  useEffect(() => {
    if (isErrorFolders) {
      errorMessage(errorFolders)
    }
  }, [isErrorFolders, errorFolders])

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  async function handleDownload(id, doc_name) {
    try {
      setIsLoadingDownload(true);
      console.log(id);
      const response = await getBlob(`/document/download?id=${id}`);
      exportPdf(response.data, doc_name);
      setIsLoadingDownload(false);
    } catch (error) {
      message.error(error.message);
      setIsLoadingDownload(false);
    }
  }

  async function handleDownloadBulk(id, filename = "filename") {
    try {
      setIsLoadingDownload(true);
      const target_folder = folders.find((folder) => folder.id == id)
      let download_endpoint = `/document/download-bulk?id=${id}`;
      if (target_folder && target_folder.description == "SIGNED") {
        download_endpoint = `/document/download-signed-bulk?id=${id}`;
      }
      const response = await getBlob(download_endpoint);
      exportBinaryToZip(response?.data, filename);
      setIsLoadingDownload(false);
    } catch (error) {
      message.error(error.message);
      setIsLoadingDownload(false);
    }
  }

  return (
    <Row gutter={[24, 12]} justify="center">
      <Col xs={24} md={18}>
        <div className="rounded-md px-6 py-3 bg-white">
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <div className="cursor-pointer" onClick={() => history.goBack()}>
                <LeftOutlined className="hover:text-gray-400" />
              </div>
            </Col>
            <Col>
              <h1 className="text-lg md:text-xl font-medium">
                {t("Version Document")}
              </h1>
            </Col>
            <Col>
              <LeftOutlined className="hidden" />
            </Col>
          </Row>
        </div>
      </Col>
      {isSuccessFolders && folders?.map((item, index) => {
        return (
          <Col key={index} xs={24} md={18}>
            <Card className="rounded-md">
              <Row gutter={[24, 24]} justify="space-between">
                <Col span={24}>
                  <Row gutter={[24, 24]} justify="space-between">
                    <Col>
                      <h1 className="text-base md:text-lg font-medium">
                        {`${item?.name} ${item?.version}`}
                      </h1>
                    </Col>
                    <Col>
                      <ButtonComponent
                        type="primary"
                        disabled={
                          (item.description == 'SIGNED' && dataDetailSigned?.signer?.find((signer) => signer.sign_type != 3 && (signer.is_signed == false || signer.success_download < item.docs.length))) ||
                          (item.description == 'TERA' && dataDetailSigned?.signer?.find((signer) => signer.sign_type == 3 && (signer.is_signed == false || signer.success_download < item.docs.length))) ||
                          (item.description == 'STAMPED' && item.docs.length < folders[folders.length - 1].docs.length)
                        }
                        onClick={() => handleDownloadBulk(item?.id, `${item?.name} V${item?.version}`)}
                      >
                        {t("Download All")}
                      </ButtonComponent>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Table
                    dataSource={item?.docs}
                    scroll={{ x: 500 }}
                  >
                    <Column title={t("Document Name")} key={'original_name'} dataIndex={'original_name'} />
                    <Column title={t("Time")} key={'date'} dataIndex={'date'}
                      render={(date) => moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    />
                    <Column title={t("Description")} key={'description'} dataIndex={'description'} />
                    <Column render={(record) => (
                      <Button
                        type="link"
                        onClick={() => handleDownload(record?.doc_id, record?.doc_name)}
                        disabled={
                          (item.description == 'SIGNED' && dataDetailSigned?.signer?.find((signer) => signer.sign_type != 3 && (signer.is_signed == false || signer.success_download < item.docs.length))) ||
                          (item.description == 'TERA' && dataDetailSigned?.signer?.find((signer) => signer.sign_type == 3 && (signer.is_signed == false || signer.success_download < item.docs.length))) ||
                          (item.description == 'STAMPED' && item.docs.length < folders[folders.length - 1].docs.length)
                        }
                      >
                        <p className="underline text-blue-500">{t("Download")}</p>
                      </Button>)} />
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
        );
      })}
      <Spinner loading={isLoadingDownload || isFetchingFolders || isLoadingDetailSigned} />
    </Row>
  );
}
