import { FilterOutlined, PlusOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  Radio,
  Row,
  Select,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as FilterOutline } from "../../assets/icons/filter-outline.svg";
import { useFetchListDocumentQuery } from "../../config/features/document";
import { useFetchListOfficeQuery } from "../../config/features/office";
import { errorMessage } from "../../utils";


import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
const { Search } = Input;
const { Option } = Select;

export default function TeraManagement() {
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [paramsChoose, setParamChoose] = useState(0);

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row gutter={[24]} justify="start">
          <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => 
                      history.goBack()
                    }
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
          </Col>
          <Col>
            <h1 className="text-xl md:text-2xl font-medium">
              {t("Tera Management")}
            </h1>
          </Col>        
        </Row>
      </Col>
      <Col span={24}>
        <Card className="rounded-md">
          <Row gutter={[24, 24]}>
            <Col span={24}>
             <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                {t("Tera User Activation")}
             </h3>
            </Col>
            <Col span={24}>
            <div className="flex flex-col space-y-2">                
                <Radio.Group
                    defaultValue={0}
                    buttonStyle="solid"
                      onChange={(e) =>
                        setParamChoose(e.target.value)
                      }
                    value={paramsChoose}
                >
                <Radio value={0}>{t("All User")}</Radio>
                <Radio value={1}>{t("Per User")}</Radio>
                </Radio.Group>
            </div>
            </Col>   
            <Col span={24}>
            <Button
                className="md:w-full"
                type="primary"
                // icon={<PlusOutlined />}
              >
                {t("Submit")}
              </Button>    
            </Col>         
          </Row>
        </Card>
      </Col>
    </Row>
  );
}