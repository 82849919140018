import React from "react";
import { Route, Redirect } from "react-router-dom";
// import { Dashboard } from "../../../components";

export default function ProtectedRoute({
  component: Component,
  ...routeProps
}) {
  const access = Boolean(localStorage.getItem("access"));
  return (
    <Route
      {...routeProps}
      render={(props) =>
        access ? (
          // <Dashboard>
          <Component {...props} />
        ) : (
          // </Dashboard>
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}
