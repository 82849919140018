import { EditOutlined, EllipsisOutlined, FilterOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Radio,
  Row,
  Select,
  Table,
  Tabs,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as AccountSolid } from "../../assets/icons/account-solid.svg";
import { ReactComponent as FilterOutline } from "../../assets/icons/filter-outline.svg";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { useFetchListOfficeQuery } from "../../config/features/office";
import { useTranslation } from "react-i18next";
import {
  useDeleteUserMutation,
  useFetchListUserQuery,
  useFetchUserActivityLogQuery,
} from "../../config/features/user";
import { errorMessage } from "../../utils";
const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

export default function Office() {
  const history = useHistory();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [
    userId,
    // setUserId
  ] = useState(null);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [visibleFilterLog, setVisibleFilterLog] = useState(false);
  const [paramsTable, setParamsTable] = useState({
    row: 10,
    page: 1,
    sort_by: "created_at",
    order: "desc",
    office: "",
    status: "",
  });
  const [paramsTableLog, setParamsTableLog] = useState({
    row: 10,
    page: 1,
    sort_by: "created_at",
    order: "desc",
    office: "",
    status: "",
  });

  const {
    data: dataListUser = [],
    isFetching,
    refetch,
    error,
    isError,
  } = useFetchListUserQuery(paramsTable);
  const {
    data: dataListUserLog = [],
    isFetching: isFetchingLog,
    isError: isErrorLog,
    error: errorLog,
    refetch: refetchLog,
  } = useFetchUserActivityLogQuery(paramsTableLog);
  const [deleteUser, { isSuccess, isLoading }] = useDeleteUserMutation();
  const { data: dataListOffice = [] } = useFetchListOfficeQuery({ row: 100 });

  useEffect(() => {
    refetch();
    if (isSuccess) {
      setIsModalSuccess(true);
      setTimeout(() => {
        setIsModalSuccess(false);
      }, 3000);
    }
  }, [refetch, isSuccess]);

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    } else if (isErrorLog) {
      errorMessage(errorLog);
    }
  }, [isError, error, isErrorLog, errorLog]);

  useEffect(() => {
    refetch();
    refetchLog();
  }, [refetch, refetchLog, paramsTable.page]);

  function handleResetFilter() {
    setParamsTable({
      ...paramsTable,
      sort_by: "created_at",
      order: "desc",
      office: "",
      status: "",
    });
  }

  function handleResetFilterLog() {
    setParamsTableLog({
      ...paramsTableLog,
      sort_by: "created_at",
      order: "desc",
      office: "",
      status: "",
    });
  }

  const menuFilter = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={handleResetFilter}
            >
              {t('resetall')}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Work Unit")}</p>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, office: e })}
              value={paramsTable.office}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">{t("All Work Unit")}</Option>
              {dataListOffice.map((item, index) => {
                return (
                  <Option key={index} value={item.office_id}>
                    {item.office_name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Status</p>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, status: e })}
              value={paramsTable.status}
            >
              <Option value="">{t("All Status")}</Option>
              <Option value="1">{t("Active")}</Option>
              <Option value="2">{t("Inactive")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="w-full h-0.5 bg-gray-100 rounded-full" />
        </Menu.Item>
        <Menu.Item>
          <p className="text-gray-500 text-base font-medium">{t("Sort")}</p>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort By")}</p>
            <Select
              defaultValue="full_name"
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, sort_by: e })}
              value={paramsTable.sort_by}
            >
              <Option value="full_name">{t("Full Name")}</Option>
              <Option value="email">{t("Email")}</Option>
              <Option value="created_at">{t("Created Date")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="desc"
              buttonStyle="solid"
              onChange={(e) =>
                setParamsTable({ ...paramsTable, order: e.target.value })
              }
              value={paramsTable.order}
            >
              <Radio value="asc">{t("Ascending")}</Radio>
              <Radio value="desc">{t("Descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const menuFilterLog = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={handleResetFilterLog}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Work Unit")}</p>
            <Select
              defaultValue=""
              style={{ width: 250 }}
              onChange={(e) =>
                setParamsTableLog({ ...paramsTableLog, office: e })
              }
              value={paramsTableLog.office}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">{t("All Work Unit")}</Option>
              {dataListOffice.map((item, index) => {
                return (
                  <Option key={index} value={item.office_id}>
                    {item.office_name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Status")}</p>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(e) =>
                setParamsTableLog({ ...paramsTableLog, status: e })
              }
              value={paramsTableLog.status}
            >
              <Option value="">{t("All Status")}</Option>
              <Option value="1">{t("Active")}</Option>
              <Option value="2">{t("Inactive")}</Option>
              {/* <Option value="3">{t("New User")}</Option> */}
            </Select>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const menuAction = (record) => {
    return (
      <Menu>
        <Menu.Item>
          <div
            className="flex flex-row space-x-2 items-center"
            onClick={() =>
              history.push({
                pathname: `/user/edit/${record.user_id}`,
                state: record,
              })
            }
          >
            <EditOutlined />
            <p>{t("Edit")}</p>
          </div>
        </Menu.Item>
        {/* <Menu.Item>
          <div
            className="flex flex-row space-x-2 items-center text-red-500"
            onClick={() => {
              setUserId(record.user_id);
              setIsModalDelete(true);
            }}
          >
            <DeleteOutlined />
            <p>Delete</p>
          </div>
        </Menu.Item> */}
      </Menu>
    );
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("User Name"),
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: t("Email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("Role"),
      dataIndex: "role_name",
      key: "role_name",
    },
    {
      title: t("Work Unit"),
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (e) => {
        return (
          <div
            className={`px-2 py-1 text-gray-100 rounded-full text-center text-xs font-medium ${e === 1 ? "bg-blue-400" : "bg-gray-400"
              }`}
          >
            {e === 1 ? t("ACTIVE") : t("INACTIVE")}
          </div>
        );
      },
    },
    {
      render: (record) => {
        return (
          <Dropdown overlay={menuAction(record)} trigger={["click"]}>
            <div className="group cursor-pointer">
              <EllipsisOutlined className="group-hover:text-blue-500" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const columnsLog = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Activity"),
      dataIndex: "activity",
      key: "activity",
    },
    {
      title: t("Actor"),
      dataIndex: "creator",
      key: "creator",
    },
    {
      title: t("Source"),
      dataIndex: "source",
      key: "source",
      render: (_, record) => {
        return (
          <>
            <p className="text-gray-500">
              {t("Device Type")} :
              <span className="text-gray-800"> {record?.device_type}</span>
            </p>
            <p className="text-gray-500">
              {t("Operating System")} :{" "}
              <span className="text-gray-800"> {record?.os}</span>
            </p>
            <p className="text-gray-500">
              {t("Browser")} :{" "}
              <span className="text-gray-800"> {record?.browser}</span>
            </p>
            <p className="text-gray-500">
              IP : <span className="text-gray-800"> {record?.ip}</span>
            </p>
          </>
        );
      },
    },
    {
      title: t("date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (e) => {
        return moment(e, moment.ISO_8601).format("DD/MM/YYYY, hh:mm:ss a");
      },
    },
  ];

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  function datasetsListTableLog(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTableLog.row * (paramsTableLog.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  const onSearch = (value) => setParamsTable({ ...paramsTable, search: value });
  const onSearchLog = (value) =>
    setParamsTableLog({ ...paramsTableLog, search: value });

  function handleCancel() {
    setIsModalDelete(false);
  }

  async function handleDelete() {
    try {
      setIsModalDelete(false);
      deleteUser(userId);
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleDone() {
    setIsModalSuccess(false);
  }

  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }

  function handleVisibleFilterLog(flag) {
    setVisibleFilterLog(flag);
  }

  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  function handleChangeTableLog(pagination) {
    setParamsTableLog({
      ...paramsTableLog,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <h1 className="text-xl md:text-2xl font-medium">{t("User")}</h1>
            </Col>
            <Col>
              <Button
                className="bg-blue-700 md:w-40 rounded-md"
                type="primary"
                onClick={() => history.push("/user/add")}
                icon={<AccountSolid />}
              >
                {t("btnadduser")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <div>
            <h3 className="text-gray-500 text-sm md:text-base">Total {t("User")}</h3>
            <h2 className="text-blue-700 text-4xl md:text-5xl font-bold">
              {dataListUser[0] ? dataListUser[0]?.total : 0}
            </h2>
          </div>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Tabs defaultActiveKey="1">
              <TabPane tab={t("tablistuser")} key="1">
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Row gutter={[24, 24]} justify="end">
                      <Col>
                        <Row gutter={[12, 12]}>
                          <Col>
                            <Search
                              placeholder={t("Search")}
                              allowClear
                              onSearch={onSearch}
                              onChange={(e) =>
                                setTimeout(() => {
                                  onSearch(e.target.value);
                                }, 1000)
                              }
                              style={{
                                width: 200,
                                borderRadius: "10px !important",
                              }}
                            />
                          </Col>
                          <Col>
                            <Dropdown
                              overlay={menuFilter}
                              onVisibleChange={handleVisibleFilter}
                              visible={visibleFilter}
                              trigger={["click"]}
                            >
                              <Button
                                type="primary"
                                ghost
                                icon={<FilterOutlined />}
                              />
                            </Dropdown>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={columns}
                      dataSource={datasetsListTable(dataListUser)}
                      scroll={{ x: 900 }}
                      loading={isFetching || isLoading}
                      pagination={{
                        current: paramsTable.page,
                        pageSize: paramsTable.row,
                        total: dataListUser[0] ? dataListUser[0]?.total : 0,
                        locale: {
                          items_per_page: t("/ page"),
                        }
                      }}
                      onChange={handleChangeTable}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={t("activitylog")} key="2">
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Row gutter={[24, 24]} justify="end">
                      <Col>
                        <Row gutter={[12, 12]}>
                          <Col>
                            <Search
                              placeholder={t("Search")}
                              allowClear
                              onSearch={onSearchLog}
                              onChange={(e) =>
                                setTimeout(() => {
                                  onSearchLog(e.target.value);
                                }, 1000)
                              }
                              style={{
                                width: 200,
                                borderRadius: "10px !important",
                              }}
                            />
                          </Col>
                          <Col>
                            <Dropdown
                              overlay={menuFilterLog}
                              onVisibleChange={handleVisibleFilterLog}
                              visible={visibleFilterLog}
                              trigger={["click"]}
                            >
                              <Button
                                type="primary"
                                shape="round"
                                icon={<FilterOutline />}
                              />
                            </Dropdown>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={columnsLog}
                      dataSource={datasetsListTableLog(dataListUserLog)}
                      scroll={{ x: 800 }}
                      loading={isFetchingLog}
                      pagination={{
                        current: paramsTableLog.page,
                        pageSize: paramsTableLog.row,
                        total: dataListUserLog[0]
                          ? dataListUserLog[0]?.total
                          : 0,
                        locale: {
                          items_per_page: t("/ page"),
                        }
                      }}
                      onChange={handleChangeTableLog}
                    />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
      <Modal
        title={t("Delete User?")}
        subtitle={t("Are you sure want to delete this user?")}
        type="danger"
        visible={isModalDelete}
        onCancel={handleCancel}
      >
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="secondary"
            onClick={handleCancel}
            ghost
            danger
          >
            {t("Cancel")}
          </Button>
          <Button
            className="w-full"
            type="primary"
            onClick={handleDelete}
            danger
          >
            {t("Delete")}
          </Button>
        </div>
      </Modal>
      <Modal
        type="success"
        title={t("Updated Success!")}
        subtitle={t("Changes have been saved! Now you can continue browsing.")}
        visible={isModalSuccess}
        closable={false}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={handleDone}>
            {t("Done")}
          </Button>
        </div>
      </Modal>
    </>
  );
}
