import * as FileSaver from "file-saver";

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export function exportZip(base64, fileName) {
  var blob = b64toBlob(base64, "application/zip");
  FileSaver.saveAs(blob, fileName);
}

export function exportBinaryToZip(binary, fileName) {
  const url = window.URL.createObjectURL(new Blob([binary]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.zip`);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
