import React from "react";
import { Spin } from "antd";

export default function Spinner({ loading }) {
  return (
    <>
      {loading ? (
        <div
          className="fixed top-0 left-0 w-full h-screen flex items-center justify-center"
          style={{ backgroundColor: "rgba(163, 163, 163, 0.3)" }}
        >
          <Spin size="large" />
        </div>
      ) : null}
    </>
  );
}
