import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { rootApi, access } from "../config";

export const otpApi = createApi({
  reducerPath: "api/otp",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi,
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      sendOtp: builder.mutation({
        query: (body) => ({
          method: "PUT",
          url: `/send-otp${body.resend ? "?resend=1" : ""}`,
          body,
        }),
      }),
    };
  },
});

export const { useSendOtpMutation } = otpApi;
