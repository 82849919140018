import { LeftOutlined } from "@ant-design/icons";
import {
  Col,
  Row,
  Menu,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../../components";
import { ProtectedRoute } from "../../config";
import { useFetchDetailClientQuery } from "../../config/features/platform";

// === TABS PAGE
import ModuleList from './ModuleList';
import ClientInfo from "./ClientInfo";
import CooperationAgreement from "./CooperationAgreement";

export default function ClientDetail(props) {
  const history = useHistory();
  const { id } = useParams();

  const pathname = props?.location?.pathname;
  const initialTabs = pathname.match(/\/module/) ? "module" : pathname.match(/\/coopAgreement/) ? "coopAgreement" : ""
  const [tabs, setTabs] = useState(initialTabs);

  const {
    data: dataDetailClient = {},
    refetch: refetchDetailClient,
    isFetching,
  } = useFetchDetailClientQuery(id);

  function handleClickTabs(e) {
    history.push(`${props?.match?.url}${e.key ? `/${e.key}` : ""}`)
    setTabs(e.key)
  }

  useEffect(() => {
    refetchDetailClient()
  }, [refetchDetailClient])
  
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    Client Detail
                  </h1>
                </Col>
              </Row>
            </Col>
            {dataDetailClient?.account?.is_verified && (
              <Col>
                <Row gutter={[24, 24]}>
                  <Col>
                    <Button
                      className="md:w-40"
                      type="secondary"
                      onClick={() =>
                        history.push({
                          pathname: `/client-management/edit-module/${id}`,
                          state: dataDetailClient?.module,
                        })
                      }
                    >
                      Update Module
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className="md:w-40"
                      type="primary"
                      onClick={() =>
                        history.push({
                          pathname: `/client-management/edit-client/${id}`,
                          state: dataDetailClient?.account,
                        })
                      }
                    >
                      Edit
                    </Button>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24}>
          <Menu 
            className="bg-transparent"
            onClick={handleClickTabs} 
            selectedKeys={[tabs]} 
            mode="horizontal"
          >
            <Menu.Item key="">
              Client Information
            </Menu.Item>
            <Menu.Item key="module">
              Modules
            </Menu.Item>
            <Menu.Item key="coopAgreement">
              Cooperation Agreement
            </Menu.Item>
          </Menu>
        </Col>

        <ProtectedRoute
          path="/client-management/detail/:id"
          component={
            () => 
            <ClientInfo 
              id={id} 
              dataDetailClient={dataDetailClient} 
              refetchDetailClient={refetchDetailClient} 
              isFetching={isFetching} 
            />
          }
          exact={true}
        />

        <ProtectedRoute
          path="/client-management/detail/:id/module"
          component={
            () => 
            <ModuleList 
              id={id} 
              dataDetailClient={dataDetailClient} 
              refetchDetailClient={refetchDetailClient} 
              isFetching={isFetching} 
            />
          }
          exact={true}
        />

        <ProtectedRoute
          path="/client-management/detail/:id/coopAgreement"
          component={
            () => 
            <CooperationAgreement 
              id={id}
              dataDetailClient={dataDetailClient} 
              refetchDetailClient={refetchDetailClient} 
              isFetching={isFetching} 
            />
          }
          exact={true}
        />
      </Row>
    </>
  );
}
