import { DownOutlined, EyeFilled, LeftOutlined, UpOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Row,
  Table,
  Select,
  Form,
  Input,
  Modal as AntModal,
} from "antd";
import clsx from "clsx";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Document, Page } from "react-pdf";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Button, Modal, Spinner } from "../../components";
import { useFetchDetailContractQuery, useStopContractMutation, useUpdateDocumentContractMutation } from "../../config/features";
import { CONTRACT_DOC_STATUS, CONTRACT_STATUS, MODULE_LIST } from "../../constant";
import { errorMessage, getBlob, getProductsTotalPrice, numberCommas } from "../../utils";

const { Option } = Select;
const { TextArea } = Input;

export default function CoopAgreementDetail() {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  
  const [isModalUpdateDoc, setIsModalUpdateDoc] = useState(false);
  const [isModalStopContract, setIsModalStopContract] = useState(false);

  const [docId, setDocId] = useState("");
  const [docStatusForm] = Form.useForm();
  
  const { data: dataDetailContract = {}, refetch: refetchDetailContract } = useFetchDetailContractQuery(id);

  const isActive = dataDetailContract?.status === 2

  const totalProductPrice = useMemo(() => {
    return getProductsTotalPrice(dataDetailContract?.products)
  }, [dataDetailContract?.products])

  const moduleListData = useMemo(() => {
    const module = dataDetailContract?.modules
    if(module){
      const keys = Object.keys(module)
      return keys
        .map((key) => module[key] && MODULE_LIST[key] ? ({ module_name: MODULE_LIST[key] }) : null)
        .filter(data => data)
    }
    
    return []
  }, [dataDetailContract?.modules])

  const [
    updateDocumentContract, 
    { 
      isSuccess: isSuccessUpdateDocumentContract, 
      isError: isErrorUpdateDocumentContract, 
      error: errorLoadingUpdateDocumentContract, 
      isLoading: isLoadingUpdateDocumentContract 
    }
  ] = useUpdateDocumentContractMutation();

  const [
    stopContract, 
    { 
      isSuccess: isSuccessStopContract, 
      isError: isErrorStopContract, 
      error: errorLoadingStopContract, 
      isLoading: isLoadingStopContract 
    }
  ] = useStopContractMutation();

  const [isModalSuccess, setIsModalSuccess] = useState(false);

  // Contract Document File Preview
  const [contractDocName, setContractDocName] = useState(null);
  const [isFetchingContractDocFile, setIsFetchingContractDocFile] = useState(false);
  const [contractDocFile, setContractDocFile] = useState(null);
  const [contractDocFileNumPages, setContractDocFileNumPages] = useState(null);
  const [contractDocFilePageNumber, setContractDocFilePageNumber] = useState(1);
  const [isModalContractDocOpen, setIsModalContractDocOpen] = useState(false)

  const toggleModalSuccess = () => {
    setIsModalSuccess(!isModalSuccess);
  }

  const toggleModalContractDoc = () => {
    setIsModalContractDocOpen(prevState => !prevState)
  }

  const toggleModalStopContract = useCallback(() =>  {
    setIsModalStopContract(prevState => !prevState);
  }, [setIsModalStopContract])

  const toggleModalUpdateDoc = useCallback((doc_id) => {
    setIsModalUpdateDoc(prevState => {
      if(prevState) { setDocId("") }
      else { setDocId(doc_id) }
      return !prevState
    });
  }, [setIsModalUpdateDoc])

  const onContractDocLoadSuccess = ({ numPages }) => {
    setContractDocFileNumPages(numPages);
  }

  const handleChangeContractDocPage = (val) => {
    setContractDocFilePageNumber(val);
  }

  const handleStopContract = () => {
    stopContract({ office_id: location?.state?.client })
  }

  const handleSubmitUpdateDoc = (values) => {
    updateDocumentContract({
      contract_doc_id: docId,
      body: values
    })
  }

  useEffect(() => {
    if (isSuccessUpdateDocumentContract) {
      setIsModalSuccess(true);
      toggleModalUpdateDoc()
      docStatusForm.resetFields();
      refetchDetailContract();
    }
    if (isErrorUpdateDocumentContract) {
      errorMessage(errorLoadingUpdateDocumentContract);
    }
  }, [
    isSuccessUpdateDocumentContract, 
    errorLoadingUpdateDocumentContract, 
    isErrorUpdateDocumentContract, 
    docStatusForm,
    refetchDetailContract,
    toggleModalUpdateDoc,
  ]);

  useEffect(() => {
    if (isSuccessStopContract) {
      setIsModalSuccess(true);
      toggleModalStopContract()
      refetchDetailContract();
    }
    if (isErrorStopContract) {
      errorMessage(errorLoadingStopContract);
    }
  }, [
    isSuccessStopContract, 
    errorLoadingStopContract, 
    isErrorStopContract, 
    refetchDetailContract,
    toggleModalStopContract
  ]);

  return(
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    Cooperation Agreement Detail
                  </h1>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row className="flex justify-between">
              <Col>
                <h5 className="text-gray-500 mb-3">
                  Status
                </h5>
                <h4 className={clsx("text-3xl", CONTRACT_STATUS[dataDetailContract?.status]?.clsTextColor)}>
                  {CONTRACT_STATUS[dataDetailContract?.status]?.value}
                </h4>
              </Col>
              <Col>
                <h5 className="text-gray-500 mb-3">
                  Date Issued
                </h5>
                <h4 className="text-3xl">
                  {dataDetailContract?.issued_at
                  ? moment(dataDetailContract?.issued_at, moment.ISO_8601)
                    .format("DD/MM/YY")
                  : "--"}
                </h4>
              </Col>
              <Col>
                <h5 className="text-gray-500 mb-3">
                  Expired Date
                </h5>
                <h4 className="text-3xl">
                  {dataDetailContract?.expired_at
                  ? moment(dataDetailContract?.expired_at, moment.ISO_8601)
                    .format("DD/MM/YY")
                  : "--"}
                </h4>
              </Col>
              <Col>
                <h5 className="text-gray-500 mb-3">
                  Last Update
                </h5>
                <h4 className="text-3xl">
                  {dataDetailContract?.updated_at
                  ? moment(dataDetailContract?.updated_at, moment.ISO_8601)
                    .format("DD/MM/YY")
                  : "--"}
                </h4>
              </Col>
              <Col className="flex flex-col items-center justify-center">
                <Button 
                  type="danger" 
                  className="w-48 mb-2"
                  onClick={toggleModalStopContract}
                  disabled={!isActive}
                > 
                  Stop Agreement
                </Button>
                <Button 
                  type="primary" 
                  className="w-48" 
                  onClick={() => history.push({
                    pathname: `/cooperation-agreement/extend/${id}`,
                    state: location?.state
                  })}
                  disabled={!isActive}
                  ghost
                > 
                  Extend
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={24}>
          <Card className="rounded-md">
            <h2 className="text-gray-500 text-lg md:text-xl font-medium mb-2">
              Product List
            </h2>
            <Table 
              pagination={{ position: ["none"]}}
              columns={[
                {
                  title: 'Product Name',
                  dataIndex: 'product_name',
                  key: 'product_name',
                  render: text => text,
                },
                {
                  title: 'Unit Price',
                  dataIndex: 'unit_price',
                  key: 'unit_price',
                  render: text => `Rp ${numberCommas(text)},-`,
                },
                {
                  title: 'Quantity',
                  dataIndex: 'quantity',
                  key: 'quantity',
                  render: text => numberCommas(text),
                },
                {
                  title: 'Quota',
                  dataIndex: 'quota',
                  key: 'quota',
                  render: text => numberCommas(text),
                },
                {
                  title: 'Total',
                  dataIndex: 'total',
                  key: 'total',
                  align: 'right',
                  render: text => `Rp ${numberCommas(text)},-`,
                },
              ]} 
              dataSource={dataDetailContract?.products || []} 
            />
            <div className="flex flex-row justify-between p-4 text-blue-500">
              <p className="font-bold">Total</p>
              <p className="font-bold">
                Rp {numberCommas(totalProductPrice)},-
              </p>
            </div>
          </Card>
        </Col>
        <Col xs={24} md={24}>
          <Card className="rounded-md">
            <h2 className="text-gray-500 text-lg md:text-xl font-medium mb-2">
              Module List
            </h2>
            <Table 
              pagination={{ position: ["none"] }}
              columns={[
                {
                  title: 'Module Name',
                  dataIndex: 'module_name',
                  key: 'module_name',
                  render: text => text,
                },
              ]} 
              dataSource={moduleListData} 
            />
          </Card>
        </Col>
        {/* 
        // ===============================================
        // Document Contract is disabled for now.
        // It is hidden, until further requirement confirmations.
        // ===============================================
        <Col xs={24} md={24}>
          <Card className="rounded-md">
            <h2 className="text-gray-500 text-lg md:text-xl font-medium mb-2">
              Document List
            </h2>
            <Table 
              pagination={{ position: ["none"]}}
              columns={[
                {
                  title: 'Document Name',
                  dataIndex: 'doc_name',
                  key: 'doc_name',
                  render: text => text,
                },
                {
                  title: 'Last Update',
                  dataIndex: 'updated_at',
                  key: 'updated_at',
                  render: updated_at => updated_at
                  ? moment(updated_at, moment.ISO_8601)
                    .format("DD/MM/YYYY")
                  : "--",
                },
                {
                  title: 'Approval',
                  dataIndex: 'status',
                  key: 'status',
                  render: status => <p className={CONTRACT_DOC_STATUS[status]?.clsTextColor}>
                    {CONTRACT_DOC_STATUS[status]?.value}
                  </p>,
                },
                {
                  title: '',
                  dataIndex: 'view',
                  key: 'view',
                  render: (_, row) => {
                    return (
                      <Button 
                        icon={<EyeFilled />}
                        onClick={async () => {
                          setIsFetchingContractDocFile(true)
                          const response = await getBlob(`/document/preview/${row?.id}?contract=1`);
                          setContractDocName(row?.doc_name)
                          setContractDocFile(response?.data)
                          setIsFetchingContractDocFile(false)
                          toggleModalContractDoc()
                        }}
                        disabled={!row?.is_exist}
                      >
                        Detail
                      </Button> 
                    )
                  },
                  width: 125,
                  align: 'center',
                },
                {
                  title: '',
                  dataIndex: 'status',
                  key: 'status',
                  render: (status, row) => status === 1 
                    ? <Button type="primary" ghost onClick={() => toggleModalUpdateDoc(row.id)}>
                      Update
                    </Button> 
                    : null,
                  width: 20,
                  align: 'center',
                },
              ]} 
              dataSource={dataDetailContract?.contract_docs || []} 
              expandable={{
                expandedRowClassName: () => "bg-blue-500",
                expandedRowRender: record => {
                  return(
                    <div className="rounded-md border-l-4 border-red-500 bg-red-100 px-4 py-2">
                      <p className="text-red-500">Reason of rejection</p>
                      <p>{record.note}</p>
                    </div>
                  )
                },
                rowExpandable: record => record.status === 3,
                expandIcon: ({ expanded, onExpand, record, expandable }) =>
                  expandable ? 
                    expanded ? (
                      <UpOutlined onClick={e => onExpand(record, e)} />
                    ) : (
                      <DownOutlined onClick={e => onExpand(record, e)} />
                    ) 
                  : null,
                expandRowByClick: true
              }}
              rowKey="id"
            />
          </Card>
        </Col> */}
      </Row>
      <Modal
        title="Are you sure?"
        subtitle="Are you sure want to stop this cooperation?"
        type="warning"
        visible={isModalStopContract}
        onCancel={toggleModalStopContract}
        width={400}
      >
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={toggleModalStopContract}
          >
            Back
          </Button>
          <Button className="w-full" type="primary" onClick={handleStopContract}>
            Confirm
          </Button>
        </div>
      </Modal>
      <Modal
        title="Are you sure?"
        subtitle="You can change the status cooperation agreement below"
        type="warning"
        visible={isModalUpdateDoc}
        onCancel={toggleModalUpdateDoc}
      >
        <Form
          onFinish={handleSubmitUpdateDoc}
          form={docStatusForm}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item 
            name="status" 
            label="Status" 
            rules={[{
              required: true,
              message: "Please select status!",
            }]}>
            <Select className="w-full">
              <Option value="approve">Approve</Option>
              <Option value="reject">Reject</Option>
            </Select>
          </Form.Item>  
          <Form.Item 
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.status !== currentValues.status}
          >
            {({ getFieldValue }) => 
              getFieldValue('status') === 'reject' ?
              <Form.Item 
                name="note" 
                label="Note"  
                rules={[{
                  required: true,
                  message: "Please input note!",
                }]}> 
                <TextArea rows={4} />  
              </Form.Item> :
              null
            }
          </Form.Item>      
          <Form.Item>
            <div className="flex flex-row space-x-4 pt-4">
              <Button
                className="w-full"
                type="primary"
                ghost
                onClick={toggleModalUpdateDoc}
              >
                Back
              </Button>
              <Button className="w-full" type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        type="success"
        title="Updated Success!"
        subtitle="Changes have been saved! Now you can continue browsing."
        visible={isModalSuccess}
        closable={false}
      >
        <div className="text-center">
          <Button 
            className="w-32" 
            type="primary" 
            onClick={() => {
              toggleModalSuccess()
            }}
          >
            Done
          </Button>
        </div>
      </Modal>
      <AntModal 
        title={contractDocName} 
        visible={isModalContractDocOpen} 
        onOk={toggleModalContractDoc} 
        onCancel={toggleModalContractDoc}
        footer={[]}
        width={800}
        centered
      >
        <div className="relative flex justify-center items-center p-8 bg-gray-300">
          <Document file={contractDocFile} onLoadSuccess={onContractDocLoadSuccess}>
            <Page pageNumber={contractDocFilePageNumber}>
            </Page>
          </Document>
          <div className="flex flex-row justify-center items-center space-x-2 bg-gray-100 shadow-md absolute bottom-5 right-5 p-2 rounded-md">
            <Select
              value={contractDocFilePageNumber}
              size="small"
              onChange={handleChangeContractDocPage}
            >
              {Array.from(new Array(contractDocFileNumPages), (_, index) => (
                <Option value={index + 1}>{index + 1}</Option>
              ))}
            </Select>
            <p>{`dari ${contractDocFileNumPages} halaman`}</p>
          </div>
        </div>
      </AntModal>
      <Spinner loading={isFetchingContractDocFile || isLoadingUpdateDocumentContract} />
    </>
  )
}