import { CloseOutlined, LeftOutlined, ArrowsAltOutlined } from "@ant-design/icons";
import { Button as ButtonAntd, Col, Collapse, Divider, Input, message, Row, Select, Tooltip, Form, Modal as ModalAntd, Progress } from "antd";
import pdfJsWorker from "pdfjs-dist/build/pdf.worker.entry";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import Draggable from "react-draggable";
import OtpInput from "react-otp-input";
import { Document, Page, pdfjs } from "react-pdf";
import Fade from "react-reveal/Fade";
import { useHistory, useLocation, useParams } from "react-router-dom";
import QrCodeSVG, {
  ReactComponent as QrCodeIcon,
} from "../../assets/icons/qrcode.svg";
import EditIconYellow from '../../assets/img/icon/edit-doc-yellow.png'
import EditIconBlack from '../../assets/img/icon/edit-doc-black.png'
import SignSvg, {
  ReactComponent as SignIcon,
} from "../../assets/icons/sign.svg";

import CapSvg, {
  ReactComponent as CapIcon,
} from "../../assets/icons/cap.svg";

import DoneIcon from '../../assets/icons/done.svg'
import ResetIcon from '../../assets/icons/reset.svg'
import CloseIcon from '../../assets/img/icon/closeIcon.png'
import SettingIcon from '../../assets/img/icon/setting.png'
import google1 from '../../assets/img/google-1.png'
import google2 from '../../assets/img/google-2.png'
import safari1 from '../../assets/img/safari1.png'
import safari2 from '../../assets/img/safari2.png'
import safari3 from '../../assets/img/safari3.png'
import firefox1 from '../../assets/img/firefox1.png'
import firefox2 from '../../assets/img/firefox2.png'
import arrowRight from '../../assets/img/icon/right-arrow.png'
import arrowBtm from '../../assets/img/icon/arrow-btm.png'
import arrowUp from '../../assets/img/icon/arrow-up.png'

import { Button, Modal, Spinner } from "../../components";
import { useFetchQuotaQuery, useSendDocSingleSignerBulkMutation, useSendOtpMutation } from "../../config/features";
import { useFetchDetailDocumentQuery, useFetchDetBulkSignQuery, useFetchDocumentsByFolderQuery } from "../../config/features/document";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { useAddBulkStampMutation , useFetchListJenisDocumentQuery } from "../../config/features/peruri";

import { errorMessage, getData, getPosition, numberCommas } from "../../utils";
import _ from 'lodash';
import { PDFDocument, degrees } from "pdf-lib";

import axios from "axios";
import { rootApi } from "../../config/features/config";
import { useTranslation } from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker;


// Dynamic import for stamp image based on env variable
// This is because prod and others has different stmap image
let stampImg;
import(`../../assets/img/env/${process.env.REACT_APP_ROOT_ENV}/e-meterai.png`)
.then((module) => {
  stampImg = module.default;
});

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;

let TeraImg;
  import(`../../assets/img/tera-spec.png`)
  .then((module) => {
    TeraImg = module.default;
  });


export default function SelfSignBulk() {
  const { t } = useTranslation();

  const tokenPeruri = useMemo( () => localStorage.getItem('peruri') , [])
  const tokenAccess = useMemo( () => localStorage.getItem('access') , [])  
  const history = useHistory();
  // const location = useLocation();
  const {state : {dataDoc}} = useLocation()
  const { id: doc_id } = useParams();
  const [totalQuota, setTotalQuota] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [originalPdfFile, setOriginalPdfFile] = useState([]);
  const [pdfFile, setPdfFile] = useState([]);
  const [idDocument, setIdDocument] = useState(null);

  const [done , setDone] = useState(false)
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  let [activeDrags, setActiveDrags] = useState({});

  const [activeDeltaPosition, setActiveDeltaPosition] = useState({ x: 351, y: -520});
  const [deltaPosition, setDeltaPosition] = useState([]);
  
  const [totalStampPosition, setTotalStampPosition] = useState([]);

  const [location , setLocation] = useState(null)
  const [reason , setReason] = useState(null)


  const [size, setSize] = useState({ x: 100, y: 100 });
  const [sizing, setSizing] = useState({
    signature: { width: 150, height: 90 },
    paraf: { width: 150, height: 90 },
    tera: {width: 100, height: 100}
  });
  let [totalStamp, setTotalStamp] = useState([]);
  let [totalSignature, setTotalSignature] = useState([]);  
  const [totalReset , setTotalReset] = useState(0)
  const [totalMaterai , setTotalMaterai] = useState({total : 0 , remaining : 0})
  const [successModal, setsuccessModal] = useState(false)
  // const [moveable] = useState(true);
  const [moveable, setMoveable] = useState(true);
  const [reloadDocument, setReloadDocument] = useState(0);

  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalOtp, setIsModalOtp] = useState(false);
  const [isModalDocType, setIsModalDocType] = useState(false);
  const [isLocationBlocked , setIsLocationBlocked ] = useState(false)
  const [openSign , setOpenSign] = useState(false)
  const [listStampPosition , setListStampPosition] = useState([])
  const [loading , setLoading] = useState(false)
  const [isParaf , setIsParaf] = useState(false)
  const [isTera, setIsTera] = useState(false)
  const [imgSign , setImgSign] = useState(null) 
  const [imgParaf , setImgParaf] = useState(null) 
  const [currentWidthRatio, setCurrentWidthRatio] = useState(3)

  const [guideModal , setGuideModal] = useState({ guide1 : false , guide2 : false , guide3 : false ,  })

  //bypass OTP Modal
  const [isModalConfirm2, setIsModalConfirm2] = useState(false);  
  const toggleModalConfirm2 = () => {
    setIsModalConfirm2(!isModalConfirm2);
  }

  const [docType , setDocType] = useState(location?.state?.docType)
  const [otpValue, setOtpValue] = useState("");
  const [isFirstOtpSent, setIsFirstOtpSent] = useState(false);
  const [countdownValue, setCountdownValue] = useState(Date.now());
  const countdownRef = useRef();

  const [isLoadingPreview, setIsLoadingPreview] = useState(false)
  const [userLocation , setUserLocation] = useState(null)
  const [activeLocation , setActiveLocation ] = useState(false)
  const [OTPSender , setOTPSender ] = useState(false)

  
  const [modalProgress, setModalProgress] = useState(false)
  const [isModalSystem, setIsModalSystem] = useState(false)

  const [totalSend, setTotalSend] = useState(0);
  const [isSend, setIsSend] = useState(false)
  
  // const [
  //   sendOtp,
  //   { data: dataOtp, isError: isErrorOtp, error: errorOtp, isLoading: isLoadingOtp },
  // ] = useSendOtpMutation();

  const [
    sendDocSingleSignerBulk,
    {
      data: responseSendDoc,
      isError: isErrorSendDoc,
      error: errorSendDoc,
      isSuccess: isSuccessSendDoc,
      isLoading: isLoadingSendDoc
    }
  ] = useSendDocSingleSignerBulkMutation();

  const {
    data: dataDetail = {},
    isFetching,
    isError: isErrorDetailDoc,
    error: errorDetailDoc,
    refetch: refetchDetailDocument,
    isSuccess: isSuccessDetail,
  } = useFetchDetBulkSignQuery({ id: doc_id, is_folder: 1, is_sign: 1 });

  const {
    data: documents = [],
    isFetching: isFetchingDocuments,
    isError: isErrorDocuments,
    error: errorDocuments,
    refetch: refetchDocuments,
    isSuccess: isSuccessDocuments
  } = useFetchDocumentsByFolderQuery({folder_id: doc_id})

  useEffect(() => {
    refetchDocuments()
  }, [refetchDocuments])

  const { data: userProfile = {} } = useFetchProfileUserQuery();

  const { data: dataQuota, refetch: refetchQuota } = useFetchQuotaQuery();

  const getMeteraiQuota = useCallback(() => {
    for (let i = 0; i < dataQuota?.length; i++) {
      if (dataQuota[i]?.id === "1") {
        return dataQuota[i]?.remaining;
      }
    }
  }, [dataQuota])

  useEffect(() => {
    setTotalQuota(getMeteraiQuota())
    
  }, [getMeteraiQuota])

  const normalizeCoordinates = (x, y, rotation, pageWidth, pageHeight) => {
    let newX, newY;
    switch (rotation % 360) {
      case 90:
        // 90 degrees: Origin at the top-left corner
        newX = pageWidth - y;
        newY = x;
        break;
      case 180:
        // 180 degrees: Origin at the top-right corner
        newX = pageWidth - x;
        newY = pageHeight - y;
        break;
      case 270:
        // 270 degrees: Origin at the bottom-right corner
        newX = y;
        newY = pageHeight - x;
        break;
      case 0:
      default:
        // 0 degrees: Default coordinate system
        newX = x;
        newY = y;
        break;
    }
    return { x: newX, y: newY }
  }

  useEffect(() => {
    function base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    }

    async function getQuoteMeterai(){

      const {data : {data}} = await axios({
        method : 'GET' ,
        url : `${rootApi}/quota` ,
        headers : {
          token : tokenAccess
        }
      })

      setTotalMaterai({ total : data[1].remaining , remaining : data[1].remaining})
    }

    // fetchData();
    getQuoteMeterai()
  }, [totalReset]);

  async function getSignatureAndParaf(type){

    if (dataDoc.typeSign == 0) {
      try{

        setLoading(true)
  
        const {data : {data : dataProfile}} = await axios({
          method : 'GET' ,
          url : `${rootApi}/user/profile` ,
          headers : {
            token : tokenAccess
          }
        })
  
        const {data: dataSign} = await axios({
          method : 'GET' ,
          url : `${rootApi}/sign/preview-speciment/${dataProfile.user_id}` ,
          headers : {
            token : tokenAccess ,
           "token-peruri-sign" : tokenPeruri
          }
        })
  
        // Get images size
        let sign = new Image()
        sign.src = dataSign.data.base64Speciment
        sign.onload = function() {
          const ratio = Math.min(100 / sign.width, 100 / sign.height);
          setSizing(prev => ({
            ...prev, 
            signature: { width: sign.width * ratio, height: sign.height * ratio }
          }))
          if (type == 'sign') {
            setSize({x: sign.width * ratio, y: sign.height * ratio})
          }
        }
        let paraf = new Image()
        paraf.src = dataSign.data.base64Paraf
        paraf.onload = function() {
          const ration = Math.min(100 / paraf.width, 100 / paraf.height)
          setSizing(prev => ({
            ...prev, 
            paraf: { width: paraf.width * ration, height: paraf.height * ration }
          }))
          if (type == 'paraf') {
            setSize({ x: paraf.width * ration, y: paraf.height * ration })
          }
        }
  
        const teraImageBytes = new Image();
        teraImageBytes.src = TeraImg
        teraImageBytes.width = sizing.tera.width
        teraImageBytes.height = sizing.tera.height
        setSizing(prev => ({
          ...prev, 
          tera: { width: teraImageBytes.width, height: teraImageBytes.height }
        }))
        if (type == 'tera') {
          setSize({x: teraImageBytes.width, y: teraImageBytes.height})
        }
        if(dataSign.data.base64Speciment === null && dataSign.data.base64Paraf === null )
        {
          message.info(t("Please upload your signature and paraf before sign!"))
        }
        else
        {
  
          if(dataSign.data.base64Speciment !== null && dataSign.data.base64Paraf !== null)
          {
            setImgSign(dataSign.data.base64Speciment)
            setImgParaf(dataSign.data.base64Paraf)
          }
  
          if(dataSign.data.base64Speciment === null)
          {
            message.info(t("Please upload your signature before sign!"))
          }
  
          if(dataSign.data.base64Paraf === null)
          {
            message.info(t("Please upload your paraf before sign!"))
          }
        }
  
        setLoading(false)
  
      }
      catch(e){
  
        if(e.response){
          const {data : {error}} = e.response
  
          setLoading(false)
  
          message.error(error)
  
          if(error === "Error when preview speciment")
          {
            history.push('/sign-management/create-signature')
          }
        } else {
          message.error(e)
        }
  
       
  
      }
    }
  }
  useEffect(() => {
    if (dataDoc.typeSign == 0) {
      getSignatureAndParaf()
    }
  },[tokenAccess, tokenPeruri])

  useEffect(() => {
    // get current user position 
    async function getStatePosition(){
      const data = await getPosition()
      if(data !== undefined || data !== '' )
      {
        setLocation(data)
      }
      else
      {
        setLocation('')
      }
    }
    getStatePosition()
  },[])

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
  
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  async function handleAddSignature(index , paraf = false, tera = false) {
    // setDone(true)
    if ((isSend && isSuccessSendDoc) || isLoadingSendDoc) {
      return;
    }
    const isInsufficient = Number(totalDocs) > totalMaterai
    if(isInsufficient){
      message.warning(t('You dont have enough quota'))
      return
    } else {
      setCurrentWidthRatio(3)
      if(tera){
        await getSignatureAndParaf("tera");
        setIsParaf(false)
        setIsTera(true)
        setTotalMaterai( prev => ({ ...prev , total : prev.total - 1 }))
        // setDeltaPosition({  x: 0, y: -500 });
        setTotalSignature([...totalSignature, index]);
      } else {
        if(!paraf) {
          await getSignatureAndParaf("sign");
          setIsParaf(false)
          setTotalMaterai( prev => ({ ...prev , total : prev.total - 1 }))
          // setDeltaPosition({  x: 0, y: -500 });
          setTotalSignature([...totalSignature, index]);
        } else {
          await getSignatureAndParaf("paraf");
          setIsParaf(true)
          setTotalMaterai( prev => ({ ...prev , total : prev.total - 1 }))
          // setDeltaPosition({  x: 0, y: -500 });
          setTotalSignature([...totalSignature, index]);
        }
      }
      

    setActiveDeltaPosition({ x: 351, y: -520 });
    // setTotalStamp([...totalStamp, index]);
    }
  }

  function handleCloseSignature(index) {
    setDone(false)
    //delete total signature by value
    setTotalMaterai( prev => ({ ...prev , total : prev.total + 1 }))
    const duplicateTotalSignature = [...totalSignature];
    for (var i = 0; i < duplicateTotalSignature.length; i++) {
      if (duplicateTotalSignature[i] === index) {
        duplicateTotalSignature.splice(i, 1);
      }
      setTotalSignature(duplicateTotalSignature);
    }

    // delete delta position of signature
    const duplicateDeltaPosition = { ...deltaPosition };
    delete duplicateDeltaPosition[index];
    setDeltaPosition(duplicateDeltaPosition);
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const getEmbededDocUint8Array = async (existingPdfBytes, currentDeltaPosition) => {
    // if doesn't have currentDeltaPosition then use deltaPosition in state
    // current delta position is used when there is new stamping ( refer to modifyPdf() )
    // delta position is state is used if no new stamping
    const dPos = currentDeltaPosition ? currentDeltaPosition : deltaPosition

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // const res = await fetch(stampImg)
    // const pngImageBytes = await res.arrayBuffer()

    let pngImageBytes;
    let pngWidth;
    let pngHeight;

    if(isTera){
      pngImageBytes = await fetch(TeraImg).then( res => res.arrayBuffer())
      pngWidth = sizing.tera.width;
      pngHeight = sizing.tera.height;
    } else {
      if(isParaf){
        pngImageBytes = await fetch(imgParaf).then( res => res.arrayBuffer())
        pngWidth = sizing.paraf.width;
        pngHeight = sizing.paraf.height;
      } else {
        pngImageBytes = await fetch(imgSign).then( res => res.arrayBuffer())
        pngWidth = sizing.signature.width;
        pngHeight = sizing.signature.height;
      }
    }
    

    const pngImage = await pdfDoc.embedPng(pngImageBytes);

    const pages = pdfDoc.getPages();
    const pageDocument = pages[pageNumber - 1];

    const rotation = pageDocument.getRotation().angle;
    const { width, height } = pageDocument.getSize();
    
    for (let i = 0; i < dPos.length; i++) {
      const deltaPositionY =  dPos[i].y < 0
        ? Math.abs(dPos[i].y)
        : -Math.abs(dPos[i].y);
      const deltaPositionX = dPos[i].x;

      const { x: x_pos, y: y_pos } = normalizeCoordinates(deltaPositionX, deltaPositionY - pngHeight, rotation, width, height)

      const {width: boxWidth, height: boxHeight} = getBoxSize(pngWidth, pngHeight)
      const shift_x = Math.abs((boxWidth - pngWidth) / 2)
      const shift_y = -Math.abs((boxHeight - pngHeight) / 2)

      pageDocument.drawImage(pngImage , {
        x : x_pos + shift_x,
        y : y_pos + shift_y,
        width : pngWidth,
        height : pngHeight,
        rotate: degrees(rotation)
      });

      setListStampPosition( prev => [
        ...prev, 
        {
          type: isParaf ? "PARAF" : "SPESIMEN",
          llx: `${Math.abs(deltaPositionX + shift_x)}`,
          lly: `${Math.abs(deltaPositionY + shift_y) - pngHeight}`,
          urx: `${Math.abs(dPos[i].x + shift_x) + pngWidth}`,
          ury: `${Math.abs(dPos[i].y + shift_y)}`,
          page: `${pageNumber}` ,
          location: (location === null || location === '') ? '' : `${location?.city},${location?.country}`,
          reason: "" ,
          isv : "YES"
        }
      ]);
    }

    const pdfBytes = await pdfDoc.save();
    const arr = new Uint8Array(pdfBytes);    

    return arr
  }


  //modify existing pdf file by buffer/bytes and id
  const modifyPdf = async (existingPdfBytes, id, currentDeltaPosition) => {
    const arr = await getEmbededDocUint8Array(existingPdfBytes, currentDeltaPosition)
    const prevPdf = { ...pdfFile };
    prevPdf[id].buffer = arr.buffer;
    setPdfFile(prevPdf);
  }

  async function modifyPdfSign(existingPdfBytes) {
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    let pngImageBytes;
    let pngWidth;
    let pngHeight;
    if(isTera){
      pngImageBytes = await fetch(TeraImg).then( res => res.arrayBuffer())
      pngWidth = sizing.tera.width;
      pngHeight = sizing.tera.height;
    } else {
      if(isParaf){
        pngImageBytes = await fetch(imgParaf).then( res => res.arrayBuffer())
        pngWidth = sizing.paraf.width;
        pngHeight = sizing.paraf.height;
      } else {
        pngImageBytes = await fetch(imgSign).then( res => res.arrayBuffer())
        pngWidth = sizing.signature.width;
        pngHeight = sizing.signature.height;
      }
    }
    

    const pngImage = await pdfDoc.embedPng(pngImageBytes)

    const pages = pdfDoc.getPages();
    const pageDocument = pages[pageNumber - 1];

    const deltaPositionY = deltaPosition.y < 0
          ? Math.abs(deltaPosition.y)
          : -Math.abs(deltaPosition.y);
      const deltaPositionX = deltaPosition.x;

    pageDocument.drawImage(pngImage , {
      x : deltaPositionX ,
      y : deltaPositionY - pngHeight,
      width : pngWidth,
      height : pngHeight
    })

    const pdfBytes = await pdfDoc.save();
    const arr = new Uint8Array(pdfBytes);
    setPdfFile(arr.buffer);
    
    setListStampPosition( prev => [
      ...prev, 
      {
        type: isParaf ? "PARAF" : "SPESIMEN",
        llx: `${Math.abs(deltaPosition.x)}`,
        lly: `${Math.abs(deltaPosition.y) - pngHeight}`,
        urx: `${Math.abs(deltaPosition.x) + pngWidth}`,
        ury: `${Math.abs(deltaPosition.y)}`,
        page: `${pageNumber}` ,
        location: (location === null || location === '') ? '' : `${location?.city},${location?.country}`,
        reason: "" ,
        isv : "YES"
      }
    ])
  }

  const handleAddStamp = (index) => {
    const isInsufficient = Number(totalDocs) > totalQuota
    if(isInsufficient){
      message.error(t("You dont have enough quota"));
      return
    }

    setActiveDeltaPosition({ x: 351, y: -520 });
    setTotalStamp([...totalStamp, index]);
  }

  const handleCloseStamp = () => {
    setActiveDeltaPosition({})
    setTotalStamp([])
  }

  const handleStart = (e) => {
    setActiveDrags({ ...activeDrags, [e]: ++activeDrags });
  };

  const handleStop = (e, position, index) => {
    e.preventDefault()
    setActiveDrags({ ...activeDrags, [index]: --activeDrags });

    const { x, y } = position;
    setActiveDeltaPosition({ x, y })
  };

  const handleSave = async (e) => {
    e.preventDefault()
    setTotalQuota(prevState => Number(prevState) - Number(totalDocs))
    // console.log(activeDeltaPosition);
    for (let i = 0; i < Object.keys(pdfFile).length; i++) {
      const id = Object.keys(pdfFile)[i];
      modifyPdf(
        pdfFile[id]?.buffer, 
        id, 
        [activeDeltaPosition],
      );
    }
    setTotalSignature([]);    
    setTotalStamp([]);

    // Add new stamping data to send to backend
    // llx : lower left x coordinate
    // lly : lower left y coordinate
    // urx : upper right x coordinate
    // ury : upper right y coordinate

    let pngImageBytes;
    let pngWidth;
    let pngHeight;
    if(isTera){
      pngImageBytes = await fetch(TeraImg).then( res => res.arrayBuffer())
      pngWidth = sizing.tera.width;
      pngHeight = sizing.tera.height;
    } else {
      if(isParaf){
        pngImageBytes = await fetch(imgParaf).then( res => res.arrayBuffer())
        pngWidth = sizing.paraf.width;
        pngHeight = sizing.paraf.height;
      } else {
        pngImageBytes = await fetch(imgSign).then( res => res.arrayBuffer())
        pngWidth = sizing.signature.width;
        pngHeight = sizing.signature.height;
      }
    }

    const first_id = Object.keys(pdfFile)[0];
    const pdfDoc = await PDFDocument.load(pdfFile[first_id]?.buffer);
    const pages = pdfDoc.getPages();
    const pageDocument = pages[pageNumber - 1];

    const rotation = pageDocument.getRotation().angle;
    const { width, height } = pageDocument.getSize();

    const deltaPositionY = activeDeltaPosition.y < 0 ? Math.abs(activeDeltaPosition.y) : -Math.abs(activeDeltaPosition.y);
    const deltaPositionX = activeDeltaPosition.x;
    const { x, y } = normalizeCoordinates(deltaPositionX, deltaPositionY - pngHeight, rotation, width, height)

    const {width: boxWidth, height: boxHeight} = getBoxSize(pngWidth, pngHeight)
    const shift_x = Math.abs((boxWidth - pngWidth) / 2)
    const shift_y = -Math.abs((boxHeight - pngHeight) / 2)

    // Add new deltaPosition for pdf preview
    setDeltaPosition(prev => [...prev, {x: x + shift_x, y: y + shift_y}])

    setTotalStampPosition((prevPosition) => [...prevPosition, {
      llx: Math.abs(deltaPositionX + shift_x),
      lly: Math.abs(deltaPositionY + shift_y) - pngHeight,
      urx: Math.abs(activeDeltaPosition.x + shift_x) + pngWidth,
      ury: Math.abs(activeDeltaPosition.y + shift_y),
      page: pageNumber
    }]);
    setDone(false);    
    //console.log("handleSave End - totalDocs ==> ", totalDocs);
  };

  const handleSaveSign = () => {
    modifyPdfSign(pdfFile);
    setDone(false)
    setTotalSignature([]);
    setTotalStamp(totalStamp + 1)
  };

  const handleReset = () => {
    if ((isSend && isSuccessSendDoc) || isLoadingSendDoc) {
      return;
    }
    setPdfFile(_.cloneDeep(originalPdfFile))
    setCurrentWidthRatio(3)
    setTotalStamp([])
    setTotalStampPosition([]);
    setDeltaPosition([])
    setTotalMaterai( prev => ({ ...prev , total : prev.remaining }))
    setTotalReset(totalReset + 1)    
    setListStampPosition([])
    setTotalQuota(getMeteraiQuota())
  }

  const handleResetSign = () => {
    setTotalMaterai( prev => ({ ...prev , total : prev.remaining }))
    setTotalReset(totalReset + 1)
    setTotalStamp(0)
    setListStampPosition([])
  }

  const handleChangePage = (e) => {
    setPageNumber(e);
  }

  const handleSendDocument = async () => {
    if(!isFirstOtpSent) {
      setIsFirstOtpSent(true)
      
      
      let bodySend 

      if(isTera){
        bodySend = {
          doc_id : `${doc_id}`,
          sign_coordinates: listStampPosition.map( item => ({...item , reason })) ,
          assign_type :  '1',
          sign_type : '3'

        }
      } else {       
        if(userProfile?.is_system_sign){
          bodySend = {
            doc_id : `${doc_id}`,
            sign_coordinates: listStampPosition.map( item => ({...item , reason })) ,
            assign_type :  '1' ,  
            sign_type : '4'        
          }
        } else {
          bodySend = {
            doc_id : `${doc_id}`,
            sign_coordinates: listStampPosition.map( item => ({...item , reason })) ,
            assign_type :  '1' ,          
          }
        }
        
      }

      await axios({
        method : 'POST' ,
        url : `${rootApi}/sign/single/send-doc-bulk/${doc_id}`,
        data : bodySend ,
        timeout: 7 * 60 * 1000,
        headers : {
          "token" : tokenAccess ,
         "token-peruri-sign" : tokenPeruri
        }
      }).then((e)=>{
        if(e.data.status === "success"){
          
        } else {
          setIsLoadingPreview(false);
          message.error({ content: "Send Document Failed"});
        }
      });
    }
  }

  const handleSendDocSingleSignBulk = () => {
    try {
      toggleModalConfirm();
      if (!isFirstOtpSent) {
        setIsFirstOtpSent(true)
  
        let bodySend 
  
        if(isTera){
          bodySend = {
            doc_id : `${doc_id}`,
            sign_coordinates: listStampPosition.map( item => ({...item , reason })) ,
            assign_type :  '1',
            sign_type : '3'
  
          }
        } else {       
          if(userProfile?.is_system_sign){
            bodySend = {
              doc_id : `${doc_id}`,
              sign_coordinates: listStampPosition.map( item => ({...item , reason })) ,
              assign_type :  '1' ,  
              sign_type : '4'
            }
          } else {
            bodySend = {
              doc_id : `${doc_id}`,
              sign_coordinates: listStampPosition.map( item => ({...item , reason })) ,
              assign_type :  '1' ,          
            }
          } 
        }
        sendDocSingleSignerBulk(bodySend);
        setIsSend(true);
      }
    } catch (err) {
      message.error(err.message);
    }
  }

  const clearOtp = () => {
    setOtpValue("");
  }

  const handleSubmit = async (values) => {
    const body = { otp_code : `${otpValue}` }
    setIsLoadingPreview(true);
    toggleModalOtp();
    try {
      const response = await axios({
        method : 'POST' ,
        url : `${rootApi}/sign/sign-doc-bulk/${doc_id}`,
        data : body ,
        headers : {
          "token" : tokenAccess , 
         "token-peruri-sign" : tokenPeruri
        }
      }).then((e)=>{
        console.log("A => ", e);
        setIsLoadingPreview(false);        
        setsuccessModal(true)        
      }).catch((ee) => {
        console.log("B => ", ee);
        setIsLoadingPreview(false);
        setIsModalOtp(true);
        message.error(ee.message);
      });
             
    } catch (error) {
      console.log("C => ", error);
      setIsLoadingPreview(false);
      toggleModalOtp();
      message.error(error.message);
    }
  }

  const handleSubmitSystem = async (values) => {
    const body = { signer_note : `${values.note}` }
    setIsLoadingPreview(true);
    setIsModalSystem(false);
    try {
      const response = await axios({
        method : 'POST' ,
        url : `${rootApi}/sign/sign-doc-system-bulk/${doc_id}`,
        data : body ,
        headers : {
          "token" : tokenAccess , 
         "token-peruri-sign" : tokenPeruri
        }
      }).then((e)=>{
        console.log("A => ", e);
        setIsLoadingPreview(false);        
        setsuccessModal(true)        
      }).catch((ee) => {
        console.log("B => ", ee);
        setIsLoadingPreview(false);
        setIsModalSystem(true);
        message.error(ee.message);
      });
             
    } catch (error) {
      console.log("C => ", error);
      setIsLoadingPreview(false);
      toggleModalOtp();
      message.error(error.message);
    }
  }

  const toggleModalConfirm = () => {
    setIsModalConfirm(!isModalConfirm);
  }

  const toggleModalOtp = () => {
    setIsModalOtp(!isModalOtp);
  }

  const handleSendOtp = async (props) => {
    try {
     
        message.loading({ content: t("Sending the OTP"), key: "otpMsg" });
        const responseGetOtp = await axios({
          method : 'GET' ,
          url : `${rootApi}/sign/get-otp-bulk/${doc_id}` ,
          headers : {
            "token" : tokenAccess ,
           "token-peruri-sign" : tokenPeruri
          }
        })

    } catch (error) {
      setIsLoadingPreview(false);
      message.error(error.message);
    }
  }

  function handleModalDocType(){
    setIsModalDocType(false)
  }

  function onFinish(values){
    setIsModalDocType(false)
    setDocType(values.docType)
    
  }

  const getLocation = async () => {

    setActiveLocation(false)

    const response = await getPosition()
    
    if(typeof response === 'object')
    {
      setUserLocation(response)
      toggleModalConfirm()
      return
    }

    setUserLocation('')
    setIsLocationBlocked(true)

  }

  function handleMovable() {
    setMoveable(!moveable);
  }

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <ButtonAntd
          onClick={() => {
            setCountdownValue(Date.now() + 120000)
            setTimeout(() => {
              countdownRef.current.api.start()
            }, 100)
            handleSendOtp({ resend: false });
          }}
          type="link"
          className="p-0"
        >
          {t("Resend")}
        </ButtonAntd>
      );
    } else {
      return (
        <p>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </p>
      );
    }
  };

  const base64ToArrayBuffer = (base64) => {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  // Initial preview document to be used when reset the stamping
  const getInitialPreviewDocument = useCallback(async (params) => {
    try {
      setIsLoadingPreview(true);
      const res = await getData(`/document/preview/${params?.doc_id}`);
      let documentBuffer = await base64ToArrayBuffer(res.data?.data);

      setOriginalPdfFile((prev) => {
        return {
          ...prev,
          [params?.doc_id]: {
            buffer: documentBuffer,
            name: params?.doc_name,
          },
        };
      });
      setPdfFile((prev) => {
        return {
          ...prev,
          [params?.doc_id]: {
            buffer: documentBuffer,
            name: params?.doc_name,
          },
        };
      });
      setIsLoadingPreview(false);
    } catch (error) {
      setIsLoadingPreview(false);
    }
  }, [])

  const getStampedDocument = async (documentBuffer) => {
    const pdfDoc = await PDFDocument.load(documentBuffer)
    const pages = pdfDoc.getPages();

    const isTera = dataDoc.typeSign != 0;
    let pngImageBytes;
    
    // Tera 
    pngImageBytes = await fetch(TeraImg).then( res => res.arrayBuffer())
    const pngImageTera = await pdfDoc.embedPng(pngImageBytes)

    // PARAF
    pngImageBytes = await fetch(imgParaf).then( res => res.arrayBuffer())
    const pngImageParaf = await pdfDoc.embedPng(pngImageBytes)

    // PARAF
    pngImageBytes = await fetch(imgSign).then( res => res.arrayBuffer())
    const pngImageSign = await pdfDoc.embedPng(pngImageBytes)

    let pngImage = pngImageTera;

    for (const item of listStampPosition) {
      const pageDocument = pages[Number(item.page) - 1]
      const rotation = pageDocument.getRotation().angle;
      const { width: pageWidth, height: pageHeight } =  pageDocument.getSize()
      const { x, y } = normalizeCoordinates(Number(item.llx), Number(item.lly), rotation, pageWidth, pageHeight)

      const width = Math.abs(item.urx - item.llx)
      const height = Math.abs(item.ury - item.lly)

      if (!isTera) {
        if (item.type == "SPESIMEN") {
          pngImage = pngImageSign
        }
        else {
          pngImage = pngImageParaf
        }
      }
      pageDocument.drawImage(pngImage, {
        x,
        y,
        width,
        height,
        rotate: degrees(rotation)
      })
    }

    const pdfBytes = await pdfDoc.save()
    const arr = new Uint8Array(pdfBytes)
    console.log(arr.buffer);
    return arr.buffer
    
  }

  // Get the document to preview when clicked 
  // For performance purpose, we not get and store all document
  // at one time in the beginning
  const getPreviewDocument = async (params) => {
    try {
      setIsLoadingPreview(true);
      const res = await getData(`/document/preview/${params?.doc_id}`);
      const isStamped = totalStampPosition.length > 0;
      let documentBuffer = await base64ToArrayBuffer(res.data?.data);
      let stampedDocumentBuffer;


      if(isStamped){
        stampedDocumentBuffer = await getStampedDocument(documentBuffer)
      } 

      setOriginalPdfFile((prev) => {
        return {
          ...prev,
          [params?.doc_id]: {
            buffer: documentBuffer,
            name: params?.doc_name,
          },
        };
      });
      setPdfFile((prev) => {
        return {
          ...prev,
          [params?.doc_id]: {
            buffer: isStamped ? stampedDocumentBuffer : documentBuffer,
            name: params?.doc_name,
          },
        };
      });
      setIsLoadingPreview(false);
    } catch (error) {
      setIsLoadingPreview(false);
    }
  }

  const checkStatusDoc = async () => {
    try {
      
      
          const body = {
            "folder_id" : doc_id
          }
          const response = await axios({
            method : 'POST' ,
            url : `${rootApi}/sign/check-docsign-bulk`,
            data : body,
            headers : {
              "token" : tokenAccess
            }
          }).then((e)=>{
            // console.log(`A  Cek=> `, e.data.data.length);
            const lengthss = e.data.data.length
            setTotalSend(lengthss)
            
          }).catch((ee) => {
            // console.log("B => ", ee);        
            // message.error(ee.message);
            
          });
             
         
    } catch (error) {
      message.warning('Unable to check status. Try Again')      
    }  
  }

  useEffect(() => {
    refetchQuota();
  }, [refetchQuota]);

  useEffect(() => {
    refetchDetailDocument();
  }, [refetchDetailDocument]);

  useEffect(() => {
    if(isSuccessDocuments){
      const element = documents[0];
      getInitialPreviewDocument(element);
      setIdDocument(documents[0]?.doc_id);
      setTotalDocs(documents?.length)
    }
  }, [
    isSuccessDocuments,
    documents,
    getInitialPreviewDocument,
    reloadDocument,
  ]);

  useEffect(() => {
    if (isErrorDocuments) {
      errorMessage(errorDocuments)
    }
  }, [
    isErrorDetailDoc,
    errorDetailDoc,
    isErrorDocuments,
    errorDocuments
  ]);
  
  useEffect(() => {
    if (isErrorSendDoc) {
      errorMessage(errorSendDoc);
    }
  }, [isErrorSendDoc, errorSendDoc])

  useEffect(() => {
    if (isSuccessSendDoc) {
      setModalProgress(true);
    }
  }, [isSuccessSendDoc])

  useEffect(() => {  
    if(modalProgress && totalSend < totalDocs){
      const interval = setInterval(() => {
        checkStatusDoc()
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [modalProgress])


const handler0 = (mouseDownEvent) => {
  mouseDownEvent.preventDefault()
  var isDown = TextTrackCueList;
  const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY }
  function onMouseMove(mouseMoveEvent) {
    if (isDown) {
      const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY }
      const currentSize = size
      const ratio = currentSize.x / currentSize.y

      let resizeValue = 0
      if (currentPosition.x < startPosition.x && currentPosition.y > startPosition.y) {
        // Zoom In
        resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
      }
      else if (currentPosition.x > startPosition.x && currentPosition.y < startPosition.y) {
        // Zoom Out
        resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
      }
      const currentWidth = currentSize.x + (ratio * resizeValue)
      const currentHeight = currentSize.y + resizeValue
      setSize({
        x: currentWidth,
        y: currentHeight
      })
      setSizing({
        paraf: { width: currentWidth, height: currentHeight },
        signature: { width: currentWidth, height: currentHeight },
        tera: { width: currentWidth, height: currentHeight },
      })
    }
  }
  function onMouseUp() {
    isDown = false
    document.body.removeEventListener('mousemove', onMouseMove)
  }

  document.body.addEventListener('mousemove', onMouseMove)
  document.body.addEventListener('mouseup', onMouseUp, { once: true })
}

const handler1 = (mouseDownEvent) => {
  mouseDownEvent.preventDefault();
  var isDown = true;
  const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
  function onMouseMove(mouseMoveEvent) {
    if (isDown) {
      // console.log("HANDLER 1");
      const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
      const currentSize = size
      const ratio = currentSize.x / currentSize.y

      let resizeValue = 0
      if (currentPosition.x > startPosition.x && currentPosition.y > startPosition.y) {
        // Zoom In
        resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
      }
      else if (currentPosition.x < startPosition.x && currentPosition.y < startPosition.y) {
        // Zoom Out
        resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))          
      }

      const currentWidth = currentSize.x + (ratio * resizeValue);
      const currentHeight = currentSize.y + resizeValue;
      setSize({
        x: currentWidth,
        y: currentHeight
      })
      setSizing({
        paraf: { width: currentWidth, height: currentHeight },
        signature: { width: currentWidth, height: currentHeight },
        tera: { width: currentWidth, height: currentHeight },
      })
    }
  }
  function onMouseUp() {
    isDown = false
    document.body.removeEventListener("mousemove", onMouseMove);
  }
  document.body.addEventListener("mousemove", onMouseMove);
  document.body.addEventListener("mouseup", onMouseUp, { once: true });
}

const handler2 = (mouseDownEvent) => {
  mouseDownEvent.preventDefault();
  var isDown = true;
  const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
  function onMouseMove(mouseMoveEvent) {
    if (isDown) {
      const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
      const currentSize = size
      const ratio = currentSize.x / currentSize.y
      let resizeValue = 0
      if (currentPosition.x < startPosition.x && currentPosition.y < startPosition.y) {
        // Zoom In
        resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
      }
      else if (currentPosition.x > startPosition.x && currentPosition.y > startPosition.y) {
        // Zoom Out
        resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
      }
      const currentWidth = currentSize.x + (ratio * resizeValue);
      const currentHeight = currentSize.y + resizeValue;
      setSize({
        x: currentWidth,
        y: currentHeight
      })
      setSizing({
        paraf: { width: currentWidth, height: currentHeight },
        signature: { width: currentWidth, height: currentHeight },
        tera: { width: currentWidth, height: currentHeight },
      })
    }
  }
  function onMouseUp() {
    isDown = false
    document.body.removeEventListener("mousemove", onmousemove);
  }
  document.body.addEventListener("mousemove", onMouseMove);
  document.body.addEventListener("mouseup", onMouseUp, { once: true });
}

const handler3 = (mouseDownEvent) => {
  mouseDownEvent.preventDefault()
  var isDown = true
  
  const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY }
  function onMouseMove(mouseMoveEvent) {
    if (isDown) {
      const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY }
      const currentSize = size
      const ratio = currentSize.x / currentSize.y

      const x_shift = startPosition.x - currentPosition.x
      setCurrentWidthRatio(prev => prev + (x_shift / startPosition.x) > ratio && prev + (x_shift / startPosition.x) <= 3 ? prev + (x_shift / startPosition.x) : prev) // faster calculation
    }
  }
  function onMouseUp() {
    isDown = false
    document.body.removeEventListener("mousemove", onMouseMove)
  }
  document.body.addEventListener("mousemove", onMouseMove)
  document.body.addEventListener("mouseup", onMouseUp, { once: true })
}

const onImgLoad = ({target:img}) => {
  // this.setState({dimensions:{height:img.offsetHeight,width:img.offsetWidth}});
  //console.log(img.offsetHeight, " - ", img.offsetWidth);
};

const getBoxSize = (width, height) => {
  if (dataDoc.typeSign != 0) {
    return { width, height }
  }
  const TARGET_WIDTH_RATIO = currentWidthRatio
  const current_width_ratio = width / height
  if (current_width_ratio > 1 && current_width_ratio < 3) {
    var height_bounding_box = height
    var width_bounding_box = TARGET_WIDTH_RATIO * height_bounding_box
  }
  else if (current_width_ratio > TARGET_WIDTH_RATIO) {
    var width_bounding_box = TARGET_WIDTH_RATIO * height
    if (width_bounding_box >= width) {
      var height_bounding_box = width / 3
      width_bounding_box = TARGET_WIDTH_RATIO * height
    }
    else {
      var height_bounding_box = width / TARGET_WIDTH_RATIO
      width_bounding_box = width
    }
  }
  
  else if (current_width_ratio <= 1) {
    var width_bounding_box = height * TARGET_WIDTH_RATIO
    var height_bounding_box = height
  }
  else {
    var width_bounding_box = width
    var height_bounding_box = height
  }
  return {
    width: width_bounding_box,
    height: height_bounding_box
  }
}

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <Row gutter={[24, 3]}>
                    <Col span={24}>
                      <Tooltip title={location?.state?.doc_name}>
                        <h1 className="text-xl md:text-2xl font-medium truncate w-40 md:w-60 lg:w-96">
                          {dataDetail?.name}
                        </h1>
                        <div className="flex">
                          <img width={25} height={25} src={EditIconYellow} /> 
                          <span className="font-semibold text-yellow-500 mr-2">
                            {t("Self Sign Bulk Document")} 
                          </span>                                                    
                        </div>
                        
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                className="md:w-40"
                type="primary"
                disabled={
                  totalStampPosition?.length < 1 || totalStamp.length > 0
                }
                onClick={() => {
                  
                  if(totalSend > 0 || isSend){
                    setModalProgress(true);
                  } else {
                    setActiveLocation(true)
                  }                    
                  
                }}
              >
                {t("Submit")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col xs={0} lg={4} xl={6}>
              <div className="bg-white rounded-tl-md">
                <div className="w-full bg-yellow-400 rounded-tl-md">
                  <p className="text-center text-white text-sm md:text-base py-2">
                    {t("Preview Sign")}
                  </p>
                </div>
                <div
                  className="overflow-y-auto custom-scrollbar"
                  style={{ height: "80vh" }}
                >
                  <Collapse defaultActiveKey={["1"]} ghost>
                    <Panel
                      className="bg-gray-100 border-l-4 border-blue-500 font-medium"
                      header={dataDetail?.name}
                      key="1"
                    >
                      <div className="relative">
                        <ul className="flex flex-col space-y-2">
                          {documents?.map((item, index) => {
                            return (
                              <li
                                key={index}
                                onClick={async () => {
                                  const isFilePreviewed = !!pdfFile[item.doc_id]
                                  setIdDocument(item.doc_id)
                                  
                                  if(!isFilePreviewed){
                                    await getPreviewDocument(item);
                                  }
                                }}
                              >
                                <Tooltip title={item.doc_name}>
                                  <p
                                    className={`cursor-pointer rounded-md p-2 hover:bg-gray-300 truncate ${
                                      item.doc_id === idDocument &&
                                      "bg-gray-200 font-medium border-l-4 border-gray-800"
                                    }`}
                                  >
                                    {item.doc_name}
                                  </p>
                                </Tooltip>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </Col>
            <Col xs={24} lg={20} xl={18}>
              <div className="relative">
                <div className="w-full bg-gray-600 rounded-tr-md py-1">
                  <Row gutter={[24, 12]} justify="end" align="middle">
                    <Col xs={24} md={8}>
                      { totalStampPosition.length > 0 &&
                        <p className="text-gray-300 text-sm md:text-base">
                          {t("You Have Added")} [{totalStampPosition.length * Number(totalDocs)}] {t(dataDoc.typeSign == 0 ? "Sign": "Tera")}!
                        </p>
                      }
                    </Col>
                    <Col>
                      <ButtonAntd
                        disabled={totalStampPosition.length < 1}
                        className="rounded-md"
                        type="default"
                        onClick={handleReset}
                      >
                        {t("Reset")}
                      </ButtonAntd>
                    </Col>
                    <Col>
                      <Button
                        disabled={totalStamp.length < 1}
                        type="secondary"
                        onClick={(e) => handleSave(e)}
                      >
                        {t("Preview")}
                      </Button>
                    </Col>
                    <Col xs={24} md={8}>
                      <Row
                        className="pr-4 pb-2 md:pb-0"
                        gutter={[24, 24]}
                        justify="center"
                        align="middle"
                      >
                        {dataDoc.typeSign == 0 && (
                          <Col>
                            <div className="flex flex-row bg-blue-400 text-white rounded-full px-2 py-1 justify-center items-center">
                              <SignIcon />
                              <Divider className="h-4 bg-white" type="vertical" />
                              <p className="font-medium">{numberCommas(totalMaterai.total)}</p>
                            </div>
                          </Col>
                        )}
                        {(dataDoc.typeSign != 0) &&
                        <Col>
                          <div className="flex flex-row bg-blue-400 text-white rounded-full px-2 py-1 justify-center items-center">
                            <CapIcon />
                            <Divider className="h-4 bg-white" type="vertical" />
                            <p className="font-medium">{numberCommas(totalMaterai.total)}</p>
                          </div>
                        </Col>
                        }
                      </Row>
                    </Col>
                  </Row>
                </div>
                {totalStamp.length < 1 && (
                  <div className={` space-y-4 justify-end items-end fixed mt-8 right-10 md:right-20 lg:right-32 z-50 flex flex-col }`}>
                    { openSign === true ?
                      <div className={`relative rounded-full top-0 ${!done ? 'cursor-pointer' : null}`} onClick={() => !done ? setOpenSign(false) : null}>
                        <img src={CloseIcon} alt="Close" />
                      </div> : null
                    }
                    <div
                      className="flex flex-col text-white justify-center items-center duration-700   transition"
                      onClick={() => !done ? setOpenSign(true) : null}
                    >
                      <Fade>
                          <div className={`${openSign > 0 ? "hidden" : "flex" } flex-row bg-blue-400 hover:bg-blue-300 rounded-full p-2 cursor-pointer justify-center items-center`}>
                            <img
                              className="transition duration-500 ease-in-out"
                              src={(dataDoc.typeSign != 0) ? CapSvg : SignSvg}
                              alt="qr-icon"
                              width="35px"
                            />
                          </div>
                      </Fade>
                      <Fade>
                      <div onClick={() => !done ? handleAddSignature(totalSignature.length, false, false) : null} style={{background : '#f4f4f4' , minWidth : '100%'}} className={`${(openSign > 0 && (dataDoc.typeSign != 1)) ? "flex" : "hidden" } flex-row items-center p-2 rounded-t-lg ${!done ? 'cursor-pointer' : ''}`}>
                            <div className="rounded-full bg-blue-400 mr-4 w-8 h-8 flex items-center justify-center">
                              <img
                                className="transition duration-500 ease-in-out" 
                                src={SignSvg}
                                alt="qr-icon"
                                width="24px"
                              />
                            </div>
                            <span className={`${!done ? 'text-blue-400' : 'text-gray-400' }  text-xl font-medium `}>{t("Signature")}</span>
                          </div>
                          
                      </Fade>
                      <Divider className="my-0" />
                    </div>
                    <Fade>
                      <div 
                        onClick={() => !done ? handleAddSignature(totalSignature.length , true, false) : null }
                        style={{minWidth : '100%' , background : '#f4f4f4' , marginTop : 0 , marginBottom : 0}} 
                        className={`${ (openSign > 0 && (dataDoc.typeSign != 1)) ? "flex" : "hidden"} flex-row items-center p-2 bg-green-500 ${!done ? 'cursor-pointer' : ''} my-0`}>
                        <div className="rounded-full bg-blue-400 mr-4 w-8 h-8 flex items-center justify-center">
                          <img
                            className="transition duration-500 ease-in-out" 
                            src={SignSvg}
                            alt="qr-icon"
                            width="24px"
                          />
                        </div>
                        <span className={`${!done ? 'text-blue-400' : 'text-gray-400' }  text-xl font-medium `}>{t("Paraf")}</span>
                      </div>
                    </Fade>
                    <Divider style={{marginTop : 0 , marginBottom : 0}} className="my-0" />
                    <Fade>
                      <div 
                        onClick={() => !done ? handleAddSignature(totalSignature.length , false, true) : null }
                        style={{minWidth : '100%' , background : '#f4f4f4' , marginTop : 0 , marginBottom : 0}} 
                        className={`${ (openSign > 0 && (dataDoc.typeSign != 0)) ? "flex" : "hidden"} flex-row items-center p-2 bg-green-500 ${!done ? 'cursor-pointer' : ''} my-0`}>
                        <div className="rounded-full bg-blue-400 mr-4 w-8 h-8 flex items-center justify-center">
                          <img
                            className="transition duration-500 ease-in-out" 
                            src={CapSvg}
                            alt="qr-icon"
                            width="24px"
                          />
                        </div>
                        <span className={`${!done ? 'text-blue-400' : 'text-gray-400' }  text-xl font-medium `}>{t("Tera")}</span>
                      </div>
                    </Fade>
                    <Divider style={{marginTop : 0 , marginBottom : 0}} className="my-0" />
                    <Fade>
                      <div
                        onClick={handleSave} 
                        style={{minWidth : '100%' , background : '#f4f4f4' , marginTop : 0 , marginBottom : 0}} 
                        className={`${ totalSignature.length > 0 ? "flex" : "hidden"} flex-row items-center p-2 bg-green-500 cursor-pointer my-0`}>
                        <div className="rounded-full bg-green-400 mr-4 w-8 h-8 flex items-center justify-center">
                          <img
                            className="transition duration-500 ease-in-out" 
                            src={DoneIcon}
                            alt="qr-icon"
                            width="20px"
                          />
                        </div>
                        <span className="text-green-400 text-xl font-medium">{t("Done")}</span>
                      </div>
                    </Fade>
                    <Divider style={{marginTop : 0 , marginBottom : 0}} className="my-0" />
                    <Fade>
                      <div
                        onClick={handleReset}
                        style={{minWidth : '100%' , background : '#f4f4f4' , marginTop : 0 , marginBottom : 0}} 
                        className={`${ openSign > 0 ? "flex" : "hidden"} flex-row items-center p-2 bg-green-500 cursor-pointer `}>
                        <div className="rounded-full bg-red-500 mr-4 w-8 h-8 flex items-center justify-center">
                          <img
                            className="transition duration-500 ease-in-out" 
                            src={ResetIcon}
                            alt="qr-icon"
                            width="20px"
                          />
                        </div>
                        <span className="text-red-500 text-xl font-medium">{t("Reset")}</span>
                      </div>
                    </Fade>
                    <Divider style={{marginTop : 0 , marginBottom : 0}} className="my-0" />
                    {dataDoc.typeSign == 0 && (
                      <Fade>
                        <div
                          onClick={() => history.push('/sign-management/edit-signature')}
                          style={{minWidth : '100%' , background : '#f4f4f4' , marginTop : 0 , marginBottom : 0}} 
                          className={`${ openSign > 0 ? "flex" : "hidden"} flex-row items-center p-2 rounded-b-lg bg-green-500 cursor-pointer `}>
                          <div className="rounded-full bg-gray-300 mr-4 w-8 h-8 flex items-center justify-center">
                            <img
                              className="transition duration-500 ease-in-out" 
                              src={SettingIcon}
                              alt="qr-icon"
                              width="20px"
                            />
                          </div>
                          <span className="text-gray-500 text-sm font-medium">{t("Edit Signature")}</span>
                        </div>
                      </Fade>
                    )}
                  </div>
            )}
            <div
                  className="bg-gray-300 rounded-b-md overflow-auto custom-scrollbar shadow-md pb-10"
                  style={{ height: "80vh" }}
                >
                  <div className="flex justify-center items-center p-8">
                    <Document file={pdfFile[idDocument]?.buffer} onLoadSuccess={onDocumentLoadSuccess}>
                      <Page pageNumber={pageNumber}>
                        {totalSignature.map((_, index) => {
                          return (
                            <Draggable
                              key={`stamp-${index}`}
                              defaultPosition={activeDeltaPosition}
                              onStart={ moveable ? () => handleStart(index) : false }
                              onStop={(e, position) => handleStop(e, position)}
                              bounds="parent"
                                                  
                            >
                                <div
                                  className={`relative border-dashed border-2 border-gray-500 px- ${
                                    moveable ? "cursor-move" : "cursor-pointer"
                                  }`}
                                  style={getBoxSize(size.x, size.y)}
                                  onDoubleClick={handleMovable}
                                >
                                  <div
                                    className="absolute -top-3 -right-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                    onClick={() => handleCloseSignature(index)}
                                  >
                                    <CloseOutlined className="font-medium" />
                                  </div>
                                  <div style={{WebkitUserDrag: "none", height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                                    <img 
                                      className="drag-none"
                                      style={{height : size.y , width : size.x}} 
                                      onLoad={onImgLoad}
                                      src={ isTera ? TeraImg : isParaf ? imgParaf: imgSign}
                                      alt="" 
                                    />                                    
                                  </div>
                                  <button 
                                    className="absolute -top-3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                    type="button" onMouseDown={handler2}>
                                      <ArrowsAltOutlined style = {{transform: 'rotate(90deg)' }} className="font-medium" />
                                  </button>
                                  {dataDoc.typeSign == 0 && (
                                    <button 
                                      className="absolute top-1/3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                      type="button" onMouseDown={handler3}>
                                        <ArrowsAltOutlined style = {{transform: 'rotate(45deg)' }} className="font-medium" />
                                    </button>
                                  )}
                                  <button 
                                    className="absolute -bottom-3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                    type="button" onMouseDown={handler0}>
                                      <ArrowsAltOutlined className="font-medium" />
                                  </button>
                                  <button 
                                    className="absolute -bottom-3 -right-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                    type="button" onMouseDown={handler1}>
                                      <ArrowsAltOutlined style = {{transform: 'rotate(90deg)' }} className="font-medium" />
                                  </button>
                                  
                                </div>
                            </Draggable>
                          );
                        })}
                      </Page>
                    </Document>
                    <div className="flex flex-row justify-center items-center space-x-2 bg-gray-100 shadow-md absolute bottom-5 right-5 p-2 rounded-md">
                      <Select
                        value={pageNumber}
                        size="small"
                        onChange={handleChangePage}
                      >
                        {Array.from(new Array(numPages), (_, index) => (
                          <Option value={index + 1}>{index + 1}</Option>
                        ))}
                      </Select>
                      <p>{`dari ${numPages} halaman`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        title={t("Are you sure?")}
        subtitle={t("After the data has been submit, you cannot change the data. Please make sure the data is correct.")}
        type="warning"
        visible={isModalConfirm}
        onCancel={toggleModalConfirm}
        page={1}        
        totalPage={2}
      >
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Document Name")}</p>
            <div className="w-2" />
            <ul className="flex flex-col space-y-1 max-h-40 overflow-y-auto custom-scrollbar">
              {documents?.map((item, index) => {
                return (
                  <li key={index}>
                    <Tooltip title={item.doc_name}>
                      <p className="text-gray-800 truncate">{item.doc_name}</p>
                    </Tooltip>
                  </li>
                );
              })}
            </ul>
          </div>
          {dataDoc.typeSign == 0 ? (
            <div className="flex flex-row justify-between">
              <p className="text-gray-500">{t("Signature Category")}</p>
              <div className="w-12" />
              <Tooltip title="Single Stamp">
                <p className="text-gray-800 truncate">{t("Signature Bulk On Document")}</p>
              </Tooltip>
            </div>
          ) : (
            <div className="flex flex-row justify-between">
              <p className="text-gray-500">{t("Stamp Category")}</p>
              <div className="w-12" />
              <Tooltip title={t("Stamp Tera Bulk")}>
                <p className="text-gray-800 truncate">{t("Stamp Tera Bulk")}</p>
              </Tooltip>
            </div>
          )}
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t(dataDoc.typeSign == 0 ? "Total Signature": "Total Tera")}</p>
            <div className="w-12" />
            <Tooltip title="Single Stamp">
              <p className="text-gray-800 truncate">
                {documents?.length * totalStampPosition?.length} {t(dataDoc.typeSign == 0 ? "Signature" : "Tera")}
              </p>
            </Tooltip>
          </div>
          <Divider />
        </div>
        <div className="flex flex-row space-x-4">
          <Button
            onClick={toggleModalConfirm}
            className="w-full"
            ghost
            type="secondary"
          >
            {t("Back")}
          </Button>
          <Button
            // onClick={() => {
              
            //   toggleModalConfirm();              
            //   // setModalProgress(!modalProgress);
            //   setIsSend(true);
            //   // handleSendDocument();
            // }}
            onClick={handleSendDocSingleSignBulk}
            className="w-full"
            type="primary"
          >
            {t("Next")}
          </Button>
        </div>
      </Modal>

      <Modal
        title={t("Are you sure?")}
        subtitle={t("After the data has been submit, you cannot change the data. Please make sure the data is correct.")}
        type="warning"
        visible={isModalConfirm2}
        onCancel={toggleModalConfirm2}
        page={1}
        totalPage={1}
      >
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Document Name")}</p>
            <div className="w-2" />
            <ul className="flex flex-col space-y-1 max-h-40 overflow-y-auto custom-scrollbar">
              {documents?.map((item, index) => {
                return (
                  <li key={index}>
                    <Tooltip title={item.doc_name}>
                      <p className="text-gray-800 truncate">{item.doc_name}</p>
                    </Tooltip>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Stamp Category")}</p>
            <div className="w-12" />
            <Tooltip title="Single Stamp">
              <p className="text-gray-800 truncate">{t("Stamp Bulk On Document")}</p>
            </Tooltip>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Total Stamp")}</p>
            <div className="w-12" />
            <Tooltip title="Single Stamp">
              <p className="text-gray-800 truncate">
                {documents?.length * totalStampPosition?.length} {t("Stamp")}
              </p>
            </Tooltip>
          </div>
          <Divider />
        </div>
        <Form onFinish={handleSubmit} autoComplete={false}>
            <div>
              <p>Note (Optional)</p>
              <Form.Item name="note">
                <TextArea name="note" rows={4} />
              </Form.Item>
            </div>
            <Form.Item>
              <div className="flex flex-row space-x-4">
                <Button
                  onClick={() => {
                                        
                    toggleModalConfirm2();
                  }}
                  className="w-full"
                  ghost
                  type="secondary"
                >
                  {t("Back")}
                </Button>
                <Button                  
                  className="w-full"
                  type="primary"
                  htmlType="submit">
                  {t("Submit")}
                </Button>
              </div>
            </Form.Item>
          </Form>
      </Modal>

      <Modal
        title={t("Are you sure?")}
        subtitle={`${t("Please make sure your data is correct. OTP Code automatic has sent to your email, let’s check!")} ${userProfile?.email}`}
        type="warning"
        visible={isModalOtp}                        
        page={2}
        totalPage={2}
      >
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row justify-between">
            <p>{t("OTP Code")}</p>
            <Countdown 
              date={countdownValue} 
              renderer={renderer}
              ref={countdownRef} />
          </div>
          <OtpInput
            value={otpValue}
            onChange={(otp) => setOtpValue(otp)}
            numInputs={6}
            isInputNum
            className="text-gray-800"
            containerStyle={{
              display: "flex",
              justifyContent: "space-between",
            }}
            inputStyle={{
              width: "3rem",
              height: "3rem",
              fontWeight: "bold",
              border: "2px solid #DBDBDB",
              borderRadius: 4,
              color: "#000000",
            }}
          />
          <Form onFinish={handleSubmit} autoComplete={false}>
            <div>
              <p>{t("Note (Optional)")}</p>
              <Form.Item name="note">
                <TextArea name="note" rows={4} />
              </Form.Item>
            </div>
            <Form.Item>
              <div className="flex flex-row space-x-4">
                <Button
                  // disabled
                  onClick={() => {
                    toggleModalOtp();
                    toggleModalConfirm();
                  }}
                  className="w-full"
                  ghost
                  type="secondary"
                  
                >
                  {t("Back")}
                </Button>
                <Button
                  disabled={otpValue?.length < 6}
                  className="w-full"
                  type="primary"
                  htmlType="submit"
                >
                  {t("Submit")}
                </Button>
              </div>
            </Form.Item>
          </Form>
          <Divider />
          <div className="text-center">
            <p className="text-gray-500 text-xs">
              {t("By pressing the ‘Submit’ button means that you have agreed to the")}
            </p>
            <p className="text-blue-500 text-xs">{t("Terms & Conditions")}</p>
          </div>
        </div>
      </Modal>
      <Modal
        title={t("Are you sure?")}
        type="warning"
        visible={isModalSystem}                        
        page={2}
        totalPage={2}
      >
        <div className="flex flex-col space-y-4">               
          <Form onFinish={handleSubmitSystem} autoComplete={false}>
            <div>
              <p>{t("Note (Optional)")}</p>
              <Form.Item name="note">
                <TextArea name="note" rows={4} />
              </Form.Item>
            </div>
            <Form.Item>
              <div className="flex flex-row space-x-4">
                <Button
                  onClick={() => {
                    setIsModalSystem(false);
                    setModalProgress(true);
                  }}
                  className="w-full"
                  ghost
                  type="secondary"
                  
                >
                  {t("Back")}
                </Button>
                <Button
                  className="w-full"
                  type="primary"
                  htmlType="submit"
                >
                  {t("Submit")}
                </Button>
              </div>
            </Form.Item>
          </Form>
          <Divider />
          <div className="text-center">
            <p className="text-gray-500 text-xs">
              {t("By pressing the ‘Submit’ button means that you have agreed to the")}
            </p>
            <p className="text-blue-500 text-xs">{t("Terms & Conditions")}</p>
          </div>
        </div>
      </Modal>
      <Modal
        visible={successModal}
        title={t("Success")}
        subtitle={t("The document has been stamped! Now you can view document detail or continue browsing.")}
        onCancel={() => setsuccessModal(false)}
        type="success"
      >
        <div className="flex flex-row space-x-4 gap-3">
          <Button
            type="primary"
            className="w-full h-11 tracking-tight"
            onClick={() => history.replace('/sign-management')}
          >
            {t("Done")}
          </Button>
        </div>
      </Modal>
      <Modal
        visible={activeLocation}
        type="warning"
        title={t("Confirmation Location")}
        subtitle={t("For more accurate data certificate in document, turn on device location.")}
        onCancel={() => setActiveLocation(false)}
      >
        <div className="flex justify-center">
        <Button onClick={() => setActiveLocation(false)} ghost type="primary" className="mt-9 mb-9 h-10 rounded-md w-52 tracking-tight font-medium mr-4">{t("No")}</Button>
          <Button onClick={() => getLocation()} type="primary" className="mt-9 mb-9 h-10 rounded-md w-52 tracking-tight font-medium">{t("Yes")}</Button>
        </div>
      </Modal>

      <Modal
        visible={isModalDocType}
        onCancel={handleModalDocType}
        type="warning"
        subtitle={t("Choose Document Type")}
        title={t("Update Document Type")}
        width={500}
      >
        <Form onFinish={onFinish}>
          <Row align="midle">
            <Col md={24}>
              <Form.Item
                name="docType"
                initialValue={docType}
                rules={[{required : true , message : t('Please select document type')}]}
              >
                <Select placeholder="Choose" value={docType} className="mt-4">
                  <Option key={1}>Test 1</Option>
                </Select>
              </Form.Item>
              <div className="flex justify-center">
                <Button onClick={handleModalDocType} ghost type="primary" className="mt-9 mb-9 h-10 rounded-md w-full tracking-tight font-medium mr-4">{t("No")}</Button>
                <Button htmlType="submit" type="primary" className="mt-9 mb-9 h-10 rounded-md w-full tracking-tight font-medium">{t("Yes")}</Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        visible={modalProgress && isSuccessSendDoc}
        onCancel={() => { setModalProgress(!modalProgress) }}        
        subtitle={t("Please don't reload or leave this page until process is done.")}
        title={t("Your Document Has Been Proceed")}
        width={500}
      
      >

      <Row gutter={24}>
        <Col span={24}>
          <Progress 
            type="line" 
            strokeWidth={20} 
            percent={(Number(totalSend) / Number(totalDocs)) * 100} 
            format={percent => `${totalSend}/${totalDocs}`}
            className="pr-6"
          />
        </Col>
      </Row>   
      <Row gutter={24} justify="center">
      <div className="flex justify-center">
          <Button 
            className="bg-blue-700 rounded-md text-white w-40"
            disabled={totalSend < totalDocs}
            onClick={() => {
              setModalProgress(!modalProgress)
              if(userProfile?.is_system_sign){
                setIsModalSystem(true)
                   
              } else {
                toggleModalOtp();
                handleSendOtp();
                setCountdownValue(Date.now() + 120000)
                setTimeout(() => {
                  countdownRef.current.api.start()
                }, 100) 
              }
                        
            }}
          > 
            {t("Next")} 
          </Button>
      </div>
      </Row>     
      </Modal>
      
      <ModalAntd
        footer={false} 
        visible={isLocationBlocked}
        width={1050}
        onCancel={() => setIsLocationBlocked(false)}
        >
        <div className='mb-6 pt-2 pb-2'>
          <div className="mb-6">
            <h4 className="text-xl font-semibold text-gray-600 mb-5">{t("Location Not Found")}</h4>
            <hr />
          </div>
          <p className='font-medium text-base text-left mt-2 text-gray-600'>{t("Your location was not found. You need to enable GPS permission to be able to Stamp documents. You can do some of the steps below. !")}</p>
          <div onClick={() => setGuideModal( prev => ({...prev , guide1 : !prev.guide1}))} className='mt-5 cursor-pointer'>
            <div className="flex justify-between items-center">
              <p className='text-xl font-bold'>{t("Access Google Chrome")}</p>
              <img width={18} height={15} src={ guideModal.guide1 === true ? arrowUp : arrowBtm } alt="" />
            </div>
            {
              guideModal.guide1 &&
              <div className='mt-2'>
                <p className='text-black font-medium text-base'>{t("1. Logo location on the searchbar")}</p>
                <p className='text-black font-medium text-base'>{t("2. Change the option to “Allow this site access your location”")}</p>
                <div className="mt-2 flex items-center">
                  <img src={google1} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={google2} alt="" />
                </div> 
              </div> 
            }
            <hr className="mt-4 h-1" />
          </div>
          <div onClick={() => setGuideModal( prev => ({...prev , guide2 : !prev.guide2}))} className='mt-2 cursor-pointer'>
            <div className="flex justify-between items-center">
              <p className='text-xl font-bold'>{t("Access With Safari")}</p>
              <img width={18} height={15} src={ guideModal.guide2 === true ? arrowUp : arrowBtm } alt="" />
            </div>
            {
              guideModal.guide2 && 
              <div className='mt-2'>
                <p className='text-black font-medium text-base'>{t("1. Safari menu")}  </p>
                <p className='text-black font-medium text-base'>{t("2. Settings for [domain name, Co: demo.vas.id]")} </p>
                <p className='text-black font-medium text-base'>{t("3. Deactivate the “Enable content blockers” checkbox")}</p>
                <div className="mt-2 flex items-center">
                  <img width={225} height={215} src={safari1} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={safari2} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={safari3} alt="" />
                </div> 
              </div>
            }
            <hr className="mt-4 h-1" />
          </div>
          <div onClick={() => setGuideModal( prev => ({...prev , guide3 : !prev.guide3}))} className='mt-2 cursor-pointer'>
            <div className="flex justify-between items-center">
              <p className='text-xl font-bold'>Access With Firefox</p>
              <img width={18} height={15} src={ guideModal.guide3 === true ? arrowUp : arrowBtm } alt="" />
            </div>
            {
              guideModal.guide3 && 
              <div className='mt-2'>
                <p className='text-black font-medium text-base'>{t("1. Logo location on the searchbar")} </p>
                <p className='text-black font-medium text-base'>{t("2. Delete the option “Block temporarily”")} </p>
                <div className="mt-2 flex items-center">
                  <img src={firefox1} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={firefox2} alt="" />
                </div> 
              </div>
            }
          </div>
        </div>
        <div className="w-full">
          <hr className='mb-4' />
          <div className="flex justify-center">
          <Button 
            className="bg-blue-700 rounded-md text-white w-40"
            onClick={() => setIsLocationBlocked(false)}
          > 
            {t("Close")} 
          </Button>
          </div>
        </div>
      </ModalAntd>
      <Spinner loading={isFetching || isLoadingPreview || isFetchingDocuments} />
    </>
  );
}