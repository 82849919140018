import { Modal } from "antd";
import React from "react";
import { ReactComponent as CloudUploadIcon } from "../../assets/icons/cloud-upload-outline.svg";

export default function ModalUpload(props) {
  return (
    <Modal
      {...props}
      className="border-green-400 rounded-md"
      style={{ borderTopWidth: 14 }}
      footer={false}
      title={false}
    >
      <div className="flex flex-col space-y-4 px-8 -mt-20">
        <div className="flex flex-col space-y-2 justify-center items-center">
          <CloudUploadIcon className="p-2 w-20 h-20 bg-green-500 rounded-full" />
          <p className="text-gray-800 font-semibold text-xl md:text-2xl">
            {props.title}
          </p>
          {props.subtitle && <p className="text-gray-500">{props.subtitle}</p>}
        </div>
        {props.children}
      </div>
    </Modal>
  );
}
