import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { rootApi, access } from "../config";

export const generalApi = createApi({
  reducerPath: "api/general",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi,
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      downloadQuotation: builder.mutation({
        query: ({ id, t }) => ({
          method: "GET",
          url: "/download-quotation",
          params: {
            id,
            t,
          },
        }),
        transformResponse: (response) => (response ? response.data : {}),
      }),

      FetchListRegion: builder.query({
        query(province_id = "") {
          return `/region?province_id=${province_id}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchListModule: builder.query({
        query(office_id) {
          return `/module?office_id=${office_id}`;
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      fetchQuota: builder.query({
        query() {
          return `/quota`;
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      forgotPassword: builder.mutation({
        query: (body) => ({
          method: "PUT",
          url: "/forgot",
          params: {
            email: body?.email,
          },
          body,
        }),
        transformResponse: (response) => (response ? response.data : ""),
      }),

      editTags: builder.mutation({
        query: ({ is_folder = "", doc_id = "", body = {} }) => ({
          method: "PUT",
          url: `/tag/${doc_id}?is_folder=${is_folder}`,
          body,
        }),
      }),

      fetchListPayment: builder.query({
        query({bill_id}) {
          //console.log(bill_id);
          return `/admin/paymentmethode/${bill_id}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      createPayment: builder.mutation({        
        query: ({body, acc_q}) => ({
          method: "POST",
          url: `/admin/createpayment/${acc_q}`,          
          body,
        }),
        transformResponse: (response) => (response ? response.data : {}),
      }),

      detilPayMethode : builder.query({
        query({id}) {
          //console.log(id);
          return `/admin/detilpaymethode/${id}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

    };
  },
});

export const {
  useDownloadQuotationMutation,
  useFetchListRegionQuery,
  useFetchListModuleQuery,
  useForgotPasswordMutation,
  useEditTagsMutation,
  useFetchQuotaQuery,
  useFetchListPaymentQuery,
  useCreatePaymentMutation,
  useDetilPayMethodeQuery
} = generalApi;
