import { DeleteOutlined, LeftOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Modal, Spinner } from "../../components";
import { useAddQuotationMutation } from "../../config/features";
import { useFetchListProductQuery } from "../../config/features/product";
import { errorMessage, numberCommas } from "../../utils";
import { PRODUCTS } from "../../constant";
import { useTranslation } from "react-i18next";
const { Option } = Select;

export default function AddQuotationSelf() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { products } = form.getFieldsValue();
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const [totalPrice, setTotalPrice] = useState(0);
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [payload, setPayload] = useState({});

  const { data: dataListProduct = [], isFetching } = useFetchListProductQuery({});

  const [addQuotation, { isSuccess, isError, error, isLoading }] = useAddQuotationMutation();

  useEffect(() => {
    if (isSuccess) {
      setIsModalSuccess(true);
      form.resetFields();
    }

    if (isError) {
      errorMessage(error);
    }
  }, [isSuccess, isError, error, form]);

  useEffect(() => {
    const products = [];
    for (let i = 0; i < location?.state?.length; i++) {
      products.push({
        ...location?.state[i],
        quota: location?.state[i]?.remaining_quota || 0,
        price: `Rp ${numberCommas(location?.state[i].price)},-`,
      });
    }
    form.setFieldsValue({ products });
    console.log(location.state);
  }, [form, location]);

  useEffect(() => {
    setTotalPrice(1);
  }, [totalPrice]);

  function getTotalPrice() {
    const { products } = form.getFieldsValue();
    let price = 0;

    // price += total;
    if (products) {
      for (let i = 0; i < products.length; i++) {
        price += products[i]?.quantity * products[i]?.unit_price;
      }
    }

    return price;
  }

  function isProductInvalid(item) {
    const isInvalid = Boolean(form?.getFieldValue("id") === item?.id);
    const isInvalidList = Boolean(
      form
        ?.getFieldValue("products")
        ?.find((element) => element?.id === item?.id)
    );

    return isInvalidList || isInvalid;
  }

  function handleFinish(values) {
    //console.log(values)
    values.office_id = id;
    values.total = getTotalPrice();
    setPayload(values);
    toggleModalConfirm();
  }

  function handleSubmitThisClient() {
    try {
      toggleModalConfirm();
      addQuotation(payload);
    } catch (error) {
      message.error(error.message);
    }
  }

  function toggleModalConfirm() {
    setIsModalConfirm(!isModalConfirm);
  }

  function handleDone() {
    history.goBack();
  }

  return (
    <>
      <Form
        autoComplete="off"
        layout="vertical"
        form={form}
        onFinish={handleFinish}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.goBack()}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      {t("Add New Quotation")}
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    className="md:w-40"
                    type="primary"
                    htmlType="submit"
                  >
                    {t("Create")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="mb-20">
            <Form.List name="products">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => (
                      <Card className="rounded-md mb-4" key={index}>
                        <Row key={key} gutter={[24, 0]} align="middle">
                          <Col span={24}>
                            <Row
                              gutter={[24, 24]}
                              align="middle"
                              justify="space-between"
                            >
                              <Col>
                                <h3 className="text-gray-500 text-base md:text-lg font-medium">
                                  {location?.state[index]?.id ? PRODUCTS[location?.state[index]?.id].value : `${t("Product")} (${index + 1})`}
                                </h3>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Divider className="my-4" />
                          </Col>
                          <Col xs={24} md={24} className="hidden">
                            <Form.Item
                              {...restField}
                              label={t("Product Name")}
                              name={[name, "product_name"]}
                              fieldKey={[fieldKey, "product_name"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("Please input product name!"),
                                },
                              ]}
                            >
                              <Select
                                placeholder={t("Not Selected")}
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={true}
                              >
                                {dataListProduct?.map((item, index) => {
                                  return (
                                    !isProductInvalid(item) && (
                                      <Option key={index} value={item.id}>
                                        {item.name}
                                      </Option>
                                    )
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} className="hidden">
                            <Form.Item
                              {...restField}
                              label={t("Product Name")}
                              name={[name, "id"]}
                              fieldKey={[fieldKey, "id"]}
                              rules={[
                                {
                                  required: false,
                                  message: t("Please input product name!"),
                                },
                              ]}
                            >
                              <Select
                                placeholder={t("Not Selected")}
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {dataListProduct?.map((item, index) => {
                                  return (
                                    !isProductInvalid(item) && (
                                      <Option key={index} value={item.id}>
                                        {item.name}
                                      </Option>
                                    )
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={24}>
                            <Form.Item
                              {...restField}
                              label={t("Unit Price")}
                              name={[name, "unit_price"]}
                              fieldKey={[fieldKey, "unit_price"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("Please input unit price!"),
                                },
                                ({ getFieldsValue, setFieldsValue }) => ({
                                  validator(_, value) {
                                    const fields = getFieldsValue();
                                    const { products } = fields;
                                    if (value || products[index]?.quantity) {
                                      Object.assign(products[index], {
                                        total:
                                          products[index]?.quantity * value,
                                        price: `Rp ${numberCommas(
                                          products[index]?.quantity * value
                                        )},-`,
                                      });
                                      setFieldsValue({ products });
                                      setTotalPrice(totalPrice + 1);
                                      return Promise.resolve();
                                    }
                                  },
                                }),
                              ]}
                            >
                              <Input
                                addonBefore="Rp"
                                placeholder={t("Enter unit price")}
                                min={1}
                                disabled={true}
                              />
                            </Form.Item>
                          </Col>
                          {products[key]?.id != "6" && (
                            <>
                              <Col xs={24} md={12}>
                                <Form.Item
                                  {...restField}
                                  label={t("Quantity")}
                                  name={[name, "quantity"]}
                                  fieldKey={[fieldKey, "quantity"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("Please input quantity!"),
                                    },
                                    ({ getFieldsValue, setFieldsValue }) => ({
                                      validator(_, value) {
                                        const fields = getFieldsValue();
                                        const { products } = fields;
                                        // e-Meterai per seal is related with Stamp Process
                                        // find stamp process index and populate the data 
                                        // based on e-meterai per-seal
                                        if(products[key].id == "1"){
                                          const stampProcessKey = products.findIndex(item => item?.id == "6")
                                          Object.assign(products[stampProcessKey], {
                                            quantity: value,
                                            total: products[stampProcessKey].unit_price * value,
                                            price: `Rp ${numberCommas(
                                              products[stampProcessKey].unit_price * value
                                            )},-`,
                                          });
                                        }
                                        
                                        Object.assign(products[index], {
                                          total: products[index].unit_price * value,
                                          price: `Rp ${numberCommas( products[index].unit_price * value)},-`,
                                        });
                                        setFieldsValue({ products });
                                        setTotalPrice(totalPrice + 1);

                                        if(value > products[index].quota || 
                                          products[index].quantity > products[index].quota) {
                                            return Promise.reject("Quantity cannot be more than Quota")
                                          }

                                        return Promise.resolve();
                                      },
                                    }),
                                  ]}
                                >
                                  <InputNumber 
                                    placeholder={t("Enter Quantity")} 
                                    className="w-full"
                                    min={0} 
                                    precision={0}
                                    controls={false} />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={12}>
                                <Form.Item
                                  {...restField}
                                  label={t("Quota")}
                                  name={[name, "quota"]}
                                  fieldKey={[fieldKey, "quota"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("Please input quota!"),
                                    },
                                  ]}
                                >
                                  <InputNumber 
                                    placeholder={t("Enter Quota")} 
                                    className="w-full"
                                    min={0} 
                                    precision={0}
                                    controls={false} 
                                    disabled={true}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          )}
                          <Col xs={24} md={12} className="hidden">
                            <Form.Item
                              {...restField}
                              label="Price"
                              name={[name, "price"]}
                              fieldKey={[fieldKey, "price"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("Please input price!"),
                                },
                              ]}
                              initialValue="Rp 0,-"
                            >
                              <Input placeholder={t("Enter price")} disabled />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} className="hidden">
                            <Form.Item
                              {...restField}
                              label="Total"
                              name={[name, "total"]}
                              fieldKey={[fieldKey, "total"]}
                              rules={[
                                {
                                  required: false,
                                  message: t("Please input total!"),
                                },
                              ]}
                            >
                              <Input placeholder={t("Enter total")} disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    )
                  )}
                </>
              )}
            </Form.List>
          </Col>
          <div className="fixed bottom-10 right-10 left-60">
            <div className="flex flex-row justify-between rounded-md bg-blue-200 px-8 py-2">
              <h2 className="text-xs md:text-sm lg:text-base font-medium text-gray-800">
                {t("Total Price")}
              </h2>
              <h2 className="text-xs md:text-sm lg:text-base font-medium text-blue-800">
                {`Rp ${numberCommas(getTotalPrice())},-`}
              </h2>
            </div>
          </div>
        </Row>
      </Form>
      <Modal
        title={t("Are you sure?")}
        type="warning"
        visible={isModalConfirm}
        onCancel={toggleModalConfirm}
        width={700}
      >
        <div className="flex flex-col space-y-2">
          <Table
            dataSource={payload?.products}
            columns={[
              {
                title: t("Product Name"),
                render: (record) => PRODUCTS[record.id].value
              },
              {
                title: t("Unit Price"),
                dataIndex: "unit_price",
                key: "unit_price",
                render: (unit_price) => {
                  return `Rp ${numberCommas(unit_price)},-`;
                },
              },
              {
                title: t("Quantity"),
                dataIndex: "quantity",
                key: "quantity",
                render: (quantity) => {
                  return numberCommas(quantity);
                },
              },
              {
                title: t("Quota"),
                dataIndex: "quota",
                key: "quota",
                render: (quota) => {
                  return numberCommas(quota);
                },
              },
              {
                title: t("Total"),
                align: "right",
                render: (record) => {
                  return `Rp ${numberCommas(
                    record.unit_price * record.quantity
                  )},-`;
                },
              },
            ]}
            pagination={false}
          />
          <div className="flex flex-row justify-between px-4">
            <p className="font-medium text-gray-800">{t("Total")}</p>
            <p className="font-medium text-gray-800">
              Rp {numberCommas(payload?.total)},-
            </p>
          </div>
          <Divider />
        </div>
        <div className="flex flex-row space-x-4 pt-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={toggleModalConfirm}
          >
            {t("Cancel")}
          </Button>
          <Button
            className="w-full"
            type="primary"
            onClick={handleSubmitThisClient}
          >
            {t("Yes")}
          </Button>
        </div>
      </Modal>
      <Modal
        title={t("Successfully Create a Quotation!")}
        type="success"
        visible={isModalSuccess}
        onCancel={() => setIsModalSuccess(false)}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={handleDone}>
            {t("Done")}
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
