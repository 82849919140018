import { CloseOutlined, LeftOutlined, ArrowsAltOutlined } from "@ant-design/icons";
import { Col, Divider, message, Row, Select , Form , Input , Modal as ModalAntd} from "antd";
import {Modal , Button, Spinner} from '../../components'
import { PDFDocument, degrees } from "pdf-lib";
import {useLocation} from 'react-router-dom'
import pdfJsWorker from "pdfjs-dist/build/pdf.worker.entry";
import React, { useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { Document, Page, pdfjs } from "react-pdf";
import { Resizable } from "react-resizable";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router-dom";
import Countdown from "react-countdown";
import OtpInput from "react-otp-input";
import { ReactComponent as SignWithOtherIcon } from "../../assets/icons/sign-with-other.svg";
import SignSvg, {
  ReactComponent as SignIcon,
} from "../../assets/icons/sign.svg";
import CapSvg, {
  ReactComponent as CapIcon,
} from "../../assets/icons/cap.svg";
import DoneIcon from '../../assets/icons/done.svg'
import ResetIcon from '../../assets/icons/reset.svg'
import CloseIcon from '../../assets/img/icon/closeIcon.png'
import SettingIcon from '../../assets/img/icon/setting.png'
import google1 from '../../assets/img/google-1.png'
import google2 from '../../assets/img/google-2.png'
import safari1 from '../../assets/img/safari1.png'
import safari2 from '../../assets/img/safari2.png'
import safari3 from '../../assets/img/safari3.png'
import firefox1 from '../../assets/img/firefox1.png'
import firefox2 from '../../assets/img/firefox2.png'
import arrowRight from '../../assets/img/icon/right-arrow.png'
import arrowBtm from '../../assets/img/icon/arrow-btm.png'
import arrowUp from '../../assets/img/icon/arrow-up.png'

import axios from "axios";
import { rootApi } from "../../config/features/config";
import { getPosition, numberCommas } from "../../utils";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { useTranslation } from "react-i18next";


pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker;

const { Option } = Select;

const {TextArea} = Input

let TeraImg;
  import(`../../assets/img/tera-spec.png`)
  .then((module) => {
    TeraImg = module.default;
  });

export default function SelfSign() {
  const { t } = useTranslation();

  const tokenPeruri = useMemo( () => localStorage.getItem('peruri') , [])
  const tokenAccess = useMemo( () => localStorage.getItem('access') , [])
  const {state : {dataDoc}} = useLocation()
  const history = useHistory();
  const [pdfFile, setPdfFile] = useState(null);
  const [totalStamp , setTotalStamp] = useState(0)
  const [done , setDone] = useState(false)
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  let [totalSignature, setTotalSignature] = useState([]);
  const [moveable, setMoveable] = useState(true);
  const [previewModal , setPreview] = useState(false)
  const [successModal, setsuccessModal] = useState(false)
  const [guideModal , setGuideModal] = useState({ guide1 : false , guide2 : false , guide3 : false ,  })
  const [activeLocation , setActiveLocation ] = useState(false)
  const [isLocationBlocked , setIsLocationBlocked ] = useState(false)
  const [isResendOtp, setIsResendOtp] = useState(false);
  const [countdownValue , setCountdownValue] = useState(Date.now());
  const [otpValue, setOtpValue] = useState("");
  const [otpPreview , setOtpPreview] = useState(false)
  const [totalReset , setTotalReset] = useState(0)
  const [totalMaterai , setTotalMaterai] = useState({total : 0 , remaining : 0})
  const [docSign , setDocSign] = useState(null)
  const [isSubmit , setIsSubmit] = useState(false)
  const [listStampPosition , setListStampPosition] = useState([])
  const [loading , setLoading] = useState(false)
  const [openSign , setOpenSign] = useState(false)
  const [isParaf , setIsParaf] = useState(false)
  const [isTera, setIsTera] = useState(false)
  const [doResize , setDoResize] = useState(false)

  const [imgSign , setImgSign] = useState(null) 
  const [imgParaf , setImgParaf] = useState(null) 

  const [deltaPosition, setDeltaPosition] = useState({ x: 0, y: -500 });
  const [sizing, setSizing] = useState({
    signature: { width: 150, height: 90 },
    paraf: { width: 150, height: 90 },
    tera: {width: 100, height: 100}
  });
  const [location , setLocation] = useState(null)
  const [reason , setReason] = useState(null)


  const [size, setSize] = useState({ x: 100, y: 100 });
  const [isDrag, setIsDrag] = useState(false);

  const { data: userProfile = {} } = useFetchProfileUserQuery();

  const normalizeCoordinates = (x, y, rotation, pageWidth, pageHeight) => {
    let newX, newY;
    switch (rotation % 360) {
      case 90:
        // 90 degrees: Origin at the top-left corner
        newX = pageWidth - y;
        newY = x;
        break;
      case 180:
        // 180 degrees: Origin at the top-right corner
        newX = pageWidth - x;
        newY = pageHeight - y;
        break;
      case 270:
        // 270 degrees: Origin at the bottom-right corner
        newX = y;
        newY = pageHeight - x;
        break;
      case 0:
      default:
        // 0 degrees: Default coordinate system
        newX = x;
        newY = y;
        break;
    }
    return { x: newX, y: newY }
  }

  const [currentWidthRatio, setCurrentWidthRatio] = useState(3)

  useEffect(() => {
    function base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    }

    async function fetchData() {

      try {
        setLoading(true)
        //console.log("Data Dokument : ", dataDoc);
        const {data : {data}} = await axios({
          method : 'GET' ,
          url : `${rootApi}/document/preview/${dataDoc.doc_id}` ,
          headers : {
            "token" : tokenAccess
          }
        })
        
        setLoading(false)
        const documentBuffer = base64ToArrayBuffer(data)
        setPdfFile(documentBuffer);
      } catch (error) {
        setLoading(false)
        message.error(error.message);
      }
    }

    async function getQuoteMeterai(){

      const {data : {data}} = await axios({
        method : 'GET' ,
        url : `${rootApi}/quota` ,
        headers : {
          token : tokenAccess
        }
      })

      setTotalMaterai({ total : data[1].remaining , remaining : data[1].remaining})
    }

    fetchData();
    getQuoteMeterai()
  }, [totalReset]);

  async function getSignatureAndParaf(type){

    if (dataDoc.typeSign == 0) {
      try{

        setLoading(true)
  
        const {data : {data : dataProfile}} = await axios({
          method : 'GET' ,
          url : `${rootApi}/user/profile` ,
          headers : {
            token : tokenAccess
          }
        })
  
        const {data: dataSign} = await axios({
          method : 'GET' ,
          url : `${rootApi}/sign/preview-speciment/${dataProfile.user_id}` ,
          headers : {
            token : tokenAccess ,
           "token-peruri-sign" : tokenPeruri
          }
        })
  
        let sign, paraf
        if(dataDoc.typeSign != 1){
          sign = new Image()
          sign.src = dataSign.data.base64Speciment
          sign.onload = function() {
            const ratioA = Math.min(100 / sign.width, 100 / sign.height);
            setSizing(prev => ({
              ...prev, 
              signature: { width: sign.width * ratioA, height: sign.height*ratioA }
            }))
            if (type == 'sign') {
              setSize({x: sign.width * ratioA, y: sign.height * ratioA})
            }
          }
          
          paraf = new Image()
          paraf.src = dataSign.data.base64Paraf
          paraf.onload = function() {
            const ratioB = Math.min(100 / paraf.width, 100 / paraf.height);
            setSizing(prev => ({
              ...prev, 
              paraf: { width: paraf.width * ratioB, height: paraf.height * ratioB }
            }))
            if (type == 'paraf') {
              setSize({x: paraf.width * ratioB, y: paraf.height * ratioB})
            }
          }
        } else {
          setSizing(prev => ({
            ...prev, 
            tera: { width: 100 , height: 100  }
          }))
          if (type == 'tera') {
            setSize({x: 100, y: 100})
          }
        }
        // Get images size
        
  
  
        
          const ratioB = Math.min(100 / TeraImg.width, 100 / TeraImg.height);
          
        
       
  
        if(dataSign.data.base64Speciment === null && dataSign.data.base64Paraf === null )
        {
          message.info(t("Please upload your signature and paraf before sign!"))
        }
        else
        {
  
          if(dataSign.data.base64Speciment !== null && dataSign.data.base64Paraf !== null)
          {
            setImgSign(dataSign.data.base64Speciment)
            setImgParaf(dataSign.data.base64Paraf)
  
          }
  
          if(dataSign.data.base64Speciment === null)
          {
            message.info(t("Please upload your signature before sign!"))
          }
  
          if(dataSign.data.base64Paraf === null)
          {
            message.info(t("Please upload your paraf before sign!"))
          }
        }
  
        setLoading(false)
  
      }
      catch(e){
  
        if(e.response){
          const {data : {error}} = e.response
  
          setLoading(false)
  
          message.error(error)
  
          if(error === "Error when preview speciment")
          {
            history.push('/sign-management/create-signature')
          }
        } else {
          message.error(e)
        }
        
  
      }
    }
  }
  useEffect(() => {
    if (dataDoc.typeSign == 0) {
      getSignatureAndParaf()
    }
  },[tokenAccess, tokenPeruri, dataDoc.typeSign])

  useEffect(() => {
    // get current user position 
    async function getStatePosition(){
      const data = await getPosition()
      if(data !== undefined || data !== '' )
      {
        setLocation(data)
      }
      else
      {
        setLocation('')
      }
    }
    getStatePosition()
  },[])
  

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleStop = (e, position) => {    
      const { x, y } = position;
      setDeltaPosition({ x, y });    
  };

  const handleResize = (event, { element, size, handle }) => {
    setSizing({ ...sizing, width: size.width, height: size.height });
  };

  
  
  async function handleAddSignature(index , paraf = false, tera = false) {
    setDone(true)
    if(totalMaterai - 1 < 0){
      message.warning(t('Your meterai not enought to sign'))
    } else {
      setCurrentWidthRatio(3)
      if(tera){
        await getSignatureAndParaf("tera");
        setIsParaf(false)
        setIsTera(true)
        setTotalMaterai( prev => ({ ...prev , total : prev.total - 1 }))
        setDeltaPosition({  x: 0, y: -500 });
        setTotalSignature([...totalSignature, index]);
      } else {
        if(!paraf) {
          await getSignatureAndParaf("sign");
          setIsParaf(false)
          setTotalMaterai( prev => ({ ...prev , total : prev.total - 1 }))
          setDeltaPosition({  x: 0, y: -500 });
          setTotalSignature([...totalSignature, index]);
        } else {
          await getSignatureAndParaf("paraf");
          setIsParaf(true)
          setTotalMaterai( prev => ({ ...prev , total : prev.total - 1 }))
          setDeltaPosition({  x: 0, y: -500 });
          setTotalSignature([...totalSignature, index]);
        }
      }
      

    }
  }


  function handleCloseSignature(index) {
    setDone(false)
    //delete total signature by value
    setTotalMaterai( prev => ({ ...prev , total : prev.total + 1 }))
    const duplicateTotalSignature = [...totalSignature];
    for (var i = 0; i < duplicateTotalSignature.length; i++) {
      if (duplicateTotalSignature[i] === index) {
        duplicateTotalSignature.splice(i, 1);
      }
      setTotalSignature(duplicateTotalSignature);
    }

    // delete delta position of signature
    const duplicateDeltaPosition = { ...deltaPosition };
    delete duplicateDeltaPosition[index];
    setDeltaPosition(duplicateDeltaPosition);
  }

  function handleChangePage(e) {
    setPageNumber(e);
  }


  async function modifyPdf(existingPdfBytes) {
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    let pngImageBytes;
    let pngWidth;
    let pngHeight;

    if(isTera){
      pngImageBytes = await fetch(TeraImg).then( res => res.arrayBuffer())
      pngWidth = sizing.tera.width;
      pngHeight = sizing.tera.height;
    } else {
      if(isParaf){
        pngImageBytes = await fetch(imgParaf).then( res => res.arrayBuffer())
        pngWidth = sizing.paraf.width;
        pngHeight = sizing.paraf.height;
      } else {
        pngImageBytes = await fetch(imgSign).then( res => res.arrayBuffer())
        pngWidth = sizing.signature.width;
        pngHeight = sizing.signature.height;
      }
    }
    

    const pngImage = await pdfDoc.embedPng(pngImageBytes)

    const pages = pdfDoc.getPages();
    const pageDocument = pages[pageNumber - 1];

    const rotation =  pageDocument.getRotation().angle;
    const { width, height } = pageDocument.getSize();

    const deltaPositionY = deltaPosition.y < 0
          ? Math.abs(deltaPosition.y)
          : -Math.abs(deltaPosition.y);
      const deltaPositionX = deltaPosition.x;

    const { x: x_pos, y: y_pos } = normalizeCoordinates(deltaPositionX, deltaPositionY - pngHeight, rotation, width, height)

    const {width: boxWidth, height: boxHeight} = getBoxSize(pngWidth, pngHeight)
    const shift_x = Math.abs((boxWidth - pngWidth) / 2)
    const shift_y = -Math.abs((boxHeight - pngHeight) / 2)
    pageDocument.drawImage(pngImage , {
      x : x_pos + shift_x,
      y : y_pos + shift_y,
      width : pngWidth || 100,
      height : pngHeight || 100,
      rotate: degrees(rotation)
    })

    const pdfBytes = await pdfDoc.save();
    const arr = new Uint8Array(pdfBytes);
    setPdfFile(arr.buffer);
    
    setListStampPosition( prev => [
      ...prev, 
      {
        type: isParaf ? "PARAF" : "SPESIMEN",
        llx: `${Math.abs(deltaPosition.x + shift_x)}`,
        lly: `${Math.abs(deltaPosition.y + shift_y) - pngHeight}`,
        urx: `${Math.abs(deltaPosition.x + shift_x) + pngWidth}`,
        ury: `${Math.abs(deltaPosition.y + shift_y)}`,
        page: `${pageNumber}` ,
        location: (location === null || location === '') ? '' : `${location?.city},${location?.country}`,
        reason: "" ,
        isv : "YES"
      }
    ])
  }

  const handleSave = () => {
    modifyPdf(pdfFile);
    setDone(false)
    setTotalSignature([]);
    setTotalStamp(totalStamp + 1)
  };

  function handleMovable() {
    setMoveable(!moveable);
  }

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setIsResendOtp(true);
      return <div />;
    } else {
      return (
        <p>
          {minutes}:{seconds}
        </p>
      );
    }
  };

  const handlePreviewOtp = async ( id ) => {

    setIsSubmit(true)

    try{

      let bodySend

      if(isTera){
        bodySend = {
          doc_id : `${id}`,
          sign_coordinates: listStampPosition.map( item => ({...item , reason })) ,
          assign_type :  '1',
          sign_type : '3'
        }
      } else {
        if (userProfile?.is_system_sign) {
          bodySend = {
            doc_id : `${id}`,
            sign_coordinates: listStampPosition.map( item => ({...item , reason })) ,
            assign_type :  '1',
            sign_type: '4'
          }
        }
        else {
          bodySend = {
            doc_id : `${id}`,
            sign_coordinates: listStampPosition.map( item => ({...item , reason })) ,
            assign_type :  '1',
          }
        }
      }
      
      if (!docSign) {
        var { data : {data}} = await axios({
          method : 'POST' ,
          url : `${rootApi}/sign/single/send-doc` ,
          data : bodySend ,
          headers : {
            "token" : tokenAccess ,
           "token-peruri-sign" : tokenPeruri
          }
        })
        setDocSign(data)
      }
      else {
        var data = docSign
      }
      if (userProfile?.is_system_sign) {
        await handleSubmitSystem(data)
        console.log(data);
        return;
      }

      const responseGetOtp = await axios({
        method : 'GET' ,
        url : `${rootApi}/sign/get-otp/${data.sign_id}` ,
        headers : {
          "token" : tokenAccess ,
         "token-peruri-sign" : tokenPeruri
        }
      })


      setIsSubmit(false)

      setCountdownValue(Date.now() + 60000)

      setOtpPreview(true)


    }catch(e){

      setIsSubmit(false)
      console.log("Error =>",e);
      message.error(t("Sorry we can process your data, please try again!"))
      
    }


  }

  async function generateSystemSignKey (){
    let r = (Math.random() + 1).toString(36).substring(6);
    const body = { key : `DLII${r}`, minutes: `30`, doc_id: dataDoc?.doc_id }
    const generateSystemKey = await axios({
      method : 'POST' ,
      url : `${rootApi}/sign/set-system-sign-key`,
      data : body ,
      headers : {
        "token" : tokenAccess
      }
    }).then((e) => {
      console.log("KEY ", e);
      return e;

    }).catch((ee) => {
      console.log("B => ", ee);
      message.error(ee.message);
    });
  }

  const handleSubmitSystem = async (data) => {
   setIsSubmit(true) 
   
   try {
    // await generateSystemSignKey() 
    const body = {
      sign_id: [data?.sign_id],
      signer_note: reason ? reason : ''
    }
    const response = await axios({
      method: 'POST',
      url: `${rootApi}/sign/sign-doc-system`,
      data: body,
      headers: {
        "token": tokenAccess
      }
    })
    setPreview(false)
    setsuccessModal(true)
    setIsSubmit(false)
   } catch (e) {
    setIsSubmit(false)
    message.error(t("Pleas try again"))
   }
  }

  const handleSubmit = async (values) => {

    const body = { otp_code : `${otpValue}` , signer_note : reason }

    setIsSubmit(true)

      try{

        const response = await axios({
          method : 'POST' ,
          url : `${rootApi}/sign/sign-doc/${docSign.sign_id}` ,
          data : body ,
          headers : {
            "token" : tokenAccess , 
           "token-peruri-sign" : tokenPeruri
          }
        })

        setPreview(false)

        setsuccessModal(true)

        setOtpValue("")

        setIsSubmit(false)
        
      }catch(e){

        setIsSubmit(false)
        setOtpValue("")
        message.error(t("Please try again!"))
        
      }
  }

  const handleReset = () => {
    setTotalMaterai( prev => ({ ...prev , total : prev.remaining }))
    setCurrentWidthRatio(3)
    setTotalReset(totalReset + 1)
    setTotalStamp(0)
    setListStampPosition([])
  }

  const handleResendOtp = async () => {

    setIsSubmit(true)

    const {data : {data : {message : messageBody}}} = await axios({
      method : 'GET' ,
      url : `${rootApi}/sign/get-otp/${docSign.sign_id}` ,
      headers : {
        "token" : tokenAccess ,
       "token-peruri-sign" : tokenPeruri
      }
    })

    setIsSubmit(false)

    setOtpPreview(true)

    setCountdownValue(Date.now() + 60000)

    message.success(t("Resend OTP success check your email!"))

  }

  const getLocation = async () => {

    setActiveLocation(false)

    const response = await getPosition()
    
    if(typeof response === 'object')
    {
      setLocation(response)
      setPreview(true)
      return
    }

    setLocation('')
    setIsLocationBlocked(true)

  }

  
  const handler0 = (mouseDownEvent) => {
    mouseDownEvent.preventDefault();
    var isDown = true;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const currentSize = size
        const ratio = currentSize.x / currentSize.y

        let resizeValue = 0
        if (currentPosition.x < startPosition.x && currentPosition.y > startPosition.y) {
          // Zoom In
          resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        else if (currentPosition.x > startPosition.x && currentPosition.y < startPosition.y) {
          // Zoom Out
          resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        const currentWidth = currentSize.x + (ratio * resizeValue);
        const currentHeight = currentSize.y + resizeValue;
        setSize({
          x: currentWidth,
          y: currentHeight
        })
        setSizing({
          paraf: { width: currentWidth, height: currentHeight },
          signature: { width: currentWidth, height: currentHeight },
          tera: { width: currentWidth, height: currentHeight },
        })
      }
    }
    function onMouseUp() {
      isDown = false
      document.body.removeEventListener("mousemove", onMouseMove);
    }
    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }
    
  const handler1 = (mouseDownEvent) => {
    mouseDownEvent.preventDefault();
    var isDown = true;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        // console.log("HANDLER 1");
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const currentSize = size
        const ratio = currentSize.x / currentSize.y

        let resizeValue = 0
        if (currentPosition.x > startPosition.x && currentPosition.y > startPosition.y) {
          // Zoom In
          resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        else if (currentPosition.x < startPosition.x && currentPosition.y < startPosition.y) {
          // Zoom Out
          resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))          
        }

        const currentWidth = currentSize.x + (ratio * resizeValue);
        const currentHeight = currentSize.y + resizeValue;
        setSize({
          x: currentWidth,
          y: currentHeight
        })
        setSizing({
          paraf: { width: currentWidth, height: currentHeight },
          signature: { width: currentWidth, height: currentHeight },
          tera: { width: currentWidth, height: currentHeight },
        })
      }
    }
    function onMouseUp() {
      isDown = false
      document.body.removeEventListener("mousemove", onMouseMove);
    }
    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }

  const handler2 = (mouseDownEvent) => {
    mouseDownEvent.preventDefault();
    var isDown = true;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const currentSize = size
        const ratio = currentSize.x / currentSize.y
        let resizeValue = 0
        if (currentPosition.x < startPosition.x && currentPosition.y < startPosition.y) {
          // Zoom In
          resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        else if (currentPosition.x > startPosition.x && currentPosition.y > startPosition.y) {
          // Zoom Out
          resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        const currentWidth = currentSize.x + (ratio * resizeValue);
        const currentHeight = currentSize.y + resizeValue;
        setSize({
          x: currentWidth,
          y: currentHeight
        })
        setSizing({
          paraf: { width: currentWidth, height: currentHeight },
          signature: { width: currentWidth, height: currentHeight },
          tera: { width: currentWidth, height: currentHeight },
        })
      }
    }
    function onMouseUp() {
      isDown = false
      document.body.removeEventListener("mousemove", onmousemove);
    }
    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }

  const handler3 = (mouseDownEvent) => {
    mouseDownEvent.preventDefault()
    var isDown = true
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY }
    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const currentSize = size
        const ratio = currentSize.x / currentSize.y
        const { width: boxWidth } = getBoxSize(currentSize.x, currentSize.y)

        const x_shift = (startPosition.x - currentPosition.x) * 2
        setCurrentWidthRatio(prev => prev + (x_shift / boxWidth) > ratio && prev + (x_shift / boxWidth) <= 3 ? prev + (x_shift / boxWidth) : prev) // faster calculation
      }
    }
    function onMouseUp() {
      isDown = false
      document.body.removeEventListener("mousemove", onMouseMove)
    }
    document.body.addEventListener("mousemove", onMouseMove)
    document.body.addEventListener("mouseup", onMouseUp, { once: true })
  }


  const onImgLoad = ({target:img}) => {
    // this.setState({dimensions:{height:img.offsetHeight,width:img.offsetWidth}});
    //console.log(img.offsetHeight, " - ", img.offsetWidth);
  }
  const getBoxSize = (width, height) => {
    if (dataDoc.typeSign != 0) {
      return { width, height }
    }
    const TARGET_WIDTH_RATIO = currentWidthRatio
    const current_width_ratio = width / height
    if (current_width_ratio > 1 && current_width_ratio < 3) {
      var height_bounding_box = height
      var width_bounding_box = TARGET_WIDTH_RATIO * height_bounding_box
    }
    else if (current_width_ratio > TARGET_WIDTH_RATIO) {
      var width_bounding_box = TARGET_WIDTH_RATIO * height
      if (width_bounding_box >= width) {
        var height_bounding_box = width / 3
        width_bounding_box = TARGET_WIDTH_RATIO * height
      }
      else {
        var height_bounding_box = width / TARGET_WIDTH_RATIO
        width_bounding_box = width
      }
    }
    
    else if (current_width_ratio <= 1) {
      var width_bounding_box = height * TARGET_WIDTH_RATIO
      var height_bounding_box = height
    }
    else {
      var width_bounding_box = width
      var height_bounding_box = height
    }
    return {
      width: width_bounding_box,
      height: height_bounding_box
    }
  }

  

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <Row gutter={[24, 3]}>
                    <Col span={24}>
                      <h1 className="text-xl md:text-2xl font-medium">
                        {dataDoc.doc_name}
                      </h1>
                    </Col>
                    {dataDoc.typeSign == 0 && (
                      <Col>
                        <Row gutter={[6, 24]}>
                          <Col>
                            <SignWithOtherIcon />
                          </Col>
                          <Col>
                            <p className="text-xs text-blue-700">{t("Self Sign")}</p>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                className="bg-blue-700 rounded-md md:w-40 h-10 font-medium tracking-tight"
                type="primary"
                disabled={! totalStamp > 0}
                onClick={() => setActiveLocation(true)}
              >
                {t("Submit")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="relative">
            <div className="w-full bg-gray-600 rounded-t-md">
              <Row justify="space-between" align="middle">
                <Col span={16} xs={24} md={16} className="flex justify-center">
                  <p className="text-center text-gray-300 text-sm md:text-base p-2">
                     { totalStamp > 0 ?  `${t("You Have Added")} [${totalStamp}] ${t(dataDoc.typeSign == 0 ? "Signature": "Tera")}!` : t(dataDoc.typeSign == 0 ? 'Please Add Your Signature': 'Please Add Your Tera') }
                  </p>
                </Col>
                <Col span={8} xs={24} md={8}>
                  <Row
                    className="pr-4 pb-2 md:pb-0"
                    gutter={[24, 24]}
                    justify="center"
                    align="middle"
                  >
                    {dataDoc.typeSign == 0 && (
                      <Col>
                        <div className="flex flex-row bg-blue-400 text-white rounded-full px-2 py-1 justify-center items-center">
                          <SignIcon />
                          <Divider className="h-4 bg-white" type="vertical" />
                          <p className="font-medium">{numberCommas(totalMaterai.total)}</p>
                        </div>
                      </Col>
                    )}
                    {dataDoc.typeSign != 0 && (
                    <Col>
                      <div className="flex flex-row bg-blue-400 text-white rounded-full px-2 py-1 justify-center items-center">
                        <CapIcon />
                        <Divider className="h-4 bg-white" type="vertical" />
                        <p className="font-medium">{numberCommas(totalMaterai.total)}</p>
                      </div>
                    </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
            <div className={` space-y-4 justify-end items-end fixed mt-8 right-10 md:right-20 lg:right-32 z-50 flex flex-col }`}>
              { openSign === true ?
                <div className={`relative rounded-full top-0 ${!done ? 'cursor-pointer' : null}`} onClick={() => !done ? setOpenSign(false) : null}>
                  <img src={CloseIcon} alt="" />
                </div> : null
              }
              <div
                className="flex flex-col text-white justify-center items-center duration-700   transition"
                // onClick={() => (imgParaf === null || imgSign === null ? null : ( !done ? setOpenSign(true) : null) ) }
                onClick={() => !done ? setOpenSign(true) : null}
              >
                <Fade>
                    <div className={`${openSign > 0 ? "hidden" : "flex" } flex-row bg-blue-400 hover:bg-blue-300 rounded-full p-2 cursor-pointer justify-center items-center`}>
                      <img
                        className="transition duration-500 ease-in-out"
                        src={(dataDoc.typeSign != 0) ? CapSvg : SignSvg}
                        alt="qr-icon"
                        width="35px"
                      />
                    </div>
                </Fade>
                <Fade>
                <div onClick={() => !done ? handleAddSignature(totalSignature.length) : null} style={{background : '#f4f4f4' , minWidth : '100%'}} className={`${(openSign > 0 && (dataDoc.typeSign != 1)) ? "flex" : "hidden" } flex-row items-center p-2 rounded-t-lg ${!done ? 'cursor-pointer' : ''}`}>
                      <div 
                        className={`rounded-full bg-blue-400 mr-4 w-8 h-8 flex items-center justify-center`}>
                        <img
                          className="transition duration-500 ease-in-out" 
                          src={SignSvg}
                          alt="qr-icon"
                          width="24px"
                        />
                      </div>
                      <span className={`${!done ? 'text-blue-400' : 'text-gray-400' }  text-xl font-medium `}>{t("Signature")}</span>
                    </div>
                    
                </Fade>
                <Divider className="my-0" />
              </div>
              <Fade>
                <div 
                   onClick={() => !done ? handleAddSignature(totalSignature.length , true) : null }
                   style={{minWidth : '100%' , background : '#f4f4f4' , marginTop : 0 , marginBottom : 0}} 
                   className={`${ (openSign > 0 && (dataDoc.typeSign != 1)) ? "flex" : "hidden"} flex-row items-center p-2 bg-green-500 ${!done ? 'cursor-pointer' : ''} my-0`}>
                  <div className="rounded-full bg-blue-400 mr-4 w-8 h-8 flex items-center justify-center">
                    <img
                      className="transition duration-500 ease-in-out" 
                      src={SignSvg}
                      alt="qr-icon"
                      width="24px"
                    />
                  </div>
                  <span className={`${!done ? 'text-blue-400' : 'text-gray-400' }  text-xl font-medium `}>{t("Paraf")}</span>
                </div>
              </Fade>
              <Divider style={{marginTop : 0 , marginBottom : 0}} className="my-0" />
              <Fade>
                <div 
                   onClick={() => !done ? handleAddSignature(totalSignature.length , false, true) : null }
                   style={{minWidth : '100%' , background : '#f4f4f4' , marginTop : 0 , marginBottom : 0}} 
                   className={`${ (openSign > 0 && (dataDoc.typeSign != 0)) ? "flex" : "hidden"} flex-row items-center p-2 bg-green-500 ${!done ? 'cursor-pointer' : ''} my-0`}>
                  <div className="rounded-full bg-blue-400 mr-4 w-8 h-8 flex items-center justify-center">
                    <img
                      className="transition duration-500 ease-in-out" 
                      src={CapSvg}
                      alt="qr-icon"
                      width="24px"
                    />
                  </div>
                  <span className={`${!done ? 'text-blue-400' : 'text-gray-400' }  text-xl font-medium `}>{t("Tera")}</span>
                </div>
              </Fade>
              <Divider style={{marginTop : 0 , marginBottom : 0}} className="my-0" />
              <Fade>
                <div
                   onClick={handleSave} 
                   style={{minWidth : '100%' , background : '#f4f4f4' , marginTop : 0 , marginBottom : 0}} 
                   className={`${ totalSignature.length > 0 ? "flex" : "hidden"} flex-row items-center p-2 bg-green-500 cursor-pointer my-0`}>
                  <div className="rounded-full bg-green-400 mr-4 w-8 h-8 flex items-center justify-center">
                    <img
                      className="transition duration-500 ease-in-out" 
                      src={DoneIcon}
                      alt="qr-icon"
                      width="20px"
                    />
                  </div>
                  <span className="text-green-400 text-xl font-medium">{t("Done")}</span>
                </div>
              </Fade>
              <Divider style={{marginTop : 0 , marginBottom : 0}} className="my-0" />
              <Fade>
                <div
                   onClick={handleReset}
                   style={{minWidth : '100%' , background : '#f4f4f4' , marginTop : 0 , marginBottom : 0}} 
                   className={`${ openSign > 0 ? "flex" : "hidden"} flex-row items-center p-2 bg-green-500 cursor-pointer `}>
                  <div className="rounded-full bg-red-500 mr-4 w-8 h-8 flex items-center justify-center">
                    <img
                      className="transition duration-500 ease-in-out" 
                      src={ResetIcon}
                      alt="qr-icon"
                      width="20px"
                    />
                  </div>
                  <span className="text-red-500 text-xl font-medium">{t("Reset")}</span>
                </div>
              </Fade>
              <Divider style={{marginTop : 0 , marginBottom : 0}} className="my-0" />
              {dataDoc.typeSign == 0 && (
                <Fade>
                  <div 
                    onClick={() => history.push('/sign-management/edit-signature')}
                    style={{minWidth : '100%' , background : '#f4f4f4' , marginTop : 0 , marginBottom : 0}} 
                    className={`${ openSign > 0 ? "flex" : "hidden"} flex-row items-center p-2 rounded-b-lg bg-green-500 cursor-pointer `}>
                    <div className="rounded-full bg-gray-300 mr-4 w-8 h-8 flex items-center justify-center">
                      <img
                        className="transition duration-500 ease-in-out" 
                        src={SettingIcon}
                        alt="qr-icon"
                        width="20px"
                      />
                    </div>
                    <span className="text-gray-500 text-sm font-medium">{t("Edit Signature")}</span>
                  </div>
               </Fade>
              )}
            </div>
            <div
              className="bg-gray-300 rounded-b-md overflow-auto custom-scrollbar shadow-md pb-10"
              style={{ height: "80vh" }}
            >
              <div style={{background : '#dbdbdb'}} className="flex justify-center items-center p-8">
                <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page /**renderMode="svg"*/ pageNumber={pageNumber}>
                    {totalSignature.map((_, index) => {
                      return (
                        <Draggable
                          key={index}
                          defaultPosition={deltaPosition}
                          onStop={(e, position) => handleStop(e, position)}
                          bounds="parent"
                                              
                        >
                          
                          {/* <Resizable
                            height={sizing.height}
                            width={sizing.width}
                            onResize={handleResize}
                          > */}
                            <div
                              className={`relative border-dashed border-2 border-gray-500 px- ${
                                moveable ? "cursor-move" : "cursor-pointer"
                              }`}
                              // style={{width: size.x, height: size.y}}
                              style={getBoxSize(size.x, size.y)}
                              onDoubleClick={handleMovable}
                              
                            >
                              <div
                                className="absolute -top-3 -right-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                onClick={() => handleCloseSignature(index)}
                              >
                                <CloseOutlined className="font-medium" />
                              </div>
                              <div style={{WebkitUserDrag: "none", height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <img 
                                  className="drag-none"
                                  style={{height : size.y , width : size.x}} 
                                  onLoad={onImgLoad}
                                  src={ isTera ? TeraImg : isParaf ? imgParaf: imgSign} 
                                  alt="" 
                                />
                                 
                              </div>
                              <button 
                                className="absolute -top-3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                type="button" onMouseDown={handler2}>
                                  <ArrowsAltOutlined style = {{transform: 'rotate(90deg)' }} className="font-medium" />
                              </button>
                              {dataDoc.typeSign == 0 && (
                                <button 
                                  className="absolute top-1/3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                  type="button" onMouseDown={handler3}>
                                    <ArrowsAltOutlined style = {{transform: 'rotate(45deg)' }} className="font-medium" />
                                </button>
                              )}
                              <button 
                                className="absolute -bottom-3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                type="button" onMouseDown={handler0}>
                                  <ArrowsAltOutlined className="font-medium" />
                              </button>
                              <button 
                                className="absolute -bottom-3 -right-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                type="button" onMouseDown={handler1}>
                                  <ArrowsAltOutlined style = {{transform: 'rotate(90deg)' }} className="font-medium" />
                              </button>
                              
                            </div>
                          {/* </Resizable> */}
                        </Draggable>
                      );
                    })}
                  </Page>
                </Document>
                <div className="flex flex-row justify-center items-center space-x-2 bg-gray-100 shadow-md absolute bottom-5 right-5 p-2 rounded-md">
                  <Select
                    value={pageNumber}
                    size="small"
                    onChange={handleChangePage}
                  >
                    {Array.from(new Array(numPages), (_, index) => (
                      <Option value={index + 1}>{index + 1}</Option>
                    ))}
                  </Select>
                  <p>{`${t("from")} ${numPages} ${t("pages")}`}</p>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Modal
              type="warning"
              visible={previewModal}
              onCancel={() => {
                setPreview(false)
                setOtpValue("")
              }}
              title={t("Are you sure?")}
              subtitle={ !otpPreview ? 
                  t(`After the data has been submit, you cannot change the data. Please make sure the data is correct.`) 
                  : 
                  t(`Please make sure your data is correct. OTP Code automatic has sent to your email, let’s check!`)
                }
            >
              { !otpPreview ? 
              <>
                <div style={{marginTop : 40}} className="w-full mt-10">
                  <div className="flex justify-between mb-5">
                      <span className="text-gray-500 tracking-tight">
                        {t("Document Name")}
                      </span>
                      <span className="font-medium tracking-tight">
                        {dataDoc?.doc_name}
                      </span>
                  </div>
                  {dataDoc.typeSign == 0 && (
                    <div className="flex justify-between mb-5 tracking-tight">
                        <span className="text-gray-500 tracking-tight">
                          {t("Sign Category")}
                        </span>
                        <span className="font-medium tracking-tight">
                          {t("Self Sign")}
                        </span>
                    </div>
                  )}
                {dataDoc.typeSign == 0 && (
                  <div className="flex justify-between mb-5 tracking-tight">
                      <span className="text-gray-500 tracking-tight">
                        {t("Signing Type")}
                      </span>
                      <span className="font-medium tracking-tight">
                        {t("Personal")}
                      </span>
                  </div>
                )}
                <div className="flex justify-between mb-4 tracking-tight">
                    <span className="text-gray-500 tracking-tight">
                      {t(dataDoc.typeSign == 0 ? "Total Signature": "Total Tera")}
                    </span>
                    <span className="font-medium tracking-tight">
                      {totalStamp}
                    </span>
                </div>
                <Row>
                  <Col span={24}>
                    <span className="text-gray-500 tracking-tight mb-2">
                      {t("Reason")}
                    </span>
                    
                    <TextArea showCount maxLength={100} className="mt-2" onChange={(data) => setReason(data.target.value)} disabled={isSubmit} name="note" rows={4} />
                    
                  </Col>
                </Row>
                {/* <div className="flex justify-between mb-2">
                    <span>
                      Total Stamp
                    </span>
                    <span>
                      8
                    </span>
                </div> */}
                {/* <Divider className="mt-2 mb-8" /> */}

                <Row style={{marginTop : 24}} gutter={[12 , 0]}>
                  <Col span={12}>
                    <Button
                      ghost
                      onClick={() => {
                        setPreview(!previewModal)
                        setOtpValue("")
                        setOtpPreview(otpPreview)
                      }}
                      className=" w-full h-10 font-medium tracking-tight"
                      disabled={isSubmit}
                    >
                      <span>{t("Back")}</span>
                    </Button>
                  </Col>
                  <Col span={12}>
                      <Button 
                        className="w-full h-10 font-medium tracking-tight"
                        type="primary"
                        onClick={() => handlePreviewOtp(dataDoc?.doc_id)}
                        disabled={isSubmit}
                      >
                        <span>{t("Next")}</span>
                      </Button>
                  </Col>
                </Row>
                </div> 
                <Spinner loading={isSubmit} />
              </>
              : 
              <>
                <div className="flex flex-col space-y-4">
                  <div className="flex flex-row justify-between">
                    <p>{t("OTP Code")}</p>
                    <Countdown date={countdownValue} renderer={renderer} />
                  </div>
                  <OtpInput
                    value={otpValue}
                    onChange={(otp) => setOtpValue(otp)}
                    numInputs={6}
                    isInputNum
                    className="text-gray-800 mb-4"
                    containerStyle={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    inputStyle={{
                      width: "3rem",
                      height: "3rem",
                      fontWeight: "bold",
                      border: "2px solid #DBDBDB",
                      borderRadius: 4,
                      color: "#000000",
                    }}
                  />
                  <Form onFinish={handleSubmit} autoComplete={false}>
                    {/* <div>
                      <p>Note (Optional)</p>
                      <Form.Item name="note">
                        <TextArea onChange={(data) => setReason(data.target.value)} disabled={isSubmit} name="note" rows={4} />
                      </Form.Item>
                    </div> */}
                    <Form.Item>
                      <div className="flex flex-row space-x-4">
                        <Button
                          onClick={() => {
                            setPreview(false)
                            setOtpValue("")
                            setOtpPreview(!otpPreview)
                          }}
                          className=" w-full h-10 font-medium tracking-tight"
                          ghost
                          type="secondary"
                          disabled={isSubmit}
                        >
                          {t("Back")}
                        </Button>
                        <Button
                          disabled={otpValue?.length < 6 || isSubmit}
                          className="w-full h-10 font-medium tracking-tight"
                          type="primary"
                          htmlType="submit"
                        >
                          {t("Submit")}
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                  <p className="text-center">
                    {t("But, if get a problem you can")}
                    <span
                      onClick={handleResendOtp}
                      type="text"
                      disabled={isResendOtp}
                      className="text-blue-500 hover:text-blue-400 px-1 cursor-pointer hover:underline duration-500"
                    >
                    {t("Resend")}
                    </span>
                    {t("the otp code again")}
                  </p>
                  <Divider />
                  <div className="text-center">
                    <p className="text-gray-500 text-xs">
                      {t("By pressing the ‘Submit’ button means that you have agreed to the")}
                    </p>
                    <p className="text-blue-500 text-xs">{t("Terms & Conditions")}</p>
                  </div>
                </div>
                <Spinner loading={isSubmit} />
              </>
              }

          </Modal>
          <Modal
            visible={successModal}
            title={t("Submit Success")}
            subtitle={t("The document has been signed! Now you can view document detail or continue browsing.")}
            onCancel={() => setsuccessModal(false)}
            type="success"
          >
            <div className="flex flex-row space-x-4 gap-3">
              <Button
                className="w-full h-11 tracking-tight"
                type="secondary"
                onClick={() => {
                  let dataDoc = {sign_id : docSign.sign_id , doc_id : docSign?.doc_id  }
                  history.push(`sign-detail/${docSign.doc_id}` , {dataDoc})
                }}
              >
               {t("Detail Document")}
              </Button>

              <Button
                type="primary"
                className="w-full h-11 tracking-tight"
                onClick={() => history.replace('/sign-management')}
              >
                {t("Done")}
              </Button>
            </div>
          </Modal>
          <Modal
              visible={totalMaterai === 0 }
              type="warning"
              title={t("Your Quota Not Enough")}
              subtitle={t("Your e-signature quota is 0, you can not add sign to this document")}
          >
            <div className="flex justify-center">
              <Button onClick={() => history.replace('/sign-management')} ghost className="mt-9 mb-9 h-10 rounded-md w-52 tracking-tight font-medium">{t("Back")}</Button>
            </div>
          </Modal>
          <Modal
              visible={activeLocation}
              type="warning"
              title={t("Confirmation Location")}
              subtitle={t("For more accurate data certificate in document, turn on device location.")}
              onCancel={() => setActiveLocation(false)}
          >
            <div className="flex justify-center">
              <Button onClick={() => setActiveLocation(false)} ghost type="primary" className="mt-9 mb-9 h-10 rounded-md w-52 tracking-tight font-medium mr-4">{t("No")}</Button>
              <Button onClick={() => getLocation()} type="primary" className="mt-9 mb-9 h-10 rounded-md w-52 tracking-tight font-medium">{t("Yes")}</Button>
            </div>
          </Modal>
          
          <ModalAntd
            footer={false} 
            visible={isLocationBlocked}
            width={1050}
            onCancel={() => setIsLocationBlocked(false)}
            >
            <div className='mb-6 pt-2 pb-2'>
              <div className="mb-6">
                <h4 className="text-xl font-semibold text-gray-600 mb-5">{t("Location Not Found")}</h4>
                <hr />
              </div>
              <p className='font-medium text-base text-left mt-2 text-gray-600'>{t("Your location was not found. You need to enable GPS permission to be able to Stamp documents. You can do some of the steps below. !")}</p>
              <div onClick={() => setGuideModal( prev => ({...prev , guide1 : !prev.guide1}))} className='mt-5 cursor-pointer'>
                <div className="flex justify-between items-center">
                  <p className='text-xl font-bold'>{t("Access With Google Chrome")}</p>
                  <img width={18} height={15} src={ guideModal.guide1 === true ? arrowUp : arrowBtm } alt="" />
                </div>
                {
                  guideModal.guide1 &&
                  <div className='mt-2'>
                    <p className='text-black font-medium text-base'>{t("1. Logo location on the searchbar")}</p>
                    <p className='text-black font-medium text-base'>{t("2. Change the option to “Allow this site access your location”")}</p>
                    <div className="mt-2 flex items-center">
                      <img src={google1} alt="" />
                      <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                      <img src={google2} alt="" />
                    </div> 
                  </div> 
                }
                <hr className="mt-4 h-1" />
              </div>
              <div onClick={() => setGuideModal( prev => ({...prev , guide2 : !prev.guide2}))} className='mt-2 cursor-pointer'>
                <div className="flex justify-between items-center">
                  <p className='text-xl font-bold'>{t("Access With Safari")}</p>
                  <img width={18} height={15} src={ guideModal.guide2 === true ? arrowUp : arrowBtm } alt="" />
                </div>
                {
                  guideModal.guide2 && 
                  <div className='mt-2'>
                    <p className='text-black font-medium text-base'>{t("1. Safari menu")}</p>
                    <p className='text-black font-medium text-base'>{t("2. Settings for [domain name, Co: demo.vas.id]")} </p>
                    <p className='text-black font-medium text-base'>{t("3. Deactivate the “Enable content blockers” checkbox")}</p>
                    <div className="mt-2 flex items-center">
                      <img width={225} height={215} src={safari1} alt="" />
                      <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                      <img src={safari2} alt="" />
                      <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                      <img src={safari3} alt="" />
                    </div> 
                  </div>
                }
                <hr className="mt-4 h-1" />
              </div>
              <div onClick={() => setGuideModal( prev => ({...prev , guide3 : !prev.guide3}))} className='mt-2 cursor-pointer'>
                <div className="flex justify-between items-center">
                  <p className='text-xl font-bold'>{t("Access With Firefox")}</p>
                  <img width={18} height={15} src={ guideModal.guide3 === true ? arrowUp : arrowBtm } alt="" />
                </div>
                {
                  guideModal.guide3 && 
                  <div className='mt-2'>
                    <p className='text-black font-medium text-base'>{t("1. Logo location on the searchbar")}</p>
                    <p className='text-black font-medium text-base'>{t("2. Delete the option “Block temporarily”")}</p>
                    <div className="mt-2 flex items-center">
                      <img src={firefox1} alt="" />
                      <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                      <img src={firefox2} alt="" />
                    </div> 
                  </div>
                }
              </div>
            </div>
            <div className="w-full">
              <hr className='mb-4' />
              <div className="flex justify-center">
              <Button 
                className="bg-blue-700 rounded-md text-white w-40"
                onClick={() => setIsLocationBlocked(false)}
              > 
                {t("Close")} 
              </Button>
              </div>
            </div>
          </ModalAntd>
      </Row>
      <Spinner loading={loading} />
    </>
  );
}