import React from "react";
import { Button } from "antd";

export default function ButtonPrimary(props) {
  const className = props.className ? props.className : "";
  const classNameGhost =
    "rounded-md bg-transparent hover:bg-transparent border-1 border-blue-700 hover:border-blue-500 text-blue-700 hover:text-blue-500 focus:bg-transparent focus:text-blue-700 focus:border-blue-700 ";
  const classNameNonGhost =
    "rounded-md bg-blue-700 hover:bg-blue-500 focus:bg-blue-600 ";
  return (
    <Button
      {...props}
      className={(props.ghost ? classNameGhost : classNameNonGhost) + className}
      type="primary"
      icon={false}
      ghost={false}
    >
      <div className="flex items-center justify-center space-x-2">
        {props.icon}
        {props.children && <p>{props.children}</p>}
      </div>
    </Button>
  );
}
