import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Form, Input, message, Row } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import { useEditUserProfileMutation } from "../../config/features/user";
import { REGEXES } from "../../constant";
import { errorMessage } from "../../utils";
import { useTranslation } from "react-i18next";

export default function EditProfile() {
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [editUser, { isSuccess, isLoading, error, isError }] =
    useEditUserProfileMutation();
  const [isModalSuccess, setIsModalSuccess] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setIsModalSuccess(true);
    }
    if (isError) {
      errorMessage(error);
    }
  }, [isSuccess, error, isError, history]);

  useEffect(() => {
    form.setFieldsValue(location.state);
  }, [location, form]);

  async function handleSubmit(values) {
    try {
      values.status = 1;
      editUser(values);
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleDone() {
    setIsModalSuccess(false);
    history.push("/profile");
  }

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.push("/profile")}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      {t("Change Password")}
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    className="md:w-40"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {t("Update")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Row gutter={[24, 0]}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("Current Password")}
                        name="old_password"
                        rules={[
                          {
                            required: true,
                            message: t("Please input current password!"),
                          },
                        ]}
                      >
                        <Input.Password placeholder={t("Enter password")} />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Divider />
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("New Password")}
                        name="new_password"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: t("Please input new password!"),
                          },
                          () => ({
                            validator(_, value) {
                              if (
                                value.match(
                                  REGEXES.password
                                )
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                 t( "Password needs to be at least 8 character, 1 uppercase, 1 special character!")
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder={t("Enter password")} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("Confirmation Password")}
                        name="password_confirmation"
                        dependencies={["new_password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: t("Please confirm your password!"),
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("new_password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  t("The two passwords that you entered do not match!")
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder={t("Enter password confirmation" )}/>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      <Modal
        title={t("Updated Success!")}
        subtitle={t("Changes have been saved! Now you can continue browsing.")}
        visible={isModalSuccess}
        closable={false}
      >
        <div className="text-center">
          <Button onClick={handleDone} className="w-32" type="primary">
            {t("Done")}
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
