import * as FileSaver from "file-saver";
const XLSX = require('xlsx');
const pdfMake = require('pdfmake');

export const exportPdf = (apiData, fileName) => {
  const fileType = "application/pdf";
  const fileExtension = ".pdf";
  const data = new Blob([apiData], { type: fileType });
  FileSaver.saveAs(data, fileName ? fileName : "Document" + fileExtension);
};

export const exportPdfFromExcel = (apiData, fileName) => {
  const convert = () => {
    const workbook = XLSX.read(apiData, { type: "buffer" })
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    // Define PDF content
    const pdfContent = {
      content: [
        {
          table: {
            headerRows: 1,
            widths: Array(data[0].length).fill('*'),
            body: data
          }
        }
      ]
    };

    // Create PDF
    const pdfDoc = pdfMake.createPdf(pdfContent);
    // console.log(pdfDoc);

    // Generate PDF as buffer
    return new Promise((resolve, reject) => {
      pdfDoc.getBuffer((buffer) => {
        console.log(buffer);
        resolve(buffer);
      });
    });
  }
  convert().then((pdfBuffer) => {
    // const fileType = "application/pdf";
    // const fileExtension = ".pdf";
    // const data = new Blob([pdfBuffer], { type: fileType });
    // FileSaver.saveAs(data, fileName ? fileName : "Document" + fileExtension);
    console.log(pdfBuffer);
  }).catch(err => {
    console.log(err);
  })
}