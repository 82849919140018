import { DownOutlined, EyeFilled, LeftOutlined, UpOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Divider,
  message,
  Row,
  Skeleton,
  Switch,
  Table,
  Menu,
  Select,
  Form,
  Input,
  DatePicker,
} from "antd";
import clsx from "clsx";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Document, Page } from "react-pdf";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Button, Modal, Spinner, NumericInput } from "../../components";
import { useFetchDetailContractQuery, useExtendContractMutation } from "../../config/features";
import { CONTRACT_DOC_STATUS, CONTRACT_STATUS, MODULE_LIST } from "../../constant";
import { errorMessage, getBlob, getProductsTotalPrice, numberCommas } from "../../utils";

const { Option } = Select;
const { TextArea } = Input;

export default function CoopAgreementExtend() {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [form] = Form.useForm();
  
  const [extendData, setExtendData] = useState({});
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  
  const { data: dataDetailContract = {}, refetch: refetchDetailContract } = useFetchDetailContractQuery(id);

  const totalProductPrice = useMemo(() => {
    return getProductsTotalPrice(dataDetailContract?.products)
  }, [dataDetailContract?.products])

  const moduleListData = useMemo(() => {
    const module = dataDetailContract?.modules
    if(module){
      const keys = Object.keys(module)
      return keys
        .map((key) => module[key] && MODULE_LIST[key] ? ({ module_name: MODULE_LIST[key] }) : null)
        .filter(data => data)
    }
    
    return []
  }, [dataDetailContract?.modules])

  const [
    extendContract, 
    { 
      isSuccess: isSuccessExtendContract, 
      isError: isErrorExtendContract, 
      error: errorLoadingExtendContract, 
      isLoading: isLoadingExtendContract 
    }
  ] = useExtendContractMutation();

  const [isModalSuccess, setIsModalSuccess] = useState(false);

  const toggleModalSuccess = () => {
    setIsModalSuccess(prevState => !prevState);
  }

  const toggleModalConfirm = useCallback(() =>  {
    setIsModalConfirm(prevState => !prevState);
  }, [setIsModalConfirm])

  const disabledDateExpire = (current) => {
    return current && current < moment(dataDetailContract?.expired_at)
  }

  function disabledDateBefore(current) {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  }

  const handleExtendCoopAgreement = (values) => {
    setExtendData({
      ...values,
      products: dataDetailContract?.products,
      total: totalProductPrice
    })
    toggleModalConfirm()
  }

  const handleConfirm = () => {
    extendContract({
      contract_id: id,
      body: {
        extend_date: extendData?.contract_expire
      }
    })
  }

  useEffect(() => {
    if(dataDetailContract){
      form.setFieldsValue({ 
        contract_issue: dataDetailContract?.issued_at ? moment(dataDetailContract?.issued_at) : null
      })
    }
  }, [dataDetailContract, form])

  useEffect(() => {
    if (isSuccessExtendContract) {
      setIsModalSuccess(true);
      toggleModalConfirm()
      refetchDetailContract();
    }
    if (isErrorExtendContract) {
      errorMessage(errorLoadingExtendContract);
    }
  }, [
    isSuccessExtendContract, 
    errorLoadingExtendContract, 
    isErrorExtendContract, 
    refetchDetailContract,
    toggleModalConfirm
  ]);

  return(
    <>
      <Form
        autoComplete="off"
        layout="vertical"
        form={form}
        onFinish={handleExtendCoopAgreement}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.goBack()}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      Extend Cooperation Agreement
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 0]} align="middle">
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Date Issued"
                    name="contract_issue"
                    rules={[
                      {
                        required: true,
                        message: "Please input Date Issued!",
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      className="w-full"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Expired Date"
                    name="contract_expire"
                    rules={[
                      {
                        required: true,
                        message: "Please input Expired Date!",
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      className="w-full"
                      disabledDate={disabledDateBefore}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} md={24}>
            <Card className="rounded-md">
              <h2 className="text-gray-500 text-lg md:text-xl font-medium mb-2">
                Product List
              </h2>
              <Table 
                pagination={{ position: ["none"]}}
                columns={[
                  {
                    title: 'Product Name',
                    dataIndex: 'product_name',
                    key: 'product_name',
                    render: text => text,
                  },
                  {
                    title: 'Unit Price',
                    dataIndex: 'unit_price',
                    key: 'unit_price',
                    render: text => `Rp ${numberCommas(text)},-`,
                  },
                  {
                    title: 'Quantity',
                    dataIndex: 'quantity',
                    key: 'quantity',
                    render: text => numberCommas(text),
                  },
                  {
                    title: 'Quota',
                    dataIndex: 'quota',
                    key: 'quota',
                    render: text => numberCommas(text),
                  },
                  {
                    title: 'Total',
                    dataIndex: 'total',
                    key: 'total',
                    align: 'right',
                    render: text => `Rp ${numberCommas(text)},-`,
                  },
                ]} 
                dataSource={dataDetailContract?.products || []} 
              />
              <div className="flex flex-row justify-between p-4 text-blue-500">
                <p className="font-bold">Total</p>
                <p className="font-bold">
                  Rp {numberCommas(totalProductPrice)},-
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={24}>
            <Card className="rounded-md">
              <h2 className="text-gray-500 text-lg md:text-xl font-medium mb-2">
                Module List
              </h2>
              <Table 
                pagination={{ position: ["none"] }}
                columns={[
                  {
                    title: 'Module Name',
                    dataIndex: 'module_name',
                    key: 'module_name',
                    render: text => text,
                  },
                ]} 
                dataSource={moduleListData} 
              />
            </Card>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Are you sure?"
        subtitle="After the data has been added, you cannot change the data. Please make sure the data is correct."
        type="warning"
        visible={isModalConfirm}
        onCancel={toggleModalConfirm}
        width={700}
      >
        <div className="flex flex-col space-y-2">
          <Row>
            <Col span={6}>
              <span className="text-gray-500"> Date Issued </span>
            </Col>
            <Col span={12}>
              {extendData?.contract_issue ?
               moment(extendData?.contract_issue, moment.ISO_8601).format("DD/MM/YYYY") : 
               "--"}
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <span className="text-gray-500"> Expired Date </span>
            </Col>
            <Col span={12}>
              {extendData?.contract_expire ?
               moment(extendData?.contract_expire, moment.ISO_8601).format("DD/MM/YYYY") : 
               "--"}
            </Col>
          </Row>
          <Divider />
          <Table
            dataSource={extendData?.products}
            columns={[
              {
                title: "Product Name",
                dataIndex: "product_name",
                key: "product_name",
              },
              {
                title: "Unit Price",
                dataIndex: "unit_price",
                key: "unit_price",
                render: (unit_price) => {
                  return `Rp ${numberCommas(unit_price)},-`;
                },
              },
              {
                title: "Qty",
                dataIndex: "quantity",
                key: "quantity",
                render: (quantity) => {
                  return numberCommas(quantity);
                },
              },
              {
                title: "Quota",
                dataIndex: "quota",
                key: "quota",
                render: (quota) => {
                  return numberCommas(quota);
                },
              },
              {
                title: "Total",
                render: (record) => {
                  return `Rp ${numberCommas(
                    record.unit_price * record.quantity
                  )},-`;
                },
              },
            ]}
            pagination={false}
          />
          <div className="flex flex-row justify-between px-4">
            <p className="font-medium text-gray-800">Total</p>
            <p className="font-medium text-gray-800">
              Rp {numberCommas(extendData?.total)},-
            </p>
          </div>
          <Divider />
        </div>
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={toggleModalConfirm}
          >
            Back
          </Button>
          <Button className="w-full" type="primary" onClick={handleConfirm}>
            Extend
          </Button>
        </div>
      </Modal>
      <Modal
        type="success"
        title="Updated Success!"
        subtitle="Changes have been saved! Now you can continue browsing."
        visible={isModalSuccess}
        closable={false}
      >
        <div className="text-center">
          <Button 
            className="w-32" 
            type="primary" 
            onClick={() => history.replace(`/client-management/detail/${location?.state?.client}/coopAgreement`)}
          >
            Done
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoadingExtendContract} />
    </>
  )
}