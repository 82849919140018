import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Form, Input, message, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import { useFetchListUserQuery } from "../../config/features/user";
import { useEditPICMutation } from "../../config/features/platform";
import { errorMessage } from "../../utils";
const { Option } = Select;

export default function EditClient() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const [form] = Form.useForm();
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [payload, setPayload] = useState({});

  const [paramsTable, setParamsTable] = useState({
    row: 9999,
    page: 1,
    sort_by: "full_name",
    order: "asc",
    office: params?.id,
    status: 1,
  });

  const {
    data: dataListUser = [],
    error: errorUser,
    isError: isErrorUser,
    refetch,
  } = useFetchListUserQuery(paramsTable);

  const [editPic, { isSuccess, isLoading, error, isError }] =
    useEditPICMutation();

  useEffect(() => {
    if (isSuccess) {
      setIsModalSuccess(true);
      form.resetFields();
    }
    if (isError) {
      errorMessage(error);
    }
    if (isErrorUser) {
      errorMessage(errorUser);
    }
  }, [isSuccess, error, isError, errorUser, isErrorUser, form]);

  useEffect(() => {
    form.setFieldsValue({
      ...location.state,
      email_pic: location?.state?.user_id
    });
    refetch();
  }, [form, location, refetch]);

  const onFinish = (values) => {
    values.user_id = values?.email_pic;
    values.office_id = params?.id;
    showModal(values);
  };

  async function handleSubmit(values) {
    try {
      editPic(values);
    } catch (error) {
      message.error(error.message);
    }
  }

  const showModal = (values) => {
    setPayload(values);
    setIsModalConfirm(true);
  };

  const handleOk = () => {
    setIsModalConfirm(false);
    handleSubmit(payload);
  };

  const handleCancel = () => {
    setIsModalConfirm(false);
  };

  const handleDone = () => {
    setIsModalSuccess(false);
    history.goBack();
  };

  const onSearch = (value) => setParamsTable({ ...paramsTable, search: value });

  return (
    <>
      <Form
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.goBack()}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      Edit Client
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    className="md:w-40"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Update
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Row gutter={[24, 12]}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="First Name PIC"
                        name="first_name_pic"
                        rules={[
                          {
                            required: true,
                            message: "Please input first name!",
                          },
                        ]}
                      >
                        <Input disabled placeholder="Enter first name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Last Name PIC"
                        name="last_name_pic"
                        rules={[
                          {
                            required: false,
                            message: "Please input last name!",
                          },
                        ]}
                      >
                        <Input disabled placeholder="Enter last name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Phone Number PIC"
                        name="phone_pic"
                        rules={[
                          {
                            required: true,
                            message: "Please input phone PIC!",
                          },
                        ]}
                      >
                        <Input disabled placeholder="Enter phone PIC" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Email PIC"
                        name="email_pic"
                        rules={[
                          {
                            required: true,
                            message: "Please select email pic!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Not Selected"
                          defaultValue={2}
                          value={2}
                          onSearch={onSearch}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            const selected = dataListUser?.find(
                              (element) => element?.user_id === value
                            );
                            const firstName = selected?.full_name?.split(" ");
                            const lastName =
                              firstName?.length > 1 ? firstName?.pop() : "";
                            form.setFieldsValue({
                              first_name_pic: firstName?.join(" "),
                              last_name_pic: lastName,
                              phone_pic: selected?.phone,
                            });
                          }}
                        >
                          {dataListUser?.map((item, index) => {
                            return (
                              <Option key={index} value={item?.user_id}>
                                {item?.email}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Are you sure?"
        subtitle="Be careful in changing client data! If you make any changes to pic, it may change / lost your access to the system."
        type="warning"
        visible={isModalConfirm}
        onCancel={handleCancel}
      >
        <div className="flex flex-col space-y-2">
          <Divider />
        </div>
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={handleCancel}
          >
            Back
          </Button>
          <Button className="w-full" type="primary" onClick={handleOk}>
            Update
          </Button>
        </div>
      </Modal>
      <Modal
        title="Updated Success!"
        subtitle="Changes have been saved! Now you can continue browsing."
        type="success"
        visible={isModalSuccess}
        onCancel={() => setIsModalSuccess(false)}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={handleDone}>
            Done
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
