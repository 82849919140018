import {
  LeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Card, Col, Divider, Row, Input, Form, AutoComplete } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useFetchTagKeyQuery, useFetchTagValueQuery, useEditTagsMutation } from "../../config/features/tag";
import { errorMessage } from "../../utils";
import { Spinner, Button, Modal } from "../../components";
import { useTranslation } from "react-i18next";

export default function EditTag() {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [form] = Form.useForm();

  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [deletedTags, setDeletedTags] = useState([]);

  useEffect(() => {
    form.setFieldsValue({ tags: location?.state?.tags });
  }, [form, location]);

  const [editTags, { isSuccess, isError, error, isLoading }] = useEditTagsMutation();
  const { data: dataListTagKey } = useFetchTagKeyQuery();
  const { data: dataListTagValue } = useFetchTagValueQuery();

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }

    if (isSuccess) {
      setIsModalSuccess(true);
      form.resetFields();
    }
  }, [isError, error, isSuccess, form]);

  function handleSubmit() {
    toggleModalConfirm();
    const { tags } = form.getFieldsValue();
    editTags({
      body:
        deletedTags.length > 0 ? { tags: [...tags, ...deletedTags] } : { tags },
      doc_id: id,
      is_folder: location?.state?.is_folder ? 1 : "",
    });
  }

  function handleRemove(index) {
    const deletedTag = { ...form.getFieldValue("tags")[index] };

    if (deletedTag && deletedTag.id) {
      deletedTag.is_deleted = true;
    }

    setDeletedTags((prev) => [...prev, deletedTag]);
  }

  function toggleModalConfirm() {
    setIsModalConfirm(!isModalConfirm);
  }

  function toggleModalSuccess() {
    setIsModalSuccess(!isModalSuccess);
  }

  function handleDone() {
    toggleModalSuccess();
    history.goBack();
  }

  return (
    <>
      <Form form={form} autoComplete="off" onFinish={toggleModalConfirm} layout="vertical">
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() =>
                        history.push(`/document-management/detail/${id}`)
                      }
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      {t("Edit Tag")}
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button
                  className="bg-blue-700 justify-center px-8"
                  type="primary"
                  htmlType="submit"
                >
                  {t("Update")}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <p className="text-xs md:text-sm text-gray-400">
                    {t("You can edit the tags of all selected resources or add new tags to them.")}
                  </p>
                </Col>
                <Col span={24}>
                  <Divider />
                </Col>
                <Col xs={24}>
                  <Form.List name="tags">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Row key={key} gutter={[24, 12]} align="middle">
                            <Col md={10} lg={8}>
                              <Form.Item
                                {...restField}
                                label={t("Tag Key")}
                                name={[name, "tag_key"]}
                                fieldKey={[fieldKey, "tag_key"]}
                                rules={[
                                  {
                                    required: true,
                                    message: t("Please input your Tag Key!"),
                                  },
                                ]}
                              >
                                <AutoComplete 
                                  options={dataListTagKey?.map(item => ({ value: item.tag_key }))} 
                                  placeholder={t("Enter tag key" )}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={10} lg={8}>
                              <Form.Item
                                {...restField}
                                label={t("Tag Value (Optional)")}
                                name={[name, "tag_value"]}
                                fieldKey={[fieldKey, "tag_value"]}
                                rules={[
                                  {
                                    required: false,
                                    message: t("Please input your Tag Value!"),
                                  },
                                ]}
                              >
                                <AutoComplete
                                  options={dataListTagValue?.map(item => ({ value: item.tag_value }))} 
                                  placeholder={t("Enter tag value" )}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={1}>
                              <MinusCircleOutlined
                                onClick={() => {
                                  handleRemove(name);
                                  remove(name);
                                }}
                              />
                            </Col>
                          </Row>
                        ))}
                        <Form.Item>
                          <Button
                            type="secondary"
                            ghost
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            {t("Add Tag")}
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      <Spinner loading={isLoading} />
      <Modal
        type="warning"
        title={t("Are you sure?")}
        subtitle={t("Before the data has been updated, please make sure the data is correct.")}
        visible={isModalConfirm}
        onCancel={toggleModalConfirm}
      >
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={toggleModalConfirm}
          >
            {t("Back")}
          </Button>
          <Button className="w-full" type="primary" onClick={handleSubmit}>
            {t("Update")}
          </Button>
        </div>
      </Modal>
      <Modal
        title={t("Updated Success!")}
        subtitle={t("Changes have been saved! Now you can continue browsing.")}
        visible={isModalSuccess}
      >
        <div className="text-center">
          <Button onClick={handleDone} className="w-32" type="primary">
            {t("Done")}
          </Button>
        </div>
      </Modal>
    </>
  );
}
