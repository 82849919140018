import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { rootApi, access } from "../config";
import moment from "moment";

export const documentApi = createApi({
  reducerPath: "api/document",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi,
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      fetchListDocument: builder.query({
        query({
          page = 1,
          sort_by = "created_at",
          order = "desc",
          row = "10",
          search = "",
          office = "",
          stamp_status = "",
          stamped = "",
          is_signed = "",
          tera_stamped = "",
          is_draft = ""
        }) {
          return `/document?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}&office=${office}&stamped=${stamped}&stamp_status=${stamp_status}&is_signed=${is_signed}&tera_stamped=${tera_stamped}&is_draft=${is_draft}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchDetailDocument: builder.query({
        query({ id, is_folder = "" }) {
          return `/document/${id}?is_folder=${is_folder}`;
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      fetchActivityDocument: builder.query({
        query({
          page = 1,
          sort_by = "created_at",
          order = "desc",
          row = "10",
          search = "",
          date = moment(new Date(), moment.ISO_8601).format("YYYY-MM-DD"),
          doc_id = "",
          full_name = "",
          folder_id = "",
        }) {
          return `/document-log-activities?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}&date=${date}&doc_id=${doc_id}&folder_id=${folder_id}&full_name=${full_name}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchPopularDocument: builder.query({
        query({
          page = 1,
          sort_by = "created_at",
          order = "desc",
          row = "10",
          search = "",
          date = moment(new Date(), moment.ISO_8601).format("YYYY-MM-DD"),
          doc_id = "",
          office_id = "",
          office_name = "",
          full_name = "",
          created_at = "",
        }) {
          return `/document-log-activities?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}&date=${date}&doc_id=${doc_id}&office_id=${office_id}&office_name=${office_name}&full_name=${full_name}&create_at=${created_at}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      addDocument: builder.mutation({
        query: (body) => ({
          url: "/document",
          method: "POST",
          body,
        }),
      }),

      addDocumentBulk: builder.mutation({
        query: (body) => ({
          url: "/document-bulk",
          method: "POST",
          body,
        }),
      }),

      sendDocument: builder.mutation({
        query: (body) => ({
          url: "/sent-item",
          method: "POST",
          body,
        }),
      }),

      fetchSentDocument: builder.query({
        query({
          page = 1,
          sort_by = "created_at",
          order = "desc",
          row = 10,
          search = "",
          start = "",
          end = "",
        }) {
          return `/sent-items?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}&start=${start}&end=${end}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchReceivedDocument: builder.query({
        query({
          page = 1,
          sort_by = "created_at",
          order = "desc",
          row = 10,
          search = "",
          start = "",
          end = "",
        }) {
          return `/inbox?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}&start=${start}&end=${end}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchDetailReceived: builder.query({
        query(receivedId) {
          return `/inbox/${receivedId}`;
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      fetchDetailSent: builder.query({
        query(sentId) {
          return `/sent-item/${sentId}`;
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      fetchDocumentPreview: builder.query({
        query(id_doc) {
          return `/document/preview/${id_doc}`;
        },
        transformResponse: (response) => (response ? response.data : ""),
      }),

      downloadDocument: builder.query({
        query({ id, version }) {
          return `/document/download?id=${id}&version=${version}`;
        },
        transformResponse: (response) => (response ? response.data : ""),
      }),

      fetchListReportRekon: builder.query({
        query({
          page = 1,
          sort_by = "used_at",
          order = "desc",
          row = "10",
          search = "",
          office = "",
          start_date = "",
          end_date = ""
        }) {
          return `/report/rekonsiliasi?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}&office=${office}&startdate=${start_date}&enddate=${end_date}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      addReconsileFile: builder.mutation({
        query: (body) => ({
          url: "/report/rekonsiliasi",
          method: "POST",
          body,
        }),
      }),
      
      addUserReconStatus: builder.mutation({
        query: (body) => ({
          url: "/report/rekon/confirm-user",
          method: "POST",
          body,
        }),
      }),

      fetchListReportRekap: builder.query({
        query({
          page = 1,
          sort_by = "used_at",
          order = "desc",
          row = "10",
          office_id = "",
          monthDate = ""
        }) {
          return `/report/rekapitulasi?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&office_id=${office_id}&monthDate=${monthDate}`
        },
        transformResponse: (response) => (response ? response.data : []),
      }),
      fetchListReportRecap: builder.mutation({
        query({
          page = 1,
          sort_by = "used_at",
          order = "desc",
          row = "10",
          office_id = "",
          monthDate = ""
        }) {
          return `/report/rekapitulasi?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&office_id=${office_id}&monthDate=${monthDate}`
        },
        transformResponse: (response) => (response ? response : {}),
      }),
      fetchDetailReportRekap: builder.query({
        query({
          page = 1,
          sort_by = "used_at",
          order = "desc",
          row = "10",
          office_id = "",
          dept_id = "",
          monthDate = ""
        }) {
          return `/report/rekapitulasidetil?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&office_id=${office_id}&dept_id=${dept_id}&monthDate=${monthDate}`
        },
        transformResponse: (response) => (response ? response : {})
      }),
      fetchListRecordRekon: builder.query({
        query({
          page = 1,
          row = 10,
          status_rekon = '',
          startdate = '',
          enddate = '',
          rekonsile_code = '',
          office_id= '',
        }) {
          return `/record/rekonsiliasi?page=${page}&row=${row}&status_rekon=${status_rekon}&startdate=${startdate}&enddate=${enddate}&rekonsile_code=${rekonsile_code}&office_id=${office_id}`
        },
        transformResponse: (response) => (response ? response.data : []),
      }),
      fetchListDetailRecordRekon: builder.query({
        query({
          id = 1,
          page = 1,
          row = 10,
          search = '',
          status_recon = '',
          min_price = '',
          max_price = '',
        }) {
          return `/record/rekonsiliasi-detail/${id}?row=${row}&page=${page}&search=${search}&status_recon=${status_recon}&min_price=${min_price}&max_price=${max_price}`;
        },
        transformResponse: (response) => (response ? response.data : [])
      }),      

      fetchDetBulkSign: builder.query({
        query({ id, is_folder = "", is_sign="" }) {
          return `/document/${id}?is_folder=${is_folder}&is_sign=1`;
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      addAdminReconStatus: builder.mutation({
        query: (body) => ({
          url: "/report/rekon/confirm-admin",
          method: "POST",          
          body,
        }),
      }),
      fetchDocumentsByFolder: builder.query({
        query({
          folder_id,
          row = "",
          page =  ""
        }) {
          return `/document/documments-by-folder?folder_id=${folder_id}&row=${row}&page=${page}`
        },
        transformResponse: (response) => (response ? response.data : [])
      }),
      fetchDocumentsFolderVersions: builder.query({
        query({
          folder_id,
          row = '',
          page = '',
          version = ''
        }) {
          return `/document/folder/versions?folder_id=${folder_id}&row=${row}&page=${page}&version=${version}`
        },
        transformResponse: (response) => (response ? response.data : [])
      })
    };
  },
});

export const {
  useFetchListDocumentQuery,
  useFetchDetailDocumentQuery,
  useFetchActivityDocumentQuery,
  useFetcPopularDocumentQuery,
  useAddDocumentMutation,
  useSendDocumentMutation,
  useFetchSentDocumentQuery,
  useFetchReceivedDocumentQuery,
  useFetchDetailReceivedQuery,
  useFetchDetailSentQuery,
  useFetchDocumentPreviewQuery,
  useLazyFetchDocumentPreviewQuery,
  useLazyDownloadDocumentQuery,
  useAddDocumentBulkMutation,
  useFetchListReportRekonQuery,
  useAddReconsileFileMutation,
  useAddUserReconStatusMutation,
  useFetchListReportRekapQuery,
  useFetchListReportRecapMutation,
  useFetchDetailReportRekapQuery,
  useFetchListRecordRekonQuery,
  useFetchListDetailRecordRekonQuery,  
  useFetchDetBulkSignQuery,  
  useAddAdminReconStatusMutation,
  useFetchDocumentsByFolderQuery,
  useFetchDocumentsFolderVersionsQuery
} = documentApi;
