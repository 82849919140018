import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Col, Divider, message, Row, Select } from "antd";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
// import pdfJsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { Document, Page } from "react-pdf";
import { Resizable } from "react-resizable";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router-dom";
import { ReactComponent as AccountMultiplePlus } from "../../assets/icons/account-multiple-plus.svg";
import QrCodeSVG, {
  ReactComponent as QrCodeIcon,
} from "../../assets/icons/qrcode.svg";
import { ReactComponent as SignWithOther } from "../../assets/icons/sign-with-other.svg";
import SignSvg, {
  ReactComponent as SignIcon,
} from "../../assets/icons/sign.svg";
// pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker;
const { Option } = Select;

export default function DocumentPreview() {
  const history = useHistory();
  const [pdfFile, setPdfFile] = useState(null);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  let [activeDrags, setActiveDrags] = useState({});
  const [deltaPosition, setDeltaPosition] = useState({});
  const [sizing, setSizing] = useState({
    width: 180,
    height: 50,
  });
  let [totalSignature, setTotalSignature] = useState([]);
  const [moveable, setMoveable] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const url = "https://pdf-lib.js.org/assets/with_update_sections.pdf";
        const existingPdfBytes = await fetch(url).then((res) =>
          res.arrayBuffer()
        );
        setPdfFile(existingPdfBytes);
      } catch (error) {
        message.error(error.message);
      }
    }
    fetchData();
  }, []);

  //   const nextPage = () =>
  //     pdfRef && currentPage < pdfRef.numPages && setCurrentPage(currentPage + 1);

  //   const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleStart = (e) => {
    setActiveDrags({ ...activeDrags, [e]: ++activeDrags });
  };

  const handleStop = (e) => {
    setActiveDrags({ ...activeDrags, [e]: --activeDrags });
  };

  const onControlledDrag = (e, position, index) => {
    const { x, y } = position;
    setDeltaPosition({ ...deltaPosition, [index]: { x, y } });
  };

  const handleResize = (event, { element, size, handle }) => {
    setSizing({ ...sizing, width: size.width, height: size.height });
  };

  function handleAddSignature(index) {
    setDeltaPosition({
      ...deltaPosition,
      [index]: { x: 400, y: -750 },
    });
    setTotalSignature([...totalSignature, index]);
  }

  function handleCloseSignature(index) {
    //delete total signature by value
    const duplicateTotalSignature = [...totalSignature];
    for (var i = 0; i < duplicateTotalSignature.length; i++) {
      if (duplicateTotalSignature[i] === index) {
        duplicateTotalSignature.splice(i, 1);
      }
      setTotalSignature(duplicateTotalSignature);
    }

    // delete delta position of signature
    const duplicateDeltaPosition = { ...deltaPosition };
    delete duplicateDeltaPosition[index];
    setDeltaPosition(duplicateDeltaPosition);
  }

  function handleChangePage(e) {
    setPageNumber(e);
  }

  async function modifyPdf(existingPdfBytes) {
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    // const pngImage = await pdfDoc.embedPng("./images/test_ttd.png");

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    // const lastPage = pages[pages?.length - 1];
    // const { width, height } = firstPage.getSize();
    for (let i = 0; i < Object.keys(deltaPosition).length; i++) {
      const deltaPositionY =
        deltaPosition[i].y < 0
          ? Math.abs(deltaPosition[i].y) - 16 - 55 * i
          : -Math.abs(deltaPosition[i].y);
      const deltaPositionX = deltaPosition[i].x;

      firstPage.drawText("Rian Akbar Ferdiansyah", {
        x: deltaPositionX,
        y: deltaPositionY,
        size: 15,
        font: helveticaFont,
        color: rgb(0, 0, 0),
        // rotate: degrees(-45),
      });
    }

    // firstPage.drawImage(pngImage, {
    //   x: deltaPosition.x,
    //   y: deltaPosition.y,
    //   width: 100,
    //   height: 100,
    // });

    const pdfBytes = await pdfDoc.save();
    const arr = new Uint8Array(pdfBytes);
    setPdfFile(arr.buffer);
  }

  const handleSave = () => {
    // for (let i = 0; i < deltaPosition.length; i++) {
    // const deltaPositionX = deltaPosition[i].x;
    // const deltaPositionY =
    //   deltaPosition[i].y < 0
    //     ? Math.abs(deltaPosition[i].y) - 15
    //     : -Math.abs(deltaPosition[i].y);

    modifyPdf(pdfFile);
    // }
    setTotalSignature([]);
  };

  function handleMovable() {
    setMoveable(!moveable);
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.push("/document-management")}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <Row gutter={[24, 3]}>
                    <Col span={24}>
                      <h1 className="text-xl md:text-2xl font-medium">
                        SK_Galang Pratama_193020
                      </h1>
                    </Col>
                    <Col>
                      <Row gutter={[6, 24]}>
                        <Col>
                          <SignWithOther />
                        </Col>
                        <Col>
                          <p className="text-xs text-blue-700">
                            Sign With Other
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                className="bg-blue-700 rounded-md md:w-40"
                type="primary"
                disabled
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="relative">
            <div className="w-full bg-gray-600 rounded-t-md">
              <Row gutter={[24, 12]} justify="space-between" align="middle">
                <Col xs={24} md={8}>
                  <div className="flex flex-row bg-blue-700 items-center justify-center rounded-t-md md:rounded-none md:rounded-tl-md pl-4 w-full cursor-pointer hover:bg-blue-600 transition duration-500">
                    <AccountMultiplePlus className="text-sm" />
                    <p className="text-center text-white p-3 text-sm">
                      No other signer
                    </p>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <p className="text-center text-gray-300 text-sm md:text-base">
                    Please Add You Signature
                  </p>
                </Col>
                <Col xs={24} md={8}>
                  <Row
                    className="pr-4 pb-2 md:pb-0"
                    gutter={[24, 24]}
                    justify="center"
                    align="middle"
                  >
                    <Col>
                      <div className="flex flex-row bg-blue-400 text-white rounded-full px-2 py-1 justify-center items-center">
                        <SignIcon />
                        <Divider className="h-4 bg-white" type="vertical" />
                        <p className="font-medium">17</p>
                      </div>
                    </Col>
                    <Col>
                      <div className="flex flex-row bg-yellow-400 text-white rounded-full px-2 py-1 justify-center items-center">
                        <QrCodeIcon />
                        <Divider className="h-4 bg-white" type="vertical" />
                        <p className="font-medium">5</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="flex flex-col space-y-4 justify-end items-end fixed mt-8 right-10 md:right-20 lg:right-32 z-50">
              <div
                className="flex bg-blue-400 hover:bg-blue-300 cursor-pointer text-white rounded-full p-1 justify-center items-center duration-700 transition"
                onClick={() => handleAddSignature(totalSignature.length)}
              >
                <Fade>
                  {totalSignature.length > 0 ? (
                    <p className="p-2 transition duration-500 ease-in-out">
                      Place More
                    </p>
                  ) : (
                    <img
                      className="transition duration-500 ease-in-out"
                      src={SignSvg}
                      alt="qr-icon"
                      width="40px"
                    />
                  )}
                </Fade>
              </div>
              <div className="flex bg-yellow-400 hover:bg-yellow-300 cursor-pointer text-white rounded-full p-1 justify-center items-center">
                <img src={QrCodeSVG} alt="qr-icon" width="40px" />
              </div>
              <Fade>
                <div
                  className={`cursor-pointer text-white rounded-full p-1 justify-center items-center h-12 w-12 transition duration-500 ease-in-out bg-green-400 hover:bg-green-300 ${
                    totalSignature.length > 0 ? "flex" : "hidden"
                  }`}
                  onClick={handleSave}
                >
                  <p className="font-semibold">Done</p>
                </div>
              </Fade>
            </div>
            <div
              className="bg-gray-300 rounded-b-md overflow-auto custom-scrollbar shadow-md pb-10"
              style={{ height: "80vh" }}
            >
              <div className="flex justify-center items-center p-8">
                <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber}>
                    {totalSignature.map((item, index) => {
                      return (
                        <Draggable
                          key={index}
                          // defaultPosition={{
                          //   x: 400 + index * 6,
                          //   y: -750 + -index * 2,
                          // }}
                          position={deltaPosition[index]}
                          onStart={moveable ? () => handleStart(index) : false}
                          onStop={() => handleStop(index)}
                          onDrag={(e, position) =>
                            onControlledDrag(e, position, index)
                          }
                          bounds="parent"
                        >
                          <Resizable
                            height={sizing.height}
                            width={sizing.width}
                            onResize={handleResize}
                          >
                            <div
                              className={`relative border-dashed border-2 border-gray-500 ${
                                moveable ? "cursor-move" : "cursor-pointer"
                              }`}
                              style={{
                                width: sizing.width,
                                height: sizing.height,
                              }}
                              // style={{
                              //   // width: "-moz-fit-content",
                              //   width: "fit-content",
                              // }}
                              onDoubleClick={handleMovable}
                            >
                              <div
                                className="absolute -top-3 -right-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                onClick={() => handleCloseSignature(index)}
                              >
                                <CloseOutlined className="font-medium" />
                              </div>
                              <p
                                style={{
                                  // fontSize:
                                  //   typeof sizing.width === "number"
                                  //     ? sizing.width / 8
                                  //     : 10,
                                  fontSize: 15,
                                }}
                              >
                                Rian Akbar Ferdiansyah + {item}
                              </p>
                            </div>
                          </Resizable>
                        </Draggable>
                      );
                    })}
                  </Page>
                </Document>
                <div className="flex flex-row justify-center items-center space-x-2 bg-gray-100 shadow-md absolute bottom-5 right-5 p-2 rounded-md">
                  <Select
                    value={pageNumber}
                    size="small"
                    onChange={handleChangePage}
                  >
                    {Array.from(new Array(numPages), (_, index) => (
                      <Option value={index + 1}>{index + 1}</Option>
                    ))}
                  </Select>
                  <p>{`dari ${numPages} halaman`}</p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
