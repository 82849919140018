import { FilterOutlined, PlusOutlined, RightOutlined, LeftOutlined, SettingOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Radio,
  Row,
  Select,
  DatePicker,
  Divider,
  Table,
  message,
  Upload,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as FilterOutline } from "../../assets/icons/filter-outline.svg";
import { useFetchListReportRekonQuery, useAddReconsileFileMutation } from "../../config/features/document";
import { useFetchListOfficeQuery } from "../../config/features/office";
import { errorMessage, exportExcel, exportPdf, getBlob, numberCommas } from "../../utils";
import { SiMicrosoftexcel } from "react-icons/si";
import Button from "../../components/Button";
import { Modal, Spinner } from "../../components";
import { useTranslation } from "react-i18next";
const { Search } = Input;
const { Option } = Select;

export default function RekonsileList() {
  const history = useHistory();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [paramsTable, setParamsTable] = useState({
    row: 10,
    page: 1,
    office: "",
    sort_by: "used_at",
    order: "desc",
    start_date: "",
    end_date: "",
  });
  const [visibleFilter, setVisibleFilter] = useState(false);

  const [visibleModalRekon, setVisibleModalRekon ] = useState(false);
  const [visibleList, setVisibleList ] = useState(false);
  const [fileList, setFileList] = useState([]);

  const {
    // data: dataListDocument = [],
    data: dataListRekonsilasi = [],
    isFetching,
    refetch,
    isError,
    error    
  } = useFetchListReportRekonQuery(paramsTable);
  
  const [addReconsileFile, { isSuccess, isLoading, errors, data, isErrors }] = useAddReconsileFileMutation();

  // useEffect(() => {
  //   refetch();
  // }, [refetch]);

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
  }, [isError, error]);

  useEffect(() => {
    if (isErrors) {
      toggleModalRekon();
      errorMessage(errors);
    }
  }, [isErrors, errors]);

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Nomor Seri",
      dataIndex: "serial_number",
      key: "serial_number",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Tanggal Stamping",
      dataIndex: "tanggal_stamping",
      key: "tanggal_stamping",
    },
  ];

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1,
        ...data[i],
      });
    }

    return datasets;
  }

  const onSearch = (value) => setParamsTable({ ...paramsTable, search: value });

  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }

  function handleVisibleList(){
    if(!visibleList){
      setVisibleList(true);
    }
  }

  function handleResetFilter() {
    setParamsTable({
      ...paramsTable,
      office: "",
      sort_by: "used_at",
      start_date: "",
      end_date: "",
      order: "desc",
    });
  }

  const propsUpload = {
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      
      const isLt5M = file.size / 1024 / 1024 < 50;
      if (!isLt5M) {
        message.error("File must smaller than 50MB!");
      } else {
        setFileList([file]);
      }
      return false;
    },
    fileList,
  };

  async function handleSubmit(val) {
    if(fileList.length < 0){
      message.error("Anda Belum Melakukan Upload Dokumen Rekonsiliasi !");
    }
    try {      
      const formData = new FormData();      
      formData.append("file", fileList[0]);
      formData.append("startdate", val.start_date);
      formData.append("enddate", val.end_date);
      formData.append("total", dataListRekonsilasi.length);
      addReconsileFile(formData);
    } catch (error) {
      message.error(error.message);
    }
  }

  const handleOk = () => {
    if(fileList.length < 0){
      message.error("Anda Belum Melakukan Upload Dokumen Rekonsiliasi !");
    } else {
      toggleModalRekon(false);
      handleSubmit(paramsTable);
    }    
  };

  const handleDone = () => {
    history.push(`/Rekonsiliasi`);
    // window.location.reload(false);
  };

  const menuFilter = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between" onClick={() => { (paramsTable.start_date && paramsTable.end_date) ? toggleModalRekon() : message.error("Anda belum memilih periode rekonsiliasi !");}}>
            <p className="text-gray-500 text-base font-medium" style={{fontSize:12}}>Proses Rekonsiliasi</p>           
          </div>
        </Menu.Item>   
        <Menu.Item>
          <div className="flex flex-row justify-between" onClick={handleClickExportExcel}>
            <p className="text-gray-500 text-base font-medium" style={{fontSize:12}}>Download Log Stamp System</p>           
          </div>
        </Menu.Item>               
      </Menu>
    );
  };


  function toggleModalRekon (){
    setVisibleModalRekon(!visibleModalRekon);
    if(visibleFilter){
      setVisibleFilter(!visibleFilter)
    }    
  }

  const handleClickExportExcel = async () => {
    const startEnd = (paramsTable.start_date && paramsTable.end_date) ? `&startdate=${paramsTable.start_date}&enddate=${paramsTable.end_date}` : ""
    const response = await getBlob(`/report/rekonsiliasi?export_format=excel${startEnd}`);
    exportExcel(response.data, `Data Rekonsiliasi ${paramsTable.start_date}`);
  }



  return (    
    <>
    <Row gutter={[24, 24]}>      
      <Col span={24}>
        <Row gutter={[24, 24]} justify="space-between">
          <Col>
            <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div className="group cursor-pointer" onClick={() => history.goBack()}>
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    {t('rekon')}
                  </h1>
                </Col>
              </Row>
          </Col>          
        </Row>
      </Col>
      <Col span={24}>
      <Card className="rounded-md" style={{marginBottom:10}}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col span={24}>
              <Row gutter={[24, 24]}>
                <Col>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  {t("filterRekon")}
                  </h3>                  
                </Col>                              
              </Row>              
              <Row gutter={[24, 24]} justify="space-between" style={{paddingInline:15, marginTop:15}}>
                  <Col md={11}>
                    <Row gutter={[24,24]}>
                    <h5 className="text-xl md:text-xs font-medium">
                      {t("startDate")}
                    </h5>
                    </Row>
                    <Row gutter={[24,24]}>
                      
                      <DatePicker
                        defaultValue=""
                        style={{ width: "100%" }}                        
                        onChange={(value) => {
                          // //console.log(value);
                          setParamsTable({ 
                            ...paramsTable, 
                            start_date: moment(value).utc(false).format('YYYY-MM-DD').toString(),
                            
                          })                        
                        }}
                        
                      />
                                           
                    </Row>
                  </Col>  
                  <Col md={11}>
                    <Row gutter={[24,24]}>
                    <h5 className="text-xl md:text-xs font-medium">
                      {t("endDate")}
                    </h5>
                    </Row>
                    <Row gutter={[24,24]}>
                      
                      <DatePicker
                        defaultValue=""
                        style={{ width: "100%" }}
                        minDate={new Date(paramsTable.start_date)}             
                        onChange={(value) => {                          
                          setParamsTable({ 
                            ...paramsTable,                            
                            end_date: moment(value).utc(false).format('YYYY-MM-DD').toString(), 
                          })
                          
                        }}                        
                      />                                          
                    </Row>
                  </Col>                  
              </Row>              
            </Col>            
          </Row>
        </Card>
        <Card className="rounded-md" style={{marginBottom:15}}>
          <Row gutter={[24]}>
            <Col md={24} xl={24}>
              <Button                
                type="primary"  
                style={{ width: "100%" }}  
                disabled={(paramsTable.start_date && paramsTable.end_date ? false : true)}        
                onClick={() =>{
                  refetch();
                  handleVisibleList();                 
                }}               
              >
                Filter
              </Button>
            </Col>            
          </Row>
        </Card>
        { visibleList && 
        <Card className="rounded-md">
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Row gutter={[24, 24]} justify="space-between">
                <Col>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  {t("rekon")}
                  </h3>                  
                </Col>
                <Col>
                  <Row gutter={[12, 12, 12]}>                    
                    <Col>                                             
                        <Dropdown
                          overlay={menuFilter}
                          onVisibleChange={handleVisibleFilter}
                          visible={visibleFilter}
                          trigger={["click"]}
                        >
                          <Button
                            type="text"
                            className="text-white hover:text-white"
                            style={{ background: "#8BC34A" }}
                            icon={<SettingOutlined />}                           
                          >
                            Action
                          </Button>
                      </Dropdown>  
                    </Col>
                  </Row>
                </Col>
              </Row>   
                <Row>
                    <Col span={5}>
                      <span className="text-black-500 font-medium"> Tanggal Rekonsiliasi </span>
                    </Col>
                    <Col span={2}>
                      <span className="text-black-500 font-medium"> : </span>
                    </Col>
                    <Col span={7}>              
                      {`${ moment(paramsTable.start_date).utc(true).format("YYYY/MM/DD").toString()} - ${moment(paramsTable.end_date).utc(true).format("YYYY/MM/DD").toString()}`}
                    </Col>
                </Row>
                <Row>
                    <Col span={5}>
                      <span className="text-black-500 font-medium"> Total Data </span>
                    </Col>
                    <Col span={2}>
                      <span className="text-black-500 font-medium"> : </span>
                    </Col>
                    <Col span={7}>
                      {dataListRekonsilasi.length}
                    </Col>
                  </Row> 
                  <Row>
                    <Col span={5}>
                      <span className="text-black-500 font-medium"> Periode </span>
                    </Col>
                    <Col span={2}>
                      <span className="text-black-500 font-medium"> : </span>
                    </Col>
                    <Col span={7}>
                      { moment(paramsTable.start_date).utc(true).format("MMMM").toString() }
                    </Col>
                  </Row>                                                            
            </Col>
            
            <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={datasetsListTable(dataListRekonsilasi)}
                  scroll={{ x: 850 }}
                  loading={isFetching}
                  pagination={{
                    current: paramsTable.page,
                    pageSize: paramsTable.row,
                    total: dataListRekonsilasi[0] ? dataListRekonsilasi[0]?.total : 0,
                  }}
                  onChange={handleChangeTable}
                />
              </Col>            
          </Row>          
        </Card>
        }
      </Col>
    </Row>
    <Modal
        title="Proses Rekonsiliasi"
        subtitle="Anda akan melakukan proses Rekonsiliasi, Dengan data sebagai berikut :"
        type="warning"
        visible={visibleModalRekon}
        onCancel={toggleModalRekon}
        width={500}
      >
      <div className="flex flex-col space-y-2">
        
        <Row>
            <Col span={6}>
              <span className="text-black-500 font-medium"> Periode </span>
            </Col>
            <Col span={6}>
              <span className="text-black-500 font-medium"> : </span>
            </Col>
            <Col span={12}>              
               {paramsTable.start_date}
            </Col>
        </Row>
          <Row>
            <Col span={6}>
              <span className="text-black-500 font-medium"> Tanggal </span>
            </Col>
            <Col span={6}>
              <span className="text-black-500 font-medium"> : </span>
            </Col>
            <Col span={12}>
              {paramsTable.end_date}
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <span className="text-black-500 font-medium"> Total Data </span>
            </Col>
            <Col span={6}>
              <span className="text-black-500 font-medium"> : </span>
            </Col>
            <Col span={12}>
              {dataListRekonsilasi.length}
            </Col>
          </Row>
          <Row style={{border: "2px solid #1D4ED8", padding: 5, borderRadius: 5, backgroundColor: '#DEF2FE'}}>
            <Col align="center">
              <span>Silahkan upload data history stamp yang telah di download melalui menu settlement pada</span> <br></br><a className="text-blue-800 font-medium" href="https://newscm.e-meterai.co.id/" target="_blank">Portal SCM Pemungut</a>           
            </Col>
          </Row> 
          <Row>
            <Col span={24} justify="center">
               <Upload                                                      
                  maxCount={1}          
                  className="w-full"                                
                  {...propsUpload}
                  fileList={fileList}
                  accept=".csv"                                    
                >
                  <Button
                      type="text"
                      className="text-white hover:text-white"
                      style={{ background: "#8BC34A", width: "400%" }}
                      icon={<UploadOutlined />}                                            
                      ghost                                            
                  >
                      Upload
                  </Button> 
                </Upload>                                                            
            </Col>
          </Row>
          <Divider />
                 
          
        
      </div>
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={toggleModalRekon}
          >
            Kembali
          </Button>
          {/* <Button className="w-full" type="primary" onClick={handleSubmit}> */}
          <Button className="w-full" type="primary" disabled={ fileList.length < 0 ? true : false } onClick={handleOk}>
            Ya
          </Button>
        </div>
    </Modal>
    <Modal
        title="Berhasil Proses Rekonsiliasi"
        subtitle="Dokumen telah berhasil diproses dan data rekonsiliasi telah terkirim ke email support@vas.id"
        type="success"
        visible={isSuccess}
        closable={false}
      >
        <div className="flex flex-row space-x-4">
          
          <Button onClick={handleDone} className="w-full" type="primary">
            {t("btndone")}
          </Button>
        </div>
    </Modal>
    <Spinner loading={isLoading} />
    </>    
  );
}