import { DeleteOutlined, LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Form, Input, message, Row } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import { useAddProductMutation } from "../../config/features/product";
import { errorMessage, numberCommas } from "../../utils";

export default function AddProduct() {
  const history = useHistory();

  const [addProduct, { isSuccess, isLoading, error, isError }] =
    useAddProductMutation();

  const [form] = Form.useForm();
  const [payload, setPayload] = useState({});
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setIsModalSuccess(true);
    } else if (isError) {
      errorMessage(error);
    }
  }, [isSuccess, error, isError]);

  const onFinish = (values) => {
    const { name, price_default, unit, products } = values;
    const payload = products
      ? {
          products: [{ name, price_default, unit }, ...products],
        }
      : {
          products: [{ name, price_default, unit }],
        };
    showModal();
    setPayload(payload);
  };

  async function handleSubmit(values) {
    try {
      addProduct(values);
      form.resetFields();
    } catch (error) {
      message.error(error.message);
    }
  }

  const showModal = () => {
    setIsModalConfirm(true);
  };

  const handleOk = () => {
    setIsModalConfirm(false);
    handleSubmit(payload);
  };

  const handleCancel = () => {
    setIsModalConfirm(false);
  };

  const handleDone = () => {
    setIsModalSuccess(false);
    history.push(`/quotation-management`);
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.goBack()}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      Add Product
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    className="md:w-40"
                    type="primary"
                    // onClick={() => history.push("/client-management/add")}
                    // disabled
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Add Product
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    Input New Product
                  </h3>
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 12]}>
                    <Col xs={20} md={12} xl={8}>
                      <Form.Item
                        label="Product Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please input product name!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter product name" />
                      </Form.Item>
                    </Col>
                    <Col xs={20} md={12} xl={6}>
                      <Form.Item
                        label="Unit Price"
                        name="price_default"
                        rules={[
                          {
                            required: true,
                            message: "Please input unit price!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          addonBefore="Rp"
                          placeholder="Enter unit price"
                          min={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.List name="products">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(
                              ({ key, name, fieldKey, ...restField }) => (
                                <Row
                                  key={key}
                                  gutter={[24, 12]}
                                  align="middle"
                                  className="mb-4 md:mb-0"
                                >
                                  <Col xs={20} md={12} xl={8}>
                                    <Form.Item
                                      {...restField}
                                      label="Product Name"
                                      name={[name, "name"]}
                                      fieldKey={[fieldKey, "name"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input product name!",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Enter product name" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={20} md={12} xl={6}>
                                    <Form.Item
                                      {...restField}
                                      label="Unit Price"
                                      name={[name, "price_default"]}
                                      fieldKey={[fieldKey, "price_default"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input unit price!",
                                        },
                                      ]}
                                    >
                                      <Input
                                        type="number"
                                        addonBefore="Rp"
                                        placeholder="Enter unit price"
                                        min={1}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={1}>
                                    <Button
                                      type="primary"
                                      danger
                                      onClick={() => remove(name)}
                                      icon={<DeleteOutlined />}
                                    >
                                      Delete
                                    </Button>
                                  </Col>
                                </Row>
                              )
                            )}
                            <Form.Item>
                              <Button
                                type="primary"
                                ghost
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                              >
                                Add Product
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Are you sure?"
        subtitle="After the data has been added, you cannot change the data. Please make sure the data is correct."
        type="warning"
        visible={isModalConfirm}
        onCancel={handleCancel}
      >
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">Product Name</p>
            <div className="w-2" />
            <div className="flex flex-col space-y-2">
              {payload?.products?.map((item, index) => {
                return (
                  <p key={index} className="text-gray-800 truncate">
                    {item.name}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">Unit Price</p>
            <div className="w-2" />
            <div className="flex flex-col space-y-2">
              {payload.products?.map((item, index) => {
                return (
                  <p key={index} className="text-gray-800 truncate">
                    {`Rp ${numberCommas(item.price_default)},-`}
                  </p>
                );
              })}
            </div>
          </div>
          <Divider />
        </div>
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            ghost
            type="primary"
            onClick={handleCancel}
          >
            Back
          </Button>
          <Button className="w-full" type="primary" onClick={handleOk}>
            Add
          </Button>
        </div>
      </Modal>
      <Modal
        title="Added Success!"
        subtitle="New product has been added! Now you can continue browsing"
        type="success"
        visible={isModalSuccess}
        onCancel={() => setIsModalSuccess(false)}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={handleDone}>
            Done
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
