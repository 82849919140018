import { Card, Col, Divider, Form, Input, message, Modal, Row, Skeleton , Table, Tooltip,   Button as ButtonAntd } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment';
import { Button, Spinner, Modal as ModalComponent } from '../../components'
import Countdown, { zeroPad } from "react-countdown";
import OtpInput from "react-otp-input";
import { ReactComponent as FilePdfBox } from "../../assets/icons/file-pdf-box.svg";
import { DeleteOutlined, DownloadOutlined, LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useFetchActivityDocumentQuery, useFetchDetailDocumentQuery, useFetchDetailSignDocumentQuery , useFetchProfileUserQuery } from '../../config/features';
import { useParams , useLocation } from 'react-router-dom';
import { errorMessage, exportPdf, getBlob } from '../../utils';
import { useTranslation } from 'react-i18next';
import { ASSIGN_TYPE, SIGN_TYPE } from '../../constant';
import axios from 'axios';
import { rootApi } from '../../config/features/config';

const { TextArea } = Input;


function SignatureDetail() {

  const history = useHistory()
  const { t } = useTranslation();

  const {state : {dataDoc}} = useLocation()
  const { data: userProfile = {} } = useFetchProfileUserQuery();
  
  const {id} = useParams()
  const tokenAccess = useMemo( () => localStorage.getItem('access') , [])


  const countdownRef = useRef();
  const [countdownValue, setCountdownValue] = useState(Date.now());
  const [OTPSender, setOTPSender] = useState(false)
  const [otpValue, setOtpValue] = useState("");

  const [isModalOtp, setIsModalOtp] = useState(false)
  const [isResendOtp, setIsResendOtp] = useState(false)
  const [isModalDownload, setIsModalDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [paramsTable, setParamsTable] = useState({
    row: 10,
    page: 1,
    office: "",
    sort_by: "created_at",
    order: "desc",
    doc_id: id,
    date: moment(new Date(), moment.ISO_8601).format("YYYY-MM-DD"),
  });

  const columns = useMemo( () => [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Activity"),
      dataIndex: "activity",
      key: "activity",
    },
    {
      title: t("Actor"),
      dataIndex: "creator",
      key: "creator",
    },
    {
      title: t("Source"),
      dataIndex: "source",
      key: "source",
      render: (_, record) => {
        return (
          <>
            <p className="text-gray-500">
              {t("Device Type")} :
              <span className="text-gray-800"> {record?.device_type}</span>
            </p>
            <p className="text-gray-500">
              {t("Operating System")} :{" "}
              <span className="text-gray-800"> {record?.os}</span>
            </p>
            <p className="text-gray-500">
              {t("Browser")} :{" "}
              <span className="text-gray-800"> {record?.browser}</span>
            </p>
            <p className="text-gray-500">
              IP : <span className="text-gray-800"> {record?.ip}</span>
            </p>
          </>
        );
      },
    },
    {
      title: t("Time"),
      dataIndex: "created_at",
      key: "created_at",
      render: (e) => {
        return moment(e, moment.ISO_8601).format("DD/MM/YYYY, HH:mm:ss");
      },
    },
  ] ,[]) 




  const {
    data: dataDetail = {},
    isFetching,
    isError,
    error,
    refetch: refetchDetailDocument,
  } = useFetchDetailDocumentQuery({ id });

  const {
    data: dataActivity = [],
    isError: isErrorActivity,
    error: errorActivity,
    refetch,
  } = useFetchActivityDocumentQuery(paramsTable)

  const {
    data : { sign_id , signer_id , document,  sign_type , creator , assign_type, success_send_doc, expired_at, signer : dataSignature = []} = {} ,  
    isError : isErrorSign ,
    error : errorSign ,
    refetch : refetchSign
  } = useFetchDetailSignDocumentQuery(dataDoc?.sign_id)


  const datasetsVersion = dataDetail?.versions
  ? [
      {
        file: dataDetail?.file,
        created_at: dataDetail?.created_at,
        description: "UPLOADED",
        creator: dataDetail?.creator,
        version: 1,
        doc_id: id,
      },
      ...dataDetail?.versions,
    ]
  : [];
  const columnsVersion = useMemo( () => [
    {
      title: t("Document Name"),
      dataIndex: "file",
      key: "file",
      render: (file) => {
        return file?.split("/")?.pop();
      },
    },
    {
      title: t("Time"),
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY, HH:mm");
      },
    },
    {
      title: t("Noted"),
      dataIndex: "description",
      key: "description",
      render: (description, record) => {
        console.log(dataSignature);
        if (description == 'SIGNED' && dataSignature?.length > 0) {
          const signers = dataSignature.map((signer) => signer.signer_name).join(", ")
          return (
            <Tooltip title={`${t(description)} ${t("by")} ${signers}`}>
              {`${t(description)} ${t("by")} ${signers}`}
            </Tooltip>
          )
        }
        return `${t(description)} ${t("by")} ${record?.creator}`;
      },
    },
    {
      render: (_, record) => {
        return (
          <Button
            className="bg-blue-700 focus:bg-blue-700 active:bg-blue-700 justify-center"
            type="primary"
            disabled={record?.bc_address === "On Progress" || record?.bc_address === "Failed" }
            onClick={() => handleDownload(record?.doc_id)}
          >
            {t("Download")}
          </Button>
        );
      },
    },
  ], [dataSignature, dataDetail]) 

  const toggleModalDownload = () => setIsModalDownload(!isModalDownload)

  const handleChangeTable = (pagination) => {
      setParamsTable({
          ...paramsTable ,
          page : pagination.current ,
          row : pagination.pageSize
      })
  }

  const handleDownload = async (id_doc) => {
    try {
      // downloadDocument({ id, version });
      setIsLoadingDownload(true);
      setIsModalDownload(false);
      const response = await getBlob(`/document/download?id=${id_doc}` );
      const documentName = dataDetail?.file
        ? dataDetail?.file?.split("/")?.pop()
        : "";
      exportPdf(response.data, documentName);
      setIsLoadingDownload(false);
    } catch (error) {
      setIsLoadingDownload(false);
      message.error(error.message);
    }
  }

  const datasetsListTable = (data) => {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  const datasetsHistory = (dataVersion) => {
    dataVersion?.pop();
    return dataVersion.reverse();
  }


  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
  }, [isError, error, dataDetail]);

  useEffect(() => {
    if (isErrorActivity) {
      errorMessage(errorActivity);
    }
  }, [isErrorActivity, errorActivity]);

  useEffect( () => {
    if(isErrorSign){
      errorMessage(errorSign)
    }
  },[isErrorSign , errorSign])

  useEffect(() => {
    refetch();
    refetchDetailDocument();
    refetchSign();
  }, [refetch, refetchDetailDocument , refetchSign]);

  const handleRetrySendDoc = async () => {
    const bodySend = {
      doc_id: document.id,
      assign_type: ASSIGN_TYPE[assign_type],
      sign_type: SIGN_TYPE[sign_type]
    }
    let success;
    try {
      const { data: { data } } = await axios({
        method: 'POST',
        url: `${rootApi}/sign/single/send-doc-with-retry?retry=1`,
        data: bodySend,
        headers: {
          "token": tokenAccess
        }
      })
      success = true;
    } catch (err) {
      success = false;
    }
    return success;
  }


  
  

  const handleClickRetry = async () => {
    // Mekanisme retry signing pada single document

    // 1. Jika user merupakan sender & signer & assign_type nya adalah self sign
    //  a. Jika user sudah berhasil menandatangani namun terjadi kegagalan dalam proses download dengan status yaitu: sign_status = 2 & is_signed = false
    //  b. Jika expired lakukan send document ulang terlebih dahulu
    const signer = dataSignature.find((sign) => sign.signer_id == signer_id)
    if (sign_type != 'System Sign' && signer?.sign_status == 2 && signer?.is_signed == false) {
      setLoading(true);
      await handleSubmit({}, 1);
      setLoading(false);
      return;
    }
    const currentDate = Math.round(new Date() / 1000)

    if (signer_id == creator.id && sign_type != 'System Sign' && assign_type == 'Self Sign' && (currentDate >= expired_at || success_send_doc == false)) {
      // retry send document
      setLoading(true)
      const success = await handleRetrySendDoc()
      setLoading(false)
      if (!success) {
        message.error(t("Sorry we can process your data, please try again!"))
        return;
      }
      // Trigger get otp & signing biasa
    }
    

    if (sign_type == 'System Sign') {
      await handleSubmitSystem()
      return
    }
    if (signer.sign_status == 3) {
      setIsModalOtp(true)
      await handleSendOtp()
    }
    else {
      await handleSubmit({}, 1)
    }
    console.log("SIGNER => ", signer);


    // let dataDoc = {
    //   doc_id : dataDetail?.doc_id , 
    //   doc_name : dataDetail?.doc_name , 
    //   sign_id : sign_id , 
    //   sign_type , 
    //   assign_type
    // }

    // history.push(`/sign-management/sign` , {dataDoc})

    //  b. Get otp untuk yg bukan system sign, set key untuk yg system sign
    // setLoading(true)
    // if (sign_type == Object.keys(SIGN_TYPE)[4]) {
    //   // set key
    // }
    // else {
    //   // Get OTP
    // }
    // setLoading(false)
    //  c. retry sigining
    

    // 2. Jika user bukan sender & hanya sebagai signer
    //  a. Get otp yg bukan system sign, set key untuk system sign
    //  b. retry signing
  }


  const handleSendOtp = async () => {
    setLoading(true)
    try {
      message.loading({ content: "Sending the OTP", key: "otpMsg" });
      const responseGetOtp = await axios({
        method: 'GET',
        url: `${rootApi}/sign/get-otp/${sign_id}`,
        headers: {
          "token": tokenAccess
        }
      }).then(function (e) {
        setLoading(false)
        message.success({ content: t("Sending the OTP Success"), key: "otpMsg" });
        setCountdownValue(Date.now() + 60000)
        setIsResendOtp(false)
      });
    } catch (error) {
      setLoading(false)
      message.error(error.message);
    }
  }

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setIsResendOtp(true)
      return <div />
    }
    return (
      <p>
        {minutes}:{seconds}
      </p>
    )
  }


  // const renderer = ({ minutes, seconds, completed }) => {
  //   // setLoading(true)
  //   if (completed && sign_id) {
  //     if (!OTPSender) {
  //       console.log("SENDING OTP");
  //       setOTPSender(true);
  //       message.loading({ content: t("Sending the OTP"), key: "otpMsg" });
  //       axios({
  //         method: 'GET',
  //         url: `${rootApi}/sign/get-otp/${sign_id}`,
  //         timeout: 7 * 60 * 1000,
  //         headers: {
  //           "token": tokenAccess
  //         }
  //       }).then(function (eb) {
  //         // setLoading(false)
  //         message.success({ content: t("Sending the OTP Success"), key: "otpMsg" });
  //       }).catch((error) => {
  //         // setLoading(false)
  //         message.error({ content: t("Failed to send OTP"), key: "otpMsg" });
  //       });
  //     }
  //     return (
  //       <ButtonAntd
  //         onClick={async () => {
  //           // setCountdownValue(Date.now() + 60000)
  //           setCountdownValue(Date.now() + 120000)
  //           setTimeout(() => {
  //             countdownRef.current.api.start()
  //           }, 100)
  //           // setIsFirstOtpSent(false);
  //           // handleSendOtp({ resend: true });
  //           // await handleSendOtp({ resend: false });
  //         }}
  //         type="link"
  //         className="p-0"
  //       >
  //         {t("Resend")}
  //       </ButtonAntd>
  //     );
  //   } else {
  //     setLoading(false)
  //     return (
  //       <p>
  //         {zeroPad(minutes)}:{zeroPad(seconds)}
  //       </p>
  //     );
  //   }
  // };
  const handleSubmitSystem = async () => {
    setLoading(true)

    const signer = dataSignature.find((sign) => sign.signer_id == signer_id)
    const body = {
      sign_id: [Number(sign_id)],
      signer_note: signer.signer_note
    }
    try {
      await axios({
        method: "POST",
        url: `${rootApi}/sign/sign-doc-system?retry=1`,
        data: body,
        headers: {
          "token": tokenAccess
        }
      }).then((res) => {
        setLoading(false)
        setIsModalOtp(false)
        message.success({content: t("Signing document successfully"), key: "sign_doc"})
        window.location.reload()
      }).catch((e) => {
        setLoading(false)
        message.error(e.message)
      })
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }
  const handleSubmit = async (values, retry) => {
    setLoading(true)
    const body = {...values, otp_code: otpValue}
    if (retry != 1) {
      retry = 0;
    }
    try {
      await axios({
        method: "POST",
        url: `${rootApi}/sign/sign-doc/${sign_id}?retry=${retry}`,
        data: body,
        headers: {
          "token": tokenAccess
        }
      }).then((res) => {
        setLoading(false)
        setIsModalOtp(false)
        message.success({content: t("Signing document successfully"), key: "sign_doc"})
        window.location.reload()
      }).catch((e) => {
        setLoading(false)
        message.error(e.message)
      })
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  const StatusSign = ({data}) => {
    if (data.sign_status == 2 && data.is_signed == true) {
      return (
        <div style={{backgroundColor: '#8BC34A'}} className='flex justify-center items-center w-full px-4 py-1 rounded-xl h-8 tracking-tight'>
          <span className='text-white whitespace-nowrap'>
            {sign_type != "Peruri Tera" ? t('Signed') : t('Success Tera')}
          </span>
        </div>
      )
    }
    if (data.sign_status == 3 || (data.sign_status == 2 && !data.is_signed)) {
      // FAILED TO SIGN
      if (data.signer_id == signer_id) {
        return (
          <div style={{backgroundColor: data.signer_id == signer_id ? '#E9290F' : '#48A1D9'}} className='flex justify-center items-center w-full px-4 py-1 rounded-xl h-8 tracking-tight'>
            <span className='text-white whitespace-nowrap'>
              {t("Failed to sign")}
            </span>
          </div>
        )
      }
    }
    // PENDING // NOT SIGNED YET
    return (
      <div style={{backgroundColor: data.signer_id == signer_id ? '#E9290F' : '#48A1D9'}} className='flex justify-center items-center w-full px-4 py-1 rounded-xl h-8 tracking-tight'>
        <span className='text-white whitespace-nowrap'>
          {data.signer_id == signer_id ? t('Not Signed Yet') : t("Pending")}
        </span>
      </div>
    )
  }
  
  return (
      <>
      {/* <Skeleton loading={isFetching} paragraph={{rows : 4}}> */}
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                    <Col>
                    <Row gutter={[24, 24]} align="middle">
                        <Col>
                        <div
                            className="group cursor-pointer"
                            onClick={() => history.goBack()}
                        >
                            <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                        </div>
                        </Col>
                        <Col>
                        <h1 className="text-xl md:text-2xl font-medium">
                            {t("Document Detail")}
                        </h1>
                        </Col>
                    </Row>
                    </Col>
                    <Col>
                    <Button
                        className="bg-blue-700 focus:bg-blue-700 active:bg-blue-700 justify-center h-10"
                        type="primary"
                        onClick={toggleModalDownload}
                        disabled={ dataSignature?.length > 0 ? Boolean(dataSignature?.find( item => item.is_signed === false)) : true}
                        // loading={isLoading}
                        icon={<DownloadOutlined />}
                    >
                        {t("Download Document")}
                    </Button>
                    </Col>
                </Row>
            </Col>
            <Col>
                <Row>
                    <Col span={24}>
                        <Card className="rounded-md">
                            <Skeleton loading={false} active paragraph={{ rows: 6 }}>
                                <Row gutter={[24, 0]}>
                                    <Col span={24}>
                                    <Row gutter={[24, 24]} justify="space-between">
                                        <Col>
                                        <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                                            {dataDetail.doc_name}
                                            
                                        </h3>
                                        </Col>
                                        {/* <Col>
                                        <Row gutter={[16, 24]} align="middle">
                                            <Col>
                                            <div
                                                className={`p-2 rounded-full ${
                                                dataDetail.is_authentic
                                                    ? "bg-green-400"
                                                    : "bg-red-400"
                                                }`}
                                            />
                                            </Col>
                                            <Col>
                                            <h4 className="text-gray-500 text-base md:text-lg font-medium">
                                                {dataDetail.is_authentic
                                                ? "Autentik"
                                                : "Tidak Autentik"}
                                            </h4>
                                            </Col>
                                        </Row>
                                        </Col> */}
                                    </Row>
                                    </Col>
                                    <Col span={24}>
                                    <Divider />
                                    </Col>
                                    <Col span={24}>
                                    <Row gutter={[24, 24]}>
                                        <Col xs={12} lg={8}>
                                        <Row gutter={[24, 12]}>
                                            <Col span={24}>
                                            <p className="text-gray-400 text-xs md:text-sm">
                                                {t("Created Date")}
                                            </p>
                                            </Col>
                                            <Col span={24}>
                                            <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                                {moment(
                                                dataDetail.created_at,
                                                moment.ISO_8601
                                                ).format("DD MMMM YYYY, HH:mm:ss")}
                                            </h4>
                                            </Col>
                                        </Row>
                                        </Col>
                                        <Col xs={12} lg={8}>
                                        <Row gutter={[24, 12]}>
                                            <Col span={24}>
                                            <p className="text-gray-400 text-xs md:text-sm">
                                                {t("Last Updated")} 
                                            </p>
                                            </Col>
                                            <Col span={24}>
                                            <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                              {
                                                dataDetail && dataDetail?.versions?.length > 0 ?
                                                moment(
                                                dataDetail?.versions?.length > 0 ? 
                                                dataDetail?.versions[dataDetail?.versions?.length - 1]?.created_at :
                                                dataDetail?.versions[dataDetail?.versions?.length]?.created_at ,
                                                moment.ISO_8601
                                                ).format("DD MMMM YYYY, HH:mm:ss") 
                                                :
                                                moment(
                                                  dataDetail.created_at,
                                                  moment.ISO_8601
                                                ).format("DD MMMM YYYY, HH:mm:ss")
                                              }
                                            </h4>
                                            </Col>
                                        </Row>
                                        </Col>
                                        <Col xs={12} lg={8}>
                                        <Row gutter={[24, 12]}>
                                            <Col span={24}>
                                            <p className="text-gray-400 text-xs md:text-sm">
                                                {t("Work Unit")}
                                            </p>
                                            </Col>
                                            <Col span={24}>
                                            <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                                {dataDetail.office_name}

                                            </h4>
                                            </Col>
                                        </Row>
                                        </Col>
                                        <Col xs={12} lg={8}>
                                        <Row gutter={[24, 12]}>
                                            <Col span={24}>
                                            <p className="text-gray-400 text-xs md:text-sm">
                                                {t("Attach File")}
                                            </p>
                                            </Col>
                                            <Col span={24}>
                                            <div className="flex flex-row space-x-2 items-center">
                                                <FilePdfBox />
                                                <h4 className="text-gray-800 text-sm md:text-base font-medium truncate">
                                                {dataDetail?.file
                                                    ? dataDetail?.file?.split("/")?.pop()
                                                    : ""}
                                                </h4>
                                            </div>
                                            </Col>
                                        </Row>
                                        </Col>
                                        <Col xs={12} lg={8}>
                                        <Row gutter={[24, 12]}>
                                            <Col span={24}>
                                            <p className="text-gray-400 text-xs md:text-sm">
                                                {t("Description")}
                                            </p>
                                            </Col>
                                            <Col span={24}>
                                            <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                                {dataDetail.description}{" "}
                                                {/* <span className="text-blue-400 hover:text-blue-500 text-xs md:text-sm cursor-pointer">
                                            (Detail)
                                            </span> */}

                                            </h4>
                                            </Col>
                                        </Row>
                                        </Col>
                                        {/* <Col xs={12} lg={8}>
                                          <Row gutter={[24, 12]}>
                                              <Col span={24}>
                                              <p className="text-gray-400 text-xs md:text-sm">
                                                  {t("Blockchain Address")}
                                              </p>
                                              </Col>
                                              <Col span={24}>
                                                <h4 className="text-gray-800 text-sm md:text-base font-medium truncate">
                                                {
                                                  (dataDetail?.bc_address === "On Progress" || dataDetail?.bc_address === "Failed")  ? 
                                                  <span>{dataDetail?.bc_address}</span> 
                                                  : 
                                                  <a rel="noreferrer" href={`${process.env.REACT_APP_ROOT_ENV === 'prod' ? 'https://blockexplorer.vas.id' : 'https://demo-blockexplorer.vas.id'}/traces?address=${dataDetail.bc_address}&version=1`} target="_blank">
                                                    {dataDetail.bc_address}
                                                  </a> 
                                                }
                                                </h4>
                                              </Col>
                                          </Row>
                                        </Col> */}
                                        <Col xs={12} lg={8}>
                                        <Row gutter={[24, 12]}>
                                            <Col span={24}>
                                            <p className="text-gray-400 text-xs md:text-sm">
                                                {t("Uploaded By")}
                                            </p>
                                            </Col>
                                            <Col span={24}>
                                            <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                                {dataDetail.creator}{" "}
                                                {/* <span className="text-gray-400">(Admin)</span> */}
                                            </h4>
                                            </Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    </Col>
                                    <Col span={24}>
                                    <Divider />
                                    </Col>
                                    <Col span={24}>
                                    <Row gutter={[24, 24]}>
                                        <Col xs={12} lg={8}>
                                        <Row gutter={[24, 12]}>
                                            <Col span={24}>
                                            <p className="text-gray-400 text-xs md:text-sm">
                                                {t("Tag Key")}
                                            </p>
                                            </Col>
                                            {dataDetail?.tags?.map((item, index) => {
                                            return (
                                                <Col key={index} span={24}>
                                                <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                                    {item?.tag_key}
                                                </h4>
                                                </Col>
                                            );
                                            })}
                                            {/* <Col span={24}>
                                                <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                                    SURAT MASUK HUMAS
                                                </h4>
                                            </Col> */}
                                        </Row>
                                        </Col>
                                        <Col xs={12} lg={8}>
                                        <Row gutter={[24, 12]}>
                                            <Col span={24}>
                                            <p className="text-gray-400 text-xs md:text-sm">
                                                {t("Tag Value")}
                                            </p>
                                            </Col>
                                            {dataDetail?.tags?.map((item, index) => {
                                            return (
                                                <Col key={index} span={24}>
                                                <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                                    {item?.tag_value} 
                                                
                                                </h4>
                                                </Col>
                                            );
                                            })}
                                            {/* <Col span={24}>
                                                <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                                   
                                                    Mediapartner
                                                </h4>
                                            </Col> */}
                                        </Row>
                                        </Col>
                                        <Col xs={12} lg={8}>
                                        <Row gutter={[24, 12]}>
                                            <Col span={24}>
                                            <p className="text-gray-800 text-xs md:text-sm font-medium">
                                                {t("You can edit the tags of all selected resources or add new tags to them.")}
                                            </p>
                                            </Col>
                                            <Col span={24}>
                                            <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                                <Button
                                                type="primary"
                                                ghost
                                                onClick={() =>
                                                    history.push({
                                                    pathname: `/document-management/edit/${id}`,
                                                    state: {
                                                        tags: dataDetail?.tags,
                                                        is_folder: false,
                                                    },
                                                    })
                                                }
                                                >
                                                {t("Edit Tag")}
                                                </Button>
                                            </h4>
                                            </Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    </Col>
                                </Row>
                            </Skeleton>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Row>
                    <Col span={24}>
                        <Card className='rounded-md'>
                            <Row>
                                <Col span={24} className="mb-7">
                                    <h3 style={{color : '#5C5F5B'}} className="text-lg md:text-xl font-medium">
                                        {/* {dataDetail.doc_name} */}
                                        {t(sign_type != "Peruri Tera" ? "Signature Receipt" : "receipt")}
                                    </h3>
                                </Col>
                                {
                                  dataSignature?.length > 0 && 
                                  dataSignature?.map( ( data , index) => (
                                    <>
                                      <Col span={24} className="mb-7">
                                          <div style={{background: '#F4F4F4'}} className='flex justify-between items-center p-3'>
                                              <div className=''>
                                                  <h1>
                                                      <span className='text-xl text-gray-500 font-medium'>{t(sign_type != "Peruri Tera" ? "Signature" : "Tera")} ({index + 1}) : </span>
                                                      <span className='text-xl font-sans font-medium'>{ assign_type === "Self Sign" ? creator.name : data.signer_name}</span>
                                                  </h1>
                                              </div>
                                              <div className='flex gap-5'>
                                              {/* <div style={
                                                {background: `${(data.is_signed === false || data.sign_status == 3) && signer_id === data.signer_id ? '#E9290F' :  ( data.is_signed && data.sign_status == 2 ? '#8BC34A' : '#48A1D9' )}`}} className=' flex justify-center items-center w-full px-4 py-1 rounded-xl h-8 tracking-tight '>
                                                  <span className='text-white whitespace-nowrap'>{`${(data.is_signed === false || data.sign_status == 3) && signer_id === data.signer_id ? t(data.sign_status == 3 ? 'Failed to sign' : 'Not Signed Yet') : ( data.is_signed && data.sign_status == 2 ? t(sign_type != "Peruri Tera" ? 'Signed' : 'Success Tera') : t('Pending') )}`}</span>
                                              </div> */}
                                              {/* {statusSign(data)} */}
                                              <StatusSign
                                                data={data}
                                              />
                                               { data.sign_status == 1 && signer_id === data.signer_id ?

                                                ( index - 1 < 0 ? 
                                            
                                                    <Button 
                                                        type='primary' 
                                                        onClick={() => {
                                                        
                                                        let dataDoc = {
                                                          doc_id : dataDetail?.doc_id , 
                                                          doc_name : dataDetail?.doc_name , 
                                                          sign_id : sign_id , 
                                                          sign_type , 
                                                          assign_type
                                                        }

                                                        history.push(`/sign-management/sign` , {dataDoc})
                                                      }} className='w-full h-8 rounded-md text-white translate-y-3'>{t("Sign Now")}</Button> 

                                                : 

                                                      ( dataSignature[index - 1].is_signed === false && sign_type === "Sequential"  ? 
                                                  
                                                        <Button 
                                                          type='primary' 
                                                          disabled
                                                          onClick={() => {
                                                          
                                                          let dataDoc = {
                                                            doc_id : dataDetail?.doc_id , 
                                                            doc_name : dataDetail?.doc_name , 
                                                            sign_id : sign_id , 
                                                            sign_type , 
                                                            assign_type
                                                          }

                                                          history.push(`/sign-management/sign` , {dataDoc})
                                                          }} className='w-full h-8 rounded-md text-white translate-y-3'>{t("Sign Now")}</Button> 

                                                        : 

                                                        <Button 
                                                          type='primary' 
                                                          onClick={() => {
                                                          
                                                          let dataDoc = {
                                                            doc_id : dataDetail?.doc_id , 
                                                            doc_name : dataDetail?.doc_name , 
                                                            sign_id : sign_id , 
                                                            sign_type , 
                                                            assign_type
                                                          }

                                                          history.push(`/sign-management/sign` , {dataDoc})
                                                          }} className='w-full h-8 rounded-md text-white translate-y-3'>{t("Sign Now")}</Button> 
                                                
                                                      )
                                                )   

                                                :

                                                null
                                              
                                              }
                                              {data.signer_id == signer_id && (data.sign_status == 3 || (data.sign_status == 2 && !data.is_signed)) && (
                                                <Button
                                                  type="primary"
                                                  onClick={handleClickRetry}
                                                >
                                                  {t("Retry Signing")}
                                                </Button>
                                              )}
                                              </div>
                                          </div>
                                      </Col>
                                      <Col span={24}> 
                                          <Row gutter={[24,40]}>
                                              <Col span={24} className="mb-12">
                                                  <Row className="pl-1">
                                                      <Col lg={8}>
                                                      <Row>
                                                          <Col span={24}>
                                                              <p className='text-gray-400 text-xs md:text-sm'>ID</p>
                                                          </Col>
                                                          <Col span={24}>
                                                              <h4 className='text-gray-800 text-sm md:text-base font-medium' >{data?.signer_id || '-'}</h4>
                                                          </Col>
                                                      </Row>
                                                      </Col>
                                                      {/* <Col lg={8}>
                                                          <Row>
                                                              <Col span={24}>
                                                                  <p className='text-gray-400 text-xs md:text-sm'>Total Page</p>
                                                              </Col>
                                                              <Col>
                                                                  <h4 className='text-gray-800 text-sm md:text-base font-medium'>{data?.pages || '-'}</h4>
                                                              </Col>
                                                          </Row>
                                                      </Col> */}
                                                      <Col lg={8}>
                                                          <Row>
                                                              <Col span={24}>
                                                                  <p className='text-gray-400 text-xs md:text-sm'>{t("Time")}</p>
                                                              </Col>
                                                              <Col>
                                                                  <h4 className='text-gray-800 text-sm md:text-base font-medium'>
                                                                  { data.updated_at === null ? "-" :
                                                                  moment(
                                                                  data.updated_at,
                                                                  moment.ISO_8601
                                                                  ).format("DD MMMM YYYY, HH:mm:ss")}
                                                                  </h4>
                                                              </Col>
                                                          </Row>
                                                      </Col>
                                                      <Col lg={8}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <p className='text-gray-400 text-xs md:text-sm'>{t("Notes")}</p>
                                                            </Col>
                                                            <Col>
                                                                <h4 className='text-gray-800 text-sm md:text-base font-medium'>{data?.signer_note || '-'}</h4>
                                                            </Col>
                                                        </Row>
                                                      </Col>
                                                  </Row>
                                              </Col>
                                            <Col span={24} className="mb-12">
                                                <Row className="pl-1">
                                                      <Col lg={8}>
                                                          <Row>
                                                              <Col span={24}>
                                                                  <p className='text-gray-400 text-xs md:text-sm'>{t("Device")}</p>
                                                              </Col>
                                                              <Col>
                                                                  <h4 className='text-gray-800 text-sm md:text-base font-medium'>
                                                                      {data.device || '-'}
                                                                  </h4>
                                                              </Col>
                                                          </Row>
                                                      </Col>
                                                      <Col lg={8}>
                                                          <Row>
                                                              <Col span={24}>
                                                                  <p className='text-gray-400 text-xs md:text-sm'>{t("IP Address")}</p>
                                                              </Col>
                                                              <Col>
                                                                  <h4 className='text-gray-800 text-sm md:text-base font-medium'>
                                                                      {data.ip || '-'}
                                                                  </h4>
                                                              </Col>
                                                          </Row>
                                                      </Col>
                                                </Row>
                                            </Col>
                                          </Row>
                                      </Col>
                                    </>
                                  ))
                                }
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Card className="rounded-md">
                    <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Row gutter={[24, 24]} justify="space-between">
                        <Col>
                            <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                            {t("Document Activity")}
                            <span className="text-gray-400">
                                ({t("Last 30 Days From Date")})
                            </span>
                            </h3>
                        </Col>

                        </Row>
                    </Col>
                    <Col span={24}>
                        <Table
                        columns={columns}
                        dataSource={datasetsListTable(dataActivity)}
                        scroll={{ x: 800 }}
                        loading={false}
                        pagination={{
                            current: paramsTable.page,
                            pageSize: paramsTable.row,
                            total: dataActivity[0] ? dataActivity[0]?.total : 0,
                        }}
                        onChange={handleChangeTable}
                        />
                    </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
        <Modal
        visible={isModalDownload}
        onCancel={toggleModalDownload}
        footer={false}
        width={800}
      >
        <Row gutter={[24, 24]} className="pr-4">
          <Col span={24}>
            <h3 className="text-gray-500 text-lg md:text-xl font-medium">
              {t("Version Document (Current)")}
            </h3>
          </Col>
          <Col span={24}>
            <Table
              columns={columnsVersion}
              dataSource={[datasetsVersion[datasetsVersion.length - 1]]}
              scroll={{ x: 500 }}
              loading={isFetching}
              pagination={false}
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]} className="pr-4 mt-4">
          <Col span={24}>
            <h3 className="text-gray-500 text-lg md:text-xl font-medium">
              {t("Version History")}
            </h3>
          </Col>
          <Col span={24}>
            <Table
              columns={columnsVersion}
              dataSource={datasetsHistory(datasetsVersion)}
              scroll={{ x: 500 }}
              loading={isFetching}
              pagination={{
                current: paramsTable.page,
                pageSize: paramsTable.row,
                // total: dataListDocument[0] ? dataListDocument[0]?.total : 0,
              }}
              onChange={handleChangeTable}
            />
          </Col>
        </Row>
      </Modal>
      <ModalComponent
        title={t("Are you sure?")}
        subtitle={`${t("Please make sure your data is correct. OTP Code automatic has sent to your email, let’s check!")} ${userProfile?.email}`}
        type="warning"
        visible={isModalOtp}
        onCancel={() => setIsModalOtp(false)}
      >
        <Spinner loading={loading}/>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row justify-between">
            <p>OTP Code</p>
            <Countdown
              date={countdownValue}
              renderer={renderer}
              ref={countdownRef} />
          </div>
          <OtpInput
            value={otpValue}
            onChange={(otp) => setOtpValue(otp)}
            numInputs={6}
            isInputNum
            className="text-gray-800"
            containerStyle={{
              display: "flex",
              justifyContent: "space-between",
            }}
            inputStyle={{
              width: "3rem",
              height: "3rem",
              fontWeight: "bold",
              border: "2px solid #DBDBDB",
              borderRadius: 4,
              color: "#000000",
            }}
          />
          <Form onFinish={handleSubmit} autoComplete={false}>
            <div>
              <p>Note (Optional)</p>
              <Form.Item name="signer_note">
                <TextArea name="signer_note" rows={4} />
              </Form.Item>
            </div>
            <Form.Item>
              <div className="flex flex-row space-x-4">
                <Button
                  // disabled
                  onClick={() => {
                    setIsModalOtp(false);
                  }}
                  className="w-full"
                  ghost
                  type="secondary"

                >
                  {t("Back")}
                </Button>
                <Button
                  disabled={otpValue?.length < 6}
                  className="w-full"
                  type="primary"
                  htmlType="submit"
                >
                  {t("Submit")}
                </Button>
              </div>
            </Form.Item>
          </Form>
          <p className="text-center">
            {t("But, if get a problem you can")}
            <span
              onClick={handleSendOtp}
              type="text"
              disabled={!isResendOtp}
              className="text-blue-500 cursor-pointer px-1 hover:underline duration-500"
            >
              {t("Resend")}
            </span>
            {t("the otp code again")}
          </p>
          <Divider />
          <div className="text-center">
            <p className="text-gray-500 text-xs">
              {t("By pressing the ‘Submit’ button means that you have agreed to the")}
            </p>
            <p className="text-blue-500 text-xs">{t("Terms & Conditions")}</p>
          </div>
        </div>
      </ModalComponent>
      <Spinner 
        loading={loading}
      // loading={ dataDetail?.bc_address === "On Progress" ? true : ( isLoadingDownload === true ? true : false )} 
      />
    </>
  )
}

export default SignatureDetail