import { DownloadOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  DatePicker,
  Divider,
  message,

  Modal as ModalAntd,
  Progress,
  Row,
  Skeleton,
  Button as ButtonAntd,
  Table,
  Input,
  Form
} from "antd";
import moment from "moment";
import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { Spinner, Button, Modal } from "../../components";
import SignatureReceipt from "../../components/SignatureReceipt";
import Countdown, { zeroPad } from "react-countdown";
import OtpInput from "react-otp-input";

import axios from "axios";
import { rootApi } from "../../config/features/config";
// import { useAddBulkStampMutation } from "../../config/features";
import { useAddBulkStampMutation, useFetchActivityDocumentQuery, useFetchDetailDocumentQuery, useFetchDetBulkSignQuery, useFetchDetailSignDocumentQuery, useFetchProfileUserQuery, useFetchDetailSignFolderMutation } from '../../config/features';
// import {
//   useFetchActivityDocumentQuery,
//   useFetchDetailDocumentQuery,
// } from "../../config/features/document";
import { ASSIGN_TYPE, SIGN_TYPE, STAMP_DOC_STATUS } from "../../constant";
import { errorMessage, exportPdf, getBlob } from "../../utils";

import DoneIcon from '../../assets/icons/done.svg'
import ResetIcon from '../../assets/icons/reset.svg'
import CloseIcon from '../../assets/img/icon/closeIcon.png'
import SettingIcon from '../../assets/img/icon/setting.png'
import google1 from '../../assets/img/google-1.png'
import google2 from '../../assets/img/google-2.png'
import safari1 from '../../assets/img/safari1.png'
import safari2 from '../../assets/img/safari2.png'
import safari3 from '../../assets/img/safari3.png'
import firefox1 from '../../assets/img/firefox1.png'
import firefox2 from '../../assets/img/firefox2.png'
import arrowRight from '../../assets/img/icon/right-arrow.png'
import arrowBtm from '../../assets/img/icon/arrow-btm.png'
import arrowUp from '../../assets/img/icon/arrow-up.png'
import { useTranslation } from "react-i18next";

import("moment/locale/id");
moment.locale("id");


const { TextArea } = Input;


const columnsFailedStampDocs = [
  {
    title: "Document name",
    dataIndex: "doc_name",
    key: "doc_name"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: () => "Incomplete"
  },
]

export default function DocumentDetail() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const { state: { dataDoc } } = useLocation();

  const tokenPeruri = useMemo(() => localStorage.getItem('peruri'), [])
  const tokenAccess = useMemo(() => localStorage.getItem('access'), [])

  const countdownRef = useRef();
  const [countdownValue, setCountdownValue] = useState(Date.now());
  const [OTPSender, setOTPSender] = useState(false)
  const [otpValue, setOtpValue] = useState("");
  const [totalSigned, setTotalSigned] = useState(0)
  const [signers, setSigners] = useState([])
  const [lastActivity, setLastActivity] = useState(null);
  const [loading, setLoading] = useState(false);

  const [paramsTable, setParamsTable] = useState({
    row: 10,
    page: 1,
    office: "",
    sort_by: "created_at",
    order: "desc",
    folder_id: id,
    date: moment(new Date(), moment.ISO_8601).format("YYYY-MM-DD"),
  });
  const [isModalDownload, setIsModalDownload] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const [isModalOtp, setIsModalOtp] = useState(false);

  const { data: userProfile = {} } = useFetchProfileUserQuery();

  const {
    data: dataDetail = {},
    isFetching,
    isError,
    error,
    refetch: refetchDetBulkSign,
  } = useFetchDetBulkSignQuery({ id, is_folder: 1, is_sign: 1 });

  const [
    getDetailSigned,
    {
      isSuccess: isSuccessDetailSigned,
      isLoading: isLoadingDetailSigned,
      error: errorDetailSigned,
      data: dataDetailSigned = {}
    }
  ] = useFetchDetailSignFolderMutation();

  useEffect(() => {
    if (dataDetail.id) {
      getDetailSigned({folder_id: dataDetail.id, sign_type: SIGN_TYPE[dataDoc.sign_type]})
    }
  }, [dataDetail])

  // useEffect(() => {
  //   console.log(dataDetailSigned);
  // }, [dataDetailSigned])
  const {
    data: dataActivity = [],
    isError: isErrorActivity,
    error: errorActivity,
    refetch,
  } = useFetchActivityDocumentQuery(paramsTable);

  const [
    addBulkStamp,
    {
      isSuccess: isSuccessRetry,
      isError: isErrorRetry,
      error: errorRetry,
      isLoading: isLoadingRetry,
    },
  ] = useAddBulkStampMutation();


  const renderer = ({ minutes, seconds, completed }) => {
    // setLoading(true)
    if (completed && dataDetailSigned.document) {
      if (!OTPSender) {
        setOTPSender(true);
        message.loading({ content: t("Sending the OTP"), key: "otpMsg" });
        axios({
          method: 'GET',
          url: `${rootApi}/sign/get-otp-bulk/${dataDetailSigned.document.id}`,
          timeout: 7 * 60 * 1000,
          headers: {
            "token": tokenAccess,
            "token-peruri-sign": tokenPeruri
          }
        }).then(function (eb) {
          // setLoading(false)
          message.success({ content: t("Sending the OTP Success"), key: "otpMsg" });
        }).catch((error) => {
          // setLoading(false)
          message.error({ content: t("Failed to send OTP"), key: "otpMsg" });
        });
      }
      return (
        <ButtonAntd
          onClick={async () => {
            // setCountdownValue(Date.now() + 60000)
            setCountdownValue(Date.now() + 120000)
            setTimeout(() => {
              countdownRef.current.api.start()
            }, 100)
            // setIsFirstOtpSent(false);
            // handleSendOtp({ resend: true });
            await handleSendOtp({ resend: false });
          }}
          type="link"
          className="p-0"
        >
          {t("Resend")}
        </ButtonAntd>
      );
    } else {
      setLoading(false)
      return (
        <p>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </p>
      );
    }
  };

  const handleSendOtp = async (props) => {
    setLoading(true)
    try {
      message.loading({ content: "Sending the OTP", key: "otpMsg" });
      const responseGetOtp = await axios({
        method: 'GET',
        url: `${rootApi}/sign/get-otp-bulk/${dataDetailSigned.document.id}`,
        headers: {
          "token": tokenAccess,
          "token-peruri-sign": tokenPeruri
        }
      }).then(function (e) {
        setLoading(false)
        message.success({ content: t("Sending the OTP Success"), key: "otpMsg" });
      });

    } catch (error) {
      setLoading(false)
      message.error(error.message);
    }
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    const body = { otp_code: `${otpValue}` }
    try {
      await axios({
        method: 'POST',
        url: `${rootApi}/sign/sign-doc-bulk/${dataDetailSigned.document.id}`,
        data: body,
        headers: {
          "token": tokenAccess,
          "token-peruri-sign": tokenPeruri
        }
      }).then((e) => {
        //console.log("A => ", e);
        console.log(e.data);
        setLoading(false)
        setIsModalOtp(false);
        message.success({ content: t("Tera Stamp Successfully"), key: "otpMsg" });
      }).catch((ee) => {
        setLoading(false)
        message.error(ee.message);
      });

    } catch (error) {
      setLoading(false)
      message.error(error.message);
    }
  }


  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
  }, [isError, error, dataDetail]);

  useEffect(() => {
    if (isErrorActivity) {
      errorMessage(errorActivity);
    }
  }, [isErrorActivity, errorActivity]);

  useEffect(() => {
    if (isErrorRetry) {
      errorMessage(errorRetry);
    }
  }, [isErrorRetry, errorRetry]);

  useEffect(() => {
    refetch();
    refetchDetBulkSign();
  }, [refetch, refetchDetBulkSign]);

  useEffect(() => {
    if (userProfile.email && dataDetailSigned.signer && dataDetailSigned.signer.length > 0) {
      const user_signer = Object.assign([], dataDetailSigned.signer)
      setSigners(user_signer.filter((signer) => signer.sign_type != 3))
      user_signer.reverse()
      if (user_signer.find((signer) => signer.email == userProfile.email)) {
        setLastActivity({ total_docs: dataDetailSigned?.document?.total_docs, ...user_signer.find((signer) => signer.email == userProfile.email) })
      }
    }
  }, [dataDetailSigned, userProfile])
  // useEffect(() => {
  //   if (signers.length > 0) {
  //     setTotalSigned(signers.find((signer) => signer.email == userProfile.email).success_signed || 0)
  //     setLastActivity(signers.find((signer) => signer.email == userProfile.email))
  //   }
  // }, [signers, userProfile])

  useEffect(() => {
    if (dataDetail.id && lastActivity?.success_send == dataDetailSigned?.document?.total_docs && (lastActivity?.success_signed < dataDetailSigned?.document?.total_docs || lastActivity?.success_download < dataDetailSigned?.document?.total_docs) && lastActivity?.sign_status == 1) {
      const interval = setInterval(() => {
        getDetailSigned({folder_id: dataDetail.id, sign_type: SIGN_TYPE[dataDoc.sign_type]})
      }, 5000);
      return () => clearInterval(interval)
    }
  }, [dataDetail, lastActivity, dataDetailSigned])


  const columnsVersion = [
    {
      title: t("Document Name"),
      dataIndex: "file",
      key: "file",
      render: (file) => {
        return file?.split("/")?.pop();
      },
    },
    {
      title: t("Time"),
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY, HH:mm");
      },
    },
    {
      title: t("Noted"),
      dataIndex: "description",
      key: "description",
      render: (description, record) => {
        return `${description} by ${record?.creator}`;
      },
    },
    {
      render: (_, record) => {
        return (
          <Button
            className="bg-blue-700 focus:bg-blue-700 active:bg-blue-700 justify-center"
            type="primary"
            disabled={record?.bc_address === "On Progress" || record?.bc_address === "Failed"}
            onClick={() => handleDownload(record?.version)}
          >
            {t("Download")}
          </Button>
        );
      },
    },
  ];

  async function handleDownload(version) {
    try {
      // downloadDocument({ id, version });
      setIsLoadingDownload(true);
      setIsModalDownload(false);
      const response = await getBlob(
        `/document/download?id=${id}&version=${version}`
      );
      const documentName = dataDetail?.file
        ? dataDetail?.file?.split("/")?.pop()
        : "";
      exportPdf(response.data, documentName);
      setIsLoadingDownload(false);
    } catch (error) {
      message.error(error.message);
      setIsLoadingDownload(false);
    }
  }

  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  function handleChangeActivityDate(date) {
    setParamsTable({
      ...paramsTable,
      date: moment(date, moment.ISO_8601).format("YYYY-MM-DD"),
    });
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }

  function toggleModalDownload() {
    setIsModalDownload(!isModalDownload);
  }

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Activity"),
      dataIndex: "activity",
      key: "activity",
    },
    {
      title: t("Actor"),
      dataIndex: "creator",
      key: "creator",
    },
    {
      title: t("Source"),
      dataIndex: "source",
      key: "source",
      render: (_, record) => {
        return (
          <>
            <p className="text-gray-500">
              {t("Device Type")} :
              <span className="text-gray-800"> {record?.device_type}</span>
            </p>
            <p className="text-gray-500">
              {t("Operating System")} :{" "}
              <span className="text-gray-800"> {record?.os}</span>
            </p>
            <p className="text-gray-500">
              {t("Browser")} :{" "}
              <span className="text-gray-800"> {record?.browser}</span>
            </p>
            <p className="text-gray-500">
              IP : <span className="text-gray-800"> {record?.ip}</span>
            </p>
          </>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (e) => {
        return moment(e, moment.ISO_8601).format("DD/MM/YYYY, HH:mm:ss");
      },
    },
  ];

  const datasetsVersion = dataDetail?.versions
    ? [
      {
        file: dataDetail?.file,
        created_at: dataDetail?.created_at,
        description: "UPLOADED",
        creator: dataDetail?.creator,
        version: 1,
      },
      ...dataDetail?.versions,
    ]
    : [];

  function datasetsHistory(dataVersion) {
    dataVersion?.pop();
    return dataVersion.reverse();
  }

  // async function handleClickRetry() {
  //   try {
  //     await addBulkStamp({
  //       doc_id: dataDetail?.id,
  //       retry: 1
  //     });
  //     refetchDetBulkSign();
  //   } catch (error) {
  //     message.error(error.message);
  //   }
  // }
  
  const [modalProgress, setModalProgress] = useState(false)
  const [triggerSignSystem, setTriggerSignSystem] = useState(false)
  const [totalRetrySend, setTotalRetrySend] = useState(0)
  
  const checkStatusSendDocument = async () => {
    try {
      const body = {
        "folder_id": dataDetailSigned?.document?.id
      }
      await axios({
        method: "POST",
        url : `${rootApi}/sign/check-docsign-bulk`,
        data: body,
        headers: {
          "token": tokenAccess
        }
      }).then((res) => {
        const length = res.data.data.length
        if (length == dataDetailSigned?.document?.total_docs) {
          setTriggerSignSystem(true)
        }
        setTotalRetrySend(length)
      }).catch(e => message.error(e.message))
    } catch (error) {
      message.warning('Unable to check status. Try Again')
    }
  }
  
  useEffect(() => {
    if (modalProgress && totalRetrySend < dataDetailSigned?.document?.total_docs) {
      const interval = setInterval(() => {
        checkStatusSendDocument()
      }, 1000);
      return () => clearInterval(interval)
    }
  }, [modalProgress])

  const handleRetrySendDoc = async () => {
    setLoading(true)
    const bodySend = {
      sign_type: SIGN_TYPE[dataDoc?.sign_type],
      assign_type: ASSIGN_TYPE[dataDoc?.assign_type]
    }
    await axios({
      method: 'POST',
      url: `${rootApi}/sign/single/send-doc-bulk/${dataDetailSigned?.document?.id}?retry=1`,
      data: bodySend,
      timeout: 7 * 60 * 1000,
      headers: {
        "token": tokenAccess,
        "token-peruri-sign" : tokenPeruri
      }
    }).then((res) => {
      setModalProgress(true)
    }).catch((e) => {
      // message.warning(e.message)
    })
    setLoading(false)
  }

  async function generateSystemSignKey (){
    let r = (Math.random() + 1).toString(36).substring(6);
    const body = { key : `DLII${r}`, minutes: `30` }
    const generateSystemKey = await axios({
      method : 'POST' ,
      url : `${rootApi}/sign/set-system-sign-key`,
      data : body ,
      headers : {
        "token" : tokenAccess
      }
    }).then((e) => {
      console.log("KEY ", e);
      return e;

    }).catch((ee) => {
      console.log("B => ", ee);
      message.error(ee.message);
    });
  }

  const isExpired = (timeStamps = []) => {
    return timeStamps.some(ts => Math.round(new Date() / 1000) >= ts)
  }

  const handleTrigerSignSystem = async () => {
    if (triggerSignSystem == true) {
      setTriggerSignSystem(false)
      setModalProgress(false)
      setLoading(true)
  
      const body = {
        signer_note: lastActivity?.signer_note
      }
  
      try {
        // await generateSystemSignKey()
        await axios({
          method: 'POST',
          url : `${rootApi}/sign/sign-doc-system-bulk/${dataDetailSigned?.document?.id}`,
          data: body,
          headers: {
            "token": tokenAccess
          }
        }).then((res) => {
          message.success({content: t("Signing System Bulk Successfully"), key: 'sign_syst_bulk'})
        }).catch((err) => {
          message.error(err.message)
        })
      } catch (error) {
        message.error(error.message)
      }
  
      setLoading(false)
      window.location.reload()
    }
  }

  const handleRetrySignSystem = async () => {
    setLoading(true)

    try {
      // await generateSystemSignKey()
      await axios({
        method: 'POST',
        url : `${rootApi}/sign/sign-doc-system-bulk/${dataDetailSigned?.document?.id}?retry=1`,
        headers: {
          "token": tokenAccess
        }
      }).then((res) => {
        message.success({content: t("Retry Signing System Bulk Successfully"), key: 'retry_sign_syst_bulk'})
      }).catch((error) => {
        message.error(error.message)
      })
    } catch (error) {
      message.error(error.message)
    }
    setLoading(false)
    window.location.reload()
  }

  const handleRetrySignDocBulk = async () => {
    setLoading(true)

    try {
      await axios({
        method: 'POST',
        url: `${rootApi}/sign/sign-doc-bulk/${dataDetailSigned.document.id}?retry=1`,
        headers: {
          "token": tokenAccess,
          "token-peruri-sign": tokenPeruri
        }
      }).then((res) => {
        message.success({content: t("Retry Signing Bulk On Progress"), key: 'retry_sign_bulk'})
      }).catch((error) => {
        message.error(error.message)
      })
    } catch (error) {
      message.error(error.message)
    }
    setLoading(false);
    window.location.reload();
  }

  const handleClickRetry = async () => {
    // this function is only available for bulk system sign for now
    if (dataDetailSigned?.assign_type == 'Self Sign' && userProfile?.user_id == dataDetailSigned?.creator?.id) {
      if (dataDetailSigned?.send_doc_success == false || (lastActivity?.sign_status == 3 && isExpired(dataDetailSigned?.receipts?.map(({expired_at}) => Number(expired_at)))) ) {
        // 1. handle retry send document
        // 2. progress bar
        // 3. trigger sign document
        await handleRetrySendDoc()
        if (lastActivity?.sign_type != 4) {
          setIsModalOtp(true)
          return;
        }
      }
    }
    
    // 1. retry signing for system sign
    if (lastActivity?.sign_type == 4) {
      await handleRetrySignSystem();
      return;
    }
    
    // 2. retry signing for not system sign
    await handleRetrySignDocBulk();
  };
  const StatusSign = ({data}) => {
    if (data.sign_status == 2 && data.is_signed == true) {
      return (
        <div style={{backgroundColor: '#8BC34A'}} className='flex justify-center items-center w-full px-4 py-1 rounded-xl h-8 tracking-tight'>
          <span className='text-white whitespace-nowrap'>
            {t("Signed")}
          </span>
        </div>
      )
    }
    if (data.sign_status == 3 && data.signer_id == userProfile?.user_id) {
      return (
        <div style={{backgroundColor: '#E9290F'}} className='flex justify-center items-center w-full px-4 py-1 rounded-xl h-8 tracking-tight'>
          <span className='text-white whitespace-nowrap'>
            {t("Failed to sign")}
          </span>
        </div>
      )
    }
    if (data.sign_status == 1 && data.success_signed == 0 && data.signer_id == userProfile?.user_id) {
      return (
        <div style={{backgroundColor: '#E9290F'}} className='flex justify-center items-center w-full px-4 py-1 rounded-xl h-8 tracking-tight'>
          <span className='text-white whitespace-nowrap'>
            {t("Not Signed Yet")}
          </span>
        </div>
      )
    }
    return (
      <div style={{backgroundColor: '#48A1D9'}} className='flex justify-center items-center w-full px-4 py-1 rounded-xl h-8 tracking-tight'>
        <span className='text-white whitespace-nowrap'>
          {t("Pending")}
        </span>
      </div>
    )
  }
  return (
    <>

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    {t("Document Detail")}
                  </h1>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                className="bg-blue-700 focus:bg-blue-700 active:bg-blue-700 justify-center"
                type="primary"
                onClick={() =>
                  history.push({
                    // pathname: `/document-management/detail-sign-bulk/${id}/version`,
                    pathname: `/document-management/detail-bulk/${id}/version`,
                    // state: dataDetail,
                  })
                }
                icon={<DownloadOutlined />}
                disabled={isFetching}
              >
                {t("Download Document")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Skeleton loading={isFetching} active paragraph={{ rows: 6 }}>
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <Row gutter={[24, 24]} justify="space-between">
                    <Col>
                      <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                        {dataDetail?.name}
                      </h3>
                    </Col>
                    {/* <Col>
                      <Row gutter={[16, 24]} align="middle">
                        <Col>
                          <div
                            className={`p-2 rounded-full ${
                              dataDetail?.is_authentic
                                ? "bg-green-400"
                                : "bg-red-400"
                            }`}
                          />
                        </Col>
                        <Col>
                          <h4 className="text-gray-500 text-base md:text-lg font-medium">
                            {dataDetail?.is_authentic
                              ? "Autentik"
                              : "Tidak Autentik"}
                          </h4>
                        </Col>
                      </Row>
                    </Col> */}
                  </Row>
                </Col>
                <Col span={24}>
                  <Divider />
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 24]}>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Create Date")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {moment(
                              dataDetail?.created_at,
                              moment.ISO_8601
                            ).format("DD MMMM YYYY, HH:mm:ss")}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Last Updated")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {
                              dataDetail && dataDetail?.versions?.length > 0 ?
                                moment(
                                  dataDetail?.versions?.length > 0 ?
                                    dataDetail?.versions[dataDetail?.versions?.length - 1]?.created_at :
                                    dataDetail?.versions[dataDetail?.versions?.length]?.created_at,
                                  moment.ISO_8601
                                ).format("DD MMMM YYYY, HH:mm:ss")
                                :
                                moment(
                                  dataDetail.created_at,
                                  moment.ISO_8601
                                ).format("DD MMMM YYYY, HH:mm:ss")
                            }
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Office")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetail?.office_name}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Attach File")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium truncate">
                            {dataDetail?.name + ".zip"}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Description")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetail?.description}
                            {/* <span className="text-blue-400 hover:text-blue-500 text-xs md:text-sm cursor-pointer">
                          (Detail)
                        </span> */}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                          <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                              Blockchain Address
                          </p>
                          </Col>
                          <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium truncate">
                              {dataDetail.bc_address}
                              <a href="https://scan.haratoken.app/traces-view?addr=0x0A35909C144a31b1c4e82571C3659D4e391e0C55&ver=3" target="_blank" rel="noreferrer">
                                0x0A35909C144a31b1c4e82571C3659D4e391e0C55
                              </a>
                          </h4>
                          </Col>
                      </Row>
                    </Col> */}
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Uploaded By")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            {dataDetail?.creator}
                            {/* <span className="text-gray-400">(Admin)</span> */}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Divider />
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 24]}>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Tag Key")}
                          </p>
                        </Col>
                        {dataDetail?.tags?.map((item, index) => {
                          return (
                            <Col key={index} span={24}>
                              <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                {item?.tag_key}
                              </h4>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-400 text-xs md:text-sm">
                            {t("Tag Value")}
                          </p>
                        </Col>
                        {dataDetail?.tags?.map((item, index) => {
                          return (
                            <Col key={index} span={24}>
                              <h4 className="text-gray-800 text-sm md:text-base font-medium">
                                {item?.tag_value}
                              </h4>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Row gutter={[24, 12]}>
                        <Col span={24}>
                          <p className="text-gray-800 text-xs md:text-sm font-medium">
                            {t("You can edit the tags of all selected resources or add new tags to them.")}
                          </p>
                        </Col>
                        <Col span={24}>
                          <h4 className="text-gray-800 text-sm md:text-base font-medium">
                            <Button
                              type="primary"
                              ghost
                              onClick={() =>
                                history.push({
                                  pathname: `/document-management/edit/${id}`,
                                  state: {
                                    tags: dataDetail?.tags,
                                    is_folder: true,
                                  },
                                })
                              }
                            >
                              {t("Edit Tag")}
                            </Button>
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Skeleton>
          </Card>
        </Col>
        {/* Last Activity E-Sign Atau TERA */}

        {lastActivity && (
          <Col span={24}>
            <Card className="rounded-md">
              <Skeleton loading={(isLoadingDetailSigned || isFetching) && !lastActivity} active paragraph={{ rows: 6 }}>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Row gutter={[24, 24]} justify="space-between">
                      <Col>
                        <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                          {t(lastActivity?.sign_type != 3 ? "Latest Sign Activity" : "Tera Activity")}
                        </h3>
                      </Col>
                      <Col>
                        <div className="flex gap-2 items-center">

                          {lastActivity?.sign_status == 1 &&
                            <div
                              className="bg-blue-500 rounded-full text-white py-1 px-3 text-sm"
                              type="primary"
                            >
                              {t("On Progress")}
                            </div>}
                          {(lastActivity?.success_download == dataDetailSigned?.document?.total_docs) &&
                            <div
                              className="bg-green-500 rounded-full text-white py-1 px-3 text-sm"
                              type="primary"
                            >
                              {t("Completed")}
                            </div>
                          }
                          {lastActivity && lastActivity.sign_type == 3 && !lastActivity.signed_at && (
                            <>
                              <div
                                className="bg-gray-500 rounded-full text-white py-1 px-3 text-sm"
                                type="primary"
                              >
                                {t("Incomplete")}
                              </div>
                              <Button
                                type="primary"
                                onClick={() => {
                                  setIsModalOtp(true)
                                }}
                              >
                                {t("Input OTP")}
                              </Button>
                            </>
                          )}
                          {lastActivity && (lastActivity.sign_status == 3 || dataDetailSigned?.send_doc_success == false || (lastActivity.sign_status != 2 && isExpired(dataDetailSigned?.receipts?.map(({expired_at}) => Number(expired_at))))) && (
                            <Button
                              type="primary"
                              onClick={handleClickRetry}
                            >
                              {t("Retry Signing")}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                      <Col span={24}>
                        <h6> {t("Date Issued")} : {" "}
                          {lastActivity?.signed_at
                            ? moment(lastActivity?.signed_at, moment.ISO_8601)
                              .format("DD/MM/YYYY - HH:mm")
                            : "--"
                          }
                        </h6>
                      </Col>
                      <Col span={24}>
                        {lastActivity?.sign_type == 4 ? (
                          <Progress
                            type="line"
                            strokeWidth={20}
                            percent={lastActivity?.success_download / Number(lastActivity?.total_docs) * 100}
                            format={percent => `${lastActivity?.success_download}/${lastActivity?.total_docs || 0}`}
                            className="pr-6"
                          />
                        ) :  (
                          <Progress
                            type="line"
                            strokeWidth={20}
                            percent={lastActivity?.success_download / Number(lastActivity?.total_docs) * 100}
                            format={percent => `${lastActivity?.success_download}/${lastActivity?.total_docs || 0}`}
                            className="pr-6"
                          />
                        )}
                      </Col>
                      {/* {dataDetail?.stamp_in_progress?.stamp_status === STAMP_DOC_STATUS.INCOMPLETE &&
                        <>
                          <Divider className="m-0" />
                          <Col span={24}>
                            <h3 className="text-gray-500 text-lg md:text-xl font-medium mb-2">
                              {t("List of Failed Document")}
                            </h3>
                            <Table columns={columnsFailedStampDocs} dataSource={dataDetail?.stamp_in_progress?.failed_docs || []} />
                          </Col>
                        </>} */}
                    </Row>
                  </Col>
                </Row>
              </Skeleton>

            </Card>
          </Col>
        )}
        {/* } */}

        {
          signers.length > 0 &&
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Card className="rounded-md">
                  <Row>
                    <Col span={24} className="mb-7">
                      <h3 style={{ color: '#5C5FF5B' }} className="text-gray-500 text-lg md:text-xl font-medium">
                        {t("Signature Receipt")}
                      </h3>
                    </Col>
                    {signers.map((signer, index) => (
                      <>
                        <Col span={24} className="mb-7">
                          <div style={{ background: '#F4F4F4' }} className='flex justify-between items-center p-3'>
                            <div className=''>
                              <h1>
                                <span className='text-xl text-gray-500 font-medium'>{t("Signature")} ({index + 1}) : </span>
                                <span className='text-xl font-sans font-medium'>{signer.signer_name}</span>
                              </h1>
                            </div>
                            <div className='flex gap-5'>
                              {/* <div style={{ background: `${signer.is_signed === false && userProfile?.email === signer.email ? '#E9290F' : (signer.is_signed ? '#8BC34A' : '#48A1D9')}` }} className=' flex justify-center items-center w-full px-4 py-1 rounded-xl h-8 tracking-tight '>
                                <span className='text-white whitespace-nowrap'>{`${signer.is_signed === false &&  userProfile?.email === signer.email ? t(signer?.sign_status == 1 && dataDetailSigned?.send_doc_success != false ? 'Not Signed Yet' : 'Failed to Sign') : (signer.is_signed ? t('Signed') : t('Pending'))}`}</span>
                              </div> */}
                              <StatusSign
                                data={signer}
                              />
                              {dataDetailSigned?.send_doc_success == true && signer?.sign_status == 1 && signer?.success_signed == 0 && userProfile?.user_id == signer?.signer_id && !isExpired(dataDetailSigned?.receipts?.map(({expired_at}) => Number(expired_at))) &&
                                <Button
                                  type='primary'
                                  // disabled={dataDetailSigned?.signer[index - 1]?.is_signed == false && dataDetailSigned?.sign_type == "Sequential"}
                                  disabled={signers.find((prev_signer) => prev_signer.sequence == signer.sequence - 1)?.signed_at == null && signer.sign_type == 2} // For Sequential
                                  onClick={() => {
                                    let dataDoc = {
                                      ...dataDetailSigned?.document,
                                      current_version: id,
                                      creator: dataDetailSigned?.creator,
                                      sign_type: dataDetailSigned?.sign_type,
                                      assign_type: dataDetailSigned?.assign_type,
                                      receipts: dataDetailSigned?.receipts
                                    };
                                    history.push(`/sign-management/sign-bulk`, { dataDoc })
                                  }}
                                  className='w-full h-8 rounded-md text-white translate-y-3'
                                >
                                  {t("Sign Now")}
                                </Button>
                              }
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <Row gutter={[24, 40]}>
                            <Col span={24} className="mb-12">
                              <Row className="pl-1">
                                <Col lg={8}>
                                  <Row>
                                    <Col span={24}>
                                      <p className='text-gray-400 text-xs md:text-sm'>Email</p>
                                    </Col>
                                    <Col span={24}>
                                      <h4 className='text-gray-800 text-sm md:text-base font-medium' >{signer?.email || '-'}</h4>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={8}>
                                  <Row>
                                    <Col span={24}>
                                      <p className='text-gray-400 text-xs md:text-sm'>{t("Time")}</p>
                                    </Col>
                                    <Col>
                                      <h4 className='text-gray-800 text-sm md:text-base font-medium'>
                                        {signer.signed_at === null ? "-" :
                                          moment(
                                            signer.signed_at,
                                            moment.ISO_8601
                                          ).format("DD MMMM YYYY, HH:mm:ss")}
                                      </h4>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={8}>
                                  <Row>
                                    <Col span={24}>
                                      <p className='text-gray-400 text-xs md:text-sm'>{t("Notes")}</p>
                                    </Col>
                                    <Col>
                                      <h4 className='text-gray-800 text-sm md:text-base font-medium'>{signer?.signer_note || '-'}</h4>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24} className="mb-12">
                              <Row className="pl-1">
                                <Col lg={8}>
                                  <Row>
                                    <Col span={24}>
                                      <p className='text-gray-400 text-xs md:text-sm'>{t("Device")}</p>
                                    </Col>
                                    <Col>
                                      <h4 className='text-gray-800 text-sm md:text-base font-medium'>
                                        {signer?.device || '-'}
                                      </h4>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={8}>
                                  <Row>
                                    <Col span={24}>
                                      <p className='text-gray-400 text-xs md:text-sm'>{t("IP Address")}</p>
                                    </Col>
                                    <Col>
                                      <h4 className='text-gray-800 text-sm md:text-base font-medium'>
                                        {signer?.ip || '-'}
                                      </h4>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </>
                    ))}
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        }
        {dataDetail?.receipts?.length > 0 && (
          <Col span={24}>
            <SignatureReceipt
              receiptHistory={dataDetail?.receipts}
              isFetching={isFetching}
            />
          </Col>
        )}
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("Document Activity")}
                      <span className="text-gray-400">
                        ({t("Last 30 Days From Date")})
                      </span>
                    </h3>
                  </Col>
                  <Col>
                    <DatePicker
                      format="DD/MM/YYYY"
                      onChange={handleChangeActivityDate}
                      allowClear={false}
                      disabledDate={disabledDate}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={datasetsListTable(dataActivity)}
                  scroll={{ x: 800 }}
                  loading={isFetching}
                  pagination={{
                    current: paramsTable.page,
                    pageSize: paramsTable.row,
                    total: dataActivity[0] ? dataActivity[0]?.total : 0,
                  }}
                  onChange={handleChangeTable}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        visible={isModalDownload}
        onCancel={toggleModalDownload}
        footer={false}
        width={800}
      >
        <Row gutter={[24, 24]} className="pr-4">
          <Col span={24}>
            <h3 className="text-gray-500 text-lg md:text-xl font-medium">
              {t("Version Document (Current)")}
            </h3>
          </Col>
          <Col span={24}>
            <Table
              columns={columnsVersion}
              dataSource={[datasetsVersion[datasetsVersion.length - 1]]}
              scroll={{ x: 500 }}
              loading={isFetching}
              pagination={false}
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]} className="pr-4 mt-4">
          <Col span={24}>
            <h3 className="text-gray-500 text-lg md:text-xl font-medium">
              {t("Version History")}
            </h3>
          </Col>
          <Col span={24}>
            <Table
              columns={columnsVersion}
              dataSource={datasetsHistory(datasetsVersion)}
              scroll={{ x: 500 }}
              loading={isFetching}
            // pagination={{
            //   current: paramsTable.page,
            //   pageSize: paramsTable.row,
            //   // total: dataListDocument[0] ? dataListDocument[0]?.total : 0,
            // }}
            // onChange={handleChangeTable}
            />
          </Col>
        </Row>
      </Modal>

      {/* modal OTP */}
      <Modal
        title={t("Are you sure?")}
        subtitle={`${t("Please make sure your data is correct. OTP Code automatic has sent to your email, let’s check!")} ${dataDetail?.email}`}
        type="warning"
        visible={isModalOtp}
        onCancel={() => {
          setIsModalOtp(false);
        }}
      >
        <Spinner loading={loading} />
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row justify-between">
            <p>OTP Code</p>
            <Countdown
              date={countdownValue}
              renderer={renderer}
              ref={countdownRef} />
          </div>
          <OtpInput
            value={otpValue}
            onChange={(otp) => setOtpValue(otp)}
            numInputs={6}
            isInputNum
            className="text-gray-800"
            containerStyle={{
              display: "flex",
              justifyContent: "space-between",
            }}
            inputStyle={{
              width: "3rem",
              height: "3rem",
              fontWeight: "bold",
              border: "2px solid #DBDBDB",
              borderRadius: 4,
              color: "#000000",
            }}
          />
          <Form onFinish={handleSubmit} autoComplete={false}>
            <div>
              <p>Note (Optional)</p>
              <Form.Item name="note">
                <TextArea name="note" rows={4} />
              </Form.Item>
            </div>
            <Form.Item>
              <div className="flex flex-row space-x-4">
                <Button
                  // disabled
                  onClick={() => {
                    setIsModalOtp(false);
                  }}
                  className="w-full"
                  ghost
                  type="secondary"

                >
                  {t("Back")}
                </Button>
                <Button
                  disabled={otpValue?.length < 6}
                  className="w-full"
                  type="primary"
                  htmlType="submit"
                >
                  {t("Submit")}
                </Button>
              </div>
            </Form.Item>
          </Form>
          <Divider />
          <div className="text-center">
            <p className="text-gray-500 text-xs">
              {t("By pressing the ‘Submit’ button means that you have agreed to the")}
            </p>
            <p className="text-blue-500 text-xs">{t("Terms & Conditions")}</p>
          </div>
        </div>
      </Modal>

      <Modal
        visible={modalProgress}
        subtitle={t("Please don't reload or leave this page until process is done.")}
        title={t("Your Document Has Been Proceed")}
        width={500}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Progress
              type="line"
              strokeWidth={20}
              percent={Number(totalRetrySend) / Number(dataDetailSigned?.document?.total_docs || 0) * 100}
              format={percent => `${totalRetrySend}/${Number(dataDetailSigned?.document?.total_docs || 0)}`}
            />
          </Col>
        </Row>
        <Row gutter={24} justify="center">
          <div className="flex justify-center">
              <Button 
                className="bg-blue-700 rounded-md text-white w-40"
                disabled={totalRetrySend < dataDetailSigned?.document?.total_docs && !triggerSignSystem}
                onClick={handleTrigerSignSystem}
                // onClick={() => {
                //   setModalProgress(!modalProgress)
                //   if(userProfile?.is_system_sign){
                //     setIsModalSystem(true)
                      
                //   } else {
                //     toggleModalOtp();
                //     handleSendOtp();
                //     setCountdownValue(Date.now() + 120000)
                //     setTimeout(() => {
                //       countdownRef.current.api.start()
                //     }, 100) 
                //   }
                            
                // }}
              > 
                {t("Next")} 
              </Button>
          </div>
        </Row>  
      </Modal>
      <Spinner loading={loading} />
    </>
  );
}
