import React from "react";
import { Card, Col, Input, Row, Form, Divider } from "antd";
import Fade from "react-reveal/Fade";
import { NumericInput } from "../../components";

export default function ClientInformation({ payload, setPayload }) {
  function handleChangePhone(e) {
    setPayload({ ...payload, phone_pic: e });
  }
  return (
    <Fade>
      <Card className="rounded-md">
        <Row gutter={[24, 12]}>
          <Col xs={24} md={12}>
            <Form.Item
              label="Office Name"
              name="office_name"
              rules={[
                {
                  required: true,
                  message: "Please input office name!",
                },
              ]}
            >
              <Input placeholder="Enter office name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Email Office"
              name="email_office"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input email office!",
                },
              ]}
            >
              <Input placeholder="Enter email office" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Divider className="my-1" />
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="First Name  PIC"
              name="first_name_pic"
              rules={[
                {
                  required: true,
                  message: "Please input first name!",
                },
              ]}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Last Name  PIC"
              name="last_name_pic"
              rules={[
                {
                  required: true,
                  message: "Please input last name!",
                },
              ]}
            >
              <Input placeholder="Enter last name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            {/* <Form.Item
              label="Phone Number PIC"
              name="phone_pic"
              rules={[
                {
                  required: true,
                  message: "Please input phone number!",
                },
              ]}
            >
              <Input placeholder="Enter phone number" />
            </Form.Item> */}
            <div className="flex flex-col space-y-2">
              <label>
                <span className="text-red-500 mr-1">*</span>Phone Number PIC
              </label>
              <NumericInput
                placeholder="Enter phone number"
                maxLength={14}
                value={payload.phone_pic}
                onChange={handleChangePhone}
              />
            </div>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Email PIC"
              name="email_pic"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input email PIC!",
                },
              ]}
            >
              <Input placeholder="Enter email PIC" />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Fade>
  );
}
