import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Dashboard } from "./components";
import { ProtectedRoute, routes } from "./config";
import { useFetchProfileUserQuery } from "./config/features/user";
import { ForgotPassword, Login, Quotation, PaymentSuccess, PaymentFailed, NotFound } from "./pages";
function App() {
  const access = localStorage.getItem("access");
  const { data: userProfile = {} } = useFetchProfileUserQuery();

  useEffect(() => {
    if (access) {
      const tokenExp = jwtDecode(access).exp;
      const dateNow = new Date().getTime();
      if (tokenExp * 1000 < dateNow) {
        localStorage.removeItem("access");
        window.location.reload();
      }
    }
  }, [access]);
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/download-quotation" component={Quotation} />

        <Route path="/callback/payment/payment_success" component={PaymentSuccess} />
        <Route path="/callback/payment/payment_failed" component={PaymentFailed} />
        <Route path="/notfound" component={NotFound} />
        {access && (
          <Dashboard>
            {routes(userProfile).map((item, index) => {
              return (
                !item.disable && (
                  <ProtectedRoute
                    key={index}
                    path={item.path}
                    component={item.component}
                    exact={item.exact}
                  />
                )
              );
            })}
            {/* <Redirect exact={false} from="/:other" to="/notfound" /> */}
          </Dashboard>
        )}
        <Redirect exact={false} from="*" to="/login" />
      </Switch>
    </Router>
  );
}

export default App;
