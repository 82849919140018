import { Card, Col, Row, Tooltip } from "antd";
import React, { useEffect } from "react";
import { ReactComponent as AlertIcon } from "../../assets/icons/alert-icon.svg";
import { ReactComponent as BackupIcon } from "../../assets/icons/backup-sm.svg";
import { ReactComponent as MateraiIcon } from "../../assets/icons/qrcode-sm.svg";
import { ReactComponent as SignatureIcon } from "../../assets/icons/signature-sm.svg";
import { ReactComponent as StorageIcon } from "../../assets/icons/storage-sm.svg";
import { ReactComponent as GeneratedEmet } from "../../assets/icons/generated-emet.svg";
import { numberCommas } from "../../utils";
import { ReactComponent as WarningIcon } from "../../assets/icons/warning-icon.svg";
import { useTranslation } from "react-i18next";

const warningText = <span className="text-black">Sisa kuota anda sudah hampir habis, silahkan lakukan top up Kuota</span>;

export default function CardPrimary({ data }) {
  const { t } = useTranslation();
  function Quotation(props) {
    function generateIcon(name) {
      switch (name) {
        case "Remaining e-Meterai Quota":
          return <MateraiIcon />;

        case "e-Signature per-Sign":
          return <SignatureIcon />;

        case "DMS per-Page":
          return <StorageIcon />;

        case "Total Generated E-Meterai":
          return <GeneratedEmet />;

        default:
          return <BackupIcon />;
      }
    }

    return (
      <div className="flex flex-row space-x-4">
        <div>{generateIcon(props.title)}</div>
        <div>
          <div className="flex flex-col space-y-2">
            <div>
              <h3 className="flex items-center gap-1 text-xs md:text-sm text-white font-medium">
                {t(props.title)}
                {props.warning && (
                  <Tooltip color="white" placement="topLeft" title={warningText}>
                    <WarningIcon />
                  </Tooltip>
                )}
              </h3>
            </div>
            <div className="flex flex-row space-x-2 items-center">
              <h2 className="text-2xl md:text-3xl lg:text-4xl text-white font-semibold">
                {numberCommas(props.quota)}
              </h2>
            </div>
          </div>
        </div>
        <div className="hidden xl:block">
          <div className="h-full bg-gray-300" style={{ width: 1 }} />
        </div>
      </div>
    );
  }

  return (
    <Card
      className="rounded-md"
      style={{
        backgroundImage: "url('/images/cover-login.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Row gutter={[6, 24]} align="middle">

        {/* Persiapan UAT & SIT Overview Platform VAS BJT */}
        {data?.map((item, index) => {
          return (
            <Col xs={12} lg={8} xl={6} key={index}>
              <Quotation
                title={item?.name}
                quota={item?.remaining}
                warning={item?.warning}
              />
            </Col>
          );
        })}
        {/* Persiapan UAT & SIT Overview Platform VAS BJT  */}
        {/* <Col xs={12} lg={8} xl={6}>
          <Quotation
            title="Quota E-Meterai"
            quota={data[0]?.remaining}
            total={`${numberCommas(data[0]?.max)}`}
          />
        </Col> */}
      </Row>
    </Card>
  );
}
