import { Card, Col, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFetchListModuleQuery } from "../../config/features/general";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { useFetchLogModuleQuery } from "../../config/features/admin";
import { Button } from "../../components";
import { errorMessage } from "../../utils";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function ModuleManagement() {
  const { t } = useTranslation();
  const history = useHistory();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };


  const [dataSource, setDataSource] = useState([]);

  const [paramsLog, setParamsLog] = useState({
    row: 5,
    page: 1,
  });

  const { data: userProfile = {} } = useFetchProfileUserQuery();

  const {
    data: dataModule = {},
    refetch,
    isFetching,
    isError,
    error,
  } = useFetchListModuleQuery(userProfile?.office_id);

  const { data: dataLogModule = [], refetch: refetchLog } =
    useFetchLogModuleQuery(paramsLog);

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
  }, [isError, error]);

  useEffect(() => {
    refetch();
    refetchLog();
  }, [refetch, refetchLog]);

  useEffect(() => {
    function generateModule(listModule) {
      const module = [];

      for (let i = 0; i < Object.keys(listModule)?.length; i++) {
        if (listModule[Object.keys(listModule)[i]] !== null) {
          switch (Object.keys(listModule)[i]) {
            case "stamp_m":
              module.push({
                module: "E-Meterai",
                status: Object.keys(listModule)[i],
              });
              break;

            case "sign_m":
              module.push({
                module: "Digital Signature",
                status: Object.keys(listModule)[i],
              });
              break;

            case "user_m":
              module.push({
                module: "User Management",
                status: Object.keys(listModule)[i],
              });
              break;

            case "group_m":
              module.push({
                module: "Group Management",
                status: Object.keys(listModule)[i],
              });
              break;

            case "office_m":
              module.push({
                module: "Office Management",
                status: Object.keys(listModule)[i],
              });
              break;

            case "document_m":
              module.push({
                module: "Document Management",
                status: Object.keys(listModule)[i],
              });
              break;

            case "backup_m":
              module.push({
                module: "Backup Secure Document",
                status: Object.keys(listModule)[i],
              });
              break;

            case "blockchain_m":
              module.push({
                module: "Blockchain Document",
                status: Object.keys(listModule)[i],
              });
              break;

            default:
              break;
          }
        }
      }

      setDataSource(module);
    }

    generateModule(dataModule);
  }, [dataModule]);

  const columns = [
    {
      title: "Module Name",
      dataIndex: "module",
      key: "module",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          dataModule &&
          Object.keys(dataModule).length > 0 &&
          (dataModule[status] ? (
            <p className="text-blue-600">Active</p>
          ) : (
            <p className="text-gray-500">Inactive</p>
          ))
        );
      },
    },
  ];

  const columnsHistory = [
    {
      title: "Activity",
      dataIndex: "turned_off",
      key: "turned_off",
      render: (_, record) => {
        return (
          <>
            <p>
              {record?.turned_on &&
                `Admin PIC activates module ‘${record?.turned_on}’`}
            </p>
            <p>
              {record?.turned_off &&
                `Admin PIC inactivates module ‘${record?.turned_off}’`}
            </p>
          </>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY, HH:mm");
      },
    },
  ];

  function handleChangeTableLog(pagination) {
    setParamsLog({
      ...paramsLog,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <h1 className="text-xl md:text-2xl font-medium font-sans">
              {t("modulmanagement")}
              </h1>
            </Col>
            <Col>
              <Button
                className="md:w-40"
                type="primary"
                onClick={() =>
                  history.push({
                    pathname: `/module-management/edit/${userProfile?.office_id}`,
                    state: dataModule,
                  })
                }
              >
                {t("btnedit")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  {t("listmodule")}
                </h3>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  scroll={{ x: 300 }}
                  loading={isFetching}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  {t("HistoryUpdate")}
                </h3>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsHistory}
                  dataSource={dataLogModule?.data}
                  scroll={{ x: 300 }}
                  pagination={{
                    current: paramsLog.page,
                    pageSize: paramsLog.row,
                    total: dataLogModule?.total,
                  }}
                  onChange={handleChangeTableLog}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}
