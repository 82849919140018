import { FilterOutlined, PlusOutlined, RightOutlined, SettingOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Select,
  Divider,
  DatePicker,
  Form,
  Table,
  message
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useFetchListDetailHistorySettlementQuery, useAddAdminReconStatusMutation } from "../../config/features";
import { errorMessage, exportExcel, getBlob } from "../../utils";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import { objectToQueryString } from "../../utils/queryString";
import { Spa } from "@material-ui/icons";
import { Modal, Spinner } from "../../components";
import "../../styles.css";

const { Search } = Input;
const { Option } = Select;

export default function DetailHistorySettlement() {
  const location = useLocation();
  const history = useHistory();
  const { id }  = useParams();

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [isModalConfirm, setIsModalConfirm] = useState(false)
  const [statusRecon, setStatusRecon] = useState(2)
//   const [statusReconF, setStatusReconF] = useState(2)
  const dataListDocType = [
    {
        status: "SETTLEMENT",
        code: 2
    },
    {
        status: "READY TO SETTLE",
        code: 3
    },
    {
        status: "WAITING TO SETTLE",
        code: 0
    },
    {
        status: "NOT SETTLEMENT",
        code: 1
    }
  ]

  const [paramsTables, setParamsTable] = useState({
    page: 1,
    row: 10,
    search: "",
    start_date: moment(location?.state?.b.start_date).utc(true).format("YYYY-MM-DD").toString(),
    end_date: moment(location?.state?.b.end_date).utc(true).format("YYYY-MM-DD").toString(),
    recon_id: id,
    office_id: location?.state?.c,
  });

  const {
    data: dataListHistory = [],
    isFetching,
    isError,
    error,
    refetch
   } = useFetchListDetailHistorySettlementQuery(paramsTables)
  
   const [addAdminReconStatus, { isSuccess, isLoading, errors, data, isErrors }] = useAddAdminReconStatusMutation();

  const columns = [
    {
        title: "No",        
        width: 50,               
        dataIndex: "no",
        key: "no",
      },
      {
        title: "Serial Number SCM",        
        dataIndex: "sn",
        key: "sn",
      },
      {
        title: "File",
        dataIndex: "file",
        key: "file",
      },
      {
        title: "Tanggal Update",
        dataIndex: "tanggal_peggunaan",
        key: "tanggal_penggunaan",
        render: (tanggal_penggunaan) => moment(tanggal_penggunaan).utc(true).format("YYYY-MM-DD").toString()
      },
      {
        title: "Price Product",
        dataIndex: "price",
        key: "price",
      },
      {
        title: "Serial Number System",
        dataIndex: "sn_system",
        key: "sn_system",
      },
      {
        title: "Tanggal Stamping",
        dataIndex: "tanggal_update",
        key: "tanggal_update",
        render: (tanggal_update) => moment(tanggal_update).utc(true).format("YYYY-MM-DD").toString()
      },
      {
        title: "Status Stamp",
        dataIndex: "status_sn",
        key: "status_sn",
      },
      {
        title: "Status",
        dataIndex: "status_settle",
        key: "status_settle",
      },
      {
        title: "Action",
        // dataIndex: "tanggal_stamping",
        // key: "tanggal_stamping",
      },
  ];

  async function onFinish(){
    // console.log("id_rekon", id);
    setIsModalConfirm(false);
    try {      
        const formData = new FormData();              
        formData.append("id", id);   
        formData.append("status", statusRecon);      
        // addReconsileFile(formData);
        addAdminReconStatus(new URLSearchParams(formData));
      } catch (error) {
        message.error(error.message);
      }
  }


  useEffect(() => {
    if (isError) {
      errorMessage(error)
      //console.log(error);
    }
  }, [isError, error])

  useEffect(() => {
    if (isErrors) {
      setIsModalConfirm(false);
      errorMessage(errors);
    }
  }, [isErrors, errors]);

  useEffect(() => {
    refetch();
   }, [refetch])



  return (
    <>
    <Row gutter={[24, 24]}>
          <Col span={24}>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => 
                      history.goBack()
                    }
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    Detail Rekonsiliasi {location?.state?.b?.rekonsile_code}
                  </h1>
                </Col>
              </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
                <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Row gutter={[24, 24]} justify="space-between" style={{marginBottom: 20}}>
                        <Col>
                            <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                            Informasi Data
                            </h3>
                        </Col>
                        <Col>
                            <Row gutter={[12, 12]}>
                                <Col>
                                    <Search
                                    placeholder="Search"
                                    allowClear
                                    // onSearch={onSearch}
                                    // onChange={(e) =>
                                    //     setTimeout(() => {
                                    //     onSearch(e.target.value);
                                    //     }, 1000)
                                    // }
                                    style={{
                                        width: 200,
                                        borderRadius: "10px !important",
                                    }}
                                    />
                                </Col>
                                <Col>
                                    <Dropdown
                                        // overlay={menuFilter}
                                        // onVisibleChange={handleVisibleFilter}
                                        // visible={visibleFilter}
                                        // trigger={["click"]}
                                    >
                                    <Button
                                        type="primary"
                                        ghost
                                        icon={<FilterOutlined />}
                                    />
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Col>
                    </Row>   
                    <Row gutter={[24,24]} justify="space-between" style={{marginBottom: 100}}>
                        <Col span={24}>      
                        <Row>
                            <Col span={5}>
                                <span className="text-black-500 font-medium"> Nama Perusahaan </span>
                            </Col>
                            <Col span={1}>
                                <span className="text-black-500 font-medium"> : </span>
                            </Col>
                            <Col span={7}>   
                                
                                {location?.state?.a?.office_name}
                            </Col>
                        </Row>          
                        <Row>
                            <Col span={5}>
                                <span className="text-black-500 font-medium"> Tanggal Rekonsiliasi </span>
                            </Col>
                            <Col span={1}>
                                <span className="text-black-500 font-medium"> : </span>
                            </Col>
                            <Col span={7}>   
                                
                                {`${ moment(location?.state?.b?.start_date).utc(true).format("YYYY/MM/DD").toString()} - ${moment(location?.state?.b?.end_date).utc(true).format("YYYY/MM/DD").toString()}`}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={5}>
                                <span className="text-black-500 font-medium"> Periode </span>
                            </Col>
                            <Col span={1}>
                                <span className="text-black-500 font-medium"> : </span>
                            </Col>
                            <Col span={7}>
                            
                                { moment(location?.state?.b?.start_date).utc(true).format("MMMM").toString() }
                            </Col>
                        </Row>                  
                        <Row>
                            <Col span={5}>
                                <span className="text-black-500 font-medium"> Status</span>
                            </Col>
                            <Col span={1}>
                                <span className="text-black-500 font-medium"> : </span>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={10}>
                                        {location?.state?.b?.status} 
                                    </Col>
                                    <Col span={10}>
                                    <Button 
                                        className="w-20" 
                                        size="small" 
                                        type="primary"
                                        onClick={()=>{
                                            setIsModalConfirm(true);
                                        }}
                                        >
                                        Update
                                    </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={5}>
                                <span className="text-black-500 font-medium"> Status Konfirmasi</span>
                            </Col>
                            <Col span={1}>
                                <span className="text-black-500 font-medium"> : </span>
                            </Col>
                            <Col span={11}>
                                Lihat                                
                            </Col>
                        </Row>                                                                                   
                    </Col>
                    </Row> 
                    <Row gutter={[24,24]} justify="space-between">
                        <Col span={12}>  
                            <Row>
                                <Col span={24}>
                                    <span className="text-black-700 font-medium"> Tabel Data Log Internal System </span>
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col span={8}>
                                    <span className="text-black-500 font-medium"> Total Data Log System </span>
                                </Col>
                                <Col span={1}>
                                    <span className="text-black-500 font-medium"> : </span>
                                </Col>
                                <Col span={10}>                                       
                                    {parseInt(dataListHistory[0]?.sn_system_count)+parseInt(dataListHistory[0]?.sn_system_null)}
                                </Col>
                            </Row>          
                            <Row>
                                <Col span={8}>
                                    <span className="text-black-500 font-medium"> Total Data Stamp </span>
                                </Col>
                                <Col span={1}>
                                    <span className="text-black-500 font-medium"> : </span>
                                </Col>
                                <Col span={10}>   
                                    
                                    {dataListHistory[0]?.sn_system_count}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <span className="text-black-500 font-medium"> Total Data Null </span>
                                </Col>
                                <Col span={1}>
                                    <span className="text-black-500 font-medium"> : </span>
                                </Col>
                                <Col span={10}>
                                
                                    { dataListHistory[0]?.sn_system_null }
                                </Col>
                            </Row>                                                                                                                           
                        </Col>
                        <Col span={12}>      
                            <Row>
                                <Col span={24}>
                                    <span className="text-black-800 font-medium"> Tabel Data Stamp SCM Pemungut </span>
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col span={10}>
                                    <span className="text-black-500 font-medium"> Total Data Log SCM Peruri </span>
                                </Col>
                                <Col span={1}>
                                    <span className="text-black-500 font-medium"> : </span>
                                </Col>
                                <Col span={10}>                                       
                                    {parseInt(dataListHistory[0]?.sn_scm)+parseInt(dataListHistory[0]?.sn_scm_null)}
                                </Col>
                            </Row>          
                            <Row>
                                <Col span={10}>
                                    <span className="text-black-500 font-medium"> Total Data Stamp </span>
                                </Col>
                                <Col span={1}>
                                    <span className="text-black-500 font-medium"> : </span>
                                </Col>
                                <Col span={10}>                                       
                                    {dataListHistory[0]?.sn_scm}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={10}>
                                    <span className="text-black-500 font-medium"> Total Data Null </span>
                                </Col>
                                <Col span={1}>
                                    <span className="text-black-500 font-medium"> : </span>
                                </Col>
                                <Col span={10}>                                
                                    { dataListHistory[0]?.sn_scm_null }
                                </Col>
                            </Row>                                                                                                                               
                        </Col>
                    </Row>            
                </Col>              
                <Col span={24}>                
                    <Table
                        columns={columns}
                        dataSource={dataListHistory[0]?.settlement?.map((item, index) => ({ no: index + 1, ...item }))}
                        scroll={{ x: 850 }}
                        loading={isFetching}                        
                        rowClassName={(record, index) => (record.sn == null || record.sn_system == null) ? 'table-row-dark' : 'table-row-light'}
                        // pagination={{
                        //     current: paramsTable.page,
                        //     pageSize: paramsTable.row,
                        //     total: dataListRecordRekon[0] ? dataListRecordRekon[0]?.total : 0,
                        // }}
                        // onChange={handleChangeTable}
                    />
                </Col>
                </Row>
            </Card>
         </Col>
    </Row>
    <Modal
        title="Update Data"
        subtitle="After the reconciliation data had been updated. You can't change the data. Please make sure the data is correct."
        type="warning"
        visible={isModalConfirm}
        closable={false}>        
        
          <Select placeholder="Choose" value={statusRecon} onChange={(e) => { 
                // console.log(e);
                setStatusRecon(e);    

            }}>
            {
              dataListDocType?.length > 0 && dataListDocType?.map((data , index) => {
                return (
                  <Option key={index} value={data.code}>
                    {data.status}
                  </Option>
                )
            })
              }
                </Select>              
              <div className="flex flex-row space-x-4">
                <Button 
                    className="w-full" 
                    type="primary"
                    onClick={()=>{
                        setIsModalConfirm(false)
                    }} ghost>
                        Kembali
                </Button>
                <Button className="w-full" type="primary" onClick={onFinish}>
                {t("btndone")}
                </Button>
              </div>             
    </Modal>
    <Modal
        title="Berhasil Konfirmasi"
        subtitle="Terimakasih telah melakukan proses konfirmasi data settlement."
        type="success"
        visible={isSuccess}        
      >
        <div className="flex flex-row space-x-4">          
          <Button className="w-full" onClick={() => {history.goBack()}} type="primary">
            {t("btndone")}
          </Button>
        </div>
    </Modal>
    <Spinner loading={isLoading} />
    </>
  );
}