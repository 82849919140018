import { LeftOutlined } from "@ant-design/icons";
import { Col, Divider, message, Row, Select, Form, Input } from "antd";
import { Modal, Button, Spinner } from "../../components";
import pdfJsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { Document, Page, pdfjs } from "react-pdf";
import OtpInput from "react-otp-input";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as SignWithOtherIcon } from "../../assets/icons/sign-with-other.svg";
import SignSvg, {
  ReactComponent as SignIcon,
} from "../../assets/icons/sign.svg";
import Unstamp from '../../assets/img/icon/unstamp.png'
import axios from "axios";
import { rootApi } from "../../config/features/config";
import { getPosition, numberCommas } from "../../utils";
import Countdown from "react-countdown";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";
pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker;

const { Option } = Select;

const { TextArea } = Input

function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export default function SignWithOther() {
  const { t } = useTranslation();

  const tokenPeruri = useMemo(() => localStorage.getItem('peruri'), [])
  const tokenAccess = useMemo(() => localStorage.getItem('access'), [])
  const { state: { dataDoc } } = useLocation()
  const history = useHistory();
  const [pdfFile, setPdfFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [deltaPosition, setDeltaPosition] = useState({});
  const [sizing, setSizing] = useState({
    signature: { width: 150, height: 90 },
    paraf: { width: 150, height: 90 },
  });
  const [size, setSize] = useState({})
  let [totalSignature, setTotalSignature] = useState([]);
  const [openSubmit, setOpenSubmit] = useState(false)
  const [moveable, setMoveable] = useState(true);
  const [visibleDrawer] = useState(false);
  const [previewModal, setPreview] = useState(false)
  const [successModal, setsuccessModal] = useState(false)
  const [signer, setSigner] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [totalMaterai, setTotalMaterai] = useState({ total: 0, remaining: 0 })
  const [otpPreview, setOtpPreview] = useState(false)
  const [countdownValue, setCountdownValue] = useState(Date.now());
  const [isResendOtp, setIsResendOtp] = useState(false);
  const [otpValue, setOtpValue] = useState("");

  const [imgSign, setImgSign] = useState(null)
  const [imgParaf, setImgParaf] = useState(null)
  const [location, setLocation] = useState(null)
  const [reason, setReason] = useState(null)
  const [activeLocation, setActiveLocation] = useState(false)



  useEffect(() => {
    async function getQuoteMeterai() {
      const { data: { data } } = await axios({
        method: 'GET',
        url: `${rootApi}/quota`,
        headers: {
          token: tokenAccess
        }
      })
      setTotalMaterai({ total: data[1].remaining, remaining: data[1].remaining })
    }
    getQuoteMeterai()
    if (totalMaterai !== null) {
      setLoading(false)
    }
  }, [])


  useEffect(() => {
    async function getPreviewDoc() {
      try {
        setLoading(true)

        const { data: { data: dataDocPrev } } = await axios({
          method: 'GET',
          url: `${rootApi}/sign/preview-doc/${dataDoc.sign_id}`,
          headers: {
            token: tokenAccess,
            "token-peruri-sign": tokenPeruri
          }
        })

        setTotalSignature(prev => {

          let coordinates = dataDocPrev.sign_coordinates

          let dataDup = []

          for (let i = 0; i < coordinates.length; i++) {
            dataDup.push(
              {
                index: coordinates[i].index || i + 1,
                type: coordinates[i].type,
                isSigned: dataDocPrev?.is_signed,
                page: Number(coordinates[i].page),
                signer_name: dataDocPrev.signer_name,
                isv: coordinates[i].isv,
                llx: coordinates[i].llx,
                lly: coordinates[i].lly,
                location: coordinates[i].location,
                reason: reason,
                urx: coordinates[i].urx,
                ury: coordinates[i].ury,
              }
            )
          }

          return dataDup

        })

        const populatedDeltaPosition = getDeltaByPage(dataDocPrev?.sign_coordinates)
        setDeltaPosition(populatedDeltaPosition)

        if (dataDocPrev.base64Doc === null) {


          const { data: { data: dataDocument } } = await axios({
            method: 'GET',
            url: `${rootApi}/document/preview/${dataDoc.doc_id}`,
            headers: {
              token: tokenAccess,
              "token-peruri-sign": tokenPeruri
            }
          })

          const documentBuffer = base64ToArrayBuffer(dataDocument)

          setPdfFile(documentBuffer)

          setLoading(false)

        }
        else {

          const documentBuffer = base64ToArrayBuffer(dataDocPrev.base64Doc)

          setPdfFile(documentBuffer)

          setLoading(false)

        }

      } catch (e) {

        message.error(t("Sorry we cant get your document now , please try again!"))

      }
    }

    getPreviewDoc()


  }, [])

  useEffect(() => {
    async function getSignature() {

      setLoading(true)

      try {

        const { data } = await axios({
          method: 'GET',
          url: `${rootApi}/sign/preview-speciment`,
          headers: {
            token: tokenAccess,
            "token-peruri-sign": tokenPeruri
          }
        })

        let sign = new Image()
        sign.src = data.data.base64Speciment
        sign.onload = function () {
          setSizing(prev => ({
            ...prev,
            signature: { width: sign.width, height: sign.height }
          }))
        }
        let paraf = new Image()
        paraf.src = data.data.base64Paraf
        paraf.onload = function () {
          setSizing(prev => ({
            ...prev,
            paraf: { width: paraf.width, height: paraf.height }
          }))
        }

        if (data.data.base64Speciment === null && data.data.base64Paraf === null) {
          message.info(t("Please upload your signature and paraf before sign!"))
        }
        else {

          if (data.data.base64Speciment !== null && data.data.base64Paraf !== null) {
            setImgSign(data?.data?.base64Speciment)
            setImgParaf(data?.data?.base64Paraf)
          }

          if (data.data.base64Speciment === null) {
            message.info(t("Please upload your signature before sign!"))
          }

          if (data.data.base64Paraf === null) {
            message.info(t("Please upload your paraf before sign!"))
          }
        }

        setLoading(false)

      } catch (e) {

        const { data: { error } } = e.response

        setLoading(false)

        message.error(error)

      }

    }

    getSignature()

  }, [])

  useEffect(() => {
    const signedSignature = totalSignature.filter(item => item.isSigned)

    if (signedSignature.length === totalSignature.length) {
      setOpenSubmit(true)
    } else {
      setOpenSubmit(false)
    }

  }, [totalSignature, setOpenSubmit])


  const getUserWithCoor = (user, deltaPosition, totalSignature) => {

    //  Grouping the delta position by page
    const deltaPositionKeys = Object.keys(deltaPosition)
    let deltaGroupByPage = {}
    for (let i = 0; i < deltaPositionKeys.length; i++) {
      const key = deltaPositionKeys[i];
      const item = deltaPosition[key];
      const itemWithIndex = { index: key, ...item }

      if (deltaGroupByPage[item.page]) {
        deltaGroupByPage[item.page] = [...deltaGroupByPage[item.page], itemWithIndex]
      } else {
        deltaGroupByPage[item.page] = [itemWithIndex]
      }
    }

    // Normalize the coordinates by page 
    const pageKeys = Object.keys(deltaGroupByPage)
    let normalizedDelta = {}
    for (let i = 0; i < pageKeys.length; i++) {
      const pageKey = pageKeys[i];
      const deltaArr = deltaGroupByPage[pageKey];

      let heightOffset = 0;
      for (let j = 0; j < deltaArr.length; j++) {
        const item = deltaArr[j];
        const offset = j;
        const localSizing = item.type === "PARAF" ? sizing.paraf : sizing.signature

        normalizedDelta[item.index] = {
          llx: `${item.x}`,
          lly: `${Math.abs(item.y) - localSizing?.height - (heightOffset)}`,
          urx: `${item.x + localSizing?.width}`,
          ury: `${Math.abs(item.y) - (heightOffset)}`,
        }

        heightOffset += localSizing?.height
      }
    }

    // Prepared signature data by signer
    let data = []
    for (let i = 0; i < user.length; i++) {
      const userItem = user[i];
      // Filtered by is_signed
      const totalSignatureFiltered = totalSignature.filter((item) => userItem.signer_name === item.signer_name)

      const coordinate = totalSignatureFiltered.map((item, i) => {
        const index = item.index;
        const page = item.page;
        const { llx, lly, urx, ury } = normalizedDelta[index];

        return ({
          index,
          llx: `${llx}`,
          lly: `${lly}`,
          urx: `${urx}`,
          ury: `${ury}`,
          page: `${page}`,
          location: (location === null || location === '') ? '' : `${location?.city},${location?.country}`,
          reason: reason,
          isv: "YES",
        })
      })

      data.push({
        signer_name: userItem.signer_name,
        signer_email: userItem.signer_email,
        sender_note: userItem.sender_note,
        user_id: userItem.user_id,
        sign_coordinates: [...coordinate],
      })
    }

    return data
  }


  // This function is a reverse from get
  const getDeltaByPage = (coordinates) => {
    //  Grouping the coordinates position by page
    let coordinatesByPage = {}
    for (let i = 0; i < coordinates.length; i++) {
      const item = coordinates[i];
      const width = coordinates[i].urx - coordinates[i].llx;
      const height = coordinates[i].ury + coordinates[i].lly;
      // setSize(prev => [
      //   ...prev,
      //   { width: width, height: height }
      // ])
      const currentIndex = coordinates[i].index || i + 1
      setSize(prev => (
        {
          ...prev,
          [currentIndex]: { width: width, height: height }
        }
      ))
      if (coordinatesByPage[item.page]) {
        coordinatesByPage[item.page] = [...coordinatesByPage[item.page], item]
      } else {
        coordinatesByPage[item.page] = [item]
      }
    }

    // turn coordniate into delta position 
    const pageKeys = Object.keys(coordinatesByPage)
    let deltaPosition = {}
    let index = 1
    for (let i = 0; i < pageKeys.length; i++) {
      const pageKey = pageKeys[i];
      const coordinatesArr = coordinatesByPage[pageKey];

      let heightOffset = 0;
      for (let j = 0; j < coordinatesArr.length; j++) {
        const item = coordinatesArr[j];
        const offset = j;
        const localSizing = item.type === "PARAF" ? sizing.paraf : sizing.signature

        const currentIndex = item.index || index
        deltaPosition[currentIndex] = {
          x: Number(item.llx),
          y: -Math.abs(item.ury),
          page: pageKey,
        }

        index ++

        // heightOffset += localSizing.height
      }
    }

    return deltaPosition
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function handleChangePage(e) {
    setPageNumber(e);
  }

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setIsResendOtp(true);
      return <div />;
    } else {
      return (
        <p>
          {minutes}:{seconds}
        </p>
      );
    }
  };

  const handleResendOtp = async () => {

    setIsSubmit(true)

    setCountdownValue(Date.now() + 60000)

    const responseGetOtp = await axios({
      method: 'GET',
      url: `${rootApi}/sign/get-otp/${dataDoc.sign_id}`,
      headers: {
        "token": tokenAccess,
        "token-peruri-sign": tokenPeruri
      }
    })

    setOtpPreview(true)

    setIsSubmit(false)

    message.success(t("Resend OTP success check your email!"))

  }

  const handleSubmit = async () => {

    const body = { otp_code: `${otpValue}`, signer_note: reason }

    setIsSubmit(true)

    try {

      const response = await axios({
        method: 'POST',
        url: `${rootApi}/sign/sign-doc/${dataDoc.sign_id}`,
        data: body,
        headers: {
          "token": tokenAccess,
          "token-peruri-sign": tokenPeruri
        }
      })

      setIsSubmit(false)

      setPreview(false)

      setsuccessModal(true)

      setSigner([])

    } catch (e) {
      const { data: { error } } = e.response
      setIsSubmit(false)
      message.error(error)
    }
  }

  const handleSign = (index) => {

    if (totalMaterai.total > 0) {

      let dataDup = totalSignature.map(item => {

        if (item.index === index) {
          return { ...item, isSigned: true }
        }

        return item
      })

      setTotalSignature(dataDup)

      setTotalMaterai(prev => ({ ...prev, total: prev.total - 1 }))

    }
    else {
      message.info(t("Your e-sign quota is not enough"))
    }

  }

  async function generateSystemSignKey (){
    let r = (Math.random() + 1).toString(36).substring(6);
    const body = { key : `DLII${r}`, minutes: `30`, doc_id: dataDoc?.doc_id }
    const generateSystemKey = await axios({
      method : 'POST' ,
      url : `${rootApi}/sign/set-system-sign-key`,
      data : body ,
      headers : {
        "token" : tokenAccess
      }
    }).then((e) => {
      console.log("KEY ", e);
      return e;

    }).catch((ee) => {
      console.log("B => ", ee);
      message.error(ee.message);
    });
  }

  const handleSubmitSystem = async () => {
    setIsSubmit(true)
    try {
      await generateSystemSignKey()
      const body = {
        sign_id: [dataDoc?.sign_id],
        signer_note: reason ? reason : ''
      }
      const response = await axios({
        method: 'POST',
        url: `${rootApi}/sign/sign-doc-system`,
        data: body,
        headers: {
          "token": tokenAccess
        }
      })

      setIsSubmit(false)
      setPreview(false)
      setsuccessModal(true)
      setSigner([])
    } catch (e) {
      const { data: { error } } = e.response
      setIsSubmit(false)
      message.error(error)
    }
  }

  const handlePreviewOtp = async (id) => {

    setIsSubmit(true)

    try {

      let bodySend = {
        doc_id: `${id}`,
        requested_users: getUserWithCoor(signer, deltaPosition, totalSignature),
        assign_type: dataDoc?.assign_type === 'Sign With Others' ? '2' : '3',
        sign_type: dataDoc?.sign_type === 'pararel' ? '1' : '2'
      }


      const { data: dataSend } = await axios({
        method: 'GET',
        url: `${rootApi}/sign/get-otp/${dataDoc.sign_id}`,
        data: bodySend,
        headers: {
          "token": tokenAccess,
          "token-peruri-sign": tokenPeruri
        }
      })

      setCountdownValue(Date.now() + 60000)
      setOtpPreview(true)
      setIsSubmit(false)

    } catch (e) {

      const { data: { error } } = e.response

      setIsSubmit(false)

      message.error(error)

    }

  }

  function handleMovable() {
    setMoveable(!moveable);
  }

  const getLocation = async () => {

    setActiveLocation(false)

    const response = await getPosition()

    if (typeof response === 'object') {
      setLocation(response)
      setPreview(true)
      return
    }

    setLocation('')

  }



  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <Row gutter={[24, 3]}>
                    <Col span={24}>
                      <h1 className="text-xl md:text-2xl font-medium">
                        {dataDoc.doc_name}
                      </h1>
                    </Col>
                    <Col>
                      <Row gutter={[6, 24]}>
                        <Col>
                          <SignWithOtherIcon />
                        </Col>
                        <Col>
                          <p className="text-xs text-blue-700">
                            {t(dataDoc.assign_type)}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                className="bg-blue-700 rounded-md md:w-40 h-10 font-medium tracking-tight"
                type="primary"
                onClick={() => setActiveLocation(true)}
                disabled={!openSubmit}
              >
                {t("Submit")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div 
            // className="relative"
          >
            <div className="w-full bg-gray-600 rounded-t-md">
              <Row justify="space-between" align="middle" className="h-11">
                <Col xs={24} md={16} className="flex justify-center">
                </Col>
                <Col xs={24} md={8}>
                  <Row
                    className="pr-4 pb-2 md:pb-0"
                    gutter={[24, 24]}
                    justify="center"
                    align="middle"
                  >
                    <Col>
                      <div className="flex flex-row bg-blue-400 text-white rounded-full px-2 py-1 justify-center items-center">
                        <SignIcon />
                        <Divider className="h-4 bg-white" type="vertical" />
                        <p className="font-medium">{numberCommas(totalMaterai.total)}</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div
              className="bg-gray-300 rounded-b-md overflow-auto custom-scrollbar shadow-md pb-6"
              style={{ height: "80vh" }}
            >
              <div>
                <Row className={`flex p-8 justify-center`} gutter={16}>

                  <Col span={24} className="flex justify-center">
                    <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                      <Page pageNumber={pageNumber}>
                        {totalSignature.filter(item => item.page === pageNumber).map((item, index) => {
                          return (
                            <Draggable
                              key={item.index}
                              disabled
                              defaultPosition={deltaPosition[item.index]}
                              // bounds="parent"
                              bounds={false}
                            >
                              <div
                                className={`absolute ${moveable ? "cursor-move" : "cursor-pointer"
                                  }`}
                                // style={ item?.type === "PARAF" ? sizing.paraf : sizing.signature}
                                style={size[item.index]}
                                onDoubleClick={handleMovable}
                              >
                                <div
                                  style={{ fontSize: 10 }}
                                  className={clsx(
                                    "absolute top-0 inset-x-0",
                                    "bg-blue-400 mb-1 overflow-hidden inline",
                                    item.isSigned && 'invisible'
                                  )}
                                >
                                  <span className="text-white px-1 truncate w-full whitespace-nowrap overflow-hidden">{item.type === "PARAF" ? "Paraf" : "Signature"} ({index + 1}) {item.signer_name}</span>
                                </div>

                                <img style={{ height: '100%', width: '100%' }} src={!item?.isSigned ? Unstamp : (item?.type === "PARAF" ? imgParaf : imgSign)} alt="" />

                                <button
                                  disabled={!imgSign}
                                  onClick={() => handleSign(item.index)}
                                  className={clsx(
                                    "absolute -bottom-7",
                                    "w-full h-6",
                                    "bg-green-600 text-white text-xs",
                                    "rounded-md tracking-tight",
                                    item.isSigned && 'invisible',
                                    !imgSign && 'cursor-default'
                                  )}>
                                  {t("Sign")}
                                </button>
                              </div>
                            </Draggable>
                          );
                        })}
                      </Page>
                    </Document>
                  </Col>
                </Row>
                <div className="flex flex-row justify-center items-center space-x-2 bg-gray-100 shadow-md absolute bottom-5 right-5 p-2 rounded-md">
                  <Select
                    value={pageNumber}
                    size="small"
                    onChange={handleChangePage}
                  >
                    {Array.from(new Array(numPages), (_, index) => (
                      <Option value={index + 1}>{index + 1}</Option>
                    ))}
                  </Select>
                  <p>{`dari ${numPages} halaman`}</p>
                </div>
              </div>
            </div>
          </div>
          <Modal
            type="warning"
            visible={previewModal}
            onCancel={() => {
              setPreview(false)
            }}
            title={t("Are you sure?")}
            subtitle={t("After the data has been submit, you cannot change the data. Please make sure the data is correct.")}
          >

            <>
              <Spinner loading={isSubmit} />
              <div style={{ marginTop: 40 }} className="w-full mt-10">
                <div className="flex justify-between mb-5">
                  <span className="text-gray-500 ">
                    {t("Document Name")}
                  </span>
                  <div className="w-2" />
                  <span className="font-medium">
                    {dataDoc.doc_name}
                  </span>
                </div>
                <div className="flex justify-between mb-5">
                  <span className="text-gray-500">
                    {t("Sign Category")}
                  </span>
                  <div className="w-12" />
                  <Tooltip title={t(dataDoc?.assign_type || "-")}>
                    <span className="font-medium truncate">
                      {t(dataDoc?.assign_type || "-")}
                    </span>
                  </Tooltip>
                </div>
                <div className="flex justify-between mb-5">
                  <span className="text-gray-500">
                    {t("Signing Type")}
                  </span>
                  <div className="w-12" />
                  <Tooltip title={t(dataDoc?.sign_type) || "-"}>
                    <span className="font-medium truncate">
                      {t(dataDoc?.sign_type) || "-"}
                    </span>
                  </Tooltip>
                </div>
                <Divider className="mt-2 mb-4" />

                <Row style={{ marginTop: 24 }} gutter={[12, 0]}>
                  <Col span={12}>
                    <Button
                      ghost
                      onClick={() => setPreview(!previewModal)}
                      className=" w-full h-10 font-medium tracking-tight"
                      disabled={isSubmit}
                    >
                      <span>{t("Back")}</span>
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      className="w-full h-10 font-medium tracking-tight"
                      type="primary"
                      onClick={() => handlePreviewOtp(dataDoc.doc_id)}
                      disabled={isSubmit}
                    >
                      <span>{t("Next")}</span>
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          </Modal>
          <Modal
            visible={successModal}
            title={t("Success")}
            subtitle={t("The document has been send to other! Now other can sign with this document")}
            onCancel={() => setsuccessModal(false)}
            type="success"
          >
            <div className="flex flex-row space-x-4 gap-3">

              <Button
                type="primary"
                className="w-full h-11"
                onClick={() => history.replace('/sign-management')}
              >
                {t("Done")}
              </Button>
            </div>

          </Modal>
          <Modal
            type="warning"
            visible={previewModal}
            onCancel={() => {
              setPreview(false)
              setOtpValue("")
            }}
            title={t("Are you sure?")}
            subtitle={!otpPreview ?
              t(`After the data has been submit, you cannot change the data. Please make sure the data is correct.`)
              :
              t(`Please make sure your data is correct. OTP Code automatic has sent to your email, let’s check!`)
            }
          >
            {!otpPreview ?
              <>
                <Spinner loading={isSubmit} />
                <div style={{ marginTop: 40 }} className="w-full mt-10">
                  <div className="flex justify-between mb-5">
                    <span className="text-gray-500 ">
                      {t("Document Name")}
                    </span>
                    <div className="w-2" />
                    <span className="font-medium">
                      {dataDoc.doc_name}
                    </span>
                  </div>
                  <div className="flex justify-between mb-5">
                    <span className="text-gray-500">
                      {t("Sign Category")}
                    </span>
                    <div className="w-12" />
                   <Tooltip title={t(dataDoc?.assign_type || "-")}>
                    <span className="font-medium truncate">
                      {t(dataDoc?.assign_type || "-")}
                    </span>
                   </Tooltip>
                  </div>
                  <div className="flex justify-between mb-5">
                    <span className="text-gray-500">
                      {t("Signing Type")}
                    </span>
                    <div className="w-12" />
                   <Tooltip title={t(dataDoc?.sign_type || "-")}>
                    <span className="font-medium truncate">
                      {t(dataDoc?.sign_type || "-")}
                    </span>
                   </Tooltip>
                  </div>
                  <Row>
                    <Col span={24}>
                      <span className="text-gray-500 tracking-tight mb-2">
                        {t("Reason")}
                      </span>

                      <TextArea showCount maxLength={100} className="mt-2" onChange={(data) => setReason(data.target.value)} disabled={isSubmit} name="note" rows={4} />

                    </Col>
                  </Row>
                  <Divider className="mt-2 mb-4" />

                  <Row style={{ marginTop: 24 }} gutter={[12, 0]}>
                    <Col span={12}>
                      <Button
                        ghost
                        onClick={() => setPreview(!previewModal)}
                        className=" w-full h-10 font-medium tracking-tight"
                        disabled={isSubmit}
                      >
                        <span>{t("Back")}</span>
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        className="w-full h-10 font-medium tracking-tight"
                        type="primary"
                        onClick={() => dataDoc?.sign_type == 'System Sign' ? handleSubmitSystem() : handlePreviewOtp(dataDoc.doc_id)}
                        disabled={isSubmit}
                      >
                        <span>{t("Next")}</span>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
              :
              <>
                <Spinner loading={isSubmit} />
                <div className="flex flex-col space-y-4">
                  <div className="flex flex-row justify-between">
                    <p>{t("OTP Code")}</p>
                    <Countdown date={countdownValue} renderer={renderer} />
                  </div>
                  <OtpInput
                    value={otpValue}
                    onChange={(otp) => setOtpValue(otp)}
                    numInputs={6}
                    isInputNum
                    className="text-gray-800 mb-4"
                    containerStyle={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    inputStyle={{
                      width: "3rem",
                      height: "3rem",
                      fontWeight: "bold",
                      border: "2px solid #DBDBDB",
                      borderRadius: 4,
                      color: "#000000",
                    }}
                  />
                  <Form onFinish={handleSubmit} autoComplete={false}>
                    {/* <div>
                  <p>Note (Optional)</p>
                  <Form.Item name="note">
                    <TextArea disabled={otpPreview} name="note" rows={4} />
                  </Form.Item>
                </div> */}
                    <Form.Item>
                      <div className="flex flex-row space-x-4">
                        <Button
                          onClick={() => {
                            setPreview(false)
                            setOtpValue("")
                            setOtpPreview(!otpPreview)
                          }}
                          className=" w-full h-10 font-medium tracking-tight"
                          ghost
                          type="secondary"
                          disabled={isSubmit}
                        >
                          {t("Back")}
                        </Button>
                        <Button
                          disabled={otpValue?.length < 6 || isSubmit}
                          className="w-full h-10 font-medium tracking-tight"
                          type="primary"
                          htmlType="submit"
                        >
                          {t("Submit")}
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                  <p className="text-center">
                    {t("But, if get a problem you can")}
                    <span
                      onClick={handleResendOtp}
                      type="text"
                      disabled={!isResendOtp}
                      className="text-blue-500 cursor-pointer px-1 hover:underline duration-500"
                    >
                      {t("Resend")}
                    </span>
                    {t("the otp code again")}
                  </p>
                  <Divider />
                  <div className="text-center">
                    <p className="text-gray-500 text-xs">
                      {t("By pressing the ‘Submit’ button means that you have agreed to the")}
                    </p>
                    <p className="text-blue-500 text-xs">{t("Terms & Conditions")}</p>
                  </div>
                </div>
              </>
            }

          </Modal>
          <Modal
            visible={activeLocation}
            type="warning"
            title={t("Confirmation Location")}
            subtitle={t("Please allow your location to sign document")}
            onCancel={() => setActiveLocation(false)}
          >
            <div className="flex justify-center">
              <Button onClick={() => setActiveLocation(false)} ghost type="primary" className="mt-9 mb-9 h-10 rounded-md w-52 tracking-tight font-medium mr-4">{t("No")}</Button>
              <Button onClick={() => getLocation()} type="primary" className="mt-9 mb-9 h-10 rounded-md w-52 tracking-tight font-medium">{t("Yes")}</Button>
            </div>
          </Modal>
        </Col>
      </Row>
      <Spinner loading={loading} />
    </>
  );
}