import { DeleteOutlined, EditOutlined, EllipsisOutlined, FilterOutlined, LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Dropdown, Input, Menu, message, Radio, Row, Select, Table } from "antd";
import clsx from "clsx";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import { useDeleteOfficeMutation, useDeleteDeparmentMutation, useFetchListDepartmentQuery } from "../../config/features";
import { useFetchOfficeDetailQuery } from "../../config/features/office";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { errorMessage } from "../../utils";
import { DetailsOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const { Search } = Input;
const { Option } = Select;

export default function EditOffice() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const [isModalDeleteDept, setIsModalDeleteDept] = useState(false);
  const [isModalDeleteOffice, setIsModalDeleteOffice] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [departmentId, setDepartmentId] = useState(null);
  const [departmentName, setDepartmentName] = useState(null);
  const [departmentNameToDelete, setDepartmentNameToDelete] = useState(null);
  const [officeName, setOfficeName] = useState(null);
  const [officeNameToDelete, setOfficeNameToDelete] = useState(null);

  const [paramsTable, setParamsTable] = useState({
    office_id: id,
    row: 10,
    page: 1,
    sort_by: "created_at",
    order: "desc",
  });
  const [visibleFilter, setVisibleFilter] = useState(false);

  const {
    data: userProfile = {}
  } = useFetchProfileUserQuery();
  const { data: officeDetail, refetch: refetchPfficeDetail } = useFetchOfficeDetailQuery(id);
  const [
    deleteOffice,
    { isSuccess: isSuccessDeleteOffice, isLoading: isLoadingDeleteOffice, isError: isErrorDeleteOffice, error: errorDeleteOffice },
  ] = useDeleteOfficeMutation();
  const { 
    data: dataListDepartment = [], 
    isLoading, 
    refetch,
    error,
    isError,
  } = useFetchListDepartmentQuery(paramsTable, {
    refetchOnMountOrArgChange: true,
  });

  const [
    deleteDeparment, 
    { 
      isSuccess: isSuccessDeleteDept, 
      isLoading: isLoadingDeleteDept, 
      isError: isErrorDeleteDept, 
      error: errorDeleteDept
    }
  ] = useDeleteDeparmentMutation();

  function handleCancelOffice() {
    setIsModalDeleteOffice(false);
  }

  async function handleDeleteOffice() {
    try {
      setIsModalDeleteOffice(false);
      deleteOffice(id);
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleCancelDept() {
    setIsModalDeleteDept(false);
  }

  async function handleDeleteDept() {
    try {
      setIsModalDeleteDept(false);
      deleteDeparment(departmentId);
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleDone() {
    setIsModalSuccess(false);
  }

  const onSearch = (value) => setParamsTable({ ...paramsTable, search: value });

  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }

  function handleResetFilter() {
    setParamsTable({
      ...paramsTable,
      sort_by: "created_at",
      order: "desc",
    });
  }

  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  const menuFilter = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={handleResetFilter}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort By")}</p>
            <Select
              defaultValue="created_at"
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, sort_by: e })}
              value={paramsTable.sort_by}
            >
              <Option value="dept_name">{t("Department Name")}</Option>
              <Option value="created_at">{t("Created Date")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) =>
                setParamsTable({ ...paramsTable, order: e.target.value })
              }
              value={paramsTable.order}
            >
              <Radio value="asc">{t("Ascending")}</Radio>
              <Radio value="desc">{t("Descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const menuAction = (record) => {
    return (
      <Menu>
        <Menu.Item>
          <div
            className="flex flex-row space-x-2 items-center"
            onClick={() =>
              history.push({
                pathname: `/office/department/edit/${record.id}`,
                state: {
                  office: officeDetail?.office_name,
                  dept_name: record.dept_name,
                },
              })
            }
          >
            <EditOutlined />
            <p>{t("Edit")}</p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div
            className="flex flex-row space-x-2 items-center text-red-500"
            onClick={() => { 
              if(record?.total_users === 0 || record?.total_users === "0"){
                setDepartmentId(record.id)
                setDepartmentNameToDelete(record.dept_name)
                setIsModalDeleteDept(true)
              } else {
                message.error("Can not delete a department that still have user(s)");
              }
            }}
          >
            <DeleteOutlined />
            <p>{t("Delete")}</p>
          </div>
        </Menu.Item>
        {/* Persiapan UAT & SIT Overview Platform VAS BJT */}
        {/* <Menu.Item>
          <div
            className="flex flex-row space-x-2 items-center"
            onClick={() =>
              history.push({
                pathname: `/Office/Branch/${record.office_id}`,
                state: {
                  office_name: record.office_name,
                  office_type_id: record.office_type_id,
                  is_active: record.is_active,
                  pic_id: record.pic_id
                },
              })
            }
          >
            <DetailsOutlined />
            <p>Detail</p>
          </div>
        </Menu.Item> */}
      </Menu>
    );
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Department Name"),
      dataIndex: "dept_name",
      key: "dept_name",
    },
    {
      title: t("Date Issued"),
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return created_at ? moment(created_at, moment.ISO_8601).format("DD/MM/YY") : "--"
      }
    },
    {
      title: t("User"),
      dataIndex: "total_users",
      key: "total_users",
    },
    {
      render: (record) => {
        return (
          <Dropdown
            disabled={record?.office_type_id === 1}
            overlay={menuAction(record)}
            trigger={["click"]}
          >
            <div className="group cursor-pointer">
              <EllipsisOutlined className="group-hover:text-blue-500" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  function datasetsListTable(data) {
    const datasets = [];

    if(data){
      for (let i = 0; i < data.length; i++) {
        datasets.push({
          no: i + 1 + paramsTable.row * (paramsTable.page - 1),
          ...data[i],
        });
      }
    }

    return datasets;
  }

  useEffect(() => {
    if (isSuccessDeleteDept) {
      refetch();
      setIsModalSuccess(true);
      setTimeout(() => {
        setIsModalSuccess(false);
      }, 3000);
    }

    if(isSuccessDeleteOffice){
      history.replace("/office")
    }
  }, [history, refetch, isSuccessDeleteDept, isSuccessDeleteOffice]);

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
    if (isErrorDeleteDept) {
      errorMessage(errorDeleteDept);
    }
    if (isErrorDeleteOffice) {
      errorMessage(errorDeleteOffice);
    }
  }, [isError, error, isErrorDeleteDept, errorDeleteDept, isErrorDeleteOffice, errorDeleteOffice]);

  useEffect(() => {
    refetchPfficeDetail()
  }, [refetchPfficeDetail])

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.push("/office")}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    {t("Work Unit Detail")}
                  </h1>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row className="flex justify-between">
              <Col>
                <h5 className="text-gray-500 mb-3">
                  {t("Work Unit")}
                </h5>
                <h4 className="text-3xl">
                  {officeDetail?.office_name || "--"}
                </h4>
              </Col>
              <Col>
                <h5 className="text-gray-500 mb-3">
                  {t("Date Issued")}
                </h5>
                <h4 className="text-3xl">
                  {officeDetail?.created_at
                  ? moment(officeDetail?.created_at, moment.ISO_8601)
                    .format("DD/MM/YY")
                  : "--"}
                </h4>
              </Col>
              <Col>
                <h5 className="text-gray-500 mb-3">
                  {t("Work Unit Type")}
                </h5>
                <h4 className="text-3xl">
                  {officeDetail?.office_type_name || "--"}
                </h4>
              </Col>
              <Col>
                <h5 className="text-gray-500 mb-3">
                  Status
                </h5>
                <h4 className={clsx("text-3xl", officeDetail?.is_active ? "text-blue-500" : "text-gray-500")}>
                  {typeof officeDetail?.is_active === "boolean" 
                    ? officeDetail?.is_active ? t("Active") : t("Inactive")
                    : "--"}
                </h4>
              </Col>
              <Col className="flex flex-col items-center justify-center">
                {officeDetail?.office_type_id !== 1 && userProfile && userProfile?.is_pic &&
                  <>
                      <Button 
                        type="danger" 
                        className="w-48 mb-2"
                        onClick={() => { 
                          setIsModalDeleteOffice(true) 
                          setOfficeNameToDelete(officeDetail?.office_name)
                        }}
                      > 
                        {t("Delete")}
                      </Button>
                      <Button 
                        type="primary" 
                        className="w-48" 
                        onClick={() =>
                          history.push({
                            pathname: `/office/edit/${id}`,
                            state: {
                              office_name: officeDetail?.office_name,
                              office_type_id: officeDetail?.office_type_id,
                              is_active: officeDetail?.is_active,
                              pic_id: officeDetail?.pic_id,
                            },
                          })
                        }
                        ghost
                      > 
                        {t("Edit")}
                      </Button>
                  </>
                }
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Col span={24} className="mb-4">
              <Row gutter={[24, 24]} justify="space-between">
                <Col>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    {t("List of Department")}
                  </h3>
                </Col>
                <Col>
                  <Row gutter={[12, 12]}>
                    <Col>
                      <Search
                        placeholder={t("Search")}
                        allowClear
                        onSearch={onSearch}
                        onChange={(e) =>
                          setTimeout(() => {
                            onSearch(e.target.value);
                          }, 1000)
                        }
                        style={{
                          width: 200,
                          borderRadius: "10px !important",
                        }}
                      />
                    </Col>
                    <Col>
                      <Dropdown
                        overlay={menuFilter}
                        onVisibleChange={handleVisibleFilter}
                        visible={visibleFilter}
                        trigger={["click"]}
                      >
                        <Button
                          type="primary"
                          ghost
                          icon={<FilterOutlined />}
                        />
                      </Dropdown>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          history.push({
                            pathname: `/office/department/add`,
                            state: { office: officeDetail?.office_name, office_id: officeDetail?.office_id  }
                          })
                        }}
                      >
                        {t("Add New Department")}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Table
              columns={columns}
              dataSource={datasetsListTable(dataListDepartment)}
              scroll={{ x: 800 }}
              loading={isLoading}
              pagination={{
                current: paramsTable.page,
                pageSize: paramsTable.row,
                total: dataListDepartment[0] ? dataListDepartment[0]?.total : 0,
              }}
              onChange={handleChangeTable}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title={t("Delete Work Unit?")}
        subtitle={t("Are you sure want to delete this work unit?")}
        type="danger"
        visible={isModalDeleteOffice}
        onCancel={handleCancelOffice}
      >
        <Row gutter={[16,16]}>
          <Col span={24}>
            <Input 
              placeholder={t("Enter the work unit name")} 
              value={officeName}
              onChange={e => setOfficeName(e.target.value)}
            />
          </Col>
          <Col span={12}>
            <Button
              className="w-full"
              type="secondary"
              ghost
              danger
              onClick={handleCancelOffice}
            >
              {t("Cancel")}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              className="w-full"
              danger
              onClick={handleDeleteOffice}
              disabled={officeName !== officeNameToDelete}
            >
              {t("Delete")}
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        title={t("Delete Department?")}
        subtitle={t("Are you sure want to delete this department?")}
        type="danger"
        visible={isModalDeleteDept}
        onCancel={handleCancelDept}
      >
        <Row gutter={[16,16]}>
          <Col span={24}>
            <Input 
              placeholder={t("Enter the department name")} 
              value={departmentName}
              onChange={e => setDepartmentName(e.target.value)}
            />
          </Col>
          <Col span={12}>
            <Button
              className="w-full"
              type="secondary"
              ghost
              danger
              onClick={handleCancelDept}
            >
              {t("Cancel")}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              className="w-full"
              danger
              onClick={handleDeleteDept}
              disabled={departmentName !== departmentNameToDelete}
            >
              {t("Delete")}
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        title={t("Deleted Success!")}
        subtitle={t("Changes have been saved! Now you can continue browsing.")}
        type="success"
        visible={isModalSuccess}
      ></Modal>
      <Spinner loading={isLoading || isLoadingDeleteDept || isLoadingDeleteOffice} />
    </>
  );
}