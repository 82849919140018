import {createApi , fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {access , rootApi} from '../config'

export const tagApi = createApi({
  reducerPath : 'api/tag' ,
  baseQuery : fetchBaseQuery({
    baseUrl : rootApi,
    prepareHeaders : (header) => {
      header.set('token' , access)
      return header
    }
  }),
  endpoints(builder) {
    return {
      fetchTagKey : builder.query({
        query(){
          return `/tag-search/key`
        },
        transformResponse : (response) => ( response ? response.data : [])
      }),
      fetchTagValue : builder.query({
        query(){
          return `/tag-search/value`
        },
        transformResponse : (response) => ( response ? response.data : [])
      }),
      editTags: builder.mutation({
        query: ({ is_folder = "", doc_id = "", body = {} }) => ({
          method: "PUT",
          url: `/tag/${doc_id}?is_folder=${is_folder}`,
          body,
        }),
      }),
    }
  }
})

export const {
  useFetchTagKeyQuery,
  useFetchTagValueQuery,
  useEditTagsMutation,
} = tagApi