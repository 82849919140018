export const getPeruriToken = () => {
    return localStorage.getItem('peruri')
}
export const isBasicAccess = (role_id) => role_id == 1;
export const isAllDocumentAccess = (role_id) => role_id == 2;
export const isFullAccess = (role_id) => role_id == 3;
export const isSuperAdmin = (role_id) => role_id == 4;
export const isAdminBilling = (role_id) => role_id == 5;
export const isBranch = (office_type_id) => office_type_id == 2;
export const isHeadquarter = (office_type_id) => office_type_id == 1;
export const isPic = (is_pic) => is_pic == true;