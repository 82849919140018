export const REGEXES = {
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
}

export const USER_ROLE = {
  BASIC_ACCESS: 1,
  ALL_DOCUMENT_ACCESS: 2,
  FULL_ACCESS: 3,
  SUPER_ADMIN: 4,
  ADMIN_BILLING: 5,
}


export const STAMP_STATUS = {
  PENDING: 1,
  COMPLETED: 2,
  FAILED: 3,
  1: { value: "In Progress", clsTextColor: "text-gray-800" },
  2: { value: "Complete", clsTextColor: "text-blue-500" },
  3: { value: "Incomplete", clsTextColor: "text-red-500" },
}
export const STAMP_GENERATE_STATUS = {
  1: { value: "In Progress", clsTextColor: "text-gray-800" },
  2: { value: "Completed", clsTextColor: "text-blue-500" },
  3: { value: "Canceled", clsTextColor: "text-red-500" },
}
export const STAMP_DOC_STATUS = {
  ORIGINAL: 0,
  PENDING: 1,
  COMPLETE: 2,
  INCOMPLETE: 3,
  // ABORTED: 4
}

export const DIGITAL_CERTIFICATE_STATUS = {
  0: "Actived CA",
  1: "Not Actived CA",
  2: "Active less than 30 days",
  "01": "Invalid System ID",
  "02": "Email address not registered",
  "03": "e-KYC verification attempts have reached the maximum limit (3 times)",
  "04": "Haven't done e-KYC yet",
  "05": "The account has not been activated",
  "06": "The certificate has been revoked and has not been published on the CRL",
  "07": "The certificate has expired",
  "08": "Currently processing certificate creation",
  "09": "The certificate has been revoked and has been published on the CRL",
  "10": "Email address has not been verified",
  "11": "Certificate failed to create, Error code",
  "14": "Not yet verified phone number and KYC",
  "15": "Waiting for verification",
  "16": "Verification rejected",
  "99": "Certificate Error"
}

export const CONTRACT_STATUS = {
  1: { value: "Pending", clsTextColor: "text-yellow-500" },
  2: { value: "Active", clsTextColor: "text-blue-500" },
  3: { value: "Cancelled", clsTextColor: "text-red-500" },
  4: { value: "Expired", clsTextColor: "text-gray-500" },
}

export const CONTRACT_DOC_STATUS = {
  1: { value: "Pending", clsTextColor: "text-yellow-500" },
  2: { value: "Approved", clsTextColor: "text-blue-500" },
  3: { value: "Rejected", clsTextColor: "text-red-500" },
}

export const MODULE_LIST = {
  "stamp_m": "E-Meterai",
  "sign_m": "Digital Signature",
  "backup_m": "Backup Secure Document",
  "user_m": "User Management",
  "group_m": "Group Management",
  "office_m": "Office Management",
  "document_m": "Document Management",
}

export const QUOTATION_STATUS = {
  1: { value: "Pending", clsTextColor: "text-yellow-500" },
  2: { value: "Completed", clsTextColor: "text-blue-500" },
  3: { value: "Cancelled", clsTextColor: "text-red-500" },
  4: { value: "Expired", clsTextColor: "text-gray-500" },
}

// Product list from database by id
export const PRODUCTS = {
  1: { name: "e-Meterai per-Seal", value: "E-EMETRAI" },
  2: { name: "e-Signature per-Sign", value: "E-SIGNATURE" },
  3: { name: "DMS per-Document", value: "DMS per-Document" },
  4: { name: "Backup Secure Document", value: "Backup Secure Document" },
  5: { name: "e-Signature Certificate", value: "e-Signature Certificate" },
  6: { name: "Stamp Process", value: "Stamp Process" },
  7: { name: "Blockchain", value: "Blockchain" }
}

export const GROUP_MEMBER_ROLE = {
  OWNER: 1,
  MEMBER: 2,
  1: { value: "Owner" },
  2: { value: "Member" },
}

export const STATUS_REGISTRATION_SIGN = {
  0: "Unregistered",
  1: "Success Register",
  2: "Verified Email Success",
  3: "KYC Success",
  4: "Registration Success",
}

export const SIGN_TYPE = {
  'Self Sign': 0,
  'Parallel': 1,
  'Sequential': 2,
  'Peruri Tera': 3,
  'System Sign': 4
}
export const ASSIGN_TYPE = {
  'Self Sign': 1,
  'Sign With Others': 2,
  'Request Others to Sign': 3
}
