import React, { useEffect } from "react";
import { Row, Col, Card, Table } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import Button from "../../components/Button";
import Fade from "react-reveal/Fade";
import { numberCommas } from "../../utils";
import moment from 'moment';
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function Qoutation({ next, back, dataSource, handleDownload }) {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const columnsProduct = [
    {
      title: t("Product Name"),
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: t("Unit Price"),
      dataIndex: "unit_price",
      key: "unit_price",
      render: (unit_price) => {
        return `Rp ${numberCommas(unit_price)},-`;
      },
    },
    {
      title: t("Quantity"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t("Price"),
      dataIndex: "price",
      key: "price",
      render: (price) => {
        return `Rp ${numberCommas(price)},-`;
      },
    },
  ];

  function paymentDetail(params) {
    if (params != null) {
      return <div>
            <Row gutter={[24, 24]} justify="space-between">
                <Col>
                  <h1 className="text-base md:text-lg font-medium text-gray-500">
                    {i18next.t("Transaction ID")}
                  </h1>
                </Col>
                <Col>
                <h1 className="text-base md:text-lg font-medium text-black">
                    {dataSource?.transaction_id}
                  </h1>
                </Col>
            </Row>
            <Row gutter={[24, 24]} justify="space-between">
                <Col>
                  <h1 className="text-base md:text-lg font-medium text-gray-500">
                    {i18next.t("Payment Method")}
                  </h1>
                </Col>
                <Col>
                  <h1 className="text-base md:text-lg font-medium text-black">
                    {dataSource?.pga_name}
                  </h1>
                </Col>
            </Row>
            <Row gutter={[24, 24]} justify="space-between">
                <Col>
                  <h1 className="text-base md:text-lg font-medium text-gray-500">
                    {i18next.t("Due Date")}
                  </h1>
                </Col>
                <Col>
                  <h1 className="text-base md:text-lg font-medium text-black">
                    {moment(dataSource?.expired_time, moment.ISO_8601).format("DD/MM/YYYY, HH:mm")}
                  </h1>
                </Col>          
            </Row></div>;
    } else {
      return "";
    }
    
  }

  return (
    <Fade>
      <Row gutter={[24, 12]} justify="center">
        <Col xs={24} md={18}>
          <div className="rounded-md px-6 py-3 bg-white">
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <div className="cursor-pointer" onClick={back}>
                  <LeftOutlined className="hover:text-gray-400" />
                </div>
              </Col>
              <Col>
                <h1 className="text-lg md:text-xl font-medium">{t("Transaction Details")}</h1>
              </Col>
              <Col>
                <LeftOutlined className="hidden" />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={24} md={18}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]} justify="space-between">
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h1 className="text-base md:text-lg font-medium">
                      {t("Here is the price quotation you are offered")} :
                    </h1>
                  </Col>
                  <Col>
                    <Button type="primary" ghost onClick={handleDownload}>
                      {t("Download")}
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsProduct}
                  dataSource={dataSource?.products}
                  pagination={false}
                  scroll={{ x: 500 }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={18}>
          <Card className="rounded-md">
            
            
          {paymentDetail(dataSource?.transaction_id)}
          {dataSource?.admin_fee && (
            <Row gutter={[24, 24]} justify="space-between">
                <Col>
                  <h1 className="text-base md:text-lg font-medium text-gray-500">
                    {("Admin Fee")}
                  </h1>
                </Col>
                <Col>
                  <h1 className="text-base md:text-lg font-medium text-blue-700">
                    {`Rp ${numberCommas(Number(dataSource?.admin_fee || 0))},-`}
                  </h1>
                </Col>
            </Row>
          )}
          <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <h1 className="text-base md:text-lg font-medium text-gray-500">
                  {("Subtotal")}
                </h1>
              </Col>
              <Col>
                <h1 className="text-base md:text-lg font-medium text-blue-700">
                  {`Rp ${numberCommas(Number(dataSource?.total || 0))},-`}
                </h1>
              </Col>
          </Row>
          <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <h1 className="text-base md:text-lg font-medium text-gray-500">
                  Total
                </h1>
              </Col>
              <Col>
                <h1 className="text-base md:text-lg font-medium text-blue-700">
                  {`Rp ${numberCommas(Number(dataSource?.total || 0) + Number(dataSource?.admin_fee || 0))},-`}
                </h1>
              </Col>
              
              <Col span={24}>
                <Button className="w-full" type="primary" onClick={next}>
                  {t("Pay Now")}
                </Button>
              </Col>
          </Row>
          </Card>
        </Col>
      </Row>
    </Fade>
  );
}
