import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { access, rootApi } from "../config";
import moment from "moment";

function dateFormating(date) {
  return moment(date, moment.ISO_8601).format("YYYY-MM-DD");
}

export const userApi = createApi({
  reducerPath: "api/user",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi,
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      loginUser: builder.mutation({
        query: (body) => ({
          url: "/login",
          method: "POST",
          body,
        }),
        transformResponse: (response) => (response ? response.data : {}),
      }),

      fetchProfileUser: builder.query({
        query() {
          return "/user/profile";
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      fetchListUser: builder.query({
        query({
          page = 1,
          sort_by = "full_name",
          order = "desc",
          row = 10,
          search = "",
          status = "",
          office = "",
        }) {
          return `/user?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}&status=${status}&office=${office}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      deleteUser: builder.mutation({
        query: (userId) => ({
          url: `/user/${userId}`,
          method: "DELETE",
        }),
      }),

      addUser: builder.mutation({
        query: (body) => ({
          url: "/register",
          method: "POST",
          body,
        }),
      }),

      editUser: builder.mutation({
        query: ({ userId, body }) => ({
          url: `/user/${userId}`,
          method: "POST",
          body,
        }),
      }),

      fetchUserActivityLog: builder.query({
        query({
          page = 1,
          sort_by = "created_at",
          order = "desc",
          row = 10,
          search = "",
          date = new Date(),
          office = "",
          status = "",
        }) {
          return `/user-log-activities?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}&date=${dateFormating(
            date
          )}&office=${office}&status=${status}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchComposeUser: builder.query({
        query({
          page = 1,
          sort_by = "full_name",
          order = "asc",
          row = 2,
          search = "",
        }) {
          return `/compose-user?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchListFilter: builder.query({
        query() {
          return "/user-filter-list";
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      editUserProfile: builder.mutation({
        query: (body) => ({
          method: "POST",
          url: "/user/profile",
          body,
        }),
      }),

      fetchQoutation: builder.query({
        query() {
          return "/quota";
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),
      checkValidityToken: builder.query({
        query() {
          return "/validity-token"
        },
        transformResponse: (response) => (response ? response.data : {})
      })
    };
  },
});

export const {
  useLoginUserMutation,
  useFetchListUserQuery,
  useFetchProfileUserQuery,
  useDeleteUserMutation,
  useAddUserMutation,
  useFetchUserActivityLogQuery,
  useFetchComposeUserQuery,
  useEditUserMutation,
  useFetchListFilterQuery,
  useEditUserProfileMutation,
  useFetchQoutationQuery,
  useCheckValidityTokenQuery
} = userApi;
