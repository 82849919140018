import { Form, Input, Button, Row, Col, Card } from "antd";
import { useCallback, useEffect, useState } from "react";
import { CheckOutlined, LeftOutlined } from "@ant-design/icons";
import Spinner from "../../components/Spinner";
import { Link, useHistory } from "react-router-dom";
import { errorMessage } from "../../utils";
import Fade from "react-reveal/Fade";


export default function PaymentSuccess() {

  return (
    <>
      <Fade>
      <Row gutter={[24, 12]} justify="center">        
        <Col xs={24} md={12}>
          <Card className="rounded-md" style={{backgroundColor:"#ffffff", borderStyle:"none"}}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <div>
                    <img
                            className="w-9"
                            src="/images/reply.png"
                            alt="dli-logo"
                        />
                </div>
              </Col>              
              <Col>
                <img
                        className="w-28"
                        src="/images/logo-primary.png"
                        alt="dli-logo"
                    />
              </Col>
            </Row>            
            <Row gutter={[24, 24]} style={{marginTop: 10, marginBottom: 10}} justify="center">            
             <Col span={24} style={{marginTop: 10, padding: 10,}}>
                    <Row justify="center">
                        <Col>
                            <img
                                width={175}                                                               
                                src="/images/verified.gif"                                
                            />
                        </Col>                                                                    
                    </Row>  
                    <Row justify="center">
                    <Col>
                        <p><b>Payment Success !</b></p>
                    </Col>  
                    </Row>
              </Col>
            </Row>            
          </Card>
        </Col>
      </Row>
    </Fade>     
    </>
  );
}
