import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Row, Skeleton, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../../components";
import { SiMicrosoftexcel } from "react-icons/si";
import { useEffect, useState } from "react";
import { useFetchListDetailUsageActivityQuery } from "../../config/features";
import { errorMessage, exportExcel, getBlob } from "../../utils";
import { createPagination } from "../../utils/table";
import { objectToQueryString } from "../../utils/queryString";
export default function DetailUsageActivities() {
  const { t } = useTranslation();
  const history = useHistory();
  const { office_id, period } = useParams();
  const columns = [
    { title: 'No', dataIndex: 'no', key: 'no' },
    { title: t('Activity'), dataIndex: 'activity', key: 'activity' },
    { title: t('User'), dataIndex: 'user', key: 'user' },
    { title: t('Department'), dataIndex: 'dept_name', key: 'dept_name' },
    { title: t('Total Document'), dataIndex: 'total_doc', key: 'total_doc' },
    {
      title: t('Document Name'), dataIndex: 'doc_name', key: 'doc_name',
      render: (doc_name) => <p className="w-40 truncate">{doc_name}</p>
    },
    { title: t('Document Type'), dataIndex: 'doc_type', key: 'doc_type' },
    { title: t('Date'), dataIndex: 'date', key: 'date' },
  ]
  const [paramsTable, setParamsTable] = useState({
    office_id, period, row: "10", page: "1"
  })
  const {
    data: dataListUsageActivity = [],
    isFetching: isFetchingUsageActivity,
    isError: isErrorUsageActivity,
    error: errorUsageActivity,
    refetch: refetchUsageActivity,
  } = useFetchListDetailUsageActivityQuery(paramsTable);

  // Refetch Data
  useEffect(() => {
    refetchUsageActivity();
  }, [refetchUsageActivity]);

  // Handling Error
  useEffect(() => {
    if (isErrorUsageActivity) errorMessage(errorUsageActivity);
  }, [isErrorUsageActivity, errorUsageActivity]);

  // Export data to excel
  const handleClickExportExcel = () => {
    const queryString = objectToQueryString({ export_format: 'excel', ...paramsTable });
    getBlob(`/overview/usage-activity/detail?${queryString}`)
      .then(response => exportExcel(response.data, `Usage Activity_${period}_${new Date()}`))
      .catch(err => errorMessage(err));
  }
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div className="group cursor-pointer" onClick={() => history.goBack()}>
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    {t("Detail Usage Activities")}
                  </h1>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Skeleton loading={isFetchingUsageActivity} active paragraph={{ rows: 6 }}>
              <Row gutter={[24, 24]} justify="space-between">
                <Col span={12}>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    {t("Detail Usage Activities")}
                  </h3>
                </Col>
                <Col>
                  <Button
                    type="text"
                    className="text-white hover:text-white"
                    style={{ background: "#8BC34A" }}
                    icon={<SiMicrosoftexcel />}
                    onClick={handleClickExportExcel}
                  >
                    {t("Export Excel")}
                  </Button>
                </Col>
              </Row>
              <Row gutter={[24, 24]} className="mt-4">
                <Col span={24}>
                  <Table
                    columns={columns}
                    scroll={{ x: 850 }}
                    dataSource={createPagination(dataListUsageActivity, paramsTable)}
                    pagination={{
                      current: paramsTable.page,
                      pageSize: paramsTable.row,
                      total: dataListUsageActivity[0] ? dataListUsageActivity[0].total : 0
                    }}
                    loading={isFetchingUsageActivity}
                    onChange={(pagination) => setParamsTable({ ...paramsTable, page: pagination.current, row: pagination.pageSize })}
                  />
                </Col>
              </Row>
            </Skeleton>
          </Card>
        </Col>
      </Row>
    </>
  )
}