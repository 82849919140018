import { Form, Input, Button, Row, Col } from "antd";
import { useCallback, useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { Link, useHistory } from "react-router-dom";
import { errorMessage } from "../../utils";

export default function PaymentFailed() {

  

  return (
    <>
      <Row className="h-screen">
        <Col xs={0} sm={10} md={12} lg={14}>
          <div
            className="w-full h-full bg-blue-600 pt-10 flex justify-end"
            style={{
              backgroundImage: "url('/images/cover-login.png')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="flex flex-col space-y-12 w-11/12 md:w-9/12">
              <div className="flex flex-col space-y-2">
                <h1 className="text-3xl text-white font-semibold">
                  Managed Work Perfectly
                </h1>
                <h5 className="text-white text-base">
                  Gather insights from data through customizable visualization
                </h5>
              </div>
              <img
                className="hidden md:block w-full"
                src="/images/image-login.png"
                alt="dashboard"
              />
            </div>
          </div>
        </Col>
        <Col xs={24} sm={14} md={12} lg={10}>
          <Row className="p-8 md:p-16 space-y-8">
            <Col span={24}>
              <Row>
                <Col>
                  <img
                    className="w-28"
                    src="/images/logo-primary.png"
                    alt="dli-logo"
                  />
                </Col>
              </Row>
            </Col>
            <Col className="pt-12" span={24}>
              {/* here */}
            </Col>
            <Col span={24}>
              <div className="flex flex-row justify-center items-center">
                <div className="w-full h-0.5 bg-gray-100" />
                <Link to="/forgot-password">
                  <Button type="link">Forgot Password?</Button>
                </Link>
                <div className="w-full h-0.5 bg-gray-100" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>      
    </>
  );
}
