import { message } from "antd";

export function errorMessage(error) {
  if (error?.status === 401 && error?.data?.error === "Authentication Failed") {
    localStorage.removeItem("access");
    window.location.reload();
    return message.error("Your session has ended");
  }
  return error?.data
    ? message.error(error?.data?.error)
    : message.error("Check your connection!");
}
