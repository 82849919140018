import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Form, Input, message, Row, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { errorMessage } from "../../utils";
import { useAddDeparmentMutation } from "../../config/features";
import { useTranslation } from "react-i18next";
const { Option } = Select;

export default function AddOffice() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation()

  const { data: userProfile } = useFetchProfileUserQuery();
  const [addDepartment, { isSuccess, isLoading, error, isError }] = useAddDeparmentMutation();

  const [form] = Form.useForm();
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [payload, setPayload] = useState({});

  useEffect(() => {
    form.setFieldsValue({ office: location?.state?.office })
  }, [form, location?.state?.office])

  useEffect(() => {
    if (isSuccess) {
      setIsModalSuccess(true);
      form.resetFields();
    } else if (isError) {
      errorMessage(error);
    }
  }, [isSuccess, error, isError, form]);

  const onFinish = (values) => {
    showModal(values);
  };

  async function handleSubmit(values) {
    try {
      addDepartment({ ...values, office_id: location?.state?.office_id });
    } catch (error) {
      message.error(error.message);
    }
  }

  const showModal = (values) => {
    setPayload(values);
    setIsModalConfirm(true);
  };

  const handleOk = () => {
    setIsModalConfirm(false);
    handleSubmit(payload);
  };

  const handleCancel = () => {
    setIsModalConfirm(false);
  };

  const handleDone = () => {
    setIsModalSuccess(false);
    history.goBack();;
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.goBack()}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      {t("Add Department")}
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    className="md:w-40"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {t("Add Department")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    {t("Input New Department")}
                  </h3>
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 12]}>
                    <Col xs={24}>
                      <Form.Item
                        label={t("Department Name")}
                        name="dept_name"
                        rules={[
                          {
                            required: true,
                            message: t("Please input department name!"),
                          },
                        ]}
                      >
                        <Input placeholder={t("Enter department name")} />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label={t("Work Unit")}
                        name="office"
                        rules={[
                          {
                            required: true,
                            message: t("Please input work unit name!"),
                          },
                        ]}
                      >
                        <Input placeholder={t("Enter work unit name")} value="asd" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      <Modal
        title={t("Are you sure?")}
        subtitle={t("You are going to add a new department")}
        type="warning"
        visible={isModalConfirm}
        onCancel={handleCancel}
      >
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Department Name")}</p>
            <div className="w-2" />
            <p className="text-gray-800 truncate">{payload?.dept_name}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Work Unit")}</p>
            <div className="w-2" />
            <p className="text-gray-800 truncate">{payload?.office}</p>
          </div>
          <Divider />
        </div>
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={handleCancel}
          >
            {t("Back")}
          </Button>
          <Button className="w-full" type="primary" onClick={handleOk}>
            {t("Add")}
          </Button>
        </div>
      </Modal>
      <Modal
        title={t("Added Success!")}
        subtitle={t("New department has been added! Now you can continue browsing.")}
        type="success"
        visible={isModalSuccess}
        onCancel={() => setIsModalSuccess(false)}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={handleDone}>
            {t("Done")}
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
