import { Modal } from "antd";
import React from "react";
import { ReactComponent as CheckCircleIcon } from "../../assets/icons/check-circle-outline.svg";

export default function ModalSuccess(props) {
  return (
    <Modal
      {...props}
      className="border-blue-500 rounded-md"
      style={{ borderTopWidth: 14 }}
      title={false}
      footer={false}
    >
      <div className="flex flex-col space-y-8 px-8 -mt-20">
        <div className="flex flex-col space-y-4 justify-center items-center">
          <CheckCircleIcon className="w-20" />
          <p className="text-gray-800 font-semibold text-xl md:text-2xl">
            {props.title}
          </p>
          {props.subtitle && (
            <p className="text-center font-medium text-gray-500">
              {props.subtitle}
            </p>
          )}
        </div>
        {props.children}
      </div>
    </Modal>
  );
}
