import React from "react";
import ModalSuccess from "./ModalSuccess";
import ModalWarning from "./ModalWarning";
import ModalDanger from "./ModalDanger";
import ModalUpload from "./ModalUpload";
import propTypes from "prop-types";

export default function Modal(props) {
  switch (props.type) {
    case "upload":
      return <ModalUpload {...props} />;

    case "warning":
      return <ModalWarning {...props} />;

    case "danger":
      return <ModalDanger {...props} />;

    default:
      return <ModalSuccess {...props} />;
  }
}

Modal.propTypes = {
  type: propTypes.oneOf(["warning", "success", "danger"]).isRequired,
  title: propTypes.string.isRequired,
  subtitle: propTypes.string.isRequired,
  visible: propTypes.bool,
  onCancel: propTypes.func,
};
