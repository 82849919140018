import { DeleteOutlined, LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Form, Input, message, Row, Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import {
  useEditGroupMutation,
  useFetchListMembersQuery,
} from "../../config/features";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { errorMessage } from "../../utils";
const { Option } = Select;

export default function EditGroup() {
  const history = useHistory();
  const { id } = useParams();
  const { group_name, members } = useLocation().state;

  const [searchUser, setSearchUser] = useState("");

  const { data: profileUser = {} } = useFetchProfileUserQuery();
  const [editGroup, { isSuccess, isLoading, error, isError }] =
    useEditGroupMutation();
  const { data: listUser = [] } = useFetchListMembersQuery({
    search: searchUser,
  });

  const [form] = Form.useForm();
  const addedUsers = Form.useWatch("members", form);
  const [isModalSuccess, setIsModalSuccess] = useState(false);

  const userOptions = useMemo(() => {
    let options = []
    let addedUserTable = {}
    addedUsers?.forEach(item => {
      if(item){
        addedUserTable[item?.user_id] = 1
      }
    })
    listUser?.forEach(item => {
      options.push({
        disabled: addedUserTable[item?.user_id] ? true : false, 
        ...item
      })
    })

    return options
  }, [addedUsers, listUser])

  useEffect(() => {
    form.setFieldsValue({
      group_name,
      members,
    });
    if (isSuccess) {
      setIsModalSuccess(true);
      form.resetFields();
      setTimeout(() => {
        setIsModalSuccess(false);
        history.push("/group");
      }, 3000);
    }
  }, [isSuccess, history, form, group_name, members]);

  useEffect(() => {
    isError && errorMessage(error);
  }, [isError, error]);

  function handleFinish(values) {
    const isThereOwner = values?.members?.some(member => member.role === 1)
    
    if(!isThereOwner){
      message.error("At least one member has to be the Owner.");
      return
    }

    values.add = values.members;
    delete values.members;
    const deletedUser = [];
    for (let i = 0; i < members.length; i++) {
      if (!values?.add?.find((add) => add.user_id === members[i].user_id)) {
        deletedUser.push(members[i].user_id);
      }
    }
    if (deletedUser.length > 0) {
      values.remove = deletedUser;
    }
    handleSubmit(values);
  }

  async function handleSubmit(body) {
    try {
      editGroup({ idGroup: id, body });
    } catch (error) {
      message.error(error.message);
    }
  }

  const handleDone = () => {
    history.push(`/group`);
  };

  return (
    <>
      <Form
        onFinish={handleFinish}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.goBack()}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      Edit Group
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    className="md:w-40"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Update
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[12, 24]}>
                <Col xs={24}>
                  <Form.Item
                    label="Group Name"
                    name="group_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input group name!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter group name" />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.List name="members">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Row key={key} gutter={[24, 12]} align="middle">
                            <Col>
                              <Form.Item
                                {...restField}
                                label="User Email"
                                name={[name, "user_id"]}
                                fieldKey={[fieldKey, "user_id"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input user!",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Enter user email"
                                  style={{ width: 300 }}
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {userOptions?.map((item, index) => {
                                    return (
                                      <Option
                                        key={index}
                                        value={item.user_id}
                                        disabled={item?.disabled}
                                      >
                                        {item.email}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item
                                {...restField}
                                label="Role"
                                name={[name, "role"]}
                                fieldKey={[fieldKey, "role"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your role!",
                                  },
                                ]}
                                initialValue={2}
                              >
                                <Select
                                  placeholder="Enter user role"
                                  style={{ width: 300 }}
                                  allowClear={false}
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  <Option value={1}>Owner</Option>
                                  <Option value={2}>Member</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={1}>
                              <Button
                                type="primary"
                                danger
                                onClick={() => remove(name)}
                                icon={<DeleteOutlined />}
                              >
                                Delete
                              </Button>
                            </Col>
                          </Row>
                        ))}
                        <Form.Item>
                          <Button
                            type="primary"
                            ghost
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            Add User
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Updated Success!"
        subtitle="Changes have been saved! Now you can continue browsing."
        type="success"
        visible={isModalSuccess}
        closable={false}
      >
        <div className="text-center">
          <Button onClick={handleDone} className="w-32" type="primary">
            Done
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
