import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Form, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Button from "../../components/Button";
import {
  useFetchListProductQuery,
  useFetchProductLogQuery,
} from "../../config/features/product";
import { errorMessage, numberCommas } from "../../utils";

export default function ProductDetail() {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  const [paramsTable] = useState({
    row: 10,
    page: 1,
    sort_by: "created_at",
    order: "desc",
  });

  const [paramsTableLog, setParamsTableLog] = useState({
    row: 10,
    page: 1,
    sort_by: "created_at",
    order: "desc",
  });

  const { data: dataListLog = {}, refetch: refetchLog } =
    useFetchProductLogQuery(paramsTableLog);
  const {
    data: dataListProduct = [],
    refetch: refetchListProduct,
    isError,
    error,
    isFetching,
  } = useFetchListProductQuery(paramsTable);

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
  }, [isError, error]);

  useEffect(() => {
    refetchListProduct();
  }, [refetchListProduct]);

  useEffect(() => {
    refetchLog();
  }, [refetchLog, paramsTableLog.page]);

  const columns = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Unit Price",
      dataIndex: "price_default",
      key: "price_default",
      render: (price_default) => {
        return `Rp ${numberCommas(price_default)},-`;
      },
    },
    {
      title: "Last Update",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at) => {
        return moment(updated_at, moment.ISO_8601).format("DD/MM/YYYY");
      },
    },
  ];

  const columnsLog = [
    {
      title: "Activity",
      dataIndex: "activity",
      key: "activity",
    },
    {
      title: "Change by",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format(
          "dddd, DD MMMM YYYY HH:mm"
        );
      },
    },
  ];

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  function handleChangeTable(pagination) {
    setParamsTableLog({
      ...paramsTableLog,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    Product Detail
                  </h1>
                </Col>
              </Row>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  className="md:w-40"
                  type="primary"
                  onClick={() =>
                    history.push({
                      pathname: `/quotation-management/edit-product/${id}`,
                      state: location.state,
                    })
                  }
                  //   loading={isLoading}
                >
                  Edit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  Product Information
                </h3>
              </Col>
              <Col span={24}>
                <Divider className="my-0" />
              </Col>
              {/* <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between" align="middle">
                  <Col>
                    <p className="text-gray-500 font-medium">Product Name</p>
                  </Col>
                  <Col>
                    <p className="text-gray-500 font-medium">Unit Price</p>
                  </Col>
                  <Col>
                    <p className="text-gray-500 font-medium">Last Update</p>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between" align="middle">
                  <Col>
                    <p className="text-gray-800 font-medium">
                      {dataDetailProduct?.name}
                    </p>
                  </Col>
                  <Col>
                    <p className="text-gray-800 font-medium">
                      {dataDetailProduct?.price_default}
                    </p>
                  </Col>
                  <Col>
                    <p className="text-gray-800 font-medium">
                      {dataDetailProduct?.updated_at}
                    </p>
                  </Col>
                </Row>
              </Col> */}
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={datasetsListTable(dataListProduct)}
                  pagination={false}
                  loading={isFetching}
                  scroll={{ x: 500 }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  Log History
                </h3>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsLog}
                  dataSource={dataListLog?.rows}
                  scroll={{ x: 400 }}
                  loading={isFetching}
                  pagination={{
                    current: paramsTableLog.page,
                    pageSize: paramsTableLog.row,
                    total: dataListLog.total ? dataListLog.total : 0,
                  }}
                  onChange={handleChangeTable}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}
