import {
  Card,
  Col,
  Row,
  Table,
  Select,
  Form,
  Upload,
  Modal as AntModal,
  message,
} from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Document, Page } from "react-pdf";
import { Button, Modal, Spinner } from "../../components";
import clsx from "clsx";
import moment from "moment";
import { useHistory } from "react-router-dom";

// === REDUX API
import { useFetchAllContractQuery, useFetchDetailContractQuery, useReuploadDocumentContractMutation, useStopContractMutation } from "../../config/features";

// === CONSTANT
import { CONTRACT_STATUS, CONTRACT_DOC_STATUS, MODULE_LIST } from "../../constant";

// === UTILS
import { errorMessage, getBlob, getProductsTotalPrice, numberCommas } from "../../utils";
import { DownOutlined, EyeFilled, UploadOutlined, UpOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export default function CooperationAgreement({ officeId }) {
  const history = useHistory();
  const { t } = useTranslation();

  const [isModalReuploadDoc, setIsModalReuploadDoc] = useState(false);
  const [isModalStopContract, setIsModalStopContract] = useState(false);

  const [docId, setDocId] = useState("");
  const [docReuploadForm] = Form.useForm();
  const [fileListDoc, setFileListDoc] = useState([]);


  const { data: dataAllContract = [], refetch: refetchAllContract } = useFetchAllContractQuery(officeId);
  
  const { data: dataDetailContract = {}, refetch: refetchDetailContract } = useFetchDetailContractQuery(
    dataAllContract[0]?.id
  );

  const isActive = dataDetailContract?.status === 2

  const totalProductPrice = useMemo(() => {
    return getProductsTotalPrice(dataDetailContract?.products)
  }, [dataDetailContract?.products])

  const moduleListData = useMemo(() => {
    const module = dataDetailContract?.modules
    if(module){
      const keys = Object.keys(module)
      return keys
        .map((key) => module[key] && MODULE_LIST[key] ? ({ module_name: MODULE_LIST[key] }) : null)
        .filter(data => data)
    }
    
    return []
  }, [dataDetailContract?.modules])

  const [
    reuploadDocumentContract, 
    { 
      isSuccess: isSuccessReuploadDocumentContract, 
      isError: isErrorReuploadDocumentContract, 
      error: errorLoadingReuploadDocumentContract, 
      isLoading: isLoadingReuploadDocumentContract 
    }
  ] = useReuploadDocumentContractMutation();

  const [
    stopContract, 
    { 
      isSuccess: isSuccessStopContract, 
      isError: isErrorStopContract, 
      error: errorLoadingStopContract, 
      isLoading: isLoadingStopContract 
    }
  ] = useStopContractMutation();

  const [isModalSuccess, setIsModalSuccess] = useState(false);

  const [contractDocName, setContractDocName] = useState(null);
  const [isFetchingContractDocFile, setIsFetchingContractDocFile] = useState(false);
  const [contractDocFile, setContractDocFile] = useState(null);
  const [contractDocFileNumPages, setContractDocFileNumPages] = useState(null);
  const [contractDocFilePageNumber, setContractDocFilePageNumber] = useState(1);
  const [isModalContractDocOpen, setIsModalContractDocOpen] = useState(false)

  const propsUploadDocument = {
    onRemove: () => {
      setFileListDoc([]);
    },
    beforeUpload: (file) => {
      const isPdf = file.type === "application/pdf";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isPdf) {
        message.error("You can't upload this extension!");
      } else if (!isLt5M) {
        message.error("File must smaller than 5MB!");
      } else {
        setFileListDoc([file]);
      }
      return false;
    },
    fileListDoc,
  };

  function toggleModalSuccess() {
    setIsModalSuccess(!isModalSuccess);
  }

  const toggleModalContractDoc = () => {
    setIsModalContractDocOpen(prevState => !prevState)
  }

  const toggleModalStopContract = useCallback(() =>  {
    setIsModalStopContract(prevState => !prevState);
  }, [setIsModalStopContract])

  const toggleModalUpdateDoc = useCallback((doc_id) => {
    setIsModalReuploadDoc(prevState => {
      if(prevState) { setDocId("") }
      else { setDocId(doc_id) }
      return !prevState
    });
  }, [setIsModalReuploadDoc])

  const onContractDocLoadSuccess = ({ numPages }) => {
    setContractDocFileNumPages(numPages);
  }

  const handleStopContract = () => {
    stopContract({ officeId })
  }

  function handleChangeContractDocPage(val) {
    setContractDocFilePageNumber(val);
  }

  const handleSubmitUReuploadDoc = (values) => {
    if(!fileListDoc[0]){
      message.error(t("Please attach your document!"));
      return;
    }

    const formData = new FormData();
    formData.append("file", fileListDoc[0])
    reuploadDocumentContract({
      contract_doc_id: docId,
      body: formData
    })
  }

  useEffect(() => {
    if (isSuccessReuploadDocumentContract) {
      setIsModalSuccess(true);
      toggleModalUpdateDoc()
      docReuploadForm.resetFields();
      setFileListDoc([])
      refetchDetailContract();
    }
    if (isErrorReuploadDocumentContract) {
      errorMessage(errorLoadingReuploadDocumentContract);
    }
  }, [
    isSuccessReuploadDocumentContract, 
    errorLoadingReuploadDocumentContract, 
    isErrorReuploadDocumentContract, 
    docReuploadForm,
    refetchDetailContract,
    setFileListDoc,
    toggleModalUpdateDoc,
  ]);

  useEffect(() => {
    if (isSuccessStopContract) {
      setIsModalSuccess(true);
      toggleModalStopContract()
      refetchDetailContract();
      refetchAllContract();
    }
    if (isErrorStopContract) {
      errorMessage(errorLoadingStopContract);
    }
  }, [
    isSuccessStopContract, 
    errorLoadingStopContract, 
    isErrorStopContract, 
    refetchDetailContract,
    refetchAllContract,
    toggleModalStopContract
  ]);

  return (
    <>
      <Col span={24}>
        <Card className="rounded-md">
          <Row className="flex justify-between">
            <Col>
              <h5 className="text-gray-500 mb-3">
                {t("Status")}
              </h5>
              <h4 className={clsx("text-3xl", CONTRACT_STATUS[dataDetailContract?.status]?.clsTextColor)}>
                {CONTRACT_STATUS[dataDetailContract?.status]?.value}
              </h4>
            </Col>
            <Col>
              <h5 className="text-gray-500 mb-3">
                {t("Date Issued")}
              </h5>
              <h4 className="text-3xl">
                {dataDetailContract?.issued_at
                ? moment(dataDetailContract?.issued_at, moment.ISO_8601)
                  .format("DD/MM/YY")
                : "--"}
              </h4>
            </Col>
            <Col>
              <h5 className="text-gray-500 mb-3">
                {t("Expired Date")}
              </h5>
              <h4 className="text-3xl">
                {dataDetailContract?.expired_at
                ? moment(dataDetailContract?.expired_at, moment.ISO_8601)
                  .format("DD/MM/YY")
                : "--"}
              </h4>
            </Col>
            <Col>
              <h5 className="text-gray-500 mb-3">
                {t("Last Update")}
              </h5>
              <h4 className="text-3xl">
                {dataDetailContract?.updated_at
                ? moment(dataDetailContract?.updated_at, moment.ISO_8601)
                  .format("DD/MM/YY")
                : "--"}
              </h4>
            </Col>
            <Col className="flex items-center justify-center">
              <Button 
                type="danger" 
                className="w-48 mb-2"
                onClick={toggleModalStopContract}
                disabled={!isActive}
              > 
                {t("Stop Agreement")}
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} md={24}>
        <Card className="rounded-md">
          <h2 className="text-gray-500 text-lg md:text-xl font-medium mb-2">
            {t("Product List")}
          </h2>
          <Table 
            pagination={{ position: ["none"]}}
            columns={[
              {
                title: t('Product Name'),
                dataIndex: 'product_name',
                key: 'product_name',
                render: text => text,
              },
              {
                title: t('Unit Price'),
                dataIndex: 'unit_price',
                key: 'unit_price',
                render: text => `Rp ${numberCommas(text)},-`,
              },
              {
                title: t('Quantity'),
                dataIndex: 'quantity',
                key: 'quantity',
                render: text => numberCommas(text),
              },
              {
                title: t('Quota'),
                dataIndex: 'quota',
                key: 'quota',
                render: text => numberCommas(text),
              },
              {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
                align: 'right',
                render: text => `Rp ${numberCommas(text)},-`,
              },
            ]} 
            dataSource={dataDetailContract?.products || []} 
          />
          <div className="flex flex-row justify-between p-4 text-blue-500">
            <p className="font-bold">Total</p>
            <p className="font-bold">
              Rp {numberCommas(totalProductPrice)},-
            </p>
          </div>
        </Card>
      </Col>
      <Col xs={24} md={24}>
        <Card className="rounded-md">
          <h2 className="text-gray-500 text-lg md:text-xl font-medium mb-2">
            {t("Module List")}
          </h2>
          <Table 
            pagination={{ position: ["none"] }}
            columns={[
              {
                title: t('Module Name'),
                dataIndex: 'module_name',
                key: 'module_name',
                render: text => t(text),
              },
            ]} 
            dataSource={moduleListData} 
          />
        </Card>
      </Col>
      {/* 
      // ===============================================
      // Document Contract is disabled for now.
      // It is hidden, until further requirement confirmations.
      // ===============================================
      <Col xs={24} md={24}>
        <Card className="rounded-md">
          <h2 className="text-gray-500 text-lg md:text-xl font-medium mb-2">
            Document List
          </h2>
          <Table 
            pagination={{ position: ["none"]}}
            columns={[
              {
                title: 'Document Name',
                dataIndex: 'doc_name',
                key: 'doc_name',
                render: text => text,
              },
              {
                title: 'Last Update',
                dataIndex: 'updated_at',
                key: 'updated_at',
                render: updated_at => updated_at
                ? moment(updated_at, moment.ISO_8601)
                  .format("DD/MM/YYYY")
                : "--",
              },
              {
                title: 'Approval',
                dataIndex: 'status',
                key: 'status',
                render: status => <p className={CONTRACT_DOC_STATUS[status]?.clsTextColor}>
                  {CONTRACT_DOC_STATUS[status]?.value}
                </p>,
              },
              {
                title: '',
                dataIndex: 'status',
                key: 'status',
                render: (status, row) => {
                  if(status === 1 || status === 2) {
                    return (
                      <Button 
                        icon={<EyeFilled />}
                        onClick={async () => {
                          setIsFetchingContractDocFile(true)
                          const response = await getBlob(`/document/preview/${row?.id}?contract=1`);
                          setContractDocName(row?.doc_name)
                          setContractDocFile(response?.data)
                          setIsFetchingContractDocFile(false)
                          toggleModalContractDoc()
                        }}
                      >
                        Detail
                      </Button> 
                    )
                  }

                  if(status === 3) {
                    return (
                      <Button 
                        type="primary" 
                        ghost 
                        onClick={() => {
                          setContractDocName(row?.doc_name)
                          toggleModalUpdateDoc(row?.id)
                        }}
                        icon={<UploadOutlined />}
                      >
                        Re-Upload
                      </Button> 
                    )
                  }

                  return null
                },
                width: 20,
                align: 'center',
              },
            ]} 
            expandable={{
              expandedRowClassName: () => "bg-blue-500",
              expandedRowRender: record => {
                return(
                  <div className="rounded-md border-l-4 border-red-500 bg-red-100 px-4 py-2">
                    <p className="text-red-500">Reason of rejection</p>
                    <p>{record.note}</p>
                  </div>
                )
              },
              rowExpandable: record => record.status === 3,
              expandIcon: ({ expanded, onExpand, record, expandable }) =>
                expandable ? 
                  expanded ? (
                    <UpOutlined onClick={e => onExpand(record, e)} />
                  ) : (
                    <DownOutlined onClick={e => onExpand(record, e)} />
                  ) 
                : null,
              expandRowByClick: true
            }}
            dataSource={dataDetailContract?.contract_docs || []} 
            rowKey="id"
          />
        </Card>
      </Col> */}
      <Col span={24}>
        <Card className="rounded-md">
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={12}>
              <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                {t("History Cooperation Agreement")}
              </h3>
            </Col>
            <Col span={24}>
              <Table
                columns={[
                  {
                    title: t("Activity"),
                    dataIndex: "--",
                    key: "--",
                    render: (activity) => {
                      return (
                        <p>
                          {t("Cooperation Agreement - Customer")}
                        </p>
                      );
                    },
                  },
                  {
                    title: t("Issued Date"),
                    dataIndex: "issued_at",
                    key: "issued_at",
                    render: (issued_at) => {
                      return (
                        <p>
                          {issued_at
                            ? moment(issued_at, moment.ISO_8601)
                              .format("DD/MM/YY")
                            : "--"}
                        </p>
                      );
                    },
                  },
                  {
                    title: t("Expired Date"),
                    dataIndex: "expired_at",
                    key: "expired_at",
                    render: (expired_at) => {
                      return (
                        <p>
                          {expired_at
                            ? moment(expired_at, moment.ISO_8601)
                              .format("DD/MM/YY")
                            : "--"}
                        </p>
                      );
                    },
                  },
                  {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    render: (status) => {
                      return (
                        <p className={CONTRACT_STATUS[status]?.clsTextColor}>
                          {t(CONTRACT_STATUS[status]?.value)}
                        </p>
                      );
                    },
                  },
                ]}
                dataSource={dataAllContract}
                scroll={{ x: 500 }}
                pagination={false}
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Modal
        title="Are you sure?"
        subtitle="Are you sure want to stop this cooperation?"
        type="warning"
        visible={isModalStopContract}
        onCancel={toggleModalStopContract}
        width={400}
      >
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={toggleModalStopContract}
          >
            Back
          </Button>
          <Button className="w-full" type="primary" onClick={handleStopContract}>
            Confirm
          </Button>
        </div>
      </Modal>
      <Modal
        title="Are you sure?"
        subtitle="You can change the status cooperation agreement below"
        type="upload"
        visible={isModalReuploadDoc}
        onCancel={toggleModalUpdateDoc}
      >
        <Form
          onFinish={handleSubmitUReuploadDoc}
          form={docReuploadForm}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            className="mx-auto"
            label=""
            name="fileDocument"
            rules={[
              {
                required: false,
                message: "Please attach file!",
              },
            ]}
          >
            <div className="flex justify-center">

              <Upload
                className="text-center"
                maxCount={1}
                {...propsUploadDocument}
                fileList={fileListDoc}
                itemRender={(originNode) => (
                  <div className="w-96 max-w-max overflow-hidden">
                    {originNode}
                  </div>
                )}
              >
                <Button ghost icon={<UploadOutlined />}>
                  Click to Upload
                </Button>
                <br />
                <small>Format : PDF | Max : 5 Mb</small>
              </Upload>

            </div>
          </Form.Item> 
          <Form.Item>
            <div className="flex flex-row space-x-4 pt-4">
              <Button
                className="w-full"
                type="primary"
                ghost
                onClick={toggleModalUpdateDoc}
              >
                {t("Back")}
              </Button>
              <Button className="w-full" type="primary" htmlType="submit">
                {t("Submit")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        type="success"
        title="Updated Success!"
        subtitle="Changes have been saved! Now you can continue browsing."
        visible={isModalSuccess}
        closable={false}
      >
        <div className="text-center">
          <Button 
            className="w-32" 
            type="primary" 
            onClick={() => {
              toggleModalSuccess()
            }}
          >
            {t("Done")}
          </Button>
        </div>
      </Modal>
      <AntModal 
        title={contractDocName} 
        visible={isModalContractDocOpen} 
        onOk={toggleModalContractDoc} 
        onCancel={toggleModalContractDoc}
        footer={[]}
        width={800}
        centered
      >
        <div className="relative flex justify-center items-center p-8 bg-gray-300">
          <Document file={contractDocFile} onLoadSuccess={onContractDocLoadSuccess}>
            <Page pageNumber={contractDocFilePageNumber}>
            </Page>
          </Document>
          <div className="flex flex-row justify-center items-center space-x-2 bg-gray-100 shadow-md absolute bottom-5 right-5 p-2 rounded-md">
            <Select
              value={contractDocFilePageNumber}
              size="small"
              onChange={handleChangeContractDocPage}
            >
              {Array.from(new Array(contractDocFileNumPages), (_, index) => (
                <Option value={index + 1}>{index + 1}</Option>
              ))}
            </Select>
            <p>{`dari ${contractDocFileNumPages} halaman`}</p>
          </div>
        </div>
      </AntModal>
      <Spinner loading={isFetchingContractDocFile || isLoadingReuploadDocumentContract} />
    </>
  )
}