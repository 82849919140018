export const pieOptions = (title, name, labels = [], data = [], colors = []) => {
  data = data.map((item, index) => ({ y: item, name: labels[index], color: colors[index] }))
  return {
    yAxis: {
      crosshair: true,
    },
    title: { text: title },
    chart: { type: 'pie' },
    tooltip: {
      shared: true,
      useHTML: true,
      headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
      pointFormat: '<tr><td>{series.name} </td>' +
        '<td style="text-align: right"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
    },
    credits: { enabled: false },
    plotOptions: {
      pie: { showInLegend: true, allowPointSelect: true },
      series: {
        dataLabels: {
          enabled: true, distance: "-50%",
          softConnector: false,
          connectorWidth: 0,
          style: {
            fontSize: '1.2em',
            color: "#fff"
          },
          format: '{y}'
        }
      }
    },
    series: [{
      name: name,
      type: 'pie',
      label: { enabled: false },
      data: data,
    }]
  }
}
export const generateRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};