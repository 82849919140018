import {
  Alert,
  Card,
  Col,
  Row,
  Table,
} from "antd";
import { Button } from "../../components";
import clsx from "clsx";
import moment from "moment";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// === REDUX API
import { useFetchAllContractQuery, useFetchDetailContractQuery } from "../../config/features";

// === CONSTANT
import { CONTRACT_STATUS } from "../../constant";


export default function CooperationAgreement({ dataDetailClient, id }) {
  const history = useHistory();
  
  const { data: dataAllContract = [], refetch: refetchAllContract } = useFetchAllContractQuery(id);
  
  const { data: dataDetailContract = {} } = useFetchDetailContractQuery(
    dataAllContract[0]?.id
    );
    
    const isActiveAccount = dataDetailClient?.account?.is_verified && dataDetailClient?.company?.is_active
    const isPendingAgreement = dataDetailContract?.status === 1

  useEffect(() => {
    refetchAllContract()
  }, [refetchAllContract])

  return (
    <>
      {!isActiveAccount &&
        <Col span={24}>
          <Alert
            message="Account is not active"
            description={<p>Please activate the account throught <strong>Client Information</strong> menu to be able to access the Cooperation Agreement</p>}
            type="warning"
            showIcon
          />
        </Col>
      }
      <Col span={24}>
        <Card className="rounded-md">
          <Row className="flex justify-between">
            <Col>
              <h5 className="text-gray-500 mb-3">
                Status
              </h5>
              <h4 className={clsx("text-3xl", CONTRACT_STATUS[dataDetailContract?.status]?.clsTextColor)}>
                {CONTRACT_STATUS[dataDetailContract?.status]?.value}
              </h4>
            </Col>
            <Col>
              <h5 className="text-gray-500 mb-3">
                Date Issued
              </h5>
              <h4 className="text-3xl">
                {dataDetailContract?.issued_at
                ? moment(dataDetailContract?.issued_at, moment.ISO_8601)
                  .format("DD/MM/YY")
                : "--"}
              </h4>
            </Col>
            <Col>
              <h5 className="text-gray-500 mb-3">
                Expired Date
              </h5>
              <h4 className="text-3xl">
                {dataDetailContract?.expired_at
                ? moment(dataDetailContract?.expired_at, moment.ISO_8601)
                  .format("DD/MM/YY")
                : "--"}
              </h4>
            </Col>
            <Col>
              <h5 className="text-gray-500 mb-3">
                Last Update
              </h5>
              <h4 className="text-3xl">
                {dataDetailContract?.updated_at
                ? moment(dataDetailContract?.updated_at, moment.ISO_8601)
                  .format("DD/MM/YY")
                : "--"}
              </h4>
            </Col>
            <Col className="flex items-center justify-center">
              <Button 
                type="primary"
                className="w-48" 
                onClick={() => history.push({
                  pathname: `/cooperation-agreement/detail/${dataDetailContract?.id}`,
                  state: { client: id }
                })}
                disabled={!isActiveAccount}
                ghost 
              > 
                View Detail &gt;
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <Card className="rounded-md">
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={12}>
              <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                History Cooperation Agreement
              </h3>
            </Col>
            <Col xs={24} sm={12} className="text-left sm:text-right">
              <Button 
                type="primary" 
                disabled={!isActiveAccount || isPendingAgreement}
                onClick={() => history.push({
                  pathname: `/cooperation-agreement/new/${id}`,
                })}
              >
                New Cooperation Agreement
              </Button>
            </Col>
            <Col span={24}>
              <Table
                columns={[
                  {
                    title: "Activity",
                    dataIndex: "--",
                    key: "--",
                    render: (activity) => {
                      return (
                        <p>
                          Cooperation Agreement - Customer
                        </p>
                      );
                    },
                  },
                  {
                    title: "Issued Date",
                    dataIndex: "issued_at",
                    key: "issued_at",
                    render: (issued_at) => {
                      return (
                        <p>
                          {issued_at
                            ? moment(issued_at, moment.ISO_8601)
                              .format("DD/MM/YY")
                            : "--"}
                        </p>
                      );
                    },
                  },
                  {
                    title: "Expired Date",
                    dataIndex: "expired_at",
                    key: "expired_at",
                    render: (expired_at) => {
                      return (
                        <p>
                          {expired_at
                            ? moment(expired_at, moment.ISO_8601)
                              .format("DD/MM/YY")
                            : "--"}
                        </p>
                      );
                    },
                  },
                  {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    render: (status) => {
                      return (
                        <p className={CONTRACT_STATUS[status]?.clsTextColor}>
                          {CONTRACT_STATUS[status]?.value}
                        </p>
                      );
                    },
                  },
                ]}
                dataSource={dataAllContract}
                scroll={{ x: 500 }}
                pagination={false}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  )
}