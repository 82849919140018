import { FilterOutlined, PlusOutlined, RightOutlined, SettingOutlined, DashOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Select,
  message,
  Divider,
  Upload,
  DatePicker,
  Table,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFetchListRecordRekonQuery, useAddUserReconStatusMutation } from "../../config/features/document";
import { errorMessage, exportExcel, getBlob } from "../../utils";
import { Button, Modal, Spinner}  from "../../components";
import { useTranslation } from "react-i18next";
import { objectToQueryString } from "../../utils/queryString";
const { Search } = Input;
const { Option } = Select;

export default function Rekonsiliasi() {
  const history = useHistory();
  const { t } = useTranslation();
  const [paramsTable, setParamsTable] = useState({
    page: 1,
    row: 10,
    status_rekon: "",
    startdate: "",
    enddate: "",
    rekonsile_code: ""
  });
  const {
    data: dataListRecordRekon = [],
    isFetching,
    isError,
    error,
    refetch
  } = useFetchListRecordRekonQuery(paramsTable)
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [visibleAction, setVisibleAction] = useState(false);
  const [visibleConfirm, setIsVisibleConfirm] = useState(false);
  const [fileList, setFileList] = useState([]);
  
  const statusList = [
    t("All Status"),
    "SETTLEMENT",
    "READY TO SETTLE",
    "NOT SETTLEMENT",
    "WAITING TO SETTLE"    
  ]

  const [statusRecon, setStatusRecon] = useState("Tidak Sesuai");
  const dataListDocType = [
    {
        status: "Sesuai"
    },
    {
        status: "Tidak Sesuai"
    }
  ]

  const [addUserReconStatus, { isSuccess, isLoading, errors, data, isErrors }] = useAddUserReconStatusMutation();
  const [paramsSettle, setParamSetlle] = useState({
    periode: "",
    tanggal:"",
    total:"",    
  });

  
  function handleParamSettle (param){
    setParamSetlle({
      id_rekon: param?.id,
      periode: moment(param?.start_date).utc(true).format("MMMM").toString(),
      tanggal: param?.range,
      total: param?.total_data,      
    });
  }



 
  function hadleVisibleConfirmSettle(){
    setIsVisibleConfirm(!visibleConfirm);
  }
  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }
  function handleVisibleAction(flag) {
    setVisibleAction(flag);
  }
  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }
  function handleResetFilter(params) {
    setParamsTable({
      ...paramsTable,
      rekonsile_code: "",
      page: 1,
      status_rekon: "",
      startdate: "",
      enddate: "",
    })
  }
  const handleClickExportExcel = async (record) => {
    const queryString = objectToQueryString({ export_format: 'excel'});
    const response = await getBlob(`/record/excel-rekonsiliasi/${record.id}?${queryString}`);
    exportExcel(response.data, "Record Rekonsiliasi");
  }

  
  // const [addReconsileFile, { isSuccess, isLoading, errors, data, isErrors }] = useAddReconsileFileMutation();

  const menuFilter = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p className="text-gray-300 hover:text-gray-500" onClick={handleResetFilter}>
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Status</p>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              value={paramsTable.status_rekon}
              onChange={(e) => setParamsTable({ ...paramsTable, status_rekon: e })}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              allowClear
            >
              {statusList.map((value, index) => (
                <Option key={index} value={index != 0 ? index : ""}>{value}</Option>
              ))}
            </Select>
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("date")}</p>
            <DatePicker.RangePicker
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(value) => {
                setParamsTable({
                  ...paramsTable,
                  startdate: moment(value[0]).utc().format().toString(),
                  enddate: moment(value[1]).utc().format().toString()
                })
              }}
              value={[
                paramsTable.startdate ? moment(paramsTable.startdate) : "",
                paramsTable.enddate ? moment(paramsTable.enddate) : ""
              ]}
              placeholder={[t("Start Date"), t("End Date")]}
            />
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const menuAction = (record) => (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => history.push({
          pathname: `/Rekonsiliasi/detail/${record.id}`,
          state: {a: record}
        })}>
        <p className="text-blue-600">Detail Rekonsiliasi</p>
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          handleClickExportExcel(record)
          }}>
          <p className="text-blue-600">{t("Download")} Settlement</p>
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          hadleVisibleConfirmSettle();
          handleParamSettle(record);
        }}>
          <p className="text-blue-600">Konfirmasi Settlement</p>
      </Menu.Item>
    </Menu>
  )

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Kode Rekonsiliasi",
      dataIndex: "rekonsile_code",
      key: "rekonsile_code",
    },
    {
      title: "Tanggal Rekonsiliasi",   
      dataIndex: "range",
      key: "range",   
      // render: (start_date, end_date) => moment(start_date).utc(true).format("YYYY-MM-DD").toString() + ' - ' +moment(end_date).utc(true).format("YYYY-MM-DD").toString() 
    },
    {
      title: "Tanggal Proses",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).utc(true).format("YYYY-MM-DD").toString()
    },
    {
      title: "Periode",
      dataIndex: "start_date",
      key: "start_date",
      render: (start_date) => moment(start_date).utc(true).format("MMMM").toString()
    },
    {
      title: "Total Data",
      dataIndex: "total_data",
      key: "total_data",
      width: 25
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 50,
      render: (status) => {
        if(status === 'NOT SETTLEMENT'){
          return <span style={{color: "lightsalmon"}}>{status}</span>
        } else if (status === 'SETTLEMENT'){
          return <span style={{color: "green"}}>{status}</span>
        } else {
          return <span style={{color: "blueviolet"}}>{status}</span>
        }
      }
    },
    {
      title: "Aksi",
      render: (record) => (
        <div className="group cursor-pointer">
          <Dropdown
            overlay={menuAction(record)}            
            trigger={["click"]}>
                <DashOutlined className="group-hover:text-blue-500" />
          </Dropdown>          
        </div>
      )
    }
  ];

  const propsUpload = {
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      
      const isLt5M = file.size / 1024 / 1024 < 50;
      if (!isLt5M) {
        message.error("File must smaller than 50MB!");
      } else {
        setFileList([file]);
      }
      return false;
    },
    fileList,
  };

  useEffect(() => {
    if (isError) {
      errorMessage(error)
      //console.log(error);
    }
  }, [isError, error])
  
  useEffect(() => {
    if (isErrors) {
      setIsVisibleConfirm(false);
      errorMessage(errors);
    }
  }, [isErrors, errors]);
  
  const onSearch = (value) => setParamsTable({ ...paramsTable, rekonsile_code: value, page: 1 });
  
  useEffect(() => {    
    refetch()
    
  }, [refetch])



  async function handleSubmit() {
    hadleVisibleConfirmSettle();
    if(fileList.length < 0){
      message.error("Anda Belum Melakukan Upload Dokumen Rekonsiliasi !");
    }

    try {      
      const formData = new FormData();      
      formData.append("file", fileList[0]);
      formData.append("id_rekon", paramsSettle?.id_rekon);   
      formData.append("status", statusRecon);      
      // addReconsileFile(formData);
      addUserReconStatus(formData);
    } catch (error) {
      message.error(error.message);
    }
  }



  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <h1 className="text-xl md:text-2xl font-medium">
                {t("rekon")}
              </h1>
            </Col>
            <Col>
                <Button
                  className="md:w-40"
                  type="primary"
                  onClick={()=> history.push(`/Rekonsiliasi-list`)}
                  htmlType="submit">
                    Proses Rekonsiliasi
                </Button>              
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("listrekon")}
                    </h3>
                  </Col>
                  <Col>
                    <Row gutter={[12, 12]}>
                      <Col>
                        <Search
                          placeholder={t("Search")}
                          allowClear
                          onSearch={onSearch}
                          onChange={(e) =>
                            setTimeout(() => {
                              onSearch(e.target.value);
                            }, 1000)
                          }
                          style={{
                            width: 200,
                            borderRadius: "10px !important",
                          }}
                        />
                      </Col>
                      <Col>
                        <Dropdown
                          overlay={menuFilter}
                          onVisibleChange={handleVisibleFilter}
                          visible={visibleFilter}
                          trigger={["click"]}
                        >
                          <Button
                            type="primary"
                            ghost
                            icon={<FilterOutlined />}
                          />
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                </Row>                
              </Col>              
              <Col span={24}>                
                <Table
                  columns={columns}
                  dataSource={dataListRecordRekon?.map((item, index) => ({ no: index + 1, ...item }))}
                  scroll={{ x: 850 }}
                  loading={isFetching}
                  pagination={{
                    current: paramsTable.page,
                    pageSize: paramsTable.row,
                    total: dataListRecordRekon[0] ? dataListRecordRekon[0]?.total : 0,
                  }}
                  onChange={handleChangeTable}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Konfirmasi Settlement"
        subtitle="Apakah data settlement pada portal SCM Pemungut telah sesuai? Berikut informasi data yang siap dilakukan settlement."
        type="warning"
        visible={visibleConfirm}>     
        <div className="flex flex-col space-y-2">
        
          <Row>
              <Col span={9}>
                <span className="text-black-500 font-medium"> Periode </span>
              </Col>
              <Col span={1}>
                <span className="text-black-500 font-medium"> : </span>
              </Col>
              <Col span={12}>              
                {/* {paramsTable.start_date} */}
                {paramsSettle?.periode}
              </Col>
          </Row>
          <Row>
            <Col span={9}>
              <span className="text-black-500 font-medium"> Tanggal </span>
            </Col>
            <Col span={1}>
              <span className="text-black-500 font-medium"> : </span>
            </Col>
            <Col span={12}>
              {/* {paramsTable.end_date} */}
              {paramsSettle?.tanggal}
            </Col>
          </Row>
          <Row>
            <Col span={9}>
              <span className="text-black-500 font-medium"> Total Data Settle</span>
            </Col>
            <Col span={1}>
              <span className="text-black-500 font-medium"> : </span>
            </Col>
            <Col span={12}>
              {paramsSettle?.total}
            </Col>
          </Row>
          <Row>
            <Col span={9}>
              <span className="text-black-500 font-medium">Status Data SCM</span>
            </Col>
            <Col span={1}>
              <span className="text-black-500 font-medium"> : </span>
            </Col>
            <Col span={14}>
            <Select placeholder="Pilih Status" value={statusRecon} style={{width: '100%'}} onChange={(e) => {setStatusRecon(e.value)}}>
                  {
                    dataListDocType?.length > 0 && dataListDocType?.map((data , index) => {
                      return (
                        <Option key={index} value={data.status}>
                          {data.status}
                        </Option>
                      )
                    })
                  }
            </Select>
            </Col>
          </Row>
          <Row style={{border: "2px solid #1D4ED8", padding: 10, borderRadius: 5, backgroundColor: '#DEF2FE', alignContent: 'center'}}>
            <Col align="center" style={{textAlign: 'center', alignContent:'center'}}>
              <span>{`Silahkan upload hasil capture halaman settlement `}</span>&emsp;&emsp;<a className="text-blue-800 font-medium" href="https://newscm.e-meterai.co.id/" target="_blank">Portal SCM Pemungut</a>         
            </Col>
          </Row> 
          <Row>
            <Col span={24} justify="center">
               <Upload                                                      
                  maxCount={1}          
                  className="w-full"                                
                  {...propsUpload}
                  fileList={fileList}
                  accept=".jpg, .jpeg"                                    
                >
                  <Button
                      type="text"
                      className="text-white hover:text-white"
                      style={{ background: "#8BC34A", width: "400%" }}
                      icon={<UploadOutlined />}                                            
                      ghost                                            
                  >
                      Upload
                  </Button> 
                </Upload>                                                            
            </Col>
          </Row>
          <Divider />
          <div className="flex flex-row space-x-4">          
            <Button className="w-full" onClick={hadleVisibleConfirmSettle} type="primary" ghost>
              Kembali
            </Button>
            <Button className="w-full" type="primary" onClick={handleSubmit}>
              {t("btndone")}
            </Button>
          </div>  
          
        
      </div> 
    </Modal>
    <Modal
        title="Berhasil Konfirmasi"
        subtitle="Terimakasih telah melakukan proses konfirmasi data settlement."
        type="success"
        visible={isSuccess}        
      >
        <div className="flex flex-row space-x-4">
          
          <Button className="w-full" onClick={() => {window.location.reload()}} type="primary">
            {t("btndone")}
          </Button>
        </div>
    </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}