import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Row, Skeleton, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../../components";
import { SiMicrosoftexcel } from "react-icons/si";
import { useCallback, useEffect, useState } from "react";
import { useFetchDetailReportRekapQuery, useFetchListReportRekapQuery, useFetchOfficeDetailQuery } from "../../config/features";
import moment from "moment";
import { getMonthName, summarizeVal } from "../../utils/array";
import { errorMessage, exportExcel, getBlob, numberCommas } from "../../utils";
import { createPagination } from "../../utils/table";

export default function RekapitulasiDetail() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id, dept_id, monthDate } = useParams();
  const [paramsTable, setParamsTable] = useState({
    row: 10,
    page: 1,
    office_id: id,
    monthDate,
    sort_by: "used_at",
    order: "desc",
    dept_id: dept_id || ''
  })
  const [rekapResult, setRekapResult] = useState({})


  const {
    data: rekapDetailList = {},
    isFetching,
    refetch
  } = useFetchDetailReportRekapQuery(paramsTable)
  const columns = [
    { title: 'No', dataIndex: 'no', key: 'no' },
    { title: t('User'), dataIndex: 'full_name', key: 'full_name' },
    ... !dept_id ? [{ title: t('Department'), dataIndex: 'department', key: 'department' }] : [],
    { title: t('Total Stamp'), dataIndex: 'total_stamp', key: 'total_stamp' },
    {
      title: t('doctype'), dataIndex: 'doc_type', key: 'doc_type',
      render: (doc_type) => t(doc_type)
    },
    { title: t('Document Name'), dataIndex: 'doc_name', key: 'doc_name' },
    { title: t('Upload Date'), dataIndex: 'upload_date', key: 'upload_date' },
  ]
  const handleChangeTable = useCallback((pagination) => {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize
    })
  }, [])

  const handleClickExportExcel = () => {
    getBlob(`/report/rekapitulasidetil?office_id=${id}&dept_id=${dept_id || ''}&monthDate=${monthDate}&export_format=excel`)
    getBlob(`/report/rekapitulasidetil?office_id=${id}&dept_id=${dept_id || ''}&monthDate=${monthDate}&export_format=excel`)
      .then(response => exportExcel(response.data, `Data Detail Rekapitulasi_${monthDate}_${new Date()}`))
      .catch(err => errorMessage(err))
  }

  useEffect(() => {
    if (rekapDetailList?.summary) {
      const splitted = rekapDetailList.summary.period.split(" ")
      setRekapResult({
        "Work Unit": rekapDetailList.summary.office,
        // "Department": rekapDetailList.summary.department,
        // "User": rekapDetailList.summary.user,
        ...dept_id ? { "Department": rekapDetailList.summary.department } : { "Department": "All" },
        "Period": `${t(splitted[0])} ${splitted[splitted.length - 1]}`,
        "Total Stamp": numberCommas(rekapDetailList.summary.total_stamp),
        "Total (Rp)": `Rp ${numberCommas(rekapDetailList.summary.total_rp)}`
      })
    }
  }, [rekapDetailList])

  useEffect(() => {
    refetch()
  }, [refetch])
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div className="group cursor-pointer" onClick={() => history.goBack()}>
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    {t("Detail Data Recapitulation")}
                  </h1>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Skeleton loading={isFetching} active paragraph={{ rows: 6 }}>
              <Row gutter={[24, 24]} justify="space-between">
                <Col span={12}>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    {t('recapresult')} :
                  </h3>
                  {Object.entries(rekapResult).map(([key, val], index) => (
                    <Row className="mt-2">
                      <Col span={5}>
                        <h4 className="text-gray-500 font-medium">{t(key)}</h4>
                      </Col>
                      <Col span={1}>
                        <h4 className="text-gray-500 font-medium">:</h4>
                      </Col>
                      <Col>
                        <h4 className="text-gray-500 font-medium">{t(val)}</h4>
                      </Col>
                    </Row>
                  ))}
                </Col>
                <Col>
                  <Button
                    type="text"
                    className="text-white hover:text-white"
                    style={{ background: "#8BC34A" }}
                    icon={<SiMicrosoftexcel />}
                    onClick={handleClickExportExcel}
                  >
                    {t("Export Excel")}
                  </Button>
                </Col>
              </Row>
              <Row gutter={[24, 24]} className="mt-4">
                <Col span={24}>
                  <Table
                    columns={columns}
                    // dataSource={rekapDetailList?.data?.map((item, i) => ({
                    //   no: i + 1,
                    //   jumlah_dokumen: 1,
                    //   tipe: "Single",
                    //   ...item
                    // }))}
                    dataSource={createPagination(rekapDetailList?.data || [], paramsTable)}
                    scroll={{ x: 850 }}
                    loading={isFetching}
                    pagination={{
                      current: paramsTable.page,
                      pageSize: paramsTable.row,
                      total: rekapDetailList.total || 0
                    }}
                    onChange={handleChangeTable}
                  />
                </Col>
              </Row>
            </Skeleton>
          </Card>
        </Col>
      </Row>
    </>
  )
}