import { Form, Row , Col, Card, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Modal, Spinner } from '../../components'
import OtpInput from 'react-otp-input'
import axios from 'axios'
import CheckCirlceSuccess from '../../assets/img/icon/check-circle-success.png'
import FaceRecog from '../../assets/img/icon/face.png'
import Record from '../../assets/img/icon/record.png'
import Pause from '../../assets/img/icon/pause.png'
import FailedIcon from '../../assets/img/icon/failed.png'
import RetryIcon from '../../assets/img/icon/retry.png'
import NoVideoIcon from '../../assets/img/icon/novideo.png'
import AdminInfo from '../Profile/AdminInfo'

import { rootApi } from '../../config/features/config'
import { useTranslation } from 'react-i18next'
import { useFetchProfileUserQuery } from '../../config/features'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const toBase64 = (file) => {

    return new Promise(( resolve , reject) => {

        const reader = new FileReader()

        reader.readAsDataURL(file)
    
        reader.onload = () => resolve( reader.result.replace('data:video/webm;base64,' , '') )

        reader.onerror = (e) => reject(e)
    
    })

}



function RegistrationCheck({token}) {
    const { t } = useTranslation()

    const tokenPeruri = useMemo( () => localStorage.getItem('peruri') , [])

    const tokenAccess = useMemo( () => localStorage.getItem('access') , [])

    const { data: profileUser = {} } = useFetchProfileUserQuery()

    const history = useHistory()

    const [instruction , setInstruction] = useState(0) 

    const { tab = 0, renewal = false } = useLocation().state || {};

    const [tabs, setTabs] = useState(tab || 0);


    const lastTab = 2;

    const isEndTabs = tabs === lastTab;

    const [otpValue , setOtpValue] = useState('')

    const [openConfirm , setOpenConfirm] = useState(false)

    const [openSuccess , setOpenSuccess] = useState(false)

    const [openSave , setOpenSave] = useState(false)

    const [continueVid , setContinueVid] = useState(false)

    const [record , setRecord] = useState(false)

    const [save , setSave] = useState(false)

    const [blob , setBlob] = useState(null)

    const [loading , setLoading] = useState(false)

    const [isSuccessAct , setSuccessAct] = useState(false)

    const [showVideo , setShowVideo] = useState(false)

    const [isSaved , setIsSaved] = useState(false)

    const [isSendOtp ,  setSendOtp] = useState(false)

    const [isCheckedOtp ,  setCheckedOtp] = useState(false)

    const [resendSuccess, setResendSuccess] = useState(false)

    const [count , setCount] = useState(0)

    const instructionText = useMemo( () => [
        'Please, blink at the camera' ,
        'Once again, blink at the camera' ,
        'Next, open your mouth for 2 seconds.' ,
        'And close your mouth for a while' ,
        'Yup, your face recording is successfull'
    ] , [])


    useEffect( () => {

        if(count === 3)
        {
            handleStop(true)
        }

    },[count])

    useEffect( () => {

        if(instruction !== 0)
        {
            if( instruction === 5)
            {
                handleStopVid()
            }
            else
            {
                setTimeout( () => {
                    
                    setInstruction(prev => prev + 1)
    
                },2300)
            }
        }


    },[instruction])

    const handleStopVid = () => {

        document.getElementById('stopbtn').click()
    }

    const onFinishAct = async () => {

        setOpenConfirm(true)
      
    }

    const sendVideo = async () => {

        setLoading(true)

        let body = {
            base64Vid : blob ,
            otp : otpValue
        }

        try{

            const url = renewal ? `${rootApi}/sign/renewalvideo-kyc` : `${rootApi}/sign/video-kyc`

            const {data} = await axios({
                method : 'POST' ,
                url,
                data : body ,
                headers : {
                    token : tokenAccess ,
                   "token-peruri-sign" : tokenPeruri
                }
            })
    
            setLoading(false)

            setOpenSuccess(true)

            handleStop()


        }catch(e){

            const {data : {error}} = e.response

            setCount( prev => prev + 1)

            if(count !== 2 )
            {
                message.error(t(`Video recording process failed, please retry this process by clicking “Try Again” button. You have already tried`) + `${count === 0 ? t('one') : t('two')} ${t('of the three trials')}`)
            }

            setLoading(false)

        }

    }



    const handleVideo = () => {

        setContinueVid(true)

        let mediaRecorder
        let parts = []

        navigator.mediaDevices.getUserMedia({video : {
            width : 1000 ,
            height : 460
        }}).then( stream => {
            
            
            document.getElementById('video').srcObject = stream

            document.getElementById('btn').onclick = function(){

                setInstruction( prev => prev + 1)

                setIsSaved(false)

                setOpenSave(true)

                if(!record){
                    setRecord(true)
                    message.info(t('Starting record'))
                }
                mediaRecorder = new MediaRecorder(stream)
                mediaRecorder.start(1000)
                mediaRecorder.ondataavailable = function(e){
                    parts.push(e.data)
                }
            }

            document.getElementById('stopbtn').onclick = async function(){ 

                mediaRecorder.src = ""

                mediaRecorder.stop()

                const blob = new Blob(parts , {
                    type : 'video/webm'
                })

                setBlob( await toBase64(blob))

                setSave(true)

                setOpenSave(true)
                
                parts = []

                message.info(t('Record Stoped'))
                
                document.getElementById('stopbtn').style.display = "none"

            }

                document.getElementById('btncheck').onclick = function(){

                parts = []

                setSave(false)

                setOpenSave(false)

                setRecord(false)

                setIsSaved(false)

                setInstruction(0)
            }
        })

    }


    const handleStop = (showVideo = false) => {

        const video = document.querySelector('video')

        const mediaStream = video.srcObject

        const tracks = mediaStream.getTracks()

        tracks[0].stop()

        if(showVideo)
        {
            setShowVideo(true)
        }        

    }

    const handleSave = async () => {

        message.info(t('Record has saved'))

        setIsSaved(true)

        setSave(false)

        setOpenSave(false)

        setRecord(false)

    }


    const handleSendOtp = async () => {

        try{
            const url = !renewal ? `${rootApi}/sign/send-otp` : `${rootApi}/sign/send-otp-reNewal`

            const {data} = await axios({
                method : 'POST' ,
                url,
                headers : {
                    token : tokenAccess ,
                   "token-peruri-sign" : tokenPeruri
                }
            })

            setSendOtp(true)

            message.success(t('OTP has send to your email, please check your email.'))

        }catch(e){

            message.success(t('Failed to send otp'))

        }

    }

    const handleCheckOtp = async () => {

        try{

            setLoading(true)

            let body = {
                otp : otpValue
            }
    
            const url = !renewal ? `${rootApi}/sign/check-otp` : `${rootApi}/sign/check-otp-reNewal`
            const {data} = await axios({
                method : 'POST' ,
                url,
                data : body ,
                headers : {
                    token : tokenAccess ,
                   "token-peruri-sign" : tokenPeruri
                }
            })

            setLoading(false)

            setCheckedOtp(true)

            setTabs( prev => prev + 1)

        }catch(e){

            setLoading(false)

            message.error(t("Sorry we cant process your data , please try again !"))

        }

       
    }

    const alert = e => {
        e.preventDefault()

        navigator.mediaDevices.getUserMedia({video : false})

        return t('Are you sure wannt to leave registration')
    }


    useEffect(() => {

        async function activateEmail(){

            try{

                setLoading(true)

                let body = {
                    token 
                }
    
                const {data} = await axios({
                    method : 'POST' ,
                    url : `${rootApi}/sign/activate-email` ,
                    data : body ,
                    headers : {
                        token : tokenAccess ,
                       "token-peruri-sign" : tokenPeruri
                    }
                })
    
                setSuccessAct(true)
    
                setLoading(false)

            }catch(e){
                setSuccessAct(false)
                setLoading(false)

            }
        }

        window.addEventListener('beforeunload' , alert)
        
        if (Object.keys(profileUser).length > 0) {
            if (profileUser?.is_email_peruri_sign_verified == false) {
                activateEmail()
            }
            else {
                setSuccessAct(true)
            }
        }

    }, [profileUser])
    
    // useEffect(() => {
    //     console.log(profileUser);
    // }, [profileUser])


    useEffect( () => {

        
        if(tabs === 1 && !isSendOtp)
        {
            handleSendOtp()
        }

    },[tabs])

    const handleRetrySendEmail = async (e) => {
        e.preventDefault()        
        try {
            setLoading(true)

            // Resend activation email
            await axios({
                method: 'POST',
                url: `${rootApi}/sign/resend-activation`,
                headers: {
                    token: tokenAccess
                }
            })

            setLoading(false)

            setTimeout(() => {
                setResendSuccess(true)
            }, 700);
            
        } catch (e) {
            setLoading(false)
            const {data : {error}} = e.response
            message.error(error)
        }
    }
    return (
        <>        
             <Form >
                 <Row>
                     <Col span={24} className="px-36">
                         <Row gutter={[24, 24]} className="mt-4">
                             <Col xs={24} sm={12} md={8} className="flex justify-start">
                                 <h1 className="text-xl md:text-2xl font-medium">
                                     {t("Activate Account")}
                                </h1>
                            </Col>
                            <Col xs={24} sm={12} md={8} className="flex justify-center">
                                <div className="flex flex-col space-y-4">
                                <div className="flex flex-row space-x-2 md:space-x-4 lg:space-x-12 xl:space-x-20">
                                    <div
                                    className={`py-0.5 px-2 md:py-1 md:px-3 text-white rounded-full font-medium text-center transition ease-in-out duration-500 ${
                                        tabs === 0 ? "bg-blue-700" : "bg-gray-300"
                                    }`}
                                    >
                                    1
                                    </div>
                                    <div
                                    className={`py-0.5 px-2 md:py-1 md:px-3 text-white rounded-full font-medium text-center transition ease-in-out duration-500 ${
                                        tabs === 1 ? "bg-blue-700" : "bg-gray-300"
                                    }`}
                                    >
                                    2
                                    </div>
                                    <div
                                    className={`py-0.5 px-2 md:py-1 md:px-3 text-white rounded-full font-medium text-center transition ease-in-out duration-500 ${
                                        tabs === 2 ? "bg-blue-700" : "bg-gray-300"
                                    }`}
                                    >
                                    3
                                    </div>
                                </div>
                                <h2 className="font-medium text-sm md:text-base lg:text-lg text-blue-700 text-center">
                                    {tabs === 0
                                    ? t("Email Confirmation")
                                    : tabs === 1
                                    ? t("OTP Code")
                                    : t("Face Recording")}
                                </h2>
                                </div>
                            </Col>
                            <Col xs={24} sm={12} md={8} className="flex justify-end">
                                <Row gutter={[12, 12]}>
                                {tabs > 0 && count !== 3 && (
                                    <Col>
                                    <Button
                                        className="w-fit lg:w-20 xl:w-32"
                                        type="primary"
                                        ghost
                                        disabled={isCheckedOtp}
                                        onClick={() => setTabs(prevTab => prevTab - 1)}
                                    >
                                        {t("Back")}
                                    </Button>
                                    </Col>
                                )}
                                <Col>
                                    {isEndTabs ? (
                                    <Form.Item>
                                    { count !== 3 ?   
                                        <Button
                                            className="w-fit lg:w-20 xl:w-32"
                                            type="primary"
                                            disabled={ isSaved === false}
                                            //   loading={isLoading}
                                            onClick={onFinishAct}
                                        >
                                            {t("Submit")}
                                        </Button>

                                        :

                                        <Button
                                            className="w-fit lg:w-20 xl:w-32"
                                            type="primary"
                                            disabled={ isSaved === false}
                                            //   loading={isLoading}
                                            onClick={() => history.push('/profile')}
                                        >
                                            {t("Done")}
                                        </Button>
                                    }

                                    </Form.Item>
                                    ) : ( tabs === 1 ? 
                                    <Form.Item>
                                    <Button
                                        className="w-fit lg:w-20 xl:w-32"
                                        type="primary"
                                        disabled={otpValue.length < 6}
                                        onClick={handleCheckOtp}
                                    >
                                        {t("Next")}
                                    </Button>
                                    </Form.Item>
                                    :
                                    <Form.Item>
                                    <Button
                                        className="w-fit lg:w-20 xl:w-32"
                                        type="primary"
                                        onClick={() => setTabs( prev => prev + 1)}
                                        disabled={!isSuccessAct}
                                    >
                                        {t("Next")}
                                    </Button>
                                    </Form.Item>
                                    )}
                                </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='mt-8'>
                            <Col span={24}>
                                <Card className='pb-16'>
                                    <Row>
                                        { tabs === 0 &&
                                            <Col span={24}>
                                                { isSuccessAct ? 
                                                <div className='flex flex-col items-center pt-10'>
                                                    <img src={CheckCirlceSuccess} alt="" className='h-16 w-16' />
                                                    <p style={{color : '#323331' , letterSpacing : '1px'}} className='mt-7 mb-2.5 font-medium tracking-tight text-2xl '>{t("Your email has been successfully confirmed")}</p>
                                                    <p style={{color : '#8b8b8b'}} className="font-medium text-xl">{t("Please continue the verification process with your handphone number")}</p>
                                                </div>
                                                :
                                                <div className='flex flex-col items-center pt-10'>
                                                    <img src={FailedIcon} alt="" className='h-16 w-16' />
                                                    <p style={{color : '#323331' , letterSpacing : '1px'}} className='mt-7 mb-2.5 font-medium tracking-tight text-2xl '>{t("Registration Process Failed")}</p>
                                                    <p style={{color : '#8b8b8b'}} className="font-medium text-xl">{t("Your email activation process failed, please retry sending email activation")}</p>
                                                    <Button
                                                        className="md:w-40 mt-7 mb-2.5"
                                                        type="primary"
                                                        htmlType="button"
                                                        size="small"
                                                        ghost
                                                        onClick={handleRetrySendEmail}
                                                    >
                                                        {t("Retry Send Email")}
                                                    </Button>
                                                </div> }
                                            </Col>
                                        }
                                        {
                                                tabs === 1 &&
                                                <Col span={24}>
                                                    <div className='flex flex-col items-center px-6 pt-6'>
                                                        <p className='mb-16 tracking-tight text-xl headings text-center text-gray-500 w-5/6'>{t("We will send you an OTP code via email to verify your email address. Please enter the OTP code.")}</p>
                                                        <OtpInput
                                                            value={otpValue}
                                                            onChange={(otp) => setOtpValue(otp)}
                                                            className="ext-gray-800"
                                                            numInputs={6}
                                                            isInputNum
                                                            containerStyle={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                gap : 20
                                                              }}
                                                            inputStyle={{
                                                            width: "72px",
                                                            height: "96px",
                                                            fontWeight: "bold",
                                                            border: "2px solid #DBDBDB",
                                                            borderRadius: 4,
                                                            color: "#8b8b8b",
                                                            fontSize : 48 ,
                                                            gap : 20
                                                            }}
                                                        />
                                                        <p className='mt-16 tracking-tight text-xl text-center text-gray-500'>{t("Not receiving OTP code?")}
                                                         <span onClick={() => handleSendOtp()} className='text-blue-600 cursor-pointer'>{t("Resend")}</span></p>
                                                    </div>
                                                </Col>
                                        }
                                        {
                                            (tabs === 2 && !showVideo) &&
                                            <div className='pt-12 px-6 flex flex-col items-center'>
                                                <img className='mb-7' src={FaceRecog} alt=""  />
                                                <p className='text-center text-2xl font-medium mb-3'>{t("Face Recording")}</p>
                                                <p className='text-center  font-medium tracking-tight text-xl text-gray-500'>{t("We record the face and verify the biometric data by analyzing and comparing reference data. Please do not wear accessories on your face when recording (eg glasses).")}</p>
                                                {  tabs === 2 && continueVid === false ? 
                                                    <>
                                                        <button onClick={() => handleVideo()} className='mt-12 bg-blue-400 h-10 w-52 rounded-md text-white tracking-tight'>{t("Continue")}</button>
                                                    </>

                                                    :

                                                    <>
                                                        <div className='flex flex-col w-full mt-8 relative items-center' >
                                                            <div className='bg-red-500 relative'>
                                                                <video className='w-full relative' muted id='video' autoPlay></video>
                                                                <div className={`w-full absolute flex justify-center top-5 z-20`}>
                                                                { instruction === 0 &&
                                                                    <div className='w-3/5 py-2 px-2 bg-green-50 text-center flex items-center justify-center rounded-md' >
                                                                        <span className='tracking-tight text-sm text-black font-normal'>
                                                                        {t("Please your face should inside the circle, Look at the camera. Please follow the instruction.")}  
                                                                            {t("klik button bellow to start recording")}
                                                                        </span>
                                                                    </div>
                                                                }
                                                                { instruction > 0 &&
                                                                    <div className='w-3/5 py-2 bg-green-50 text-center flex items-center justify-center rounded-md' >
                                                                        <span className='tracking-tight text-sm text-black font-normal'>
                                                                            {t(instructionText[instruction - 1])}
                                                                        </span>
                                                                    </div>
                                                                }
                                                                </div>
                                                                <div className='bg-transparent border-4 border-solid absolute bottom-5 left-2/4 translate-x-2/4  2xl:-translate-x-2/4 2xl:translate-y-1/4'
                                                                    style={{   
                                                                            height : 355 , 
                                                                            width : 255 ,
                                                                            borderRadius : '50%' ,
                                                                            borderColor : '#054BA6' ,
                                                                            transform: 'translate(-50%, 0px)'
                                                                        }}>

                                                                </div>
                                                            </div>
                                                            <div className={`flex absolute w-full justify-center ${!record ? 'bottom-6' : 'bottom-16' }`}>
                                                                <button className={`${!record && !isSaved ? '' : 'hidden'}`} id='btn'> 
                                                                    <img src={Record} alt="" />
                                                                </button>
                                                                
                                                                <>

                                                                <button className={`${isSaved ? '' : 'hidden'} mr-5`} id="btncheck">
                                                                    <img src={RetryIcon} alt="" />
                                                                </button>
                                                                
                                                                <button className={`hidden`} id="stopbtn">
                                                                    <img src={Pause} alt="" />
                                                                </button>

                                                                </>
                                                            </div>
                                                            <div className='flex justify-center mt-7'>
                                                                <button 
                                                                    onClick={handleSave} 
                                                                    disabled={!save} 
                                                                    id='save' 
                                                                    className={`${!openSave ? 'hidden' : ''} w-52 h-10 font-medium rounded-md ${ !save ? 'bg-gray-400': 'bg-blue-500'} ${ !save ? 'text-gray-500': 'text-white'} `}>
                                                                    {t("Save")}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>

                                                }
                                            </div>
                                        }

                                        {
                                            (tabs === 2 && showVideo) &&
                                            <Col span={24}>
                                                <div className='pt-12 px-6 flex flex-col justify-center items-center'>
                                                    <img className='mb-7' src={NoVideoIcon} alt=""  />
                                                    <p className='text-center text-2xl font-medium mb-3'>{t("Video recording is pending")}</p>
                                                    <p className='text-center  font-medium tracking-tight text-xl text-gray-500'>{t("The video recording process has failed three times.")}<br />
                                                        {t("Please contact the admin to check the registration process manually.")}</p>
                                                </div>
                                            </Col>
                                            
                                        }
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col> 
                    
                </Row>
            </Form>
            
            <Modal
                visible={openConfirm}
                type="warning"
                title={t('Are You Sure?')}
                subtitle={t('You will send the data for company registration account, please make sure before you submit')}
                onCancel={() => setOpenConfirm(false)}
            >
                <div className='flex flex-col'>
                    <div className='flex gap-5  mt-12'>
                        <Button className='font-medium text-base tracking-tight flex-1 h-10' ghost type='primary'>{t("Back")}</Button>
                        <Button onClick={() => {
                            setOpenConfirm(false)
                            sendVideo()
                            // setOpenSuccess(true)
                        }} className='font-medium text-base tracking-tight flex-1 h-10' type='primary'>{t("Submit")}</Button>
                    </div>
                </div>
                
            </Modal>
            <Modal
                visible={openSuccess}
                title={t("Verification Success")}
                onCancel={() => setOpenSuccess(false)}
            >
                <div className='flex flex-col items-center'>
                    <p className='mb-10 text-center text-base leading-6 font-sans tracking-tight'>{t("Verification success. Please wait for activating certificate by PERURI")}</p>
                    <Button type='primary' className='w-52 font-medium text-base tracking-tight h-10' onClick={() => history.push('/profile')}>{t("Done")}</Button>
                </div>

            </Modal>

            <Modal
                visible={resendSuccess}
                title={t("Send Activation Email Success")}
                onCancel={() => setResendSuccess(false)}
            >
                <div className='flex flex-col items-center'>
                    <p className='mb-10 text-center text-base leading-6 font-sans tracking-tight'>{t("Resend activation email success. Please check your email to continue registration process.")}</p>
                    <Button type='primary' className='w-52 font-medium text-base tracking-tight h-10' onClick={() => history.push('/profile')}>{t("Done")}</Button>
                </div>

            </Modal>
            <Spinner loading={loading} />
        </>
    )
}

export default RegistrationCheck
