import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Card, Col, DatePicker, Dropdown, Input, Menu, Radio, Row, Select, Table } from "antd";
import { UndoOutlined, EyeFilled, FilterOutlined, EllipsisOutlined, ImportOutlined } from "@ant-design/icons";
import { SiMicrosoftexcel } from "react-icons/si";
import { MdPictureAsPdf } from "react-icons/md";
import { Button } from "../../components";
import { 
  useFetchListMeteraiDefectQuery, 
  useFetchListMeteraiHistoryQuery,
  useManuallyGenerateStampMutation, 
  useFetchListMonitorReconOfficeQuery
} from "../../config/features";
import { STAMP_STATUS } from "../../constant";
import { errorMessage, exportExcel, exportPdf, getBlob, numberCommas } from "../../utils";
import { useTranslation } from "react-i18next";

const { Search } = Input;
const { Option } = Select;

export default function MonitoringProduct() {
  const history = useHistory();
  const { t } = useTranslation();
  

  const [isVisibleFilterMeteraiHistory, setIsVisibleFilterMeteraiHistory] = useState(false)
  const [paramsTableMeteraiHistory, setParamsTableMeteraiHistory] = useState({
    row: 10,
    page: 1,
    order: "desc",
    sort_by: "created_at",
    search: "",
    start_date: "",
    end_date: "",
    status: "",
  });


  const [isVisibleFilterMeteraiDefect, setIsVisibleFilterMeteraiDefect] = useState(false)
  const [paramsTableMeteraiDefect, setParamsTableMeteraiDefect] = useState({
    row: 5,
    page: 1,
    order: "desc",
    sort_by: "created_at",
    search: "",
    start_date: "",
    end_date: "",
  });

  const [paramsTableMonitorRecon, setParamTableMonitorRecon] = useState({
    row: 10,
    page: 1,
    search: ""
  });

  const {
    data: dataListMeteraiHistory = {},
    refetch: refetchListMeteraiHistory,
    isError: isErrorMeteraiHistory,
    error: errorMeteraiHistory,
    isFetching: isFetchingMeteraiHistory,
  } = useFetchListMeteraiHistoryQuery(paramsTableMeteraiHistory);

  const {
    data: dataListMeteraiDefect = {},
    refetch: refetchListMeteraiDefect,
    isError: isErrorMeteraiDefect,
    error: errorMeteraiDefect,
    isFetching: isFetchingMeteraiDefect,
  } = useFetchListMeteraiDefectQuery(paramsTableMeteraiDefect);

  const {
    data: dataListMonitorRecon = {},
    refetch: refetchListMonitorRecon,
    isError: isErrorListMonitorRecon,
    error: errorListMonitorRecon,
    isFetching: isfetchingListMonitorRecon
  } = useFetchListMonitorReconOfficeQuery(paramsTableMonitorRecon);

  const [manuallyGenerateStamp, { isSuccess, isLoading, error, data, isError }] = useManuallyGenerateStampMutation()

  const [currentPage, setIsCurrentPage] = useState('0');
  
  const itemsMenu = [
    {
      label: "Stock",
      key: "0"
    }, 
    {
      label: "Settlement",
      key: "1"
    }
  ]

  const monitorAction = (record) => {
    return (
      <Menu>
        {record.status == STAMP_STATUS.FAILED && (
          <Menu.Item>
            <div
              className="flex flex-row space-x-2 items-center"
              onClick={() => handleClickRetry(record?.id)}
            >
              <UndoOutlined/>
              <p>Retry</p>  
            </div>
          </Menu.Item>
        )}
        {record.bill_id && (
          <Menu.Item>
            <div
              className="flex flex-row space-x-2 items-center"
              onClick={() => history.push({
                pathname: `/monitoring-product/completion`,
                state: record
              })}
            >
              <ImportOutlined/>
              <p>Completion</p>
            </div>
          </Menu.Item>
        )}
      </Menu>
    )
  }

  const columnEmeteraiHistory = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Company Name",
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY HH:mm");
      },
    },
    {
      title: "Quantity",
      dataIndex: "total",
      key: "total",
      render: (total) => {
        return numberCommas(total)
      }
    },
    {
      title: "Generated",
      dataIndex: "generated",
      key: "generated",
      render: (generated) => {
        return numberCommas(generated)
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <span className={STAMP_STATUS[status]?.clsTextColor}>
            {STAMP_STATUS[status]?.value}
          </span>
        )
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, row) => {
        if (row.status == STAMP_STATUS.FAILED || row.status == STAMP_STATUS.PENDING) {
          if (!row.bill_id && row.status != STAMP_STATUS.FAILED) {
            return null;
          }
          return (
            <Dropdown overlay={monitorAction(row)} trigger={['click']}>
              <div className="group cursor-pointer">
                <EllipsisOutlined className="group-hover:text-blue-500" />
              </div>
            </Dropdown>
          )
        }
        return null
      },
    },
  ];

  const columnMeteraiDefect = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Company Name",
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity) => {
        return numberCommas(quantity)
      }
    },
    {
      title: "Refunded",
      dataIndex: "refunded",
      key: "refunded",
      render: (refunded) => {
        return numberCommas(refunded)
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, row) => {
        return (
          <Button 
            type="primary" 
            icon={<EyeFilled />} 
            onClick={() => {
              history.push({
                pathname: `/monitoring-product/defect/${row?.office_id}`,
                state: row
              })
            }}
            ghost
          > 
            Detail 
          </Button>
        );
      },
    },
  ];

  const columnMonitorRecon = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Company Name",
      dataIndex:"office_name",
      key: "office_name"      
    },
    {
      title: "Last Settlement",
      dataIndex:"last_settlement",
      key: "last_settlement",
      render: (last_settlement) => { return (last_settlement) ? last_settlement : "-"}      
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, row) => {
        return (
          <Button 
            type="primary" 
            icon={<EyeFilled />} 
            onClick={() => {
              history.push({
                pathname: `/history-settlement-office/${row?.office_id}`,
                state: row
              })
            }}
            ghost
          > 
            Detail 
          </Button>
        )
      }
    },
  ]

  const datasetsListTableMeteraiHistory = (data = []) => {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTableMeteraiHistory.row * (paramsTableMeteraiHistory.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  const datasetsListTableDefectMeterail = (data = []) => {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTableMeteraiDefect.row * (paramsTableMeteraiDefect.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  const handleChangeSearchMeteraiHistory = (e) => {
    setParamsTableMeteraiHistory(prevState => ({
      ...prevState,
      search: e.target.value
    }))
  }

  const handleClickSearchMeteraiHistory = () => {
    //console.log(paramsTableMeteraiHistory)
  }

  const handleResetFilterMeteraiHistory = () => {
    setParamsTableMeteraiHistory(prevState => ({
      ...prevState,
      sort_by: "created_at",
      order: "desc",
      search: "",
      start_date: "",
      end_date: "",
      status: "",
    }))
  }

  const menuFilterMeteraiHistory = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-blue-500"
              onClick={handleResetFilterMeteraiHistory}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Status</p>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(e) => {
                setParamsTableMeteraiHistory({ 
                  ...paramsTableMeteraiHistory, 
                  status: e 
                })
              }}
              value={paramsTableMeteraiHistory.status}
            >
              <Option value="">All Status</Option>
              <Option value={1}>In Progress</Option>
              <Option value={2}>Complete</Option>
              <Option value={3}>Incomplete</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Date</p>
            <DatePicker.RangePicker
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(value) => {
                setParamsTableMeteraiHistory({ 
                  ...paramsTableMeteraiHistory, 
                  start_date: moment(value[0]).utc().format().toString(),
                  end_date: moment(value[1]).utc().format().toString(), 
                })
              }}
              value={[
                paramsTableMeteraiHistory.start_date ? moment(paramsTableMeteraiHistory.start_date) : "", 
                paramsTableMeteraiHistory.end_date ? moment(paramsTableMeteraiHistory.end_date) : ""
              ]}
            />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort By</p>
            <Select
              defaultValue="office_name"
              style={{ width: "100%" }}
              onChange={(e) => {
                setParamsTableMeteraiHistory({ 
                  ...paramsTableMeteraiHistory, 
                  sort_by: e 
                })
              }}
              value={paramsTableMeteraiHistory.sort_by}
            >
              <Option value="created_at">Created Date</Option>
              <Option value="office_name">Office Name</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort Type</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) => {
                setParamsTableMeteraiHistory({ 
                  ...paramsTableMeteraiHistory, 
                  order: e.target.value 
                })
              }}
              value={paramsTableMeteraiHistory.order}
            >
              <Radio value="asc">Ascending</Radio>
              <Radio value="desc">Descending</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const handleChangeSearchMeteraiDefect = (e) => {
    setParamsTableMeteraiDefect(prevState => ({
      ...prevState,
      search: e.target.value
    }))
  }

  const handleClickSearchMeteraiDefect = () => {
    //console.log(paramsTableMeteraiDefect)
  }

  const handleResetFilterMeteraiDefect = () => {
    setParamsTableMeteraiDefect(prevState => ({
      ...prevState,
      sort_by: "created_at",
      order: "desc",
      search: "",
      start_date: "",
      end_date: "",
    }))
  }

  const menuFilterMeteraiDefect = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
          <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-blue-500"
              onClick={handleResetFilterMeteraiDefect}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort By</p>
            <Select
              defaultValue="office_name"
              style={{ width: "100%" }}
              onChange={(e) => {
                setParamsTableMeteraiDefect({ 
                  ...paramsTableMeteraiDefect, 
                  sort_by: e 
                })
              }}
              value={paramsTableMeteraiDefect.sort_by}
            >
              <Option value="created_at">Created Date</Option>
              <Option value="office_name">Office Name</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort Type</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) => {
                setParamsTableMeteraiDefect({ 
                  ...paramsTableMeteraiDefect, 
                  order: e.target.value 
                })
              }}
              value={paramsTableMeteraiDefect.order}
            >
              <Radio value="asc">Ascending</Radio>
              <Radio value="desc">Descending</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const handleChangePaginationMeteraiHistory = (pagination) => {
    setParamsTableMeteraiHistory(prevState => ({
      ...prevState,
      page: pagination.current,
      row: pagination.pageSize,
    }));
  }

  const handleChangeTab = (key) => {
    setIsCurrentPage(key);
    if(key === '0'){
      refetchListMonitorRecon();
    }
  }

  const handleChangePaginationMeteraiDefect = (pagination) => {
    setParamsTableMeteraiDefect(prevState => ({
      ...prevState,
      page: pagination.current,
      row: pagination.pageSize,
    }));
  }

  const handleChangePaginationOfficerecon = (pagination) => {
    setParamTableMonitorRecon(prevState => ({
      ...prevState,
      search: "",
      page: pagination.current,
      row: pagination.pageSize
    }));
  }

  const handleClickRetry = (monitor_id) => {
    manuallyGenerateStamp(monitor_id)
  }

  const handleClickExportExcel = async () => {
    const response = await getBlob(`/platform/defect?export_format=excel`);
    exportExcel(response.data, "Defective E-Meterai Monitoring");
  }

  const handleClickExportPdf = async () => {
    const response = await getBlob(`/platform/defect?export_format=pdf`);
    exportPdf(response.data, "Defective E-Meterai Monitoring");
  }

  const handleSearchRekonOffice = (param) => {
    setParamTableMonitorRecon(prevState => ({
      ...prevState,
      search: param.target.value
    }))
  }

  /* CURRENTLY COMMENTED UNTIL BACKEND API IS READY */

  useEffect(() => {
    if (isErrorMeteraiHistory) errorMessage(errorMeteraiHistory);
  }, [isErrorMeteraiHistory, errorMeteraiHistory]);

  useEffect(() => {
    if (isErrorMeteraiDefect) errorMessage(errorMeteraiDefect);
  }, [isErrorMeteraiDefect, errorMeteraiDefect]);

  useEffect(() => {
    if (isError) errorMessage(error);
  }, [isError, error])

  useEffect(() => {
    if(isErrorListMonitorRecon) errorMessage(errorListMonitorRecon) 
  },[isErrorListMonitorRecon, errorListMonitorRecon]);

  useEffect(() => {
    if(isSuccess){
      refetchListMeteraiHistory()
    }
  }, [isSuccess, refetchListMeteraiHistory])



  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <h1 className="text-xl md:text-2xl font-medium">
                Monitoring Product
              </h1>
            </Col>
          </Row>
        </Col>
        <Col span={[24]}>
        <Menu
              onClick={(e) =>{                
                handleChangeTab(e.key);
              }}
              selectedKeys={[currentPage]}
              mode="horizontal"
              style={{background: 'transparent'}}
              >
                {
                  itemsMenu.map(items => {
                    return (
                      <Menu.Item key={items.key}>
                        <span style={{color : `${currentPage === items.key ? '#1890ff' : '#8B8B8B'}`}} className={`text-base font-medium`}>{items.label}</span>
                      </Menu.Item>
                    )
                  })
                }
            </Menu>
        </Col>
        {
          currentPage === '0' &&
          <>
            <Col span={24}>
              <Card className="rounded-md">
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Row gutter={[24, 24]} justify="space-between">
                      <Col>
                        <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                          Generated E-Meterai History
                        </h3>
                      </Col>
                      <Col className="flex gap-2">
                        <Search
                          placeholder="Search"
                          className="rounded-full"
                          allowClear
                          onSearch={handleClickSearchMeteraiHistory}
                          onChange={handleChangeSearchMeteraiHistory}
                        />
                        <Dropdown
                          overlay={menuFilterMeteraiHistory}
                          onVisibleChange={() => {
                            setIsVisibleFilterMeteraiHistory(prevState => !prevState)
                          }}
                          visible={isVisibleFilterMeteraiHistory}
                          trigger={["click"]}
                        >
                          <Button
                            type="primary"
                            ghost
                            icon={<FilterOutlined />}
                          />
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={columnEmeteraiHistory}
                      dataSource={datasetsListTableMeteraiHistory(dataListMeteraiHistory.data)}
                      loading={isFetchingMeteraiHistory || isLoading}
                      scroll={{ x: 600 }}
                      pagination={{
                        current: paramsTableMeteraiHistory.page,
                        pageSize: paramsTableMeteraiHistory.row,
                        total: dataListMeteraiHistory?.total || 0,
                      }}
                      onChange={handleChangePaginationMeteraiHistory}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card className="rounded-md">
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Row gutter={[24, 24]} justify="space-between">
                      <Col>
                        <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                          Monitoring Defective E-Meterai
                        </h3>
                      </Col>
                      <Col className="flex gap-2">
                        <Search
                          placeholder="Search"
                          className="rounded-full"
                          allowClear
                          onSearch={handleClickSearchMeteraiDefect}
                          onChange={handleChangeSearchMeteraiDefect}
                        />
                        <Dropdown
                          overlay={menuFilterMeteraiDefect}
                          onVisibleChange={() => {
                            setIsVisibleFilterMeteraiDefect(prevState => !prevState)
                          }}
                          visible={isVisibleFilterMeteraiDefect}
                          trigger={["click"]}
                        >
                          <Button
                            type="primary"
                            ghost
                            icon={<FilterOutlined />}
                            />
                        </Dropdown>
                        <Button
                          type="text"
                          className="text-white hover:text-white"
                          style={{ background: "#8BC34A" }}
                          icon={<SiMicrosoftexcel />}
                          onClick={handleClickExportExcel}
                        >
                          Export Excel
                        </Button>
                        <Button
                          type="text"
                          className="text-white hover:text-white"
                          style={{ background: "#E9290F" }}
                          icon={<MdPictureAsPdf />}
                          onClick={handleClickExportPdf}
                        >
                          Export PDF
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={columnMeteraiDefect}
                      dataSource={datasetsListTableDefectMeterail(dataListMeteraiDefect.data)}
                      loading={isFetchingMeteraiDefect}
                      scroll={{ x: 600 }}
                      pagination={{
                        current: paramsTableMeteraiDefect.page,
                        pageSize: paramsTableMeteraiDefect.row,
                        total: dataListMeteraiDefect?.total || 0,
                      }}
                      onChange={handleChangePaginationMeteraiDefect}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </>    
        }    

        {
          currentPage === '1' &&
          <Col span={24}>
              <Card className="rounded-md">
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Row gutter={[24, 24]} justify="space-between">
                      <Col>
                        <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                          Client Settlement E-Meterai
                        </h3>
                      </Col>
                      <Col className="flex gap-2">
                        <Search
                          placeholder="Search"
                          className="rounded-full"
                          allowClear
                          // onSearch={handleSearchRekonOffice}
                          onChange={handleSearchRekonOffice}
                        />                        
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={columnMonitorRecon}
                      dataSource={dataListMonitorRecon?.data.map((item, index) => ({no: index+1, ...item}))}
                      loading={isfetchingListMonitorRecon || isLoading}
                      scroll={{ x: 600 }}
                      pagination={{
                        current: paramsTableMonitorRecon.page,
                        pageSize: paramsTableMonitorRecon.row,
                        total: dataListMonitorRecon?.data[0].total || 0,
                      }}
                      onChange={handleChangePaginationOfficerecon}
                    />
                  </Col>
                </Row>
              </Card>
          </Col>
        }        
      </Row>
    </>
  );
}
