import { Card, Col, Row, Switch, Table, Form } from "antd";
import React from "react";
import Fade from "react-reveal/Fade";

export default function ClientModule({ form }) {
  const column = [
    {
      title: "Module Name",
      dataIndex: "module",
      key: "module",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Form.Item className="my-0" name={status} initialValue={false}>
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked={
                form.getFieldValue(status) ? form.getFieldValue(status) : false
              }
              valuePropName="checked"
            />
          </Form.Item>
        );
      },
    },
  ];

  const data = [
    {
      module: "E-Meterai",
      status: "stamp_m",
    },
    {
      module: "Digital Signature",
      status: "sign_m",
    },
    {
      module: "Backup Secure Document",
      status: "backup_m",
    },
    {
      module: "User Management",
      status: "user_m",
    },
    {
      module: "Group Management",
      status: "group_m",
    },
    {
      module: "Office Management",
      status: "office_m",
    },
    {
      module: "Document Management",
      status: "document_m",
    },
    {
      module: "Blockchain Document",
      status: "blockchain_m",
    },
  ];

  return (
    <Fade>
      <Card className="rounded-md">
        <Row gutter={[24, 12]}>
          <Col span={24}>
            <Table columns={column} dataSource={data} />
          </Col>
        </Row>
      </Card>
    </Fade>
  );
}
