import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Form, Input, message, Row, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import { useTranslation } from "react-i18next";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { useAddOfficeMutation, useFetchListOfficePicQuery, useFetchListOfficeTypeQuery } from "../../config/features/office";
import { errorMessage } from "../../utils";
const { Option } = Select;

export default function AddOffice() {
  const history = useHistory();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const { data: userProfile } = useFetchProfileUserQuery();
  const [addOffice, { isSuccess, isLoading, error, isError }] = useAddOfficeMutation();
  const { data: listOfficeType = [] } = useFetchListOfficeTypeQuery();
  const { data: listOfficePic = [] } = useFetchListOfficePicQuery();

  const [form] = Form.useForm();
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [payload, setPayload] = useState({});

  useEffect(() => {
    form.setFieldsValue({status: true})
  }, [form])

  useEffect(() => {
    if (isSuccess) {
      setIsModalSuccess(true);
      form.resetFields();
    } else if (isError) {
      errorMessage(error);
    }
  }, [isSuccess, error, isError, form]);

  const onFinish = (values) => {
    showModal(values);
  };

  async function handleSubmit(values) {
    values.office_type_id = 2;
    try {
      addOffice(values);
    } catch (error) {
      message.error(error.message);
    }
  }

  const showModal = (values) => {
    setPayload(values);
    setIsModalConfirm(true);
  };

  const handleOk = () => {
    setIsModalConfirm(false);
    handleSubmit(payload);
  };

  const handleCancel = () => {
    setIsModalConfirm(false);
  };

  const handleDone = () => {
    setIsModalSuccess(false);
    history.push(`/office`);
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.push("/office")}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      {t("Add Work Unit")}
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    className="md:w-40"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                   {t("Add Work Unit")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    {t("Input Work Unit Name")}
                  </h3>
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 12]}>
                    <Col xs={24}>
                      <Form.Item
                        label={t("Work Unit Name")}
                        name="office_name"
                        rules={[
                          {
                            required: true,
                            message: t("Please input work unit!"),
                          },
                        ]}
                      >
                        <Input placeholder={t("Enter Work Unit")} />
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item
                        label={t("Work Unit Type")}
                        name="office_type_id"
                        rules={[
                          {
                            required: true,
                            message: t("Please select work unit type!"),
                          },
                        ]}
                      >
                        <Select
                          placeholder={t("Please Choose Work Unit Type")}
                          defaultValue={null}
                        >
                          {listOfficeType.map((item, index) => {
                            return item.office_type_name !== "Headquarter" && (
                              <Option key={index} value={item.office_type_id}>
                                {item.office_type_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item
                        label="PIC"
                        name="pic_id"
                        rules={[
                          {
                            required: true,
                            message: t("Please select PIC!"),
                          },
                        ]}
                      >
                        <Select
                          placeholder={t("Please Choose PIC")}
                          defaultValue={null}
                        >
                          {listOfficePic.map((item, index) => {
                            return (
                              <Option key={index} value={item.user_id}>
                                {item.full_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item
                        label="Status"
                        name="is_active"
                        rules={[
                          {
                            required: false,
                            message: t("Please select status!"),
                          },
                        ]}
                        defaultValue={true}
                        valuePropName="checked"
                      >
                        <Switch
                          checkedChildren={t("Active")}
                          unCheckedChildren={t("Inactive")}
                          defaultChecked={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      <Modal
        title={t("Are you sure?")}
        subtitle={t("Be careful in changing work unit data! If you make any changes to work unit type, it may change / lost your access to the system.")}
        type="warning"
        visible={isModalConfirm}
        onCancel={handleCancel}
      >
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Work Unit Name")}</p>
            <div className="w-2" />
            <p className="text-gray-800 truncate">{payload?.office_name}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Work Unit Type")}</p>
            <div className="w-2" />
            <p className="text-gray-800 truncate">{t("Branch")}</p>
          </div>
          <Divider />
        </div>
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={handleCancel}
          >
            {t("Back")}
          </Button>
          <Button className="w-full" type="primary" onClick={handleOk}>
            {t("Add")}
          </Button>
        </div>
      </Modal>
      <Modal
        title={t("Added Success!")}
        subtitle={t("New work unit has been added! Now you can continue browsing")}
        type="success"
        visible={isModalSuccess}
        onCancel={() => setIsModalSuccess(false)}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={handleDone}>
            {t("Done")}
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
