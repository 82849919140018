import { LeftOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  DatePicker,
  Divider,
  Modal,
  Row,
  Select,
  Table,
  Input,
  message,
} from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import {
  Button,
  Invoice,
  Modal as ModalCustom,
  Receipt,
  Spinner,
} from "../../components";
import {
  useConfirmPaymentMutation,
  useFetchBillingDetailQuery,
} from "../../config/features/platform";
import { errorMessage, numberCommas } from "../../utils";
const { Option } = Select;
const { TextArea } = Input;

export default function BillingDetail() {
  const history = useHistory();
  const { id } = useParams();

  const receiptRef = useRef();
  const invoiceRef = useRef();

  const [isModalInvoice, setIsModalInvoice] = useState(false);
  const [isModalReceipt, setIsModalReceipt] = useState(false);
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [completedPayment, setCompletedPayment] = useState(null);
  const [paymentDate, setPaymentDate] = useState("");
  const [note, setNote] = useState("");

  const {
    data: dataBillingDetail,
    refetch,
    error: errorDetail,
    isError: isErrorDetail,
  } = useFetchBillingDetailQuery(id);

  const [confirmPayment, { isSuccess, isLoading, isError, error }] =
    useConfirmPaymentMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      setIsModalSuccess(true);
    }
  }, [isSuccess, refetch]);

  useEffect(() => {
    if (isError) {
      errorMessage(error || "");
    }
  }, [isError, error])

  useEffect(() => {
    if (isErrorDetail) {
      errorMessage(errorDetail);
    }
  }, [isErrorDetail, errorDetail])

  function toggleInvoice() {
    setIsModalInvoice(!isModalInvoice);
  }

  function toggleReceipt() {
    setIsModalReceipt(!isModalReceipt);
  }

  function toggleModalConfirm() {
    setIsModalConfirm(!isModalConfirm);
  }

  function toggleModalSuccess() {
    setIsModalSuccess(!isModalSuccess);
  }

  function handleChangePayment(e) {
    setCompletedPayment(e);
  }

  function handleChangePaymentDate(e) {
    setPaymentDate(moment(e).format());
  }

  async function onConfirm() {
    try {
      toggleModalConfirm();
      confirmPayment({
        id_bill: id,
        payment_date: paymentDate,
        cancel: completedPayment ? "" : 1,
        note,
      });
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleConfirmPayment() {
    if (completedPayment === null) {
      message.error("Please select payment status!");
      return;
    }
    if (completedPayment) {
      if (!paymentDate) {
        message.error("Please select payment date!");
        return;
      }
    }
    onConfirm();
  }

  const columnsProduct = [
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      render: (product_name) => {
        return product_name === "Total" ? (
          <p classproduct_name="text-gray-500 font-medium text-sm">
            {product_name}
          </p>
        ) : (
          product_name
        );
      },
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (unit_price, record) => {
        return (
          record.product_name !== "Total" && `Rp ${numberCommas(unit_price)},-`
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity, record) => {
        return record.product_name !== "Total" && `${numberCommas(quantity)}`;
      },
    },
    {
      title: "Price",
      dataIndex: "total_price",
      key: "total_price",
      render: (total_price, record) => {
        return record.product_name === "Total" ? (
          <p className="text-blue-700 font-medium text-sm">{`Rp ${numberCommas(
            total_price
          )},-`}</p>
        ) : (
          `Rp ${numberCommas(total_price)},-`
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    Billing Detail
                  </h1>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={[24, 24]}>
                <Col>
                  <Button
                    className="md:w-40"
                    type="secondary"
                    onClick={toggleInvoice}
                  >
                    Download Invoice
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="md:w-40"
                    type="primary"
                    onClick={toggleReceipt}
                    disabled={dataBillingDetail?.status !== 2}
                  >
                    Download Receipt
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 6]}>
              <Col>
                <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  Billing Information
                </h3>
              </Col>
              <Col span={24}>
                <Divider />
              </Col>
              <Col span={24}>
                <Row gutter={[24, 24]}>
                  <Col xs={12} lg={8}>
                    <Row gutter={[24, 12]}>
                      <Col span={24}>
                        <p className="text-gray-400 text-xs md:text-sm">
                          Client Name
                        </p>
                      </Col>
                      <Col span={24}>
                        <h4 className="text-gray-800 text-sm md:text-base font-medium">
                          {dataBillingDetail?.office_name}
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Row gutter={[24, 12]}>
                      <Col span={24}>
                        <p className="text-gray-400 text-xs md:text-sm">
                          Purchase Date
                        </p>
                      </Col>
                      <Col span={24}>
                        <h4 className="text-gray-800 text-sm md:text-base font-medium">
                          {dataBillingDetail?.purchase_date
                            ? moment(
                                dataBillingDetail?.purchase_date,
                                moment.ISO_8601
                              ).format("DD MMMM YYYY")
                            : "-"}
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Row gutter={[24, 12]}>
                      <Col span={24}>
                        <p className="text-gray-400 text-xs md:text-sm">
                          Status
                        </p>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[24, 12]} align="middle">
                          <Col>
                            {dataBillingDetail?.status === 1 ? (
                              <h4 className="text-yellow-500 text-sm md:text-base font-medium">
                                Pending
                              </h4>
                            ) : dataBillingDetail?.status === 2 ? (
                              <h4 className="text-blue-700 text-sm md:text-base font-medium">
                                Completed
                              </h4>
                            ) : (
                              <h4 className="text-gray-500 text-sm md:text-base font-medium">
                                Canceled
                              </h4>
                            )}
                          </Col>
                          {dataBillingDetail?.status === 1 && (
                            <Col>
                              <Button
                                type="secondary"
                                ghost
                                onClick={toggleModalConfirm}
                              >
                                Update
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Row gutter={[24, 12]}>
                      <Col span={24}>
                        <p className="text-gray-400 text-xs md:text-sm">Note</p>
                      </Col>
                      <Col span={24}>
                        <h4 className="text-gray-800 text-sm md:text-base font-medium">
                          {dataBillingDetail?.note}
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Row gutter={[24, 12]}>
                      <Col span={24}>
                        <p className="text-gray-400 text-xs md:text-sm">Type</p>
                      </Col>
                      <Col span={24}>
                        <h4 className="text-gray-800 text-sm md:text-base font-medium capitalize">
                          {dataBillingDetail?.type}
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  Product List
                </h3>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsProduct}
                  dataSource={
                    dataBillingDetail?.product_list?.length > 0
                      ? [
                          ...dataBillingDetail?.product_list,
                          {
                            product_name: "Total",
                            total_price: dataBillingDetail?.total,
                          },
                        ]
                      : []
                  }
                  scroll={{ x: 500 }}
                  pagination={false}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        visible={isModalInvoice}
        onCancel={toggleInvoice}
        footer={false}
        className="top-4"
        width={600}
      >
        <div className="flex flex-col space-y-4">
          <div className="hidden">
            <div ref={invoiceRef} className="p-12">
              <Invoice dataSource={dataBillingDetail} />
            </div>
          </div>
          <Invoice dataSource={dataBillingDetail} />
          <div className="text-center">
            <ReactToPrint
              trigger={() => (
                <Button className="w-10/12" type="primary">
                  Download
                </Button>
              )}
              content={() => invoiceRef.current}
            />
          </div>
        </div>
      </Modal>
      <Modal
        visible={isModalReceipt}
        onCancel={toggleReceipt}
        footer={false}
        className="top-4"
        width={600}
      >
        <div className="flex flex-col space-y-4">
          <div className="hidden">
            <div ref={receiptRef} className="p-12">
              <Receipt dataSource={dataBillingDetail} />
            </div>
          </div>
          <Receipt dataSource={dataBillingDetail} />
          <div className="text-center">
            <ReactToPrint
              trigger={() => (
                <Button className="w-10/12" type="primary">
                  Download
                </Button>
              )}
              content={() => receiptRef.current}
            />
          </div>
        </div>
      </Modal>
      <ModalCustom
        title="Are you sure?"
        subtitle={
          completedPayment === null
            ? "You can change payment status below"
            : completedPayment
            ? "The credit will be transfered to your client once you submit"
            : "The order will be cancelled and cannot be changed"
        }
        alertError={!completedPayment && completedPayment !== null}
        alertSuccess={completedPayment}
        type="warning"
        visible={isModalConfirm}
        onCancel={toggleModalConfirm}
      >
        <div className="flex flex-col space-y-4 items-center">
          <Select
            className="w-10/12"
            placeholder="Not Selected"
            onChange={handleChangePayment}
          >
            <Option value={true}>Completed</Option>
            <Option value={false}>Cancel</Option>
          </Select>
          {completedPayment && (
            <DatePicker
              className="w-10/12"
              format="DD/MM/YYYY"
              onChange={handleChangePaymentDate}
            />
          )}
          <div className="w-10/12">
            <p className="flex self-start">Note (Optional)</p>
          </div>
          <TextArea
            name="note"
            className="w-10/12"
            rows={4}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
        <div className="flex flex-row space-x-4 pt-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={toggleModalConfirm}
          >
            Back
          </Button>
          <Button
            className="w-full"
            type="primary"
            onClick={handleConfirmPayment}
          >
            Update
          </Button>
        </div>
      </ModalCustom>
      <ModalCustom
        title="Updated Success!"
        subtitle="Changes have been saved! Now you can continue browsing."
        type="success"
        visible={isModalSuccess}
        onCancel={() => setIsModalSuccess(false)}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={toggleModalSuccess}>
            Done
          </Button>
        </div>
      </ModalCustom>
      <Spinner loading={isLoading} />
    </>
  );
}
