import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { rootApi, access } from "../config";
import { objectToQueryString } from "../../../utils/queryString";

export const adminApi = createApi({
  reducerPath: "api/admin",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi + "/admin",
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      fetchOverview: builder.query({
        query() {
          return "/overview";
        },
        transformResponse: (response) => {
          if (!response || !response.data) {
            return []
          }
          response = response.data.map((item) => {
            if (item.name == 'e-Meterai per-Seal') {
              item.name = 'Remaining e-Meterai Quota';
            }
            return item;
          })
          return response;
        },
      }),

      fetchBillHistory: builder.query({
        query({ row = 10, page = 1 }) {
          return `/bill?row=${row}&page=${page}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchDepartmentActivity: builder.query({
        query({ row = 10, page = 1 }) {
          return `/dept?row=${row}&page=${page}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchDetailDepartmentActivity: builder.query({
        query({
          page = 1,
          sort_by = "created_at",
          order = "desc",
          row = "10",
          search = "",
          id_dept = "",
        }) {
          return `/dept/${id_dept}?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      registerAdmin: builder.mutation({
        query: (body) => ({
          method: "PUT",
          url: "/register",
          body,
        }),
      }),

      fetchInvoice: builder.mutation({
        query: (bill_id) => ({
          method: "GET",
          url: `/invoice/${bill_id}`,
        }),
        transformResponse: (response) => (response ? response.data : {}),
      }),

      fetchReceipt: builder.mutation({
        query: (bill_id) => ({
          method: "GET",
          url: `/receipt/${bill_id}`,
        }),
        transformResponse: (response) => (response ? response.data : {}),
      }),

      payInvoice: builder.mutation({
        query: (bill_id) => ({
          method: "PUT",
          url: `/invoice/${bill_id}`,
        }),
        transformResponse: (response) => (response ? response.data : ""),
      }),

      fetchLogModule: builder.query({
        query({ page = 1, row = 5 }) {
          return `/m-log?page=${page}&row=${row}`;
        },
      }),
      fetchGenerateStampMonitor: builder.query({
        query({
          row = 10,
          page = 1,
          status = '',
          start_date = '',
          end_date = ''
        }) {
          const queryString = objectToQueryString({row, page, status, start_date, end_date})
          return `/generate-stamp-monitor?${queryString}`
        }
      })
    };
  },
});

export const {
  useFetchOverviewQuery,
  useFetchBillHistoryQuery,
  useFetchDepartmentActivityQuery,
  useRegisterAdminMutation,
  useFetchInvoiceMutation,
  useFetchReceiptMutation,
  usePayInvoiceMutation,
  useFetchDetailDepartmentActivityQuery,
  useFetchLogModuleQuery,
  useFetchGenerateStampMonitorQuery
} = adminApi;
