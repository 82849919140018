import * as FileSaver from "file-saver";

export const exportExcel = (apiData, fileName) => {
  const fileType = "application/excel";
  const fileExtension = ".xls";
  const name = fileName ? fileName : "Document";
  const data = new Blob([apiData], { type: fileType });
  FileSaver.saveAs(data, name + fileExtension);
};

