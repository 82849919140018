import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./locales/en/translation.json";
import idTranslation from "./locales/id/translation.json";

// konfigurasi i18next
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    id: {
      translation: idTranslation,
    },
  },
  lng: "en", // default bahasa
  fallbackLng: "en", // bahasa fallback jika tidak tersedia
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
