import { CheckOutlined, LeftOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import Button from "../../components/Button";
import { numberCommas } from "../../utils";
import { useDetilPayMethodeQuery } from "../../config/features/general";
import moment from 'moment';

export default function PaymentPendingPage({
  next,
  back,    
  data,    
}) {

  



  useEffect(() => {    
    window.scrollTo(0, 0);      
    
  }, []);
  
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };


  const calculateTimeLeft = () => {
    const difference = +new Date(data?.expired_time) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  return (
    <Fade>
      <Row gutter={[24, 12]} justify="center">        
        <Col xs={24} md={18}>
          <Card className="rounded-md" style={{backgroundColor:"rgba(15, 40, 91, 0.95)"}}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <div className="cursor-pointer" onClick={back} style={{color: "white"}}>
                  <LeftOutlined className="hover:text-white-400" /> 
                </div>
              </Col>              
              <Col>
                <LeftOutlined className="hidden" />
              </Col>
            </Row>
            <Row gutter={[24, 24]} justify="center">              
              <Col gutter={[24, 24]} className="w-full" style={{marginTop: 5, padding: 5,}}>
                    <h4 className="text-base md:text-md font-small text-white text-center">
                        Time Left
                    </h4>               
              </Col>                            
              <Col span={24} className="w-full">
                <h2 className="text-4xl md:text-5xl text-white text-center  font-medium">
                    {(timeLeft.hours > 0 && timeLeft.minutes > 0 && timeLeft.seconds > 0) ? timeLeft.hours + " : " +timeLeft.minutes + " : " + timeLeft.seconds : "Payment Expired"}                    
                </h2>
              </Col>                                                                              
            </Row>
            <Row gutter={[24, 24]} style={{marginTop: 10, marginBottom: 10}} justify="center">            
             <Col span={24} style={{marginTop: 10, padding: 10,}}>
                <Card className="rounded-md w-full">
                    <Row>
                        <Col span={5}>Invoice Number</Col>
                        <Col> : </Col>
                        <Col> &nbsp; {data?.nomor_surat}</Col>
                    </Row>
                    <Row>
                        <Col span={5}>Total Amount</Col>
                        <Col> : </Col>
                        <Col> &nbsp; Rp {numberCommas(data?.total)},-</Col>
                    </Row>
                    <Row>
                        <Col span={5}>Transaction Id</Col>
                        <Col> : </Col>
                        <Col> &nbsp; {data?.transaction_id}</Col>
                    </Row>
                    <Row>
                        <Col span={5}>Payment Method</Col>
                        <Col> : </Col>
                        <Col> &nbsp; {data?.pga_name}</Col>
                    </Row>
                    <Row>
                        <Col span={5}>Payment URL</Col>
                        <Col> : </Col>
                        <Col> &nbsp; {data?.payment_url}</Col>
                    </Row>
                    <Row>
                        <Col span={5}>Due Date</Col>
                        <Col> : </Col>
                        <Col> &nbsp; {moment(data?.expired_time, moment.ISO_8601).format("DD/MM/YYYY, HH:mm")}</Col>
                    </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={[24, 24]} justify="center">
             <Col span={24}>
                <Button className="w-full " onClick={ () => openInNewTab(data?.payment_url)} type="secondary">                                                      
                    Go To Payment Page                    
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fade>
  );
}
