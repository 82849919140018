import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Row, Table } from "antd";
import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useFetchDetailDepartmentActivityQuery } from "../../config/features";
import { numberCommas } from "../../utils";
import { useTranslation } from "react-i18next";

export default function DepartmentDetail() {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [paramsTable] = useState({
    row: 10,
    page: 1,
    sort_by: "created_at",
    order: "desc",
    search: "",
    id_dept: params?.id,
  });

  const { data: dataDetail = [] } =
    useFetchDetailDepartmentActivityQuery(paramsTable);

  const columnDepartment = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      render: (no) => {
        return no === "Total" ? (
          <p className="text-gray-500 font-medium">{no}</p>
        ) : (
          no
        );
      },
    },
    {
      title: "User",
      dataIndex: "full_name",
      key: "full_name",
      render: (full_name, record) => {
        return record.no === "Total" ? null : full_name;
      },
    },
    {
      title: "Signature",
      dataIndex: "signature",
      key: "signature",
      render: (_, record) => {
        return (
          <>
            {record?.product_list &&
              record?.product_list?.map((item) => {
                return item.id === 2 && numberCommas(item.used);
              })}
          </>
        );
      },
    },
    {
      title: "Stamp",
      dataIndex: "stamp",
      key: "stamp",
      render: (_, record) => {
        return (
          <>
            {record?.product_list &&
              record?.product_list?.map((item) => {
                return item.id === 1 && numberCommas(item.used);
              })}
          </>
        );
      },
    },
    {
      title: "DMS per-Document",
      dataIndex: "page",
      key: "page",
      render: (_, record) => {
        return (
          <>
            {record?.product_list &&
              record?.product_list?.map((item) => {
                return item.id === 3 && numberCommas(item.used);
              })}
          </>
        );
      },
    },
    {
      title: "Backup Secure Document",
      dataIndex: "gb",
      key: "gb",
      render: (_, record) => {
        return (
          <>
            {record?.product_list &&
              record?.product_list?.map((item) => {
                return item.id === 4 && `${item.used / 1000} GB`;
              })}
          </>
        );
      },
    },
  ];

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
      <div>
            <button class="px-4 py-2 text-right rounded-l-md dark:bg-violet-400 peer-checked:dark:bg-gray-300" 
            onClick={() => changeLanguage("en")} >English</button>
           <button class="px-4 py-2 text-right rounded-r-md dark:bg-gray-300 peer-checked:dark:bg-violet-400"
            onClick={() => changeLanguage("id")}>Indonesia</button>
            </div>
        <Row gutter={[24, 24]} justify="space-between">
          <Col>
            <Row gutter={[24, 24]} align="middle">
              <Col>
                <div
                  className="group cursor-pointer"
                  onClick={() => history.goBack()}
                >
                  <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                </div>
              </Col>
              <Col>
                <h1 className="text-xl md:text-2xl font-medium">
                  {t("depactivity")}
                </h1>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Card className="rounded-md">
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Row gutter={[24, 24]} justify="space-between">
                <Col>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    {t("generaluserlist")}
                  </h3>
                </Col>
                {/* <Col>
                  <Row gutter={[24, 24]}>
                    <Col>
                      <Search
                        placeholder="Search"
                        allowClear
                        // onSearch={onSearch}
                        // onChange={(e) =>
                        //   setTimeout(() => {
                        //     onSearch(e.target.value);
                        //   }, 1000)
                        // }
                        style={{ width: 200, borderRadius: "10px !important" }}
                      />
                    </Col>
                    <Col>
                      <Dropdown
                        overlay={menuFilter}
                        // onVisibleChange={handleVisibleFilter}
                        // visible={visibleFilter}
                        trigger={["click"]}
                      >
                        <Button
                          className="rounded-full"
                          type="primary"
                          icon={<FilterOutline />}
                        />
                      </Dropdown>
                    </Col>
                  </Row>
                </Col> */}
              </Row>
            </Col>
            <Col span={24}>
              <Table
                columns={columnDepartment}
                dataSource={datasetsListTable(dataDetail)}
                scroll={{ x: 600 }}
                // loading={isFetching}
                // pagination={{
                //   current: paramsTable.page,
                //   pageSize: paramsTable.row,
                //   total: dataListDocument[0] ? dataListDocument[0]?.total : 0,
                // }}
                // onChange={handleChangeTable}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
