import { FilterOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  Radio,
  Row,
  Select,
  Table,
  Tag,
  message,
} from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as FilterOutline } from "../../assets/icons/filter-outline.svg";
import { useFetchListDocumentQuery } from "../../config/features/document";
import { useFetchListOfficeQuery } from "../../config/features/office";
import { errorMessage, exportPdf, getBlob } from "../../utils";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { rootApi } from "../../config/features/config";
const { Search } = Input;
const { Option } = Select;

export default function DocumentManagement() {
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const tokenAccess = useMemo( () => localStorage.getItem('access') , [])
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [paramsTable, setParamsTable] = useState({
    row: 10,
    page: 1,
    office: "",
    sort_by: "created_at",
    order: "desc",
    search: ''
  });
  const [visibleFilter, setVisibleFilter] = useState(false);

  
  const {
    data: dataListDocument = [],
    isFetching,
    isError,
    error,
    refetch,
  } = useFetchListDocumentQuery(paramsTable);
  const { data: dataListOffice = [] } = useFetchListOfficeQuery({ row: 100 });

  const [isLoadingDownload, setIsLoadingDownload] = useState(false)

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
  }, [isError, error]);

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Document Name"),
      dataIndex: "doc_name",
      key: "doc_name",
    },
    {
      title: t("Type"),
      dataIndex: "is_folder",
      key: "is_folder",
      render: (is_folder) => {
        return is_folder ? t("Bulk") : t("Single");
      },
    },
    {
      title: t('Stamp Status'),
      render: (record) => {
        if (!record.is_stamped && !record.is_signed && !record.is_tera) {
          return <Tag color="#A0A19F" className="rounded w-20 text-center">{t("Draft")}</Tag>
        }
        return (
          <span className="flex flex-row gap-2">
            {record.is_stamped && (
              <Tag color="#1890FF" className="rounded w-20 text-center">{t("E-Meterai")}</Tag>
            )}
            {record.is_signed && (
              <Tag color="#1890FF" className="rounded w-20 text-center">{t("E-Sign")}</Tag>
            )}
            {record.is_tera && (
              <Tag color="#1890FF" className="rounded w-20 text-center">{t("Tera")}</Tag>
            )}
          </span>
        )
      }
    },
    {
      title: t("Tag Document"),
      dataIndex: "tags",
      key: "tags",
      render: (tags) => {
        return (
          <p className="w-60 truncate">
            {tags?.map((item, index) => {
              return (
                item !== null && (
                  <span key={index}>
                    {item}
                    {tags?.length > 1 && tags?.length - 1 !== index ? ", " : ""}
                  </span>
                )
              );
            })}
          </p>
        );
      },
    },
    {
      title: t("Work Unit"),
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: t("Uploaded By"),
      dataIndex: "creator",
      key: "creator",
    },
    {
      render: (record) => {
        return (
          <div
            className="group cursor-pointer"
            onClick={() =>
              history.push(
                record.is_folder
                  ? `/document-management/detail-bulk/${record.doc_id}`
                  : `/document-management/detail/${record.doc_id}`
              )
            }
          >
            <RightOutlined className="group-hover:text-blue-500" />
          </div>
        );
      },
    },
  ];

  

  const downloadDocument = useCallback( async (id) => {
    setIsLoadingDownload(true)
    try {
      // Get Document Detail By ID
      const { data: documentRes } = await axios({
        url: `${rootApi}/document/${id}`,
        method: 'GET',
        headers: {
          "token": tokenAccess
        }
      })
;
      const { data } = documentRes;
      const versions = data.versions;
      versions.sort((a, b) => b.version - a.version)
      const last_version = versions[0] || data


      // Download Document By Last Version
      const response = await getBlob(`/document/download?id=${last_version.doc_id}`);
      console.log("HIT DOWNLOAD....");
      const documentName = last_version?.file ? last_version?.file?.split("/")?.pop() : ""
      exportPdf(response.data, documentName)
      console.log("EXPORTING PDF....");
      setIsLoadingDownload(false)
      console.log("FILE DOWNLOADED....");
    } catch (error) {
      setIsLoadingDownload(false)
      message.error(error.message)
    }
  }, [])

  const columnsDocument = [
    {
      title: "No",
      dataIndex: "no",
      key: "no"
    },
    {
      title: t("Document Name"),
      dataIndex: "original_name",
      key: "original_name"
    },
    {
      render: (_, record) => (
        <Button
          className="bg-blue-700 focus:bg-blue-700 active:bg-blue-700 justify-center"
          type="primary" 
          onClick={(e) => downloadDocument(record.doc_id)}
        >
          {t("Download")}
        </Button>
      )
    }
  ]

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        key: i + 1 + paramsTable.row * (paramsTable.page - 1),
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  const onSearch = (value) => setParamsTable({ ...paramsTable, search: value, page: 1 });

  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }

  function handleResetFilter() {
    setParamsTable({
      ...paramsTable,
      office: "",
      sort_by: "created_at",
      order: "desc",
    });
  }

  const menu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => history.push("/document-management/add")}
      >
        <p className="text-blue-600">{t("singledoc")}</p>
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => history.push("/document-management/add-bulk")}
      >
        <p className="text-blue-600">{t("bulkdoc")}</p>
      </Menu.Item>
    </Menu>
  );


  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row gutter={[24, 24]} justify="space-between">
          <Col>
            <h1 className="text-xl md:text-2xl font-medium">
              {t("doclist")}
            </h1>
          </Col>
          <Col>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                className="md:w-45"
                type="primary"
                icon={<PlusOutlined />}
              >
                {t("Upload Document")}
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Card className="rounded-md">
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Row gutter={[24, 24]} justify="space-between">
                <Col>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  {t("doclist")}
                  </h3>
                </Col>
                <Col>
                  <Row gutter={[12, 12]}>
                    <Col>
                      <Search
                        placeholder={t("Search")}
                        allowClear
                        onSearch={onSearch}
                        onChange={(e) =>
                          setTimeout(() => {
                            onSearch(e.target.value);
                          }, 1000)
                        }
                        style={{ width: 200, borderRadius: "10px !important" }}
                      />
                    </Col>
                    <Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={datasetsListTable(dataListDocument)}
                scroll={{ x: 850 }}
                loading={isFetching || isLoadingDownload}
                pagination={{
                  current: paramsTable.page,
                  pageSize: paramsTable.row,
                  total: dataListDocument[0] ? dataListDocument[0]?.total : 0,
                  locale: {
                    items_per_page: t("/ page"),
                  }
                }}
                onChange={handleChangeTable}
                expandable={{
                  expandedRowRender: (record) => (
                    <Table
                      columns={columnsDocument}
                      dataSource={datasetsListTable(record.documents || [record])}
                    />
                  ),
                  // rowExpandable: (record) => record.is_folder == true && record.documents && record.documents.length > 0
                }}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
