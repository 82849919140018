import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { rootApi, access } from "../config";

export const contractApi = createApi({
  reducerPath: "api/contract",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi,
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      fetchAllContract: builder.query({
        query(office_id) {
          return `/admin/contract?office_id=${office_id}`;
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      fetchDetailContract: builder.query({
        query(contract_id) {
          return `/admin/contract/${contract_id}`;
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      updateDocumentContract: builder.mutation({
        query: ({contract_doc_id, body}) => {
          return ({
            method: "PUT",
            url: `/platform/contract-doc/${contract_doc_id}${body?.status === "reject" ? "?reject=1" : ""}`,
            body: {
              note: body?.note || ""
            }
          })
        }
      }),

      reuploadDocumentContract: builder.mutation({
        query: ({contract_doc_id, body}) => {
          return ({
            method: "PUT",
            url: `/admin/contract-doc/${contract_doc_id}`,
            body
          })
        }
      }),

      extendContract: builder.mutation({
        query: ({contract_id, body}) => {
          return ({
            method: "PUT",
            url: `/platform/contract/${contract_id}`,
            body
          })
        }
      }),

      createNewContract: builder.mutation({
        query: ({office_id, body}) => {
          return ({
            method: "POST",
            url: `/platform/contract/${office_id}`,
            body
          })
        }
      }),

      stopContract: builder.mutation({
        query: ({ office_id }) => {
          return ({
            method: "DELETE",
            url: `/platform/contract/${office_id}`,
          })
        }
      }),
    };
  },
});

export const {
  useFetchAllContractQuery,
  useFetchDetailContractQuery,
  useUpdateDocumentContractMutation,
  useReuploadDocumentContractMutation,
  useExtendContractMutation,
  useCreateNewContractMutation,
  useStopContractMutation,
} = contractApi;
