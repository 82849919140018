import { UploadOutlined } from '@ant-design/icons'
import { Form, Row , Col, Input, Card, DatePicker, Select, Divider, Upload, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { Button, Modal, NumericInput, Spinner } from '../../components'
import moment from 'moment'
import axios from 'axios'
import { rootApi } from '../../config/features/config'
import { useFetchListRegionQuery, useFetchProfileUserQuery } from '../../config/features'
import { useTranslation } from "react-i18next";
const {Option} = Select

// encoded file to base64 format and remove prefix of file 

const toBase64 = (file) => {

    return new Promise( (resolve , reject) => {

        const reader = new FileReader()

        reader.readAsDataURL(file)
    
        reader.onload = () => {

            if(reader.result.includes('data:application/pdf;base64,')){
                resolve(reader.result.replace('data:application/pdf;base64,' , ''))
            }

            if(reader.result.includes('data:image/jpeg;base64,') || reader.result.includes('data:image/jpg;base64,') ){
                resolve(reader.result.replace('data:image/jpeg;base64,' , ''))
            }
            
        }

        reader.onerror = e => reject(e)

    })


}


function RegistrationKYC() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };


    const tokenPeruri = useMemo( () => localStorage.getItem('peruri') , [])

    const tokenAccess = useMemo( () => localStorage.getItem('access') , [])

    const history = useHistory()
    const [isAgree, setIsAgree] = useState(false);

    const [openConfirm , setOpenConfirm] = useState(false)

    const [openSuccess , setOpenSuccess] = useState(false)

    const [payload , setPayload] = useState({ktp : '' , rt : '' , rw : '' , npwp : ''})

    const [fileListKtp , setFileListKtp] = useState([])

    const [fileListSelfie , setFileListSelfie] = useState([])

    const [fileListNpwp , setFileListNpwp] = useState([])

    const [body , setBody] = useState(null)

    const [loading , setLoading] = useState(false)

    const [provinceSelected, setProvinceSelected] = useState({
        province_id: 0,
      });
    // get data profile 
    const { data: userProfile = {} , isLoading : loadingProfile } = useFetchProfileUserQuery();

    // get data province
    const { data: dataProvince = [] } = useFetchListRegionQuery();

    // get data kota 
    const { data: dataCity = [] } = useFetchListRegionQuery(provinceSelected?.province_id);
    

    // handle before date under 17
    
    const maxDate = new Date(17,"years").setDate(17,"years")
    const minDate = new Date(17,"years").setDate(17,"years")
    
    const daysFromRefDate = (date) =>
      date &&
      Math.floor((date.getTime() - date.getTimezoneOffset() * 60000) / 86400000)
    
    const DateTimePicler = ({ minDate, maxDate, initDate }) => {
      const [selDate, setSelDate] = React.useState(initDate)
      const changeDate = React.useCallback((date) => setSelDate(date), [])
    
      const minDays = React.useMemo(() => daysFromRefDate(minDate), [minDate])
      const maxDays = React.useMemo(() => daysFromRefDate(maxDate), [maxDate])
      const selDays = React.useMemo(() => daysFromRefDate(selDate), [selDate])
      
      return (
        DatePicker
      )
    }
    // cek size for file 
    const propsUploadKtp = {
        onRemove : () => {
            setFileListKtp([])
        } ,
        beforeUpload : (file) => {
            const isJpg = file.type === 'image/jpeg'

            const isLt1 = file.size / 1024 / 1024 < 1

            if( isJpg){
               
                if(!isLt1){
                    message.info(t("File must smaller than 1MB!"))
                }else{
                    setFileListKtp([file])
                }

            }else{
                message.info(t("You can't upload this extension!"))
            }
            return false
        } ,
        fileListKtp
    }

    // handle before upload for file selfie
    // cek size for file 
    const propsUploadSelfie = {
        onRemove : () => {
            setFileListSelfie([])
        } ,
        beforeUpload : (file) => {
            
            const isJpg = file.type === 'image/jpeg'
            const isLt1 = file.size / 1024 / 1024 < 1
            if(isJpg){

                if(!isLt1){
                    message.info(t('File must smaller than 1MB!'))
                }
                else{
                    setFileListSelfie([file])
                }

            }else{
                message.info(t("You can't upload this extension!"))
            }
            return false
        } , 
        fileListSelfie
    }

    // handle before upload for file npwp
    // cek size for file 
    const propsUploadNpwp = {
        onRemove : () => {
            setFileListNpwp([])
        } ,
        beforeUpload : (file) => {
            const isPdf = file.type === "application/pdf"
            const isJpg = file.type === 'image/jpeg'
            const isLt1 = file.size / 1024 / 1024 < 1
            if(isJpg){

                if(!isLt1){
                    message.info(t('File must smaller than 1MB!'))
                }
                else{
                    setFileListNpwp([file])
                }

            }else{
                message.info(t("You can't upload this extension!"))
            }
        } ,
        fileListNpwp
    }

    // handle format upload payload before send to BE
    const onFinish = async (values) => {

        if(!payload.ktp || payload.ktp.length !== 16){
            message.info( payload.ktp.length !== 16 ? t('Inccorect NIK should 16 digit') : t('Please add your NIK'))
            return
        }
        
        if( !fileListKtp[0] || !fileListSelfie[0]){
            message.info(t('Please upload your KTP or Selfie Photo'))
            return
        } 

        const base64Ktp = await toBase64(fileListKtp[0])

        let base64Npwp = ``
        if(fileListNpwp.length > 0){
            base64Npwp = await toBase64(fileListNpwp[0])
        }        

        const base64Selfie = await toBase64(fileListSelfie[0])

        const body = {

            ktp : payload.ktp ,
            npwp : payload.npwp ,
            address : values.address ,
            village : values.village ,
            sub_district : "" ,
            district : values.district ,
            province : values.province ,
            gender : payload.gender ,
            place_of_birth : values.place_of_birth ,
            date_of_birth : moment(values.date_of_birth, moment.ISO_8601).format("DD/MM/YYYY") ,
            organization_unit : values.organization_unit ,
            work_unit : values.work_unit ,
            position : values.position ,
            rt : payload.rt ,
            rw : payload.rw ,
            password : values.password ,
            ktp_photo : base64Ktp ,
            npwp_photo : base64Npwp ,
            self_photo : base64Selfie 

        }
        
        setBody(body)

        setOpenConfirm(true)

    }

    // send format payload to BE for register 
    const handleSubmit = async () => {

        try{

            setLoading(true)

            const { data } = await axios({
                method : 'POST' ,
                url : `${rootApi}/sign/register` ,
                data : body ,
                headers : {
                   "token-peruri-sign" : tokenPeruri ,
                    token : tokenAccess
                }
            })

            setLoading(false)

            setTimeout(() => {
                const now = new Date()
                localStorage.setItem('last_send_activation', now.toISOString()) // for mechanism resend email activation from profile
                setOpenSuccess(true)

            } , 700)

        }catch(e){

            setLoading(false)

            const {data : {error}} = e.response
            message.error(error)
        }
        
    }


    return (
        <>

            { !loadingProfile &&
                    <Form onFinish={onFinish} layout='vertical'>
                    <Row className='px-12 pt-7' gutter={[24 , 24]}>
                        <Col span={24}>
                            <Row justify='space-between'>
                                <Col>
                                    <h1 className='font-medium text-3xl'>{t("regisign")}</h1>
                                </Col>
                                <Col>
                                    <Button htmlType='submit' type='primary' className='w-52 h-10 font-medium tracking-tight'>
                                        {t("submit")}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Card className='p-6'>
                                <Row gutter={[24 , 12]}>
                                    <Col xs={24} md={12}>
                                        <Form.Item initialValue={userProfile?.first_name} label={t("firstname")} name="first_name">
                                            <Input disabled className=''  />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item initialValue={userProfile?.last_name} label={t("lastname")} name="last_name">
                                            <Input disabled className=''  />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item initialValue={userProfile?.phone} label={t("phonenumber")} name="phone_number">
                                            <Input disabled className=''  />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item initialValue={userProfile?.email} label="Email" name="email">
                                            <Input disabled className=''  />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <div className='flex flex-col space-y-2'>
                                            <label>
                                                <span><span className='text-red-400 mr-1'>*</span>NIK</span>
                                            </label>
                                            <NumericInput 
                                                maxLength={16}
                                                placeholder="Enter NIK"
                                                value={payload.ktp}
                                                onChange={e => setPayload( prev => ({...prev , ktp : e}))}
                                                className="nik"
                                                rules={[
                                                {
                                                    required : true ,
                                                    message : t('Please input NIK!')
                                                } ,
                                                {
                                                    min : 16,
                                                    message : t('NIK needs to be at least 16 character')
                                                }
                                            ]}
                                            /> 
                                            
                                        </div>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item 
                                            label={t("gender")} 
                                            name="gender"
                                            rules={[
                                                {
                                                    required : true ,
                                                    message : t('Please select your gender!')
                                                }
                                            ]}>
                                            <Select size='xl' onChange={e => setPayload( prev => ({...prev , gender : e }))} placeholder={t("No Selected")}>
                                                <Option value={'M'}>{t("male")}</Option>
                                                <Option value={'F'}>{t("female")}</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item 
                                            label={t("placeofbirth")}
                                            name="place_of_birth"
                                            rules={[
                                                {
                                                    required : true ,
                                                    message : t('Please input Place of Birth!')
                                                }
                                            ]}
                                            >
                                            <Input placeholder={t('Enter place of birth')} className='' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item 
                                            label={t("mindateregis")} 
                                            name="date_of_birth"
                                            showDisabledMonthNavigation
                                            rules={[
                                                {
                                                    required : true ,
                                                    message : t('Please input Date of Birth!')
                                                    
                                                }
                                            
                                            ]}
                                            >
                                                
                                                <DatePicker className='w-full' disabledDate={d => !d || d.isAfter("2006-06-06") 
                                                || d.isSameOrBefore("1960-01-01") } 
                                                format="YYYY-MM-DD" 
                                                defaultPickerValue={moment("2006-06-06")} 
                                                placeholder={t("Date")} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            label={t("Province")}
                                            name="province"
                                            rules={[
                                                {
                                                    required : true ,
                                                    message : t("Please select province!")
                                                }
                                            ]}
                                        >
                                            <Select
                                                placeholder={t("Not Selected")}
                                                onChange={ (_ , record) => {
                                                    setProvinceSelected(dataProvince[record?.key])  
                                                }}>
                                                {dataProvince?.map((item, index) => {
                                                    return (
                                                    <Option key={index} value={item?.province_id}>
                                                        {item?.province_name}
                                                    </Option>
                                                    );
                                                })}
                                            </Select>

                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            label={t("District")}
                                            name="district"
                                            rules={[
                                            {
                                                required: true,
                                                message: t("Please select district!"),
                                            },
                                            ]}
                                        >
                                            <Select
                                                placeholder={t("Not Selected")}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                                }
                                                allowClear={false}>
                                                {dataCity?.map((item, index) => {
                                                    return (
                                                    <Option key={index} value={item?.kab_id}>
                                                        {item?.kab_name}
                                                    </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}  md={12}>
                                        <Form.Item 
                                                label={t("Village")} 
                                                name="village"
                                                rules={[
                                                    {
                                                        required : true ,
                                                        message : t('Please input village!')
                                                    }
                                                ]}
                                                >

                                            <Input placeholder={t('Enter village')} className='' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item 
                                            label={t("Address")} 
                                            name="address"
                                            rules={[
                                                {
                                                    required : true ,
                                                    message : t('Please input address!')
                                                }
                                            ]}
                                            >
                                            <Input placeholder={t('Enter Address')} className='' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={6}>
                                        <div className='flex flex-col space-y-2'>
                                            <label>RT</label>
                                            <NumericInput 
                                                maxLength={3}
                                                placeholder="Enter RT"
                                                value={payload.rt}
                                                onChange={ e => setPayload( prev => ({...prev , rt : e}))}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24} md={6}>
                                        <div className='flex flex-col space-y-2'>
                                            <label>RW</label>
                                            <NumericInput 
                                                maxLength={3}
                                                placeholder="Enter RW"
                                                value={payload.rw}
                                                onChange={ e => setPayload( prev => ({...prev , rw : e}))}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item initialValue={userProfile?.office_name} label={t("Work Unit")} name="work_unit">
                                            <Input disabled placeholder='Branch' className='' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item initialValue={userProfile?.dept_name} label={t("Department")} name="organization_unit">
                                            <Input disabled placeholder='Product' className='' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}  md={12}>
                                        <Form.Item 
                                            label="Role" 
                                            name="position"
                                            rules={[
                                                {
                                                    required : true ,
                                                    message : t('Please input role!')
                                                }
                                            ]}
                                            >
                                            <Input placeholder={t('Enter Role')} className='' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item 
                                            label="Password" 
                                            name="password"
                                            rules={[
                                                {
                                                    required : true ,
                                                    message : t('Please input password!')
                                                } ,
                                                {
                                                    min : 8 ,
                                                    message : t('Password needs to be at least 8 character')
                                                }
                                            ]}
                                            >
                                            <Input.Password placeholder={t('Enter Password')} className='' />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Divider />
                                    </Col>

                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            label={
                                                <span><span className='text-red-400 mr-1'>* </span>KTP</span>
                                            }
                                            name="file_ktp"
                                            rules={[{ required :  false , message : t('Please attach KTP!')}]}
                                        >

                                            <div className='border border-gray-200 border-solid px-4 py-2 rounded-sm'>
                                                <Upload 
                                                    className='w-full'
                                                    maxCount={1}
                                                    {...propsUploadKtp}
                                                    fileList={fileListKtp}
                                                >
                                                <Button type='primary' icon={<UploadOutlined />}>
                                                    {t("Upload")}
                                                </Button>
                                                </Upload>
                                            </div>
                                            <small className='font-medium tracking-tight'>Format : jpg, jpeg | {t("Max")} : 1 Mb</small>

                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            label={<span><span className='text-red-400 mr-1'>* </span>{t("Selfie Photo")}</span>}
                                            name="file_selfie"
                                            rules={[{ required :  false , message : t('Please attach Selfie!')}]}
                                        >
                                            <div className='border border-gray-200 border-solid px-4 py-2 rounded-sm'>
                                                <Upload 
                                                    maxCount={1}
                                                    {...propsUploadSelfie}
                                                    fileList={fileListSelfie}
                                                >
                                                <Button type='primary' icon={<UploadOutlined />}>
                                                    {t("Upload")}
                                                </Button>
                                                </Upload>
                                            </div>
                                            <small className='font-medium tracking-tight'>Format : jpg, jpeg | {t("Max")} : 1 Mb</small>
                                            {/* <small className='font-medium tracking-tight'>Format : jpg, jpeg, pdf | Max : 1 Mb</small> */}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                        
                                            label={<span><span className='text-red-400 mr-1'></span>NPWP</span>}
                                            name="file_npwp"
                                            rules={[{ required :  false , message : t('Please attach NPWP!')}]}
                                        >
                                            <div className='border border-gray-200 border-solid px-4 py-2 rounded-sm'>
                                                <Upload 
                                                
                                                    maxCount={1}
                                                    {...propsUploadNpwp}
                                                    fileList={fileListNpwp}
                                                >
                                                <Button type='primary' icon={<UploadOutlined />}>
                                                    {t("Upload")}
                                                </Button>
                                                </Upload>
                                            </div>
                                            <small className='font-medium tracking-tight'>Format : jpg, jpeg | {t("Max")} : 1 Mb</small>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item 
                                            label={t("NPWP Number")}
                                        >
                                            <NumericInput 
                                                placeholder={t("Enter NPWP Number")}
                                                className=''
                                                maxLength={15}
                                                value={payload.npwp}
                                                onChange={ e => setPayload( prev => ({...prev , npwp : e}))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    </Form> }
                   
                    <Modal
                        visible={openConfirm}
                        type="checkbox"
                        title={t("modaltermcon")}
                     
                        onCancel={() => setOpenConfirm(false)}
                    >
                        <div className='flex flex-col'>
                            <label>
                                <input type="checkbox" checked={isAgree} onChange={(e) => setIsAgree(isAgree => !isAgree)}/>
                                &nbsp;&nbsp;
                                <span>{t("I have read and agree")}  <a href="https://ca.peruri.co.id/ca/legal/" target="_blank"><u>{t("to the terms legal (CP, CPS, Subscriber Agreement, dan Privacy Policy) From Peruri CA.")}</u></a></span>
                            </label>
                            <div className='flex gap-5 mt-12' dis>
                                <Button onClick={() => setOpenConfirm(false)} className='font-medium text-base tracking-tight flex-1 h-10' ghost type='primary'>{t("btnback")}</Button>
                                <Button onClick={() => {
                                    if (isAgree) {                                        
                                        setOpenConfirm(false)
                                        handleSubmit()
                                    }
                                }} 
                                className='font-medium text-base tracking-tight flex-1 h-10' type='primary' disabled={!isAgree}>{t("btnsend")}</Button>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        visible={openSuccess}
                        title={t("Registration Success")}
                        onCancel={() => setOpenSuccess(false)}
                    >
                        <div className='flex flex-col items-center'>
                            <p className='mb-10 text-center text-base leading-6 font-sans tracking-tight'>{t("infosaving")}</p>
                            <Button type='primary' className='w-52 font-medium text-base tracking-tight h-10' onClick={() => history.push('/profile')}>{t("btndone")}</Button>
                        </div>
                    </Modal>
                    <Spinner loading={loadingProfile || loading} />
        </>
    )
}

export const accountSelector = (state) => state.account

export default RegistrationKYC