import { Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { numberCommas } from "../../utils";
import { useTranslation } from "react-i18next";

export default function Invoice({ dataSource }) {
  const { t } = useTranslation();
  const columnsProduct = [
    {
      title: t("Product Name"),
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: t("Unit Price"),
      dataIndex: "unit_price",
      key: "unit_price",
      render: (unit_price) => {
        return `Rp ${numberCommas(unit_price)},-`;
      },
    },
    {
      title: t("Quantity"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t("Price"),
      dataIndex: "total_price",
      key: "total_price",
      render: (e) => {
        return `Rp ${numberCommas(e)},-`;
      },
    },
  ];

  return (
    <div>
      <table>
        <thead>
          <tr>
            <td>
              <Row gutter={[24, 24]} className="page-header page-header-space">
                <Col>
                  <img
                    className="w-24 md:w-28"
                    src="/images/logo-primary.png"
                    alt="dli-logo"
                  />
                </Col>
              </Row>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="page">
                <Row gutter={[24, 24]} className="mt-8">
                  <Col span={24}>
                    <h2 className="text-2xl text-gray-800 font-semibold text-center">
                      {t("Invoice")}
                    </h2>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[24, 24]}>
                      <Col span={24}>
                        <Row gutter={[24, 24]}>
                          <Col span={8}>
                            <h3 className="text-gray-500 text-base font-medium">
                              {t("Invoice Number")}
                            </h3>
                          </Col>
                          <Col span={16}>
                            <h3 className="text-gray-800 text-base font-medium">
                              {dataSource?.invoice_id}
                            </h3>
                          </Col>
                        </Row>
                        <Row gutter={[24, 24]} className="mt-2">
                          <Col span={8}>
                            <h3 className="text-gray-500 text-base font-medium">
                              {t("Quotation Date")}
                            </h3>
                          </Col>
                          <Col span={16}>
                            <h3 className="text-gray-800 text-base font-medium">
                              {dataSource?.quotation_date
                                ? moment(
                                    dataSource?.quotation_date,
                                    moment.ISO_8601
                                  ).format("DD MMMM YYYY")
                                : "-"}
                            </h3>
                          </Col>
                        </Row>
                        <Row gutter={[24, 24]} className="mt-2">
                          <Col span={8}>
                            <h3 className="text-gray-500 text-base font-medium">
                              {t("Payment Due Date")}
                            </h3>
                          </Col>
                          <Col span={16}>
                            <h3 className="text-gray-800 text-base font-medium">
                              {dataSource?.payment_due
                                ? moment(
                                    dataSource?.payment_due,
                                    moment.ISO_8601
                                  ).format("DD MMMM YYYY")
                                : "-"}
                            </h3>
                          </Col>
                        </Row>
                        <Row gutter={[24, 24]} className="mt-2">
                          <Col span={8}>
                            <h3 className="text-gray-500 text-base font-medium">
                              {t("Seller")}
                            </h3>
                          </Col>
                          <Col span={16}>
                            <h3 className="text-gray-800 text-base font-medium">
                              PT Digital Logistik Internasional
                            </h3>
                          </Col>
                        </Row>
                        <Row gutter={[24, 24]} className="mt-2">
                          <Col span={8}>
                            <h3 className="text-gray-500 text-base font-medium">
                              {t("Buyer")}
                            </h3>
                          </Col>
                          <Col span={16}>
                            <h3 className="text-gray-800 text-base font-medium">
                              {dataSource?.office_name}
                            </h3>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={[24, 12]} className="mt-8">
                  <Col span={24}>
                    <h3 className="text-2xl text-gray-800 font-semibold">
                      {t("Purchase Details")}
                    </h3>
                  </Col>
                  <Col span={24}>
                    <table class="items-center bg-transparent w-full">
                      <thead>
                        <tr>
                          {columnsProduct.map((item, index) => {
                            return (
                              <th
                                key={index}
                                className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-2 border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
                              >
                                {item.title}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>

                      <tbody>
                        {dataSource?.product_list?.map((itemTr, indexTr) => {
                          return (
                            <tr key={indexTr}>
                              {columnsProduct.map((itemTd, indexTd) => {
                                return (
                                  <td
                                    key={indexTd}
                                    className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-2 "
                                  >
                                    {itemTd?.render
                                      ? itemTd.render(
                                          itemTr[itemTd.dataIndex],
                                          itemTr
                                        )
                                      : itemTr[itemTd.dataIndex]}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row gutter={[24, 12]} className="mt-8 total">
                  <div className="flex w-full justify-end">
                    <div>
                      <div className="grid grid-cols-2 gap-4">
                        <h3 className="text-gray-500">{t("Subtotal")}</h3>
                        <h3 className="text-gray-800">{`Rp ${numberCommas(
                          dataSource?.total
                        )},-`}</h3>
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <h3 className="text-gray-500">{t("Admin Fee")}</h3>
                        <h3 className="text-gray-800">{`Rp ${numberCommas(
                          dataSource?.admin_fee
                        )},-`}</h3>
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <h3 className="text-gray-500">{t("Other Fees")}</h3>
                        <h3 className="text-gray-800">Rp. 0,-</h3>
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <h3 className="font-medium text-blue-700">
                          {t("Total Payment")}
                        </h3>
                        <h3 className="font-medium text-blue-700">{`Rp ${numberCommas(
                          Number(dataSource?.total || 0) + Number(dataSource?.admin_fee || 0)
                        )},-`}</h3>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div className="page-footer-space page-footer invoice">
                <h3 className="font-medium text-gray-800">
                  PT Digital Logistik Internasional
                </h3>
                <h3 className="font-medium text-gray-800">
                  Jl. Prapanca Raya No. 17
                </h3>
                <h3 className="font-medium text-gray-800">(021) 724-6842</h3>
                <h3 className="font-medium text-gray-800">sales@vas.id</h3>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
