import { ArrowsAltOutlined, CloseOutlined, DeleteOutlined, DoubleLeftOutlined, EditOutlined, LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Avatar, Col, Divider, Dropdown, Form, Input, Menu, message, Radio, Row, Select, Modal as ModalAntd } from "antd";
import { Modal, Button, Spinner } from "../../components";
import pdfJsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { Document, Page, pdfjs } from "react-pdf";
import Fade from "react-reveal/Fade";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as AccountMultiplePlus } from "../../assets/icons/account-multiple-plus.svg";
import { ReactComponent as SignWithOtherIcon } from "../../assets/icons/sign-with-other.svg";
import SignSvg, {
  ReactComponent as SignIcon,
} from "../../assets/icons/sign.svg";
import { ReactComponent as SettingSign } from "../../assets/icons/setting-sign.svg";
import Dotted from '../../assets/img/icon/dotted.png'
import CloseIcon from '../../assets/img/icon/closeIcon.png'
import ResetIcon from '../../assets/icons/reset.svg'
import SettingIcon from '../../assets/img/icon/setting.png'
import Unstamp from '../../assets/img/icon/unstamp.png'
import google1 from '../../assets/img/google-1.png'
import google2 from '../../assets/img/google-2.png'
import safari1 from '../../assets/img/safari1.png'
import safari2 from '../../assets/img/safari2.png'
import safari3 from '../../assets/img/safari3.png'
import firefox1 from '../../assets/img/firefox1.png'
import firefox2 from '../../assets/img/firefox2.png'
import arrowRight from '../../assets/img/icon/right-arrow.png'
import arrowBtm from '../../assets/img/icon/arrow-btm.png'
import arrowUp from '../../assets/img/icon/arrow-up.png'

import ButtonPrimary from "../../components/Button/ButtonPrimary";
import axios from "axios";
import { rootApi } from "../../config/features/config";
import { getPosition, numberCommas } from "../../utils";
import { getLastArrayValue } from "../../utils/array";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker;

const { Option } = Select;
const { TextArea } = Input

const rgba = ['rgba(39, 245, 137, 0.8)', 'rgba(39, 143, 245, 0.8)', 'rgba(199, 245, 39, 0.8)', 'rgba(98, 39, 245, 0.8)', 'rgba(250, 32, 246, 0.8)', 'rgba(71, 206, 189, 0.8)', 'rgba(86, 206, 71, 0.8)']

export default function SignWithOther() {
  const { t } = useTranslation();

  const tokenPeruri = useMemo(() => localStorage.getItem('peruri'), [])
  const tokenAccess = useMemo(() => localStorage.getItem('access'), [])
  const { state: { dataDoc } } = useLocation()
  const history = useHistory();
  const [pdfFile, setPdfFile] = useState(null);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  let [totalSignature, setTotalSignature] = useState([]);
  const [done] = useState(false)
  const [totalStamp, setTotalStamp] = useState(0)
  const [openSubmit, setOpenSubmit] = useState(false)
  const [moveable, setMoveable] = useState(true);
  const [visibleDrawer, setVisibleDrawer] = useState(true);
  const [signType, setSignType] = useState('pararel')
  const [signModal, setSignModal] = useState(false)
  const [signModalEdit, setSignModalEdit] = useState({ open: false, key: '' })
  const [previewModal, setPreview] = useState(false)
  const [successModal, setsuccessModal] = useState(false)
  const [guideModal, setGuideModal] = useState({ guide1: false, guide2: false, guide3: false, })
  const [isLocationBlocked, setIsLocationBlocked] = useState(false)
  const [signer, setSigner] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)
  const [listStampPosition, setListStampPosition] = useState([])
  const [user, setUserSign] = useState({ signer_name: '', signer_email: '', sender_note: '', user_id: '', key: '' })
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [data, setData] = useState(null)
  const [totalMaterai, setTotalMaterai] = useState({ total: 0, remaining: 0 })
  const [openSign, setOpenSign] = useState(false)

  const [deltaPosition, setDeltaPosition] = useState({});
  const [sizing, setSizing] = useState([]);
  const [location, setLocation] = useState(null)
  const [reason, setReason] = useState(null)
  const [isSetResize, setIsSetResize] = useState(false);

  const [activeLocation, setActiveLocation] = useState(false)

  // get document pdf 
  useEffect(() => {
    function base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    }

    async function fetchData() {

      setLoading(true)

      try {
        const { data: { data } } = await axios({
          method: 'GET',
          url: `${rootApi}/document/preview/${dataDoc.doc_id}`,
          headers: {
            "token": tokenAccess
          }
        })

        const documentBuffer = base64ToArrayBuffer(data)
        setPdfFile(documentBuffer)
        setLoading(false)
      } catch (error) {

        message.error(error.message);
      }
    }
    fetchData();
  }, []);


  // get quota materai
  useEffect(() => {

    async function getQuoteMeterai() {

      const { data: { data } } = await axios({
        method: 'GET',
        url: `${rootApi}/quota`,
        headers: {
          token: tokenAccess
        }
      })

      setTotalMaterai({ total: data[1].remaining, remaining: data[1].remaining })
    }

    getQuoteMeterai()

    if (totalMaterai !== null) {
      setLoading(false)
    }
  }, [])



  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const getSpesimenSize = (email, isParaf = false) => {
    if (isParaf) {
      return sizing.find((item) => item.email == email).paraf;
    }
    return sizing.find((item) => item.email == email).signature;
  }

  // useEffect(() => {
  //   console.log(totalSignature);
  // }, [totalSignature])

  // add signature signer
  function handleAddSignature(index, isParaf = false) {

    setOpenSubmit(true)

    if (totalMaterai.total === 0) {
      message.warning(t('Your quota not enought to sign'))
    } else {
      setTotalMaterai(prev => ({ ...prev, total: prev.total - 1 }))
      const SIZE_LIMIT = 100
      const startSize = getSpesimenSize(signer[signer.length - 1].signer_email, isParaf)
      if (startSize.height > SIZE_LIMIT && startSize.height >= startSize.width) {
        const ratio = startSize.width / startSize.height
        startSize.height = SIZE_LIMIT
        startSize.width = ratio * startSize.height
      }
      else if (startSize.width > SIZE_LIMIT && startSize.width >= startSize.height) {
        const ratio = startSize.height / startSize.width
        startSize.width = SIZE_LIMIT
        startSize.height = ratio * startSize.width
      }
      setTotalSignature([...totalSignature, { index, page: pageNumber, signer_name: signer[signer.length - 1].signer_name, signer_email: signer[signer.length - 1].signer_email, rgb: signer[signer.length - 1].color, paraf: isParaf ? "PARAF" : "SPESIMEN", frame_width_ratio: 3, ...startSize }]);
      setDeltaPosition(prevDelta => {
        const newDelta = {
          ...prevDelta,
          [index]: { x: 0, y: -Number(startSize.height || 0), page: pageNumber, type: isParaf ? "PARAF" : "SPESIMEN", name: signer[signer.length - 1].signer_name }
        }

        return newDelta
      })
    }
  }

  // close signer 
  function handleCloseSignature(indexNumber, name = "", isParaf) {

    if (Object.keys(deltaPosition).length === 1) {
      setOpenSubmit(false)
    }

    setTotalMaterai(prev => ({ ...prev, total: prev.total + 1 }))

    // Delete Delta Position Item
    setDeltaPosition(prevDelta => {
      const newDelta = {}

      const itemSizing = sizing.filter(items => items.name === name)[0]
      const heightItem = isParaf ? itemSizing?.paraf?.height : itemSizing?.signature?.height
      const keys = Object.keys(prevDelta)
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        const item = prevDelta[key]

        if (key >= indexNumber) {
          newDelta[key] = { ...item, y: item.y + heightItem }
        } else {
          newDelta[key] = { ...item }
        }
      }

      if (newDelta[indexNumber]) delete newDelta[indexNumber]

      return newDelta
    })

    // Delete List Stamp
    setListStampPosition(prevList => {
      const newList = prevList.filter((item) => item.index !== indexNumber);
      return newList
    })

    // Delete Total Stamp
    setTotalSignature(prevTotal => {
      const newTotal = prevTotal.filter((item) => item.index !== indexNumber);
      const isSignerSignExist = newTotal.findIndex((item) => item.signer_name === name)

      if (isSignerSignExist < 0) {
        deleteSigner(name)
      }

      return newTotal
    })
  }


  const handleDrag = (e, position, index, isParaf, name) => {
    const { x, y } = position;

    setDeltaPosition(prev => ({
      ...prev,
      [index]: { x, y, page: pageNumber, type: isParaf ? "PARAF" : "SPESIMEN", name }
    }))
  };

  // get coordinate each user  
  const getUserWithCoor = (user, deltaPosition, totalSignature) => {

    //  Grouping the delta position by page
    const deltaPositionKeys = Object.keys(deltaPosition)
    let deltaGroupByPage = {}
    for (let i = 0; i < deltaPositionKeys.length; i++) {
      const key = deltaPositionKeys[i];
      const item = deltaPosition[key];
      const itemWithIndex = { index: key, ...item }

      if (deltaGroupByPage[item.page]) {
        deltaGroupByPage[item.page] = [...deltaGroupByPage[item.page], itemWithIndex]
      } else {
        deltaGroupByPage[item.page] = [itemWithIndex]
      }
    }

    // Normalize the coordinates by page 
    const pageKeys = Object.keys(deltaGroupByPage)
    let normalizedDelta = {}
    for (let i = 0; i < pageKeys.length; i++) {
      const pageKey = pageKeys[i];
      const deltaArr = deltaGroupByPage[pageKey];

      let heightOffset = 0;
      for (let j = 0; j < deltaArr.length; j++) {
        const item = deltaArr[j];
        const { width, height, frame_width_ratio } = totalSignature.find((element) => element.index == item.index)
        // const offset = j;
        const itemSizing = sizing.filter(items => items.name === item.name)[0]
        const localSizing = item.type === "PARAF" ? itemSizing.paraf : itemSizing.signature

        const { width: boxWidth, height: boxHeight } = getBoxSize(width, height, frame_width_ratio)
        const shift_x = Math.abs((boxWidth - width) / 2)
        const shift_y = -Math.abs((boxHeight - height) / 2)

        normalizedDelta[item.index] = {
          llx: `${Math.abs(item.x + shift_x)}`,
          lly: `${Math.abs(item.y + shift_y) - height}`,
          urx: `${Math.abs(item.x + shift_x) + width}`,
          ury: `${Math.abs(item.y + shift_y)}`,
        }
      }
    }

    // Prepared signature data by signer
    let data = []
    for (let i = 0; i < user.length; i++) {
      const userItem = user[i];
      const totalSignatureFiltered = totalSignature.filter((item) => userItem.signer_name === item.signer_name)

      const coordinate = totalSignatureFiltered.map((item, i) => {
        const index = item.index;
        const page = item.page;
        const paraf = item.paraf
        const { llx, lly, urx, ury } = normalizedDelta[index];

        return ({
          index,
          type: paraf,
          llx: `${llx}`,
          lly: `${lly}`,
          urx: `${urx}`,
          ury: `${ury}`,
          page: `${page}`,
          location: (location === null || location === '') ? '' : `${location?.city},${location?.country}`,
          reason: reason,
          isv: "YES",
        })
      })

      data.push({
        signer_name: userItem.signer_name,
        signer_email: userItem.signer_email,
        sender_note: userItem.sender_note,
        user_id: userItem.user_id,
        sign_coordinates: [...coordinate],
      })
    }

    return data
  }


  // submit document for sign to BE
  const handlePreviewOtp = async (id) => {

    setIsSubmit(true)

    try {
      let bodySend = {
        doc_id: `${id}`,
        requested_users: getUserWithCoor(signer, deltaPosition, totalSignature),
        assign_type: '3',
        sign_type: signType === 'pararel' ? '1' : '2'
      }

      const { data: { data: dataSign } } = await axios({
        method: 'POST',
        url: `${rootApi}/sign/multi/send-doc`,
        data: bodySend,
        headers: {
          "token": tokenAccess,
          "token-peruri-sign": tokenPeruri
        }
      })

      setIsSubmit(false)
      setsuccessModal(true)
      setPreview(false)

    } catch (e) {

      setPreview(false)
      setIsSubmit(false)
      message.error(t("Sorry we can process your data, please try again!"))

    }
  }


  function handleChangePage(e) {
    setPageNumber(e);
  }

  function handleMovable() {
    setMoveable(!moveable);
  }


  // delete signer request
  const deleteSigner = (data) => {

    if (listStampPosition.find(item => item.signer_name === data)) {

      setListStampPosition(prev => {

        if (prev.length === 0) {
          return []
        }
        else {

          let dataDupSign = totalSignature.filter(item => item.signer_name !== data)

          let dataDup = prev.filter(items => items.signer_name !== data)

          setTotalSignature(dataDupSign)

          return dataDup
        }

      })

    }

    let dataFilter = signer.filter(item => item.signer_name !== data)


    setSigner(dataFilter)

  }

  // add sign request
  const handleAddSign = async () => {

    if (user.signer_name === "" || user.signer_email === "") {
      message.info(t("Please add signer before assign!"))

      return
    }

    if (signer.filter(item => item.signer_email === user.signer_email).length > 0) {
      message.warning(t("User has exist"))

      return
    }
    else if (signer.length !== 0 && !totalSignature.find((item, index) => item.signer_name === signer[signer.length - 1].signer_name)) {
      message.info(t("Please add a signature before add new user!"))

      return
    }
    else {

      try {

        setLoading(true)

        const { data: { data: dataSign } } = await axios({
          method: 'GET',
          url: `${rootApi}/sign/preview-speciment/${user.user_id}`,
          headers: {
            token: tokenAccess,
            "token-peruri-sign": tokenPeruri
          }
        })

        let sign = new Image()
        let name = user.signer_name
        let email = user.signer_email
        sign.src = dataSign.base64Speciment
        sign.onload = function () {

          setSizing(prev => ([...prev, {
            name,
            email,
            signature: {
              width: sign.width,
              height: sign.height
            }
          }]))

        }
        let paraf = new Image()
        paraf.src = dataSign.base64Paraf
        paraf.onload = function () {
          setSizing(prev => {
            const item = prev[prev.length > 0 ? prev.length - 1 : 0]
            const dupItem = { ...item, paraf: { width: paraf.width, height: paraf.height } }
            const prevArr = prev.filter(item => item.name !== user.signer_name)

            return [...prevArr, dupItem]
          })
        }

        user.color = rgba[Math.floor(Math.random() * 7)]
        user.key = Math.floor(Math.random() * 100)

        setSigner(prev => [...prev, user])
        setUserSign({ signer_name: '', signer_email: '', sender_note: '', key: '', user_id: '' })
        setLoading(false)
        setSignModal(false)
      } catch (e) {
        setLoading(false)
        const { data: { error } } = e.response
        message.error(error)
      }

    }

  }

  // check last signer has signed 
  const checkUserCoor = () => {

    const lastSigner = signer[signer.length - 1]

    if (totalSignature.find(item => item.signer_name === lastSigner.signer_name)) {
      setActiveLocation(true)
    }
    else {
      message.info(t("Please add coordinate last signer!"))
    }

  }

  // update signer 
  const handleUpdateSign = () => {

    setUserSign({ signer_email: '', user_id: '', signer_name: '', key: '', sender_note: '' })

    let dataCopy = signer
    let name = ""

    for (let i = 0; i < signer.length; i++) {
      if (signer[i].key === signModalEdit.key) {
        name = dataCopy[i].signer_name
        dataCopy[i] = user

      }
    }

    let copyStamp = listStampPosition.map(item => {

      if (item.signer_name === name) {
        return { ...item, signer_name: user.signer_name }
      }

      return item

    })

    let copySignature = totalSignature.map(item => {

      if (item.signer_name === name) {
        return { ...item, signer_name: user.signer_name }
      }

      return item

    })


    setSigner(dataCopy)

    setListStampPosition(copyStamp)

    setTotalSignature(copySignature)
  }



  // handle edit signer request
  const handleEdit = (data) => {

    let { signer_email, user_id, signer_name, key } = signer.filter(item => item.signer_name === data)[0]

    setUserSign(
      {
        signer_email,
        user_id,
        signer_name,
        key
      }
    )

    setSignModalEdit(prev => ({ ...prev, open: true, key }))

  }

  // menu for signer update
  const MenuItem = (data) =>
  (
    <Menu>
      <Menu.Item onClick={() => handleEdit(data)}>
        <EditOutlined /> <span className="ml-2">Edit</span>
      </Menu.Item>
      <Menu.Item onClick={() => deleteSigner(data)}>
        <DeleteOutlined style={{ color: 'red' }} /> <span className="ml-2 text-red-500">{t("Delete")}</span>
      </Menu.Item>
    </Menu>
  )

  // reset signature
  const handleReset = () => {

    if (totalSignature.length !== 0) {
      setTotalMaterai(prev => ({ ...prev, total: prev.remaining }))
      setTotalStamp(0)
      setListStampPosition([])
      setSigner([])
      setTotalSignature([])
      setDeltaPosition({})
    }
  }

  // handle change add signer input
  const handleChange = (e, status = "name") => {

    if (status === "name") {
      setOpen(true)
      setUserSign(prev => ({ ...prev, signer_name: e }))
    }

    if (status === "email") {
      setOpen2(true)
      setUserSign(prev => ({ ...prev, signer_email: e }))
    }

    if (status === "note") {
      setUserSign(prev => ({ ...prev, sender_note: e }))
    }

    if (status === 'email' || status === 'name') {
      setTimeout(async () => {

        const { data: { data } } = await axios({
          method: 'GET',
          url: `${rootApi}/sign/users?search=${status === "email" ? user.signer_email : user.signer_name}`,
          headers: {
            "token": tokenAccess,
          }
        })

        setData(data)

      }, 700)
    }
  }

  // handle search option signer
  const handleSelectOption = (value, status) => {

    if (status) {
      setOpen2(false)
      setUserSign(prev => ({ ...prev, signer_email: value.email, signer_name: value.full_name, user_id: value.user_id }))
    } else {
      setOpen(false)
      setUserSign(prev => ({ ...prev, signer_email: value.email, signer_name: value.full_name, user_id: value.user_id }))
    }
  }

  // get user size sign
  const getUserSize = (name, isParaf) => {

    const item = sizing.filter(item => item.name === name)[0]

    return isParaf === true ? item.paraf : item.signature

  }

  const getLocation = async () => {

    setActiveLocation(false)

    const response = await getPosition()

    if (typeof response === 'object') {
      setLocation(response)
      setPreview(true)
      return
    }

    setLocation('')
    setIsLocationBlocked(true)

  }



  // const handler = (mouseDownEvent, index) => {
  //   mouseDownEvent.preventDefault();
  //   var isDown = true;
  //   const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

  //   function onMouseMove(mouseMoveEvent) {
  //     if (isDown) {
  //       const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
  //       const { width, height, signer_email, paraf } = totalSignature.find((item) => item.index == index);
  //       const defaultSize = getSpesimenSize(signer_email, paraf == "PARAF");
  //       const ratio = defaultSize.width / defaultSize.height;

  //       // const resizeValue = Math.min(mouseMoveEvent.pageX - mouseDownEvent.pageX, mouseMoveEvent.pageY - mouseDownEvent.pageY);
  //       // const currentWidth = width - ((ratio * resizeValue))
  //       // const currentHeight = height - (resizeValue)

  //       // Mekanisme Zoom In / Zoom Out
  //       let resizeValue = 0
  //       if (currentPosition.x < startPosition.x && currentPosition.y > startPosition.y) {
  //         // Zoom In
  //         resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
  //       }
  //       else if (currentPosition.x > startPosition.x && currentPosition.y < startPosition.y) {
  //         // Zoom Out
  //         resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
  //       }
  //       const currentWidth = width + (ratio * resizeValue);
  //       const currentHeight = height + resizeValue;

  //       // Updating Size
  //       setTotalSignature(totalSignature.map(signature => (signature.index == index ? { ...signature, width: currentWidth, height: currentHeight } : signature)))
  //     }
  //   }

  //   function onMouseUp() {
  //     isDown = false
  //     document.body.removeEventListener("mousemove", onmousemove);
  //     // document.body.removeEventListener("mouseup", onMouseUp);
  //   }

  //   document.body.addEventListener("mousemove", onMouseMove);
  //   document.body.addEventListener("mouseup", onMouseUp, { once: true });
  // }

  const handler0 = (mouseDownEvent, index) => {
    mouseDownEvent.preventDefault();
    var isDown = true;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const { width, height, signer_email, paraf } = totalSignature.find((item) => item.index == index);
        const defaultSize = getSpesimenSize(signer_email, paraf == "PARAF");
        const ratio = defaultSize.width / defaultSize.height;

        // const resizeValue = Math.min(mouseMoveEvent.pageX - mouseDownEvent.pageX, mouseMoveEvent.pageY - mouseDownEvent.pageY);
        // const currentWidth = width - ((ratio * resizeValue))
        // const currentHeight = height - (resizeValue)

        // Mekanisme Zoom In / Zoom Out
        let resizeValue = 0
        if (currentPosition.x < startPosition.x && currentPosition.y > startPosition.y) {
          // Zoom In
          resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        else if (currentPosition.x > startPosition.x && currentPosition.y < startPosition.y) {
          // Zoom Out
          resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        const currentWidth = width + (ratio * resizeValue);
        const currentHeight = height + resizeValue;

        // Updating Size
        setTotalSignature(totalSignature.map(signature => (signature.index == index ? { ...signature, width: currentWidth, height: currentHeight } : signature)))
      }
    }

    function onMouseUp() {
      isDown = false
      document.body.removeEventListener("mousemove", onMouseMove);
      // document.body.removeEventListener("mouseup", onMouseUp);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }

  const handler1 = (mouseDownEvent, index) => {
    mouseDownEvent.preventDefault();
    var isDown = true;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const { width, height, signer_email, paraf } = totalSignature.find((item) => item.index == index);
        const defaultSize = getSpesimenSize(signer_email, paraf == "PARAF");
        const ratio = defaultSize.width / defaultSize.height;

        // const resizeValue = Math.min(mouseMoveEvent.pageX - mouseDownEvent.pageX, mouseMoveEvent.pageY - mouseDownEvent.pageY);
        // const currentWidth = width - ((ratio * resizeValue))
        // const currentHeight = height - (resizeValue)

        // Mekanisme Zoom In / Zoom Out
        let resizeValue = 0
        if (currentPosition.x > startPosition.x && currentPosition.y > startPosition.y) {
          // Zoom In
          resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        else if (currentPosition.x < startPosition.x && currentPosition.y < startPosition.y) {
          // Zoom Out
          resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        const currentWidth = width + (ratio * resizeValue);
        const currentHeight = height + resizeValue;

        // Updating Size
        setTotalSignature(totalSignature.map(signature => (signature.index == index ? { ...signature, width: currentWidth, height: currentHeight } : signature)))
      }
    }

    function onMouseUp() {
      isDown = false
      document.body.removeEventListener("mousemove", onMouseMove);
      // document.body.removeEventListener("mouseup", onMouseUp);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }

  const handler2 = (mouseDownEvent, index) => {
    mouseDownEvent.preventDefault();
    var isDown = true;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const { width, height, signer_email, paraf } = totalSignature.find((item) => item.index == index);
        const defaultSize = getSpesimenSize(signer_email, paraf == "PARAF");
        const ratio = defaultSize.width / defaultSize.height;

        // const resizeValue = Math.min(mouseMoveEvent.pageX - mouseDownEvent.pageX, mouseMoveEvent.pageY - mouseDownEvent.pageY);
        // const currentWidth = width - ((ratio * resizeValue))
        // const currentHeight = height - (resizeValue)

        // Mekanisme Zoom In / Zoom Out
        let resizeValue = 0
        if (currentPosition.x < startPosition.x && currentPosition.y < startPosition.y) {
          // Zoom In
          resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        else if (currentPosition.x > startPosition.x && currentPosition.y > startPosition.y) {
          // Zoom Out
          resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        const currentWidth = width + (ratio * resizeValue);
        const currentHeight = height + resizeValue;

        // Updating Size
        setTotalSignature(totalSignature.map(signature => (signature.index == index ? { ...signature, width: currentWidth, height: currentHeight } : signature)))
      }
    }

    function onMouseUp() {
      isDown = false
      document.body.removeEventListener("mousemove", onMouseMove);
      // document.body.removeEventListener("mouseup", onMouseUp);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }

  // useEffect(() => {
  //   console.log(totalSignature);
  // }, [totalSignature])

  const handler3 = (mouseDownEvent, index) => {
    mouseDownEvent.preventDefault()
    var isDown = true
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY }

    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY }
        const currentSignature = totalSignature.find((item) => item.index == index)
        const original_ratio = currentSignature.width / currentSignature.height
        const { width: boxWidth } = getBoxSize(currentSignature.width, currentSignature.height, currentSignature.frame_width_ratio)

        const x_shift = (startPosition.x - currentPosition.x) * 2
        const frame_width_ratio = currentSignature.frame_width_ratio + (x_shift / boxWidth) > original_ratio && currentSignature.frame_width_ratio + (x_shift / boxWidth) <= 3 ? currentSignature.frame_width_ratio + (x_shift / boxWidth) : currentSignature.frame_width_ratio
        setTotalSignature(
          totalSignature.map(signature => 
            (signature.index == index ? { ...signature, frame_width_ratio } : signature))
        )
      }
    }

    function onMouseUp() {
      isDown = false
      document.body.removeEventListener("mousemove", onMouseMove)
    }

    document.body.addEventListener("mousemove", onMouseMove)
    document.body.addEventListener("mouseup", onMouseUp, { once: true })
  }

  const getBoxSize = (width, height, target_width_ratio = 3) => {
    const TARGET_WIDTH_RATIO = target_width_ratio
    const current_width_ratio = width / height
    if (current_width_ratio > 1 && current_width_ratio < 3) {
      var height_bounding_box = height
      var width_bounding_box = TARGET_WIDTH_RATIO * height_bounding_box
    }
    else if (current_width_ratio > TARGET_WIDTH_RATIO) {
      var width_bounding_box = TARGET_WIDTH_RATIO * height
      if (width_bounding_box >= width) {
        var height_bounding_box = width / 3
        width_bounding_box = TARGET_WIDTH_RATIO * height
      }
      else {
        var height_bounding_box = width / TARGET_WIDTH_RATIO
        width_bounding_box = width
      }
    }
    
    else if (current_width_ratio <= 1) {
      var width_bounding_box = height * TARGET_WIDTH_RATIO
      var height_bounding_box = height
    }
    else {
      var width_bounding_box = width
      var height_bounding_box = height
    }
    return {
      width: width_bounding_box,
      height: height_bounding_box
    }
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <Row gutter={[24, 3]}>
                    <Col span={24}>
                      <h1 className="text-xl md:text-2xl font-medium">
                        {dataDoc.doc_name}
                      </h1>
                    </Col>
                    <Col>
                      <Row gutter={[6, 24]}>
                        <Col>
                          <SignWithOtherIcon />
                        </Col>
                        <Col>
                          <p className="text-xs text-blue-700">
                            {t("Request Sign to Other")}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                className="bg-blue-700 rounded-md md:w-40 h-10 font-medium tracking-tight"
                type="primary"
                disabled={(signer.length < 1 || openSubmit === false)}
                onClick={() => checkUserCoor()}
              >
                {t("Submit")}
              </Button>
            </Col>
          </Row>
        </Col>
       
        <Col span={24}>
          <Row>
            <Col span={24}>
              <div className="relative">
                <div className="w-full bg-gray-600 rounded-t-md">
                  <Row justify="space-between" align="middle">
                    <Col xs={24} md={8}>
                      <div
                        className={`flex flex-row bg-blue-700 items-center ${!visibleDrawer ? 'justify-start' : 'justify-around'} rounded-t-md md:rounded-none md:rounded-tl-md pl-4 w-full cursor-pointer hover:bg-blue-600 transition duration-500`}
                        onClick={() => setVisibleDrawer(!visibleDrawer)}
                      >
                        <div className={`flex flex-row items-center ${signer.length > 0 ? 'h-14' : ''}`}>
                          <AccountMultiplePlus className="text-sm" />
                          {
                            signer.length > 0 ?
                              <Avatar.Group
                                maxCount={2}
                                size="large"
                                className="ml-2"
                                maxStyle={
                                  {
                                    color: '#f56a00',
                                    backgroundColor: '#fde3cf',
                                    cursor: 'pointer',
                                    height: '32px',
                                    width: '32px',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                              >
                                {
                                  signer.map((item, index) =>
                                    <Avatar key={index} style={{ border: 'none ' }} className="bg-green-600 h-8 w-8 flex items-center" >
                                      {item.signer_email.slice(0, 2)}
                                    </Avatar>
                                  )
                                }
                              </Avatar.Group>
                              :
                              <p className="text-center text-white p-3 text-sm">
                                {t("No other signer")}
                              </p>
                          }
                        </div>

                        {visibleDrawer && <DoubleLeftOutlined style={{ fontSize: 22, color: '#fff' }} />}
                      </div>
                    </Col>
                    <Col xs={24} md={8}>
                      <p className="text-center text-gray-300 text-sm md:text-base">
                        {totalStamp > 0 ?
                          (signer.length > totalStamp ? `${t("You Have Added")} [${signer.length}] ${t("Request Signature!")}` : `${t("You Have Added")} [${totalStamp}] ${t("Signature!")}`) :
                          (signer.length > 0 ? `${t("You Have Added")} [${signer.length}] ${t("Request Signature!")}` : t('Please Add Your Signature'))
                        }
                      </p>
                    </Col>
                    <Col xs={24} md={8}>
                      <Row
                        className="pr-4 pb-2 md:pb-0"
                        gutter={[24, 24]}
                        justify="center"
                        align="middle"
                      >
                        <Col>
                          <div className="flex flex-row bg-blue-400 text-white rounded-full px-2 py-1 justify-center items-center">
                            <SignIcon />
                            <Divider className="h-4 bg-white" type="vertical" />
                            <p className="font-medium">{numberCommas(totalMaterai.total)}</p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div className="flex flex-col space-y-4 justify-end items-end fixed mt-8 right-10 md:right-20 lg:right-32 z-50">
                  {openSign === true ?
                    <div className={`relative rounded-full top-0 ${!done ? 'cursor-pointer' : null}`} onClick={() => setOpenSign(false)}>
                      <img src={CloseIcon} alt="" />
                    </div> : null
                  }
                  <div
                    className="flex flex-col text-white justify-center items-center duration-700   transition"
                    onClick={() => setOpenSign(true)}
                  >
                    <Fade>
                      <div className={`${openSign > 0 || loading ? "hidden" : "flex"} flex-row bg-blue-400 hover:bg-blue-300 rounded-full p-2 cursor-pointer justify-center items-center`}>
                        <img
                          className="transition duration-500 ease-in-out"
                          src={SignSvg}
                          alt="qr-icon"
                          width="35px"
                        />
                      </div>
                    </Fade>
                    <Fade>
                      <div
                        onClick={() => {
                          const lastIndex = getLastArrayValue(totalSignature) ? getLastArrayValue(totalSignature)?.index : 0;
                          return signer.length > 0
                            ? handleAddSignature(lastIndex + 1)
                            : message.info(t('add signer first'))
                        }}
                        style={{ background: '#f4f4f4', minWidth: '100%' }}
                        className={`${openSign ? "flex" : "hidden"} flex-row items-center p-2 rounded-t-lg cursor-pointer`}
                      >
                        <div className="rounded-full bg-blue-400 mr-4 w-8 h-8 flex items-center justify-center">
                          <img
                            className="transition duration-500 ease-in-out"
                            src={SignSvg}
                            alt="qr-icon"
                            width="24px"
                          />
                        </div>
                        <span className="text-blue-400 text-xl font-medium ">{t("Signature")}</span>
                      </div>

                    </Fade>
                    <Divider className="my-0" />
                    <Fade>
                      <div
                        onClick={() => {
                          const lastIndex = getLastArrayValue(totalSignature) ? getLastArrayValue(totalSignature)?.index : 0;
                          return signer.length > 0
                            ? handleAddSignature(lastIndex + 1, true)
                            : message.info(t('add signer first'))
                        }}
                        style={{ background: '#f4f4f4', minWidth: '100%' }}
                        className={`${openSign ? "flex" : "hidden"} flex-row items-center p-2 cursor-pointer`}
                      >
                        <div className="rounded-full bg-blue-400 mr-4 w-8 h-8 flex items-center justify-center">
                          <img
                            className="transition duration-500 ease-in-out"
                            src={SignSvg}
                            alt="qr-icon"
                            width="24px"
                          />
                        </div>
                        <span className="text-blue-400 text-xl font-medium ">{t("Paraf")}</span>
                      </div>
                    </Fade>
                  </div>
                  <Divider style={{ marginTop: 0, marginBottom: 0 }} className="my-0" />
                  <Fade>
                    <div
                      onClick={handleReset}
                      style={{ minWidth: '100%', background: '#f4f4f4', marginTop: 0, marginBottom: 0 }}
                      className={`${openSign > 0 ? "flex" : "hidden"} flex-row items-center p-2 bg-green-500 cursor-pointer `}>
                      <div className="rounded-full bg-red-500 mr-4 w-8 h-8 flex items-center justify-center">
                        <img
                          className="transition duration-500 ease-in-out"
                          src={ResetIcon}
                          alt="qr-icon"
                          width="20px"
                        />
                      </div>
                      <span className="text-red-500 text-xl font-medium">{t("Reset")}</span>
                    </div>
                  </Fade>
                  <Divider style={{ marginTop: 0, marginBottom: 0 }} className="my-0" />
                  <Fade>
                    <div
                      onClick={() => history.push('/sign-management/edit-signature')}
                      style={{ minWidth: '100%', background: '#f4f4f4', marginTop: 0, marginBottom: 0 }}
                      className={`${openSign > 0 ? "flex" : "hidden"} flex-row items-center p-2 rounded-b-lg bg-green-500 cursor-pointer `}>
                      <div className="rounded-full bg-gray-300 mr-4 w-8 h-8 flex items-center justify-center">
                        <img
                          className="transition duration-500 ease-in-out"
                          src={SettingIcon}
                          alt="qr-icon"
                          width="20px"
                        />
                      </div>
                      <span className="text-gray-500 text-sm font-medium">{t("Edit Signature")}</span>
                    </div>
                  </Fade>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="relative">
                <div className="w-full">
                  <Row justify="space-between" align="middle">
                    {visibleDrawer &&
                      <Col span={8}>
                        <div className="bg-gray-100 rounded-b-md overflow-auto custom-scrollbar shadow-md pb-6"
                          style={{ height: "80vh" }}>
                          <div className="p-8 pl-6 pr-4 h-full">
                            <p className="mb-4 text-sm font-normal font-sans">{t("Signing Type")}</p>

                            <Radio.Group onChange={(e) => setSignType(e.target.value)} value={signType}>
                              <Radio style={{ marginRight: '12px', color: '#323331', letterSpacing: 0.5 }} value="pararel" className={signType === 'pararel' ? 'font-medium text-base font-sans' : 'font-normal text-base font-sans '}>
                                {t("Parallel")}
                              </Radio>

                              <Radio style={{ letterSpacing: 0.5 }} value="sequential" className={signType === 'sequential' ? 'font-medium text-base font-sans' : 'font-normal text-base font-sans '}>
                                {t("Sequential")}
                              </Radio>
                            </Radio.Group>

                            <Divider />

                            {signer.length === 0 && <p style={{ color: '#8B8B8B', letterSpacing: '0.5px' }} className="font-sans font-normal text-sm text-center">{t("Please add signer")}</p>}

                            <div style={{ maxHeight: '500px' }} className="overflow-y-scroll relative">

                              <div className="w-full list-drag">
                                {
                                  signer.map((item, index) => (
                                    <div style={{ borderLeft: `4px solid ${item.color}` }} className="card-sign box-border bg-gray-50 rounded-md mb-3">
                                      <div className="card-sign-wrap box-border pt-4 pb-2 pr-4">
                                        <div className="card-sign-header box-border flex flex-row pl-10 justify-between items-center">
                                          <h2 style={{ letterSpacing: 0.5 }} className="text-black text-base font-medium font-sans">{`Signature (${index + 1})`}</h2>
                                          <div>
                                            <Dropdown trigger={['click']} overlay={MenuItem(item.signer_name)}>
                                              <SettingSign className="cursor-pointer" />
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <Divider className="my-2" />
                                        <div className="card-sign-content pl-10 relative">
                                          <img src={Dotted} className="absolute left-3 top-5" />
                                          <div className="card-sign-name w-full truncate overflow-x-hidden mb-2">
                                            <span className="mr-3 text-gray-500 font-normal text-sm tracking-tight">{t("Name")}</span>
                                            <span style={{ color: '#323331' }} className="truncate text-sm font-sans font-normal tracking-tight">{item.signer_name}</span>
                                          </div>
                                          <div className="w-full truncate overflow-x-hidden mb-2">
                                            <span className="mr-3 text-gray-500 text-sm tracking-tight">Email</span>
                                            <span style={{ color: '#323331' }} className="truncate text-sm font-sans font-normal tracking-tight">{item.signer_email}</span>
                                          </div>
                                          <div className="w-full truncate overflow-x-hidden mb-2">
                                            <span className="mr-3 text-gray-500 text-sm tracking-tight">{t("Note")}</span>
                                            <span style={{ color: '#323331' }} className="truncate text-sm font-sans font-normal tracking-tight">{item.sender_note}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <ButtonPrimary
                              onClick={() => setSignModal(!signModal)}
                              className="w-4/5 absolute bottom-8 translate-x-2 h-10"
                              icon={<PlusOutlined />}>
                              <span>{t("Add Signer")}</span>
                            </ButtonPrimary>
                          </div>
                        </div>
                      </Col>
                    }
                    <Col span={visibleDrawer ? 16 : 24}>
                      <div
                        className="overflow-auto custom-scrollbar pb-6"
                        style={{ height: "80vh" }}
                      >
                        <Row className='flex p-8 justify-center' gutter={16}>
                          <Col span={24} className="flex justify-center">
                            <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                              <Page pageNumber={pageNumber}>
                                {totalSignature
                                  .filter(item => item.page === pageNumber)
                                  .map((item, index) => {
                                    return (
                                      <Draggable
                                        key={item.index}
                                        position={deltaPosition[item.index]}
                                        onStop={(e, position) => handleDrag(e, position, item.index, item.paraf === "PARAF" ? true : false, item.signer_name)}
                                        bounds="parent"
                                      >
                                        <div
                                          className={clsx(
                                            "absolute",
                                            moveable ? "cursor-move" : "cursor-pointer"
                                          )}
                                          // style={{ width: item.width, height: item.height }}
                                          style={getBoxSize(item.width, item.height, item.frame_width_ratio)}
                                          onDoubleClick={handleMovable}
                                        >
                                          <div style={{ background: item.rgb, fontSize: 10 }} className="absolute top-0 inset-x-0 mb-1 overflow-hidden rounded inline">
                                            <span className="text-white px-1 truncate w-full whitespace-nowrap overflow-hidden">
                                              {item.paraf === "PARAF" ? "Paraf" : "Signature"} ({index + 1}) {item.signer_name}
                                            </span>
                                          </div>
                                          <div
                                            className="absolute -top-3 -right-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                            onClick={() => handleCloseSignature(item.index, item.signer_name, item.paraf === "PARAF" ? true : false)}
                                          >
                                            <CloseOutlined className="font-medium" />
                                          </div>
                                          <div style={{ WebkitUserDrag: "none", height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <img style={{ height: item.height, width: item.width }} className="drag-none" src={Unstamp} alt="" />
                                          </div>
                                          {/* <button
                                            className="absolute -bottom-3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                            type="button" onMouseDown={(onMouseDown) => handler(onMouseDown, item.index)}>
                                            <ArrowsAltOutlined className="font-medium" />
                                          </button> */}
                                          <button 
                                            className="absolute -top-3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                            type="button" onMouseDown={(onMouseDown) => handler2(onMouseDown, item.index)}>
                                              <ArrowsAltOutlined style = {{transform: 'rotate(90deg)' }} className="font-medium" />
                                          </button>
                                          <button 
                                            className="absolute top-1/3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                            type="button" onMouseDown={(onMouseDown) => handler3(onMouseDown, item.index)}>
                                              <ArrowsAltOutlined style = {{transform: 'rotate(45deg)' }} className="font-medium" />
                                          </button>
                                          <button 
                                            className="absolute -bottom-3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                            type="button" onMouseDown={(onMouseDown) => handler0(onMouseDown, item.index)}>
                                              <ArrowsAltOutlined className="font-medium" />
                                          </button>
                                          <button 
                                            className="absolute -bottom-3 -right-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                            type="button" onMouseDown={(onMouseDown) => handler1(onMouseDown, item.index)}>
                                              <ArrowsAltOutlined style = {{transform: 'rotate(90deg)' }} className="font-medium" />
                                          </button>
                                        </div>
                                      </Draggable>
                                    );
                                  })
                                }
                              </Page>
                            </Document>
                          </Col>
                          <div className="flex flex-row justify-center items-center space-x-2 bg-gray-100 shadow-md absolute bottom-5 right-5 p-2 rounded-md">
                            <Select
                              value={pageNumber}
                              size="small"
                              onChange={handleChangePage}
                            >
                              {Array.from(new Array(numPages), (_, index) => (
                                <Option value={index + 1}>{index + 1}</Option>
                              ))}
                            </Select>
                            <p>{`dari ${numPages} halaman`}</p>
                          </div>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalAntd
        visible={signModal}
        footer={false}
        onCancel={() => {
          setSignModal(!signModal)
          setOpen(false)
          setOpen2(false)
        }}
        width={500}
      >
        <Row>
          <Col span={24} className="p-4" onClick={() => {
            setOpen(false)
            setOpen2(false)
          }}>
            <h2 style={{ color: '#5C5F5B' }} className="text-2xl mb-8 font-medium">{t("Add Signer")}</h2>
            <Row>
              <Col span={24} className="mb-8">
                <Row>
                  <Col span={24} className="mb-2"> <span className="text-sm font-normal font-sans tracking-tight">{t("Signer Name")}*</span></Col>
                  <Col span={24}>
                    <div className={'w-full'}>
                      <input placeholder={t("Enter Name")} value={user.signer_name} style={{ outline: '1px solid #8080807a' }} className='w-full px-4 py-1.5 h-7' onChange={e => handleChange(e.target.value)} />
                      {
                        data && open &&
                        <div style={{ background: '#e5e5e5', minHeight: 170 }} className='max-h-52 overflow-y-scroll no-scrollbar relative z-10'>
                          {data.map((item, index) => {
                            return <p onClick={() => handleSelectOption(item)} className='px-2 py-2 tracking-tight font-medium z-10 text-gray-500 cursor-pointer hover:bg-white'>{item.full_name}</p>
                          })}
                        </div>
                      }
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="mb-8">
                <Row>
                  <Col span={24} className="mb-2"> <span className="text-sm font-normal font-sans tracking-tight">{t("Email Address for Signer")}*</span></Col>
                  <Col span={24}>
                    <div className={'w-full'}>
                      <input placeholder={t("Enter Email")} value={user.signer_email} style={{ outline: '1px solid #8080807a' }} className='w-full px-4 py-1.5 h-7' onChange={e => handleChange(e.target.value, "email")} />
                      {
                        data && open2 &&
                        <div style={{ background: '#e5e5e5', minHeight: 170 }} className='max-h-52 overflow-y-scroll no-scrollbar relative z-10'>
                          {data.map((item, index) => {
                            return <p onClick={() => handleSelectOption(item, true)} className='px-2 py-2 tracking-tight font-medium z-10 text-gray-500 cursor-pointer hover:bg-white'>{item.email}</p>
                          })}
                        </div>
                      }
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="mb-24">
                <Row>
                  <Col span={24} className="mb-2"> <span className="text-sm font-normal font-sans tracking-tight">{t("Note")} <span className="text-gray-400y">({t("optional")})</span> </span></Col>
                  <Col span={24}>
                    <TextArea
                      rows={6}
                      placeholder={t("Enter Note")}
                      value={user.sender_note}
                      onChange={(e) => handleChange(e.target.value, "note")}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="mb-8">
                <Row gutter={[20, 10]}>
                  <Col span={12} className="mb-2">
                    <Button onClick={() => setSignModal(false)} style={{ outlineColor: 'gray' }} className=" w-full h-10 outline outline-gray-500 font-medium tracking-tight" ghost>
                      {t("Cancel")}
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button onClick={handleAddSign} type="primary" className="w-full h-10 font-medium tracking-tight">
                      {t("Assign")}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalAntd>

      <ModalAntd
        visible={signModalEdit.open}
        footer={false}
        onCancel={() => setSignModalEdit({ open: false, email: '' })}
        width={500}
      >
        <Row>
          <Col span={24} className="p-4" onClick={() => {
            setOpen(false)
            setOpen2(false)
          }}>
            <h2 style={{ color: '#5C5F5B' }} className="text-2xl mb-8 font-medium">{t("Edit Signer")} </h2>
            <Row>
              <Col span={24} className="mb-8">
                <Row>
                  <Col span={24} className="mb-2"> <span className="text-sm font-normal font-sans tracking-tight">{t("Signer Name")}*</span></Col>
                  <Col span={24}>
                    <div className={'w-full'}>
                      <input placeholder={t("Enter Name")} value={user.signer_name} style={{ outline: '1px solid #8080807a' }} className='w-full px-4 py-1.5 h-7' onChange={e => handleChange(e.target.value)} />
                      {
                        data && open &&
                        <div style={{ background: '#e5e5e5', minHeight: 170 }} className='max-h-52 overflow-y-scroll no-scrollbar relative z-10'>
                          {data.map((item, index) => {
                            return <p onClick={() => handleSelectOption(item)} className='px-2 py-2 tracking-tight font-medium z-10 text-gray-500 cursor-pointer hover:bg-white'>{item.full_name}</p>
                          })}
                        </div>
                      }
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="mb-8">
                <Row>
                  <Col span={24} className="mb-2"> <span className="text-sm font-normal font-sans tracking-tight">{t("Email Address for Signer")}*</span></Col>
                  <Col span={24}>
                    <div className={'w-full'}>
                      <input placeholder={t("Enter Email")} value={user.signer_email} style={{ outline: '1px solid #8080807a' }} className='w-full px-4 py-1.5 h-7' onChange={e => handleChange(e.target.value, "email")} />
                      {
                        data && open2 &&
                        <div style={{ background: '#e5e5e5', minHeight: 170 }} className='max-h-52 overflow-y-scroll no-scrollbar relative z-10'>
                          {data.map((item, index) => {
                            return <p onClick={() => handleSelectOption(item, true)} className='px-2 py-2 tracking-tight font-medium z-10 text-gray-500 cursor-pointer hover:bg-white'>{item.email}</p>
                          })}
                        </div>
                      }
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="mb-24">
                <Row>
                  <Col span={24} className="mb-2"> <span className="text-sm font-normal font-sans tracking-tight">{t("Note")} <span className="text-gray-400y">({t("optional")})</span> </span></Col>
                  <Col span={24}>
                    <TextArea
                      rows={6}
                      placeholder={t("Enter Note")}
                      value={user.sender_note}
                      onChange={(e) => handleChange(e.target.value, "note")}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="mb-8">
                <Row gutter={[20, 10]}>
                  <Col span={12} className="mb-2">
                    <Button onClick={() => setSignModalEdit(false)} style={{ outlineColor: 'gray' }} className=" w-full h-10 outline outline-gray-500 font-medium tracking-tight" ghost>
                      {t("Cancel")}
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button onClick={handleUpdateSign} type="primary" className="w-full h-10 font-medium tracking-tight">
                      {t("Update")}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

      </ModalAntd>

      <Modal
        type="warning"
        visible={previewModal}
        onCancel={() => {
          setPreview(false)
        }}
        title={t("Are you sure?")}
        subtitle={t(`After the data has been submit, you cannot change the data. Please make sure the data is correct.`)}
      >

        <>
          <div style={{ marginTop: 40 }} className="w-full mt-10">
            <div className="flex justify-between mb-5">
              <span className="text-gray-500 ">
                {t("Document Name")}
              </span>
              <span className="font-medium">
                {dataDoc.doc_name}
              </span>
            </div>
            <div className="flex justify-between mb-5">
              <span className="text-gray-500">
                {t("Sign Category")}
              </span>
              <span className="font-medium">
                {t("Request Sign to Other")}
              </span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="text-gray-500">
                {t("Signing Type")}
              </span>
              <span className="font-medium">
                {t(signType)}
              </span>
            </div>
            <Row>
              <Col span={24}>
                <span className="text-gray-500 tracking-tight mb-2">
                  {t("Note")}
                </span>

                <TextArea showCount maxLength={100} className="mt-2" onChange={(data) => setReason(data.target.value)} disabled={isSubmit} name="note" rows={4} />

              </Col>
            </Row>
            {/* <Divider className="mt-2 mb-4" /> */}

            <Row style={{ marginTop: 24 }} gutter={[12, 0]}>
              <Col span={12}>
                <Button
                  ghost
                  onClick={() => setPreview(!previewModal)}
                  className=" w-full h-10 font-medium tracking-tight"
                  disabled={isSubmit}
                >
                  <span>{t("Back")}</span>
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="w-full h-10 font-medium tracking-tight"
                  type="primary"
                  onClick={() => handlePreviewOtp(dataDoc.doc_id)}
                  disabled={isSubmit}
                >
                  <span>{t("Next")}</span>
                </Button>
              </Col>
            </Row>
          </div>
          <Spinner loading={isSubmit} />
        </>
      </Modal>
      <Modal
        visible={successModal}
        title={t("Success")}
        subtitle={t("The document has been send to other! Now other can sign with this document")}
        onCancel={() => setsuccessModal(false)}
        type="success"
      >
        <div className="flex flex-row space-x-4 gap-3">

          <Button
            type="primary"
            className="w-full h-11"
            onClick={() => history.replace('/sign-management')}
          >
            {t("Done")}
          </Button>
        </div>

      </Modal>

      <Modal
        visible={activeLocation}
        type="warning"
        title={t("Confirmation Location")}
        subtitle={t("Please allow your location to sign document")}
        onCancel={() => setActiveLocation(false)}
      >
        <div className="flex justify-center">
          <Button onClick={() => setActiveLocation(false)} ghost type="primary" className="mt-9 mb-9 h-10 rounded-md w-52 tracking-tight font-medium mr-4">{t("No")}</Button>
          <Button onClick={() => getLocation()} type="primary" className="mt-9 mb-9 h-10 rounded-md w-52 tracking-tight font-medium">{t("Yes")}</Button>
        </div>
      </Modal>

      <ModalAntd
        footer={false}
        visible={isLocationBlocked}
        width={1050}
        onCancel={() => setIsLocationBlocked(false)}
      >
        <div className='mb-6 pt-2 pb-2'>
          <div className="mb-6">
            <h4 className="text-xl font-semibold text-gray-600 mb-5">{t("Location Not Found")}</h4>
            <hr />
          </div>
          <p className='font-medium text-base text-left mt-2 text-gray-600'>{t("Your location was not found. You need to enable GPS permission to be able to Stamp documents. You can do some of the steps below. !")}</p>
          <div onClick={() => setGuideModal(prev => ({ ...prev, guide1: !prev.guide1 }))} className='mt-5 cursor-pointer'>
            <div className="flex justify-between items-center">
              <p className='text-xl font-bold'>Access Google Chrome</p>
              <img width={18} height={15} src={guideModal.guide1 === true ? arrowUp : arrowBtm} alt="" />
            </div>
            {
              guideModal.guide1 &&
              <div className='mt-2'>
                <p className='text-black font-medium text-base'>{t("1. Logo location on the searchbar")}</p>
                <p className='text-black font-medium text-base'>{t("2. Change the option to “Allow this site access your location”")}</p>
                <div className="mt-2 flex items-center">
                  <img src={google1} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={google2} alt="" />
                </div>
              </div>
            }
            <hr className="mt-4 h-1" />
          </div>
          <div onClick={() => setGuideModal(prev => ({ ...prev, guide2: !prev.guide2 }))} className='mt-2 cursor-pointer'>
            <div className="flex justify-between items-center">
              <p className='text-xl font-bold'>{t("Access With Safari")}</p>
              <img width={18} height={15} src={guideModal.guide2 === true ? arrowUp : arrowBtm} alt="" />
            </div>
            {
              guideModal.guide2 &&
              <div className='mt-2'>
                <p className='text-black font-medium text-base'>{t("1. Safari menu")}</p>
                <p className='text-black font-medium text-base'>{t("2. Settings for [domain name, Co: demo.vas.id]")}</p>
                <p className='text-black font-medium text-base'>{t("3. Deactivate the “Enable content blockers” checkbox")}</p>
                <div className="mt-2 flex items-center">
                  <img width={225} height={215} src={safari1} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={safari2} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={safari3} alt="" />
                </div>
              </div>
            }
            <hr className="mt-4 h-1" />
          </div>
          <div onClick={() => setGuideModal(prev => ({ ...prev, guide3: !prev.guide3 }))} className='mt-2 cursor-pointer'>
            <div className="flex justify-between items-center">
              <p className='text-xl font-bold'>{t("Access With Firefox")}</p>
              <img width={18} height={15} src={guideModal.guide3 === true ? arrowUp : arrowBtm} alt="" />
            </div>
            {
              guideModal.guide3 &&
              <div className='mt-2'>
                <p className='text-black font-medium text-base'>{t("1. Logo location on the searchbar")} </p>
                <p className='text-black font-medium text-base'>{t("2. Delete the option “Block temporarily”")}</p>
                <div className="mt-2 flex items-center">
                  <img src={firefox1} alt="" />
                  <img width={20} height={20} src={arrowRight} className="mx-4" alt="" />
                  <img src={firefox2} alt="" />
                </div>
              </div>
            }
          </div>
        </div>
        <div className="w-full">
          <hr className='mb-4' />
          <div className="flex justify-center">
            <Button
              className="bg-blue-700 rounded-md text-white w-40"
              onClick={() => setIsLocationBlocked(false)}
            >
              {t("Close")}
            </Button>
          </div>
        </div>
      </ModalAntd>
      <Spinner loading={loading} />
    </>
  );
}
