import {
  AddClient,
  AddDocument,
  AddGroup,
  AddOffice,
  AddProduct,
  AddUser,
  AddUserBilling,
  BillingDetail,
  ClientDetail,
  ClientManagement,
  CreateSignature,
  EditSignature,
  DepartmentDetail,
  DocumentDetail,
  DocumentDetailReceived,
  DocumentDetailSent,
  DocumentManagement,
  DocumentMessage,
  DocumentPreview,
  EditClient,
  EditGroup,
  EditModule,
  EditOffice,
  EditProduct,
  EditProfile,
  AddQuotation,
  AddQuotationSelf,
  EditTag,
  EditUser,
  EditUserBilling,
  Group,
  GroupDetail,
  ModuleManagement,
  Office,
  OfficeDetail,
  OverviewFull,
  DetailUsageActivities,
  OverviewBasic,
  OverviewAdmin,
  PanelAdmin,
  ProductDetail,
  Profile,
  QoutationDetail,
  QoutationManagement,
  Registration,
  RequestSign,
  SingleStamp,
  SendDocument,
  StampManagement,
  SignWithOther,
  TopUp,
  User,
  UserBilling,
  AddDocumentBulk,
  DocumentVersion,
  BulkStamp,
  DocumentDetailBulk,
  Notifications,
  SignManagement,
  Sign,
  SelfSign,
  SelfSignBulk,
  UploadDocSign,
  SignatureDetail,
  SignatureDetailBulk,
  CoopAgreementDetail,
  CoopAgreementExtend,
  CoopAgreementCreateNew,
  AddDepartment,
  EditDepartment,
  RegistrationKYC,
  MonitoringProduct,
  MonitoringProductDefectDetail,
  DepartementOffice,
  Rekonsiliasi,
  RekonsileList,
  Rekapitulasi,
  RekapitulasiDetail,
  RekonsiliasiDetail,
  HistorySettlementOffice,
  DetailHistorySettlement,
  TeraManagement,
  BulkSign,
  SignWithOtherBulk,
  NotFound,
  ConfigManagement
} from "../../pages";
import CompletionMonitor from "../../pages/CompletionMonitor";
import MonitoringDepartemenDefectDetail from "../../pages/MonitoringDepartemenDefectDetail";
import ProtectedRoute from "./ProtectedRoute";
const routes = (profile) => {
  const isBasicAccess = profile?.role_id === 1;
  const isAllDocumentAccess = profile?.role_id === 2;
  const isFullAccess = profile?.role_id === 3;
  const isSuperAdmin = profile?.role_id === 4;
  const isAdminBilling = profile?.role_id === 5;
  const isBranch = profile?.office_type_id === 2;
  const isPic = profile?.is_pic;

  return [
    {
      path: "/profile",
      component: Profile, // translate done
      exact: false,
      disable: false,
    },
    {
      path: '/config-management',
      component: ConfigManagement,
      exact: false,
      disable: !isSuperAdmin && !isAdminBilling
    },
    {
      path: "/profile/edit",
      component: EditProfile,
      exact: false,
      disable: false,
    },
    {
      path: "/",
      component: isFullAccess ? OverviewFull : OverviewBasic, // translate done
      exact: true,
      disable: isSuperAdmin || isAdminBilling,
    },
    {
      path: '/usage-activity/:office_id/:period',
      component: DetailUsageActivities, // translate done
      exact: true,
      disable: isSuperAdmin || isAdminBilling,
    },
    {
      path: "/document-management",
      component: DocumentManagement, // translate done
      exact: true,
      disable: isSuperAdmin,
    },
    {
      path: "/document-management/detail/:id",
      component: DocumentDetail, // translate done
      exact: true,
      disable: isSuperAdmin,
    },
    {
      path: "/document-management/detail-bulk/:id",
      component: DocumentDetailBulk, // translate done
      exact: true,
      disable: isSuperAdmin,
    },
    {
      path: "/document-management/detail-bulk/:id/version",
      component: DocumentVersion, // translate done
      exact: true,
      disable: isSuperAdmin,
    },
    {
      path: "/document-management/edit/:id",
      component: EditTag, // translate done
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/document-management/add",
      component: AddDocument, // translate done
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/document-management/add-bulk",
      component: AddDocumentBulk, // translate done
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/office",
      component: Office, // translate done
      exact: true,
      disable: isBasicAccess || isAllDocumentAccess || isSuperAdmin,
    },
    {
      path: "/office/add",
      component: AddOffice, // translate done
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isSuperAdmin,
    },
    {
      path: "/office/edit/:id",
      component: EditOffice, // translate done
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isSuperAdmin,
    },
    {
      path: "/office/detail/:id",
      component: OfficeDetail, // translate done
      exact: false,
      disable:
        isBasicAccess ||
        isAllDocumentAccess ||
        isSuperAdmin
    },
    {
      path: "/office/department/add",
      component: AddDepartment, // translate done
      exact: false,
      disable:
        isBasicAccess ||
        isAllDocumentAccess ||
        isSuperAdmin
    },
    {
      path: "/office/department/edit/:id",
      component: EditDepartment, // translate done
      exact: false,
      disable:
        isBasicAccess ||
        isAllDocumentAccess ||
        isSuperAdmin
    },
    {
      path: "/user",
      component: User, // translate done
      exact: true,
      disable: isBasicAccess || isAllDocumentAccess || isSuperAdmin,
    },
    {
      path: "/user/add",
      component: AddUser, // translate done
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isSuperAdmin,
    },
    {
      path: "/user/edit/:id",
      component: EditUser, // translate done
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isSuperAdmin,
    },
    {
      path: "/stamp-management",
      component: StampManagement, // translate done
      exact: true,
      disable: isSuperAdmin,
    },
    {
      path: "/stamp-management/detail/:id",
      component: DocumentDetail, // translate done
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/stamp-management/detail-bulk/:id",
      component: DocumentDetailBulk, // translate done
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/stamp-management/single-stamp/:id",
      component: SingleStamp, // translate done
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/stamp-management/bulk-stamp/:id",
      component: BulkStamp, // translate done
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/sign-management",
      component: SignManagement,
      exact: true,
      disable: isSuperAdmin,
    },
    {
      path: "/tera-management",
      component: TeraManagement,
      exact: true,
      disable: isSuperAdmin,
    },
    {
      path: "/sign-management/sign",
      component: Sign,
      exact: true,
      disable: isSuperAdmin,
    },
    {
      path: "/sign-management/sign-bulk",
      component: BulkSign,
      exact: true,
      disable: isSuperAdmin
    },
    {
      path: "/sign-management/create-signature",
      component: CreateSignature,
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/sign-management/edit-signature",
      component: EditSignature,
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/sign-management/document-preview",
      component: DocumentPreview,
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/sign-management/self-sign",
      component: SelfSign,
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/sign-management/self-sign-bulk/:id",
      component: SelfSignBulk,
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/sign-management/sign-with-other",
      component: SignWithOther,
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/sign-management/sign-with-other-bulk",
      component: SignWithOtherBulk,
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/sign-management/request-sign",
      component: RequestSign,
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: '/sign-management/upload-document',
      component: UploadDocSign,
      exact: false,
      disable: isSuperAdmin
    },
    {
      path: '/sign-management/sign-detail/:id',
      component: SignatureDetail,
      exact: false,
      disable: isSuperAdmin
    },
    {
      path: "/sign-management/sign-detail-bulk/:id",
      component: SignatureDetailBulk,
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: '/registrationKYC',
      component: RegistrationKYC,
      exact: true,
    },
    {
      path: "/document-message",
      component: DocumentMessage,
      exact: true,
      disable: isSuperAdmin,
    },
    {
      path: "/document-message/send",
      component: SendDocument,
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/document-message/received/:id",
      component: DocumentDetailReceived,
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/document-message/sent/:id",
      component: DocumentDetailSent,
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/group",
      component: Group,
      exact: true,
      disable: isSuperAdmin,
    },
    {
      path: "/group/detail/:id",
      component: GroupDetail,
      exact: true,
      disable: isSuperAdmin,
    },
    {
      path: "/group/add",
      component: AddGroup,
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/group/edit/:id",
      component: EditGroup,
      exact: false,
      disable: isSuperAdmin,
    },
    {
      path: "/panel-admin",
      component: PanelAdmin,
      exact: true,
      disable:
        isBasicAccess ||
        isAllDocumentAccess ||
        isBranch ||
        isSuperAdmin ||
        !isPic,
    },
    {
      path: "/panel-admin/top-up",
      component: TopUp,
      exact: false,
      disable:
        isBasicAccess ||
        isAllDocumentAccess ||
        isBranch ||
        isSuperAdmin ||
        !isPic,
    },
    {
      path: "/panel-admin/department-office/:id",
      component: DepartementOffice,
      exact: false,
      disable:
        isBasicAccess ||
        isAllDocumentAccess ||
        isBranch ||
        isSuperAdmin ||
        !isPic,
    },
    {
      path: "/panel-admin/department-detail/:id",
      component: DepartmentDetail,
      exact: false,
      disable:
        isBasicAccess ||
        isAllDocumentAccess ||
        isBranch ||
        isSuperAdmin ||
        !isPic,
    },
    {
      path: "/module-management",
      component: ModuleManagement,
      exact: true,
      disable:
        isBasicAccess ||
        isAllDocumentAccess ||
        isBranch ||
        isSuperAdmin ||
        !isPic,
    },
    {
      path: "/module-management/edit",
      component: EditModule,
      exact: false,
      disable:
        isBasicAccess ||
        isAllDocumentAccess ||
        isBranch ||
        isSuperAdmin ||
        !isPic,
    },
    {
      path: "/overview",
      component: OverviewAdmin,
      exact: true,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/overview/billing-detail/:id",
      component: BillingDetail,
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/user-management",
      component: UserBilling,
      exact: true,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/user-management/add",
      component: AddUserBilling,
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/user-management/edit/:id",
      component: EditUserBilling,
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/client-management",
      component: ClientManagement,
      exact: true,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/client-management/detail/:id",
      component: ClientDetail,
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/client-management/add",
      component: AddClient,
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/client-management/edit-module/:id",
      component: EditModule,
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/client-management/edit-client/:id",
      component: EditClient,
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/quotation-management",
      component: QoutationManagement,
      exact: true,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/quotation-management/product-detail/:id",
      component: ProductDetail,
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/quotation-management/add-product",
      component: AddProduct,
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/quotation-management/edit-product/:id",
      component: EditProduct,
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/quotation-management/quotation-detail/:id",
      component: QoutationDetail,
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/quotation-management/add-quotation/:id",
      component: AddQuotation,
      exact: false,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/po-management/create-po/:id",
      component: AddQuotationSelf,
      exact: false,
      disable:
        isBasicAccess ||
        isAllDocumentAccess ||
        isBranch ||
        isSuperAdmin ||
        !isPic,
    },
    {
      path: "/monitoring-product",
      component: MonitoringProduct,
      exact: true,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/monitoring-product/completion",
      component: CompletionMonitor,
      exact: true,
      disable: !isSuperAdmin
    },
    {
      path: "/history-settlement-office/:id",
      component: HistorySettlementOffice,
      exact: true,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/detail-history-settlement/:id",
      component: DetailHistorySettlement,
      exact: true,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/monitoring-product/defect/:id",
      component: MonitoringProductDefectDetail,
      exact: true,
      disable: isBasicAccess || isAllDocumentAccess || isFullAccess,
    },
    {
      path: "/monitoring-departemen/defect/:id",
      component: MonitoringDepartemenDefectDetail,
      exact: true,
      disable: false,
    },
    {
      path: "/registration",
      component: Registration,
      exact: true,
      disable: isBasicAccess || isAllDocumentAccess || isBranch || isSuperAdmin,
    },
    {
      path: "/notification-list",
      component: Notifications,
      exact: true,
      disable: false,
    },
    {
      path: "/cooperation-agreement/detail/:id",
      component: CoopAgreementDetail,
      exact: true,
      disable: false,
    },
    {
      path: "/cooperation-agreement/extend/:id",
      component: CoopAgreementExtend,
      exact: true,
      disable: false,
    },
    {
      path: "/cooperation-agreement/new/:id",
      component: CoopAgreementCreateNew,
      exact: true,
      disable: false,
    },
    // Persiapan UAT & SIT Overview Platform VAS BJT
    // {
    //   path: "/Rekonsiliasi",
    //   component: Rekonsiliasi,
    //   exact: true,
    //   disable: isSuperAdmin,
    // },
    // Persiapan UAT & SIT Overview Platform VAS BJT
    {
      path: "/Rekonsiliasi",
      component: Rekonsiliasi,
      exact: true,
      disable: isSuperAdmin || isBranch || isBasicAccess,
    },
    {
      path: "/Rekonsiliasi/detail/:id",
      component: RekonsiliasiDetail,
      exact: true,
      disable: isSuperAdmin
    },
    {
      path: "/Rekonsiliasi-list",
      component: RekonsileList,
      exact: true,
      disable: isSuperAdmin,
    },
    {
      path: "/Rekapitulasi",
      component: Rekapitulasi,
      exact: true,
      disable: isSuperAdmin
    },
    {
      path: '/Rekapitulasi/detail/:id/:monthDate',
      component: RekapitulasiDetail,
      exact: true,
      disable: isSuperAdmin
    },
    {
      path: '/Rekapitulasi/detail/:id/:dept_id/:monthDate',
      component: RekapitulasiDetail,
      exact: true,
      disable: isSuperAdmin || (isBranch && isBasicAccess)
    }
  ];
};

export { routes, ProtectedRoute };
