import {
  DownOutlined,
  ExclamationCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import {
  Col,
  Divider,
  Dropdown,
  Layout,
  Menu,
  message,
  Modal,
  Row,
} from "antd";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { menus, routes } from "../../config";
import { useFetchListModuleQuery } from "../../config/features/general";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { useFetchNotificationDropdownQuery, useNotifIsReadDropdownMutation } from "../../config/features/notification";
import { NotificationDropdown } from "../../components";
import { timeSince } from "../../utils";
import { useTranslation } from "react-i18next";
import { Switch } from "antd";
import SwitchSelector from "react-switch-selector";
import { NotFound } from "../../pages";
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;
const { confirm } = Modal;

export default function Dashboard({ children }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    changeLanguage("id");
  }, [])

  const options = [
    {
      label: "ID",
      value: 'id',
      selectedBackgroundColor: "#fbc531",
    },
    {
      label: "EN",
      value: "en",
      selectedBackgroundColor: "#fbc531"
    }
  ];

  const onClick = (onChange) => {
    //console.log(onChange);
  };

  const initialSelectedIndex = options.findIndex(({ value }) => value === "id");

  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const { isFetching, isError, data: userProfile = {} } = useFetchProfileUserQuery();

  // useEffect(() => {
  //   if ((!isFetching && !userProfile?.user_id) || isError) {
  //     handleLogOut()
  //   }
  // }, [isFetching, userProfile, isError])

  const { data: dataModule = {} } = useFetchListModuleQuery(
    userProfile?.office_id
  );

  const notificationParams = {
    profile: userProfile,
  }
  const { data: dataNotifications } = useFetchNotificationDropdownQuery(notificationParams)
  const [notifIsReadDropdown] = useNotifIsReadDropdownMutation();

  useEffect(() => {
    async function validation() {
      try {
        const isSuperAdmin = (await userProfile?.role_id) === 4;
        const isNewUser = await userProfile?.is_first;
        if (isNewUser && !isSuperAdmin && location?.pathname !== "/profile") {
          history.push("/profile");
        }
      } catch (error) {
        message.error(error.message);
      }
    }
    validation();
  }, [location, userProfile, history]);


  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleNotifDropdownClick = () => {
    setTimeout(() => {
      notifIsReadDropdown(notificationParams)
    }, 2000)
  }

  const handleLogOut = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("peruri");
    localStorage.clear();
    window.location.pathname = "/login";
  };


  function showConfirm() {
    confirm({
      title: "Apakah anda yakin ingin keluar ?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleLogOut();
      },
    });
  }

  const menuDropdown = (
    <Menu>
      <Menu.Item onClick={() => history.push("/profile")}>{t("Profile")}</Menu.Item>
      <Menu.Item danger onClick={showConfirm}>
        {t("Log Out")}
      </Menu.Item>
    </Menu>
  );

  const styles = {
    sider: {
      overflow: "auto",
      height: "100vh",
      position: "fixed",
      left: 0,
    },
    content: {
      padding: location.pathname === "/registration" ? 40 : 20,
      minHeight: "100vh",
      paddingTop: 100,
    },
  };
  return (
    <>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={styles.sider}
        >
          <div className="logo mt-6" />
          <Menu theme="dark" mode="inline">
            {menus(userProfile, dataModule).map((item, index) => {
              return !item?.hidden && item?.subMenu ? (
                <SubMenu
                  key={index}
                  icon={item?.icon}
                  title={item?.title}
                  disabled={item?.disabled}
                >
                  {item?.subMenu?.map((subMenuItem, subMenuIndex) => {
                    return (
                      !subMenuItem?.hidden && (
                        <Menu.Item
                          key={`${index}-${subMenuIndex}`}
                          onClick={() => history.push(subMenuItem?.path)}
                          icon={
                            !collapsed && (
                              <React.Fragment>
                                {subMenuItem?.icon}
                              </React.Fragment>
                            )
                          }
                          disabled={subMenuItem?.disabled}
                        >
                          <React.Fragment>{subMenuItem?.title}</React.Fragment>
                        </Menu.Item>
                      )
                    );
                  })}
                </SubMenu>
              ) : (
                !item?.hidden && (
                  <Menu.Item
                    key={index}
                    icon={item.icon}
                    onClick={() => history.push(item.path)}
                    disabled={item?.disabled}
                  >
                    {item.title}
                  </Menu.Item>
                )
              );
            })}
          </Menu>
        </Sider>
        <Layout
          className={clsx(
            "site-layout duration-500 transition-width transition-slowest ease",
            {
              "ml-200": !collapsed,
              "ml-80": collapsed,
            }
          )}
        >
          <Header
            className={clsx(
              "site-layout-background pr-5 pl-1 fixed z-50 shadow-md duration-500 transition-width transition-slowest ease",
              {
                "w-100-200": !collapsed,
                "w-200-80": collapsed,
              }
            )}
          >
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[12, 24]} align="middle">
                  <Col>
                  </Col>
                  <Col>
                    <img
                      className="w-16 md:w-20"
                      src="/images/logo-primary.png"
                      alt="dli-logo"
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row gutter={[12, 12]}>
                  <Col>
                    <NotificationDropdown onClick={handleNotifDropdownClick} isNewNotification={dataNotifications?.unread_total}>
                      {dataNotifications?.data?.map((notif) => {
                        return (
                          <NotificationDropdown.Item
                            key={`notifDropdownItem-${notif.id}`}
                            title={notif?.title}
                            message={notif?.message}
                            date={timeSince(new Date(notif?.created_at))}
                            isRead={notif?.is_read}
                            onClick={() => history.push(notif?.endpoint)}
                          />
                        )
                      })}
                    </NotificationDropdown>
                  </Col>
                  <Col>
                    <Divider className="h-8" type="vertical" />
                  </Col>
                  <Col>
                    <div className="flex flex-row space-x-3 justify-center items-center p-2 mt-2 rounded-full">
                      <Dropdown
                        overlay={menuDropdown}
                        trigger={["click"]}
                        placement="bottomRight"
                        arrow={{ pointAtCenter: true }}
                      >
                        <img
                          className="h-8 w-8 rounded-full cursor-pointer md:hidden"
                          src="/images/account-circle.png"
                          alt="profile-user"
                        />
                      </Dropdown>
                      <img
                        className="h-8 w-8 rounded-full hidden md:block"
                        src="/images/account-circle.png"
                        alt="profile-user"
                      />
                      <p className="text-gray-500 font-medium text-sm hidden md:block">
                        {userProfile?.full_name}
                      </p>
                      <Dropdown overlay={menuDropdown}>
                        <DownOutlined className="text-xs text-gray-300 font-light cursor-pointer hover:text-gray-800 transition duration-500 hidden md:block" />
                      </Dropdown>

                      <Col>
                        <div className="your-required-wrapper" style={{ width: 80, height: 30, paddingTop: 5, paddingRight: 2 }}>
                          <SwitchSelector
                            onChange={changeLanguage}
                            options={options}
                            initialSelectedIndex={initialSelectedIndex}
                            backgroundColor={"#0741B5"}
                            fontColor={"#f5f6fa"}
                          />
                        </div>
                      </Col>
                    </div>

                  </Col>

                </Row>
              </Col>
            </Row>
          </Header>
          <Content
            // className="site-layout-background"
            style={styles.content}
          >
            {children}
            {/* {routes(userProfile).find((route) => matchPath(location.pathname, { path: route.path, exact: true, strict: false })) ? children : <NotFound />} */}
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
