import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import {
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Radio,
  Row,
  Select,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as AccountSolid } from "../../assets/icons/account-solid.svg";
import { ReactComponent as FilterOutline } from "../../assets/icons/filter-outline.svg";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import {
  useFetchAdminBillingQuery,
  useDeleteAdminBillMutation,
} from "../../config/features/platform";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { errorMessage } from "../../utils";
import moment from "moment";
import { useTranslation } from "react-i18next";
const { Search } = Input;
const { Option } = Select;

export default function Office() {
  const history = useHistory();
  const { t } = useTranslation();
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [userId, setUserId] = useState(null);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [paramsTable, setParamsTable] = useState({
    row: 10,
    page: 1,
    sort_by: "created_at",
    order: "desc",
    office: "",
    status: "",
  });

  const {
    data: dataListUser,
    isFetching,
    refetch,
    error,
    isError,
  } = useFetchAdminBillingQuery(paramsTable);

  const [deleteUser, { isSuccess, isLoading }] = useDeleteAdminBillMutation();

  const { data: userProfile = {} } = useFetchProfileUserQuery();

  useEffect(() => {
    refetch();
    if (isSuccess) {
      setIsModalSuccess(true);
      setTimeout(() => {
        setIsModalSuccess(false);
      }, 3000);
    }
  }, [refetch, isSuccess]);

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
  }, [isError, error]);

  useEffect(() => {
    refetch();
  }, [refetch, paramsTable]);

  function handleResetFilter() {
    setParamsTable({
      ...paramsTable,
      sort_by: "created_at",
      order: "desc",
      office: "",
      status: "",
    });
  }

  const menuFilter = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">{t("Filter")}</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={handleResetFilter}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Status")}</p>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, status: e })}
              value={paramsTable.status}
            >
              <Option value="">{t("All Status")}</Option>
              <Option value="1">{t("Active")}</Option>
              <Option value="2">{t("Inactive")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="w-full h-0.5 bg-gray-100 rounded-full" />
        </Menu.Item>
        <Menu.Item>
          <p className="text-gray-500 text-base font-medium">{t("Sort")}</p>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort By")}</p>
            <Select
              defaultValue="full_name"
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, sort_by: e })}
              value={paramsTable.sort_by}
            >
              <Option value="full_name">{t("Full Name")}</Option>
              <Option value="email">{t("Email")}</Option>
              <Option value="created_at">{t("Created Date")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="desc"
              buttonStyle="solid"
              onChange={(e) =>
                setParamsTable({ ...paramsTable, order: e.target.value })
              }
              value={paramsTable.order}
            >
              <Radio value="asc">{t("Ascending")}</Radio>
              <Radio value="desc">{t("Descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const menuAction = (record) => {
    return (
      <Menu>
        <Menu.Item>
          <div
            className="flex flex-row space-x-2 items-center"
            onClick={() =>
              history.push({
                pathname: `/user-management/edit/${record.user_id}`,
                state: record,
              })
            }
          >
            <EditOutlined />
            <p>Edit</p>
          </div>
        </Menu.Item>
        {userProfile?.user_id !== record?.user_id && record?.status !== 1 && (
          <Menu.Item>
            <div
              className="flex flex-row space-x-2 items-center text-red-500"
              onClick={() => {
                setUserId(record.user_id);
                setIsModalDelete(true);
              }}
            >
              <DeleteOutlined />
              <p>{t("Delete")}</p>
            </div>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("User Name"),
      dataIndex: "first_name",
      key: "first_name",
      render: (first_name, record) => {
        return `${first_name} ${record?.last_name ? record?.last_name : ""}`;
      },
    },
    {
      title: t("Email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("Date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY");
      },
    },
    {
      title: t("Role"),
      dataIndex: "role",
      key: "role",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (e) => {
        return (
          <div
            className={`px-2 py-1 text-gray-100 rounded-full text-center text-xs font-medium ${
              e === 1 ? "bg-blue-400" : "bg-gray-400"
            }`}
          >
            {e === 1 ? t("ACTIVE") : t("INACTIVE")}
          </div>
        );
      },
    },
    {
      render: (record) => {
        return (
          <Dropdown overlay={menuAction(record)} trigger={["click"]}>
            <div className="group cursor-pointer">
              <EllipsisOutlined className="group-hover:text-blue-500" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  const onSearch = (value) => setParamsTable({ ...paramsTable, search: value });

  function handleCancel() {
    setIsModalDelete(false);
  }

  async function handleDelete() {
    try {
      setIsModalDelete(false);
      deleteUser(userId);
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleDone() {
    setIsModalSuccess(false);
  }

  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }

  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <h1 className="text-xl md:text-2xl font-medium">
                {t("Admin Management")}
              </h1>
            </Col>
            <Col>
              <Button
                className="bg-blue-700 md:w-40 rounded-md"
                type="primary"
                onClick={() => history.push("/user-management/add")}
                icon={<AccountSolid />}
              >
                {t("Add Admin")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <div>
            <h3 className="text-gray-500 text-sm md:text-base">{t("Total Admin")}</h3>
            <h2 className="text-blue-700 text-4xl md:text-5xl font-bold">
              {dataListUser?.data?.length}
            </h2>
          </div>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="end">
                  <Col>
                    <Row gutter={[12, 12]}>
                      <Col>
                        <Search
                          placeholder={t("Search")}
                          allowClear
                          onSearch={onSearch}
                          onChange={(e) =>
                            setTimeout(() => {
                              onSearch(e.target.value);
                            }, 1000)
                          }
                          style={{
                            width: 200,
                            borderRadius: "10px !important",
                          }}
                        />
                      </Col>
                      <Col>
                        <Dropdown
                          overlay={menuFilter}
                          onVisibleChange={handleVisibleFilter}
                          visible={visibleFilter}
                          trigger={["click"]}
                        >
                          <Button
                            type="primary"
                            ghost
                            icon={<FilterOutlined />}
                          />
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={
                    dataListUser?.data && datasetsListTable(dataListUser?.data)
                  }
                  scroll={{ x: 700 }}
                  loading={isFetching || isLoading}
                  pagination={{
                    current: paramsTable.page,
                    pageSize: paramsTable.row,
                    // total: dataListUser[0] ? dataListUser[0]?.total : 0,
                  }}
                  onChange={handleChangeTable}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        title={t("Delete User")}
        subtitle={t("Are you sure want to delete this user?")}
        type="danger"
        visible={isModalDelete}
        onCancel={handleCancel}
      >
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="secondary"
            onClick={handleCancel}
            ghost
            danger
          >
            {t("Cancel")}
          </Button>
          <Button
            className="w-full"
            type="primary"
            onClick={handleDelete}
            danger
          >
            {t("Delete")}
          </Button>
        </div>
      </Modal>
      <Modal
        type="success"
        title={t("Updated Success!")}
        subtitle={t("Changes have been saved! Now you can continue browsing.")}
        visible={isModalSuccess}
        closable={false}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={handleDone}>
            {t("Done")}
          </Button>
        </div>
      </Modal>
    </>
  );
}
