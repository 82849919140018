import { Card, Col, Row, Skeleton, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  useFetchCardQuery, useFetchListUsageMateraiQuery,
} from "../../config/features/overview";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { useFetchActivityDocumentQuery, useFetchListModuleQuery } from "../../config/features";
import { errorMessage } from "../../utils";
import { useTranslation } from "react-i18next";
import CardPrimary from "../../components/Card/CardPrimary";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { numberFormat } from "highcharts";

export default function Overview() {
  // filter state
  const [filterOffice, setFilterOffice] = useState("");
  const { t } = useTranslation();

  const { data: userProfile = {} } = useFetchProfileUserQuery();

  const { data: dataModule = {} } = useFetchListModuleQuery(userProfile?.office_id);

  const {
    data: dataCard = {},
    isFetching: isFetchingCard,
    error: errorCard,
    isError: isErrorCard,
  } = useFetchCardQuery({ office_id: filterOffice });

  const [paramsTableDocActivityLog, setParamsTableDocActivityLog] = useState({
    row: 5,
    page: 1,
    office: "",
    sort_by: "created_at",
    order: "desc",
    doc_id: "",
    date: moment(new Date(), moment.ISO_8601).format("YYYY-MM-DD"),
  });
  const {
    data: dataDocActivityLog = [],
    isFetching: isFetchingDocActivityLog,
    isError: isErrorDocActivityLog,
    error: errorDocActivityLog,
  } = useFetchActivityDocumentQuery(paramsTableDocActivityLog);

  useEffect(() => {
    if (userProfile?.office_type_id !== 1) {
      setFilterOffice(userProfile?.office_id);
    } else {
      setFilterOffice("");
    }
  }, [userProfile]);

  // Usage E-Meterai
  const [paramsUsageMaterai, setParamsUsageMaterai] = useState(() => {
    let start_date = new Date();
    let end_date = new Date();
    start_date.setMonth(start_date.getMonth() - 5)
    start_date = `${start_date.getFullYear()}-${start_date.getMonth() + 1 < 10 ? 0 : ''}${start_date.getMonth() + 1}`
    end_date = `${end_date.getFullYear()}-${end_date.getMonth() + 1 < 10 ? 0 : ''}${end_date.getMonth() + 1}`
    return { start_date, end_date };
  })

  const {
    data: dataUsageMaterai = [],
    isFetching: isFetchingUsageMaterai,
    error: errorUsageMaterai,
    isError: isErrorUsageMaterai,
    refetch: refetchUsageMaterai
  } = useFetchListUsageMateraiQuery(paramsUsageMaterai);

  useEffect(() => {
    if (isErrorUsageMaterai) {
      errorMessage(errorUsageMaterai);
    }
  }, [isErrorUsageMaterai, errorUsageMaterai])

  useEffect(() => {
    refetchUsageMaterai();
  }, [refetchUsageMaterai])

  const optionsLine = (title, name, categories, data, other) => {
    return {
      title: { text: title },
      chart: {
        type: "line"
      },
      credits: { enabled: false },
      xAxis: {
        categories: categories,
        crosshair: false,
        gridLineWidth: 1
      },
      yAxis: {
        title: false,
      },
      tooltip: {
        shared: true,
        useHTML: true,
        formatter: function () {
          let tooltipOther;
          if (other && other.length == data.length) {
            const cost = other[categories.findIndex(item => item == this.x)]
            tooltipOther = `
            <tr>
              <td style="color: ${this.points[0].series.color}">Total Rp : </td>
              <td style="text-align: right"><b>${numberFormat(cost, 0)}</b></td>
            </tr>
            `
          }
          return `
            <table>
              <tr><th colspan="2">${this.x}</th></tr>
              ${tooltipOther}
              <tr>
                <td style="color: purple">${this.points[0].series.name} : </td>
                <td style="text-align: right"><b>${numberFormat(this.y, 0)}</b></td>
              </tr>
            </table>
          `;
        }
      },
      series: [
        {
          name: name,
          type: 'line',
          linkedTo: false,
          data: data
        }
      ]
    }
  }
  // End Usage E-Meterai


  // Error Handling
  // =============== 
  useEffect(() => {
    if (isErrorCard) errorMessage(errorCard)
  }, [isErrorCard, errorCard]);

  useEffect(() => {
    if (isErrorDocActivityLog) errorMessage(errorDocActivityLog)
  }, [isErrorDocActivityLog, errorDocActivityLog]);

  const columnsDocLog = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Document Name"),
      dataIndex: "doc_name",
      key: "doc_name",
    },
    {
      title: t("Activity"),
      dataIndex: "activity",
      key: "activity",
    },
    {
      title: t("Actor"),
      dataIndex: "creator",
      key: "creator",
    },
    {
      title: t("Source"),
      dataIndex: "source",
      key: "source",
      render: (_, record) => {
        return (
          <>
            <p className="text-gray-500">
              {t("Device Type")} :
              <span className="text-gray-800"> {record?.device_type}</span>
            </p>
            <p className="text-gray-500">
              {t("Operating System")} :{" "}
              <span className="text-gray-800"> {record?.os}</span>
            </p>
            <p className="text-gray-500">
              {t("Browser")} :{" "}
              <span className="text-gray-800"> {record?.browser}</span>
            </p>
            <p className="text-gray-500">
              IP : <span className="text-gray-800"> {record?.ip}</span>
            </p>
          </>
        );
      },
    },
    {
      title: t("date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (e) => {
        return moment(e, moment.ISO_8601).format("DD/MM/YYYY, HH:mm:ss");
      },
    },
  ];

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTableDocActivityLog.row * (paramsTableDocActivityLog.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  function handleChangeTable(pagination) {
    setParamsTableDocActivityLog({
      ...paramsTableDocActivityLog,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }
  const getActivedCard = () => {
    const cards = []
    if (dataModule.office_m == true) {
      cards.push("Office Management")
    }
    if (dataModule.sign_m == true) {
      cards.push("Sign Management")
    }
    if (dataModule.stamp_m == true) {
      cards.push("Stamp Management")
    }
    return cards
  }
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row gutter={[24, 24]} justify="space-between">
          <Col>
            <h1 className="text-xl md:text-2xl font-medium">{t("overview")}</h1>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col sm={24} md={parseInt(24 / getActivedCard().length)}>
            <CardPrimary
              title={t('Work Unit Information')}
              data={[
                { label: t("Total Active User"), value: dataCard?.total_user },
                { label: t("Total Work Unit"), value: dataCard?.total_office }
              ]}
            />
          </Col>
          {dataModule?.stamp_m == true && (
            <Col sm={24} md={parseInt(24 / getActivedCard().length)}>
              <CardPrimary
                title={'E-Meterai'}
                data={[
                  { label: t("Used E-Meterai"), value: dataCard?.used_meterai },
                  { label: t("Unused E-Meterai"), value: dataCard?.total_meterai, warning: dataCard?.warning_meterai }
                ]}
              />
            </Col>
          )}
          {dataModule?.sign_m == true && (
            <Col sm={24} md={parseInt(24 / getActivedCard().length)}>
              <CardPrimary
                title={'E-Sign'}
                data={[
                  { label: t("Total E-Sign"), value: Number(dataCard?.total_signature + dataCard?.used_signature) },
                  { label: t("Used E-Sign"), value: dataCard?.used_signature },
                  { label: t("Unused E-Sign"), value: dataCard?.total_signature }
                ]}
              />
            </Col>
          )}
        </Row>
      </Col>
      <Col span={24}>
        <Card className="rounded-md">
          <Skeleton loading={isFetchingUsageMaterai} active paragraph={{ rows: 4 }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={optionsLine(
                t("totalUsage6Month"), "Total Stamp",
                dataUsageMaterai?.map(({ period }) => period),
                dataUsageMaterai?.map(({ usage }) => parseInt(usage)),
                dataUsageMaterai?.map(({ cost }) => parseInt(cost)),
              )
              }
            />
          </Skeleton>
        </Card>
      </Col>
      <Col span={24}>
        <Card className="rounded-md">
          <Skeleton loading={isFetchingDocActivityLog} active paragraph={{ rows: 4 }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("Document Activity Log")} {" "}
                      <span className="text-gray-400">
                        {`(${t("Last 30 Days From Date")})`}
                      </span>
                    </h3>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsDocLog}
                  dataSource={datasetsListTable(dataDocActivityLog)}
                  loading={isFetchingDocActivityLog}
                  scroll={{ x: 800 }}
                  pagination={{
                    current: paramsTableDocActivityLog.page,
                    pageSize: paramsTableDocActivityLog.row,
                    total: dataDocActivityLog[0] ? dataDocActivityLog[0]?.total : 0,
                    showSizeChanger: false,
                  }}
                  onChange={handleChangeTable}
                />
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>
    </Row>
  );
}
