import { FilterOutlined, PlusOutlined, RightOutlined, SettingOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Select,
  DatePicker,
  Table,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useFetchListRecordRekonQuery } from "../../config/features/document";
import { errorMessage, exportExcel, getBlob } from "../../utils";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import { objectToQueryString } from "../../utils/queryString";
const { Search } = Input;
const { Option } = Select;

export default function HistorySettlementOffice() {
  const location = useLocation();
  const history = useHistory();
  const { id }  = useParams();

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [paramsTable, setParamsTable] = useState({
    page: 1,
    row: 10,
    status_rekon: "",
    startdate: "",
    enddate: "",
    rekonsile_code: "",
    office_id: id,
  });
  const {
    data: dataListRecordRekon = [],
    isFetching,
    isError,
    error,
    refetch
  } = useFetchListRecordRekonQuery(paramsTable)
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [visibleAction, setVisibleAction] = useState(false);
  const statusList = [
    "All Status",
    "Pending",
    "Settle",
    "Dispute"
  ]

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Kode Rekonsiliasi",
      dataIndex: "rekonsile_code",
      key: "rekonsile_code",
    },
    {
      title: "Tanggal Rekonsiliasi",   
      dataIndex: "range",
      key: "range",   
      // render: (start_date, end_date) => moment(start_date).utc(true).format("YYYY-MM-DD").toString() + ' - ' +moment(end_date).utc(true).format("YYYY-MM-DD").toString() 
    },
    {
      title: "Tanggal Proses",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).utc(true).format("YYYY-MM-DD").toString()
    },
    {
      title: "Periode",
      dataIndex: "start_date",
      key: "start_date",
      render: (start_date) => moment(start_date).utc(true).format("MMMM").toString()
    },
    {
      title: "Total Data",
      dataIndex: "total_data",
      key: "total_data"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 50,
      render: (status) => {
        if(status === 'NOT SETTLEMENT'){
          return <span style={{color: "lightsalmon"}}>{status}</span>
        } else if (status === 'SETTLEMENT'){
          return <span style={{color: "green"}}>{status}</span>
        } else {
          return <span style={{color: "blueviolet"}}>{status}</span>
        }
      }
    },
    {
      title: "Aksi",
      render: (record) => (
        <div className="group cursor-pointer"
          onClick={() => 
            history.push({
              pathname: `/detail-history-settlement/${record.id}`,
              state: {a: location?.state, b: record, c: id}
            })
          }
        >
          <RightOutlined className="group-hover:text-blue-500" />
        </div>
      )
    }
  ];

  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }
  function handleVisibleAction(flag) {
    setVisibleAction(flag);
  }
  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }
  function handleResetFilter(params) {
    setParamsTable({
      ...paramsTable,
      rekonsile_code: "",
      page: 1,
      status_rekon: "",
      startdate: "",
      enddate: "",
    })
  }
  const handleClickExportExcel = async () => {
    const queryString = objectToQueryString({ export_format: 'excel', ...paramsTable });
    const response = await getBlob(`/record/rekonsiliasi?${queryString}`);
    exportExcel(response.data, "Record Rekonsiliasi");
  }
  const menuFilter = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p className="text-gray-300 hover:text-gray-500" onClick={handleResetFilter}>
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Status</p>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              value={paramsTable.status_rekon}
              onChange={(e) => setParamsTable({ ...paramsTable, status_rekon: e })}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              allowClear
            >
              {statusList.map((value, index) => (
                <Option key={index} value={index != 0 ? index : ""}>{value}</Option>
              ))}
            </Select>
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Date</p>
            <DatePicker.RangePicker
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(value) => {
                setParamsTable({
                  ...paramsTable,
                  startdate: moment(value[0]).utc().format().toString(),
                  enddate: moment(value[1]).utc().format().toString()
                })
              }}
              value={[
                paramsTable.startdate ? moment(paramsTable.startdate) : "",
                paramsTable.enddate ? moment(paramsTable.enddate) : ""
              ]}
            />
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  useEffect(() => {
    if (isError) {
      errorMessage(error)
      //console.log(error);
    }
  }, [isError, error])
  const onSearch = (value) => setParamsTable({ ...paramsTable, rekonsile_code: value, page: 1 });
  
  useEffect(() => {    
    refetch()
    
  }, [refetch])

  return (
    <>
       <Row gutter={[24, 24]}>
          <Col span={24}>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => 
                      history.goBack()
                    }
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    {location?.state?.office_name}
                  </h1>
                </Col>
              </Row>
          </Col>
          <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      Riwayat Hasil Rekonsiliasi
                    </h3>
                  </Col>
                  <Col>
                    <Row gutter={[12, 12]}>
                      <Col>
                        <Search
                          placeholder="Search"
                          allowClear
                          onSearch={onSearch}
                          onChange={(e) =>
                            setTimeout(() => {
                              onSearch(e.target.value);
                            }, 1000)
                          }
                          style={{
                            width: 200,
                            borderRadius: "10px !important",
                          }}
                        />
                      </Col>
                      <Col>
                        <Dropdown
                          overlay={menuFilter}
                          onVisibleChange={handleVisibleFilter}
                          visible={visibleFilter}
                          trigger={["click"]}
                        >
                          <Button
                            type="primary"
                            ghost
                            icon={<FilterOutlined />}
                          />
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                </Row>                
              </Col>              
              <Col span={24}>                
                <Table
                  columns={columns}
                  dataSource={dataListRecordRekon?.map((item, index) => ({ no: index + 1, ...item }))}
                  scroll={{ x: 850 }}
                  loading={isFetching}
                  pagination={{
                    current: paramsTable.page,
                    pageSize: paramsTable.row,
                    total: dataListRecordRekon[0] ? dataListRecordRekon[0]?.total : 0,
                  }}
                  onChange={handleChangeTable}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        </Row> 
    </>
  )

}