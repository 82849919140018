import { CloseOutlined, DeleteOutlined, ArrowsAltOutlined, DoubleLeftOutlined, EditOutlined, LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Avatar, Col, Divider, Dropdown, Form, Input, Menu, message, Radio, Row, Select, Modal as ModalAntd, Collapse, Tooltip, Progress, Segmented } from "antd";
import { Modal, Button, Spinner } from "../../components";
import pdfJsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { Document, Page, pdfjs } from "react-pdf";
import Countdown from "react-countdown";
import OtpInput from "react-otp-input";
import Fade from "react-reveal/Fade";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as AccountMultiplePlus } from "../../assets/icons/account-multiple-plus.svg";
import { ReactComponent as AccountMultiplePlusBlack } from "../../assets/icons/account-multiple-plus-black.svg";
import { ReactComponent as SignWithOtherIcon } from "../../assets/icons/sign-with-other.svg";
import SignSvg, {
  ReactComponent as SignIcon,
} from "../../assets/icons/sign.svg";
import { ReactComponent as SettingSign } from "../../assets/icons/setting-sign.svg";
import Dotted from '../../assets/img/icon/dotted.png'
import CloseIcon from '../../assets/img/icon/closeIcon.png'
import ResetIcon from '../../assets/icons/reset.svg'
import SettingIcon from '../../assets/img/icon/setting.png'
import Unstamp from '../../assets/img/icon/unstamp.png'
import google1 from '../../assets/img/google-1.png'
import google2 from '../../assets/img/google-2.png'
import safari1 from '../../assets/img/safari1.png'
import safari2 from '../../assets/img/safari2.png'
import safari3 from '../../assets/img/safari3.png'
import firefox1 from '../../assets/img/firefox1.png'
import firefox2 from '../../assets/img/firefox2.png'
import arrowRight from '../../assets/img/icon/right-arrow.png'
import arrowBtm from '../../assets/img/icon/arrow-btm.png'
import arrowUp from '../../assets/img/icon/arrow-up.png'

import CapSvg, { ReactComponent as CapIcon } from "../../assets/icons/cap.svg";

import ButtonPrimary from "../../components/Button/ButtonPrimary";
import axios from "axios";
import { rootApi } from "../../config/features/config";
import { getPosition, numberCommas } from "../../utils";
import { getLastArrayValue } from "../../utils/array";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useFetchDetBulkSignQuery } from "../../config/features";
import { find } from "highcharts";
pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker;

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;

const rgba = ['rgba(39, 245, 137, 0.8)', 'rgba(39, 143, 245, 0.8)', 'rgba(199, 245, 39, 0.8)', 'rgba(98, 39, 245, 0.8)', 'rgba(250, 32, 246, 0.8)', 'rgba(71, 206, 189, 0.8)', 'rgba(86, 206, 71, 0.8)']

export default function SignWithOtherBulk() {
  const { t } = useTranslation();

  const tokenPeruri = useMemo(() => localStorage.getItem('peruri'), [])
  const tokenAccess = useMemo(() => localStorage.getItem('access'), [])
  const { state: { dataDoc } } = useLocation()
  const history = useHistory();
  const [pdfFile, setPdfFile] = useState(null);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [isHaveSign, setHaveSign] = useState(false)
  let [totalSignature, setTotalSignature] = useState([]);
  const [docSign, setDocSign] = useState(null)
  const [done] = useState(false)
  const [totalStamp, setTotalStamp] = useState(0)
  const [openSubmit, setOpenSubmit] = useState(false)
  const [moveable, setMoveable] = useState(true);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [signType, setSignType] = useState('pararel')
  const [signModal, setSignModal] = useState(false)
  const [isLocationBlocked, setIsLocationBlocked] = useState(false)
  const [guideModal, setGuideModal] = useState({ guide1: false, guide2: false, guide3: false, })
  const [signModalEdit, setSignModalEdit] = useState({ open: false, key: '' })
  const [previewModal, setPreview] = useState(false)
  const [successModal, setsuccessModal] = useState(false)
  const [activeLocation, setActiveLocation] = useState(false)
  const [signer, setSigner] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)
  const [listStampPosition, setListStampPosition] = useState([])
  const [user, setUserSign] = useState({ signer_name: '', signer_email: '', sender_note: '', user_id: '', key: '' })
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [data, setData] = useState(null)
  const [totalMaterai, setTotalMaterai] = useState({ total: 0, remaining: 0 })
  const [openSign, setOpenSign] = useState(false)
  const [idDocument, setIdDocument] = useState(null)
  const [isSendDoc, setIsSendDoc] = useState(false);

  const [otpValue, setOtpValue] = useState("");
  const [countdownValue, setCountdownValue] = useState(Date.now());
  const [otpPreview, setOtpPreview] = useState(false)
  const [isResendOtp, setIsResendOtp] = useState(false);

  const [deltaPosition, setDeltaPosition] = useState({});
  const [sizing, setSizing] = useState([]);
  const [profile, setProfile] = useState({ imgSign: '', imgParaf: '', email: '' })
  const [location, setLocation] = useState(null)
  const [reason, setReason] = useState(null)
  const [isSetResize, setIsSetResize] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(0);
  const [isSetSignature, setIsSetSignature] = useState(false)

  const {
    data: dataDetail = {},
    isFetching: isFetchingDetail,
    isError: isErrorDetail,
    error: errorDetail,
    refetch: refetchDetail,
    isSuccess: isSuccessDetail
  } = useFetchDetBulkSignQuery({ id: dataDoc.doc_id, is_folder: 1, is_sign: 1 })

  function base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  // This function is a reverse from get
  const getDeltaByPage = (coordinates) => {
    //  Grouping the coordinates position by page
    let coordinatesByPage = {}
    for (let i = 0; i < coordinates.length; i++) {
      const item = coordinates[i];

      if (coordinatesByPage[item.page]) {
        coordinatesByPage[item.page] = [...coordinatesByPage[item.page], item]
      } else {
        coordinatesByPage[item.page] = [item]
      }
    }

    // turn coordniate into delta position 
    const pageKeys = Object.keys(coordinatesByPage)
    let deltaPosition = {}
    for (let i = 0; i < pageKeys.length; i++) {
      const pageKey = pageKeys[i];
      const coordinatesArr = coordinatesByPage[pageKey];

      let heightOffset = 0;
      for (let j = 0; j < coordinatesArr.length; j++) {
        const item = coordinatesArr[j];
        const offset = j;
        const localSizing = item.type === "PARAF" ? sizing.paraf : sizing.signature
        deltaPosition[item.index] = {
          x: Number(item.llx),
          y: -Math.abs(item.ury) - (heightOffset),
          page: pageKey,
        }
        heightOffset += localSizing.height
      }
    }
    return deltaPosition
  }
  useEffect(() => {
    refetchDetail();
  }, [refetchDetail])


  useEffect(() => {
    const getPreviewDoc = async () => {
      try {
        setLoading(true)
        const { data: { data: dataDocument } } = await axios({
          method: 'GET',
          url: `${rootApi}/document/preview/${idDocument}`,
          headers: {
            token: tokenAccess,
            "token-peruri-sign": tokenPeruri
          }
        })

        const documentBuffer = base64ToArrayBuffer(dataDocument)

        setPdfFile(documentBuffer)

        setLoading(false)
      } catch (e) {
        message.error(t("Sorry we cant get your document now , please try again!"))
      }
    }
    if (idDocument) {
      getPreviewDoc();
    }
  }, [idDocument])

  const checkStatusSendDocument = async () => {
    try {
      const { data: { data } } = await axios({
        method: 'POST',
        url: `${rootApi}/sign/check-docsign-bulk`,
        data: { folder_id: dataDoc.doc_id },
        headers: {
          "token": tokenAccess,
          "token-peruri-sign": tokenPeruri
        }
      })
      setSendSuccess(data?.length || 0)
    } catch (error) {
      setSendSuccess(0)
    }
  }
  useEffect(() => {
    checkStatusSendDocument();
  }, [])
  useEffect(() => {
    if (isSendDoc && sendSuccess != dataDetail.docs.length) {
      const interval = setInterval(() => {
        checkStatusSendDocument()
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [isSendDoc, sendSuccess, dataDetail.docs])


  // useEffect(() => {
  //   function base64ToArrayBuffer(base64) {
  //     var binary_string = window.atob(base64);
  //     var len = binary_string.length;
  //     var bytes = new Uint8Array(len);
  //     for (var i = 0; i < len; i++) {
  //       bytes[i] = binary_string.charCodeAt(i);
  //     }
  //     return bytes.buffer;
  //   }

  //   async function fetchData() {

  //     setLoading(true)

  //     try {
  //       const { data: { data } } = await axios({
  //         method: 'GET',
  //         url: `${rootApi}/document/preview/${dataDoc.doc_id}`,
  //         headers: {
  //           "token": tokenAccess
  //         }
  //       })

  //       const documentBuffer = base64ToArrayBuffer(data)
  //       setPdfFile(documentBuffer)
  //       setLoading(false)
  //     } catch (error) {

  //       setLoading(false)

  //       message.error(error.message);
  //     }
  //   }
  //   fetchData();
  // }, []);

  useEffect(() => {

    async function getSignatureWithProfile() {

      setLoading(true)

      try {

        const { data: { data: dataProfile } } = await axios({
          method: 'GET',
          url: `${rootApi}/user/profile`,
          headers: {
            token: tokenAccess
          }
        })

        const { data: dataSign } = await axios({
          method: 'GET',
          url: `${rootApi}/sign/preview-speciment/${dataProfile.user_id}`,
          headers: {
            token: tokenAccess,
            "token-peruri-sign": tokenPeruri
          }
        })

        let sign = new Image()
        sign.src = dataSign.data.base64Speciment
        sign.onload = function () {
          setSizing(prev => ([
            ...prev,
            {
              name: dataProfile.full_name,
              email: dataProfile.email,
              signature: { width: sign.width, height: sign.height }
            }
          ]))
        }
        let paraf = new Image()
        paraf.src = dataSign.data.base64Paraf
        paraf.onload = function () {
          setSizing(prev => {

            const firstItem = prev[0]
            const newData = { ...firstItem, paraf: { width: paraf.width, height: paraf.height } }
            return [newData]

          })
        }

        setProfile({
          imgSign: dataSign.data.base64Speciment,
          imgParaf: dataSign.data.base64Paraf,
          email: dataProfile.email,

        })

        if (dataProfile?.signature_id === null && dataProfile?.paraf_id === null) {
          message.info(t("Please create your signature before sign!"))
        }
        else {

          if (dataProfile?.signature_id !== null && dataProfile?.paraf_id !== null) {
            setHaveSign(true)
          }
          else if (dataProfile?.signature_id === null) {
            message.info(t("Please create your signature before sign!"))
          }
          else if (dataProfile?.paraf_id === null) {
            message.info(t("Please create your paraf before sign!"))
          }
        }

        setSigner(prev =>
        ([...prev,
        {
          signer_email: dataProfile.email,
          sender_note: '',
          user_id: dataProfile.user_id,
          signer_name: dataProfile.full_name,
          color: rgba[Math.floor(Math.random() * 7)],
          key: Math.floor(Math.random() * 100)
        }
        ]))


      } catch (e) {
        setLoading(false)
        const { data: { error } } = e.response
        message.error(error)
        setTimeout(() => {
          if (error === "Error when preview speciment") {
            history.push('/sign-management/create-signature')
          }
        }, 1000)
      }
    }

    if (dataDoc.assign_type == 2) {
      getSignatureWithProfile()
    }

  }, [])


  useEffect(() => {
    async function getQuoteMeterai() {
      try {
        setLoading(true)
        const { data: { data } } = await axios({
          method: 'GET',
          url: `${rootApi}/quota`,
          headers: {
            token: tokenAccess
          }
        })
        setTotalMaterai({ total: data[1].remaining, remaining: data[1].remaining })
      } catch (e) {
        const { data: { error } } = e.response
        message.error(error)
      }
    }

    getQuoteMeterai()
  }, [])

  useEffect(() => {

    // get current user position 
    async function getStatePosition() {
      const data = await getPosition()
      if (data !== undefined || data !== '') {
        setLocation(data)
      }
      else {
        setLocation('')
      }
    }

    getStatePosition()

  }, [])

  useEffect(() => {
    if (dataDetail?.docs && dataDetail?.docs?.length > 0) {
      setIdDocument(dataDetail?.docs[0].doc_id)
    }
  }, [dataDetail.docs])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const getSpesimenSize = (email, isParaf = false) => {
    if (isParaf) {
      return sizing.find((item) => item.email == email).paraf;
    }
    return sizing.find((item) => item.email == email).signature;
  }
  // add signature signer
  function handleAddSignature(index, isParaf = false) {
    setOpenSubmit(true)
    const startSize = getSpesimenSize(signer[signer.length - 1].signer_email, isParaf)
    const SIZE_LIMIT = 100
    if (startSize.height > SIZE_LIMIT && startSize.height >= startSize.width) {
      const ratio = startSize.width / startSize.height
      startSize.height = SIZE_LIMIT
      startSize.width = ratio * startSize.height
    }
    else if (startSize.width > SIZE_LIMIT && startSize.width >= startSize.height) {
      const ratio = startSize.height / startSize.width
      startSize.width = SIZE_LIMIT
      startSize.height = ratio * startSize.width
    }
    if (totalMaterai.total === 0) {
      message.warning(t('Your quota not enought to sign'))
    } else {
      setHaveSign(true)
      setTotalMaterai(prev => ({ ...prev, total: prev.total - (dataDetail.docs.length || 1) }))
      setTotalSignature([...totalSignature, { index, page: pageNumber, signer_name: signer[signer.length - 1].signer_name, signer_email: signer[signer.length - 1].signer_email, rgb: signer[signer.length - 1].color, paraf: isParaf ? "PARAF" : "SPESIMEN", frame_width_ratio: 3, ...startSize }]);
      setDeltaPosition(prevDelta => {
        const newDelta = {
          ...prevDelta,
          [index]: { x: 0, y: -Number(startSize.height || 0), page: pageNumber, type: isParaf ? "PARAF" : "SPESIMEN", name: signer[signer.length - 1].signer_name }
        }


        return newDelta
      })
    }
  }

  // close signer 
  function handleCloseSignature(indexNumber, name = "", isParaf) {

    if (Object.keys(deltaPosition).length === 1) {
      setOpenSubmit(false)
    }

    setTotalMaterai(prev => ({ ...prev, total: prev.total + (dataDetail?.docs?.length || 1) }))

    // Delete Delta Position Item
    setDeltaPosition(prevDelta => {
      const newDelta = {}

      const itemSizing = sizing.filter(items => items.name === name)[0]
      const heightItem = isParaf ? itemSizing?.paraf?.height : itemSizing?.signature?.height
      const keys = Object.keys(prevDelta)
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        const item = prevDelta[key]



        if (key >= indexNumber) {
          newDelta[key] = { ...item, y: item.y + heightItem }
        } else {
          newDelta[key] = { ...item }
        }
      }

      if (newDelta[indexNumber]) delete newDelta[indexNumber]

      return newDelta
    })

    // Delete List Stamp
    setListStampPosition(prevList => {
      const newList = prevList.filter((item) => item.index !== indexNumber);
      return newList
    })

    // Delete Total Stamp
    setTotalSignature(prevTotal => {
      const newTotal = prevTotal.filter((item) => item.index !== indexNumber);
      const isSignerSignExist = newTotal.findIndex((item) => item.signer_name === name)

      if (isSignerSignExist < 0) {
        deleteSigner(name)
      }

      return newTotal
    })
  }


  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setIsResendOtp(true);
      return <div />;
    } else {
      return (
        <p>
          {minutes}:{seconds}
        </p>
      );
    }
  };

  // handle drag sign position
  const handleDrag = (e, position, index, isParaf, name) => {
    if (!isSetResize) {
      const { x, y } = position;
      setDeltaPosition(prev => ({
        ...prev,
        [index]: { x, y, page: pageNumber, type: isParaf ? "PARAF" : "SPESIMEN", name }
      }))
    }
  }

  // get coordinate each user 
  const getUserWithCoor = (user, deltaPosition, totalSignature) => {

    //  Grouping the delta position by page
    const deltaPositionKeys = Object.keys(deltaPosition)
    let deltaGroupByPage = {}
    for (let i = 0; i < deltaPositionKeys.length; i++) {
      const key = deltaPositionKeys[i];
      const item = deltaPosition[key];
      const itemWithIndex = { index: key, ...item }

      if (deltaGroupByPage[item.page]) {
        deltaGroupByPage[item.page] = [...deltaGroupByPage[item.page], itemWithIndex]
      } else {
        deltaGroupByPage[item.page] = [itemWithIndex]
      }
    }

    // Normalize the coordinates by page 
    const pageKeys = Object.keys(deltaGroupByPage)
    let normalizedDelta = {}
    for (let i = 0; i < pageKeys.length; i++) {
      const pageKey = pageKeys[i];
      const deltaArr = deltaGroupByPage[pageKey];

      let heightOffset = 0;
      for (let j = 0; j < deltaArr.length; j++) {
        const item = deltaArr[j];
        const { width, height, frame_width_ratio } = totalSignature.find((element) => element.index == item.index)
        const offset = j;
        const itemSizing = sizing.filter(items => items.name === item.name)[0]
        const localSizing = item.type === "PARAF" ? itemSizing.paraf : itemSizing.signature

        const { width: boxWidth, height: boxHeight } = getBoxSize(width, height, frame_width_ratio)
        const shift_x = Math.abs((boxWidth - width) / 2)
        const shift_y = -Math.abs((boxHeight - height) / 2)
        
        normalizedDelta[item.index] = {
          llx: `${Math.abs(item.x + shift_x)}`,
          lly: `${Math.abs(item.y + shift_y) - height - (heightOffset)}`,
          urx: `${Math.abs(item.x + shift_x) + width}`,
          ury: `${Math.abs(item.y + shift_y) - (heightOffset)}`,
        }

        // heightOffset += localSizing?.height
      }
    }

    // Prepared signature data by signer
    let data = []
    for (let i = 0; i < user.length; i++) {
      const userItem = user[i];
      const totalSignatureFiltered = totalSignature.filter((item) => userItem.signer_name === item.signer_name)

      const coordinate = totalSignatureFiltered.map((item, i) => {
        const index = item.index;
        const page = item.page;
        const paraf = item.paraf
        const { llx, lly, urx, ury } = normalizedDelta[index];

        return ({
          index,
          type: paraf,
          llx: `${llx}`,
          lly: `${lly}`,
          urx: `${urx}`,
          ury: `${ury}`,
          page: `${page}`,
          location: (location === null || location === '') ? '' : `${location?.city},${location?.country}`,
          reason: reason,
          isv: "YES",
        })
      })

      data.push({
        signer_name: userItem.signer_name,
        signer_email: userItem.signer_email,
        sender_note: userItem.sender_note,
        user_id: userItem.user_id,
        sign_coordinates: [...coordinate],
      })
    }

    return data
  }

  // check last signer has signed 
  const checkUserCoor = () => {

    const lastSigner = signer[signer.length - 1]

    if (totalSignature.find(item => item.signer_name === lastSigner.signer_name)) {
      setActiveLocation(true)
    }
    else {
      message.info(t("Please add coordinate last signer!"))
    }

  }

  // send sign to BE
  const handleSubmit = async (values) => {
    setIsSubmit(true)
    const body = {
      otp_code: otpValue,
      signer_note: reason
    }
    try {
      await axios({
        method: 'POST',
        url: `${rootApi}/sign/sign-doc-bulk/${dataDoc.doc_id}`,
        data: body,
        headers: {
          "token": tokenAccess,
          "token-peruri-sign": tokenPeruri
        }
      }).then((res) => {
        if (res.data.status == "success") {
          setIsSubmit(false)
          setPreview(false)
          setsuccessModal(true)
        }
        else {
          setIsSubmit(false)
          message.error({ content: t("Failed to sign document.") })
        }
      }).catch((error) => {
        setIsSubmit(false)
        message.error(error.message)
      })

    } catch (error) {
      setIsSubmit(false)
      message.error(error)
    }
    // return true;
    // const body = { otp_code: `${otpValue}`, signer_note: reason }


    // setIsSubmit(true)

    // try {

    //   const response = await axios({
    //     method: 'POST',
    //     url: `${rootApi}/sign/sign-doc/${docSign.sign_id}`,
    //     data: body,
    //     headers: {
    //       "token": tokenAccess,
    //       "token-peruri-sign": tokenPeruri
    //     }
    //   })

    //   setIsSubmit(false)

    //   setPreview(false)

    //   setsuccessModal(true)

    //   setSigner([])

    // } catch (e) {

    //   const { data: { error } } = e.response
    //   setIsSubmit(false)
    //   message.error(error)
    // }
  }

  // submit send document for sign to BE
  const handlePreviewOtp = async (id) => {
    setIsSendDoc(false)
    setPreview(true)
    setOtpPreview(true)
    setIsSubmit(true)
    // return true;

    try {
      if (signType == 'pararel' || (signType == 'sequential' && signer[0].signer_email == profile.email)) {
        await axios({
          method: 'GET',
          url: `${rootApi}/sign/get-otp-bulk/${id}`,
          headers: {
            "token": tokenAccess,
            "token-peruri-sign": tokenPeruri
          }
        }).then((res) => {
          setIsSubmit(false)
          setCountdownValue(Date.now() + 60000)
          if (res.data.status != "success") {
            message.error({ content: t("Failed to get OTP") })
          }
          else {
            message.success(t("Success send OTP, please check your email."))
          }
        }).catch((error) => {
          setIsSubmit(false)
          message.error(error.message)
        })
      }
    } catch (error) {
      setIsSubmit(false)
      message.error(error)
    }

    // try {

    //   let bodySend = {
    //     doc_id: `${id}`,
    //     requested_users: getUserWithCoor(signer, deltaPosition, totalSignature),
    //     assign_type: '2',
    //     sign_type: signType === 'pararel' ? '1' : '2'
    //   }

    //   const { data: { data: dataSign } } = await axios({
    //     method: 'POST',
    //     url: `${rootApi}/sign/multi/send-doc`,
    //     data: bodySend,
    //     headers: {
    //       "token": tokenAccess,
    //       "token-peruri-sign": tokenPeruri
    //     }
    //   })

    //   setDocSign(dataSign)

    //   if (signType === 'sequential') {

    //     if (signer[0].signer_email === profile.email) {

    //       const { data: dataSend } = await axios({
    //         method: 'GET',
    //         url: `${rootApi}/sign/get-otp/${dataSign.sign_id}`,
    //         headers: {
    //           "token": tokenAccess,
    //           "token-peruri-sign": tokenPeruri
    //         }
    //       })

    //       setCountdownValue(Date.now() + 60000)

    //       setOtpPreview(true)

    //       setIsSubmit(false)
    //     }
    //     else {
    //       history.push('/sign-management')
    //     }

    //   }
    //   else {

    //     const { data: dataSend } = await axios({
    //       method: 'GET',
    //       url: `${rootApi}/sign/get-otp/${dataSign.sign_id}`,
    //       headers: {
    //         "token": tokenAccess,
    //         "token-peruri-sign": tokenPeruri
    //       }
    //     })

    //     setCountdownValue(Date.now() + 60000)

    //     setOtpPreview(true)

    //     setIsSubmit(false)
    //   }

    // } catch (e) {

    //   const { data: { error } } = e.response
    //   setPreview(false)
    //   setIsSubmit(false)
    //   message.error(error)

    // }
  }

  // Handle Preview Send Documents Process
  const handlePreviewSendDoc = async () => {
    setPreview(false);
    setOtpPreview(false)
    setIsSendDoc(true);
    setIsSetSignature(true);

    // Send Document
    const bodySend = {
      assign_type: dataDoc.assign_type,
      sign_type: signType === 'pararel' ? '1' : '2',
      requested_users: getUserWithCoor(signer, deltaPosition, totalSignature)
    }
    await axios({
      method: 'POST',
      url: `${rootApi}/sign/multi/send-doc-bulk/${dataDoc.doc_id}`,
      data: bodySend,
      headers: {
        "token": tokenAccess,
        "token-peruri-sign": tokenPeruri
      }
    }).then((res) => {
      if (res.data.status != "success") {
        setIsSendDoc(false)
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  // handle resend otp for sign
  const handleResendOtp = async () => {

    setIsSubmit(true)

    setCountdownValue(Date.now() + 60000)

    const responseGetOtp = await axios({
      method: 'GET',
      url: `${rootApi}/sign/get-otp-bulk/${dataDoc.doc_id}`,
      headers: {
        "token": tokenAccess,
        "token-peruri-sign": tokenPeruri
      }
    })

    setOtpPreview(true)
    setIsSubmit(false)
    message.success(t("Resend OTP success check your email!"))

  }


  function handleChangePage(e) {
    setPageNumber(e);
  }

  function handleMovable() {
    setMoveable(!moveable);
  }


  // delete signer request
  const deleteSigner = (data) => {

    if (listStampPosition.find(item => item.signer_name === data)) {

      setListStampPosition(prev => {

        if (prev.length === 0) {
          return []
        }
        else {

          let dataDupSign = totalSignature.filter(item => item.signer_name !== data)

          let dataDup = prev.filter(items => items.signer_name !== data)

          setTotalSignature(dataDupSign)

          return dataDup
        }

      })

    }

    let dataFilter = signer.filter(item => item.signer_name !== data)


    setSigner(dataFilter)

  }

  // add sign request
  const handleAddSign = async () => {


    if (user.signer_name === "" || user.signer_email === "") {
      message.info(t("Please add signer before assign!"))

      return
    }

    if (signer.filter(item => item.signer_email === user.signer_email).length > 0) {
      message.warning(t("User has exist"))

      return
    }
    else if (signer.length !== 0 && !totalSignature.find((item, index) => item.signer_name === signer[signer.length - 1].signer_name)) {
      message.info(t("Please add a signature before add new user!"))

      return
    }
    else {

      try {

        setLoading(true)

        const { data: { data: dataSign } } = await axios({
          method: 'GET',
          url: `${rootApi}/sign/preview-speciment/${user.user_id}`,
          headers: {
            token: tokenAccess,
            "token-peruri-sign": tokenPeruri
          }
        })

        let sign = new Image()
        let name = user.signer_name
        let email = user.signer_email
        sign.src = dataSign.base64Speciment
        sign.onload = function () {

          setSizing(prev => ([...prev, {
            name,
            email,
            signature: {
              width: sign.width,
              height: sign.height
            }
          }]))

        }
        let paraf = new Image()
        paraf.src = dataSign.base64Paraf
        paraf.onload = function () {
          setSizing(prev => {
            const item = prev[prev.length > 0 ? prev.length - 1 : 0]
            const dupItem = { ...item, paraf: { width: paraf.width, height: paraf.height } }
            const prevArr = prev.filter(item => item.name !== user.signer_name)

            return [...prevArr, dupItem]
          })
        }

        user.color = rgba[Math.floor(Math.random() * 7)]
        user.key = Math.floor(Math.random() * 100)

        setSigner(prev => [...prev, user])
        setUserSign({ signer_name: '', signer_email: '', sender_note: '', key: '', user_id: '' })
        setHaveSign(true)
        setLoading(false)
        setSignModal(false)
      } catch (e) {
        setLoading(false)
        const { data: { error } } = e.response
        message.error(error)
      }

    }

  }


  // check last signer has signed 
  const handleUpdateSign = () => {

    setUserSign({ signer_email: '', user_id: '', signer_name: '', key: '', sender_note: '' })

    let dataCopy = signer
    let name = ""

    for (let i = 0; i < signer.length; i++) {
      if (signer[i].key === signModalEdit.key) {
        name = dataCopy[i].signer_name
        dataCopy[i] = user

      }
    }

    let copyStamp = listStampPosition.map(item => {

      if (item.signer_name === name) {
        return { ...item, signer_name: user.signer_name }
      }

      return item

    })

    let copySignature = totalSignature.map(item => {

      if (item.signer_name === name) {
        return { ...item, signer_name: user.signer_name }
      }

      return item

    })


    setSigner(dataCopy)

    setListStampPosition(copyStamp)

    setTotalSignature(copySignature)
  }


  // handle edit signer request
  const handleEdit = (data) => {

    let { signer_email, user_id, signer_name, key } = signer.filter(item => item.signer_name === data)[0]

    setUserSign(
      {
        signer_email,
        user_id,
        signer_name,
        key
      }
    )

    setSignModalEdit(prev => ({ ...prev, open: true, key }))

  }


  // handle edit signer request
  const MenuItem = (data) =>
  (
    <Menu>
      <Menu.Item onClick={() => handleEdit(data)}>
        <EditOutlined /> <span className="ml-2">Edit</span>
      </Menu.Item>
      <Menu.Item onClick={() => deleteSigner(data)}>
        <DeleteOutlined style={{ color: 'red' }} /> <span className="ml-2 text-red-500">{t("Delete")}</span>
      </Menu.Item>
    </Menu>
  )

  // reset signature
  const handleReset = () => {

    if (totalSignature.length !== 0) {
      setTotalMaterai(prev => ({ ...prev, total: prev.remaining }))
      setTotalStamp(0)
      setListStampPosition([])
      setSigner(signer.filter((item) => item.signer_email == profile.email))
      setTotalSignature([])
      setDeltaPosition({})
    }
  }

  // useEffect(() => {
  //   console.log(signer);
  // }, [signer])

  // handle change add signer input
  const handleChange = (e, status = "name") => {

    if (status === "name") {
      setOpen(true)
      setUserSign(prev => ({ ...prev, signer_name: e }))
    }

    if (status === "email") {
      setOpen2(true)
      setUserSign(prev => ({ ...prev, signer_email: e }))
    }

    if (status === "note") {
      setUserSign(prev => ({ ...prev, sender_note: e }))
    }

    if (status === 'email' || status === 'name') {
      setTimeout(async () => {

        const { data: { data } } = await axios({
          method: 'GET',
          url: `${rootApi}/sign/users?search=${status === "email" ? user.signer_email : user.signer_name}`,
          headers: {
            "token": tokenAccess,
          }
        })

        setData(data)

      }, 700)
    }
  }


  // handle search option signer
  const handleSelectOption = (value, status) => {

    if (status) {
      setOpen2(false)
      setUserSign(prev => ({ ...prev, signer_email: value.email, signer_name: value.full_name, user_id: value.user_id }))
    } else {
      setOpen(false)
      setUserSign(prev => ({ ...prev, signer_email: value.email, signer_name: value.full_name, user_id: value.user_id }))
    }
  }

  // get user size sign
  const getUserSize = (name, isParaf) => {

    const item = sizing.filter(item => item.name === name)[0]

    return isParaf === true ? item.paraf : item.signature

  }

  const getLocation = async () => {

    setActiveLocation(false)

    const response = await getPosition()

    if (typeof response === 'object') {
      setLocation(response)
      setPreview(true)
      return
    }

    setLocation('')
    setIsLocationBlocked(true)

  }


  // const handler = (mouseDownEvent, index, isParaf) => {
  //   mouseDownEvent.preventDefault();
  //   const { width, height, signer_email,signer_name, paraf } = totalSignature.find((item) => item.index == index);
  //   const startSize = getSpesimenSize(signer_email, paraf == "PARAF");
  //   const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
  //   const ratio = startSize.width / startSize.height;

  //   function onMouseMove(mouseMoveEvent) {
  //     console.log("Sizing => ", sizing);
  //     console.log("Delta => ", deltaPosition);


  //     // if((startSize.width - startPosition.x + mouseMoveEvent.pageX) < 150 && (startSize.width - startPosition.x + mouseMoveEvent.pageX) > 40){

  //       const resizeValue = Math.min(mouseMoveEvent.pageX - mouseDownEvent.pageX, mouseMoveEvent.pageY - mouseDownEvent.pageY);        

  //       const currentWidth = width - ((ratio * resizeValue))
  //       const currentHeight = height - (resizeValue)
  //       let findSizing
  //       if(isParaf){
  //         findSizing = sizing.find((item) => item.email == signer_email);
  //         findSizing.paraf = {width:currentWidth, height:currentHeight}
  //       } else {
  //         findSizing = sizing.find((item) => item.email == signer_email);
  //         findSizing.signature = {width:currentWidth, height:currentHeight}
  //       }
  //       // setSizing(press => ({
  //       //   ...press,
  //       //   [index]: {email:signer_email,name:signer_name,paraf:{width:currentWidth,height:currentHeight},signature:{width:currentWidth,height:currentHeight}}
  //       // }))
  //       setDeltaPosition(deltaPosition)
  //       setTotalSignature(totalSignature.map(signature => (signature.index == index ? { ...signature, width: currentWidth, height: currentHeight } : signature)))
  //       // setDeltaPosition(deltaPosition);
  //     // }                        
  //   }
  //   function onMouseUp() {

  //     document.body.removeEventListener("mousemove", onMouseMove);        
  //     // uncomment the following line if not using `{ once: true }`
  //     // document.body.removeEventListener("mouseup", onMouseUp);

  //   }

  //   document.body.addEventListener("mousemove", onMouseMove);
  //   document.body.addEventListener("mouseup", onMouseUp, { once: true });
  // }

  // const handler = (mouseDownEvent, index, isParaf) => {
  //   mouseDownEvent.preventDefault();
  //   setIsSetResize(true)
  //   var isDown = true;
  //   const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
  //   function onMouseMove(mouseMoveEvent) {
  //     if (isDown) {
  //       const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
  //       const { width, height, signer_email, paraf } = totalSignature.find((item) => item.index == index);
  //       const defaultSize = getSpesimenSize(signer_email, paraf == "PARAF");
  //       const ratio = defaultSize.width / defaultSize.height;


  //       // const resizeValue = Math.min(mouseMoveEvent.pageX - mouseDownEvent.pageX, mouseMoveEvent.pageY - mouseDownEvent.pageY);
  //       // const currentWidth = width - ((ratio * resizeValue)) // currentWidth = width
  //       // const currentHeight = height - (resizeValue) // currentHeight = height

  //       // Mekanisme Zoom In / Zoom Out
  //       let resizeValue = 0
  //       if (currentPosition.x < startPosition.x && currentPosition.y > startPosition.y) {
  //         // Zoom In
  //         resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
  //       }
  //       else if (currentPosition.x > startPosition.x && currentPosition.y < startPosition.y) {
  //         // Zoom Out
  //         resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
  //       }
  //       const currentWidth = width + (ratio * resizeValue);
  //       const currentHeight = height + resizeValue;

  //       // Updating Size
  //       setTotalSignature(totalSignature.map(signature => (signature.index == index ? { ...signature, width: currentWidth, height: currentHeight } : signature)))

  //       // Update Delta X Positiion
  //       const deltaPos = deltaPosition[index]
  //       const delta_x_shift = currentPosition.x - startPosition.x;
  //       setDeltaPosition(prev => ({
  //         ...prev,
  //         [index]: { ...deltaPos, x: deltaPos.x + delta_x_shift }
  //       }))


  //       // let findSizing
  //       // if (isParaf) {
  //       //   findSizing = sizing.find((item) => item.email == signer_email);
  //       //   findSizing.paraf = { width: currentWidth, height: currentHeight }
  //       // } else {
  //       //   findSizing = sizing.find((item) => item.email == signer_email);
  //       //   findSizing.signature = { width: currentWidth, height: currentHeight }
  //       // }
  //     }
  //   }

  //   function onMouseUp() {
  //     setIsSetResize(false)
  //     isDown = false
  //     document.body.removeEventListener("mousemove", onmousemove);
  //   }

  //   document.body.addEventListener("mousemove", onMouseMove);
  //   document.body.addEventListener("mouseup", onMouseUp, { once: true });
  // }

  const handler0 = (mouseDownEvent, index, isParaf) => {
    mouseDownEvent.preventDefault();
    setIsSetResize(true)
    var isDown = true;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const { width, height, signer_email, paraf } = totalSignature.find((item) => item.index == index);
        const defaultSize = getSpesimenSize(signer_email, paraf == "PARAF");
        const ratio = defaultSize.width / defaultSize.height;


        // const resizeValue = Math.min(mouseMoveEvent.pageX - mouseDownEvent.pageX, mouseMoveEvent.pageY - mouseDownEvent.pageY);
        // const currentWidth = width - ((ratio * resizeValue)) // currentWidth = width
        // const currentHeight = height - (resizeValue) // currentHeight = height

        // Mekanisme Zoom In / Zoom Out
        let resizeValue = 0
        if (currentPosition.x < startPosition.x && currentPosition.y > startPosition.y) {
          // Zoom In
          resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        else if (currentPosition.x > startPosition.x && currentPosition.y < startPosition.y) {
          // Zoom Out
          resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        const currentWidth = width + (ratio * resizeValue);
        const currentHeight = height + resizeValue;

        // Updating Size
        setTotalSignature(totalSignature.map(signature => (signature.index == index ? { ...signature, width: currentWidth, height: currentHeight } : signature)))

        // Update Delta X Positiion
        const deltaPos = deltaPosition[index]
        const delta_x_shift = currentPosition.x - startPosition.x;
        setDeltaPosition(prev => ({
          ...prev,
          [index]: { ...deltaPos, x: deltaPos.x + delta_x_shift }
        }))


        // let findSizing
        // if (isParaf) {
        //   findSizing = sizing.find((item) => item.email == signer_email);
        //   findSizing.paraf = { width: currentWidth, height: currentHeight }
        // } else {
        //   findSizing = sizing.find((item) => item.email == signer_email);
        //   findSizing.signature = { width: currentWidth, height: currentHeight }
        // }
      }
    }

    function onMouseUp() {
      setIsSetResize(false)
      isDown = false
      document.body.removeEventListener("mousemove", onMouseMove);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }

  const handler1 = (mouseDownEvent, index, isParaf) => {
    mouseDownEvent.preventDefault();
    setIsSetResize(true)
    var isDown = true;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const { width, height, signer_email, paraf } = totalSignature.find((item) => item.index == index);
        const defaultSize = getSpesimenSize(signer_email, paraf == "PARAF");
        const ratio = defaultSize.width / defaultSize.height;


        // const resizeValue = Math.min(mouseMoveEvent.pageX - mouseDownEvent.pageX, mouseMoveEvent.pageY - mouseDownEvent.pageY);
        // const currentWidth = width - ((ratio * resizeValue)) // currentWidth = width
        // const currentHeight = height - (resizeValue) // currentHeight = height

        // Mekanisme Zoom In / Zoom Out
        let resizeValue = 0
        if (currentPosition.x > startPosition.x && currentPosition.y > startPosition.y) {
          // Zoom In
          resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        else if (currentPosition.x < startPosition.x && currentPosition.y < startPosition.y) {
          // Zoom Out
          resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        const currentWidth = width + (ratio * resizeValue);
        const currentHeight = height + resizeValue;

        // Updating Size
        setTotalSignature(totalSignature.map(signature => (signature.index == index ? { ...signature, width: currentWidth, height: currentHeight } : signature)))

        // Update Delta X Positiion
        const deltaPos = deltaPosition[index]
        const delta_x_shift = currentPosition.x - startPosition.x;
        setDeltaPosition(prev => ({
          ...prev,
          [index]: { ...deltaPos, x: deltaPos.x + delta_x_shift }
        }))


        // let findSizing
        // if (isParaf) {
        //   findSizing = sizing.find((item) => item.email == signer_email);
        //   findSizing.paraf = { width: currentWidth, height: currentHeight }
        // } else {
        //   findSizing = sizing.find((item) => item.email == signer_email);
        //   findSizing.signature = { width: currentWidth, height: currentHeight }
        // }
      }
    }

    function onMouseUp() {
      setIsSetResize(false)
      isDown = false
      document.body.removeEventListener("mousemove", onMouseMove);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }

  const handler2 = (mouseDownEvent, index, isParaf) => {
    mouseDownEvent.preventDefault();
    setIsSetResize(true)
    var isDown = true;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY };
        const { width, height, signer_email, paraf } = totalSignature.find((item) => item.index == index);
        const defaultSize = getSpesimenSize(signer_email, paraf == "PARAF");
        const ratio = defaultSize.width / defaultSize.height;


        // const resizeValue = Math.min(mouseMoveEvent.pageX - mouseDownEvent.pageX, mouseMoveEvent.pageY - mouseDownEvent.pageY);
        // const currentWidth = width - ((ratio * resizeValue)) // currentWidth = width
        // const currentHeight = height - (resizeValue) // currentHeight = height

        // Mekanisme Zoom In / Zoom Out
        let resizeValue = 0
        if (currentPosition.x < startPosition.x && currentPosition.y < startPosition.y) {
          // Zoom In
          resizeValue = Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        else if (currentPosition.x > startPosition.x && currentPosition.y > startPosition.y) {
          // Zoom Out
          resizeValue = -Math.min(Math.abs(currentPosition.x - startPosition.x), Math.abs(currentPosition.x - startPosition.x))
        }
        const currentWidth = width + (ratio * resizeValue);
        const currentHeight = height + resizeValue;

        // Updating Size
        setTotalSignature(totalSignature.map(signature => (signature.index == index ? { ...signature, width: currentWidth, height: currentHeight } : signature)))

        // Update Delta X Positiion
        const deltaPos = deltaPosition[index]
        const delta_x_shift = currentPosition.x - startPosition.x;
        setDeltaPosition(prev => ({
          ...prev,
          [index]: { ...deltaPos, x: deltaPos.x + delta_x_shift }
        }))


        // let findSizing
        // if (isParaf) {
        //   findSizing = sizing.find((item) => item.email == signer_email);
        //   findSizing.paraf = { width: currentWidth, height: currentHeight }
        // } else {
        //   findSizing = sizing.find((item) => item.email == signer_email);
        //   findSizing.signature = { width: currentWidth, height: currentHeight }
        // }
      }
    }

    function onMouseUp() {
      setIsSetResize(false)
      isDown = false
      document.body.removeEventListener("mousemove", onMouseMove);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }

  const handler3 = (mouseDownEvent, index) => {
    mouseDownEvent.preventDefault()
    var isDown = true
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY }

    function onMouseMove(mouseMoveEvent) {
      if (isDown) {
        const currentPosition = { x: mouseMoveEvent.pageX, y: mouseMoveEvent.pageY }
        const currentSignature = totalSignature.find((item) => item.index == index)
        const original_ratio = currentSignature.width / currentSignature.height
        const { width: boxWidth } = getBoxSize(currentSignature.width, currentSignature.height, currentSignature.frame_width_ratio)

        const x_shift = (startPosition.x - currentPosition.x) * 2

        const frame_width_ratio = currentSignature.frame_width_ratio + (x_shift / boxWidth) > original_ratio && currentSignature.frame_width_ratio + (x_shift / boxWidth) <= 3 ? currentSignature.frame_width_ratio + (x_shift / boxWidth) : currentSignature.frame_width_ratio
        setTotalSignature(
          totalSignature.map(signature => 
            (signature.index == index ? { ...signature, frame_width_ratio } : signature))
        )
      }
    }

    function onMouseUp() {
      isDown = false
      document.body.removeEventListener("mousemove", onMouseMove)
    }

    document.body.addEventListener("mousemove", onMouseMove)
    document.body.addEventListener("mouseup", onMouseUp, { once: true })
  }

  const getBoxSize = (width, height, target_width_ratio = 3) => {
    const TARGET_WIDTH_RATIO = target_width_ratio
    const current_width_ratio = width / height
    if (current_width_ratio > 1 && current_width_ratio < 3) {
      var height_bounding_box = height
      var width_bounding_box = TARGET_WIDTH_RATIO * height_bounding_box
    }
    else if (current_width_ratio > TARGET_WIDTH_RATIO) {
      var width_bounding_box = TARGET_WIDTH_RATIO * height
      if (width_bounding_box >= width) {
        var height_bounding_box = width / 3
        width_bounding_box = TARGET_WIDTH_RATIO * height
      }
      else {
        var height_bounding_box = width / TARGET_WIDTH_RATIO
        width_bounding_box = width
      }
    }
    
    else if (current_width_ratio <= 1) {
      var width_bounding_box = height * TARGET_WIDTH_RATIO
      var height_bounding_box = height
    }
    else {
      var width_bounding_box = width
      var height_bounding_box = height
    }
    return {
      width: width_bounding_box,
      height: height_bounding_box
    }
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <Row gutter={[24, 3]}>
                    <Col span={24}>
                      <h1 className="text-xl md:text-2xl font-medium">
                        {dataDoc.doc_name}
                      </h1>
                    </Col>
                    <Col>
                      <Row gutter={[6, 24]}>
                        <Col>
                          <SignWithOtherIcon />
                        </Col>
                        <Col>
                          <p className="text-xs text-blue-700">
                            {t(dataDoc.assign_type == 2 ? "Sign With Other" : dataDoc.assign_type == 3 ? "Request Sign To Other" : "Self Sign")}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                className="bg-blue-700 rounded-md md:w-40 h-10 font-medium tracking-tight"
                type="primary"
                disabled={(signer.length < 1 || openSubmit === false)}
                onClick={() => isSetSignature ? setIsSendDoc(true) : checkUserCoor()}
              >
                {t("Submit")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="relative">
            <div className="w-full bg-gray-600 rounded-t-md">
              <Row align="middle">
                <Col xs={24} md={6}>
                  <div
                    className={`bg-white rounded-t-md md:rounded-none md:rounded-tl-md w-full transition duration-500`}
                  >
                    <Segmented defaultValue={t("Preview")} size="large" options={[t("Preview"), t("Signer")]}
                      onChange={(val) => {
                        if (val == t("Preview")) {
                          setVisibleDrawer(false)
                        }
                        else {
                          setVisibleDrawer(true)
                        }
                      }} block />
                  </div>
                </Col>
                <Col xs={24} lg={20} xl={18}>
                  <Row gutter={[24, 12]} justify="center" align="middle">
                    <Col>
                      <p className="text-center text-gray-300 text-sm md:text-base">
                        {totalStamp > 0 ?
                          (signer.length > totalStamp ? `${t("You Have Added")} [${signer.length}] ${t("Request Signature!")}` : `${t("You Have Added")} [${totalStamp}] ${t("Signature!")}`) :
                          (signer.length > 0 ? `${t("You Have Added")} [${signer.length}] ${t("Request Signature!")}` : `${t('Please Add Your Signature')}`)
                        }
                      </p>
                    </Col>
                    <Col>
                      <Row
                        className="pr-4 pb-2 md:pb-0"
                        gutter={[24, 24]}
                        justify="center"
                        align="middle"
                      >
                        {/* {dataDoc.typeSign == 0 && (
                          <Col>
                            <div className="flex flex-row bg-blue-400 text-white rounded-full px-2 py-1 justify-center items-center">
                              <SignIcon />
                              <Divider className="h-4 bg-white" type="vertical" />
                              <p className="font-medium">{numberCommas(totalMaterai.total)}</p>
                            </div>
                          </Col>
                        )}
                        <Col>
                          <div className="flex flex-row bg-blue-400 text-white rounded-full px-2 py-1 justify-center items-center">
                            <CapIcon />
                            <Divider className="h-4 bg-white" type="vertical" />
                            <p className="font-medium">{numberCommas(totalMaterai.total)}</p>
                          </div>
                        </Col> */}
                        <Col>
                          <div className="flex flex-row bg-blue-400 text-white rounded-full px-2 py-1 justify-center items-center">
                            <SignIcon />
                            <Divider className="h-4 bg-white" type="vertical" />
                            <p className="font-medium">{numberCommas(totalMaterai.total)}</p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <Row>
            <Col xs={24} md={6} hidden={visibleDrawer}>
              <div
                className="bg-gray-100 rounded-b-md overflow-auto custom-scrollbar shadow-md pb-6"
                style={{ height: "80vh" }}
              >
                <Collapse defaultActiveKey={["1"]} ghost>
                  <Panel
                    className="bg-gray-100 border-l-4 border-blue-500 font-medium"
                    header={dataDetail?.name}
                    key="1"
                  >
                    <div className="relative">
                      <ul className="flex flex-col space-y-2">
                        {dataDetail?.docs?.map((item, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() => setIdDocument(item.doc_id)}
                            >
                              <Tooltip title={item.doc_name}>
                                <p
                                  className={`cursor-pointer rounded-md p-2 hover:bg-gray-300 truncate ${item.doc_id === idDocument &&
                                    "bg-gray-200 font-medium border-l-4 border-gray-800"
                                    }`}
                                >
                                  {item.doc_name}
                                </p>
                              </Tooltip>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </Col>
            <Col xs={24} md={6} hidden={!visibleDrawer}>
              <div
                className="bg-gray-100 rounded-b-md overflow-auto custom-scrollbar shadow-md pb-6"
                style={{ height: "80vh" }}
              >
                <div className="p-8 pl-6 pr-4 h-full">
                  <div className={`flex flex-row items-center ${signer.length > 0 ? 'h-14' : ''}`}>
                    <AccountMultiplePlusBlack className="text-sm" />
                    {
                      signer.length > 0 ?
                        <Avatar.Group
                          maxCount={2}
                          size="large"
                          className="ml-2"
                          maxStyle={
                            {
                              color: '#f56a00',
                              backgroundColor: '#fde3cf',
                              cursor: 'pointer',
                              height: '32px',
                              width: '32px',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                        >
                          {
                            signer.length > 0 && signer.map((item, index) =>
                              <Avatar key={index} style={{ border: 'none ' }} className="bg-green-600 h-8 w-8 flex items-center" >
                                {item.signer_email.slice(0, 2)}
                              </Avatar>
                            )
                          }
                        </Avatar.Group>
                        :
                        <p className="text-center p-3 text-sm">
                          {t("No other signer")}
                        </p>
                    }
                  </div>
                  <p className="mb-4 text-sm font-normal font-sans">{t("Signing Type")}</p>

                  <Radio.Group onChange={(e) => setSignType(e.target.value)} value={signType}>
                    <Radio style={{ marginRight: '12px', color: '#323331', letterSpacing: 0.5 }} value="pararel" className={signType === 'pararel' ? 'font-medium text-base font-sans' : 'font-normal text-base font-sans '}>
                      {t("Parallel")}
                    </Radio>

                    <Radio style={{ letterSpacing: 0.5 }} value="sequential" className={signType === 'sequential' ? 'font-medium text-base font-sans' : 'font-normal text-base font-sans '}>
                      {t("Sequential")}
                    </Radio>
                  </Radio.Group>

                  <Divider />

                  {signer.length === 0 && <p style={{ color: '#8B8B8B', letterSpacing: '0.5px' }} className="font-sans font-normal text-sm text-center">{t("Please add signer")}</p>}

                  <div style={{ maxHeight: '500px' }} className="overflow-y-scroll relative">

                    <div className="w-full list-drag">
                      {
                        signer.map((item, index) => (
                          <div style={{ borderLeft: `4px solid ${item.color}` }} className="card-sign box-border bg-gray-50 rounded-md mb-3">
                            <div className="card-sign-wrap box-border pt-4 pb-2 pr-4">
                              <div className="card-sign-header box-border flex flex-row pl-10 justify-between items-center">
                                <h2 style={{ letterSpacing: 0.5 }} className="text-black text-base font-medium font-sans">{`${t("Signature")} (${index + 1})`}</h2>
                                <div>
                                  <Dropdown trigger={['click']} overlay={MenuItem(item.signer_name)}>
                                    <SettingSign className="cursor-pointer" />
                                  </Dropdown>
                                </div>
                              </div>
                              <Divider className="my-2" />
                              <div className="card-sign-content pl-10 relative">
                                <img src={Dotted} className="absolute left-3 top-5" />
                                <div className="card-sign-name w-full truncate overflow-x-hidden mb-2">
                                  <span className="mr-3 text-gray-500 font-normal text-sm tracking-tight">{t("Name")}</span>
                                  <span style={{ color: '#323331' }} className="truncate text-sm font-sans font-normal tracking-tight">{item.signer_name}</span>
                                </div>
                                <div className="w-full truncate overflow-x-hidden mb-2">
                                  <span className="mr-3 text-gray-500 text-sm tracking-tight">{t("Email")}</span>
                                  <span style={{ color: '#323331' }} className="truncate text-sm font-sans font-normal tracking-tight">{item.signer_email}</span>
                                </div>
                                <div className="w-full truncate overflow-x-hidden mb-2">
                                  <span className="mr-3 text-gray-500 text-sm tracking-tight">{t("Note")}</span>
                                  <span style={{ color: '#323331' }} className="truncate text-sm font-sans font-normal tracking-tight">{item.sender_note}</span>
                                </div>
                              </div>
                            </div>
                          </div>))}
                    </div>
                  </div>
                  <ButtonPrimary
                    onClick={() => setSignModal(!signModal)}
                    className="w-4/5 absolute bottom-8 translate-x-2 h-10"
                    icon={<PlusOutlined />}>
                    <span>{t("Add Signer")}</span>
                  </ButtonPrimary>
                </div>
              </div>
            </Col>
            <Col xs={24} lg={20} xl={18}>
              <div
                className="bg-gray-300 rounded-b-md overflow-auto custom-scrollbar shadow-md pb-6"
                style={{ height: "80vh" }}
              >
                <div className="flex flex-col space-y-4 justify-end items-end fixed mt-8 right-10 md:right-20 lg:right-32 z-50">
                  {openSign === true ?
                    <div className={`relative rounded-full top-0 ${!done ? 'cursor-pointer' : null}`} onClick={() => setOpenSign(false)}>
                      <img src={CloseIcon} alt="" />
                    </div> : null
                  }
                  <div
                    className="flex flex-col text-white justify-center items-center duration-700 transition"
                    onClick={() => isHaveSign ? setOpenSign(true) : null}
                  >
                    <Fade>
                      <div className={`${openSign > 0 || loading ? "hidden" : "flex"} flex-row bg-blue-400 hover:bg-blue-300 rounded-full p-2 ${isHaveSign ? 'cursor-pointer' : ''} justify-center items-center`}>
                        <img
                          className="transition duration-500 ease-in-out"
                          src={SignSvg}
                          alt="qr-icon"
                          width="35px"
                        />
                      </div>
                    </Fade>
                    <Fade>
                      <div
                        onClick={() => {
                          const lastIndex = getLastArrayValue(totalSignature) ? getLastArrayValue(totalSignature)?.index : 0;
                          return signer.length > 0
                            ? handleAddSignature(lastIndex + 1)
                            : message.info('add signer first')
                        }}
                        style={{ background: '#f4f4f4', minWidth: '100%' }}
                        className={`${openSign ? "flex" : "hidden"} flex-row items-center p-2 rounded-t-lg cursor-pointer`}
                      >
                        <div className="rounded-full bg-blue-400 mr-4 w-8 h-8 flex items-center justify-center">
                          <img
                            className="transition duration-500 ease-in-out"
                            src={SignSvg}
                            alt="qr-icon"
                            width="24px"
                          />
                        </div>
                        <span className="text-blue-400 text-xl font-medium ">{t("Signature")}</span>
                      </div>

                    </Fade>
                    <Divider className="my-0" />
                    <Fade>
                      <div
                        onClick={() => {
                          const lastIndex = getLastArrayValue(totalSignature) ? getLastArrayValue(totalSignature)?.index : 0;
                          return signer.length > 0
                            ? handleAddSignature(lastIndex + 1, true)
                            : message.info('add signer first')
                        }}
                        style={{ background: '#f4f4f4', minWidth: '100%' }}
                        className={`${openSign ? "flex" : "hidden"} flex-row items-center p-2 cursor-pointer`}
                      >
                        <div className="rounded-full bg-blue-400 mr-4 w-8 h-8 flex items-center justify-center">
                          <img
                            className="transition duration-500 ease-in-out"
                            src={SignSvg}
                            alt="qr-icon"
                            width="24px"
                          />
                        </div>
                        <span className="text-blue-400 text-xl font-medium ">{t("Paraf")}</span>
                      </div>

                    </Fade>
                  </div>

                  {/* <Fade>
                <div 
                    onClick={() => handleAddSignature(totalSignature.length)}
                   style={{minWidth : '100%' , background : '#f4f4f4' , marginTop : 0 , marginBottom : 0}} 
                   className={`${ openSign > 0 ? "flex" : "hidden"} flex-row items-center p-2 bg-green-500 cursor-pointer my-0`}>
                  <div className="rounded-full bg-blue-400 mr-4 w-8 h-8 flex items-center justify-center">
                    <img
                      className="transition duration-500 ease-in-out" 
                      src={SignSvg}
                      alt="qr-icon"
                      width="24px"
                    />
                  </div>
                  <span className="text-blue-400 text-xl font-medium">Initial</span>
                </div>
              </Fade>
              <Divider style={{marginTop : 0 , marginBottom : 0}} className="my-0" /> */}
                  {/* <Fade>
                <div
                   onClick={handleSave} 
                   style={{minWidth : '100%' , background : '#f4f4f4' , marginTop : 0 , marginBottom : 0}} 
                   className={`${ totalSignature.length > 0 ? "flex" : "hidden"} flex-row items-center p-2 bg-green-500 cursor-pointer my-0`}>
                  <div className="rounded-full bg-green-400 mr-4 w-8 h-8 flex items-center justify-center">
                    <img
                      className="transition duration-500 ease-in-out" 
                      src={DoneIcon}
                      alt="qr-icon"
                      width="20px"
                    />
                  </div>
                  <span className="text-green-400 text-xl font-medium">Done</span>
                </div>
              </Fade> */}
                  <Divider style={{ marginTop: 0, marginBottom: 0 }} className="my-0" />
                  <Fade>
                    <div
                      onClick={handleReset}
                      style={{ minWidth: '100%', background: '#f4f4f4', marginTop: 0, marginBottom: 0 }}
                      className={`${openSign > 0 ? "flex" : "hidden"} flex-row items-center p-2 bg-green-500 cursor-pointer `}>
                      <div className="rounded-full bg-red-500 mr-4 w-8 h-8 flex items-center justify-center">
                        <img
                          className="transition duration-500 ease-in-out"
                          src={ResetIcon}
                          alt="qr-icon"
                          width="20px"
                        />
                      </div>
                      <span className="text-red-500 text-xl font-medium">{t("Reset")}</span>
                    </div>
                  </Fade>
                  <Divider style={{ marginTop: 0, marginBottom: 0 }} className="my-0" />
                  <Fade>
                    <div
                      onClick={() => history.push('/sign-management/edit-signature')}
                      style={{ minWidth: '100%', background: '#f4f4f4', marginTop: 0, marginBottom: 0 }}
                      className={`${openSign > 0 ? "flex" : "hidden"} flex-row items-center p-2 rounded-b-lg bg-green-500 cursor-pointer `}>
                      <div className="rounded-full bg-gray-300 mr-4 w-8 h-8 flex items-center justify-center">
                        <img
                          className="transition duration-500 ease-in-out"
                          src={SettingIcon}
                          alt="qr-icon"
                          width="20px"
                        />
                      </div>
                      <span className="text-gray-500 text-sm font-medium">{t("Edit Signature")}</span>
                    </div>
                  </Fade>
                </div>
                <div>
                  <Row className='flex p-8 justify-center' gutter={16}>
                    <Col span={24} className="flex justify-center">
                      <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page /**renderMode="svg"*/ pageNumber={pageNumber}>
                          {totalSignature
                            .filter(item => item.page === pageNumber)
                            .map((item, index) => {
                              return (
                                <Draggable
                                  key={item.index}
                                  disabled={isSetSignature}
                                  position={deltaPosition[item.index]}
                                  onStart={(e) => {
                                    if (isSetResize) {
                                      return false
                                    }
                                    return e
                                  }}
                                  onStop={(e, position) => handleDrag(e, position, item.index, item.paraf === "PARAF" ? true : false, item.signer_name)}
                                  bounds="parent"
                                >
                                  <div
                                    className={clsx(
                                      "absolute",
                                      moveable ? "cursor-move" : "cursor-pointer"
                                    )}
                                    // style={{ width: item.width, height: item.height }}
                                    style={getBoxSize(item.width, item.height, item.frame_width_ratio)}
                                    onDoubleClick={handleMovable}
                                  >

                                    <div style={{ background: item.rgb, fontSize: 10 }} className="absolute top-0 inset-x-0 mb-1 overflow-hidden rounded inline">
                                      <span className="text-white px-1 truncate w-full whitespace-nowrap overflow-hidden">
                                        {item.paraf === "PARAF" ? "Paraf" : "Signature"} ({index + 1}) {item.signer_name}
                                      </span>
                                    </div>

                                    <div
                                      className="absolute -top-3 -right-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                      onClick={() => handleCloseSignature(item.index, item.signer_name, item.paraf === "PARAF" ? true : false)}
                                    >
                                      <CloseOutlined className="font-medium" />
                                    </div>
                                    <div style={{ WebkitUserDrag: "none", height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                      {item.signer_email === profile.email ? <img style={{ height: item.height, width: item.width }} className="drag-none" src={item.paraf === "PARAF" ? profile.imgParaf : profile.imgSign} alt="" /> : <img style={{ height: item.height, width: item.width }} className="drag-none" src={Unstamp} alt="" />}
                                    </div>
                                    {/* <button
                                      className="absolute -bottom-3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                      type="button" onMouseDown={(onMouseDown) => handler(onMouseDown, item.index, item.paraf === "PARAF" ? true : false)}>
                                      <ArrowsAltOutlined className="font-medium" />
                                    </button> */}
                                    <button
                                      className="absolute -top-3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                      type="button" onMouseDown={(onMouseDown) => handler2(onMouseDown, item.index, item.paraf === "PARAF" ? true : false)}>
                                      <ArrowsAltOutlined style={{ transform: 'rotate(90deg)' }} className="font-medium" />
                                    </button>
                                    <button 
                                      className="absolute top-1/3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                      type="button" onMouseDown={(onMouseDown) => handler3(onMouseDown, item.index)}>
                                        <ArrowsAltOutlined style = {{transform: 'rotate(45deg)' }} className="font-medium" />
                                    </button>
                                    <button
                                      className="absolute -bottom-3 -left-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                      type="button" onMouseDown={(onMouseDown) => handler0(onMouseDown, item.index, item.paraf === "PARAF" ? true : false)}>
                                      <ArrowsAltOutlined className="font-medium" />
                                    </button>
                                    <button
                                      className="absolute -bottom-3 -right-3 bg-red-500 hover:bg-red-400 text-white p-1 rounded-full flex justify-center items-center cursor-pointer"
                                      type="button" onMouseDown={(onMouseDown) => handler1(onMouseDown, item.index, item.paraf === "PARAF" ? true : false)}>
                                      <ArrowsAltOutlined style={{ transform: 'rotate(90deg)' }} className="font-medium" />
                                    </button>
                                  </div>
                                </Draggable>
                              );
                            })
                          }
                        </Page>
                      </Document>
                    </Col>
                  </Row>
                  <div className="flex flex-row justify-center items-center space-x-2 bg-gray-100 shadow-md absolute bottom-5 right-5 p-2 rounded-md">
                    <Select
                      value={pageNumber}
                      size="small"
                      onChange={handleChangePage}
                    >
                      {Array.from(new Array(numPages), (_, index) => (
                        <Option value={index + 1}>{index + 1}</Option>
                      ))}
                    </Select>
                    <p>{`dari ${numPages} halaman`}</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        {/* Success Modal */}
        <Modal
          visible={successModal}
          title={t("Success")}
          subtitle={t("The document has been send to other! Now other can sign with this document")}
          // onCancel={() => setsuccessModal(false)}
          type="success"
        >
          <div className="flex flex-row space-x-4 gap-3">

            <Button
              type="primary"
              className="w-full h-11"
              onClick={() => history.replace('/sign-management')}
            >
              {t("Done")}
            </Button>
          </div>
        </Modal>
        {/* OTP */}
        <Modal
          type="warning"
          visible={previewModal}
          onCancel={() => {
            setPreview(false)
            setOtpValue("")
          }}
          title={t("Are you sure?")}
          subtitle={!otpPreview ?
            t(`After the data has been submit, you cannot change the data. Please make sure the data is correct.`)
            :
            t(`Please make sure your data is correct. OTP Code automatic has sent to your email, let’s check!`)
          }
        >
          {!otpPreview ?
            <>
              <Spinner loading={isSubmit} />
              <div style={{ marginTop: 40 }} className="w-full mt-10">
                <div className="flex justify-between mb-5">
                  <span className="text-gray-500 tracking-tight">
                    {t("Document Name")}
                  </span>
                  <span className="font-medium tracking-tight">
                    {dataDoc?.doc_name}
                  </span>
                </div>
                {dataDoc.typeSign == 0 && (
                  <div className="flex justify-between mb-5 tracking-tight">
                    <span className="text-gray-500 tracking-tight">
                      {t("Sign Category")}
                    </span>
                    <span className="font-medium tracking-tight">
                      {t("Bulk Sign")}
                    </span>
                  </div>
                )}
                {dataDoc.typeSign == 0 && (
                  <div className="flex justify-between mb-5 tracking-tight">
                    <span className="text-gray-500 tracking-tight">
                      {t("Signing Type")}
                    </span>
                    <span className="font-medium tracking-tight">
                      {t(signType === 'pararel' ? "Parallel" : "Sequential")}
                    </span>
                  </div>
                )}
                <div className="flex justify-between mb-4 tracking-tight">
                  <span className="text-gray-500 tracking-tight">
                    {t(dataDoc.typeSign == 0 ? "Total Signature" : "Total Tera")}
                  </span>
                  <span className="font-medium tracking-tight">
                    {totalSignature.length}
                  </span>
                </div>
                <Row>
                  <Col span={24}>
                    <span className="text-gray-500 tracking-tight mb-2">
                      {t("Note")}
                    </span>

                    <TextArea showCount maxLength={100} className="mt-2" onChange={(data) => setReason(data.target.value)} disabled={isSubmit} name="note" rows={4} />

                  </Col>
                </Row>
                <Row style={{ marginTop: 24 }} gutter={[12, 0]}>
                  <Col span={12}>
                    <Button
                      ghost
                      onClick={() => {
                        setPreview(!previewModal)
                        setOtpValue("")
                        setOtpPreview(otpPreview)
                      }}
                      className=" w-full h-10 font-medium tracking-tight"
                      disabled={isSubmit}
                    >
                      <span>{t("Back")}</span>
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      className="w-full h-10 font-medium tracking-tight"
                      type="primary"
                      // onClick={() => handlePreviewOtp(dataDoc?.doc_id)}
                      onClick={() => sendSuccess > 0 && sendSuccess == dataDetail?.docs?.length ? handlePreviewOtp(dataDoc?.doc_id) : handlePreviewSendDoc()}
                      disabled={isSubmit}
                    >
                      <span>{t("Next")}</span>
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
            :
            <>
              <Spinner loading={isSubmit} />
              <div className="flex flex-col space-y-4">
                <div className="flex flex-row justify-between">
                  <p>{t("OTP Code")}</p>
                  <Countdown date={countdownValue} renderer={renderer} />
                </div>
                <OtpInput
                  value={otpValue}
                  onChange={(otp) => setOtpValue(otp)}
                  numInputs={6}
                  isInputNum
                  className="text-gray-800 mb-4"
                  containerStyle={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  inputStyle={{
                    width: "3rem",
                    height: "3rem",
                    fontWeight: "bold",
                    border: "2px solid #DBDBDB",
                    borderRadius: 4,
                    color: "#000000",
                  }}
                />
                <Form onFinish={handleSubmit} autoComplete={false}>
                  <Form.Item>
                    <div className="flex flex-row space-x-4">
                      <Button
                        onClick={() => {
                          setPreview(false)
                          setOtpValue("")
                          setOtpPreview(!otpPreview)
                        }}
                        className=" w-full h-10 font-medium tracking-tight"
                        ghost
                        type="secondary"
                        disabled={isSubmit}
                      >
                        {t("Back")}
                      </Button>
                      <Button
                        disabled={otpValue?.length < 6 || isSubmit}
                        className="w-full h-10 font-medium tracking-tight"
                        type="primary"
                        htmlType="submit"
                      >
                        {t("Submit")}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
                <p className="text-center">
                  {t("But, if get a problem you can")}
                  <span
                    onClick={handleResendOtp}
                    type="text"
                    disabled={isResendOtp}
                    className="text-blue-500 hover:text-blue-400 px-1 cursor-pointer hover:underline duration-500"
                  >
                    {t("Resend")}
                  </span>
                  {t("the otp code again")}
                </p>
                <Divider />
                <div className="text-center">
                  <p className="text-gray-500 text-xs">
                    {t("By pressing the ‘Submit’ button means that you have agreed to the")}
                  </p>
                  <p className="text-blue-500 text-xs">{t("Terms & Conditions")}</p>
                </div>
              </div>
            </>
          }
        </Modal>
        {/* Send Document */}
        <Modal
          visible={isSendDoc}
          title={t("Send Document")}
          subtitle={t("Your document is being sent to the other signer to sign this document.")}
          onCancel={() => setIsSendDoc(false)}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Progress
                type="line"
                strokeWidth={20}
                // percent={parseInt(sendSuccess * 100 / dataDetail?.docs?.length)}
                percent={(Number(sendSuccess) / Number(dataDetail?.total_docs || 0) * 100)}
                format={percent => `${sendSuccess}/${Number(dataDetail?.total_docs || 0)}`}
              />
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <div className="flex justify-center">
              <Button
                className="bg-blue-700 rounded-md text-white w-40"
                onClick={() => {
                  if (dataDoc.assign_type == 3 || (signType == 'sequential' && signer[0].signer_email != profile.email)) {
                    setIsSendDoc(false)
                    setsuccessModal(true)
                    setPreview(false)
                    return;
                  }
                  handlePreviewOtp(dataDoc.doc_id)
                }}
                // onClick={() => history.replace('/sign-management')}
                disabled={sendSuccess < Number(dataDetail?.total_docs || 0)}
              >
                {t(dataDoc.assign_type == 3 || (signType == 'sequential' && signer[0].signer_email != profile.email) ? "Done" : "Next")}
              </Button>
            </div>
          </Row>
        </Modal>
        <Modal
          visible={activeLocation}
          type="warning"
          title={t("Confirmation Location")}
          subtitle={t("For more accurate data certificate in document, turn on device location.")}
          onCancel={() => setActiveLocation(false)}
        >
          <div className="flex justify-center">
            <Button onClick={() => setActiveLocation(false)} ghost type="primary" className="mt-9 mb-9 h-10 rounded-md w-52 tracking-tight font-medium mr-4">{t("No")}</Button>
            <Button onClick={() => getLocation()} type="primary" className="mt-9 mb-9 h-10 rounded-md w-52 tracking-tight font-medium">{t("Yes")}</Button>
          </div>
        </Modal>
        <ModalAntd
          visible={signModal}
          footer={false}
          onCancel={() => {
            setSignModal(!signModal)
            setOpen(false)
            setOpen2(false)
          }}
          width={500}
        >

          <Row>
            <Col span={24} className="p-4" onClick={() => {
              setOpen(false)
              setOpen2(false)
            }}>
              <h2 style={{ color: '#5C5F5B' }} className="text-2xl mb-8 font-medium">{t("Add Signer for Assign Document")}</h2>

              <Row>
                <Col span={24} className="mb-8">
                  <Row>
                    <Col span={24} className="mb-2"> <span className="text-sm font-normal font-sans tracking-tight">{t("Signer Name")}*</span></Col>
                    <Col span={24}>
                      <div className={'w-full'}>
                        <input placeholder={t("Enter Signer Name")} value={user.signer_name} style={{ outline: '1px solid #8080807a' }} className='w-full px-4 py-1.5 h-7' onChange={e => handleChange(e.target.value)} />
                        {
                          data && open &&
                          <div style={{ background: '#e5e5e5', minHeight: 170 }} className='max-h-52 overflow-y-scroll no-scrollbar relative z-10'>
                            {data.map((item, index) => {
                              return <p onClick={() => handleSelectOption(item)} className='px-2 py-2 tracking-tight font-medium z-10 text-gray-500 cursor-pointer hover:bg-white'>{item.full_name}</p>
                            })}
                            {/* <p className=' px-2 py-2 tracking-tight font-medium z-10 text-gray-500 cursor-pointer hover:bg-white'>A</p>
                                  <p className=' px-2 py-2 tracking-tight font-medium z-10 text-gray-500 cursor-pointer hover:bg-white'>A</p>
                                  <p className=' px-2 py-2 tracking-tight font-medium z-10 text-gray-500 cursor-pointer hover:bg-white'>A</p>
                                  <p className=' px-2 py-2 tracking-tight font-medium z-10 text-gray-500 cursor-pointer hover:bg-white'>A</p>
                                  <p className=' px-2 py-2 tracking-tight font-medium z-10 text-gray-500 cursor-pointer hover:bg-white'>A</p> */}
                          </div>
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} className="mb-8">
                  <Row>
                    <Col span={24} className="mb-2"> <span className="text-sm font-normal font-sans tracking-tight">{t("Email Address for Signer")}*</span></Col>
                    <Col span={24}>
                      <div className={'w-full'}>
                        <input placeholder={"Enter Email"} value={user.signer_email} style={{ outline: '1px solid #8080807a' }} className='w-full px-4 py-1.5 h-7' onChange={e => handleChange(e.target.value, "email")} />
                        {
                          data && open2 &&
                          <div style={{ background: '#e5e5e5', minHeight: 170 }} className='max-h-52 overflow-y-scroll no-scrollbar relative z-10'>
                            {data.map((item, index) => {
                              return <p onClick={() => handleSelectOption(item, true)} className='px-2 py-2 tracking-tight font-medium z-10 text-gray-500 cursor-pointer hover:bg-white'>{item.email}</p>
                            })}
                          </div>
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} className="mb-24">
                  <Row>
                    <Col span={24} className="mb-2"> <span className="text-sm font-normal font-sans tracking-tight">{t("Note")} <span className="text-gray-400y">({t("optional")})</span> </span></Col>
                    <Col span={24}>
                      <TextArea
                        rows={6}
                        placeholder={t("Enter Note")}
                        value={user.sender_note}
                        onChange={(e) => handleChange(e.target.value, "note")}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24} className="mb-8">
                  <Row gutter={[20, 10]}>
                    <Col span={12} className="mb-2">
                      <Button onClick={() => setSignModal(false)} style={{ outlineColor: 'gray' }} className=" w-full h-10 outline outline-gray-500 font-medium tracking-tight" ghost>
                        {t("Cancel")}
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button onClick={handleAddSign} type="primary" className="w-full h-10 font-medium tracking-tight">
                        {t("Assign")}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

        </ModalAntd>
      </Row>
      <Spinner loading={loading} />
    </>
  );
}