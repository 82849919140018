import React from "react";
import { ReactComponent as NotFoundIlustration } from "../../assets/icons/undraw_page_not_found.svg";
import { Button } from "../../components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function NotFound() {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <NotFoundIlustration className="w-96 -mt-40" />
      <h1 className="font-bold text-xl md:text-3xl text-blue-600">{t("Page Not Found")}</h1>
      <p className="text-gray-500 font-semibold">{t("The Page You're Looking For Was Not Found!")}</p>
      <Button onClick={() => history.goBack()} type={'primary'} className="mt-12 w-72">{t("Go Back")}</Button>
    </div>
  );
}
