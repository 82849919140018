import { FilterOutlined, InboxOutlined, RightOutlined, SettingOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Divider,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Tag,
} from "antd";
import moment from "moment";
import axios from 'axios'
import { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as AlertIcon } from "../../assets/icons/alert-icon.svg";
import { ReactComponent as CheckCircleIcon } from "../../assets/icons/check-circle-outline.svg";
import { ReactComponent as FilterOutline } from "../../assets/icons/filter-outline.svg";
// import { ReactComponent as QrCodeIcon } from "../../assets/icons/qrcode.svg";
import { ReactComponent as SelfSign } from "../../assets/icons/self-sign.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-circle-outline.svg";
import { ReactComponent as CapIcon } from "../../assets/icons/cap.svg";

import { Button, Modal as ModalComponent, Spinner } from "../../components";
import { useFetchListDocumentQuery } from "../../config/features/document";
// import { useFetchListOfficeQuery } from "../../config/features/office";
import { useFetchSignListQuery } from '../../config/features/signmanagement'
import { errorMessage, numberCommas } from "../../utils";
import { ReactComponent as SignIcon } from '../../assets/icons/sign.svg'
import { rootApi } from "../../config/features/config";
import { roleApi, useFetchProfileUserQuery, useUploadOfficeLogoMutation } from "../../config/features";
import { useTranslation } from "react-i18next";
import { isFullAccess, isHeadquarter, isPic } from "../../utils/auth";
import ImgCrop from "antd-img-crop";
import Dragger from "antd/lib/upload/Dragger";

const { Search } = Input;
const { Option } = Select;

export default function StampManagement() {
  const tokenPeruri = useMemo(() => localStorage.getItem('peruri'), [])
  const tokenAccess = useMemo(() => localStorage.getItem('access'), [])
  const history = useHistory();
  const { t } = useTranslation();
  const [isModalAlert, setIsModalAlert] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [isModalDocument, setIsModalDocument] = useState(false);
  const [isModalSignature, setIsModalSignature] = useState(false);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [visibleFilterModal, setVisibleFilterModal] = useState(false);
  const [current, setCurrent] = useState('0')
  const [dataDoc, setDataDoc] = useState({})
  const [totalMaterai, setTotalMaterai] = useState(null)
  const [loading, setLoading] = useState(false)
  // 0 : Signature Only, 1 : Tera Only, 2 : Signature and Tera
  const [typeSign, setTypeSign] = useState(0);
  const [openUpload, setOpenUpload] = useState(false);
  const [fileList, setFileList] = useState([])

  const [
    uploadOfficeLogo,
    {
      isSuccess: isSuccessUploadLogo,
      isLoading: isLoadingUploadLogo,
      isError: isErrorUploadLogo,
      error: errorUploadLogo,
      data: responseUploadLogo
    }
  ] = useUploadOfficeLogoMutation();

  useEffect(() => {
    if (isSuccessUploadLogo) {      
      message.success('Success upload logo office')
      window.location.reload();
    }
  }, [isSuccessUploadLogo])

  useEffect(() => {
    if (isErrorUploadLogo) {
      errorMessage(errorUploadLogo)
    }
  }, [isErrorUploadLogo, errorUploadLogo])

  // params filter for document list
  const [paramsTable, setParamsTable] = useState({
    row: 10,
    page: 1,
    office: "",
    sort_by: "created_at",
    order: "desc",
    stamp_status:"",
    stamped: "",
    stamp_status: "",
    is_signed: "",
    tera_stamped: 2,
    is_draft: ""
  });


  // parameter list user sign 
  const [paramsTableSign, setParamsTableSign] = useState({
    row: 10,
    page: 1,
    status: current,
    sort_by: "updated_at",
    order: "desc",
    search: null
  })


  const {
    data: dataListDocument = [],
    isFetching,
  } = useFetchListDocumentQuery(paramsTable);

  const {
    data: dataSignList,
    isFetch,
    refetch
  } = useFetchSignListQuery(paramsTableSign)

  // get user profile
  const {
    data: userProfile = {},
    isError: isErrorUser,
    error: errorUser,
  } = useFetchProfileUserQuery();




  useEffect(() => {
    refetch();

  }, [refetch, current, paramsTableSign]);



  // get peruri token from peruri 
  useEffect(() => {

    async function getPeruriToken() {
      try {
        setLoading(true)
        const { data: { data } } = await axios({
          method: 'GET',
          url: `${rootApi}/sign/token`,
          headers: {
            token: tokenAccess
          }
        })
        localStorage.setItem('peruri', data.token_peruri_sign)
        setLoading(false)
      } catch (e) {
        const { data: { error } } = e.response
        message.error(error)
        setLoading(false)
      }
    }

    if (!tokenPeruri) {
      getPeruriToken()
    }

  }, [])


  // get quota materai 
  useEffect(() => {
    async function getQuoteMeterai() {

      const { data: { data } } = await axios({
        method: 'GET',
        url: `${rootApi}/quota`,
        headers: {
          token: tokenAccess
        }
      })

      setTotalMaterai(data[1].remaining)
    }
    getQuoteMeterai()

  }, [])




  function handleCancel() {
    setIsModalDelete(false);
  }

  async function handleDelete() {
    try {
      setIsModalDelete(false);
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleDone() {
    setIsModalSuccess(false);
  }

  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }

  function handleVisibleFilterModal(flag) {
    setVisibleFilterModal(flag);
  }

  function handleModalDocument() {
    setIsModalDocument(!isModalDocument);
    // console.log("Signature Type : ", typeSign);
  }

  function handleModalSignature() {
    setIsModalSignature(!isModalSignature);
  }

  function handleModalAlert() {
    setIsModalAlert(!isModalAlert);
  }

  // list type sign document
  const modalSignatureContent = [
    {
      title: t("Self Sign"),
      subtitle: t("Sign a document"),
      icon: <SelfSign />,
      disabled: 'flex',
      handleClick: () => dataDoc.is_folder ? history.push(`/sign-management/self-sign-bulk/${dataDoc.doc_id}`, { dataDoc }) : history.push("/sign-management/self-sign", { dataDoc }),
    },
    {
      title: t("Sign With Other"),
      subtitle: t("Sign your document and invite others to sign"),
      icon: <SelfSign />,
      disabled: typeSign != 0 ? 'hidden' : 'flex',
      handleClick: () => {
        dataDoc.assign_type = 2;
        dataDoc.is_folder ? history.push("/sign-management/sign-with-other-bulk", { dataDoc, assign_type: 2 }) : history.push("/sign-management/sign-with-other", { dataDoc })
      },
    },
    {
      title: t("Request Sign To Other"),
      subtitle: t("Share your document with the recipient to sign"),
      icon: <SelfSign />,
      disabled: typeSign != 0 ? 'hidden' : 'flex',
      handleClick: () => {
        dataDoc.assign_type = 3;
        dataDoc.is_folder ? history.push("/sign-management/sign-with-other-bulk", { dataDoc, assign_type: 3 }) : history.push("/sign-management/request-sign", { dataDoc })
      },
    },
  ];

  // type menu list for sign 
  const itemsMenu = [
    {
      label: t("Request Sign"),
      key: '0'
    },
    {
      label: t("Pending"),
      key: '1'
    },
    {
      label: t("Completed"),
      key: '2'
    }
  ]

  // column table list sign
  const columnsReq = [
    {
      title: t("From"),
      dataIndex: "creator",
      key: "creator",
      render: (value, record) => <p style={{ borderLeft: `${record.is_read === false ? '5px solid #48A1D9' : ''}` }} className={`${record.is_read === false ? 'font-medium text-base pl-4 h-12 flex items-center' : 'ml-4'}`}>{record.creator.name}</p>
    },
    {
      title: t("Subject"),
      dataIndex: "document",
      key: "document",
      render: (value, record) => <span className={`${record.is_read === false ? 'font-medium text-base' : ''}`}>{record.document.name}</span>
    },
    {
      title: t("Sign Type"),
      dataIndex: "sign_type",
      key: "sign_type",
      render: (value, record) => <span className={`${record.is_read === false ? 'font-medium text-base' : ''}`}>{t(record.sign_type)}</span>
    },
    {
      title: t("Assign Type"),
      dataIndex: "assign_type",
      key: "assign_type",
      render: (value, record) => <span className={`${record.is_read === false ? 'font-medium text-base' : ''}`}>{t(record.assign_type)}</span>
    },
    {
      title: t("Document Type"),
      dataIndex: "is_folder",
      key: "is_folder",
      render: (is_folder, record) => {
        return is_folder === true ? <p className={`text-blue-500 ${record.is_read === false ? 'font-medium text-base' : ''}`}>{t("Bulk")}</p> :
          (is_folder === false ? <p className={`text-red-500 ${record.is_read === false ? 'font-medium text-base' : ''}`}>{t("Single")}</p> : <p className={`text-green-500 ${record.is_read === false ? 'font-medium text-base' : ''}`}>{t("Single")}</p>)
      },
    },
    {
      title: t("Status"),
      dataIndex: "status_doc",
      key: "status_doc",
      render: (status, record) => {
        return status === 1 ? <p className={`text-blue-500 ${record.is_read === false ? 'font-medium text-base' : ''}`}>{t("Waiting for Others")}</p> :
          (status === 0 ? <p className={`text-red-500 ${record.is_read === false ? 'font-medium text-base' : ''}`}>{t("Need to Sign")}</p> : <p className={`text-green-500 ${record.is_read === false ? 'font-medium text-base' : ''}`}>{t("Completed")}</p>)
      },
    },
    {
      title: t("Last Change"),
      dataIndex: "update_at",
      key: "update_at",
      render: (lastChanged, record) => {
        return <span className={`${record.is_read === false ? 'font-medium text-base' : ''}`}>{moment(record.updated_at === null ? record.created_at : record.updated_at, moment.ISO_8601).format("DD/MM/YYYY")}</span>
      },
    }
  ];

  const handleSelectDocument = (row) => {
    const { doc_id, doc_name, is_folder } = row
    setDataDoc({ doc_id, doc_name, is_folder, typeSign })
    handleModalDocument();
    handleModalSignature();
  }

  // columns table list document  
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Document Name"),
      dataIndex: "doc_name",
      key: "doc_name",
    },
    {
      title: t("Type"),
      dataIndex: "is_folder",
      key: "is_folder",
      render: (is_folder) => {
        return is_folder ? t("Bulk") : t("Single");
      },
    },
    {
      title: t('Stamp Status'),
      render: (record) => {
        if (!record.is_stamped && !record.is_signed && !record.is_tera) {
          return <Tag color="#A0A19F" className="rounded w-20 text-center">{t("Draft")}</Tag>
        }
        return (
          <span className="flex flex-row gap-2">
            {record.is_stamped && (
              <Tag color="#1890FF" className="rounded w-20 text-center">{t("E-Meterai")}</Tag>
            )}
            {record.is_signed && (
              <Tag color="#1890FF" className="rounded w-20 text-center">{t("E-Sign")}</Tag>
            )}
            {record.is_tera && (
              <Tag color="#1890FF" className="rounded w-20 text-center">{t("Tera")}</Tag>
            )}
          </span>
        )
      }
    },
    {
      title: t("Tag Document"),
      dataIndex: "tags",
      key: "tags",
      render: (tags) => {
        return (
          <p className="w-20 truncate">
            {tags?.map((item, index) => {
              return (
                item !== null && (
                  <span key={index}>
                    {item}
                    {tags?.length > 1 && tags?.length - 1 !== index ? ", " : ""}
                  </span>
                )
              );
            })}
          </p>
        );
      },
    },
    {
      title: t("Work Unit"),
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      render: (value, row) => {
        const { doc_id, doc_name, is_folder } = row
        if (row.stamp_status == 1 || row.sign_in_progress || row.tera_in_progress) {
          return null
        }
        return (
          <div
            className="group cursor-pointer"
            onClick={() => handleSelectDocument(row)}
          >
            <RightOutlined className="group-hover:text-blue-500" />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (typeSign == 1 && dataDoc.is_folder != undefined) {
      if (dataDoc.is_folder) {
        history.push(`/sign-management/self-sign-bulk/${dataDoc.doc_id}`, { dataDoc })
      }
      else {
        history.push("/sign-management/self-sign", { dataDoc })
      }
    }
  }, [typeSign, dataDoc])

  // searching list sign
  const onSearch = (value) => setParamsTable({ ...paramsTable, search: value });


  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  // handle page change to another page
  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  // handle reset filter for document 
  function handleResetFilter() {
    setParamsTable({
      ...paramsTable,
      office: "",
      sort_by: "created_at",
      order: "desc",
    });
  }


  // filter menu for document list
  const menuFilterModal = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="w-full h-0.5 bg-gray-100 rounded-full" />
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">{t("Filter")}</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={handleResetFilter}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort By")}</p>
            <Select
              defaultValue="created_at"
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, sort_by: e })}
              value={paramsTable.sort_by}
            >
              <Option value="created_at">{t("Uploaded Time")}</Option>
              <Option value="doc_name">{t("Document Name")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="desc"
              buttonStyle="solid"
              onChange={(e) =>
                setParamsTable({ ...paramsTable, order: e.target.value })
              }
              value={paramsTable.order}
            >
              <Radio value="asc">{t("Ascending")}</Radio>
              <Radio value="desc">{t("Descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };


  // filter menu for filter list sign
  const menuFilterModalSign = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="w-full h-0.5 bg-gray-100 rounded-full" />
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">{t("Filter")}</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={() => setParamsTableSign(prev => ({ ...prev, sort_by: null, order: "desc" }))}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort By")}</p>
            <Select
              defaultValue="updated_at"
              style={{ width: "100%" }}
              placeholder={t("No Selected")}
              onChange={(e) => setParamsTableSign({ ...paramsTableSign, sort_by: e })}
              value={paramsTableSign.sort_by}
            >
              <Option value="" disabled className="hidden"></Option>
              <Option value="creator_name">{t("Creator Name")}</Option>
              <Option value="document_name">{t("Document Name")}</Option>
              <Option value="updated_at">{t("Last Change")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="desc"
              buttonStyle="solid"
              onChange={(e) =>
                setParamsTableSign({ ...paramsTableSign, order: e.target.value })
              }
              value={paramsTableSign.order}
            >
              <Radio value="asc">{t("Ascending")}</Radio>
              <Radio value="desc">{t("Descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const menuDropSetting = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => history.push({
          pathname: `/tera-management`,
        })}>
        <p className="text-blue-600">{t("Tera Management")}</p>
      </Menu.Item>
      <Menu.Item
        key="1"
        disabled={userProfile?.status_registration_peruri !== 3 && userProfile?.status_registration_peruri !== 4}
        onClick={() => {
          history.push({
            pathname: `/sign-management/create-signature`,
          })
        }}>
        <p className="text-blue-600">{t("Create Signature")}</p>
      </Menu.Item>
      {isHeadquarter(userProfile?.office_type_id) && isFullAccess(userProfile?.role_id) && isPic(userProfile?.is_pic) && userProfile?.storage_credential?.custom_logo == "yes" && (
        <Menu.Item 
          key="2"
          disabled={userProfile?.status_registration_peruri !== 3 && userProfile?.status_registration_peruri !== 4}
        >
          <p className="text-blue-600">{t("Logo QR")}</p>
        </Menu.Item>
      )}
    </Menu>
  )


  const menuDropSignDoc = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={(e) => {
          setTypeSign(0);
          handleModalDocument();
        }}
        disabled={userProfile?.status_registration_peruri !== 3 && userProfile?.status_registration_peruri !== 4}
      >
        <p className="text-blue-600">{t("Signature Only")}</p>
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={(e) => {
          setTypeSign(1);
          handleModalDocument();
        }}
        disabled={userProfile?.status_registration_peruri !== 3 && userProfile?.status_registration_peruri !== 4}>
        <p className="text-blue-600">{t("Tera Only")}</p>
      </Menu.Item>
      {/* <Menu.Item
        key="2"
        onClick={ (e) => {
          setTypeSign(2);
          handleModalDocument();
        }}
        disabled={userProfile?.status_registration_peruri !== 3 && userProfile?.status_registration_peruri !== 4}>
        <p className="text-blue-600">Signature and Stamp Tera</p>
      </Menu.Item>          */}
    </Menu>
  )

  const propsDragger = {
    name: 'logo',
    multiple: false,
    listType: 'picture',
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isJpgOrPng = file.type == 'image/png' || file.type == 'image/jpeg';
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!')
      }
      if (!isLt1M) {
        message.error('Image must smaller than 1MB!');
      }
      setFileList([file])
      return isJpgOrPng && isLt1M;
    },
    onPreview: async (file) => {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new  FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        })
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    },
    fileList
  }

  const handleUpload = (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('file', fileList[0]);
      uploadOfficeLogo(formData);
    } catch (error) {
      message.error(error.message);
    }
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <h1 className="text-xl md:text-2xl font-medium">
                {t("Sign & Tera Management")}
              </h1>
            </Col>
            <Col>
              <Row gutter={[12, 24]}>
                <Col>
                  <Row gutter={[12, 24]} align="middle">
                    <Col>
                      <div className="flex flex-row bg-blue-400 text-white rounded-full px-2 py-1 justify-center items-center">
                        <CapIcon />
                        <Divider className="h-4 bg-white" type="vertical" />
                        <p className="font-medium">{numberCommas(totalMaterai)}</p>
                      </div>
                    </Col>
                    {Number(totalMaterai) < 1 && (
                      <Col>
                        <AlertIcon />
                      </Col>
                    )}
                    <Col>
                      <div className="flex flex-row bg-blue-400 text-white rounded-full px-2 py-1 justify-center items-center">
                        <SignIcon />
                        <Divider className="h-4 bg-white" type="vertical" />
                        <p className="font-medium">{numberCommas(totalMaterai)}</p>
                      </div>
                    </Col>
                    {Number(totalMaterai) < 1 && (
                      <Col>
                        <AlertIcon />
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col>
                  <Divider className="h-full" type="vertical" />
                </Col>
                <Col>
                  <Dropdown
                    overlay={menuDropSetting}
                    trigger={["click"]}
                  >
                    <Button
                      className="md:w-40 h-10 font-medium tracking-tight"
                      type="secondary"
                    >
                      {t("Setting")}
                    </Button>
                  </Dropdown>
                </Col>
                <Col>
                  <Dropdown
                    overlay={menuDropSignDoc}
                    trigger={["click"]}
                  >
                    <Button
                      className="md:w-40 h-10 font-medium tracking-tight"
                      type="primary"
                      disabled={userProfile?.status_registration_peruri !== 3 && userProfile?.status_registration_peruri !== 4}
                    >
                      {t("Sign & Tera")}
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col span={14}>
                    <Menu onClick={(e) => {
                      setCurrent(e.key)
                      setParamsTableSign(prev => ({ ...prev, status: e.key }))
                    }} selectedKeys={[current]} mode="horizontal">
                      {itemsMenu.map(items => {
                        return (
                          <Menu.Item key={items.key} >
                            <span style={{ color: `${current === items.key ? '#1890ff' : '#8B8B8B'}` }} className={`text-base font-medium`}>{items.label}</span>
                          </Menu.Item>
                        )
                      })}
                    </Menu>
                  </Col>
                  <Col span={10} className="flex items-end justify-end">
                    <Row gutter={[12, 12]}>
                      <Col>
                        <Search
                          placeholder={t("Search")}
                          allowClear
                          onSearch={(e) => setParamsTableSign(prev => ({ ...prev, search: e }))}
                          style={{
                            width: 310,
                            height: '100%',
                            borderRadius: "10px !important",
                          }}
                        />
                      </Col>
                      <Col>
                        <Dropdown
                          overlay={menuFilterModalSign}
                          onVisibleChange={handleVisibleFilter}
                          visible={visibleFilter}
                          trigger={["click"]}
                        >
                          <Button
                            type="primary"
                            ghost
                            icon={<FilterOutlined />}
                          />
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={current !== '3' ? columnsReq.filter(item => !item.hidden) : columnsReq}
                  rowClassName={'cursor-pointer table-l-border'}
                  dataSource={dataSignList === undefined ? [] : dataSignList.data}
                  scroll={{ x: 800 }}
                  loading={isFetch}
                  pagination={{
                    current: paramsTableSign.page,
                    pageSize: 15,
                    total: dataSignList === undefined ? 0 : dataSignList.total_items,
                    locale: {
                      items_per_page: t("/ page"),
                    }
                  }}
                  onRow={(record) => {
                    let { document } = record
                    return {
                      onClick: () => {

                        let dataDoc = { sign_id: record.sign_id, doc_id: document.id, ...record }

                        // history.push( `/sign-management/sign-detail/${document.id}` , {dataDoc} );
                        history.push(
                          record.is_folder
                            ? `/sign-management/sign-detail-bulk/${document.id}`
                            : `/sign-management/sign-detail/${document.id}`, { dataDoc }
                        )
                      }, // click row
                    };
                  }}
                  // loading={false}
                  onChange={(pagination) => setParamsTableSign(prev => ({
                    ...prev,
                    page: pagination.current
                  }))}
                />
              </Col>
            </Row>
            {/* </TabPane> */}
            {/* </Tabs> */}
          </Card>
        </Col>
      </Row>
      <ModalComponent
        title={t("Your Quota Not Enough")}
        subtitle={t("Your e-materai quota is 0, you can not add stamp to this document")}
        type="warning"
        visible={isModalAlert}
        onCancel={handleModalAlert}
      >
        <div className="flex justify-center">
          <Button
            className="w-40"
            type="primary"
            ghost
            onClick={handleModalAlert}
          >
            {t("Back")}
          </Button>
        </div>
      </ModalComponent>
      <Modal
        className="border-red-400 rounded-md"
        style={{ borderTopWidth: 14 }}
        visible={isModalDelete}
        footer={false}
        onCancel={handleCancel}
      >
        <div className="flex flex-col space-y-4 px-8 -mt-20">
          <div className="flex flex-col space-y-2 justify-center items-center">
            <TrashIcon className="w-20 h-20 bg-red-500 rounded-full" />
            <p className="text-gray-800 font-semibold text-xl md:text-2xl">
              {t("Delete Work Unit?")}
            </p>
            <p className="text-gray-500">
              {t("Are you sure want to delete this work unit?")}
            </p>
          </div>
          <div className="flex flex-row space-x-4">
            <Button
              onClick={handleCancel}
              className="w-full"
              ghost
              danger
              type="secondary"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={handleDelete}
              className="w-full"
              danger
              type="primary"
            >
              {t("Delete")}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        className="border-blue-500 rounded-md"
        style={{ borderTopWidth: 14 }}
        visible={isModalSuccess}
        footer={false}
      >
        <div className="flex flex-col space-y-4 px-8 -mt-20">
          <div className="flex flex-col space-y-2 justify-center items-center">
            <CheckCircleIcon className="w-20" />
            <p className="text-gray-800 font-semibold text-xl md:text-2xl">
              {t("Updated Success!")}
            </p>
            <p className="text-gray-500">
              {t("Changes have been saved! Now you can continue browsing.")}
            </p>
          </div>
          <div className="flex justify-center">
            <Button onClick={handleDone} className="w-32" type="primary">
              {t("Done")}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isModalDocument}
        onCancel={handleModalDocument}
        footer={false}
        width={1200}
      >
        <Row gutter={[24, 24]} className="pr-4">
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  {t("Choose Document")}
                </h3>
              </Col>
              <Col>
                <Row gutter={[12, 12]}>
                  <Col>
                    <Search
                      placeholder={t("Search")}
                      className="rounded-full"
                      allowClear
                      onSearch={onSearch}
                      onChange={(e) =>
                        setTimeout(() => {
                          onSearch(e.target.value);
                        }, 1000)
                      }
                      style={{
                        width: 200,
                        borderRadius: "10px !important",
                      }}
                    />
                  </Col>
                  <Col>
                    <Dropdown
                      overlay={menuFilterModal}
                      onVisibleChange={handleVisibleFilterModal}
                      visible={visibleFilterModal}
                      trigger={["click"]}
                    >
                      <Button
                        type="primary"
                        ghost
                        icon={<FilterOutlined />}
                      />
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={datasetsListTable(dataListDocument)}
              scroll={{ x: 500 }}
              loading={isFetching}
              pagination={{
                current: paramsTable.page,
                pageSize: paramsTable.row,
                total: dataListDocument[0] ? dataListDocument[0]?.total : 0,
                locale: {
                  items_per_page: t("/ page"),
                }
              }}
              onChange={handleChangeTable}
            />
          </Col>
          <Col className="mt-2" span={24}>
            <Divider />
            <Row gutter={[20, 0]} className="justify-center">
              <Col span={6}>
                <Button
                  ghost
                  type="primary"
                  className="w-full rounded-md font-medium h-10"
                  onClick={handleModalDocument}
                >
                  {t("Back")}
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  type="primary"
                  className="w-full rounded-md font-medium h-10"
                  onClick={() => history.push('sign-management/upload-document')}
                >
                  {t("Upload New Document")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
      <Modal
        onCancel={handleModalSignature}
        visible={isModalSignature}
        width={500}
        footer={false}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <p className="text-lg md:text-xl text-gray-500 font-medium">
              {t("Choose Document Workflow")}
            </p>
          </Col>
          {modalSignatureContent.map((item, index) => {

            return (

              <Col span={24} key={index}>
                <div
                  className={`rounded-t-md ring-1 ring-gray-300 px-4 py-2 ${item.disabled} flex-row justify-between items-center cursor-pointer group hover:bg-blue-100 transition duration-500`}
                  onClick={item.handleClick}
                >
                  <div className="flex flex-row space-x-4 items-center">
                    <div>{item.icon}</div>
                    <div className="flex flex-col">
                      <div span={24}>
                        <p className="text-sm md:text-base text-blue-700 font-medium">
                          {item.title}
                        </p>
                      </div>
                      <div span={24}>
                        <p className="text-xs text-gray-400">{item.subtitle}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <RightOutlined className="group-hover:text-blue-700 transition duration-500" />
                  </div>
                </div>
              </Col>
            );

          })}
        </Row>
      </Modal>

      <Modal
        title={t("Upload Logo")}
        visible={openUpload}
        onCancel={() => setOpenUpload(false)}
      >
        <ImgCrop rotationSlider aspectSlider showReset>
          <Dragger {...propsDragger}>
            <Col>
              <p className="ant-upload-drag-icon"><InboxOutlined/></p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Col>
          </Dragger>
        </ImgCrop>
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            ghost
            type="secondary"
          >
            {t("btnback")}
          </Button>
          <Button className="w-full" type="primary" onClick={handleUpload}>
            {t("btnupload")}
          </Button>
        </div>
      </Modal>
      <Spinner loading={loading || isFetch || isFetching} />
    </>
  );
}
