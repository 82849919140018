import { RightOutlined, EllipsisOutlined, ProfileFilled, FilterOutlined } from "@ant-design/icons";
import { Card, Col, Modal, Row, Table, Dropdown, Menu, Select, DatePicker, } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { Button, Invoice, Receipt } from "../../components";
import { useFetchCardQuery, useFetchListModuleQuery, useFetchListOfficeQuery, useFetchProfileUserQuery } from "../../config/features";
import {
  useFetchBillHistoryQuery,
  useFetchGenerateStampMonitorQuery,
  // useFetchDepartmentActivityQuery,
  useFetchInvoiceMutation,
  useFetchOverviewQuery,
  useFetchReceiptMutation,
} from "../../config/features/admin";

import {
  useFetchDetailQoutationQuery
} from "../../config/features/platform";

import { errorMessage, numberCommas } from "../../utils";
import CardPrimary from "./CardPrimary";
import { ReactComponent as GeneratedEmet } from "../../assets/icons/generated-emet.svg";
import { ReactComponent as MateraiIcon } from "../../assets/icons/qrcode-sm.svg";
import { STAMP_GENERATE_STATUS, STAMP_STATUS } from "../../constant";

const { Option } = Select;

export default function PanelAdmin() {
  const { t } = useTranslation();
  const history = useHistory();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const receiptRef = useRef();
  const invoiceRef = useRef();

  const [isModalInvoice, setIsModalInvoice] = useState(false);
  const [isModalReceipt, setIsModalReceipt] = useState(false);
  const [paramsTable] = useState({
    row: 10,
    page: 1,
    sort_by: "created_at",
    order: "desc",
  });

  const { data: userProfile = {} } = useFetchProfileUserQuery();

  const [paramsTableOffice, setParamsTableOffice] = useState({
    row: 10,
    page: 1,
  });

  const [paramsTableBillHistory, setParamsTableBillHistory] = useState({
    row: 10,
    page: 1,
  });

  const { data: dataOverview = [] } = useFetchOverviewQuery();
  const { data: dataBillHistory = [], isFetchingBill ,refetch: refetchBill } = useFetchBillHistoryQuery(paramsTableBillHistory);


  // Monitoring Generate E-Meterai
  const [paramsGenerateStampMonitor, setParamsGenerateStampMonitor] = useState({
    row: 10,
    page: 1,
    status: '',
    start_date: '',
    end_date: ''
  })
  const handleChangePaginationMonitoring = (pagination) => {
    setParamsGenerateStampMonitor(prevState => ({
      ...prevState,
      page: pagination.current,
      row: pagination.pageSize
    }))
  }

  const {
    data: dataGenerateStampMonitor = {},
    isFetching: isFetchingGenerateStampMonitor,
    refetch: refetchGenerateStampMonitor,
    isError: isErrorGenerateStampMonitor,
    error: errorGenerateStampMonitor
  } = useFetchGenerateStampMonitorQuery(paramsGenerateStampMonitor);

  useEffect(() => {
    refetchGenerateStampMonitor();
  }, [refetchGenerateStampMonitor])

  useEffect(() => {
    if (isErrorGenerateStampMonitor) {
      errorMessage(errorGenerateStampMonitor);
    }
  }, [isErrorGenerateStampMonitor, errorGenerateStampMonitor])

  const columnsGenerateStampMonitor = [
    {
      title: t("Date Generate"), dataIndex: 'created_at', key: 'created_at',
      render: (created_at) => moment(created_at, moment.ISO_8601).format("DD/MM/YYYY")
    },
    { title: t("Generated Quota"), dataIndex: 'generateds', key: 'generateds' },
    {
      title: t("Status"), dataIndex: 'status', key: 'status',
      render: (status) => (
        <span className={STAMP_GENERATE_STATUS[status]?.clsTextColor}>
          {t(STAMP_GENERATE_STATUS[status]?.value)}
        </span>
      )
    },
    // {
    //   title: 'Rp', dataIndex: 'price', key: 'price',
    //   render: (price) => `Rp ${numberCommas(price, 0)}`
    // }
  ]
  const handleResetFilterGenerateStampMonitor = () => {
    setParamsGenerateStampMonitor(prevState => ({
      ...prevState,
      row: 10,
      page: 1,
      status: '',
      start_date: '',
      end_date: ''
    }))
  }

  

  const {
    data: dataDetailQoutation = { logs: [] },
    isFetch,
    refetch: refetchDetailQuotation,
  } = useFetchDetailQoutationQuery({id_office: userProfile?.office_id});

  useEffect(() => {
    refetchDetailQuotation()
  },[refetchDetailQuotation])

  const [visibleFilterGenerateStampMonitor, setVisibleFilterGenerateStampMonitor] = useState(false);
  const menuFilterGenerateStampMonitor = () => (
    <Menu className="rounded-tl-2xl rounded-b-2xl">
      <Menu.Item>
        <div className="flex flex-row justify-between items-center gap-36">
          <p className="text-gray-400 text-base font-medium">Filter</p>
          <p
            className="text-gray-300 font-medium text-xs"
            onClick={handleResetFilterGenerateStampMonitor}
          >
            {t("Reset")}
          </p>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div className="flex flex-col space-y-2">
          <p className="text-sm">{t("Choose Status")}</p>
          <Select
            defaultValue=""
            onChange={(e) => {
              setParamsGenerateStampMonitor({
                ...paramsGenerateStampMonitor,
                status: e
              })
              console.log("Param Gen Status => ", paramsGenerateStampMonitor);
            }}
            value={paramsGenerateStampMonitor.status}
          >
            <Option value="">{t("All Status")}</Option>
            <Option value={1}>{t("In Progress")}</Option>
            <Option value={2}>{t("Completed")}</Option>
            <Option value={3}>{t("Canceled")}</Option>
          </Select>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div className="flex flex-col space-y-2">
          <p className="text-sm">{t("Date")}</p>
          <DatePicker.RangePicker
            defaultValue=""
            onChange={(value) => {
              setParamsGenerateStampMonitor({
                ...paramsGenerateStampMonitor,
                start_date: moment(value[0]).utc().format().toString(),
                end_date: moment(value[1]).utc().format().toString(),
              })
            }}
            value={[
              paramsGenerateStampMonitor.start_date ? moment(paramsGenerateStampMonitor.start_date) : "",
              paramsGenerateStampMonitor.end_date ? moment(paramsGenerateStampMonitor.end_date) : ""
            ]}
            placeholder={[t("Start Date"), t("End Date")]}
          />
        </div>
      </Menu.Item>
    </Menu>
  )
  // End Monitoring Generate E-Meterai

  const {
    data: dataCard = {},
  } = useFetchCardQuery({ office_id: '' })
  const [itemsCard, setItemsCard] = useState()
  useEffect(() => {
    if (dataCard && dataOverview.length > 0) {
      const generated_emet = {
        name: 'Total Generated E-Meterai',
        remaining: dataCard.generated_meterai
      }
      const quota_emet = {
        name: dataOverview.find(item => item.id == '1').name,
        remaining: dataCard.total_meterai,
        warning: dataCard.warning_meterai
      }
      setItemsCard([generated_emet, quota_emet])
    }

  }, [dataCard, dataOverview])


  const {
    data: dataListOffice = [],
    isFetching,

  } = useFetchListOfficeQuery(paramsTableOffice);

  const [getInvoice, { data: dataDetailInvoice = {} }] =
    useFetchInvoiceMutation();

  const [getReceipt, { data: dataDetailReceipt = {} }] =
    useFetchReceiptMutation();

  useEffect(() => {
    refetchBill();
  }, [refetchBill]);

  function handleInvoice(record) {
    getInvoice(record.id);
    toggleInvoice();
  }

  function handleReceipt(record) {
    getReceipt(record.id);
    toggleReceipt();
  }

  function handlePayment(record) {
    // console.log('RECORD =>', record); 
    // history.push("/panel-admin/top-up");
    history.push({
      pathname: '/panel-admin/top-up',
      state: {
        quotation: record
      }
    })
  }

  const columnBilling = [
    {
      title: t("Date"),
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return moment(date, moment.ISO_8601).format("DD/MM/YYYY");
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (total) => {
        return `Rp ${numberCommas(total)},-`;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        switch (status) {
          case 1:
            return <p className="text-red-500">{t("Unpaid")}</p>;

          case 2:
            return <p className="text-blue-500">{t("Paid")}</p>;

          case 3:
            return <p className="text-gray-500">{t("Canceled")}</p>;

          case 4:
            return <p className="text-gray-500">{t("Expired")}</p>;

          default:
            return <p className="text-gray-500">{t("Unknow")}</p>;
        }
      },
    },

    // Persiapan UAT & SIT Overview Platform VAS BJT
    {
      title: "Noted",
      dataIndex: "note",
      key: "note",
      render: (_, record) => {

        return (
          record.status === 1 && (
            <Button onClick={() => handlePayment(record)}>{t("Pay Now")}</Button>
          )
        );
        // return("");

      },
    },

    {
      title: t("Invoice"),
      dataIndex: "Invoice",
      key: "Invoice",
      render: (_, record) => {
        return (
          <p
            className="text-blue-500 underline hover:text-blue-400 hover:underline-none cursor-pointer"
            onClick={() => handleInvoice(record)}
          >
            {t("See Invoice")}
          </p>
        );
      },
    },
    {
      title: t("Receipt"),
      dataIndex: "Receipt",
      key: "Receipt",
      render: (_, record) => {
        return (
          record.status === 2 && (
            <p
              className="text-blue-500 underline hover:text-blue-400 hover:underline-none cursor-pointer"
              onClick={() => handleReceipt(record)}
            >
              {t("See Receipt")}
            </p>
          )
        );
      },
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Office Name",
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: "Office Type",
      dataIndex: "office_type_name",
      key: "office_type_name",
    },
    {
      render: (record) => {
        return (
          <div
            className="group cursor-pointer"
            onClick={() =>
              history.push({
                pathname: `/panel-admin/department-office/${record?.office_id}`,
              })
            }
          >
            <RightOutlined className="group-hover:text-blue-500" />
          </div>
        );
      },
    },
    // {
    //   render: (record) => {
    //     return (
    //       <Dropdown
    //         overlay={menuAction(record)}
    //         trigger={["click"]}
    //       >
    //         <div className="group cursor-pointer">
    //           <EllipsisOutlined className="group-hover:text-blue-500" />
    //         </div>
    //       </Dropdown>
    //     );
    //   },
    // },
  ];

  // const columnDepartment = [
  //   {
  //     title: "No.",
  //     dataIndex: "no",
  //     key: "no",
  //     render: (no) => {
  //       return no === "Total" ? (
  //         <p className="text-gray-500 font-medium">{no}</p>
  //       ) : (
  //         no
  //       );
  //     },
  //   },
  //   {
  //     title: "Department",
  //     dataIndex: "dept_name",
  //     key: "dept_name",
  //     render: (dept_name, record) => {
  //       return record.no === "Total" ? null : dept_name;
  //     },
  //   },
  //   {
  //     title: "Signature",
  //     dataIndex: "sign",
  //     key: "sign",
  //     render: (_, record) => {
  //       return (
  //         <>
  //           {record?.product_list &&
  //             record?.product_list?.map((item) => {
  //               return item.id === 2 && numberCommas(item.used);
  //             })}
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     title: "Stamp",
  //     dataIndex: "meterai",
  //     key: "meterai",
  //     render: (_, record) => {
  //       return (
  //         <>
  //           {record?.product_list &&
  //             record?.product_list?.map((item) => {
  //               return item.id === 1 && numberCommas(item.used);
  //             })}
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     title: "DMS per-Document",
  //     dataIndex: "storage_page",
  //     key: "storage_page",
  //     render: (_, record) => {
  //       return (
  //         <>
  //           {record?.product_list &&
  //             record?.product_list?.map((item) => {
  //               return item.id === 3 && numberCommas(item.used);
  //             })}
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     title: "Backup Secure Document",
  //     dataIndex: "storage_mb",
  //     key: "storage_mb",
  //     render: (_, record) => {
  //       return (
  //         <>
  //           {record?.product_list &&
  //             record?.product_list?.map((item) => {
  //               return item.id === 4 && `${item.used / 1000} GB`;
  //             })}
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     render: (record) => {
  //       return (
  //         <div
  //           className="group cursor-pointer"
  //           onClick={() =>
  //             history.push({
  //               pathname: `/panel-admin/detail-activity-department/${record.id}`,
  //               state: record,
  //             })
  //           }
  //         >
  //           <RightOutlined className="group-hover:text-blue-500" />
  //         </div>
  //       );
  //     },
  //   },
  // ];

  // const menuAction = (record) => {
  //   return (
  //     <Menu>
  //       <Menu.Item>
  //         <div
  //           className="flex flex-row space-x-2 items-center"
  //           onClick={() =>
  //             history.push({
  //               pathname: `/office/detail/${record.office_id}`,
  //               state: {
  //                 office_name: record.office_name,
  //                 office_type_id: record.office_type_id,
  //                 is_active: record.is_active,
  //                 pic_id: record.pic_id
  //               },
  //             })
  //           }
  //         >
  //           <ProfileFilled />
  //           <p>Detail</p>
  //         </div>
  //       </Menu.Item>
  //       {record?.office_type_id !== 1 && userProfile && userProfile?.is_pic &&
  //       <>
  //         <Menu.Item>
  //           <div
  //             className="flex flex-row space-x-2 items-center"
  //             onClick={() =>
  //               history.push({
  //                 pathname: `/office/edit/${record.office_id}`,
  //                 state: {
  //                   office_name: record.office_name,
  //                   office_type_id: record.office_type_id,
  //                   is_active: record.is_active,
  //                   pic_id: record.pic_id
  //                 },
  //               })
  //             }
  //           >
  //             <EditOutlined />
  //             <p>Edit</p>
  //           </div>
  //         </Menu.Item>
  //         <Menu.Item>
  //           <div
  //             className="flex flex-row space-x-2 items-center text-red-500"
  //             onClick={() => {
  //               setOfficeId(record.office_id);
  //               setOfficeNameToDelete(record.office_name);
  //               setIsModalDelete(true);
  //             }}
  //           >
  //             <DeleteOutlined />
  //             <p>Delete</p>
  //           </div>
  //         </Menu.Item>
  //       </>}
  //     </Menu>
  //   );
  // };

  function handleChangeTableOffice(pagination) {
    setParamsTableOffice({
      ...paramsTableOffice,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  function handleChangeTableBillHistory(pagination) {
    setParamsTableBillHistory({
      ...paramsTableBillHistory,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  function toggleInvoice() {
    setIsModalInvoice(!isModalInvoice);
  }

  function toggleReceipt() {
    setIsModalReceipt(!isModalReceipt);
  }

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <h1 className="text-xl md:text-2xl font-medium">{t("titlepaneladmin")}</h1>
        </Col>
        <Col xs={24}>
          <CardPrimary data={itemsCard} />
        </Col>
        {/* Persiapan UAT & SIT Overview Platform VAS BJT */}
        {
          userProfile?.portal_payment && <Col span={24}>
          <Card>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[12,12]} justify="space-between">
                  <Col>
                    <h1 className="text-base md:text-lg font-medium">
                      {t("titlebilinghistory")}
                    </h1>
                  </Col>                
                  <Col>
                      <Button
                        className="md:w-40 mr-2"
                        type="primary"
                        // htmlType="submit"
                        onClick={() => 
                          history.push({
                            pathname: `/po-management/create-po/${userProfile?.office_id}`,
                            state: dataDetailQoutation?.quotation?.products
                          })
                        }
                      >
                        {t("Create PO")}
                      </Button>
                  </Col>
                </Row>                
              </Col>              
              <Col span={24}>
                <Table
                  columns={columnBilling}
                  dataSource={dataBillHistory}
                  loading={isFetchingBill}
                  scroll={{ x: 800 }}
                  onChange={handleChangeTableBillHistory}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        }        
        <Col span={24}>
          <Card>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[12, 12]} justify="space-between">
                  <Col>
                    <h1 className="text-base md:text-lg font-medium">
                      {t("History Generate E-Meterai Quota")}
                    </h1>
                  </Col>
                  <Col>                   
                    <Dropdown
                      overlay={menuFilterGenerateStampMonitor}
                      visible={visibleFilterGenerateStampMonitor}
                      onVisibleChange={() => setVisibleFilterGenerateStampMonitor(prevState => !prevState)}
                      trigger={["click"]}
                    >
                      <Button
                        type="primary"
                        ghost
                        icon={<FilterOutlined />}
                      />
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsGenerateStampMonitor}
                  scroll={{ x: 600 }}
                  loading={isFetchingGenerateStampMonitor}
                  dataSource={dataGenerateStampMonitor?.data || []}
                  pagination={{
                    current: paramsGenerateStampMonitor.page,
                    pageSize: paramsGenerateStampMonitor.row,
                    total: dataGenerateStampMonitor?.total || 0
                  }}
                  onChange={handleChangePaginationMonitoring}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        {/* Persiapan UAT & SIT Overview Platform VAS BJT */}
        {/* <Col span={24}>
          <Card>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <h1 className="text-base md:text-lg font-medium">
                  {t("officeactivity")}
                </h1>
              </Col>
              <Col span={24}>
              <Table
                  columns={columns}
                  dataSource={datasetsListTable(dataListOffice)}
                  scroll={{ x: 800 }}
                  loading={isFetching}
                  pagination={{
                    current: paramsTable.page,
                    pageSize: paramsTable.row,
                    total: dataListOffice[0] ? dataListOffice[0]?.total : 0,
                  }}
                  onChange={handleChangeTableOffice}
                />
              </Col>
            </Row>
          </Card>
        </Col> */}


      </Row>
      <Modal
        visible={isModalInvoice}
        onCancel={toggleInvoice}
        footer={false}
        className="top-4"
        width={800}
      >
        <div className="flex flex-col space-y-4">
          <div className="hidden">
            <div ref={invoiceRef} className="p-12">
              <Invoice dataSource={dataDetailInvoice} />
            </div>
          </div>
          <Invoice dataSource={dataDetailInvoice} />
          <div className="text-center">
            <ReactToPrint
              trigger={() => (
                <Button className="w-10/12" type="primary">
                  {t("Download")}
                </Button>
              )}
              content={() => invoiceRef.current}
            />
          </div>
        </div>
      </Modal>
      <Modal
        visible={isModalReceipt}
        onCancel={toggleReceipt}
        footer={false}
        className="top-4"
        width={800}
      >
        <div className="flex flex-col space-y-4">
          <div className="hidden">
            <div ref={receiptRef} className="p-12">
              <Receipt dataSource={dataDetailReceipt} />
            </div>
          </div>
          <Receipt dataSource={dataDetailReceipt} />
          <div className="text-center">
            <ReactToPrint
              trigger={() => (
                <Button className="w-10/12" type="primary">
                  {t("Download")}
                </Button>
              )}
              content={() => receiptRef.current}
              copyStyles
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
