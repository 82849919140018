import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FilterOutlined,
  ProfileFilled,
} from "@ant-design/icons";
import {
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Radio,
  Row,
  Select,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as OfficeIcon } from "../../assets/icons/office-building-outline.svg";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { useTranslation } from "react-i18next";
import {
  useDeleteOfficeMutation,
  useFetchListOfficeQuery,
} from "../../config/features/office";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { errorMessage } from "../../utils";
const { Search } = Input;
const { Option } = Select;

export default function Office() {
  const history = useHistory();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [officeId, setOfficeId] = useState(null);
  const [officeName, setOfficeName] = useState(null);
  const [officeNameToDelete, setOfficeNameToDelete] = useState(null);
  const [paramsTable, setParamsTable] = useState({
    row: 10,
    page: 1,
    sort_by: "created_at",
    order: "desc",
  });
  const [visibleFilter, setVisibleFilter] = useState(false);

  const {
    data: userProfile = {}
  } = useFetchProfileUserQuery();
  const {
    data: dataListOffice = [],
    isFetching,
    refetch,
    error,
    isError,
  } = useFetchListOfficeQuery(paramsTable);
  const [
    deleteOffice,
    { isSuccess, isLoading, isError: isErrorDelete, error: errorDelete },
  ] = useDeleteOfficeMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      setIsModalSuccess(true);
      setTimeout(() => {
        setIsModalSuccess(false);
      }, 3000);
    }
  }, [refetch, isSuccess]);

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
    if (isErrorDelete) {
      errorMessage(errorDelete);
    }
  }, [isError, error, isErrorDelete, errorDelete]);

  useEffect(() => {
    refetch();
  }, [refetch, paramsTable.page]);

  function handleResetFilter() {
    setParamsTable({
      ...paramsTable,
      sort_by: "created_at",
      order: "desc",
    });
  }

  const menuFilter = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">{t("Filter")}</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={handleResetFilter}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort By")}</p>
            <Select
              defaultValue="created_at"
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, sort_by: e })}
              value={paramsTable.sort_by}
            >
              <Option value="office_name">{t("Work Unit")}</Option>
              <Option value="created_at">{t("Created Date")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Sort Type")}</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) =>
                setParamsTable({ ...paramsTable, order: e.target.value })
              }
              value={paramsTable.order}
            >
              <Radio value="asc">{t("Ascending")}</Radio>
              <Radio value="desc">{t("Descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const menuAction = (record) => {
    return (
      <Menu>
        <Menu.Item>
          <div
            className="flex flex-row space-x-2 items-center"
            onClick={() =>
              history.push({
                pathname: `/office/detail/${record.office_id}`,
                state: {
                  office_name: record.office_name,
                  office_type_id: record.office_type_id,
                  is_active: record.is_active,
                  pic_id: record.pic_id
                },
              })
            }
          >
            <ProfileFilled />
            <p>{t("Detail")}</p>
          </div>
        </Menu.Item>
        {record?.office_type_id !== 1 && userProfile && userProfile?.is_pic &&
        <>
          <Menu.Item>
            <div
              className="flex flex-row space-x-2 items-center"
              onClick={() =>
                history.push({
                  pathname: `/office/edit/${record.office_id}`,
                  state: {
                    office_name: record.office_name,
                    office_type_id: record.office_type_id,
                    is_active: record.is_active,
                    pic_id: record.pic_id
                  },
                })
              }
            >
              <EditOutlined />
              <p>{t("Edit")}</p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div
              className="flex flex-row space-x-2 items-center text-red-500"
              onClick={() => {
                setOfficeId(record.office_id);
                setOfficeNameToDelete(record.office_name);
                setIsModalDelete(true);
              }}
            >
              <DeleteOutlined />
              <p>{t("Delete")}</p>
            </div>
          </Menu.Item>
        </>}
      </Menu>
    );
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Work Unit Name"),
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: t("Work Unit Type"),
      dataIndex: "office_type_name",
      key: "office_type_name",
    },
    {
      render: (record) => {
        return (
          <Dropdown
            overlay={menuAction(record)}
            trigger={["click"]}
          >
            <div className="group cursor-pointer">
              <EllipsisOutlined className="group-hover:text-blue-500" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  const onSearch = (value) => setParamsTable({ ...paramsTable, search: value });

  function handleCancel() {
    setIsModalDelete(false);
  }

  async function handleDelete() {
    try {
      setIsModalDelete(false);
      deleteOffice(officeId);
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleDone() {
    setIsModalSuccess(false);
  }

  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }

  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <h1 className="text-xl md:text-2xl font-medium">{t("Work Unit")}</h1>
            </Col>
            {userProfile?.is_pic &&
              <Col>
                <Button
                  className="md:w-40"
                  type="primary"
                  onClick={() => history.push("/office/add")}
                >
                  <div className="flex items-center justify-center space-x-2">
                    <OfficeIcon />
                    <p>{t("Add Work Unit")}</p>
                  </div>
                </Button>
              </Col>
            }
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                     {t("List Work Unit")}
                    </h3>
                  </Col>
                  <Col>
                    <Row gutter={[12, 12]}>
                      <Col>
                        <Search
                          placeholder={t("Search")}
                          allowClear
                          onSearch={onSearch}
                          onChange={(e) =>
                            setTimeout(() => {
                              onSearch(e.target.value);
                            }, 1000)
                          }
                          style={{
                            width: 200,
                            borderRadius: "10px !important",
                          }}
                        />
                      </Col>
                      <Col>
                        <Dropdown
                          overlay={menuFilter}
                          onVisibleChange={handleVisibleFilter}
                          visible={visibleFilter}
                          trigger={["click"]}
                        >
                          <Button
                            type="primary"
                            ghost
                            icon={<FilterOutlined />}
                          />
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={datasetsListTable(dataListOffice)}
                  scroll={{ x: 800 }}
                  loading={isFetching || isLoading}
                  pagination={{
                    current: paramsTable.page,
                    pageSize: paramsTable.row,
                    total: dataListOffice[0] ? dataListOffice[0]?.total : 0,
                    locale: {
                      items_per_page: t("/ page"),
                    }
                  }}
                  onChange={handleChangeTable}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        title={t("Delete Work Unit?")}
        subtitle={t("Are you sure want to delete this work unit?")}
        type="danger"
        visible={isModalDelete}
        onCancel={handleCancel}
      >
        <Row gutter={[16,16]}>
          <Col span={24}>
            <Input 
              placeholder={t("Enter the work unit name")} 
              value={officeName}
              onChange={e => setOfficeName(e.target.value)}
            />
          </Col>
          <Col span={12}>
            <Button
              className="w-full"
              type="secondary"
              ghost
              danger
              onClick={handleCancel}
            >
              {t("Cancel")}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              className="w-full"
              danger
              onClick={handleDelete}
              disabled={officeName !== officeNameToDelete}
            >
              {t("Delete")}
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        title={t("Updated Success!")}
        subtitle={t("Changes have been saved! Now you can continue browsing.")}
        type="success"
        visible={isModalSuccess}
      >
        <div className="text-center">
          <Button onClick={handleDone} className="w-32" type="primary">
            {t("Done")}
          </Button>
        </div>
      </Modal>
    </>
  );
}
