import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { rootApi, access } from "../config";

export const departmentApi = createApi({
  reducerPath: "api/department",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi,
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      fetchListDepartment: builder.query({
        query({
          office_id = "",
          page = 1,
          sort_by = "dept_name",
          order = "asc",
          row = 5,
          search = "",
        }) {
          return `/dept?office_id=${office_id}&page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}`
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      addDeparment: builder.mutation({
        query: (body) => ({
          method: "POST",
          url: "/dept",
          body,
        }),
      }),

      editDeparment: builder.mutation({
        query: (body) => ({
          method: "PUT",
          url: `/dept/${body?.dept_id}`,
          body,
        }),
      }),

      deleteDeparment: builder.mutation({
        query: (dept_id) => ({
          method: "DELETE",
          url: `/dept/${dept_id}`
        }),
      }),
    };
  },
});

export const { 
  useFetchListDepartmentQuery, 
  useAddDeparmentMutation, 
  useEditDeparmentMutation, 
  useDeleteDeparmentMutation, 
} = departmentApi;
