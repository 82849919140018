import { Form, Input, Button, Row, Col, Image } from "antd";
import { useLoginUserMutation } from "../../config/features/user";
import { useCallback, useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { Link } from "react-router-dom";
import { errorMessage } from "../../utils";
import axios from "axios";
import { rootApi } from "../../config/features/config";
import { useTranslation } from "react-i18next";
import React from 'react';
import SwitchSelector from "react-switch-selector";
import { id } from "date-fns/locale";
const Login = () => {
  const { t } = useTranslation();
  const access = localStorage.getItem("access");
  const [loading, setLoading] = useState(false)
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  
  const languages = [
    {
      code: "id",
      name: "indonesia",
      countryCode: "id"
    },
    {
      code: "en",
      name: "english",
      countryCode: "gb"
    },
  ];
  const options = [
    {
      label: "ID",
      value: 'id',
      selectedBackgroundColor: "#fbc531",
    },
    {
      label: "EN",
      value: "en",
      selectedBackgroundColor: "#fbc531"
    }
  ];

  const onClick = (onChange) => {
    //console.log(onChange);
  };

  const initialSelectedIndex = options.findIndex(({ value }) => value === "id");

  const getPeruriToken = async (token) => {

    setLoading(true)

    let header = {
      headers: {
        "token": token
      }
    }

    const { data: { data: { token_peruri_sign } } } = await axios(`${rootApi}/sign/token`, header)

    localStorage.setItem('peruri', token_peruri_sign)

  }

  const [
    loginUser,
    { isSuccess, isLoading, error, data: profileUser = {}, isError },
  ] = useLoginUserMutation();

  const redirectUser = useCallback(async (profile) => {

    const isFullAccess = (await profile?.role_id) === 3;
    const isSuperAdmin = (await profile?.role_id) === 4;
    const isAdminBill = (await profile?.role_id) === 5;
    const isNewUser = await profile?.is_first;

    if (isNewUser && !isSuperAdmin) {
      window.location.replace("/profile");
    } else if (isFullAccess) {
      window.location.replace("/");
    } else if (isSuperAdmin || isAdminBill) {
      window.location.replace("/overview");
    } else {
      window.location.replace("/");
    }
  }, [])

  useEffect(() => {
    changeLanguage("id");
    console.log("LOGIN");
  }, [])

  useEffect(() => {
    async function check() {
      if (isSuccess || access) {
        if (isSuccess && !access) await getPeruriToken(profileUser?.token)
        if (!access) localStorage.setItem("access", profileUser?.token);
        redirectUser(profileUser);
      }
    }

    check()
  }, [access, isSuccess, profileUser, redirectUser])

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
  }, [error, isError]);

  const onFinish = async (values) => {
    values.email = values?.email?.trim();
    values.password = values?.password?.trim();
    loginUser(values);
  };

  return (
    <>
      <Row className="h-screen">
        <Col xs={0} sm={10} md={12} lg={14}>
          <div
            className="w-full h-full bg-blue-600 pt-10 flex justify-end"
            style={{
              backgroundImage: "url('/images/cover-login.png')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="flex flex-col space-y-12 w-11/12 md:w-9/12">
              <div className="flex flex-col space-y-2">
                <h1 className="text-3xl text-white font-semibold">
                  Managed Work Perfectly
                </h1>
                <h5 className="text-white text-base">
                  Gather insights from data through customizable visualization
                </h5>
              </div>
              <img
                className="hidden md:block w-full"
                src="/images/image-login.png"
                alt="dashboard"
              />
            </div>
          </div>
        </Col>
        <Col xs={24} sm={14} md={12} lg={10}>
          <Row className="p-8 md:p-16 space-y-8">
            <Col span={50}>
              <div className="your-required-wrapper" style={{ width: 80, height: 30, paddingTop: 5, paddingRight: 12 }}>
                <SwitchSelector
                  onChange={changeLanguage}
                  options={options}
                  initialSelectedIndex={initialSelectedIndex}
                  backgroundColor={"#0741B5"}
                  fontColor={"#f5f6fa"}
                />
              </div>
            </Col>

            <Col span={24}>
              <Row>
                <Col>
                  <img
                    className="w-28"
                    src="/images/logo-primary.png"
                    alt="dli-logo"
                  />
                </Col>
              </Row>
            </Col>
            <Col className="pt-12" span={24}>
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  // label="Username"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: ("Please input your email!"),
                    },
                  ]}
                >
                  <Input
                    className="rounded-md"
                    placeholder="Email"
                  />
                </Form.Item>

                <Form.Item
                  // label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    className="rounded-md"
                    placeholder={t("Password")}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    className="w-full bg-blue-700 hover:bg-blue-600 focus:bg-blue-600 border-none rounded-md"
                    type="primary"
                    htmlType="submit"
                  >
                    {t("Login")} Test
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col span={24}>
              <div className="flex flex-row justify-center items-center">
                <div className="w-full h-0.5 bg-gray-100" />
                <Link to="/forgot-password">
                  <Button type="link">{t("forgotpass")}</Button>
                  <button ></button>
                </Link>
                <div className="w-full h-0.5 bg-gray-100" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Spinner loading={isLoading || loading} />
    </>
  )
}
export default Login