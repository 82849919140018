import { Button, Col, Form, Input, message, Row } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as LockIcon } from "../../assets/icons/lock-alert-outline.svg";
import { Modal, Spinner } from "../../components";
import { useForgotPasswordMutation } from "../../config/features/general";
import { REGEXES } from "../../constant";
import { useTranslation } from "react-i18next";
import SwitchSelector from "react-switch-selector";

export default function Login() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const access = localStorage.getItem("access");
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const options = [
    {
      label: "ID",
      value: 'id',
      selectedBackgroundColor: "#fbc531",
    },
    {
      label: "EN",
      value: "en",
      selectedBackgroundColor: "#fbc531"
    }
  ];
  const initialSelectedIndex = options.findIndex(({ value }) => value === "id");
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [tokenReset, setTokenReset] = useState(false);

  const [
    forgotPassword,
    { isSuccess, isLoading, error, data: dataResponse = "", isError },
  ] = useForgotPasswordMutation();

  useEffect(() => {
    changeLanguage("id");
  }, [])

  useEffect(() => {
    if (isSuccess) {
      setIsModalSuccess(true);
    }
    if (isError) {
      message.error(error?.data);
    }
  }, [isSuccess, error, history, isError]);

  useEffect(() => {
    const token = location?.search?.replace("?t=", "");
    if (Boolean(token)) {
      setTokenReset(token);
    }
    if (access) {
      window.location.replace("/login");
    }
  }, [location, access]);

  const onFinish = async (values) => {
    if (tokenReset) {
      values.token = tokenReset;
    }
    forgotPassword(values);
  };

  function handleDone() {
    setIsModalSuccess(false);
    window.location.replace("/login");
  }

  return (
    <>
      <Row className="h-screen">
        <Col xs={0} sm={10} md={12}>
          <div
            className="w-full h-full bg-blue-600"
            style={{
              backgroundImage: "url('/images/cover-login.png')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="flex justify-center items-center h-full">
              <div>
                <LockIcon />
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={14} md={12}>
          <Row className="p-8 md:p-16 lg:px-20 xl:px-24 space-y-8">
            <div>
              <Col span={50}>
                <div className="your-required-wrapper" span={50} style={{ width: 80, height: 30, paddingTop: 5, paddingRight: 12 }}>
                  <SwitchSelector
                    onChange={changeLanguage}
                    options={options}
                    initialSelectedIndex={initialSelectedIndex}
                    backgroundColor={"#0741B5"}
                    fontColor={"#f5f6fa"}
                  />
                </div>
              </Col>
            </div>
            <Col span={24}>
              <img
                className="w-28"
                src="/images/logo-primary.png"
                alt="dli-logo"
              />
            </Col>
            {tokenReset ? (
              <Col span={24} className="pt-4">
                <h1 className="font-medium text-base md:text-lg">
                  {t("Reset Password")}
                </h1>
                <p className="text-gray-500 text-xs">
                  {t("Please create your new password")}
                </p>
              </Col>
            ) : (
              <Col span={24} className="pt-4">
                <h1 className="font-medium text-base md:text-lg">
                  {t("forgotpassh1")}
                </h1>
                <p className="text-gray-500 text-xs">
                  {t("resetpasslink")}
                </p>
              </Col>
            )}
            <Col span={24}>
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
              >
                {tokenReset ? (
                  <>
                    <Form.Item
                      label={t("New Password")}
                      name="newPassword"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("Please input password!"),
                        },
                        () => ({
                          validator(_, value) {
                            if (
                              value.match(
                                REGEXES.password
                              )
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                t("Password needs to be at least 8 character, 1 uppercase, 1 special character!")
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder={t("Enter password")} />
                    </Form.Item>

                    <Form.Item
                      label={t("Confirmation Password")}
                      name="password_confirmation"
                      dependencies={["newPassword"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("Please input your confirmation password!"),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("newPassword") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                t("The two passwords that you entered do not match!")
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder={t("Enter password confirmation")} />
                    </Form.Item>
                  </>
                ) : (
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t("Please input your email!"),
                      },
                    ]}
                  >
                    <Input className="rounded-md" placeholder={t("Email address")} />
                  </Form.Item>
                )}

                <Form.Item>
                  <Button
                    className="w-full bg-blue-700 hover:bg-blue-600 focus:bg-blue-600 border-none rounded-md"
                    type="primary"
                    htmlType="submit"
                  >
                    {t("btnsend")}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <Spinner loading={isLoading} />
      <Modal
        title={t(dataResponse)}
        subtitle={
          tokenReset
            ? t("Success reset your password")
            : t("Please check email to reset your password")
        }
        type="success"
        visible={isModalSuccess}
        onCancel={() => setIsModalSuccess(false)}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={handleDone}>
            {t("Done")}
          </Button>
        </div>
      </Modal>
    </>
  );
}
