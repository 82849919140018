import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FilterOutlined,
  ProfileFilled,
} from "@ant-design/icons";
import {
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Radio,
  Row,
  Select,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as GroupIcon } from "../../assets/icons/account-group.svg";
import { ReactComponent as FilterOutline } from "../../assets/icons/filter-outline.svg";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { useTranslation } from "react-i18next";
import {
  useDeleteGroupMutation,
  useFetchListGroupQuery,
} from "../../config/features/group";
import { errorMessage } from "../../utils";
const { Search } = Input;
const { Option } = Select;

export default function Group() {
  const history = useHistory();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [groupNameToDelete, setGroupNameToDelete] = useState(null);
  const [paramsTable, setParamsTable] = useState({
    row: 10,
    page: 1,
    sort_by: "created_at",
    order: "desc",
  });
  const [visibleFilter, setVisibleFilter] = useState(false);

  const {
    data: dataListGroup = [],
    isFetching,
    refetch,
    error,
    isError,
  } = useFetchListGroupQuery(paramsTable);
  const [
    deleteGroup,
    { 
      isSuccess: isSuccessDelete, 
      isLoading: isLoadingDelete, 
      isError: isErrorDelete, 
      error: errorDelete 
    },
  ] = useDeleteGroupMutation();

  useEffect(() => {
    if (isSuccessDelete) {
      refetch();
      setGroupName(null);
      setGroupNameToDelete(null);
      setIsModalSuccess(true);
      setTimeout(() => {
        setIsModalSuccess(false);
      }, 3000);
    }
  }, [refetch, isSuccessDelete]);

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
  }, [isError, error]);

  useEffect(() => {
    if (isErrorDelete) {
      errorMessage(errorDelete);
    }
  }, [isErrorDelete, errorDelete]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  function handleResetFilter() {
    setParamsTable({
      ...paramsTable,
      sort_by: "created_at",
      order: "desc",
    });
  }

  const menuFilter = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={handleResetFilter}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort By</p>
            <Select
              defaultValue="created_at"
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, sort_by: e })}
              value={paramsTable.sort_by}
            >
              <Option value="group_name">Group Name</Option>
              <Option value="created_at">Created Date</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">Sort Type</p>
            <Radio.Group
              defaultValue="asc"
              buttonStyle="solid"
              onChange={(e) =>
                setParamsTable({ ...paramsTable, order: e.target.value })
              }
              value={paramsTable.order}
            >
              <Radio value="asc">Ascending</Radio>
              <Radio value="desc">Descending</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const menuAction = (record) => {
    return (
      <Menu>
        <Menu.Item>
          <div
            className="flex flex-row space-x-2 items-center"
            onClick={() =>
              history.push({
                pathname: `/group/detail/${record.group_id}`,
                state: { 
                  is_owner: record?.is_owner
                },
              })
            }
          >
            <ProfileFilled />
            <p>Detail</p>
          </div>
        </Menu.Item>
        {record.is_owner && 
          <>
            <Menu.Item>
              <div
                className="flex flex-row space-x-2 items-center"
                onClick={() =>
                  history.push({
                    pathname: `/group/edit/${record.group_id}`,
                    state: record,
                  })
                }
              >
                <EditOutlined />
                <p>Edit</p>
              </div>
            </Menu.Item>
            <Menu.Item>
              <div
                className="flex flex-row space-x-2 items-center text-red-500"
                onClick={() => {
                  setGroupId(record.group_id);
                  setGroupNameToDelete(record.group_name);
                  setIsModalDelete(true);
                }}
              >
                <DeleteOutlined />
                <p>Delete</p>
              </div>
            </Menu.Item>
          </>
        }
      </Menu>
    );
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Group Name",
      dataIndex: "group_name",
      key: "group_name",
    },
    {
      title: "User List",
      dataIndex: "members",
      key: "members",
      render: (members) => {
        return (
          <p className="w-96 truncate">
            {members?.map((item, index) => {
              return (
                <span key={index}>
                  {item.email}
                  {members?.length > 1 && members?.length !== index + 1 && ", "}
                </span>
              );
            })}
          </p>
        );
      },
    },
    {
      render: (record) => {
        return (
          <Dropdown
            overlay={menuAction(record)}
            trigger={["click"]}
          >
            <div className="group cursor-pointer">
              <EllipsisOutlined className="group-hover:text-blue-500" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  const onSearch = (value) => setParamsTable({ ...paramsTable, search: value });

  function handleCancel() {
    setIsModalDelete(false);
  }

  async function handleDelete() {
    try {
      setIsModalDelete(false);
      deleteGroup(groupId);
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleDone() {
    setIsModalSuccess(false);
  }

  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }

  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <h1 className="text-xl md:text-2xl font-medium">{t("group")}</h1>
            </Col>
            <Col>
              <Button
                className="md:w-40"
                type="primary"
                onClick={() => history.push("/group/add")}
                icon={<GroupIcon />}
              >
                {t("addgroup")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("ListofGroups")}
                    </h3>
                  </Col>
                  <Col>
                    <Row gutter={[12, 12]}>
                      <Col>
                        <Search
                          placeholder="Search"
                          allowClear
                          onSearch={onSearch}
                          onChange={(e) =>
                            setTimeout(() => {
                              onSearch(e.target.value);
                            }, 1000)
                          }
                          style={{
                            width: 200,
                            borderRadius: "10px !important",
                          }}
                        />
                      </Col>
                      <Col>
                        <Dropdown
                          overlay={menuFilter}
                          onVisibleChange={handleVisibleFilter}
                          visible={visibleFilter}
                          trigger={["click"]}
                        >
                          <Button
                            type="primary"
                            ghost
                            icon={<FilterOutlined />}
                          />
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={datasetsListTable(dataListGroup)}
                  scroll={{ x: 800 }}
                  loading={isFetching || isLoadingDelete}
                  pagination={{
                    current: paramsTable.page,
                    pageSize: paramsTable.row,
                    total: dataListGroup[0] ? dataListGroup[0]?.total : 0,
                  }}
                  onChange={handleChangeTable}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Delete Group?"
        subtitle="Are you sure want to delete this group?"
        type="danger"
        visible={isModalDelete}
        onCancel={handleCancel}
      >
        <Row gutter={[16,16]}>
          <Col span={24}>
            <Input 
              placeholder="Enter the group name" 
              value={groupName}
              onChange={e => setGroupName(e.target.value)}
            />
          </Col>
          <Col span={12}>
            <Button
              onClick={handleCancel}
              className="w-full"
              type="secondary"
              ghost
              danger
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              onClick={handleDelete}
              className="w-full"
              danger
              type="primary"
              disabled={groupName !== groupNameToDelete}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Updated Success!"
        subtitle="Changes have been saved! Now you can continue browsing."
        visible={isModalSuccess}
        closable={false}
      >
        <div className="text-center">
          <Button onClick={handleDone} className="w-32" type="primary">
            Done
          </Button>
        </div>
      </Modal>
    </>
  );
}
