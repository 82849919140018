import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { rootApi, access } from "../config";

export const productApi = createApi({
  reducerPath: "api/product",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi + "/platform",
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      fetchListProduct: builder.query({
        query({
          page = 1,
          sort_by = "created_at",
          order = "desc",
          row = "10",
          search = "",
        }) {
          return `/product?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchDetailProduct: builder.query({
        query(id_product) {
          return `/product/${id_product}`;
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),

      addProduct: builder.mutation({
        query: (body) => ({
          method: "PUT",
          url: "/product",
          body,
        }),
      }),

      fetchProductLog: builder.query({
        query({ page = 1, row = "10" }) {
          return `/product-log?page=${page}&row=${row}`;
        },
        transformResponse: (response) => (response ? response.data : {}),
      }),
    };
  },
});

export const {
  useFetchListProductQuery,
  useAddProductMutation,
  useFetchDetailProductQuery,
  useFetchProductLogQuery,
} = productApi;
