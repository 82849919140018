import { UploadOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Table,
  Upload,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, Spinner, NumericInput } from "../../components";
import { useRegisterAdminMutation } from "../../config/features/admin";
import { useFetchDetailClientQuery, useFetchDetailQoutationQuery } from "../../config/features/platform";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { useFetchListRegionQuery } from "../../config/features/general";
import { useFetchAllContractQuery, useFetchDetailContractQuery } from "../../config/features/contract";
import { errorMessage, numberCommas } from "../../utils";
import moment from "moment";
import clsx from "clsx";
import { CONTRACT_STATUS, MODULE_LIST, REGEXES } from "../../constant";
import { useTranslation } from "react-i18next";
const { Option } = Select;

export default function RegistrationAccount() {
  const history = useHistory();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  //
  const [tabs, setTabs] = useState(0);
  const lastTab = 2;
  const isEndTabs = tabs === lastTab;
  //
  const [fileListNpwp, setFileListNpwp] = useState([]);
  const [fileListTdp, setFileListTdp] = useState([]);
  const [fileListKtp, setFileListKtp] = useState([]);
  const [fileListDoc1, setFileListDoc1] = useState([]);
  const [fileListDoc2, setFileListDoc2] = useState([]);
  const [fileListDoc3, setFileListDoc3] = useState([]);
  //
  const [payload, setPayload] = useState({
    nik: null,
    rt: null,
    rw: null,
    office_phone: null,
    npwp_number: null,
  });
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [provinceSelected, setProvinceSelected] = useState({
    province_id: 0,
  });

  const { data: profileUser = {} } = useFetchProfileUserQuery();

  const { data: dataDetailClient = {} } = useFetchDetailClientQuery(
    profileUser?.office_id
  );

  const { data: dataAllContract = {} } = useFetchAllContractQuery(
    profileUser?.office_id
  );
  
  const { data: dataDetailContract = {} } = useFetchDetailContractQuery(
    dataAllContract[0]?.id
  );

  const { data: dataDetailQoutation = {} } = useFetchDetailQoutationQuery({id_office: profileUser?.office_id});

  const { data: dataCity = [] } = useFetchListRegionQuery(
    provinceSelected?.province_id
  );
  const { data: dataProvince = [] } = useFetchListRegionQuery();

  const [registerAdmin, { isSuccess, isError, error, isLoading }] =
    useRegisterAdminMutation();
    
  // Taken from the module in DetailClient
  // Mapped to suit the table Data data structure
  const moduleListData = useMemo(() => {
    const module = dataDetailClient?.module
    if(module){
      const keys = Object.keys(module)
      return keys
        .map((key) => module[key] && MODULE_LIST[key] ? ({ module_name: MODULE_LIST[key] }) : null)
        .filter(data => data)
    }
    
    return []
  }, [dataDetailClient?.module])

  useEffect(() => {
    // Check if user is already registered
    form.setFieldsValue({ ...dataDetailClient?.account });
    const isRegistered = profileUser?.is_registered;
    if (isRegistered === true || isRegistered === undefined) {
      history.push("/profile");
    }
  }, [form, dataDetailClient, profileUser, history]);

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }

    if (isSuccess) {
      setIsModalSuccess(true);
      form.resetFields();
    }
  }, [isError, isSuccess, error, form]);

  // Props for uploading NPWP file
  // Contain validation for type of file and size 
  const propsUploadNpwp = {
    onRemove: () => {
      setFileListNpwp([]);
    },
    beforeUpload: (file) => {
      const isPdf = file.type === "application/pdf";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isPdf) {
        message.error("You can't upload this extension!");
      } else if (!isLt5M) {
        message.error("File must smaller than 5MB!");
      } else {
        setFileListNpwp([file]);
      }
      return false;
    },
    fileListNpwp,
  };

  // Props for uploading TDP file
  // Contain validation for type of file and size
  const propsUploadTdp = {
    onRemove: () => {
      setFileListTdp([]);
    },
    beforeUpload: (file) => {
      const isPdf = file.type === "application/pdf";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isPdf) {
        message.error("You can't upload this extension!");
      } else if (!isLt5M) {
        message.error("File must smaller than 5MB!");
      } else {
        setFileListTdp([file]);
      }
      return false;
    },
    fileListTdp,
  };

  // Props for uploading KTP file
  // Contain validation for type of file and size
  const propsUploadKtp = {
    onRemove: () => {
      setFileListKtp([]);
    },
    beforeUpload: (file) => {
      const isPdf = file.type === "application/pdf";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isPdf) {
        message.error("You can't upload this extension!");
      } else if (!isLt5M) {
        message.error("File must smaller than 5MB!");
      } else {
        setFileListKtp([file]);
      }
      return false;
    },
    fileListKtp,
  };

  const propsUploadAgreementDoc1 = {
    onRemove: () => {
      setFileListDoc1([]);
    },
    beforeUpload: (file) => {
      const isPdf = file.type === "application/pdf";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isPdf) {
        message.error("You can't upload this extension!");
      } else if (!isLt5M) {
        message.error("File must smaller than 5MB!");
      } else {
        setFileListDoc1([file]);
      }
      return false;
    },
    fileListDoc1,
  };

  const propsUploadAgreementDoc2 = {
    onRemove: () => {
      setFileListDoc2([]);
    },
    beforeUpload: (file) => {
      const isPdf = file.type === "application/pdf";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isPdf) {
        message.error("You can't upload this extension!");
      } else if (!isLt5M) {
        message.error("File must smaller than 5MB!");
      } else {
        setFileListDoc2([file]);
      }
      return false;
    },
    fileListDoc2,
  };

  const propsUploadAgreementDoc3 = {
    onRemove: () => {
      setFileListDoc3([]);
    },
    beforeUpload: (file) => {
      const isPdf = file.type === "application/pdf";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isPdf) {
        message.error("You can't upload this extension!");
      } else if (!isLt5M) {
        message.error("File must smaller than 5MB!");
      } else {
        setFileListDoc3([file]);
      }
      return false;
    },
    fileListDoc3,
  };

  const onFinish = (values) => {
    values.dob = moment(values.dob).format("YYYY-MM-DD");
    setPayload({ ...payload, ...values });

    if(tabs === 0){
      // Validation for NIK if not inputted
      if (payload.nik) {
        setTabs(1);
      } else {
        message.error("Please Enter You NIK Number!");
      }
    }

    if(tabs === 1){
       // Validation for file and input file that has to be number but still act as string
      if (!fileListNpwp[0] && !fileListTdp[0]) {
        message.error("Please attach your file!");
        return;
      } else if (!payload.office_phone) {
        message.error("Please input Office Phone Number!");
      } else if (!payload.npwp_number) {
        message.error("Please input NPWP!");
      } else {
        setTabs(2);
      }
    }

    // ===============================================
    // Document Contract is disabled for now.
    // It is hidden, until further requirement confirmations.
    // ===============================================
    // if (tabs === 2) {
    //   if(!fileListDoc1[0] || !fileListDoc2[0] || !fileListDoc3[0]){
    //     message.error("Please attach Cooperation Agreemnt files!");
    //   } else {
    //     setIsModalConfirm(true);
    //   }
    // }
    if(tabs === 2){
      setIsModalConfirm(true);
    }
  };

  function toggleModalConfirm() {
    setIsModalConfirm(!isModalConfirm);
  }

  async function handleSubmit() {
    try {
      toggleModalConfirm();
      const formData = new FormData();
      formData.append("nik", payload.nik);
      formData.append("pob", payload.pob);
      formData.append("dob", payload.dob);
      formData.append("rt", payload.rt);
      formData.append("rw", payload.rw);
      formData.append("kel", payload.kel);
      formData.append("kec", payload.kec);
      formData.append("agama", payload.agama);
      formData.append("statuskawin", payload.statuskawin);
      formData.append("pekerjaan", payload.pekerjaan);
      formData.append("warganegara", payload.warganegara);
      formData.append("fileUrlKTP", fileListKtp[0]);

      formData.append("office_phone", payload.office_phone);
      formData.append("address", payload.address);
      formData.append("province", payload.province);
      formData.append("city", payload.city);
      formData.append("npwp_number", payload.npwp_number);
      formData.append("npwp_file", fileListNpwp[0]);
      formData.append("tdp_file", fileListTdp[0]);
      formData.append("password", payload.password);

      formData.append("contract_doc_1", fileListDoc1[0]);
      formData.append("contract_doc_2", fileListDoc2[0]);
      formData.append("contract_doc_3", fileListDoc3[0]);

      registerAdmin(formData);
    } catch (error) {
      message.error(error.message);
    }
  }

  const handleDone = () => {
    history.push(`/profile`);
  };

  const listReligion = [
    {
      label: "Islam",
      value: "Islam",
    },
    {
      label: "Kristen",
      value: "Kristen",
    },
    {
      label: "Hindu",
      value: "Hindu",
    },
    {
      label: "Budha",
      value: "Budha",
    },
    {
      label: "Konghucu",
      value: "Konghucu",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  const listMarital = [
    {
      label: "Single",
      value: "Single",
    },
    {
      label: "Married",
      value: "Married",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  const listCitizen = [
    {
      label: "WNI",
      value: "WNI",
    },
    {
      label: "WNA",
      value: "WNA",
    },
  ];

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }

  function handleChangeNik(e) {
    setPayload({ ...payload, nik: e });
  }

  function handleChangeRt(e) {
    setPayload({ ...payload, rt: e });
  }

  function handleChangeRw(e) {
    setPayload({ ...payload, rw: e });
  }

  function handleChangePhone(e) {
    setPayload({ ...payload, office_phone: e });
  }

  function handleChangeNpwp(e) {
    setPayload({ ...payload, npwp_number: e });
  }

  return (
    <>
      <Form
        className="mt-10"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]} className="mt-4">
              <Col xs={24} sm={12} md={8} className="flex justify-start">
                <h1 className="text-xl md:text-2xl font-medium">
                  Registration Account
                </h1>
              </Col>
              <Col xs={24} sm={12} md={8} className="flex justify-center">
                <div className="flex flex-col space-y-4">
                  <div className="flex flex-row space-x-2 md:space-x-4 lg:space-x-12 xl:space-x-20">
                    <div
                      className={`py-0.5 px-2 md:py-1 md:px-3 text-white rounded-full font-medium text-center transition ease-in-out duration-500 ${
                        tabs === 0 ? "bg-blue-700" : "bg-gray-300"
                      }`}
                    >
                      1
                    </div>
                    <div
                      className={`py-0.5 px-2 md:py-1 md:px-3 text-white rounded-full font-medium text-center transition ease-in-out duration-500 ${
                        tabs === 1 ? "bg-blue-700" : "bg-gray-300"
                      }`}
                    >
                      2
                    </div>
                    <div
                      className={`py-0.5 px-2 md:py-1 md:px-3 text-white rounded-full font-medium text-center transition ease-in-out duration-500 ${
                        tabs === 2 ? "bg-blue-700" : "bg-gray-300"
                      }`}
                    >
                      3
                    </div>
                  </div>
                  <h2 className="font-medium text-sm md:text-base lg:text-lg text-blue-700 text-center">
                    {tabs === 0
                      ? "Personal Information"
                      : tabs === 1
                      ? "Office Information"
                      : "Cooperation Agreement"}
                  </h2>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} className="flex justify-end">
                <Row gutter={[12, 12]}>
                  {tabs > 0 && (
                    <Col>
                      <Button
                        className="w-fit lg:w-20 xl:w-32"
                        type="primary"
                        ghost
                        onClick={() => setTabs(prevTab => prevTab - 1)}
                      >
                        Back
                      </Button>
                    </Col>
                  )}
                  <Col>
                    {isEndTabs ? (
                    <Form.Item>
                      <Button
                        className="w-fit lg:w-20 xl:w-32"
                        type="primary"
                        htmlType="submit"
                        //   loading={isLoading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                    ) : (
                    <Form.Item>
                      <Button
                        className="w-fit lg:w-20 xl:w-32"
                        type="primary"
                        htmlType="submit"
                      >
                        Next
                      </Button>
                    </Form.Item>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {/* <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col>
                <img
                  className="w-14"
                  src="/images/logo-primary.png"
                  alt="dli-logo"
                />
              </Col>
              <Col>
                <Divider className="h-full" type="vertical" />
              </Col>
              <Col className="flex items-center">
                <h1 className="text-lg md:text-xl font-medium text-gray-500">
                  Registration Account
                </h1>
              </Col>
            </Row>
          </Col> */}
          {/* <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <h1 className="text-xl md:text-2xl font-medium">
                  {tabs === 0 && "Personal Information"}
                  {tabs === 1 && "Office Information"} 
                  {tabs === 2 && "Cooperation Agreement"}
                </h1>
              </Col>
              <Col>
                <Row gutter={[24, 24]}>
                  {tabs > 0 && (
                    <Col>
                      <Button
                        className="md:w-40"
                        type="primary"
                        ghost
                        onClick={() => setTabs(prevTab => prevTab - 1)}
                      >
                        Back
                      </Button>
                    </Col>
                  )}
                  <Col>
                    {isEndTabs ? (
                    <Form.Item>
                      <Button
                        className="md:w-40"
                        type="primary"
                        htmlType="submit"
                        //   loading={isLoading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                    ) : (
                    <Form.Item>
                      <Button
                        className="md:w-40"
                        type="primary"
                        htmlType="submit"
                      >
                        Next
                      </Button>
                    </Form.Item>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col> */}
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                  {tabs === 0 && (
                    <Card className="w-full">
                      <Row gutter={[24, 12]}>
                        <Col xs={24} md={12}>
                          <Form.Item label="First Name PIC" name="first_name_pic">
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item label="Last Name PIC" name="last_name_pic">
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item label="Phone Number PIC" name="phone_pic">
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item label="Email PIC" name="email_pic">
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <div className="flex flex-col space-y-2">
                            <label>
                              <span className="text-red-500 mr-1">*</span>NIK
                            </label>
                            <NumericInput
                              placeholder="Enter NIK"
                              maxLength={16}
                              value={payload.nik}
                              onChange={handleChangeNik}
                              
                            />
                          </div>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Religion/Agama"
                            name="agama"
                            rules={[
                              {
                                required: true,
                                message: "Please Select Your Region!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Not Selected"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              allowClear={false}
                            >
                              {listReligion?.map((item, index) => {
                                return (
                                  <Option key={index} value={item?.value}>
                                    {item?.label}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Place of Birth/Tempat Lahir"
                            name="pob"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter You Place of Birth!",
                              },
                            ]}
                          >
                            <Input placeholder="Enter Place of Birth" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Date of Birth/Tanggal Lahir"
                            name="dob"
                            rules={[
                              {
                                required: true,
                                message: "Please Select Your Date of Birth!",
                              },
                            ]}
                          >
                            <DatePicker
                              format="DD/MM/YYYY"
                              className="w-full"
                              disabledDate={disabledDate}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Address"
                            name="address"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Your address!",
                              },
                            ]}
                          >
                            <Input placeholder="Enter address" maxLength={50} />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          {/* <Form.Item
                            label="RT"
                            name="rt"
                            rules={[
                              {
                                required: false,
                                message: "Please input RT!",
                              },
                            ]}
                          > */}
                          <div className="flex flex-col space-y-2">
                            <label>RT</label>
                            <NumericInput
                              placeholder="Enter RT"
                              maxLength={3}
                              value={payload.rt}
                              onChange={handleChangeRt}
                            />
                          </div>
                          {/* </Form.Item> */}
                        </Col>
                        <Col xs={12} md={6}>
                          {/* <Form.Item
                            label="RW"
                            name="rw"
                            rules={[
                              {
                                required: false,
                                message: "Please input RW!",
                              },
                            ]}
                          > */}
                          <div className="flex flex-col space-y-2">
                            <label>RW</label>
                            <NumericInput
                              placeholder="Enter RW"
                              maxLength={3}
                              value={payload.rw}
                              onChange={handleChangeRw}
                            />
                          </div>
                          {/* </Form.Item> */}
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Village/Kelurahan"
                            name="kel"
                            rules={[
                              {
                                required: false,
                                message: "Please input village!",
                              },
                            ]}
                          >
                            <Input placeholder="Enter village" maxLength={50} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="District/Kecamatan"
                            name="kec"
                            rules={[
                              {
                                required: false,
                                message: "Please input distric!",
                              },
                            ]}
                          >
                            <Input placeholder="Enter distric" maxLength={50} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Marital Status/Status Pernikahan"
                            name="statuskawin"
                            rules={[
                              {
                                required: false,
                                message: "Please select marital status!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Not Selected"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              allowClear={false}
                            >
                              {listMarital?.map((item, index) => {
                                return (
                                  <Option key={index} value={item?.label}>
                                    {item?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Occupation"
                            name="pekerjaan"
                            rules={[
                              {
                                required: false,
                                message: "Please input occupation!",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter occupation"
                              maxLength={50}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Citizen/Kewarganegaraan"
                            name="warganegara"
                            rules={[
                              {
                                required: true,
                                message: "Please Select Your Citizen !",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Not Selected"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              allowClear={false}
                            >
                              {listCitizen?.map((item, index) => {
                                return (
                                  <Option key={index} value={item?.label}>
                                    {item?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="KTP File"
                            name="fileUrlKTP"
                            rules={[
                              {
                                required: false,
                                message: "Please attach file!",
                              },
                            ]}
                          >
                            <Upload
                              className="w-full"
                              maxCount={1}
                              {...propsUploadKtp}
                              fileList={fileListKtp}
                            >
                              <Button ghost icon={<UploadOutlined />}>
                                Click to Upload
                              </Button>
                            </Upload>
                            <small>Format : PDF | Max : 5 Mb</small>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  )}  

                  {tabs === 1 && (
                    <Card className="w-full">
                      <Row gutter={[24, 12]}>
                        <Col xs={24} md={12}>
                          <Form.Item label="Office Name" name="office_name">
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item label="Email Office" name="email_office">
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <div className="flex flex-col space-y-2">
                            <label>
                              <span className="text-red-500 mr-1">*</span>Office
                              Phone Number / Nomor Telfon
                            </label>
                            <NumericInput
                              placeholder="Enter phone"
                              minLength={7}
                              maxLength={14}
                              value={payload.office_phone}
                              onChange={handleChangePhone}
                            />
                          </div>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Office Address/Alamat Kantor"
                            name="address"
                            rules={[
                              {
                                required: true,
                                message: "Please input office address!",
                              },
                            ]}
                          >
                            <Input placeholder="Enter address" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Province/Provinsi"
                            name="province"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter You Province!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Not Selected"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              allowClear={false}
                              onChange={(_, record) =>
                                setProvinceSelected(dataProvince[record?.key])
                              }
                            >
                              {dataProvince?.map((item, index) => {
                                return (
                                  <Option key={index} value={item?.province_name}>
                                    {item?.province_name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="City/Kota"
                            name="city"
                            rules={[
                              {
                                required: true,
                                message: "Please Select You City!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Not Selected"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              allowClear={false}
                            >
                              {dataCity?.map((item, index) => {
                                return (
                                  <Option key={index} value={item?.kab_name}>
                                    {item?.kab_name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Divider className="my-0" />
                        </Col>
                        <Col xs={24} md={12}>
                          <div className="flex flex-col space-y-2">
                            <label>
                              <span className="text-red-500 mr-1">*</span>NPWP
                              Number
                            </label>
                            <NumericInput
                              placeholder="Enter npwp number"
                              maxLength={15}
                              value={payload.npwp_number}
                              onChange={handleChangeNpwp}
                            />
                          </div>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label={
                              <p>
                                <span className="text-red-500">*</span> NPWP File
                              </p>
                            }
                            name="npwp_file"
                            rules={[
                              {
                                required: false,
                                message: "Please attach file!",
                              },
                            ]}
                          >
                            <Upload
                              className="w-full"
                              maxCount={1}
                              {...propsUploadNpwp}
                              fileList={fileListNpwp}
                            >
                              <Button ghost icon={<UploadOutlined />}>
                                Click to Upload
                              </Button>
                            </Upload>
                            <small>Format : PDF | Max : 5 Mb</small>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label={
                              <p>
                                <span className="text-red-500">*</span> TDP File
                              </p>
                            }
                            name="tdp_file"
                            rules={[
                              {
                                required: false,
                                message: "Please attach file!",
                              },
                            ]}
                          >
                            <Upload
                              className="w-full"
                              maxCount={1}
                              {...propsUploadTdp}
                              fileList={fileListTdp}
                            >
                              <Button ghost icon={<UploadOutlined />}>
                                Click to Upload
                              </Button>
                            </Upload>
                            <small>Format : PDF | Max : 5 Mb</small>
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Divider />
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Password"
                            name="password"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please input password!",
                              },
                              () => ({
                                validator(_, value) {
                                  if (
                                    value.match(
                                      REGEXES.password
                                    )
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                     t( "Password needs to be at least 8 character, 1 uppercase, 1 special character!")
                                    )
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password placeholder="Enter password" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Password Confirmation"
                            name="password_confirmation"
                            dependencies={["password"]}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please confirm your password!",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("password") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "The two passwords that you entered do not match!"
                                    )
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password placeholder="Enter password confirmation" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  )}

                  {tabs === 2 && (
                    <Row gutter={[24, 12]}>
                      <Col span={24}>
                        <Card className="rounded-md">
                          <Row>
                            <Col xs={24} sm={12} md={6}>
                              <h5 className="text-gray-500 mb-3">
                                Status
                              </h5>
                              <h4 className={clsx("text-3xl", CONTRACT_STATUS[dataDetailContract?.status]?.clsTextColor)}>
                                {CONTRACT_STATUS[dataDetailContract?.status]?.value}
                              </h4>
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                              <h5 className="text-gray-500 mb-3">
                                Date Issued
                              </h5>
                              <h4 className="text-3xl">
                                {dataDetailContract?.issued_at
                                ? moment(dataDetailContract?.issued_at, moment.ISO_8601)
                                  .format("DD/MM/YY")
                                : "--"}
                              </h4>
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                              <h5 className="text-gray-500 mb-3">
                                Expired Date
                              </h5>
                              <h4 className="text-3xl">
                                {dataDetailContract?.expired_at
                                ? moment(dataDetailContract?.expired_at, moment.ISO_8601)
                                  .format("DD/MM/YY")
                                : "--"}
                              </h4>
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                              <h5 className="text-gray-500 mb-3">
                                Last Update
                              </h5>
                              <h4 className="text-3xl">
                                {dataDetailContract?.updated_at
                                ? moment(dataDetailContract?.updated_at, moment.ISO_8601)
                                  .format("DD/MM/YY")
                                : "--"}
                              </h4>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <br /> <br />
                      <br /> <br />
                      <Col xs={24} md={24}>
                        <Card className="rounded-md">
                          <h2 className="text-gray-500 text-lg md:text-xl font-medium mb-2">
                            Product List
                          </h2>
                          <Table 
                            pagination={{ position: ["none"]}}
                            columns={[
                              {
                                title: 'Product Name',
                                dataIndex: 'product_name',
                                key: 'product_name',
                                render: text => text,
                              },
                              {
                                title: 'Unit Price',
                                dataIndex: 'unit_price',
                                key: 'unit_price',
                                render: text => `Rp ${numberCommas(text)},-`,
                              },
                              {
                                title: 'Quantity',
                                dataIndex: 'quantity',
                                key: 'quantity',
                                render: text => numberCommas(text),
                              },
                              {
                                title: 'Quota',
                                dataIndex: 'remaining_quota',
                                key: 'remaining_quota',
                                render: text => numberCommas(text),
                              },
                            ]} 
                            dataSource={dataDetailQoutation?.quotation?.products || []} />
                        </Card>
                      </Col>
                      <Col xs={24} md={24}>
                        <Card className="rounded-md">
                          <h2 className="text-gray-500 text-lg md:text-xl font-medium mb-2">
                            Module List
                          </h2>
                          <Table 
                            pagination={{ position: ["none"] }}
                            columns={[
                              {
                                title: 'Module Name',
                                dataIndex: 'module_name',
                                key: 'module_name',
                                render: text => text,
                              },
                            ]} 
                            dataSource={moduleListData} 
                          />
                        </Card>
                      </Col>
                      {/* 
                      ===============================================
                      Document Contract is disabled for now.
                      It is hidden, until further requirement confirmations.
                      ===============================================
                      <Col xs={24} md={24}>
                        <Card className="rounded-md">
                          <h2 className="text-gray-500 text-lg md:text-xl font-medium mb-2">
                            Document Upload
                          </h2>
                          <Row>
                            <Col xs={24} sm={12} md={8}>
                              <Form.Item
                                label={
                                  <p><span className="text-red-500">*</span> Document 1 </p>
                                }
                                name="document1"
                                rules={[
                                  {
                                    required: false,
                                    message: "Please attach file!",
                                  },
                                ]}
                              >
                                <Upload
                                  className="w-full"
                                  maxCount={1}
                                  {...propsUploadAgreementDoc1}
                                  fileList={fileListDoc1}
                                >
                                  <Button ghost icon={<UploadOutlined />}>
                                    Click to Upload
                                  </Button>
                                </Upload>
                                <small>Format : PDF | Max : 5 Mb</small>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                              <Form.Item
                                label={
                                  <p><span className="text-red-500">*</span> Document 2 </p>
                                }
                                name="document2"
                                rules={[
                                  {
                                    required: false,
                                    message: "Please attach file!",
                                  },
                                ]}
                              >
                                <Upload
                                  className="w-full"
                                  maxCount={1}
                                  {...propsUploadAgreementDoc2}
                                  fileList={fileListDoc2}
                                >
                                  <Button ghost icon={<UploadOutlined />}>
                                    Click to Upload
                                  </Button>
                                </Upload>
                                <small>Format : PDF | Max : 5 Mb</small>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                              <Form.Item
                                label={
                                  <p><span className="text-red-500">*</span> Document 3 </p>
                                }
                                name="document3"
                                rules={[
                                  {
                                    required: false,
                                    message: "Please attach file!",
                                  },
                                ]}
                              >                                
                              <Upload
                                  className="w-full"
                                  maxCount={1}
                                  {...propsUploadAgreementDoc3}
                                  fileList={fileListDoc3}
                                >
                                  <Button ghost icon={<UploadOutlined />}>
                                    Click to Upload
                                  </Button>
                                </Upload>
                                <small>Format : PDF | Max : 5 Mb</small>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      </Col> */}
                    </Row>
                  )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Are you sure?"
        subtitle="You will send the data for company registration account, please make sure before you submit."
        type="warning"
        visible={isModalConfirm}
        onCancel={toggleModalConfirm}
      >
        <div className="flex flex-row space-x-4 pt-4">
          <Button
            className="w-full"
            type="primary"
            ghost
            onClick={toggleModalConfirm}
          >
            Back
          </Button>
          <Button className="w-full" type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Modal>
      <Modal
        title="Registration Success!"
        subtitle="You have submit the data! Please check your email to activate your account"
        type="success"
        visible={isModalSuccess}
        onCancel={() => setIsModalSuccess(false)}
      >
        <div className="text-center">
          <Button className="w-32" type="primary" onClick={handleDone}>
            Done
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
