import { LeftOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Space,
  Upload ,
  Modal as ModalAntd
} from "antd";
import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import SignaturePad from "react-signature-canvas";
import { ReactComponent as AlertCircleIcon } from "../../assets/icons/alert-circle-outline.svg";
// import DeleteBoxIcon from '../../assets/img/icon/deletebox.png'
import { Modal } from "../../components";
import Spinner from "../../components/Spinner";
import { useFetchProfileUserQuery, useGenerateQRCodeSpecimentMutation } from "../../config/features";
import { rootApi } from "../../config/features/config";
import { useTranslation } from "react-i18next";
import { errorMessage } from "../../utils";


// encoded file to base64 format and remove prefix of file 
let VasLogo;
import("../../assets/img/vas-logo-qr.png").then((module) => {
  VasLogo = module.default;
})

let TemplateQR;
import("../../assets/img/template_qrcode.png").then((module) => {
  TemplateQR = module.default;
})

const toBase64 = (file) => {

  return new Promise( (resolve , reject) => {

    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {

        if(reader.result.includes('data:image/png;base64,')){
          resolve(reader.result.replace('data:image/png;base64,' , ''))
        }
    }

    reader.onerror = (e) => reject(e)

  })

}

export default function CreateSignature() {
  const { t } = useTranslation();

  const tokenAccess = useMemo( () => localStorage.getItem('access') , [])
  const tokenPeruri = useMemo( () => localStorage.getItem('peruri') , [])
  const history = useHistory();
  let sigPadRef = {};
  let initPadRef = {};

  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [signatureTypeRadio, setSignatureTypeRadio] = useState(1);
  const [payload, setPayload] = useState({});
  const [loading , setLoading ] = useState(false)
  const [type , setType] = useState({ spesiment : '' , paraf : ''})
  const [drawSign , setDrawSign] = useState(false)
  const [drawParaf , setDrawParaf] = useState(false)
  const [openDelete , setOpenDelete] = useState(false)


  const [
    generateQRSign,
    {
      data: dataQRSign,
      isError: isErrorGenerateQRSign,
      error: errorGenerateQRSign,
      isLoading: isLoadignQRSign
    }
  ] = useGenerateQRCodeSpecimentMutation();

  const [
    generateQRParaf,
    {
      data: dataQRParaf,
      isError: isErrorGenerateParaf,
      error: errorGenerateQRParaf,
      isLoading: isLoadingQRParaf
    }
  ] = useGenerateQRCodeSpecimentMutation();

  useEffect(() => {
    if (isErrorGenerateQRSign) {
      errorMessage(errorGenerateQRSign)
    }
  }, [isErrorGenerateQRSign, errorGenerateQRSign]);

  useEffect(() => {
    if (isErrorGenerateParaf) {
      errorMessage(errorGenerateQRParaf);
    }
  }, [isErrorGenerateParaf, errorGenerateQRParaf]);
  
  // get data profile user
  const {data : userProfile = {} , isLoading : loadingProfile } = useFetchProfileUserQuery()


  const onFinish = async (values) => {

    if(signatureTypeRadio === 1)
    {

      try{

        if(!fileList[0] || !fileList2[0]){
          
          message.info(t('Please Upload Your Signature and Paraf'))

          return 
        }


            if(fileList[0] && fileList2[0]){

              setLoading(true)

              const {0 : fileSignature } = fileList
              
              const {0 : fileInitial } = fileList2
              
              const body = {
                extSpeciment : type.spesiment ,
                extParaf : type.paraf ,
                base64Paraf : await toBase64(fileInitial) ,
                base64Speciment: await toBase64(fileSignature) ,
                initials : values.initials
              }

        
              const {data} = await axios({
                method : 'POST' ,
                url : `${rootApi}/sign/set-speciment` ,
                data : body ,
                headers : {
                  token : tokenAccess ,
                 "token-peruri-sign" : tokenPeruri
                }
              })
        
              setLoading(false)

              setIsModalSuccess(true)

              return

            }


      }catch(e){

        const {data : {error}} = e.response

        setLoading(false)

        message.error(error)

      }

    }

    else if( signatureTypeRadio === 2 )
    {

      if(!drawSign || !drawParaf)
      {

        message.info(t('Please add your signature and paraf'))

        return

      }

      if(drawSign && drawParaf)
      {

        let dataSign = sigPadRef.getTrimmedCanvas().toDataURL('image/png').replace('data:image/png;base64,' , '')

        let dataParaf = initPadRef.getTrimmedCanvas().toDataURL('image/png').replace('data:image/png;base64,' , '')

        const body = {
          extSpeciment : "png" ,
          extParaf : "png" ,
          base64Paraf : dataParaf ,
          base64Speciment: dataSign ,
          initials : values.initials
        }


        try{

          setLoading(true)
  
          const {data} = await axios({
            method : 'POST' ,
            url : `${rootApi}/sign/set-speciment` ,
            data : body ,
            headers : {
              token : tokenAccess ,
             "token-peruri-sign" : tokenPeruri
            }
          })

          setLoading(false)
  
          setIsModalSuccess(true)

        }catch(e){

          const {data : {error}} = e.response

          setLoading(false)
  
          message.error(error)

        }

        return

      }
    }
    else if (signatureTypeRadio == 3) {
      if (!dataQRSign?.base64QR || !dataQRParaf?.base64QR) {
        message.info(t('Please generate your signature and paraf'))
        return;
      }
      const base64Speciment = dataQRSign.base64QR.replace('data:image/png;base64,' , '');
      const base64Paraf = dataQRParaf.base64QR.replace('data:image/png;base64,' , '');

      const body = {
        extSpeciment : "png" ,
        extParaf : "png" ,
        base64Paraf,
        base64Speciment,
        initials : values.initials
      }
      try {
        setLoading(true);
        const {data} = await axios({
          method : 'POST' ,
          url : `${rootApi}/sign/set-speciment` ,
          data : body ,
          headers : {
            token : tokenAccess ,
           "token-peruri-sign" : tokenPeruri
          }
        })
        setLoading(false);
        setIsModalSuccess(true);
      } catch (e) {
        const { data: {error} } = e.response;
        setLoading(false);
        message.error(error);
      }
    }
  };

  // handle upload signature to BE
  async function handleSubmit(values) {
    try {
      form.resetFields();
    } catch (error) {
      message.error(error.message);
    }
  }

  const handleOk = () => {
    setIsModalConfirm(false);
    handleSubmit(payload);
  };

  const handleCancel = () => {
    setIsModalConfirm(false);
  };


  const handleDone = () => {
    setIsModalSuccess(false);
    history.replace('/sign-management')
  };


  function handleChangeRadio(e) {
    setSignatureTypeRadio(e.target.value);
  }


  // handle change for file signature
  // cek size for file 
  const propsUpload = {
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {

      const isPng = file.type === "image/png"
      const isLt5 = file.size / 1024 / 1024 < 1

      if(isPng)
      {
        if(!isLt5)
        {
          message.error(t('File must smaller than 1MB!'))
        }
        else
        {
          setFileList([file])
          setType( prev => ({ ...prev , spesiment : file.type.replace('image/','')}))
        }
       
      }else
      {
        message.error(t("You can't upload this extension!"))
      }
      return false
    },
    fileList,
  };
  

  // handle change for file signature
  // cek size for file 
  const propsUpload2 = {
    onRemove: () => {
      setFileList2([]);
    },
    beforeUpload: (file) => {

      const isPng = file.type === "image/png"
      const isLt5 = file.size / 1024 / 1024 < 1

      if(isPng)
      {
        if(!isLt5)
        {
          message.error(t('File must smaller than 1MB!'))
        }
        else
        {
          setFileList2([file])
          setType( prev => ({ ...prev , paraf : file.type.replace('image/','')}))
        }
       
      }else
      {
        message.error(t("You can't upload this extension!"))
      }
      return false
    },
    fileList2,
  };


  // component for upload signature file
  function componentUpload() {
    return (
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12}>
          <Form.Item
            label={`${t("Upload Signature")}*`}
            name="file"
            rules={[
              {
                required: false,
                message: t("Please attach file!"),
              },
            ]}
          >
            <Upload
              className="w-full"
              maxCount={1}
              {...propsUpload}
              fileList={fileList}
            >
              <Button icon={<UploadOutlined />}>{t("Click to Upload")}</Button>
            </Upload>
            <small>Format : png | {t("Max")} : 1 Mb</small>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={`${t("Upload Paraf")}*`}
            name="file2"
            rules={[
              {
                required: false,
                message: t("Please attach file!"),
              },
            ]}
          >
            <Upload
              className="w-full"
              maxCount={1}
              {...propsUpload2}
              fileList={fileList2}
            >
              <Button icon={<UploadOutlined />}>{t("Click to Upload")}</Button>
            </Upload>
            <small>Format : png | {t("Max")} : 1 Mb</small>
          </Form.Item>
        </Col>
      </Row>
    );
  }

  const generateBase64QR = (e, text, generateQR, isParaf) => {
    const custom_logo = userProfile?.storage_credential?.custom_logo === "yes" ? 1 : 0
    try {
      e.preventDefault();
      generateQR({
        text, 
        qr_type: isParaf ? 'intials' : 'signature',
        custom_logo
      });
    } catch (error) {
      message.error(error.message);
    }
  }
  
  const getQRContent = (isParaf) => {
    return `
      ${!isParaf ? 'Signed' : 'Initiated'} By ${userProfile?.full_name}
      ${userProfile?.office_name}
      ${userProfile?.dept_name}
    `
  }


  function componentQR() {
    return (
      <Row gutter={[24, 24]} justify="space-between">
        <Col xs={24} md={12} >
          <Form.Item
            label={t("Generate Your Signature Here")}
          >
            <div className="flex flex-col items-center border rounded-lg p-4 gap-4 border-dashed">
              <div className="p-0 m-0">
                <img
                  style={{ opacity: isLoadignQRSign ? 0.3 : 1 }}
                  width={300}
                  src={dataQRSign?.base64QR ? dataQRSign?.base64QR : TemplateQR }
                />
              </div>
              <Button type="primary" size="small" className="w-full"
                style={{background: '#054BA6'}}
                onClick={(e) => generateBase64QR(e, getQRContent(false), generateQRSign, false)}
              >
                Generate
              </Button>
            </div>
          </Form.Item>
        
        </Col>
        <Col xs={24} md={12} >
          <Form.Item
            label={t("Generate Your Paraf Here")}
          >
            <div className="flex flex-col items-center border rounded-lg p-4 gap-4 border-dashed">
              <div className="p-0 m-0">
                <img
                  style={{ opacity: isLoadingQRParaf ? 0.3 : 1 }}
                  width={300}
                  src={dataQRParaf?.base64QR ? dataQRParaf?.base64QR : TemplateQR }
                />
              </div>
              <Button type="primary" size="small" className="w-full"
                style={{background: '#054BA6'}}
                onClick={(e) => generateBase64QR(e, getQRContent(true), generateQRParaf, true)}
              >
                Generate
              </Button>
            </div>
          </Form.Item>
        
        </Col>
      </Row>
    )
  }

  // component for draw file
  function componentDraw() {
    return (
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12}>
          <Form.Item
            label={t("Draw Your Signature Here")}
            name="file"
            rules={[
              {
                required: false,
                message: t("Please attach file!"),
              },
            ]}
          >
            <div className="relative" style={{height: 300}}>
              <SignaturePad                
                onBegin={() => {
                  if(!drawSign)
                  {
                    setDrawSign(true)
                  }
                }}
                throttle={1}
                canvasProps={{
                  className: "w-full h-full ring-1 ring-gray-300 rounded-md",
                }}
                ref={(ref) => {
                  sigPadRef = ref;
                }}
                maxWidth={2}
              />
              <Button
                className="absolute top-1 right-1 rounded-md"
                onClick={ () => {
                  sigPadRef.clear()
                  setDrawSign(false)
                }}
                danger
              >
                {t("Clear")}
              </Button>
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={t("Draw Your Paraf Here")}
            name="file"
            rules={[
              {
                required: false,
                message: t("Please attach file!"),
              },
            ]}
          >
            <div className="relative" style={{height: 300}}>
              <SignaturePad
                onBegin={() => {
                  if(!drawParaf)
                  {
                    setDrawParaf(true)
                  }
                }}
                canvasProps={{
                  className: "w-full h-full ring-1 ring-gray-300 rounded-md",
                }}
                ref={(ref) => {
                  initPadRef = ref;
                }}
                maxWidth={2}
              />
              <Button
                className="absolute top-1 right-1 rounded-md"
                onClick={ () => {
                  initPadRef.clear()
                  setDrawParaf(false)
                }}
                danger
              >
                {t("Clear")}
              </Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    );
  }


  // get component type
  function signatureTypeComponent(type) {
    switch (type) {
      case 1:
        return componentUpload();

      case 2:
        return componentDraw();

      case 3:
        return componentQR();
      default:
        return componentUpload();
    }
  }


  return (

    !loadingProfile ?

    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Form
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            form={form}
          >
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.push("/sign-management")}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      {t("Create Signature")}
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Item>
                    <Button 
                        className="w-72 h-11 font-medium rounded-lg tracking-tight"
                        type="primary"
                        style={{background: '#054BA6'}}
                        htmlType="submit"
                    >
                        {t("Create Signature")}
                    </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    {t("Make your signature")}
                  </h3>
                </Col>
                <Col span={24}>
                    <Row gutter={[24, 12]}>
                      <Col xs={24} md={12}>
                        <Form.Item
                          label={t("Full Name")}
                          name="full_name"
                          initialValue={userProfile?.full_name}
                        >
                          <Input
                            name="full_name"
                            placeholder={t("Enter full name")}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          label={t("Paraf")}
                          name="initials"
                          rules={[
                            {
                              required: signatureTypeRadio === 3 ? false : true,
                              message: t("Please input paraf name!"),
                            },
                          ]}
                        >
                          <Input
                            name="initials"
                            placeholder={t("Enter initials")}
                            disabled={signatureTypeRadio === 3}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Divider />
                        <p className="text-gray-500">
                          {t("You can upload, draw and select your digital signature")}
                        </p>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label={t("Signature Type")}
                          name="signature_type_id"
                          initialValue={signatureTypeRadio}
                          rules={[
                            {
                              required: true,
                              message: t("Please select signature type!"),
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={handleChangeRadio}
                            defaultValue={signatureTypeRadio}
                            value={signatureTypeRadio}
                          >
                            <Radio value={1}>{t("Upload")}</Radio>
                            <Radio value={2}>{t("Draw")}</Radio>
                            <Radio value={3}>{t("QR Code")}</Radio>
                            {/* <Radio value={3}>Select Style</Radio> */}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        {signatureTypeComponent(signatureTypeRadio)}
                      </Col>
                    </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          </Form>
        </Col>
      </Row>
      <Modal
        className="border-yellow-200 rounded-md"
        style={{ borderTopWidth: 14 }}
        visible={isModalConfirm}
        onCancel={handleCancel}
        footer={false}
      >
        <div className="flex flex-col space-y-4 px-8 -mt-20">
          <div className="flex flex-col space-y-2 justify-center items-center">
            <AlertCircleIcon className="w-20" />
            <p className="text-gray-800 font-semibold text-xl md:text-2xl">
              {t("Are you sure?")}
            </p>
            <Alert
              className="text-center"
              message="Be careful in changing office data! If you make any changes to office type, it may change / lost your access to the system."
              type="warning"
            />
          </div>
          <div className="flex flex-col space-y-2">
            <div className="flex flex-row justify-between">
              <p className="text-gray-500">{t("Work Unit Name")}</p>
              <div className="w-2" />
              <p className="text-gray-800 truncate">{payload?.office_name}</p>
            </div>
            <Divider />
          </div>
          <div className="flex flex-row space-x-4">
            <Button
              onClick={handleCancel}
              className="w-full rounded-md"
              ghost
              type="primary"
            >
              {t("Back")}
            </Button>
            <Button
              onClick={handleOk}
              className="w-full rounded-md"
              type="primary"
            >
              {t("Add")}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title={t("Upload Success!")}
        subtitle={t("Successfully created a new signature! You can now continue browsing.")}
        className="border-blue-500 rounded-md"
        onCancel={() => setIsModalSuccess(false) }
        style={{ borderTopWidth: 14 }}
        visible={isModalSuccess}
        footer={false}
      >
          <div className="flex flex-row justify-center space-x-4">
            <Button
              onClick={handleDone}
              className="w-8/12 h-11 tracking-tight rounded-md"
              type="primary"
            >
              {t("Done")}
            </Button>
          </div>
      </Modal>
      <Modal
          type="warning"
          visible={openDelete}
          onCancel={() => {
            setOpenDelete(false)
          }}
          title={t("Are you sure?")}
          subtitle={t("Your signature will be delete from your account !")}>
      
        <div className="flex gap-6 py-10">
          <Button
            onClick={() => setOpenDelete(false)}
            type="primary"
            className=" w-full h-10 font-medium tracking-tight rounded-md"            
          >
            {t("Cancel")}
          </Button>

          <Button
            
            type="danger"
            className=" w-full h-10 font-medium tracking-tight rounded-md"
          >
          {t("OK")}
          </Button>
        </div>
            
      </Modal>
      <Spinner loading={loading} />
    </> 

    : 

    <Spinner loading={loadingProfile} />
  
  );
}