import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";
import { rootApi, access } from "../config";
import { objectToQueryString } from "../../../utils/queryString";

export const monitorApi = createApi({
  reducerPath: "api/monitor",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi,
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      fetchListMeteraiHistory: builder.query({
        query({
          page = 1,
          row = 10,
          sort_by = "office_name",
          order = "asc",
          search = "",
          status = "",
          start_date = "",
          end_date = "",
        }) {
          return `/platform/monitor?page=${page}&row=${row}&sort_by=${sort_by}&order=${order}&search=${search}&status=${status}&start_date=${start_date}&end_date=${end_date}`;
        },
        transformResponse: (response) => (response ? response : []),
      }),
      fetchListMeteraiDefect: builder.query({
        query({
          page = 1,
          row = 10,
          sort_by = "office_name",
          order = "asc",
          search = "",
          start_date = "",
          end_date = "",
        }) {
          return `/platform/defect?page=${page}&row=${row}&sort_by=${sort_by}&order=${order}&search=${search}&start_date=${start_date}&end_date=${end_date}`;
        },
        transformResponse: (response) => (response ? response : []),
      }),
      fetchListMeteraiDefectDetail: builder.query({
        query({
          office_id, 
          page = 1,
          row = 10,
          sort_by = "office_name",
          order = "asc",
          search = "",
          start_date = "",
          end_date = "",
          user_name="",
          read_doc="",
          download_doc="",
          upload_doc="",
          send_doc="",

        }) {
          return `/platform/defect/${office_id}?page=${page}&row=${row}&sort_by=${sort_by}&order=${order}&search=${search}&start_date=${start_date}&end_date=${end_date}&user_name=${user_name}&read_doc=${read_doc}&download_doc=${download_doc}&upload_doc=${upload_doc}&send_doc=${send_doc}`;
        },
        transformResponse: (response) => (response ? response : []),
      }),

      manuallyGenerateStamp: builder.mutation({
        query: (monitor_id) => ({
          method: "PUT",
          url: `/platform/manual-generate/${monitor_id}`,
        }),
      }),

      fetchListMonitorReconOffice: builder.query({
        query({          
          page = 1,
          row = 10,          
          search = "",          

        }) {
          return `/monitor/rekon/office?page=${page}&row=${row}&search=${search}`;
        },
        transformResponse: (response) => (response.data ? response.data : []),
      }),

      fetchListDetailHistorySettlement: builder.query({
        query({          
          page = 1,
          row = 10,          
          search = "",  
          office_id="",
          start_date="",
          end_date="",        
          recon_id=""
        }) {
          return `/monitor/rekon/list-comparation?page=${page}&row=${row}&search=${search}&office_id=${office_id}&start_date=${start_date}&end_date=${end_date}&recon_id=${recon_id}`;
        },
        transformResponse: (response) => (response.data ? response.data : []),
      }),
      fetchMonitorDetail: builder.query({
        query({
          office_id = '',
          bill_id = '',
          bill_parent_id = '',
          monitor_id = ''
        }) {
          return `/platform/monitor/detail?${objectToQueryString({office_id, bill_id, bill_parent_id, monitor_id})}`
        },
        transformResponse: (response) => (response.data ? response.data : {})
      })
    };
  },
});

export const {
  useFetchListMeteraiHistoryQuery,
  useFetchListMeteraiDefectQuery,
  useFetchListMeteraiDefectDetailQuery,
  useManuallyGenerateStampMutation,
  useFetchListMonitorReconOfficeQuery,
  useFetchListDetailHistorySettlementQuery,
  useFetchMonitorDetailQuery
} = monitorApi;