import { FilterOutlined, RightOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Divider,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Form
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as AlertIcon } from "../../assets/icons/alert-icon.svg";
import { ReactComponent as CheckCircleIcon } from "../../assets/icons/check-circle-outline.svg";
import { ReactComponent as FilterOutline } from "../../assets/icons/filter-outline.svg";
import { ReactComponent as QrCodeIcon } from "../../assets/icons/qrcode.svg";
import { ReactComponent as SelfSign } from "../../assets/icons/self-sign.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-circle-outline.svg";
import { Button, Modal as ModalComponent } from "../../components";
import { useFetchQuotaQuery } from "../../config/features";
import { useFetchListDocumentQuery } from "../../config/features/document";
import { useFetchListOfficeQuery } from "../../config/features/office";
import { useFetchListJenisDocumentQuery } from "../../config/features/peruri";
import { STAMP_DOC_STATUS } from "../../constant";
import { errorMessage, numberCommas } from "../../utils";
import { useTranslation } from "react-i18next";

const { Search } = Input;
const { Option } = Select;


export default function StampManagement() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const history = useHistory();
  const [isModalAlert, setIsModalAlert] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [isModalDocument, setIsModalDocument] = useState(false);
  const [isModalSignature, setIsModalSignature] = useState(false);
  const [isModalDocType, setIsModalDocType] = useState(false);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [visibleFilterModal, setVisibleFilterModal] = useState(false);
  const [payload, setPayload] = useState("")
  const [docType, setDocType] = useState("")

  const [paramsTable, setParamsTable] = useState({
    row: 10,
    page: 1,
    office: "",
    sort_by: "created_at",
    order: "desc",
    stamp_status:"",
    stamped: "",
    stamp_status: "",
    is_signed: 2,
    tera_stamped: 2,
    is_draft: ""
  });

  const [paramsTableStamped, setParamsTableStamped] = useState({
    row: 10,
    page: 1,
    office: "",
    stamp_status: "",
    sort_by: "created_at",
    order: "desc",
    stamped: 1,
  });

  const {
    data: dataListDocument = [],
    isFetching,
    refetch,
  } = useFetchListDocumentQuery(paramsTable);

  const {
    data: dataListDocumentStamped = [],
    isFetching: isFetchingStamped,
    isError: isErrorStamped,
    error: errorStamped,
    refetch: refetchStamped,
  } = useFetchListDocumentQuery(paramsTableStamped);

  const { data: dataListOffice = [] } = useFetchListOfficeQuery({ row: 100 });

  const { data: dataQuota = [], refetch: refetchQuota } = useFetchQuotaQuery();

  const { data: dataListDocType, refetch: refetchDocType } = useFetchListJenisDocumentQuery()

  const getMeteraiQuota = () => {
    for (let i = 0; i < dataQuota.length; i++) {
      if (dataQuota[i].name === "e-Meterai per-Seal") {
        return dataQuota[i].remaining;
      }
    }
  };

  useEffect(() => {
    if (isErrorStamped) {
      errorMessage(errorStamped);
    }
  }, [isErrorStamped, errorStamped]);

  useEffect(() => {
    refetch();
    refetchQuota();
    refetchStamped();
  }, [refetch, refetchQuota, refetchStamped]);

  const menuFilter = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="w-full h-0.5 bg-gray-100 rounded-full" />
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">{t("filter")}</p>
            <p
              className="text-gray-300 hover:text-gray-500"
              onClick={handleResetFilterStamped}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Work Unit")}</p>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(e) => setParamsTableStamped({ ...paramsTableStamped, office: e })}
              value={paramsTableStamped.office}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">{t("All Work Unit")}</Option>
              {dataListOffice.map((item, index) => {
                return (
                  <Option key={index} value={item.office_id}>
                    {item.office_name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("status")}</p>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(e) => setParamsTableStamped({ ...paramsTableStamped, stamp_status: e })}
              value={paramsTableStamped.stamp_status}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">{t("allstatus")}</Option>
              <Option value="1">{t("pending")}</Option>
              <Option value="2">{t("completed")}</Option>
              <Option value="3">{t("incomplete")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">{t("sort")}</p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("sortby")}</p>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              placeholder="No Selected"
              onChange={(e) => setParamsTableStamped({ ...paramsTableStamped, sort_by: e })}
              value={paramsTableStamped.sort_by}
            >
              <Option value="" disabled className="hidden"></Option>
              <Option value="created_at">{t("upltime")}</Option>
              <Option value="doc_name">{t("titledocname")}</Option>
              <Option value="stamp_status">{t("Status")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("sorttype")}</p>
            <Radio.Group
              defaultValue="desc"
              buttonStyle="solid"
              onChange={(e) =>
                setParamsTableStamped({ ...paramsTableStamped, order: e.target.value })
              }
              value={paramsTableStamped.order}
            >
              <Radio value="asc">{t("ascending")}</Radio>
              <Radio value="desc">{t("descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const columnsCompleted = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Document Name"),
      dataIndex: "doc_name",
      key: "doc_name",
    },
    {
      title: t("Type"),
      dataIndex: "is_folder",
      key: "is_folder",
      render: (is_folder) => {
        return is_folder ? t("Bulk") : t("Single");
      },
    },
    {
      title: t("Actor"),
      dataIndex: "stamp_actor",
      key: "stamp_actor",
    },
    {
      title: t("Status"),
      dataIndex: "stamp_status",
      key: "stamp_status",
      render: status => {
        let StatusTag;
        switch (status) {
          case STAMP_DOC_STATUS.PENDING:
            StatusTag = <Tag color="blue">{t("On Progress")}</Tag>
            break
          case STAMP_DOC_STATUS.COMPLETE:
            StatusTag = <Tag color="green">{t("Completed")}</Tag>
            break
          case STAMP_DOC_STATUS.INCOMPLETE:
            StatusTag = <Tag color="red">{t("Incomplete")}</Tag>
            break
          // case STAMP_DOC_STATUS.ABORTED:
          //   StatusTag = <Tag color="gray">Aborted</Tag>
          //   break
          default:
            StatusTag = <Tag color="gray">{t("Unstamped")}</Tag>
        }
        return StatusTag;
      },
    },
    {
      title: t("Last Change"),
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return moment(created_at, moment.ISO_8601).format("DD/MM/YYYY");
      },
    },
    {
      render: (record) => {
        return (
          <div
            className="group cursor-pointer"
            onClick={() =>
              history.push(
                record.is_folder
                  ? `/stamp-management/detail-bulk/${record.doc_id}`
                  : `/stamp-management/detail/${record.doc_id}`
              )
            }
          >
            <RightOutlined className="group-hover:text-blue-500" />
          </div>
        );
      },
    },
  ];

  function handleCancel() {
    setIsModalDelete(false);
  }

  async function handleDelete() {
    try {
      setIsModalDelete(false);
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleDone() {
    setIsModalSuccess(false);
  }

  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }

  function handleVisibleFilterModal(flag) {
    setVisibleFilterModal(flag);
  }

  function handleModalDocument() {
    setIsModalDocument(!isModalDocument);
  }

  function handleModalSignature() {
    setIsModalSignature(!isModalSignature);
  }

  function handleModalAlert() {
    setIsModalAlert(!isModalAlert);
  }

  function handleModalDocType() {
    setIsModalDocType(!isModalDocType);
  }

  const modalSignatureContent = [
    {
      title: t("Self Sign"),
      subtitle: t("Sign a document"),
      icon: <SelfSign />,
      handleClick: () => history.push("/sign-management/self-sign"),
    },
    {
      title: t("Sign With Other"),
      subtitle: t("Sign your document and invite others to sign"),
      icon: <SelfSign />,
      handleClick: () => history.push("/sign-management/sign-with-other"),
    },
    {
      title: t("Request Sign To Other"),
      subtitle: t("Share your document with the recipient to sign"),
      icon: <SelfSign />,
      handleClick: () => history.push("/sign-management/request-sign"),
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Document Name"),
      dataIndex: "doc_name",
      key: "doc_name",
    },
    {
      title: t("Type"),
      dataIndex: "is_folder",
      key: "is_folder",
      render: (is_folder) => {
        return is_folder ? t("Bulk") : t("Single");
      },
    },
    // {
    //   title: "Stamp",
    //   dataIndex: "is_stamped",
    //   key: "is_stamped",
    //   render: (is_stamped) => {
    //     return is_stamped ? <Tag color="success">Stamped</Tag> : <Tag color="warning">Not Stamped</Tag>;
    //   },
    // },
    // {
    //   title: "Stamp",
    //   dataIndex: "stamp_status",
    //   key: "stamp_status",
    //   render: (stamp_status) => stamp_status == 2 ? <Tag color="success">Stamped</Tag> : <Tag color="warning">Not Stamped</Tag>
    // },
    {
      title: t('Stamp Status'),
      render: (record) => {
        if (!record.is_stamped && !record.is_signed && !record.is_tera) {
          return <Tag color="#A0A19F" className="rounded w-20 text-center">{t("Draft")}</Tag>
        }
        return (
          <span className="flex flex-row gap-2">
            {record.is_stamped && (
              <Tag color="#1890FF" className="rounded w-20 text-center">{t("E-Meterai")}</Tag>
            )}
            {record.is_signed && (
              <Tag color="#1890FF" className="rounded w-20 text-center">{t("E-Sign")}</Tag>
            )}
            {record.is_tera && (
              <Tag color="#1890FF" className="rounded w-20 text-center">{t("Tera")}</Tag>
            )}
          </span>
        )
      }
    },
    {
      title: t("Tag Document"),
      dataIndex: "tags",
      key: "tags",
      render: (tags) => {
        return (
          <p className="w-20 truncate">
            {tags?.map((item, index) => {
              return (
                item !== null && (
                  <span key={index}>
                    {item}
                    {tags?.length > 1 && tags?.length - 1 !== index ? ", " : ""}
                  </span>
                )
              );
            })}
          </p>
        );
      },
    },
    {
      title: t("Work Unit"),
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      render: (record) => {
        if (record.stamp_status == 1 || record.sign_in_progress || record.tera_in_progress) {
          return null
        }
        return (
          <div
            className="group cursor-pointer"
            onClick={() => {
              setIsModalDocument(false)
              setIsModalDocType(true)
              setPayload(record)
              // history.push({
              //   pathname: record.is_folder
              //     ? `/stamp-management/bulk-stamp/${record.doc_id}`
              //     : `/stamp-management/single-stamp/${record.doc_id}`,
              //   state: record,
              // })
            }

            }
          >
            <RightOutlined className="group-hover:text-blue-500" />
          </div>
        );
      },
    },
  ];

  const onSearch = (value) => setParamsTable({ ...paramsTable, search: value });

  const onSearchStamped = (value) => setParamsTableStamped({ ...paramsTableStamped, search: value });

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  function handleChangeTableStamped(pagination) {
    setParamsTableStamped({
      ...paramsTableStamped,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  function handleResetFilter() {
    setParamsTable({
      ...paramsTable,
      page: 1,
      office: "",
      sort_by: "created_at",
      order: "desc",
      stamp_status: "",
      stamped: "",
      is_signed: 2,
      tera_stamped: 2,
      is_draft: ""
    });
  }

  function handleResetFilterStamped() {
    setParamsTableStamped({
      row: 10,
      page: 1,
      office: "",
      stamp_status: "",
      sort_by: "created_at",
      order: "desc",
      stamped: 1,
    });
  }

  const menuFilterModal = () => {
    return (
      <Menu className="absolute right-0 top-0 rounded-tl-2xl rounded-b-2xl">
        <Menu.Item>
          <div className="flex flex-row justify-between items-center gap-36">
            <p className="text-gray-400 text-base font-medium">{t("Filter")}</p>
            <p
              className="text-gray-300 font-medium text-xs w-max"
              onClick={handleResetFilter}
            >
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Work Unit")}</p>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, office: e })}
              value={paramsTable.office}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">{t("All Work Unit")}</Option>
              {dataListOffice.map((item, index) => {
                return (
                  <Option key={index} value={item.office_id} selected={paramsTable.office == item.office_id}>
                    {item.office_name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </Menu.Item>  
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Stamp Status")}</p>
            <Select
              defaultValue=""
              onChange={(e) => {
                // if (e == 'draft') {
                //   setParamsTable({...paramsTable, stamped: '', stamp_status: '', is_signed: '', tera_stamped: '', is_draft: 1})
                // }
                // else if (e == 'e-meterai') {
                //   setParamsTable({...paramsTable, stamped: 1, stamp_status: 2, is_signed: '', tera_stamped: '', is_draft: ''})
                // }
                // else if (e == 'e-sign') {
                //   setParamsTable({...paramsTable, stamped: '', stamp_status: '', is_signed: 1, tera_stamped: '', is_draft: ''})
                // }
                // else if (e == 'tera') {
                //   setParamsTable({...paramsTable, stamped: '', stamp_status: '', is_signed: '', tera_stamped: 1, is_draft: ''})
                // }
                // else {
                //   setParamsTable({...paramsTable, stamped: '', stamp_status: '', is_signed: '', tera_stamped: '', is_draft: ''})
                // }
                if (e == 'draft') {
                  setParamsTable({...paramsTable, stamped: '', is_draft: 1})
                }
                else if (e == 'e-meterai') {
                  setParamsTable({...paramsTable, stamped: 1, is_draft: ''})
                }
                else {
                  setParamsTable({...paramsTable, stamped: '', is_draft: ''})
                }
              }}
            >
              <Option value="">{t("All Status")}</Option>
              <Option value={'draft'}>{t("Draft")}</Option>
              <Option value={'e-meterai'}>{t("E-Meterai")}</Option>
              {/* <Option value={'e-sign'}>{t("E-Sign")}</Option>
              <Option value={'tera'}>{t("Tera")}</Option> */}
            </Select>
          </div>
        </Menu.Item>      
        <Menu.Item>
          <div className="w-full h-0.5 bg-gray-100 rounded-full" />
        </Menu.Item>
        <Menu.Item>
          <p className="text-gray-500 text-base font-medium">{t("sort")}</p>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("sortby")}</p>
            <Select
              defaultValue="created_at"
              style={{ width: "100%" }}
              onChange={(e) => setParamsTable({ ...paramsTable, sort_by: e })}
              value={paramsTable.sort_by}
            >
              <Option value="created_at">{t("upltime")}</Option>
              <Option value="doc_name">{t("titledocname")}</Option>
            </Select>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("sorttype")}</p>
            <Radio.Group
              defaultValue="desc"
              buttonStyle="solid"
              onChange={(e) =>
                setParamsTable({ ...paramsTable, order: e.target.value })
              }
              value={paramsTable.order}
            >
              <Radio value="asc">{t("ascending")}</Radio>
              <Radio value="desc">{t("descending")}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
        <Menu.Divider />
      </Menu>
    )
  }

  function onFinish(values) {

    let data = payload
    data.docType = values.docType
    history.push({
      pathname: payload?.is_folder
        ? `/stamp-management/bulk-stamp/${payload.doc_id}`
        : `/stamp-management/single-stamp/${payload.doc_id}`,
      state: data,
    })

  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <h1 className="text-xl md:text-2xl font-medium">
                {t("stampdoc")}
              </h1>
            </Col>
            <Col>
              <Row gutter={[12, 24]}>
                <Col>
                  <Row gutter={[12, 24]} align="middle">
                    <Col>
                      <div className="flex flex-row bg-yellow-400 text-white rounded-full px-2 py-1 justify-center items-center">
                        <QrCodeIcon />
                        <Divider className="h-4 bg-white" type="vertical" />
                        <p className="font-medium">
                          {numberCommas(getMeteraiQuota())}
                        </p>
                      </div>
                    </Col>
                    {Number(getMeteraiQuota()) < 100 && (
                      <Col>
                        <Tooltip title={`e-Meterai per Seal quantity is less than 100`}>
                          <AlertIcon />
                        </Tooltip>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col>
                  <Divider className="h-full" type="vertical" />
                </Col>
                <Col>
                  {Number(getMeteraiQuota()) > 0 ? (
                    <Button
                      className="md:w-40"
                      type="primary"
                      onClick={handleModalDocument}
                    >
                      {t("stampdoc")}
                    </Button>
                  ) : (
                    <button
                      className="md:w-40 bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-1.5 rounded-md"
                      onClick={handleModalAlert}
                    >
                      {t("stampdoc")}
                    </button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      {t("doclist")}
                    </h3>
                  </Col>
                  <Col>
                    <Row gutter={[12, 12]}>
                      <Col>
                        <Search
                          placeholder={t("Search")}
                          onSearch={onSearchStamped}
                          onChange={(e) =>
                            setTimeout(() => {
                              onSearchStamped(e.target.value);
                            }, 1000)
                          }
                          allowClear
                          style={{
                            width: 200,
                            borderRadius: "10px !important",
                          }}
                        />
                      </Col>
                      <Col>
                        <Dropdown
                          overlay={menuFilter}
                          onVisibleChange={handleVisibleFilter}
                          visible={visibleFilter}
                          trigger={["click"]}
                        >
                          <Button
                            type="primary"
                            ghost
                            icon={<FilterOutlined />}
                          />
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsCompleted}
                  dataSource={datasetsListTable(dataListDocumentStamped)}
                  scroll={{ x: 800 }}
                  pagination={{
                    current: paramsTableStamped.page,
                    pageSize: paramsTableStamped.row,
                    total: dataListDocumentStamped[0] ? dataListDocumentStamped[0]?.total : 0,
                    locale: {
                      items_per_page: t("/ page"),
                    }
                  }}
                  onChange={handleChangeTableStamped}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        history.push(
                          record.is_folder
                            ? `/stamp-management/detail-bulk/${record.doc_id}`
                            : `/stamp-management/detail/${record.doc_id}`
                        );
                      }, // click row
                    };
                  }}
                  loading={isFetchingStamped}
                />
              </Col>
            </Row>
            {/* </TabPane> */}
            {/* </Tabs> */}
          </Card>
        </Col>
      </Row>
      <ModalComponent
        title={t("Your Quota Not Enough")}
        subtitle={t("Your e-materai quota is 0, you can not add stamp to this document")}
        type="warning"
        visible={isModalAlert}
        onCancel={handleModalAlert}
      >
        <div className="flex justify-center">
          <Button
            className="w-40"
            type="primary"
            ghost
            onClick={handleModalAlert}
          >
            {t("Back")}
          </Button>
        </div>
      </ModalComponent>
      <Modal
        className="border-red-400 rounded-md"
        style={{ borderTopWidth: 14 }}
        visible={isModalDelete}
        footer={false}
        onCancel={handleCancel}
      >
        <div className="flex flex-col space-y-4 px-8 -mt-20">
          <div className="flex flex-col space-y-2 justify-center items-center">
            <TrashIcon className="w-20 h-20 bg-red-500 rounded-full" />
            <p className="text-gray-800 font-semibold text-xl md:text-2xl">
              {t("Delete Office")}?
            </p>
            <p className="text-gray-500">
              {t("Are you sure want to delete this office?")}
            </p>
          </div>
          <div className="flex flex-row space-x-4">
            <Button
              onClick={handleCancel}
              className="w-full"
              ghost
              danger
              type="secondary"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={handleDelete}
              className="w-full"
              danger
              type="primary"
            >
              {t("Delete")}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        className="border-blue-500 rounded-md"
        style={{ borderTopWidth: 14 }}
        visible={isModalSuccess}
        footer={false}
      >
        <div className="flex flex-col space-y-4 px-8 -mt-20">
          <div className="flex flex-col space-y-2 justify-center items-center">
            <CheckCircleIcon className="w-20" />
            <p className="text-gray-800 font-semibold text-xl md:text-2xl">
              {t("Updated Success!")}
            </p>
            <p className="text-gray-500">
              {t("Changes have been saved! Now you can continue browsing.")}
            </p>
          </div>
          <div className="flex justify-center">
            <Button onClick={handleDone} className="w-32" type="primary">
              {t("Done")}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isModalDocument}
        onCancel={handleModalDocument}
        footer={false}
        width={1200}
      >
        <Row gutter={[24, 24]} className="pr-4">
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  {t("chosedoc")}
                </h3>
              </Col>
              <Col>
                <Row gutter={[24, 24]}>
                  <Col>
                    <Search
                      placeholder={t("search")}
                      allowClear
                      onSearch={onSearch}
                      onChange={(e) =>
                        setTimeout(() => {
                          onSearch(e.target.value);
                        }, 1000)
                      }
                      style={{
                        width: 200,
                        borderRadius: "10px !important",
                      }}
                    />
                  </Col>
                  <Col>
                    <Dropdown
                      overlay={menuFilterModal}
                      onVisibleChange={handleVisibleFilterModal}
                      visible={visibleFilterModal}
                      trigger={["click"]}
                    >
                      <Button
                        className="rounded-full"
                        type="primary"
                        icon={<FilterOutline />}
                      />
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={datasetsListTable(dataListDocument)}
              scroll={{ x: 500 }}
              loading={isFetching}
              pagination={{
                current: paramsTable.page,
                pageSize: paramsTable.row,
                total: dataListDocument[0] ? dataListDocument[0]?.total : 0,
                locale: {
                  items_per_page: t("/ page"),
                }
              }}
              onChange={handleChangeTable}
            />
          </Col>
        </Row>
      </Modal>
      <Modal
        onCancel={handleModalSignature}
        visible={isModalSignature}
        width={500}
        footer={false}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <p className="text-lg md:text-xl text-gray-500 font-medium">
              {t("Signature as")}
            </p>
          </Col>
          {modalSignatureContent.map((item, index) => {
            return (
              <Col span={24} key={index}>
                <div
                  className="rounded-t-md ring-1 ring-gray-300 px-4 py-2 flex flex-row justify-between items-center cursor-pointer group hover:bg-blue-100 transition duration-500"
                  onClick={item.handleClick}
                >
                  <div className="flex flex-row space-x-4 items-center">
                    <div>{item.icon}</div>
                    <div className="flex flex-col">
                      <div span={24}>
                        <p className="text-sm md:text-base text-blue-700 font-medium">
                          {item.title}
                        </p>
                      </div>
                      <div span={24}>
                        <p className="text-xs text-gray-400">{item.subtitle}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <RightOutlined className="group-hover:text-blue-700 transition duration-500" />
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Modal>
      <ModalComponent
        visible={isModalDocType}
        type="warning"
        title={t("doctype")}
        subtitle={t("selectdoctype")}
        onCancel={handleModalDocType}
        width={500}
      >
        <Form onFinish={onFinish}>
          <Row align="midle">
            <Col md={24}>
              <Form.Item
                className=""
                name="docType"
                rules={[{ required: true, message: t('Please select document type') }]}
              >
                <Select
                  placeholder={t("noteselected2")}
                  allowClear
                  className="mt-4"
                >

                  {
                    dataListDocType?.length > 0 && dataListDocType?.map((data, index) => {
                      return (
                        <Option key={index} value={data.nama}>
                          {data.nama}
                        </Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
              <div className="flex justify-center">
                <Button onClick={handleModalDocType} ghost type="primary" className="mt-9 mb-9 h-10 rounded-md w-full tracking-tight font-medium mr-4">{t("btnno")}</Button>
                <Button htmlType="submit" type="primary" className="mt-9 mb-9 h-10 rounded-md w-full tracking-tight font-medium">{t("btnyes")}</Button>
              </div>
            </Col>
          </Row>
        </Form>
        {/* {<div>
          
          <select
            value={docType}
            style={{width : 160}}
            disabled={dataListDocType?.length === 0 }
            onChange={e => setDocType(e.target.value)}
            >
              <option selected value="">Choice</option>
            {
              dataListDocType?.map((data , index) => {
                return(
                  <option key={index} value={data.nama}>
                    {data.nama}
                  </option>
                )
              })
            }
          </select>
        </div>} */}
      </ModalComponent>
    </>
  );
}
