import { Card, Col, Dropdown, Row, Skeleton, Table, Input, Menu, Select, DatePicker, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Spinner } from "../../components";
import { DownloadOutlined, FilterOutlined, LeftOutlined, SettingOutlined } from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { useFetchListDetailRecordRekonQuery } from "../../config/features";
import { objectToQueryString } from "../../utils/queryString";
import { exportExcel, getBlob, numberCommas } from "../../utils";
const { Search } = Input;
const { Option } = Select;
export default function RekonsiliasiDetail() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [paramsTable, setParamsTable] = useState({
    id: id,
    page: 1,
    row: 10,
    search: '',
    status_recon: '',
    min_price: '',
    max_price: '',
  });

  const [visibleFilter, setVisibleFilter] = useState(false);
  function handleVisibleFilter(flag) {
    setVisibleFilter(flag);
  }
  const {
    data: dataListDetailRecordRekon = [],
    isFetching,
    isError,
    error,
    refetch
  } = useFetchListDetailRecordRekonQuery(paramsTable);
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("Serial Number"),
      dataIndex: "sn",
      key: "sn",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status"
    },
    {
      title: t("Description"),
      dataIndex: "description",
      key: "description"
    },
    {
      title: "File",
      dataIndex: "file",
      key: "file"
    },
    {
      title: t("Date"),
      dataIndex: "tanggal",
      key: "tanggal",
      render: (tanggal) => (<p className="w-20 truncate">{moment(tanggal).utc(true).format("YYYY-MM-DD").toString()}</p>)
    },
    {
      title: t("Price"),
      dataIndex: "price",
      key: "price",
      render: (price) => (<p className="w-40 truncate">Rp. {numberCommas(price)}</p>)
    },
    {
      title: t("User"),
      dataIndex: "user",
      key: "user"
    },
    {
      title: t("Status Recon"),
      dataIndex: 'status_recon',
      key: 'status_recon',
    }
  ];
  const statusList = [
    "All Status",
    "Settle",
    "Dispute"
  ]
  function handleChangeTable(pagination) {
    setParamsTable({
      ...paramsTable,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }
  const onSearch = (value) => setParamsTable({ ...paramsTable, search: value, page: 1 });
  const handleClickExportExcel = async () => {
    const { id, ...selectedKeys } = paramsTable
    const queryString = objectToQueryString({ export_format: 'excel', ...selectedKeys });
    const response = await getBlob(`/record/rekonsiliasi-detail/${id}?${queryString}`);
    exportExcel(response.data, "Rekonsiliasi Detail");
  }
  function handleResetFilter() {
    setParamsTable({
      ...paramsTable,
      status_recon: "",
      search: "",
      min_price: "",
      max_price: "",
      page: 1,
    })
  }
  const menuFilter = () => {
    return (
      <Menu>
        <Menu.Item>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500 text-base font-medium">Filter</p>
            <p className="text-gray-300 hover:text-gray-500" onClick={handleResetFilter}>
              {t("resetall")}
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Status Recon")}</p>
            <Select
              defaultValue=""
              style={{ width: 300 }}
              value={paramsTable.status_recon}
              onChange={(e) => setParamsTable({ ...paramsTable, status_recon: e })}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              allowClear
            >
              {statusList.map((value, index) => (
                <Option key={index} value={index != 0 ? value : ""}>{t(value)}</Option>
              ))}
            </Select>
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Minimum Price")}</p>
            <InputNumber
              style={{ width: 300 }}
              addonBefore={"Rp."}
              onChange={(value) => setTimeout(() => {
                setParamsTable({ ...paramsTable, min_price: value })
              }, 1000)}
            />
          </div>
        </Menu.Item>

        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <p className="text-xs">{t("Maximum Price")}</p>
            <InputNumber
              style={{ width: 300 }}
              addonBefore={"Rp."}
              onChange={(value) => setTimeout(() => {
                setParamsTable({ ...paramsTable, max_price: value })
              }, 1000)}
            />
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  // useEffect(()=>{
  //   console.log(dataListDetailRecordRekon?.dataList);
  // });

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div className="group cursor-pointer" onClick={() => history.goBack()}>
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    {t("Reconciliation Detail")}
                  </h1>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                className="bg-blue-700 focus:bg-blue-700 active:bg-blue-700 justify-center"
                type="primary"
                onClick={handleClickExportExcel}
                icon={<DownloadOutlined />}
                disabled={isFetching || isError}
              >
                {t("Download Reconciliation")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Skeleton loading={false} active paragraph={{ rows: 6 }}>
              <Row gutter={[24, 24, 24]}>
                <Col span={24}>
                  <Row gutter={[24, 24]} justify="space-between">
                    <Col>
                      <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                        {t("Reconciliation Detail")}
                      </h3>
                    </Col>
                    <Col>
                      <Row gutter={[12, 12]}>
                        <Col>
                          <Search
                            placeholder={t("Search")}
                            allowClear
                            onSearch={onSearch}
                            onChange={(e) =>
                              setTimeout(() => {
                                onSearch(e.target.value);
                              }, 1000)
                            }
                            style={{
                              width: 200,
                              borderRadius: "10px !important",
                            }}
                          />
                        </Col>
                        <Col>
                          <Dropdown
                            overlay={menuFilter}
                            onVisibleChange={handleVisibleFilter}
                            visible={visibleFilter}
                            trigger={["click"]}
                          >
                            <Button
                              type="primary"
                              ghost
                              icon={<FilterOutlined />}
                            />
                          </Dropdown>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={5}>
                      <span className="text-black-500 font-medium"> {t("Reconciliation Date")} </span>
                    </Col>
                    <Col span={1}>
                      <span className="text-black-500 font-medium"> : </span>
                    </Col>
                    <Col span={7}>
                      {`${moment(dataListDetailRecordRekon?.dataHeader?.created_at).utc(true).format("YYYY/MM/DD").toString()}`}
                      {/* {`${ moment(paramsTable.start_date).utc(true).format("YYYY/MM/DD").toString()} - ${moment(paramsTable.end_date).utc(true).format("YYYY/MM/DD").toString()}`} */}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={5}>
                      <span className="text-black-500 font-medium"> {t("Period")} </span>
                    </Col>
                    <Col span={1}>
                      <span className="text-black-500 font-medium"> : </span>
                    </Col>
                    <Col span={7}>
                      {`${moment(dataListDetailRecordRekon?.dataHeader?.created_at).utc(true).format("MMMM").toString()}`}
                      {/* { moment(paramsTable.start_date).utc(true).format("MMMM").toString() } */}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={5}>
                      <span className="text-black-500 font-medium"> Status</span>
                    </Col>
                    <Col span={1}>
                      <span className="text-black-500 font-medium"> : </span>
                    </Col>
                    <Col span={7}>
                      {
                        (location?.state?.a?.status === 'NOT SETTLEMENT') ? <span style={{ color: "lightsalmon" }}>{location?.state?.a?.status}</span> : (location?.state?.a?.status === 'SETTLEMENT') ? <span style={{ color: "green" }}>{location?.state?.a?.status}</span> : <span style={{ color: "blueviolet" }}>{location?.state?.a?.status}</span>
                      }
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Table
                    columns={columns}
                    dataSource={dataListDetailRecordRekon?.dataList?.map((item, index) => ({ no: index + 1, ...item }))}
                    scroll={{ x: 850 }}
                    loading={isFetching}
                    pagination={{
                      current: paramsTable.page,
                      pageSize: paramsTable.row,
                      total: dataListDetailRecordRekon?.dataLength,
                      locale: {
                        items_per_page: t("/ page"),
                      }
                    }}
                    onChange={handleChangeTable}
                  />
                </Col>
              </Row>
            </Skeleton>
          </Card>
        </Col>
      </Row>
    </>
  )
}