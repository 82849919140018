import { Card, Col, List, Row, Space, Tag } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NotificationListItem = ({ title, message, date, isRead, ...props }) => {
  const { t } = useTranslation();
  return (
    <Card 
      bodyStyle={{ width: "100%"}}
      className={clsx("p-2 hover:bg-gray-100 cursor-pointer transition", !isRead && "bg-blue-100 hover:bg-blue-100")}
      {...props}
    >
      <div className={`whitespace-pre-wrap`}>
        <header className="flex justify-between items-center mb-1">
          <h6 className="text-blue-700 font-bold flex items-center">
            {t(title)} {!isRead && <Tag className="bg-blue-700 text-white border-0 ml-2">{t("NEW")}</Tag>}
          </h6>
          <p className="text-gray-400 text-xs">
            {date}
          </p>
        </header>
        <p>{t(message)}</p>
      </div>
    </Card>
  )
}

function NotificationList({ children, className, ...props }) {
  return(
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <List className={clsx("w-full shadow-lg", className)} { ...props }>
          {children}
        </List>
      </Col>
    </Row>
  );
}

NotificationList.Item = NotificationListItem;

export default NotificationList