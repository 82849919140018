import { Alert, Col, Divider, Form, Input, message, Row } from "antd";
import { useEffect, useState } from "react";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import { useEditUserProfileMutation } from "../../config/features/user";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { errorMessage } from "../../utils";
import { useHistory } from "react-router-dom";
import { REGEXES } from "../../constant";
import { useTranslation } from "react-i18next";

export default function ChangePassword() {
  const [form] = Form.useForm();
  const history = useHistory();
  const { t } = useTranslation();

  const { data: userProfile = {} } = useFetchProfileUserQuery();

  const [editUser, { isSuccess, isLoading, error, isError }] =
    useEditUserProfileMutation();

  const [isModalSuccess, setIsModalSuccess] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setIsModalSuccess(true);
      setTimeout(() => {
        setIsModalSuccess(false);
        window.location.reload();
      }, 2000);
    }
    if (isError) {
      errorMessage(error);
    }
  }, [isSuccess, error, isError, history, userProfile]);

  async function handleSubmit(values) {
    try {
      editUser(values);
    } catch (error) {
      message.error(error.message);
    }
  }

  function handleDone() {
    setIsModalSuccess(false);
    window.location.reload();
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Form
          form={form}
          onFinish={handleSubmit}
          autoComplete="off"
          layout="vertical"
        >
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <h1 className="text-xl md:text-2xl font-medium">
                  {t("Change Password")}
                </h1>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    className="md:w-40"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {t("Update")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="mt-4 mb-8">
            <Alert
              type="warning"
              message={t("For account security, please change your default password")}
            />
          </Col>
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 0]}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={t("Current Password")}
                      name="old_password"
                      rules={[
                        {
                          required: true,
                          message: t("Please input old password!"),
                        },
                      ]}
                    >
                      <Input.Password placeholder={t("Enter password")} />
                    </Form.Item>
                  </Col>
                  <Col span={24} className="-mt-4">
                    <Divider />
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={t("New Password")}
                      name="new_password"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("Please input password!"),
                        },
                        () => ({
                          validator(_, value) {
                            if (
                              value.match(
                                REGEXES.password
                              )
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                               t( "Password needs to be at least 8 character, 1 uppercase, 1 special character!")
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder={t("Enter password")} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={t("Confirmation Password")}
                      name="password_confirmation"
                      dependencies={["new_password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t("Please confirm your password!"),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("new_password") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                t("The two passwords that you entered do not match!")
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder={t("Enter password confirmation")} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Form>
      </Row>
      <Modal
        title={t("Updated Success!")}
        subtitle={t("Changes have been saved! Now you can continue browsing.")}
        visible={isModalSuccess}
        closable={false}
      >
        <div className="text-center">
          <Button onClick={handleDone} className="w-32" type="primary">
            {t("Done")}
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
