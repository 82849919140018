import { Card, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useFetchListProductQuery } from "../../config/features/product";
import { numberCommas } from "../../utils";
const { Option } = Select;

export default function ClientQuotation({ form, module }) {
  const { data: dataListProduct = [] } = useFetchListProductQuery({});
  const [totalPrice, setTotalPrice] = useState({});

  const fields = form?.getFieldsValue();
  const { products } = fields;

  //console.log(dataListProduct)

  useEffect(() => {
    function handleChangeProductNameMultiple(e) {
      const selectedProduct = dataListProduct?.find((item) => item?.id === e);
      const stampProccessProduct = e === "1" ? dataListProduct?.find((item) => item?.id === "6") : {};
      const fields = form?.getFieldsValue();
      const { products } = fields;

      if (products?.length < 1) {
        const data = [{
          unit_price: selectedProduct?.price_default,
          quantity: '',
          total: selectedProduct?.price_default * 1,
          price: `Rp ${numberCommas(selectedProduct?.price_default * 1)},-`,
          name: selectedProduct?.name,
          id: selectedProduct?.id,
        }]

        if(e === "1"){
          data.push({
            unit_price: stampProccessProduct?.price_default,
            quantity: '',
            total: stampProccessProduct?.price_default * 1,
            price: `Rp ${numberCommas(stampProccessProduct?.price_default * 1)},-`,
            name: stampProccessProduct?.name,
            id: stampProccessProduct?.id,
          })
        }

        form.setFieldsValue({
          products: [
            ...data
          ],
        });
      } else {
        const data = [{
          unit_price: selectedProduct?.price_default,
          quantity: '',
          total: selectedProduct?.price_default * 1,
          price: `Rp ${numberCommas(selectedProduct?.price_default * 1)},-`,
          name: selectedProduct?.name,
          id: selectedProduct?.id,
        }]

        if(e === "1"){
          data.push({
            unit_price: stampProccessProduct?.price_default,
            quantity: '',
            total: stampProccessProduct?.price_default * 1,
            price: `Rp ${numberCommas(stampProccessProduct?.price_default * 1)},-`,
            name: stampProccessProduct?.name,
            id: stampProccessProduct?.id,
          })
        }

        form.setFieldsValue({
          products: [
            ...products,
            ...data
          ],
        });
      }

      setTotalPrice((prev) => {
        return {
          ...prev,
          [e]: selectedProduct?.price_default * 1,
        };
      });
    }
    form.setFieldsValue({ products: [] });
    if (module?.stamp_m) {
      handleChangeProductNameMultiple("1");
    }
    if (module?.backup_m) {
      handleChangeProductNameMultiple("4");
    }
    if (module?.sign_m) {
      handleChangeProductNameMultiple("2");
      handleChangeProductNameMultiple("5");
    }
    if(module?.blockchain_m){
      handleChangeProductNameMultiple("7")
    }
  }, [module, form, dataListProduct]);

  function getTotalPrice(listPrice) {
    let price = 0;

    for (let i = 0; i < Object.keys(listPrice).length; i++) {
      price += listPrice[Object.keys(listPrice)[i]];
    }

    return price;
  }

  function isProductInvalid(item) {
    const isInvalid = Boolean(form?.getFieldValue("id") === item?.id);
    const isInvalidList = Boolean(
      form
        ?.getFieldValue("products")
        ?.find((element) => element?.id === item?.id)
    );

    return isInvalidList || isInvalid;
  }

  function disabledDateBefore(current) {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  }

  function disabledDateAfter(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }

  return (
    <>
      <div className="mb-20">
        <Card className="rounded-md mb-4">
          <h3 className="text-gray-500 text-base md:text-lg font-medium">
            New Cooperation
          </h3>
          <Divider />
          <Row gutter={[24, 0]} align="middle">
            <Col xs={24} md={12}>
              <Form.Item
                label="Date Issued"
                name="contract_issue"
                rules={[
                  {
                    required: true,
                    message: "Please input Date Issued!",
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-full"
                  disabledDate={disabledDateAfter}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Expired Date"
                name="contract_expire"
                rules={[
                  {
                    required: true,
                    message: "Please input Expired Date!",
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-full"
                  disabledDate={disabledDateBefore}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Form.List name="products">
          {(fields, { add, remove }) => (
            <>
              {fields?.map(({ key, name, fieldKey, ...restField }, index) => (
                <Card key={key} className="rounded-md mb-4">
                  <Row key={key} gutter={[24, 0]} align="middle">
                    <Col span={24}>
                      <Row
                        gutter={[24, 24]}
                        align="middle"
                        justify="space-between"
                      >
                        <Col>
                          <h3 className="text-gray-500 text-base md:text-lg font-medium">
                            Product ({index + 1})
                          </h3>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Divider className="my-4" />
                    </Col>
                    
                    <Col xs={24} md={12}>
                      <Form.Item
                        {...restField}
                        label="Product Name"
                        name={[name, "name"]}
                        fieldKey={[fieldKey, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input product name!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Not Selected"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          disabled
                        >
                          {dataListProduct?.map((item, index) => {
                            return (
                              !isProductInvalid(item) && (
                                <Option key={index} value={item.id}>
                                  {item.name}
                                </Option>
                              )
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} className="hidden">
                      <Form.Item
                        {...restField}
                        label="Product Name"
                        name={[name, "id"]}
                        fieldKey={[fieldKey, "id"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input product name!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Not Selected"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {dataListProduct?.map((item, index) => {
                            return (
                              !isProductInvalid(item) && (
                                <Option key={index} value={item.id}>
                                  {item.name}
                                </Option>
                              )
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        {...restField}
                        label="Unit Price"
                        name={[name, "unit_price"]}
                        fieldKey={[fieldKey, "unit_price"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input unit price!",
                          },
                          ({ getFieldsValue, setFieldsValue }) => ({
                            validator(_, value) {
                              const fields = getFieldsValue();
                              const { products } = fields;
                              if(products){
                                if (value || products[key]?.quantity) {
                                  Object.assign(products[key], {
                                    total: products[key]?.quantity * value,
                                    price: `Rp ${numberCommas(
                                      products[key]?.quantity * value
                                    )},-`,
                                  });
                                  setFieldsValue({ products });
                                  setTotalPrice((prev) => {
                                    return {
                                      ...prev,
                                      [products[key].id]:
                                        products[key]?.quantity * value,
                                    };
                                  });
                                  return Promise.resolve();
                                }
                              }
                            },
                          }),
                        ]}
                      >
                        <Input
                          addonBefore="Rp"
                          placeholder="Enter unit price"
                          min={1}
                        />
                      </Form.Item>
                    </Col>
                    {products && products[key]?.id !== "6" && (
                      <>
                        <Col xs={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Quantity"
                            name={[name, "quantity"]}
                            fieldKey={[fieldKey, "quantity"]}
                            rules={[
                              // {
                              //   required: true,
                              //   message: "Please input quantity!",
                              // },
                              ({ getFieldsValue, setFieldsValue }) => ({
                                validator(_, value) {
                                  value = parseInt(value) || 0
                                  const fields = getFieldsValue();
                                  const { products } = fields;
                                  // if (value || products[key]?.quantity) {
                                    // e-Meterai per seal is related with Stamp Process
                                    // find stamp process index and populate the data 
                                    // based on e-meterai per-seal
                                    if(products[key]?.id === "1"){
                                      const stampProcessKey = products.findIndex(item => item?.id == "6")
                                      Object.assign(products[stampProcessKey], {
                                        quantity: value,
                                        total: products[stampProcessKey]?.unit_price * value,
                                        price: `Rp ${numberCommas(
                                          products[stampProcessKey]?.unit_price * value
                                        )},-`,
                                      });
                                    }

                                    Object.assign(products[key], {
                                      total: products[key]?.unit_price * value,
                                      price: `Rp ${numberCommas(
                                        products[key]?.unit_price * value
                                      )},-`,
                                    });

                                    setFieldsValue({ products });

                                    const price = {
                                      [products[key]?.id]: products[key]?.unit_price * value,
                                    }

                                   // e-Meterai per seal is related with Stamp Process
                                    // find stamp process index and populate the data 
                                    // based on e-meterai per-seal
                                    if(products[key]?.id === "1"){
                                      const stampProcessKey = products.findIndex(item => item?.id == "6")
                                      price[products[stampProcessKey]?.id] = products[stampProcessKey]?.unit_price * value
                                    }
                                    setTotalPrice((prev) => {
                                      return {
                                        ...prev,
                                        ...price,
                                      };
                                    });
                                    if(value > products[key]?.quota || 
                                      products[key]?.quantity > products[key]?.quota) {
                                        return Promise.reject("Quantity cannot be more than Quota")
                                      }

                                    return Promise.resolve();
                                  // }
                                },
                              }),
                            ]}
                          >
                            <Input 
                              placeholder="Enter Quantity" 
                              className="w-full"
                              // min={1} 
                              // precision={0}
                              // controls={false} 
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Quota"
                            name={[name, "quota"]}
                            fieldKey={[fieldKey, "quota"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input quota!",
                              },
                              ({ getFieldsValue, setFieldsValue }) => ({
                                validator(_, value) {
                                  const fields = getFieldsValue();
                                  const { products } = fields;
                                  if (value || products[key]?.quantity) {
                                    // e-Meterai per seal is related with Stamp Process
                                    // find stamp process index and populate the data 
                                    // based on e-meterai per-seal
                                    if(products[key]?.id === "1"){
                                      const stampProcessKey = products.findIndex(item => item?.id == "6")
                                      Object.assign(products[stampProcessKey], {
                                        quota: value,
                                        total_quota: products[key]?.unit_price * value,
                                      });
                                    }

                                    Object.assign(products[key], {
                                      total_quota: products[key]?.unit_price * value,
                                    });

                                    setFieldsValue({ products });

                                    if(value < products[key]?.quantity || 
                                      products[key]?.quota < products[key]?.quantity) {
                                        return Promise.reject("Quota cannot be less than Quantity")
                                      }

                                    return Promise.resolve();
                                  }
                                },
                              }),
                            ]}
                          >
                            <InputNumber 
                              placeholder="Enter Quota" 
                              className="w-full"
                              min={0} 
                              precision={0}
                              controls={false} />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    <Col xs={24} md={12} className="hidden">
                      <Form.Item
                        {...restField}
                        label="Price"
                        name={[name, "price"]}
                        fieldKey={[fieldKey, "price"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input price!",
                          },
                        ]}
                        initialValue="Rp 0,-"
                      >
                        <Input placeholder="Enter price" disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} className="hidden">
                      <Form.Item
                        {...restField}
                        label="Total"
                        name={[name, "total"]}
                        fieldKey={[fieldKey, "total"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input total!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter total" disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} className="hidden">
                      <Form.Item
                        {...restField}
                        label="Name"
                        name={[name, "name"]}
                        fieldKey={[fieldKey, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input name!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter name" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
        
                </Card>
              ))}
            </>
          )}
        </Form.List>
        <Card>
        <div className="flex flex-row justify-between rounded-md bg-blue-200 px-8 py-2">
          <h2 className="text-xs md:text-sm lg:text-base font-medium text-gray-800">
            Total Price
          </h2>
          <h2 className="text-xs md:text-sm lg:text-base font-medium text-blue-800">
            {`Rp ${numberCommas(getTotalPrice(totalPrice))},-`}
          </h2>
        </div>
        </Card>
      </div>
      
    </>
  );
}
