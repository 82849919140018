import {
  LeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Tooltip,
  Upload,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import { useAddDocumentMutation } from "../../config/features/document";
import { useFetchListOfficeQuery } from "../../config/features/office";
import { useFetchTagKeyQuery, useFetchTagValueQuery } from "../../config/features/tag";
import { useFetchProfileUserQuery } from "../../config/features/user";
import { errorMessage } from "../../utils";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;
const { Option } = Select;

export default function AddDocument() {
  const history = useHistory();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [addDocument, { isSuccess, isLoading, error, data, isError }] = useAddDocumentMutation();
  const { data: listOffice = [] } = useFetchListOfficeQuery({ row: 100 });
  const { data: profileUser = {} } = useFetchProfileUserQuery();

  const { data: dataListTagKey } = useFetchTagKeyQuery();
  const { data: dataListTagValue } = useFetchTagValueQuery();

  useEffect(() => {
    if (isError) {
      errorMessage(error);
    }
  }, [isSuccess, error, isError, profileUser]);

  const [fileList, setFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [payload, setPayload] = useState({});
  const [officeName, setOfficeName] = useState("");

  const onFinish = (values) => {
    if (fileList[0]) {
      values.date = moment(values.date).format("YYYY-MM-DD");
      showModal(values);
    } else {
      message.error(t("Please attach your file!"));
    }
  };

  async function handleSubmit(values) {
    try {
      const formData = new FormData();
      formData.append("doc_name", values.doc_name);
      formData.append("doc_code", values.doc_name);
      formData.append("date", values.date);
      formData.append("office_id", values.office_id);
      formData.append("file", fileList[0]);
      formData.append("description", values.description);
      formData.append("tags", JSON.stringify(values.tags));
      addDocument(formData);
    } catch (error) {
      message.error(error.message);
    }
  }

  const propsUpload = {
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      const isPdf = file.type === "application/pdf";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isPdf) {
        message.error(t("You can only upload PDF file!"));
      } else if (!isLt5M) {
        message.error(t("File must smaller than 5MB!"));
      } else {
        setFileList([file]);
      }
      return false;
    },
    fileList,
  };

  const showModal = (values) => {
    setPayload(values);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    handleSubmit(payload);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDone = () => {
    history.push(`/document-management`);
  };

  const handleToDetail = () => {
    history.push(`/document-management/detail/${data?.data?.doc_id}`);
  };

  return (
    <>
      <Form onFinish={onFinish} autoComplete="off" layout="vertical">
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]} justify="space-between">
              <Col>
                <Row gutter={[24, 24]} align="middle">
                  <Col>
                    <div
                      className="group cursor-pointer"
                      onClick={() => history.push("/document-management")}
                    >
                      <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                    </div>
                  </Col>
                  <Col>
                    <h1 className="text-xl md:text-2xl font-medium">
                      {t("Upload Document")}
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    className="md:w-40"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {t("Upload Document")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card className="rounded-md">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                    {t("inputnewdoc")}
                  </h3>
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 12]}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("titledocname")}
                        name="doc_name"
                        rules={[
                          {
                            required: true,
                            message: t("Please input document name!"),
                          },
                          () => ({
                            validator(_, value) {
                              if (
                                !Boolean(value?.trim()) &&
                                value?.length > 0
                              ) {
                                return Promise.reject(
                                  new Error(t("must be a character!"))
                                );
                              }
                              if (value?.length <= 50) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(t("maximum 50 characters!"))
                              );
                            },
                          }),
                        ]}
                      >
                        <Input placeholder={t("enterdocname")} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={t("date")}
                        name="date"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <DatePicker placeholder={t("selectdate")} format="DD/MM/YYYY" className="w-full" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      {profileUser?.office_type_id == 1 && profileUser?.role_id != 1 ? (
                        <Form.Item
                          label={t("Work Unit")}
                          name="office_id"
                          rules={[
                            {
                              required: true,
                              message: t("Please select work unit!"),
                            },
                          ]}
                        >
                          <Select
                            placeholder={t("Please Choose Work Unit!")}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(_, record) => {
                              setOfficeName(record?.children);
                            }}
                          >
                            {listOffice.map((item, index) => {
                              return (
                                <Option key={index} value={item.office_id}>
                                  {item.office_name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      ) : (
                        <Form.Item
                          label={t("Work Unit")}
                          name="office_id"
                        >
                          <Select
                            disabled
                            placeholder={profileUser?.office_name}
                          />
                        </Form.Item>
                      )}

                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={
                          <p>
                            <span className="text-red-500">*</span> {t("attachfile")}
                          </p>
                        }
                        name="file"
                        rules={[
                          {
                            required: false,
                            message: t("Please attach file!"),
                          },
                        ]}
                      >
                        <Upload
                          className="w-full"
                          maxCount={1}
                          {...propsUpload}
                          fileList={fileList}
                        >
                          <Button ghost icon={<UploadOutlined />}>
                            {t("clicktoupload")}
                          </Button>
                        </Upload>
                        <small>Format : PDF | {t("Max")} : 5 Mb</small>
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label={t("description")}
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: t("Please input description!"),
                          },
                        ]}
                      >
                        <TextArea placeholder={t("enterdescript")} />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Divider />
                      <p className="text-gray-500">
                        {t("notetag")}
                      </p>
                    </Col>
                    <Col xs={24}>
                      <Form.List name="tags">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(
                              ({ key, name, fieldKey, ...restField }) => (
                                <Row key={key} gutter={[24, 12]} align="middle">
                                  <Col md={10} lg={8}>
                                    <Form.Item
                                      {...restField}
                                      label={`${t("Tag Key")}`}
                                      name={[name, "tag_key"]}
                                      fieldKey={[fieldKey, "tag_key"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("Please input your Tag Key!"),
                                        },
                                      ]}
                                    >
                                      <AutoComplete
                                        options={dataListTagKey?.map(item => ({ value: item.tag_key }))}
                                        placeholder={t("Enter tag key")}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col md={10} lg={8}>
                                    <Form.Item
                                      {...restField}
                                      label={`${t("Tag Value (Optional)")}`}
                                      name={[name, "tag_value"]}
                                      fieldKey={[fieldKey, "tag_value"]}
                                      rules={[
                                        {
                                          required: false,
                                          message:
                                            t("Please input your Tag Value!"),
                                        },
                                      ]}
                                    >
                                      <AutoComplete
                                        options={dataListTagValue?.map(item => ({ value: item.tag_value }))}
                                        placeholder={`${t("Enter tag value")}`}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={1}>
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                    />
                                  </Col>
                                </Row>
                              )
                            )}
                            <Form.Item>
                              <Button
                                type="primary"
                                ghost
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                              >
                                {t("addtag")}
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      <Modal
        title={t("titlemodalconf")}
        subtitle={t("subtitlemodalconf")}
        type="warning"
        visible={isModalVisible}
        onCancel={handleCancel}
      >
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("titledocname")}</p>
            <div className="w-2" />
            <Tooltip title={payload?.doc_name}>
              <p className="text-gray-800 truncate">{payload?.doc_name}</p>
            </Tooltip>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("addtag")}</p>
            <div className="w-2" />
            <Tooltip
              title={payload?.tags?.map((item, index) => {
                return (
                  <span key={index}>
                    {item.tag_key}
                    {payload?.tags?.length > 1 &&
                      payload?.tags?.length - 1 !== index
                      ? ", "
                      : ""}
                  </span>
                );
              })}
            >
              <p className="text-gray-800 truncate">
                {payload?.tags?.map((item, index) => {
                  return (
                    <span key={index}>
                      {item.tag_key}
                      {payload?.tags?.length > 1 &&
                        payload?.tags?.length - 1 !== index
                        ? ", "
                        : ""}
                    </span>
                  );
                })}
              </p>
            </Tooltip>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("attachfile")}</p>
            <div className="w-8" />
            <Tooltip title={fileList.length > 0 && fileList[0].name}>
              <p className="text-gray-800 truncate">
                {fileList.length > 0 && fileList[0].name}
              </p>
            </Tooltip>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("date")}</p>
            <div className="w-2" />
            <p className="text-gray-800">
              {moment(payload?.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
            </p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("Work Unit")}</p>
            <div className="w-2" />
            <Tooltip title={officeName ? officeName : profileUser?.office_name}>
              <p className="text-gray-800 truncate">{officeName ? officeName : profileUser?.office_name}</p>
            </Tooltip>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-gray-500">{t("description")}</p>
            <div className="w-12" />
            <Tooltip title={payload?.description}>
              <p className="text-gray-800 truncate">{payload?.description}</p>
            </Tooltip>
          </div>
          <Divider />
        </div>
        <div className="flex flex-row space-x-4">
          <Button
            onClick={handleCancel}
            className="w-full"
            ghost
            type="secondary"
          >
            {t("btnback")}
          </Button>
          <Button onClick={handleOk} className="w-full" type="primary">
            {t("btnupload")}
          </Button>
        </div>
      </Modal>
      <Modal
        title={t("Upload Success!")}
        subtitle={t("The document has been added! Now you can view document detail or continue browsing")}
        type="success"
        visible={isSuccess}
        closable={false}
      >
        <div className="flex flex-row space-x-4">
          <Button
            onClick={handleToDetail}
            className="w-full"
            ghost
            type="secondary"
          >
            {t("btndetaildoc")}
          </Button>
          <Button onClick={handleDone} className="w-full" type="primary">
            {t("btndone")}
          </Button>
        </div>
      </Modal>
      <Spinner loading={isLoading} />
    </>
  );
}
