import { RightOutlined } from "@ant-design/icons";
import { Card, Col, Row, Select, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "../../components";
import {
  useFetchListClientQuery,
  useFetchListQoutationQuery,
} from "../../config/features/platform";
import { useFetchListProductQuery } from "../../config/features/product";
import { errorMessage, numberCommas } from "../../utils";
const { Option } = Select;

export default function QoutationManagement() {
  const history = useHistory();

  const [paramsTable] = useState({
    row: 10,
    page: 1,
    sort_by: "created_at",
    order: "desc",
  });

  const [paramsTableQuota, setParamsTableQuota] = useState({
    row: 5,
    page: 1,
    sort_by: "created_at",
    order: "desc",
    office_id: "",
  });

  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);

  const {
    data: dataListProduct = [],
    refetch: refetchListProduct,
    isError,
    error,
    isFetching,
  } = useFetchListProductQuery(paramsTable);

  const { data: dataListQoutation = {}, refetch: refetchQoutation } =
    useFetchListQoutationQuery(paramsTableQuota);

  const { data: dataListOffice = {} } = useFetchListClientQuery({
    row: 9999,
    page: 1,
    sort_by: "created_at",
    order: "desc",
  });

  useEffect(() => {
    refetchListProduct();
    refetchQoutation();

    if (isError) {
      errorMessage(error);
    }
  }, [refetchListProduct, isError, error, refetchQoutation]);

  const column = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Unit Price",
      dataIndex: "price_default",
      key: "price_default",
      render: (price_default) => {
        return `Rp ${numberCommas(price_default)},-`;
      },
    },
    {
      title: "Last Update",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at) => {
        return moment(updated_at, moment.ISO_8601).format("DD/MM/YYYY");
      },
    },
    {
      render: (record) => {
        return (
          record.no === 1 && (
            <div
              className="group cursor-pointer"
              onClick={() =>
                history.push({
                  pathname: `/quotation-management/product-detail/${record.id}`,
                  state: dataListProduct,
                })
              }
            >
              <RightOutlined className="group-hover:text-blue-500" />
            </div>
          )
        );
      },
    },
  ];

  const columnQoutation = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
    },
    {
      title: "Latest Date",
      dataIndex: "quotation_date",
      key: "quotation_date",
      render: (quotation_date) => {
        return quotation_date ? moment(quotation_date, moment.ISO_8601).format("DD/MM/YYYY") : "--";
      },
    },
    {
      title: "Total Price",
      dataIndex: "total_price",
      key: "total_price",
      render: (total_price) => {
        return `Rp ${numberCommas(total_price)},-`;
      },
    },
    {
      render: (record) => {
        return (
          <div
            className="group cursor-pointer"
            onClick={() =>
              history.push({
                pathname: `/quotation-management/quotation-detail/${record.office_id}`,
                state: dataListProduct,
              })
            }
          >
            <RightOutlined className="group-hover:text-blue-500" />
          </div>
        );
      },
    },
  ];

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  function datasetsListTableQuota(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTableQuota.row * (paramsTableQuota.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  function toggleDelete() {
    setIsModalDelete(!isModalDelete);
  }

  function handleDone() {
    setIsModalSuccess(false);
  }

  function handleChangeTableQuota(pagination) {
    setParamsTableQuota({
      ...paramsTableQuota,
      page: pagination.current,
      row: pagination.pageSize,
    });
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <h1 className="text-xl md:text-2xl font-medium">
                Quotation Management
              </h1>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      List of Product
                    </h3>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={column}
                  dataSource={datasetsListTable(dataListProduct)}
                  scroll={{ x: 600 }}
                  loading={isFetching}
                  pagination={false}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      List of Quotation
                    </h3>
                  </Col>
                  <Col>
                    <Select
                      style={{ width: 130 }}
                      onChange={(value) =>
                        setParamsTableQuota({
                          ...paramsTableQuota,
                          office_id: value,
                        })
                      }
                      value={paramsTableQuota.office_id}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="">Select All HQ</Option>
                      {dataListOffice?.rows?.map((item, index) => {
                        return (
                          <Option key={index} value={item?.office_id}>
                            {item?.office_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnQoutation}
                  dataSource={
                    dataListQoutation?.data &&
                    datasetsListTableQuota(dataListQoutation?.data)
                  }
                  scroll={{ x: 600 }}
                  // loading={isFetching}
                  pagination={{
                    current: paramsTableQuota.page,
                    pageSize: paramsTableQuota.row,
                    total: dataListQoutation?.total,
                  }}
                  onChange={handleChangeTableQuota}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Delete Product?"
        subtitle="Are you sure want to delete this product?"
        type="danger"
        visible={isModalDelete}
        onCancel={toggleDelete}
      >
        <div className="flex flex-row space-x-4">
          <Button
            className="w-full"
            type="secondary"
            ghost
            danger
            onClick={toggleDelete}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="w-full"
            danger
            // onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </Modal>
      <Modal
        title="Updated Success!"
        subtitle="Changes have been saved! Now you can continue browsing."
        type="success"
        visible={isModalSuccess}
      >
        <div className="text-center">
          <Button onClick={handleDone} className="w-32" type="primary">
            Done
          </Button>
        </div>
      </Modal>
    </>
  );
}
