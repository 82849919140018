import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { rootApi, access } from "../config";

export const groupApi = createApi({
  reducerPath: "api/group",
  baseQuery: fetchBaseQuery({
    baseUrl: rootApi,
    prepareHeaders: (header) => {
      header.set("token", access);
      return header;
    },
  }),
  endpoints(builder) {
    return {
      fetchListGroup: builder.query({
        query({
          page = 1,
          sort_by = "group_name",
          order = "desc",
          row = 15,
          search = "",
        }) {
          return `/group-user?page=${page}&sort_by=${sort_by}&order=${order}&row=${row}&search=${search}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      fetchGroupDetail: builder.query({
        query(group_id) {
          return `/group-user/${group_id}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),

      addGroup: builder.mutation({
        query: (body) => ({
          method: "POST",
          url: "/group-user",
          body,
        }),
      }),

      deleteGroup: builder.mutation({
        query: (idGroup) => ({
          method: "DELETE",
          url: `/group-user/${idGroup}`,
        }),
      }),

      editGroup: builder.mutation({
        query: ({ body, idGroup }) => ({
          method: "PUT",
          url: `/group-user/${idGroup}`,
          body,
        }),
      }),

      fetchListMembers: builder.query({
        query({ search = "", group_id = "" }) {
          return `/group-member?search=${search}&group_id=${group_id}`;
        },
        transformResponse: (response) => (response ? response.data : []),
      }),
    };
  },
});

export const {
  useFetchListGroupQuery,
  useFetchGroupDetailQuery,
  useAddGroupMutation,
  useDeleteGroupMutation,
  useEditGroupMutation,
  useFetchListMembersQuery,
} = groupApi;
