import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Divider, message, Row, Table } from "antd";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Button, Modal, Quotation } from "../../components";
import { useDownloadQuotationMutation } from "../../config/features/general";
import {
  useFetchDetailQoutationQuery,
  useSendQuotationMutation,
} from "../../config/features/platform";
import { QUOTATION_STATUS } from "../../constant";
import { errorMessage, numberCommas } from "../../utils";

export default function QoutationDetail() {
  const history = useHistory();
  const { id } = useParams();

  const [paramsTable, setParansTable] = useState({
    row: 5,
    page: 1,
    sort_by: "created_at",
    order: "desc",
    id_office: id
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [
    downloadQuotation,
    { data: dataSource = {}, isSuccess, isError, error },
  ] = useDownloadQuotationMutation();

  const [
    sendQuotation,
    { isSuccess: isSuccessSend, isError: isErrorSend, error: errorSend },
  ] = useSendQuotationMutation();

  const {
    data: dataDetailQoutation = { logs: [] },
    isFetching,
    refetch,
  } = useFetchDetailQoutationQuery(paramsTable);

  useEffect(() => {
    if (isSuccess) {
      message.info("preparing files...");
      setTimeout(() => {
        handlePrint();
      }, 3000);
    }

    if (isError) {
      errorMessage(error);
    }

    if (isErrorSend) {
      errorMessage(errorSend);
    }
  }, [isSuccess, isError, error, handlePrint, isErrorSend, errorSend]);

  useEffect(() => {
    if (isSuccessSend) {
      message.success("Quotation sent!");
    }
  }, [isSuccessSend]);

  useEffect(() => {
    refetch();
  }, [refetch, isSuccessSend]);

  const columns = [
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      render: (product_name) => {
        return product_name === "Total" ? (
          <p className="text-gray-500 font-medium text-sm">{product_name}</p>
        ) : (
          product_name
        );
      },
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (unit_price, record) => {
        return (
          record.product_name !== "Total" && `Rp ${numberCommas(unit_price)},-`
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity, record) => {
        return record.product_name !== "Total" && numberCommas(quantity);
      },
    },
    {
      title: "Remaining Quota",
      dataIndex: "remaining_quota",
      key: "remaining_quota",
      render: (quota, record) => {
        return record.product_name !== "Total" && numberCommas(quota);
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price, record) => {
        return record.product_name === "Total" ? (
          <p className="text-blue-700 font-medium text-sm">{`Rp ${numberCommas(
            price
          )},-`}</p>
        ) : (
          `Rp ${numberCommas(price)},-`
        );
      },
    },
  ];

  const columnsLog = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      render: (_, record) => {
        return (
          <>
            {record?.products?.map((item) => {
              return <p>{item?.product_name}</p>;
            })}
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "quotation_date",
      key: "quotation_date",
      render: (quotation_date) => {
        return (
          quotation_date &&
          moment(quotation_date, moment.ISO_8601).format("DD MMMM YYYY, HH:mm")
        );
      },
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (_, record) => {
        return (
          <>
            {record?.products?.map((item) => {
              return <p>{`Rp ${numberCommas(item?.unit_price)},-`}</p>;
            })}
          </>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, record) => {
        return (
          <>
            {record?.products?.map((item) => {
              return <p>{numberCommas(item?.quantity)}</p>;
            })}
          </>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, record) => {
        return (
          <>
            {record?.products?.map((item) => {
              return <p>{`Rp ${numberCommas(item?.price)},-`}</p>;
            })}
          </>
        );
      },
    },
    {
      title: "Total Price",
      dataIndex: "total_price",
      key: "total_price",
      render: (_, record) => {
        let totalPrice = 0;

        for (let i = 0; i < record?.products?.length; i++) {
          totalPrice += record?.products[i].price;
        }

        return (
          <p className="text-blue-700 font-medium">{`Rp ${numberCommas(
            totalPrice
          )},-`}</p>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <p className={clsx(QUOTATION_STATUS[status]?.clsTextColor)}>
            {QUOTATION_STATUS[status]?.value}
          </p>
        );
      },
    },
    {
      title: "Last Change by",
      dataIndex: "updated_by",
      key: "updated_by",
    },
  ];

  function datasetsListTable(data) {
    const datasets = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        no: i + 1 + paramsTable.row * (paramsTable.page - 1),
        ...data[i],
      });
    }

    return datasets;
  }

  async function handleDownloadQuotation() {
    try {
      downloadQuotation({ id });
    } catch (error) {
      message.error(error.message);
    }
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col>
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <div
                    className="group cursor-pointer"
                    onClick={() => history.goBack()}
                  >
                    <LeftOutlined className="text-gray-500 group-hover:text-blue-500" />
                  </div>
                </Col>
                <Col>
                  <h1 className="text-xl md:text-2xl font-medium">
                    {dataDetailQoutation?.quotation?.office_name} Quotation Detail
                  </h1>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={[24, 24]}>
                <Col>
                  <Button
                    className="md:w-40"
                    type="primary"
                    onClick={() =>
                      history.push({
                        pathname: `/quotation-management/add-quotation/${id}`,
                        state: dataDetailQoutation?.quotation?.products,
                      })
                    }
                    disabled={dataDetailQoutation?.is_prev_inv_active}
                  >
                    New Quotation
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row className="flex justify-between">
              <Col>
                <Row gutter={[48, 48]}>
                  <Col>
                    <h5 className="text-gray-500 mb-3">
                      Status
                    </h5>
                    <h4 className={clsx("text-3xl", QUOTATION_STATUS[dataDetailQoutation?.quotation?.status]?.clsTextColor)}>
                      {QUOTATION_STATUS[dataDetailQoutation?.quotation?.status]?.value || "--"}
                    </h4>
                  </Col>
                  <Col>
                    <h5 className="text-gray-500 mb-3">
                      Date Create
                    </h5>
                    <h4 className="text-3xl">
                      {dataDetailQoutation?.quotation?.quotation_date
                      ? moment(dataDetailQoutation?.quotation?.quotation_date, moment.ISO_8601)
                        .format("DD/MM/YY")
                      : "--"}
                    </h4>
                  </Col>
                </Row>
              </Col>
              <Col className="flex flex-col items-center justify-center">
                <Button type="primary" ghost onClick={handleDownloadQuotation}>
                  Download Quotation
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 0]}>
              <Col span={24}>
                <Row gutter={[24, 24]} align="middle" justify="space-between">
                  <Col>
                    <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                      Product List
                    </h3>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Divider />
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={
                    dataDetailQoutation?.quotation?.products?.length > 0
                      ? [
                          ...dataDetailQoutation?.quotation?.products,
                          {
                            product_name: "Total",
                            price: dataDetailQoutation?.quotation?.total,
                          },
                        ]
                      : []
                  }
                  loading={isFetching}
                  pagination={false}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card className="rounded-md">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <h3 className="text-gray-500 text-lg md:text-xl font-medium">
                  Log History
                </h3>
              </Col>
              <Col span={24}>
                <Table
                  columns={columnsLog}
                  dataSource={datasetsListTable(dataDetailQoutation?.logs)}
                  loading={isFetching}
                  scroll={{ x: 950 }}
                  pagination={{
                    current: paramsTable.page,
                    pageSize: paramsTable.row,
                    total: dataDetailQoutation?.total || 0
                  }}
                  onChange={(pagination) => {
                    setParansTable({
                      ...paramsTable,
                      page: pagination.current
                    })
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <div className="hidden">
        <div ref={componentRef} className="p-4">
          <Quotation dataSource={dataSource} />
        </div>
      </div>
    </>
  );
}
